import { Module } from '../modules/module';
import { TypeModule } from '../../enums/type-module';
import { NameModule } from '../name-module';

export class ModuleAttendee extends Module {
    typeVision: number;
    access_groups: any; // uid of the group that is allowed access to the module
    selected: boolean;
    orderUsers: string; // order type for display attendees in backoffice/application
    allowedEditProfile; // allows the attendee to edit your profile
    editProfileFirstAccess; // if enabled, the attendee is required to edit the profile on the first access
    fieldsCustom: Object;
    allow_chat: boolean;
    visOnlyForLoggedUser: boolean;

    constructor(name: NameModule, icon: string, typeVision: number, eventId: string, order: number, visOnlyForLoggedUser?: boolean) {
        super(name, icon, TypeModule.ATTENDEE, eventId, order, true, true, true)
        this.typeVision = typeVision;
        this.access_groups = {};
        this.selected = false;
        this.orderUsers = 'asc';
        this.allowedEditProfile = true;
        this.editProfileFirstAccess = false;
        this.fieldsCustom = {};
        this.allow_chat = null;
        if (visOnlyForLoggedUser === undefined) {
            this.visOnlyForLoggedUser = false;
        } else {
            this.visOnlyForLoggedUser = visOnlyForLoggedUser;
        }
    }
}