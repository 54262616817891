import { eventCustomField } from "./eventCustomField";

export class Speaker {
    private uid: string;

    //required attributes
    private name: string;
    private email: string;
    private type: number;
    private eventId: string;

    //attributes not required
    private queryName: string;
    private emailRecovery: string;
    private identifier: string;
    private photoUrl: string;
    private checkin: boolean;
    private points: number;
    private moduleId: string;
    private description: Object;
    private company: string;
    private title: Object;
    private chatStatus: boolean;
    private createdAt: Number;
    private site: string;
    private website: string;
    private facebook: string;
    private instagram: string;
    private linkedin: string;
    private twitter: string;
    private phone: string;
    private firstAccess: boolean;
    private groups: any;
    private customField: Array<eventCustomField>;
    private documents: any;
    private edited_profile: boolean;
    private language: string;
    private attendees: any;

    constructor(name: string, type: number, eventId: string) {
        this.uid = null;
        this.name = name;
        if (name !== undefined && name !== null && name !== '') this.queryName = name.toUpperCase();
        this.email = "";
        this.type = type;
        this.eventId = eventId;
        this.identifier = "";
        this.photoUrl = "";
        this.checkin = false;
        this.points = 0;
        this.moduleId = "";
        this.description = {
            PtBR: "",
            EnUS: "",
            EsES: "",
            FrFR: "",
            DeDE: ""
        };
        this.company = "";
        this.title = {
            PtBR: "",
            EnUS: "",
            EsES: "",
            FrFR: "",
            DeDE: ""
        };
        this.phone = "";
        this.chatStatus = false;
        this.groups = {};
        this.customField = null;
        this.documents = {};
        this.edited_profile = false;
        this.firstAccess = true;
        this.language = "";
        this.site = "";
        this.website = "";
        this.facebook = "";
        this.instagram = "";
        this.twitter = "";
        this.linkedin = "";
        this.emailRecovery = "";
        this.attendees = {};
    }

    /**
     * Getter $phone
     * @return {string}
     */
    public get $phone(): string {
        return this.phone;
    }

    /**
     * Setter $phone
     * @param {string} value
     */
    public set $phone(value: string) {
        this.phone = value;
    }


    /**
     * Getter $uid
     * @return {string}
     */
    public get $uid(): string {
        return this.uid;
    }

    /**
     * Getter $name
     * @return {string}
     */
    public get $name(): string {
        return this.name;
    }

    /**
 * Getter $queryName
 * @return {string}
 */
    public get $queryName(): string {
        return this.queryName;
    }

    /**
     * Getter $email
     * @return {string}
     */
    public get $email(): string {
        return this.email;
    }

    /**
     * Getter $emailRecovery
     * @return {string}
     */
    public get $emailRecovery(): string {
        return this.emailRecovery;
    }

    /**
     * Getter $identifier
     * @return {string}
     */
    public get $identifier(): string {
        return this.identifier;
    }

    /**
     * Getter $photoUrl
     * @return {string}
     */
    public get $photoUrl(): string {
        return this.photoUrl;
    }

    /**
     * Getter $checkin
     * @return {boolean}
     */
    public get $checkin(): boolean {
        return this.checkin;
    }

    /**
     * Getter $points
     * @return {number}
     */
    public get $points(): number {
        return this.points;
    }

    /**
     * Getter $moduleId
     * @return {string}
     */
    public get $moduleId(): string {
        return this.moduleId;
    }

    /**
     * Getter $description
     * @return {Object}
     */
    public get $description(): Object {
        return this.description;
    }

    /**
     * Getter $company
     * @return {string}
     */
    public get $company(): string {
        return this.company;
    }

    /**
     * Getter $title
     * @return {Object}
     */
    public get $title(): Object {
        return this.title;
    }

    /**
     * Getter $chatStatus
     * @return {boolean}
     */
    public get $chatStatus(): boolean {
        return this.chatStatus;
    }

    /**
     * Getter $site
     * @return {string}
     */
    public get $site(): string {
        return this.site;
    }

    /**
 * Getter $website
 * @return {string}
 */
    public get $website(): string {
        return this.website;
    }

    /**
     * Getter $facebook
     * @return {string}
     */
    public get $facebook(): string {
        return this.facebook;
    }

    /**
     * Getter $instagram
     * @return {string}
     */
    public get $instagram(): string {
        return this.instagram;
    }

    /**
     * Getter $linkedin
     * @return {string}
     */
    public get $linkedin(): string {
        return this.linkedin;
    }

    /**
     * Getter $twitter
     * @return {string}
     */
    public get $twitter(): string {
        return this.twitter;
    }

    /**
 * Getter $language
 * @return {string}
 */
    public get $language(): string {
        return this.language;
    }

    /**
     * Setter $uid
     * @param {string} value
     */
    public set $uid(value: string) {
        this.uid = value;
    }

    /**
     * Setter $name
     * @param {string} value
     */
    public set $name(value: string) {
        this.name = value;
    }


    /**
     * Setter $queryName
     * @param {string} value
     */
    public set $queryName(value: string) {
        this.queryName = value;
    }

    /**
     * Setter $email
     * @param {string} value
     */
    public set $email(value: string) {
        this.email = value;
    }

    /**
     * Setter $emailRecovery
     * @param {string} value
     */
    public set $emailRecovery(value: string) {
        this.emailRecovery = value;
    }

    /**
     * Setter $identifier
     * @param {string} value
     */
    public set $identifier(value: string) {
        this.identifier = value;
    }

    /**
     * Setter $photoUrl
     * @param {string} value
     */
    public set $photoUrl(value: string) {
        this.photoUrl = value;
    }

    /**
     * Setter $checkin
     * @param {boolean} value
     */
    public set $checkin(value: boolean) {
        this.checkin = value;
    }

    /**
     * Setter $points
     * @param {number} value
     */
    public set $points(value: number) {
        this.points = value;
    }

    /**
     * Setter $moduleId
     * @param {string} value
     */
    public set $moduleId(value: string) {
        this.moduleId = value;
    }

    /**
     * Setter $description
     * @param {Object} value
     */
    public set $description(value: Object) {
        this.description = value;
    }

    /**
     * Setter $company
     * @param {string} value
     */
    public set $company(value: string) {
        this.company = value;
    }

    /**
     * Setter $title
     * @param {Object} value
     */
    public set $title(value: Object) {
        this.title = value;
    }

    /**
     * Setter $chatStatus
     * @param {boolean} value
     */
    public set $chatStatus(value: boolean) {
        this.chatStatus = value;
    }

    /**
     * Setter $site
     * @param {string} value
     */
    public set $site(value: string) {
        this.site = value;
    }

    /**
 * Setter $website
 * @param {string} value
 */
    public set $website(value: string) {
        this.website = value;
    }

    /**
     * Setter $facebook
     * @param {string} value
     */
    public set $facebook(value: string) {
        this.facebook = value;
    }

    /**
     * Setter $instagram
     * @param {string} value
     */
    public set $instagram(value: string) {
        this.instagram = value;
    }

    /**
     * Setter $linkedin
     * @param {string} value
     */
    public set $linkedin(value: string) {
        this.linkedin = value;
    }

    /**
     * Setter $twitter
     * @param {string} value
     */
    public set $twitter(value: string) {
        this.twitter = value;
    }


    /**
     * Getter $type
     * @return {number}
     */
    public get $type(): number {
        return this.type;
    }

    /**
     * Setter $type
     * @param {number} value
     */
    public set $type(value: number) {
        this.type = value;
    }

    /**
     * Getter $createdAt
     * @return {Number}
     */
    public get $createdAt(): Number {
        return this.createdAt;
    }

    /**
     * Setter $createdAt
     * @param {Number} value
     */
    public set $createdAt(value: Number) {
        this.createdAt = value;
    }



    /**
     * Getter $firstAccess
     * @return {boolean}
     */
    public get $firstAccess(): boolean {
        return this.firstAccess;
    }

    /**
     * Setter $firstAccess
     * @param {boolean} value
     */
    public set $firstAccess(value: boolean) {
        this.firstAccess = value;
    }


    /**
    * Getter $groups
    * @return {any}
    */
    public get $groups(): any {
        return this.groups;
    }

    /**
     * Setter $groups
     * @param {any} value
     */
    public set $groups(value: any) {
        this.groups = value;
    }

    /**
* Getter $customField
* @return {Array<eventCustomField>}
*/
    public get $customField(): Array<eventCustomField> {
        return this.customField;
    }

    /**
     * Setter $customField
     * @param {Array<eventCustomField>} value
     */
    public set $customField(value: Array<eventCustomField>) {
        this.customField = value;
    }


    /**
     * Getter $documents
     * @return {any}
     */
    public get $documents(): any {
        return this.documents;
    }

    /**
     * Setter $documents
     * @param {any} value
     */
    public set $documents(value: any) {
        this.documents = value;
    }

    /**
 * Getter $edited_profile
 * @return {boolean}
 */
    public get $edited_profile(): boolean {
        return this.edited_profile;
    }

    /**
     * Setter $edited_profile
     * @param {boolean} value
     */
    public set $edited_profile(value: boolean) {
        this.edited_profile = value;
    }

    /**
 * Setter $language
 * @param {string} value
 */
    public set $language(value: string) {
        this.language = value;
    }


    /**
     * Getter $eventId
     * @return {string}
     */
    public get $eventId(): string {
        return this.eventId;
    }

    /**
     * Setter $eventId
     * @param {string} value
     */
    public set $eventId(value: string) {
        this.eventId = value;
    }

    /**
     * Getter $attendees
     * @return {any}
     */
    public get $attendees(): any {
        return this.attendees;
    }

    /**
     * Setter $attendees
     * @param {any} value
     */
    public set $attendees(value: any) {
        this.attendees = value;
    }
}