import { Component, NgModule, OnInit } from '@angular/core';
import { DbEventsProvider } from 'src/app/providers/database/db.events';
import { GlobalService } from 'src/app/providers/global/global.service';
import { TypeUser } from 'src/app/enums/typeUser';
import { LuxonService } from 'src/app/providers/luxon/luxon.service';
import { Router } from '@angular/router';
import { FilterPipe } from 'src/app/pipes/filter.pipe';
import { DbAdminUserProvider } from 'src/app/providers/database/db-admin-user';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-client-resume',
  templateUrl: './client-resume.component.html',
  styleUrls: ['./client-resume.component.scss']
})

@NgModule({
  declarations: [FilterPipe],
})

export class ClientResumeComponent implements OnInit {
  loader: boolean = true;
  public p: number = 1;
  public events: Array<any> = [];
  public userUid: string = null;
  public userType: number;
  public clientId: string = null;
  public totalEvents: number = 0;
  public totalAttendees: number = 0;
  public totalAccess: number = 0;
  interval: any;
  term: string = '';
  typeOrder: string = 'asc';


  constructor(
    private dbEvent: DbEventsProvider,
    private global: GlobalService,
    private luxon: LuxonService,
    private router: Router,
    private dbUser: DbAdminUserProvider,
    private translateService: TranslateService
  ) {
    this.global.loadService((_) => {
      this.loadOrder().then(() => {
        this.loadEvents();
      });
    });
  }

  ngAfterContentChecked() {
    this.userUid = this.global.userId;
    this.userType = this.global.userType;
    this.clientId = this.global.clientId;
  }

  ngOnInit() {
  }

  loadOrder() {
    return new Promise((resolve) => {
      this.userUid = this.global.userId;

      this.dbUser.getUser(this.userUid, (data) => {
        let user = data.result;

        if (user.internalEventsOrder !== undefined && user.internalEventsOrder !== null && user.internalEventsOrder !== '') {
          this.typeOrder = user.internalEventsOrder;
        }

        resolve(true);
      })
    })
  }

  changeOrder() {
    this.dbUser.changeOrderClientsEvents(this.userUid, this.typeOrder, (data) => {
      if (data == true) {
        this.loadEvents();
      }
    })
  }

  loadEvents() {
    if (this.userType == TypeUser.CLIENT) {
      this.dbEvent.getClientEvent(this.userUid, this.typeOrder, (events) => {
        this.events = [];
        this.totalEvents = events.length;
        for (const event of events) {
          // code provisional
          event.startDate = this.prepareFilterDate(event.startDate);
          event.endDate = this.prepareFilterDate(event.endDate);

          this.events.push(event);
        }

        this.loader = false;
      });
    } else if (this.userType == TypeUser.EMPLOYEE) {
      this.dbEvent.getClientEvent(this.clientId, this.typeOrder, (events) => {
        this.events = [];
        this.totalEvents = events.length;

        for (const event of events) {
          // code provisional
          event.startDate = this.prepareFilterDate(event.startDate);
          event.endDate = this.prepareFilterDate(event.endDate);

          this.events.push(event);
        }

        this.loader = false;
      });
    }

    if (this.userUid !== null) clearInterval(this.interval);
  }

  openEmployees() {
    this.router.navigate([`/dash/employees/${this.userUid}`]);
  }

  openContainers() {
    this.router.navigate([`/dash/containers/${this.userUid}`]);
  }

  prepareFilterDate(oldDate: number) {
    let date = new Date(oldDate * 1000);
    let day = '0' + date.getDate();
    let month = this.selectMonth(date.getMonth() + 1);
    let finalDate = day.substr(-2) + ' ' + month;
    return finalDate;

  }

  selectMonth(month: number) {
    let finalMonth;
    switch (month) {
      case 1:
        finalMonth = this.translateService.instant('global.january')
        break;
      case 2:
        finalMonth = this.translateService.instant('global.february')
        break;
      case 3:
        finalMonth = this.translateService.instant('global.march')
        break;
      case 4:
        finalMonth = this.translateService.instant('global.april')
        break;
      case 5:
        finalMonth = this.translateService.instant('global.may')
        break;
      case 6:
        finalMonth = this.translateService.instant('global.june')
        break;
      case 7:
        finalMonth = this.translateService.instant('global.july')
        break;
      case 8:
        finalMonth = this.translateService.instant('global.august')
        break;
      case 9:
        finalMonth = this.translateService.instant('global.september')
        break;
      case 10:
        finalMonth = this.translateService.instant('global.october')
        break;
      case 11:
        finalMonth = this.translateService.instant('global.november')
        break;
      case 12:
        finalMonth = this.translateService.instant('global.december')
        break;
    }
    return finalMonth;
  }

}
