import { Component, OnInit, HostListener } from '@angular/core';
import { AuthService } from '../../providers/auth/auth.service';
import { environment } from 'src/environments/environment';
import { GlobalService } from 'src/app/providers/global/global.service';
import { TypeUser } from 'src/app/enums/typeUser';
import { Router } from '@angular/router';

declare let $: any;
@Component({
    selector: 'app-client-dashboard',
    templateUrl: './client-dashboard.component.html',
    styleUrls: ['./client-dashboard.component.scss']
})
export class ClientDashboardComponent implements OnInit {
    public navLogo: string = environment.platform.navLogo;
    public license: string = environment.platform.license;
    public displayName: string = null;

    public photoUrl: string = null;
    public userUid: string = null;
    public userType: number;

    constructor(
        private auth: AuthService,
        private global: GlobalService,
        private router: Router
    ) {
        this.global.loadService((_) => { });

    }

    ngAfterContentChecked() {
        this.displayName = this.global.displayName;
        localStorage.setItem('clientName', this.displayName);
        this.photoUrl = this.global.photoUrl;
        this.userUid = this.global.userId;
        this.userType = this.global.userType;
    }

    ngOnInit() {
        $(document).ready(function () {
            // Menu Trigger
            $('#menuToggle').on('click', function (event) {
                var windowWidth = $(window).width();
                if (windowWidth < 1010) {
                    $('body').removeClass('open');
                    if (windowWidth < 760) {
                        $('#left-panel').slideToggle();
                    } else {
                        $('#left-panel').toggleClass('open-menu');
                    }
                } else {
                    $('body').toggleClass('open');
                    $('#left-panel').removeClass('open-menu');
                }

            });


            $(".menu-item-has-children.dropdown").each(function () {
                $(this).on('click', function () {
                    var $temp_text = $(this).children('.dropdown-toggle').html();
                    $(this).children('.sub-menu').prepend('<li class="subtitle">' + $temp_text + '</li>');
                });
            });


            // Load Resize 
            $(window).on("load resize", function (event) {
                var windowWidth = $(window).width();
                if (windowWidth < 1010) {
                    $('body').addClass('small-device');
                } else {
                    $('body').removeClass('small-device');
                }

            });

        });
    }

    redirectDashboard() {
        if (this.userType == TypeUser.GOD || this.userType == TypeUser.SUPERGOD) {
            this.router.navigate(['/dashboard']);
        } else {
            this.router.navigate(['/dash']);
        }
    }

    redirectProfile() {
        if (this.userType == TypeUser.GOD || this.userType == TypeUser.SUPERGOD) {
            this.router.navigate([`/dashboard/profile/${this.userUid}`]);
        } else {
            this.router.navigate([`/dash/my-profile/${this.userUid}`]);
        }
    }

    logoutUser() {
        this.auth.logoutUser();
    }

}
