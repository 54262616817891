import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "getNameParticipant",
})
export class GetNameParticipantPipe implements PipeTransform {
  transform(value: any[]): string {
    return value.map((p) => p.name).join(", ");
  }
}
