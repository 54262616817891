import { Component, OnInit, NgModule, ViewChild } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Event } from '../../models/event';
import { LuxonService } from '../../providers/luxon/luxon.service';
import { PathApi } from 'src/app/paths/path-api';
import { DbEventsProvider } from 'src/app/providers/database/db.events';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { GlobalService } from 'src/app/providers/global/global.service';
import { TypeUser } from 'src/app/enums/typeUser';
import { DbAdminUserProvider } from 'src/app/providers/database/db-admin-user';
import { FilterPipe } from 'src/app/pipes/filter.pipe';
import { DateTimeService } from 'src/app/providers/date-time/date-time.service';

@Component({
    selector: 'app-events-client',
    templateUrl: './events-client.component.html',
    styleUrls: ['./events-client.component.scss']
})

@NgModule({
    declarations: [FilterPipe],
})

export class EventsClientComponent implements OnInit {
    public headers;
    public requestOptions;

    // models
    events: Array<Event>;
    eventRemove: Event = null;
    loader: boolean = true;
    typeOrder: string = 'asc';
    userId: string = null;
    public p: number = 1;
    term: string = '';

    @ViewChild('successSwal') public successSwal: SwalComponent;

    constructor(
        private dbEvents: DbEventsProvider,
        private dbUser: DbAdminUserProvider,
        private global: GlobalService,
        private dt: DateTimeService
    ) {
        this.headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
            'Access-Control-Allow-Headers':
                'Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers'
        })

        this.global.loadService(() => {
            this.loadOrder().then(() => {
                this.loadEvents();
            });
        })
    }

    ngOnInit() {
    }

    loadOrder() {
        return new Promise((resolve) => {
            if (this.global.userType == TypeUser.SUPERGOD || this.global.userType == TypeUser.GOD || this.global.userType == TypeUser.CLIENT) {
                this.userId = this.global.userId;

                this.dbUser.getUser(this.userId, (data) => {
                    let user = data.result;

                    if (user.clientsEventsOrder !== undefined && user.clientsEventsOrder !== null && user.clientsEventsOrder !== '') {
                        this.typeOrder = user.clientsEventsOrder;
                    }

                    resolve(true);
                })
            } else {
                resolve(true);
            }
        })
    }

    changeOrder() {
        this.dbUser.changeOrderClientsEvents(this.userId, this.typeOrder, (data) => {
            if (data == true) {
                this.loadEvents();
            }
        })
    }

    loadEvents() {
        this.dbEvents.getEventsClient(this.typeOrder, events => {
            this.events = [];

            for (const event of events) {
                event.startDate = this.dt.getDate(event.startDate);
                event.endDate = this.dt.getDate(event.endDate);

                this.events.push(event);
            }

            this.loader = false;
        });
    }

    setEventRemove(event: Event) {
        this.eventRemove = event;
    }

    deleteEvent() {
        this.loader = true;
        const eventId = this.eventRemove.uid;

        this.dbEvents.deleteEvent(eventId, (data) => {
            if (data) {
                this.successSwal.fire();
                this.loader = false;
            }
        })
    }
}
