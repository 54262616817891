import { Component, OnInit, NgModule } from '@angular/core';
import { Session } from './../../../../models/session'
import { ActivatedRoute, Router } from '@angular/router';
import { DbPersonalScheduleProvider } from 'src/app/providers/database/db-personal-schedule';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { GlobalService } from 'src/app/providers/global/global.service';
import { GetNameSession } from 'src/app/pipes/get-name-session';
import { DbEventsProvider } from 'src/app/providers/database/db.events';


declare let $: any;
type AOA = Array<Array<any>>;

@Component({
  selector: 'app-schedule-personal',
  templateUrl: './schedule-personal.component.html',
  styleUrls: ['./schedule-personal.component.scss']
})

@NgModule({
  declarations: [
    GetNameSession
  ],
})

export class SchedulePersonalComponent implements OnInit {
  public module = null
  loader: boolean = true;
  sessions: Array<Session> = []
  public p: number = 1;
  eventId: string = this.route.parent.params['_value']['uid'];
  moduleId: string = this.route.params['_value']['moduleId'];

  dataExport: AOA;
  event: any = null;
  // get the language of the user.
  public userLanguage: string
  // get the language of the event.
  public eventLanguage: string

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private dbPersonal: DbPersonalScheduleProvider,
    private dbEvent: DbEventsProvider,
    private global: GlobalService,
  ) { }

  ngOnInit() {
    // after load sessions, make loader false to display sessions list
    // load sessions
    this.dbPersonal.getSessions(this.eventId, (sessions) => {
      this.loader = false;
      this.sessions = sessions
    })

    // load module
    this.dbPersonal.getModule(this.moduleId, (module) => {
      this.module = module
    })

    // load user language
    this.global.getLanguage((language) => {
      this.userLanguage = language
    })

    this.dbEvent.getEvent(this.eventId, (event) => {
      this.event = event;
      this.eventLanguage = event.language;
    });
  }

  private s2ab(s: string): ArrayBuffer {
    const buf: ArrayBuffer = new ArrayBuffer(s.length);
    const view: Uint8Array = new Uint8Array(buf);
    for (let i = 0; i !== s.length; ++i) {
      view[i] = s.charCodeAt(i) & 0xFF;
    }
    return buf;
  }

  export() {
    this.dataExport = [
      [
        'id*',
        'session name*',
        'name of participants*',
        'participants email*',
      ]
    ]

    // add session excel
    for (const session of this.sessions) {
      this.dataExport.push(this.preparesData(session))
    }

    // sets the number and width of the columns
    const wscols: XLSX.ColInfo[] = []

    for (let i = 0; i < 1000; i++) {
      wscols.push({ wpx: 150 })
    }
    wscols.push({ hidden: false })

    const wsrows: XLSX.RowInfo[] = [
      { hpx: 25 }, // "pixels"
    ];

    /* generate worksheet */
    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.dataExport);

    /* TEST: column props */
    ws['!cols'] = wscols;

    /* TEST: row props */
    ws['!rows'] = wsrows;

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    const wbout: string = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
    saveAs(new Blob([this.s2ab(wbout)]), 'report-sessions.xlsx');

  }

  preparesData(element) {
    const row = []

    row.push(element.identifier);

    // name session
    row.push(new GetNameSession().transform(element, [this.userLanguage, this.eventLanguage]));

    // name attendees
    let totalName = 0
    let contName = 1
    let names = ''

    for (let i in element.attendees) { totalName++ }


    for (const attendee of element.attendees) {
      const name = attendee.name

      if (contName < totalName) {
        names += name + ";";
      } else {
        names += name
      }

      contName++
    }

    row.push(names)

    // email attendees
    let totalEmail = 0
    let contEmail = 1
    let emails = ''

    for (let i in element.attendees) { totalEmail++ }


    for (const attendee of element.attendees) {
      const email = attendee.email

      if (contEmail < totalEmail) {
        emails += email + ";";
      } else {
        emails += email
      }

      contEmail++
    }

    row.push(emails)

    return row
  }

}
