import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Post } from 'src/app/models/post';
import { StorageService } from '../storage/storage.service';


@Injectable({
    providedIn: 'root'
})

export class DbNewsFeedProvider {
    constructor(
        private aFirestore: AngularFirestore,
        private storage: StorageService
    ) {

    }

    moduleNewsFeed(moduleId: string, onResolve) {
        let db = this.aFirestore.firestore
        const ref = db.collection('modules').doc(moduleId)

        ref.onSnapshot((snapshot) => {
            const module = snapshot.data()
            onResolve(module)
        })
    }

    updateModule(module) {
        const moduleId = module.uid
        const eventId = module.eventId

        let db = this.aFirestore.firestore
        let batch = db.batch()

        const ref1 = db.collection('modules').doc(moduleId)
        const ref2 = db.collection('events').doc(eventId).collection('modules').doc(moduleId)

        batch.update(ref1, module)
        batch.update(ref2, module)

        batch.commit().then(() => {
            console.log('update module')
        })
    }

    // create an id for the post that will be created
    createIdPost(moduleId: string) {
        let db = this.aFirestore.firestore;
        return db.collection('modules').doc(moduleId).collection('posts').doc().id
    }



    // get the name and logo of the event for the creator of the posts
    getEventData(eventId: string) {
        return new Promise((resolve) => {
            let db = this.aFirestore.firestore;

            const ref = db.collection('events').doc(eventId)
            ref.get().then((snapshot) => {
                const event = snapshot.data()
                resolve({
                    name: event.title,
                    img: {
                        url: event.logo.url,
                    },
                    uid: event.uid
                })
            })
        })
    }

    // get the name and img of the client for the creator of the posts
    getClientData(clientId: string) {
        return new Promise((resolve) => {
            let db = this.aFirestore.firestore;

            const ref = db.collection('users').doc(clientId)
            ref.get().then((snapshot) => {
                const client = snapshot.data()
                resolve({
                    name: client.name,
                    img: {
                        url: client.photoUrl
                    },
                    uid: client.uid
                })
            })
        })
    }

    createPost(post: Post, eventId: string) {
        return new Promise((resolve, reject) => {
            let db = this.aFirestore.firestore;
            const moduleId = post.moduleId
            const postId = post.uid

            const ref = db.collection('modules').doc(moduleId).collection('posts').doc(postId);
            const refEvent = db.collection('events').doc(eventId).collection('feed-posts').doc(postId);
            ref.set(Object.assign({}, post))
                .then(() => {
                    console.log("post successfully written!");
                    resolve(true)
                })
                .catch((error) => {
                    console.error("Error writing post: ", error);
                    reject(error)
                });
            refEvent.set(Object.assign({}, post));
        })
    }

    /**
     * @description deletes the previous post doc ref and create a new one, so 
     * the edited posts gets a new doc id
     * @param post 
     * @param eventId 
     * @returns 
     */
    editPost(post: Post, eventId: string) {
        const db = this.aFirestore.firestore;
        const batch = db.batch();
        const moduleId = post.moduleId;
        const postId = post.uid;
        const refColls = [
            db.collection('modules').doc(moduleId).collection('posts'),
            db.collection('events').doc(eventId).collection('feed-posts')
        ];

        refColls.forEach((ref) => { batch.delete(ref.doc(postId)); });
        
        let docId = null;
        refColls.forEach((ref) => {
            const docRef = (docId) ? ref.doc(docId): ref.doc();
            docId = docRef.id;
            Object.assign(post, {uid: docRef.id});
            batch.set(docRef, post);
        });

        return batch.commit();
    }


    deletePost(post: Post, eventId: string) {
        return new Promise((resolve, reject) => {
            let db = this.aFirestore.firestore;
            const moduleId = post.moduleId
            const postId = post.uid

            // delete img storarge
            if (post.img.url.length > 0) {
                this.storage.deletePost(post.uid, eventId, post.moduleId)
            }

            const ref = db.collection('modules').doc(moduleId).collection('posts').doc(postId)
            const refEvent = db.collection('events').doc(eventId).collection('feed-posts').doc(postId);

            ref.delete()
                .then(() => {
                    console.log("post successfully delete!");
                    resolve(true)
                })
                .catch((error) => {
                    console.error("Error delete post: ", error);
                    reject(error)
                })

            refEvent.delete();
        })


    }

    // get posts module
    getPostsNewsFeed(moduleId: string, onResolve) {
        let db = this.aFirestore.firestore;

        const ref = db.collection('modules').doc(moduleId).collection('posts').orderBy('fixedTop').orderBy('date', 'desc')

        ref.onSnapshot((snapshot) => {
            const list = []

            snapshot.forEach((childSnapshot) => {
                const post = childSnapshot.data()
                list.push(post)
            })

            onResolve(list)
        })
    }

}