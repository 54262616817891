import { Module } from "./module";
import { TypeModule } from '../../enums/type-module';
import { Notification } from '../notifications';
import { NameModule } from "../name-module";

export class ModuleNotification extends Module {
    notifications: Array<Notification>;
    orderNotifications: string;
    visOnlyForLoggedUser?: boolean;


    constructor(name: NameModule, icon: string, eventId: string, visOnlyForLoggedUser?: boolean) {
        // name, icon, module, eventid, order, allow remove, habilited on app, habilited on backoffice
        super(name, icon, TypeModule.NOTIFY, eventId, null, false, null, true)
        this.notifications = [];
        this.orderNotifications = 'desc';
        if (visOnlyForLoggedUser === undefined) {
            this.visOnlyForLoggedUser = false;
        } else {
            this.visOnlyForLoggedUser = visOnlyForLoggedUser;
        }
    }

}