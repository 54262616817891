import { Module } from './module';
import { TypeModule } from '../../enums/type-module';
import { NameModule } from '../name-module';

export class ModuleGroupDiscussion extends Module {
    typeVision: number;
    access_groups: any; // uid of the group that is allowed access to the module
    selected: boolean;
    visOnlyForLoggedUser: boolean;

    constructor(name: NameModule, icon: string, typeVision: number, eventId: string, order: number, visOnlyForLoggedUser?: boolean) {
        super(name, icon, TypeModule.NETWORKING, eventId, order, true, false, false)
        this.typeVision = typeVision;
        this.access_groups = {};
        this.selected = false;
        if (visOnlyForLoggedUser === undefined) {
            this.visOnlyForLoggedUser = false;
        } else {
            this.visOnlyForLoggedUser = visOnlyForLoggedUser;
        }    }
}
