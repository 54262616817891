import { Pipe, PipeTransform } from '@angular/core';
import { Module } from '../models/modules/module';

@Pipe({
    name: 'getNameModule',
    pure: true
})

export class GetNameModule implements PipeTransform {
    private userLanguage: string //user language.
    private eventLanguage: string //main language of the event.

    transform(value: Module, args: string[]): any {
        this.userLanguage = args[0]
        this.eventLanguage = args[1]
        return this.getNameModule(value);
    }

    getNameModule(module: Module): string {
        let name: string = ""

        switch (this.userLanguage) {
            case 'pt_BR':
                name = module.name.PtBR;
                break;
            case 'en_US':
                name = module.name.EnUS;
                break;
            case 'es_ES':
                name = module.name.EsES;
                break;
            case 'fr_FR':
                name = module.name.FrFR;
                break;
            case 'de_DE':
                name = module.name.DeDE;
                break;
        }

        if (name === '') {
            switch (this.eventLanguage) {
                case 'pt_BR':
                    name = module.name.PtBR;
                    break;
                case 'en_US':
                    name = module.name.EnUS;
                    break;
                case 'es_ES':
                    name = module.name.EsES;
                    break;
                case 'fr_FR':
                    name = module.name.FrFR;
                    break;
                case 'de_DE':
                    name = module.name.DeDE;
                    break;
            }
        }

        return name
    }
}