import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
    providedIn: 'root'
})

export class DbRankingProvider {
    constructor(private afs: AngularFirestore) { }

    getRankingModule(eventId: string, moduleId: string, onResolve) {
        let db = this.afs.firestore;

        db
            .collection('events')
            .doc(eventId)
            .collection('modules')
            .doc(moduleId)
            .onSnapshot({
                next: (snapshot) => onResolve(snapshot.data()),
                error: (error) => console.error(error)
            })
    }

    getAttendeeRanking(eventId: string, onResolve) {
        let db = this.afs.firestore;

        db
            .collection('events')
            .doc(eventId)
            .collection('attendees')
            .orderBy('queryName', 'asc')
            // .orderBy('points', 'desc')
            .onSnapshot((snapshot) => {
                let attendees = [];
                if (snapshot.size >= 1) {
                    snapshot.forEach(element => {
                        attendees.push(element.data());
                    });
                }
                onResolve(attendees);
            })
    }

    updateRankingSettings(eventId: string, moduleId: string, mergeInteractivityPoints: boolean, showOnlyAttendeeWithPoints: boolean): Promise<void> {
        let db = this.afs.firestore;

        const ref = db
            .collection('events')
            .doc(eventId)
            .collection("modules")
            .doc(moduleId);

        return ref.update({
            mergeInteractivityPoints,
            showOnlyAttendeeWithPoints,
        })
    }
}
