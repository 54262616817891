import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from '../../providers/auth/auth.service';
import { PathApi } from '../../paths/path-api';
import firebase from 'firebase/app';
import { AngularFirestore } from '@angular/fire/firestore';
import { User } from 'src/app/models/user';

@Injectable({
    providedIn: 'root'
})
export class DbClientEmployeeProvider {
    public headers;
    public requestOptions;
    public db;
    constructor(private auth: AuthService, private http: HttpClient, private aFirestore: AngularFirestore) {
        this.headers = new HttpHeaders();
        this.db = aFirestore.firestore;
        this.headers.append("Accept", 'application/json');
        this.headers.append("Content-Type", 'application/json');
        this.requestOptions = { headers: this.headers };
    }

    getEmployees(clientId: string, typeOrder: string, onResolve) {
        let ref;

        switch (typeOrder) {
            case 'asc': //a-z
                ref = this.db
                .collection('users')
                .where('clientId', '==', clientId)
                .orderBy('name', 'asc');    
            break;

            case 'desc': //z-a
                ref = this.db
                .collection('users')
                .where('clientId', '==', clientId)
                .orderBy('name', 'desc');
            break;

            case 'recent'://distante-próximo
                ref = this.db
                .collection('users')
                .where('clientId', '==', clientId)
                .orderBy('createdAt', 'desc');
            break;

            case 'oldest': //próximo-distante
                ref = this.db
                .collection('users')
                .where('clientId', '==', clientId)
                .orderBy('createdAt', 'asc');
            break;
        }

        ref
        .get()
        .then((values) => {
            let users = [];
            if (values.size >= 1) {
                values.forEach(element => {
                    users.push(element.data());
                });
            }

            console.log(users)
            onResolve(users);
        });
    }

    createEmployee(user, photo, onResolve) {
        this.auth.createEmployee(user, photo).then((newUser) => {
            onResolve(newUser)
        })
            .catch((error) => {
                onResolve(error)
            })
    }

    editEmployee(user: User, employeeId: string, onResolve) {
        this.auth.updateUserAuth(employeeId, user, (status) => {
            if (status['result'] == true) {
                this.db
                    .collection("users").doc(employeeId).update({
                        name: user.$name,
                        email: user.$email,
                        photoUrl: user.$photoUrl,
                        company: user.$company,
                        title: user.$title,
                        description: user.$description,
                        language: user.$language,
                        clientId: user.$clientId,
                        uid: user.$uid
                    })
                    .then((data) => {
                        onResolve({
                            code: 200,
                            message: 'success',
                            result: data
                        });
                    })
                    .catch((error) => {
                        onResolve({
                            code: 500,
                            message: 'error',
                            result: error
                        });
                    });
            } else {
                onResolve({
                    code: 500,
                    message: 'error-change-password',
                    result: null
                })
            }
        })
    }

    deleteEmployee(user, onResolve) {
        this.db.collection("users").doc(user.uid).delete()
            .then((ok) => {
                this.auth.removeUserAuth(user.uid, (data) => {});
                onResolve(true);
            })
            .catch((eer) => {
                onResolve(false);
            });
    }
}