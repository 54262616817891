export class WordCloud {
    private uid: string;
    private answer: string;
    private total: number;
    private title: string;
    private subtitle: string;
    private max_characters: number;
    private visibility: boolean;
  
    constructor() {
      this.answer = '';
      this.total = 1;
      this.title = ''
      this.subtitle = '';
      this.max_characters = 10;
      this.visibility = true;
    }
  
    get _uid(): string {
      return this.uid;
    }
  
    set _uid(value: string) {
      this.uid = value;
    }
  
    get _answer(): string {
      return this.answer;
    }
  
    set _answer(value: string) {
      this.answer = value;
    }
  
    get _total(): number {
      return this.total;
    }
  
    set _total(value: number) {
      this.total = value;
    }
  
    get _title(): string {
      return this.title;
    }
  
    set _title(value: string) {
      this.title = value;
    }
  
    get _subtitle(): string {
      return this.subtitle;
    }
  
    set _subtitle(value: string) {
      this.subtitle = value;
    }
  
    get _max_characters(): number {
      return this.max_characters;
    }
  
    set _max_characters(value: number) {
      this.max_characters = value;
    }
  
    get _visibility(): boolean {
      return this.visibility;
    }
  
    set _visibility(value: boolean) {
      this.visibility = value;
    }
  }
  