import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Attendee } from 'src/app/models/attendees';
import { DbAttendeesProvider } from 'src/app/providers/database/db-attendees';
import { Name } from 'src/app/models/name';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { eventCustomField } from 'src/app/models/eventCustomField';
import { eventCustomFieldAnswerOption } from 'src/app/models/eventCustomFieldAnswerOption';
import { Group } from 'src/app/models/group';
import { DbGroupsProvider } from 'src/app/providers/database/db-groups';
import { DbSpeakersProvider } from 'src/app/providers/database/db-speakers';
import { TypeUser } from 'src/app/enums/typeUser';
import { GlobalService } from 'src/app/providers/global/global.service';
import { DbEventsProvider } from 'src/app/providers/database/db.events';
import { Languages } from 'src/app/models/languages';
import { NameGroup } from 'src/app/enums/name-group';
import { ModuleAttendee } from 'src/app/models/modules/module-attendees';
import { DbDocuments } from 'src/app/providers/database/db-documents';
import { union } from '@ngrx/store';
import { NameModule } from 'src/app/models/name-module';
declare let $: any;

@Component({
    selector: 'app-edit-attendee',
    templateUrl: './edit-attendee.component.html',
    styleUrls: ['./edit-attendee.component.scss']
})

export class EditAttendeeComponent implements OnInit {
    @ViewChild('successSwal') public successSwal: SwalComponent;
    @ViewChild('errorSwal') public errorSwal: SwalComponent;
    @ViewChild('errorEmailSpeakerSwal') public errorEmailSpeakerSwal: SwalComponent;

    imageChangedEvent: any;
    croppedImage: string;

    formValidation: FormGroup;

    public eventId: string = null;
    public moduleId: string = null;
    public attendeeId: string = null;
    public attendee;
    public loader: boolean = false;
    cropperShow: boolean = false;
    data: any = {};
    sizeImgMax: number = 2097152;
    invalidSizeImg: boolean = false;
    finalFile: any = null;
    fileName: string = null;

    // public listCustomFields: Array<eventCustomField> = [];
    public listCustomFields: any[] = [];
    public listCustomFieldsOptions: Array<eventCustomFieldAnswerOption> = [];
    public fieldsCustomOptions;
    public documentOnlyOnProfil: boolean;
    public documentProfilNParticipants: boolean;
    public docChoice: string;
    customUser: eventCustomField[] = [];

    // GROUPS FILTER
    listGroup: Array<Group>
    selectedGroup: Array<Group> = [];
    filteredListGroups = [];
    queryGroup = '';
    errorFormGroup: boolean = false;
    blockDouble: boolean = false;

    // DOCUMENTS FILTER
    listDocument: Array<any> = [];
    selectedDocument: Array<any> = [];
    filteredListDocuments = [];
    queryDocument = '';
    errorFormDocument: boolean = false;

    event: any;
    languages: Languages = null //event languages
    // get the language of the user.
    public userLanguage: string
    principalEventLanguageFormated: string = 'PtBR';
    private moduleName: any = null


    constructor(
        private fb: FormBuilder,
        private route: ActivatedRoute,
        private dbAttendee: DbAttendeesProvider,
        private router: Router,
        private dbGroups: DbGroupsProvider,
        private global: GlobalService,
        private dbEvent: DbEventsProvider,
        private dbDocuments: DbDocuments,
        private cdr: ChangeDetectorRef
    ) {
        this.eventId = this.route.parent.params['_value']['uid'];
        this.moduleId = this.route.snapshot.params['moduleId'];
        this.attendeeId = this.route.snapshot.params['attendeeId'];

        this.formValidation = fb.group({
            'photoUrl': [null],
            'id': [null, Validators.compose([Validators.required, Validators.maxLength(100)])],
            'name': [null, Validators.compose([Validators.required, Validators.maxLength(1000)])],
            'email': [null, Validators.compose([Validators.required, Validators.email, Validators.maxLength(200)])],
            'emailRecovery': [null, Validators.compose([Validators.email, Validators.maxLength(200)])],
            'title_ptBR': [""],
            'title_enUS': [""],
            'title_esES': [""],
            'title_frFR': [""],
            'title_deDE': [""],
            'company': [null, Validators.compose([Validators.maxLength(1000)])],
            'website': [null, Validators.compose([Validators.maxLength(500)])],
            'facebook': [null, Validators.compose([Validators.maxLength(1000)])],
            'instagram': [null, Validators.compose([Validators.maxLength(500)])],
            'linkedin': [null, Validators.compose([Validators.maxLength(500)])],
            'twitter': [null, Validators.compose([Validators.maxLength(500)])],
            'description_ptBR': [""],
            'description_enUS': [""],
            'description_esES': [""],
            'description_frFR': [""],
            'description_deDE': [""],
            'groups': [null],
            'documents': [null],
            'points': [null]
        })
    }

    ngOnInit() {
        this.getAttendee();
        this.getGroups();
        this.startEvent();
        this.getUserLanguage();
        this.getModule();
        this.getDocuments();
    }

    // get the language of the user.
    getUserLanguage() {
        this.global.getLanguage((language) => {
            this.userLanguage = language
        })
    }

    startEvent() {
        this.dbEvent.getEvent(this.eventId, (event) => {
            this.event = event;
            this.principalEventLanguageFormated = this.convertLangFormat(this.event.language);
            this.languages = event.languages;
        });
    }

    getModule() {
        this.dbAttendee.attendeeModule(this.moduleId, (module: ModuleAttendee) => {
            if (typeof module !== 'undefined' && module !== null) {
                this.moduleName = module.name
            }
        })
    }

    getAttendee() {
        this.dbAttendee.getAttendee(this.eventId, this.moduleId, this.attendeeId, (data) => {
            this.attendee = data.result;
            
            // groups
            for (const uid in this.attendee['groups']) {
                let index = this.checkIndexExists(this.selectedGroup, uid);

                if (index >= 0) {
                    this.selectedGroup[index] = this.attendee['groups'][uid];
                } else {
                    this.selectedGroup.push(this.attendee['groups'][uid]);
                }
            }

            // Empty the array of DOcuments before push on it
            this.selectedDocument = [];
            for (const doc in this.attendee['documents']) {
                // console.log(this.selectedDocument)
                this.selectedDocument.push(this.attendee['documents'][doc]);
            }

            // Document visibility
            this.docChoice = this.attendee['userDocVisibility'] ? this.attendee['userDocVisibility'] : 'Profil';
            console.log(this.docChoice)

            // fill fields with custom fiels
            this.getCustomFields();


            this.setFormValues();


            // this.cdr.detectChanges();
        });
    }

    getDocuments() {
        this.dbDocuments.getEventDocuments(this.eventId, (documents: Array<any>) => {
            this.listDocument = [];
            this.listDocument = documents;
        });
    }


    checkIndexExists(array, itemId) {
        return array.map(function (e) { return e.uid; }).indexOf(itemId);
    }

    getGroups() {
        this.dbGroups.searchModulesAndGroups(this.eventId, (result) => {
            this.listGroup = result['groups'];
        });
    }

    getCustomFields() {
        this.dbAttendee.getFieldOptionsCustom(this.moduleId, (fields) => {
            this.fieldsCustomOptions = fields;
            if (this.attendee.firstAccess && !this.attendee.edited_profile) {
                console.log('first time edit')
                this.dbAttendee.getCustomFieldsFirstTime(this.moduleId, this.attendee.uid, (fieldsCustom) => {
                    this.listCustomFields = fieldsCustom.result;
        
                    this.dbAttendee.getCustomFieldsOfUserFirstTime(this.moduleId, this.attendee.uid, (listCustom) => {
                        // this.customUser = listCustom.result;
                        
                        for (const aux of listCustom.result) {
                            if (this.fieldsCustomOptions[aux.uid]) {
                                if (typeof aux.textValue[this.principalEventLanguageFormated] === 'number') {
                                    aux.textValue[this.principalEventLanguageFormated] =
                                    aux.textValue[this.principalEventLanguageFormated].toString();
                                }
                                const position = this.fieldsCustomOptions[aux.uid].order;
                                // this.listCustomFields[position] = aux;
                                this.customUser[position] = aux;
                            }
                        }
                    });
                });
            }
            else {
                console.log('Not first time edit')
                this.dbAttendee.getCustomFields(this.eventId, this.moduleId)
                    .then((customFields) => {
                        // this.listCustomFields = fieldsCustom.result;
                        this.listCustomFields = customFields;

                        this.dbAttendee.getCustomFieldsOfUser(this.moduleId, this.attendee.uid, (listCustom) => {
                            listCustom.forEach(aux => {
                                if (typeof aux.textValue[this.principalEventLanguageFormated] === 'number') {
                                    aux.textValue[this.principalEventLanguageFormated] =
                                        aux.textValue[this.principalEventLanguageFormated].toString();
                                }
                            });
                            this.customUser = listCustom;
                            console.log(this.customUser)
                        });
                    });
            }
    })
      
                // if (listCustom) {
                //     for (let aux of listCustom) {
                //         if (aux.uid && this.listCustomFields.some((field) => field.uid === aux.uid)) {
                //             let index = this.listCustomFields.indexOf(this.listCustomFields.find((field) => field.uid === aux.uid));
                //         }
                //     } 
                // } 
                

                // for (let i = 0; i < this.listCustomFields.length; i++) {
                //     let custom = this.listCustomFields[i];

                //     if (custom.type == 'select') {
                //         this.dbAttendee.getCustomFieldOptions(this.moduleId, custom.uid, (listOptions) => {
                //             this.listCustomFieldsOptions[i] = listOptions;
                //         })
                //     } else {
                //         this.listCustomFieldsOptions[i] = null;
                //     }
                // }

    }

    setFormValues() {
        if (this.customUser && this.listCustomFields) {
            for (const custom of this.customUser) {
                if (this.listCustomFields.some((cus) => (cus.uid === custom.uid))) {
                    // à revoir
                }
            }
        }
       
        this.formValidation.patchValue({
            id: this.attendee.identifier,
            name: this.attendee.name,
            email: this.attendee.email,
            emailRecovery: this.attendee.emailRecovery,
            photoUrl: this.attendee.photoUrl,
            title_ptBR: this.attendee.title.PtBR,
            title_enUS: this.attendee.title.EnUS,
            title_esES: this.attendee.title.EsES,
            title_frFR: this.attendee.title.FrFR,
            title_deDE: this.attendee.title.DeDE,
            company: this.attendee.company,
            website: this.attendee.website,
            facebook: this.attendee.facebook,
            instagram: this.attendee.instagram,
            linkedin: this.attendee.linkedin,
            twitter: this.attendee.twitter,
            description_ptBR: this.attendee.description.PtBR,
            description_enUS: this.attendee.description.EnUS,
            description_esES: this.attendee.description.EsES,
            description_frFR: this.attendee.description.FrFR,
            description_deDE: this.attendee.description.DeDE,
            points: this.attendee.points

            // fill custom fields
            
        })
    }

    editAttendee(formValues) {
        this.loader = true;
        let name = formValues.name.trim();
        let email = formValues.email.toLowerCase();
        let attendee = new Attendee(name, email, 5, this.eventId);
        attendee.$moduleId = this.attendee.moduleId;
        attendee.$uid = this.attendee.uid;
        attendee.$points = formValues.points;
        attendee.$module = { uid: this.moduleId, name: this.moduleName }

        /* important to avoid filling profile everytime the
        attendee is updated */
        attendee.$edited_profile = true;

        if (formValues.emailRecovery) {
            attendee.$emailRecovery = formValues.emailRecovery.toLowerCase();
        } else {
            attendee.$emailRecovery = '';
        }

        attendee.$identifier = this.attendee.identifier
        attendee.$description = {
            PtBR: formValues.description_ptBR.replace(/href="/g, 'class="wysiwyg-link" href="'),
            EnUS: formValues.description_enUS.replace(/href="/g, 'class="wysiwyg-link" href="'),
            EsES: formValues.description_esES.replace(/href="/g, 'class="wysiwyg-link" href="'),
            FrFR: formValues.description_frFR.replace(/href="/g, 'class="wysiwyg-link" href="'),
            DeDE: formValues.description_deDE.replace(/href="/g, 'class="wysiwyg-link" href="'),
        };
        attendee.$company = formValues.company;
        attendee.$title = {
            PtBR: formValues.title_ptBR,
            EnUS: formValues.title_enUS,
            EsES: formValues.title_esES,
            FrFR: formValues.title_frFR,
            DeDE: formValues.title_deDE,
        };
        if (formValues.website !== null && formValues.website !== undefined) attendee.$website = formValues.website;
        attendee.$facebook = formValues.facebook;
        attendee.$instagram = formValues.instagram;
        attendee.$linkedin = formValues.linkedin;
        attendee.$twitter = formValues.twitter;
        attendee.$photoUrl = this.attendee.photoUrl;
        attendee.$customFields = Object.assign(this.listCustomFields, this.customUser);
        

        let changeEmail = false;
        if (this.attendee.email !== attendee.$email) {
            changeEmail = true;
        }

        // groups
        if (this.selectedGroup.length >= 1) {
            for (let index in this.selectedGroup) {
                const group = this.selectedGroup[index];
                attendee.$groups[group['uid']] = Object.assign({}, group)
            }
            this.documentOnlyOnProfil = true;


        }

        // documents
        if (this.selectedDocument.length > 0) {
            console.log(this.docChoice)
            if (this.docChoice === undefined || this.docChoice === '') {
                this.docChoice = 'Profil';
            }

            attendee.$userDocVisibility = this.docChoice;

            for (let index in this.selectedDocument) {
                const document = this.selectedDocument[index];
                attendee.$documents[document['uid']] = Object.assign({}, document);
            }
        }

        // languages
        attendee.$language = this.event.language

        this.dbAttendee.verifyIdentifierValidByAttendee(this.eventId, attendee.$identifier, attendee.$uid, (validIdentifier) => {
            this.dbAttendee.verifyEmailValidByAttendee(attendee.$email, attendee.$uid, (validEmail) => {
                if (this.blockDouble == false) {
                    this.blockDouble = true;
                    if (validIdentifier && validEmail) {
                        this.dbAttendee.editAttendee(this.eventId, this.moduleId, this.moduleName, attendee, this.listCustomFields, this.customUser, this.finalFile, changeEmail, (data) => {
                            if (data.attendee.code == 200) {
                                if (data.customFields.code == 200) {
                                    this.loader = false;
                                    this.successSwal.fire();
                                } else {
                                    this.loader = false;
                                    this.errorSwal.fire();
                                }
                            } else {
                                // erro, e-mail já existe no palestrante
                                this.loader = false;
                                this.errorEmailSpeakerSwal.fire();
                            }
                        })
                    } else {
                        this.loader = false;
                        this.errorSwal.fire();
                    }
                }
            })
        })
    }

    /**
     * On upload image file
     * @param $event 
     */
    onUploadChange($event: any) {
        this.invalidSizeImg = false;
        const file: File = $event.target.files[0];
        this.fileName = $event.target.files[0].name;

        if (file.size < this.sizeImgMax) {
            this.cropperShow = true;
            this.imageChangedEvent = event;
        } else {
            this.invalidSizeImg = true;
        }
    }

    /**
     * On cropped image event
     * @param event 
     */
    imageCropped(event: ImageCroppedEvent) {

        // Get base 64 image
        this.croppedImage = event.base64;
    }

    /**
     * Save profile picture
     */
    saveProfilePicture() {
        if (!this.invalidSizeImg && this.croppedImage) {
            const aux = this.croppedImage.split(',');
            this.finalFile = aux[1];
            this.clearCropper();
            $('#selectPicture').modal('toggle')
        }
    }

    /**
     * Clear cropper data
     */
    clearCropper() {
        this.invalidSizeImg = false;
        this.cropperShow = false;
        this.fileName = null;
    }

    redirectList() {
        this.router.navigate(["event/" + this.eventId + "/attendees/" + this.moduleId]);
    }

    /******************* GROUPS **********************/

    // filter groups
    filterGroups() {
        if (this.queryGroup !== "") {
            this.filteredListGroups = this.listGroup.filter(function (el) {
                return el.name.toLowerCase().indexOf(this.queryGroup.toLowerCase()) > -1;
            }.bind(this));
        } else {
            this.filteredListGroups = [];
        }
    }

    // select track from filtered list
    selectGroup(item) {
        if (this.selectedGroup.length > 0) {
            const index = this.selectedGroup.indexOf(item);
            if (index == -1) {
                this.selectedGroup.push(item);
            }
        } else {
            this.selectedGroup.push(item);
        }
        this.queryGroup = '';
        this.filteredListGroups = [];
    }

    // remove selected location
    removeGroup(item) {
        this.selectedGroup.splice(this.selectedGroup.indexOf(item), 1);
    }

    /******************* DOCUMENTS **********************/

    // filter documents
    filterDocuments() {
        if (this.queryDocument !== "") {
            this.filteredListDocuments = this.listDocument.filter(function (el) {
                return el.name[this.principalEventLanguageFormated].toLowerCase().indexOf(this.queryDocument.toLowerCase()) > -1;
            }.bind(this));
            this.filteredListDocuments.forEach((docFil) => {
                this.selectedDocument.forEach((docSel) => {
                    if (docFil.name === docSel.name) {
                        this.filteredListDocuments = this.filteredListDocuments.splice(docFil.name, 1);
                    }
                });
            });
        } else {
            this.filteredListDocuments = [];
        }
    }

    // select document from filtered list
    selectDocument(item) {
        if (this.selectedDocument.length > 0) {
            const index = this.selectedDocument.map(function (e) { return e.uid; }).indexOf(item.uid);

            if (index == -1) {
                this.selectedDocument.push(item);
            }
        } else {
            this.selectedDocument.push(item);
        }
        this.queryDocument = '';
        this.filteredListDocuments = [];
    }

    // remove selected document
    removeDocument(item) {
        this.selectedDocument.splice(this.selectedDocument.indexOf(item), 1);
    }



    convertLangFormat(lang) {
        let formatedLang;
        switch (lang) {
            case 'pt_BR': {
                formatedLang = 'PtBR'
                break;
            }
            case 'en_US': {
                formatedLang = 'EnUS';
                break;
            }
            case 'es_ES': {
                formatedLang = 'EsES';
                break;
            }
            case 'fr_FR': {
                formatedLang = 'FrFR';
                break;
            }
            case 'de_DE': {
                formatedLang = 'DeDE';
                break;
            }
        }
        return formatedLang;
    }


    updateDocVisibility() {
        // Initialize the docVisibility field for the first time a document is added to the event
        // 'Profil' is the default choice

        console.log(this.docChoice)

        if (!this.docChoice || this.docChoice === undefined || this.docChoice === '') {
            this.docChoice = 'Profil';
        }

        this.dbAttendee.updateModuleDocumentsVisibility(this.moduleId, { docVisibility: this.docChoice }, (status) => {
            if (status === true) {
                console.log('success');
            } else {
                console.log('failed to update docVisibility');
            }
        });
    }

}
