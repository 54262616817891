export class Image {
    uid: string;
    name: string;
    storageId: string;
    url: string;
    type: string;
    createdAt: number;
    total_access: number;

    constructor() {
        this.total_access = 0;
    }
}