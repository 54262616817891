import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DbCheckinProvider } from '../providers/database/db-checkin';
import { Checkin } from '../models/checkin';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { FormatedEventLanguageService } from '../providers/formated-event-language/formated-event-language.service';
import { DbEventsProvider } from '../providers/database/db.events';
import { Event } from '../models/event';

@Component({
  selector: 'app-realtime-checkin',
  templateUrl: './realtime-checkin.component.html',
  styleUrls: ['./realtime-checkin.component.scss']
})
export class RealtimeCheckinComponent implements OnInit {
  moduleId: string = null;
  checkinId: string = null;
  checkin: Checkin = null;
  lastsCheckins: Array<any> = [];
  totalPresent: number = 0;
  totalEvent: number = 0;

  refCheckin: any = null;
  checkinDocument: AngularFirestoreDocument<any> = null;
  refCheckinRealtime: any = null;
  checkinRealtimeCollection: AngularFirestoreCollection<any> = null;
  refAttendee: any = null;
  attendeeDocument: AngularFirestoreDocument<any> = null;
  allowFooter: boolean = false;
  allowUserName: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private dbCheckin: DbCheckinProvider,
    private afs: AngularFirestore,
    private dbEvent: DbEventsProvider,
    public formatedLanguage: FormatedEventLanguageService

  ) {
    this.moduleId = this.route.snapshot.params['moduleId'];
    this.checkinId = this.route.snapshot.params['checkinId'];
    this.loadCheckin();
  }

  ngOnInit() {
  }

  // load the checkin
  loadCheckin() {
    this.checkinDocument = this.afs.collection('modules').doc(this.moduleId).collection('checkins').doc(this.checkinId);
    this.refCheckin = this.checkinDocument.valueChanges().subscribe((data: any) => {
      this.checkin = data;
      this.allowFooter = this.checkin.allowFooter;
      this.allowUserName = this.checkin.allowUsername;
      
      // get all attendees of the event
      if (this.allowFooter == true) {
        this.getTotalAttendeesEvent(this.checkin.eventId);
      }

      // get checked attendees in order
      this.realTimeCheckins();
      this.getEvent(this.checkin.eventId)
    });
  }

  // get total attendees of the event number
  getTotalAttendeesEvent(eventId) {
    this.dbCheckin.getEventTotalAttendeesNumber(eventId, (total) => {
      this.totalEvent = total;
    });
  }

  getEvent(eventId) {
    this.dbEvent.getEvent(eventId, (event: Event) => {
      if (typeof event !== 'undefined' && event !== null) {
        this.formatedLanguage.convertLangFormat(event.language);
      }
    });
  }

  // get checked attendees in order to display
  realTimeCheckins() {
    this.checkinRealtimeCollection = this.afs.collection('modules').doc(this.moduleId).collection('checkins').doc(this.checkinId).collection('attendees', ref => ref.where('checkinStatus', '==', true).orderBy('time', 'desc'));
    this.refCheckinRealtime = this.checkinRealtimeCollection.valueChanges().subscribe((data: any) => {
      this.lastsCheckins = []

      if (data.length <= 0) { this.lastsCheckins = []; }
      
      if (data.length <= this.totalPresent || data.length >= this.totalPresent) {
        this.lastsCheckins = data
        this.totalPresent = data.length
      }
    });
  }

  // close angular fire connections
  ngOnDestroy() {
    this.refCheckin.unsubscribe();
    this.refCheckinRealtime.unsubscribe();
    this.refAttendee.unsubscribe();
  }
}
