export class Notification {
    uid: string;
    moduleId: string;
    eventId: string;
    headings: Object;
    contents: Object;
    descriptions: Object;
    createdAt: number;
    scheduled: boolean;
    scheduled_date: any;
    delivery_date: any;
    highlight_picture: string;
    icon: string;
    params: Object;
    url: string;
    users_ids: Array<string>;
    users_emails: Array<string>;
    send_to: string;
    groups_ids: Array<string>;
    principal_language: string;
    notificationId: string;
    type: string = '0';
    forModule: number = -1;
    constructor() {
        this.uid = null;
        this.moduleId = null;
        this.eventId = null;
        this.headings = {
            en: '',
            es: '',
            de: '',
            fr: '',
            pt: ''
        }
        this.contents = {
            en: '',
            es: '',
            de: '',
            fr: '',
            pt: ''
        }
        this.descriptions = {
            'en-US': '',
            'es-ES': '',
            'de-DE': '',
            'fr-FR': '',
            'pt-BR': ''
        }
        this.createdAt = Date.now();
        this.scheduled = false;
        this.scheduled_date = null;
        this.delivery_date = null;
        this.highlight_picture = '';
        this.icon = '';
        this.params = {};
        this.url = '';
        this.users_ids = [];
        this.users_emails = [];
        this.send_to = 'all';
        this.groups_ids = [];
        this.principal_language = 'en_US';
    }
}
