import { Component, NgModule, OnInit, HostListener } from '@angular/core';

import { FormGroup, FormControl } from '@angular/forms';
import { FilterPipe } from '../../pipes/filter.pipe';
import { AuthService } from '../../providers/auth/auth.service';
import { DbAdminUserProvider } from 'src/app/providers/database/db-admin-user';
import { GlobalService } from '../../providers/global/global.service';
import { environment } from '../../../environments/environment';
import { TypeUser } from 'src/app/enums/typeUser';
import { Router } from '@angular/router';

declare let $: any;

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
    providers: [DbAdminUserProvider]
})

@NgModule({
    declarations: [FilterPipe],
})

export class DashboardComponent implements OnInit {
    public navLogo: string = environment.platform.navLogo;
    public license: string = environment.platform.license;
    public formTerms: FormGroup;
    public formPrivacy: FormGroup;

    public currentUserType = -1;
    public displayName: string = null;
    public photoUrl: string = null;
    public userUid: string = null;
    public userType: number;

    constructor(private auth: AuthService, private dbAdminUser: DbAdminUserProvider, private global: GlobalService, private router: Router) {
        this.formTerms = new FormGroup({
            terms_use: new FormControl('')
        });

        this.formPrivacy = new FormGroup({
            privacy: new FormControl('')
        });
    }

    ngAfterContentChecked() {
        this.displayName = this.global.displayName;
        this.photoUrl = this.global.photoUrl;
        this.userUid = this.global.userId;
        this.userType = this.global.userType;
        if (this.userType == TypeUser.CLIENT || this.userType == TypeUser.EMPLOYEE) this.router.navigate(['/dash']);
    }

    ngOnInit() {
        $(document).ready(function () {
            // Menu Trigger
            $('#menuToggle').on('click', function (event) {
                var windowWidth = $(window).width();
                if (windowWidth < 1010) {
                    $('body').removeClass('open');
                    if (windowWidth < 760) {
                        $('#left-panel').slideToggle();
                    } else {
                        $('#left-panel').toggleClass('open-menu');
                    }
                } else {
                    $('body').toggleClass('open');
                    $('#left-panel').removeClass('open-menu');
                }

            });


            $(".menu-item-has-children.dropdown").each(function () {
                $(this).on('click', function () {
                    var $temp_text = $(this).children('.dropdown-toggle').html();
                    $(this).children('.sub-menu').prepend('<li class="subtitle">' + $temp_text + '</li>');
                });
            });


            // Load Resize 
            $(window).on("load resize", function (event) {
                var windowWidth = $(window).width();
                if (windowWidth < 1010) {
                    $('body').addClass('small-device');
                } else {
                    $('body').removeClass('small-device');
                }

            });

        });
    }


    redirectDashboard() {
        if (this.userType == TypeUser.GOD || this.userType == TypeUser.SUPERGOD) {
            this.router.navigate(['/dashboard']);
        } else {
            this.router.navigate(['/dash']);
        }
    }

    logoutUser() {
        this.auth.logoutUser();
    }

}
