import { Component, OnInit, ViewChild } from '@angular/core';
import { StorageService } from 'src/app/providers/storage/storage.service';
import { UUID } from 'angular2-uuid';
import { ActivatedRoute } from '@angular/router';
import { DbEventsProvider } from 'src/app/providers/database/db.events';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';

@Component({
  selector: 'app-logo-banner',
  templateUrl: './logo-banner.component.html',
  styleUrls: ['./logo-banner.component.scss']
})
export class LogoBannerComponent implements OnInit {
  @ViewChild('successSwal') public successSwal: SwalComponent;
  @ViewChild('errorSwal') public errorSwal: SwalComponent;
  public logo = null;
  public banner = null;
  public icon = null;
  eventId: string = null;
  event;
  bannerLoading: boolean = false;
  logoLoading: boolean = false;
  iconLoading: boolean = false;
  loader: boolean = true;
  showInMenu = true;

  constructor(
    private storage: StorageService,
    private route: ActivatedRoute,
    private dbEvent: DbEventsProvider,
  ) {
    this.eventId = this.route.parent.params['_value']['uid'];
  }

  ngOnInit() {
    this.startEvent();
  }

  startEvent() {
    this.dbEvent.getEvent(this.eventId, (event) => {
      this.event = event;
      this.showInMenu = this.event.visibiltyBanner !== undefined ? this.event.visibiltyBanner : false;
      this.loader = false;
    });
  }

  onUploadChangeLogo($ev) {
    this.logoLoading = true;
    this.logo = $ev.srcElement.files[0];
    let uid = UUID.UUID();
    this.storage.logoPicture(this.logo, uid, this.eventId, (url) => {
      if (url) {
        this.dbEvent.updateEventLogo(this.eventId, this.event.logo['uid'], url, uid, (status) => {
          if (status == true) {
            // ok
            this.successSwal.fire();
            this.logoLoading = false;
          } else {
            // failed
            this.errorSwal.fire();
            this.logoLoading = false;
          }
        });
      }
    });
  }

  onUploadChangeTouchIcon($ev) {
    this.iconLoading = true;
    this.icon = $ev.srcElement.files[0];
    let uid = UUID.UUID();
    this.storage.touchIconPicture(this.icon, uid, this.eventId, (url) => {
      if (url) {
        let oldId;
        if (typeof this.event.webApp.touchIcon['uid'] !== 'undefined') { oldId = this.event.webApp.touchIcon['uid']; }
        this.dbEvent.updateEventIcon(this.eventId, oldId, url, uid, (status) => {
          if (status == true) {
            // ok
            this.successSwal.fire();
            this.iconLoading = false;
          } else {
            // failed
            this.errorSwal.fire();
            this.iconLoading = false;
          }
        });
      }
    });
  }

  onUploadChangeBanner($ev) {
    this.bannerLoading = true;
    this.banner = $ev.srcElement.files[0];
    let uid = UUID.UUID();
    this.storage.bannerPicture(this.banner, uid, this.eventId, (url) => {
      if (url) {
        this.dbEvent.updateEventBanner(this.eventId, this.event.banner['uid'], url, uid, (status) => {
          if (status == true) {
            // ok
            this.successSwal.fire();
            this.bannerLoading = false;
          } else {
            // failed
            this.errorSwal.fire();
            this.bannerLoading = false;
          }
        });
      }
    });
  }

//   onUpdateVisibility(){
//     console.log(this.showInMenu)
//     this.showInMenu = !this.showInMenu
//     this.dbEvent.updateVisibiltyBanner(this.eventId, this.showInMenu, status => {
//       if (status == true) {
//         // ok
//         this.successSwal.fire();
//         this.bannerLoading = false;
//       } else {
//         // failed
//         this.errorSwal.fire();
//         this.bannerLoading = false;
//       }
//     })
//   }
}
