import { Injectable } from "@angular/core";
import { environment } from '../../environments/environment';

// import { environment } from '../../environments/environment.b3app'
// import { environment } from '../../environments/environment.ceuapp'

@Injectable()

export class PathApi {
    // BASE URL FROM ENVIRONMENT IN USE
    static baseUrl = environment.platform.apiBaseUrl;


    // AUTHENTICATION
    static authDeleteUser = 'authDeleteUser';
    static authUpdateUser = 'authUpdateUser';
    static authCreateEmployee = 'authCreateEmployee';
    static authClaimsUser = 'authClaimsUser';
    static authClaimsUsers = 'authClaimsUsers';
    static authCreateUser = 'authCreateUser';
    static authSetUserClaims = 'authSetUserClaims';
    static authUpdatePassword = 'authUpdatePassword';
    static authVerifyEmailExists = 'authVerifyEmailExists';

    // EMAIL SERVICE
    static emailsSendToUser = 'emailsSendToUser';
    static emailsResetPasswordUser = 'emailsResetPasswordUser';
    static emailsRoomPairInvite = 'emailsRoomPairInvite';
    static emailsRoomPairAccept = 'emailsRoomPairAccept';
    static emailsRoomPairDecline = 'emailsRoomPairDecline';

    // GENERAL TERMS
    static dbGeneralTermsEditPrivacy = 'dbGeneralTermsEditPrivacy';
    static dbGeneralTermsEditTermsOfUse = 'dbGeneralTermsEditTermsOfUse';
    static dbGeneralTermsGetPrivacy = 'dbGeneralTermsGetPrivacy';
    static dbGeneralTermsGetTermsOfUse = 'dbGeneralTermsGetTermsOfUse';

    // LOGS
    static dbLogsCreateLog = 'dbLogsCreateLog';
    static dbLogsGetLog = 'dbLogsGetLog';
    static dbLogsGetAllLogs = 'dbLogsGetAllLogs';

    // SUPERGODS
    static dbUserGetSupergods = 'dbUserGetSupergods';

    //GODS
    static dbUserGetGods = 'dbUserGetGods';

    //CLIENT
    static dbUserGetClients = 'dbUserGetClients';
    static dbUserClientCheckClient = 'dbUserClientCheckClient';

    // EMPLOYEE
    static dbUserClientGetEmployees = 'dbUserClientGetEmployees?clientId=';
    static dbUserClientEditEmployee = 'dbUserClientEditEmployee';

    static authClientDelete = 'authClientDelete';
    static dbEventGetEvent = 'dbEventGetEvent?eventId=';

    // USER
    static dbUserGetUser = 'dbUserGetUser?userId=';
    static dbGetUserByEmail = 'dbUserGetByEmail';
    static dbUserDeleteAndRemakeUser = 'dbUserDeleteAndRemakeUser';

    //ATTENDEE
    static dbAttendeesGetAttendee = 'dbAttendeesGetAttendee';
    static dbAttendeesGetAttendees = 'dbAttendeesGetAttendees';
    static dbAttendeeCreate = 'dbAttendeesCreate';
    static dbAttendeeEdit = 'dbAttendeesEdit';
    static dbAttendeeDelete = 'dbAttendeesDelete';
    static dbAttendeeChangeOrderItems = 'dbAttendeesChangeOrderItems';
    static dbAttendeeImportAttendee = 'dbAttendeesImportAttendee';
    static dbAttendeesExportAttendees = 'dbAttendeesExportAttendees';
    static dbAttendeesExportCheckin = 'dbAttendeesExportCheckin';
    
    static dbAttendeesDeleteAllCheckin = 'dbAttendeesDeleteAllCheckin';

    //SPEAKERS
    static dbSpeakerDelete = 'dbSpeakersDelete';
    static dbSpeakerImportSpeaker = 'dbSpeakersImportSpeaker';
    static dbSpeakerExportSpeakers = 'dbSpeakersExportSpeakers';

    //CUSTOM-FIELD-ATTENDEE
    static dbCustomFieldAttendeeDelete = 'dbCustomFieldAttendeeDelete';
    static dbCustomFieldAttendeeCreate = 'dbCustomFieldAttendeeCreate';
    static dbCustomFieldAttendeeEdit = 'dbCustomFieldAttendeeEdit';

    //CUSTOM-FIELD-ATTENDEE
    static dbCustomFieldSpeakerDelete = 'dbCustomFieldSpeakerDelete';
    static dbCustomFieldSpeakerCreate = 'dbCustomFieldSpeakerCreate';
    static dbCustomFieldSpeakerEdit = 'dbCustomFieldSpeakerEdit';

    // EVENT
    static dbEventEventsB3 = 'dbEventEventsB3';
    static dbEventEventsClient = 'dbEventEventsClient';
    static dbEventDelete = 'dbEventDelete';
    static dbEventCheckShortcode = 'dbEventCheckShortcode';
    static dbEventCreate = 'dbEventCreate';
    static dbEventUpdate = 'dbEventUpdate';
    static dbEventTimezone = 'dbEventGetTimezone?eventId=';

    // schedule
    static dbScheduleGetIdentifiers = 'dbScheduleGetIdentifiers?eventId=';
    static dbScheduleCreate = 'dbScheduleCreate';
    static dbScheduleDelete = 'dbScheduleDelete';
    static dbScheduleImportSessions = 'dbScheduleImportSessions'
    static dbScheduleDeleteSessions = 'dbScheduleDeleteSessions'
    static dbScheduleGetSessionsExport = 'dbScheduleGetSessionsExport'

    // personal
    static dbPersonalScheduleExport = 'dbPersonalScheduleExport'
    static dbPersonalScheduleGetAllSessions = 'dbPersonalScheduleGetAllSessions'


    // schedule tracks
    static dbScheduleDeleteTrack = 'dbScheduleDeleteTrack';
    static dbScheduleEditTrack = 'dbScheduleEditTrack';
    static dbScheduleImportTracks = 'dbScheduleImportTracks';

    // WIDGETS
    static dbWidgetsGetAll = 'dbWidgetsGetAll?eventId=';
    static dbWidgetsGetByModule = 'dbWidgetsGetByModule?moduleId=';
    static dbWidgetsCreateItem = 'dbWidgetsCreateItem';
    static dbWidgetsEditItem = 'dbWidgetsEditItem';
    static dbWidgetsDeleteItem = 'dbWidgetsDeleteItem';

    // LOCATIONS
    static dbLocationsDeleteLocation = 'dbLocationsDeleteLocation';
    static dbLocationsImportLocations = 'dbLocationsImportLocations';
    static dbLocationsDeleteAll = 'dbLocationsDeleteAll';

    // GROUPS
    static dbGroupsEditGroup = 'dbGroupsEditGroup';
    static dbGroupsDeleteGroup = 'dbGroupsDeleteGroup';
    static dbGroupsImportGroup = 'dbGroupsImportGroup';
    static dbGroupsDeleteAll = 'dbGroupsDeleteAll';


    //SURVEY
    static dbSurveyExportSurvey = 'dbSurveyExportSurvey';
    static dbSurveyExportQuestionSurvey = 'dbSurveyExportQuestionSurvey';
    static dbSurveyClearAll = 'dbSurveyClearAll';
    static dbSurveyClearResultSurvey = 'dbSurveyClearResultSurvey';
    static dbSurveyClearResultQuestion = 'dbSurveyClearResultQuestion';
    static dbSurveyDeleteSurvey = 'dbSurveyDeleteSurvey';
    static dbSurveyDeleteQuestion = 'dbSurveyDeleteQuestion';

    //QUIZ
    static dbQuizExportQuiz = 'dbQuizExportQuiz';
    static dbQuizExportQuestionQuiz = 'dbQuizExportQuestionQuiz';
    static dbQuizClearAll = 'dbQuizClearAll';
    static dbQuizClearResultQuiz = 'dbQuizClearResultQuiz';
    static dbQuizClearResultQuestion = 'dbQuizClearResultQuestion';
    static dbQuizDeleteQuiz = 'dbQuizDeleteQuiz';
    static dbQuizDeleteQuestion = 'dbQuizDeleteQuestion';

    //TRAINING
    static dbTrainingExportTraining = 'dbTrainingExportTraining';
    static dbTrainingExportQuestionTraining = 'dbTrainingExportQuestionTraining';
    static dbTrainingClearAll = 'dbTrainingClearAll';
    static dbTrainingClearResultTraining = 'dbTrainingClearResultTraining';
    static dbTrainingClearResultQuestion = 'dbTrainingClearResultQuestion';
    static dbTrainingDeleteTraining = 'dbTrainingDeleteTraining';
    static dbTrainingDeleteQuestion = 'dbTrainingDeleteQuestion';

    //SESSION FEEDBACK
    static dbSessionFeedbackExportFeedback = 'dbSessionFeedbackExportFeedback';
    static dbSessionFeedbackExportQuestionFeedback = 'dbSessionFeedbackExportQuestionFeedback';
    static dbSessionFeedbackClearAll = 'dbSessionFeedbackClearAll';
    static dbSessionFeedbackClearResultFeedback = 'dbSessionFeedbackClearResultFeedback';
    static dbSessionFeedbackClearResultQuestion = 'dbSessionFeedbackClearResultQuestion';
    static dbSessionFeedbackDeleteFeedback = 'dbSessionFeedbackDeleteFeedback';
    static dbSessionFeedbackDeleteQuestion = 'dbSessionFeedbackDeleteQuestion';

    //ASK QUESTION
    static dbAskQuestionExport = 'dbAskQuestionExport';
    static dbAskQuestionExportGeral = 'dbAskQuestionExportGeral';
    static dbAskQuestionDelete = 'dbAskQuestionDelete';
    static dbAskQuestionClearResults = 'dbAskQuestionClearResults';
    static dbAskQuestionClearResultsSession = 'dbAskQuestionClearResultsSession';

    //WORD CLOUD
    static dbWordCloudClearResults = 'dbWordCloudClearResults';
    static dbWordCloudDelete = 'dbWordCloudDelete';
    static dbWordCloudExport = 'dbWordCloudExport';

    // CHECKIN
    static dbCheckinCreate = 'dbCheckinCreate';
    static dbCheckinAttendeeCheckinStatus = 'dbCheckinCheckinStatus';
    static dbCheckinStatuses = "dbCheckinStatuses";

    // BULK DATA FLOW
    static dbBulkDataFlowUpdate = 'dbBulkDataFlowUpdate';
}