import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Document } from 'src/app/models/document';
import { StorageService } from '../storage/storage.service';
import { TypeModule } from 'src/app/enums/type-module';
import { DocumentsFolder } from 'src/app/models/documents-folder';
import { NameModule } from 'src/app/models/name-module';
import { Image } from 'src/app/models/image';
import { PathStorage } from 'src/app/paths/path-storage';

@Injectable({
    providedIn: 'root'
})
export class DbDocuments {

    constructor(
        private aFirestore: AngularFirestore,
        private storage: StorageService
    ) {}

    getEventDocuments(eventId: string, onResolve) {
        let db = this.aFirestore.firestore;

        db
            .collection('events')
            .doc(eventId)
            .collection('documents')
            .get()
            .then((values) => {
                let documents = [];
                if (values.size >= 1) {
                    values.forEach(element => {
                        let document = element.data();
                        documents.push(document);
                    });
                }
                onResolve(documents);
            })
            .catch((err) => {
                console.log();
                onResolve([]);
            });
    }

    getDocuments(moduleId: string, folderId: string, typeOrder: string, language, onResolve) {
        let db = this.aFirestore.firestore;
        let ref;
        let orderName = `name.${language}`;
        switch (typeOrder) {
            case 'asc'://a-z
                ref = db.collection('modules').doc(moduleId).collection('folders').doc(folderId).collection('documents')
                    .orderBy(orderName, 'asc');
                break;

            case 'desc': //z-a
                ref = db.collection('modules').doc(moduleId).collection('folders').doc(folderId).collection('documents')
                    .orderBy(orderName, 'desc');
                break;
            case 'oldest'://antigo-recente
                ref = db.collection('modules').doc(moduleId).collection('folders').doc(folderId).collection('documents')
                    .orderBy('createdAt', 'asc');
                break;

            case 'recent': //recente-antigo
                ref = db.collection('modules').doc(moduleId).collection('folders').doc(folderId).collection('documents')
                    .orderBy('createdAt', 'desc');
                break;
        }

        ref.
            onSnapshot((values) => {
                let documents = [];
                if (values.size >= 1) {
                    values.forEach(element => {
                        documents.push(element.data());
                    });
                }
                onResolve(documents);
            })
    }

    getFolders(moduleId: string, typeOrder: string, onResolve) {
        let db = this.aFirestore.firestore;
        let ref;

        switch (typeOrder) {
            case 'oldest'://antigo-recente
                ref = db.collection('modules').doc(moduleId).collection('folders')
                    .orderBy('createdAt', 'asc');
                break;

            case 'recent': //recente-antigo
                ref = db.collection('modules').doc(moduleId).collection('folders')
                    .orderBy('createdAt', 'desc');
                break;
            case 'custom': // manual ordenation
                ref = db.collection('modules').doc(moduleId).collection('folders')
                    .orderBy('order', 'asc');
        }

        ref
            .onSnapshot((values) => {
                let folders = [];
                if (values.size >= 1) {
                    values.forEach(element => {
                        folders.push(element.data());
                    });
                }
                onResolve(folders);
            });
    }

    getFolder(moduleId: string, folderId: string, onResolve) {
        let db = this.aFirestore.firestore;
        db
            .collection('modules')
            .doc(moduleId)
            .collection('folders')
            .doc(folderId)
            .onSnapshot((value) => {
                onResolve(value.data());
            });
    }

    createFolder(eventId: string, moduleId: string, folder: DocumentsFolder, onResolve) {
        let db = this.aFirestore.firestore;
        let ref = db.collection('modules').doc(moduleId).collection('folders').doc();
        folder.uid = ref.id;
        folder.createdAt = Date.now() / 1000 | 0;
        folder = Object.assign({}, folder);
        folder.name = Object.assign({}, folder.name);
        folder.eventId = eventId;

        ref
            .set(folder)
            .then((set) => {
                onResolve(true);
            })
            .catch((err) => {
                onResolve(false);
            });
    }

    editFolder(moduleId: string, folder: DocumentsFolder, onResolve) {
        let db = this.aFirestore.firestore;
        let ref = db.collection('modules').doc(moduleId).collection('folders').doc(folder.uid);
        folder.name = Object.assign({}, folder.name);

        ref
            .update(folder)
            .then((set) => {
                onResolve(true);
            })
            .catch((err) => {
                onResolve(false);
            });
    }

    // deletes the folders and documents contained therein.
    // deleteFolder(eventId: string, moduleId: string, folderId: string, onResolve) {
    //     let db = this.aFirestore.firestore;
    //     let batch = db.batch()

    //     let ref = db.collection('modules').doc(moduleId).collection('folders').doc(folderId)
    //     batch.delete(ref) //delete folder

    //     ref.get().then((data) => {
    //         let folder = data.data();
    //         let orderRemove = folder.order;
    //         let imageId = folder.folderCoverId;

    //         // exclui a imagem de capa da pasta no firebase storage
    //         this.storage.deleteImg(eventId, moduleId, folderId, imageId, PathStorage.documents);

    //         // get the ducumentos of the paste.
    //         ref.collection('documents').get().then((snapshot) => {
    //             const documents = []

    //             snapshot.forEach((childSnapshot) => {
    //                 const document = childSnapshot.data()
    //                 const documentId = document.uid
    //                 const eventId = document.eventId
    //                 documents.push(document)

    //                 // delete document paths
    //                 let ref1 = db.collection('modules').doc(moduleId).collection('folders').doc(folderId).collection('documents').doc(documentId);
    //                 let ref2 = db.collection('events').doc(eventId).collection('documents').doc(documentId);

    //                 batch.delete(ref1);
    //                 batch.delete(ref2);
    //             })

    //             batch
    //                 .commit()
    //                 .then((_) => {
    //                     // delete files from documents in storage.
    //                     for (const document of documents) {
    //                         const eventId = document.eventId
    //                         const storageId = document.storageId
    //                         this.storage.deleteDocument(eventId, moduleId, folderId, storageId);
    //                     }

    //                     this.reorderFolders(moduleId, orderRemove, (data) => {
    //                         onResolve(true);
    //                     });
    //                 })
    //                 .catch((err) => {
    //                     console.log();
    //                     onResolve(false);
    //                 });
    //         })
    //     })
    // }

    reorderFolders(moduleId, orderRemove, onResolve) {
        console.log('reorderFolders')
        let db = this.aFirestore.firestore;
        let refFolders = db.collection('modules').doc(moduleId).collection('folders');

        refFolders.get().then((data) => {
            if (data.size > 0) {
                let listFolders = [];

                data.forEach(element => {
                    listFolders.push(element.data());
                });

                let cont = 0;
                for (let folder of listFolders) {
                    if (folder.order > orderRemove) {
                        folder.order--;

                        refFolders.doc(folder.uid).update(folder).then(() => {
                            if (cont == listFolders.length - 1) {
                                onResolve(true);
                            }

                            cont++;
                        })
                    } else {
                        if (cont == listFolders.length - 1) {
                            onResolve(true);
                        }

                        cont++;
                    }
                }
            } else {
                onResolve(true);
            }
        })

    }

    getModule(moduleId: string, onResolve) {
        let db = this.aFirestore.firestore;
        db
            .collection('modules')
            .doc(moduleId)
            .onSnapshot((value) => {
                onResolve(value.data());
            });
    }

    changeOrderModule(moduleId: string, typeOrder: string, onResolve) {
        let db = this.aFirestore.firestore;
        db.collection("modules").doc(moduleId).update({ typeOrder: typeOrder })
            .then((_) => {
                onResolve(true);
            })
            .catch((__) => {
                onResolve(false);
            });
    }

    changeOrderItems(moduleId: string, folderId: string, typeOrder: string, onResolve) {
        let db = this.aFirestore.firestore;
        db.collection("modules").doc(moduleId).collection('folders').doc(folderId).update({ orderDocuments: typeOrder })
            .then((_) => {
                onResolve(true);
            })
            .catch((__) => {
                onResolve(false);
            });
    }

    saveDocument(moduleId: string, eventId: string, folderId: string, document: Document, onResolve) {
        let db = this.aFirestore.firestore;
        let ref = db.collection('modules').doc(moduleId).collection('folders').doc(folderId).collection('documents').doc();
        let refEvent = db.collection('events').doc(eventId).collection('documents').doc(ref.id);
        let batch = db.batch();

        document.uid = ref.id;
        document = Object.assign({}, document);
        document.name = Object.assign({}, document.name);

        batch.set(ref, document);
        batch.set(refEvent, document);
        batch
            .commit()
            .then((_) => {
                onResolve(true);
            })
            .catch((err) => {
                console.log();
                onResolve(false);
            });
    }

    async editDocumentName(moduleId: string, eventId: string, folderId: string, document: Document, name: NameModule, onResolve) {
        let db = this.aFirestore.firestore;
        let batch = db.batch();

        let ref = db.collection('modules').doc(moduleId).collection('folders').doc(folderId).collection('documents').doc(document.uid);
        let refEvent = db.collection('events').doc(eventId).collection('documents').doc(document.uid);

        name = Object.assign({}, name);
        batch.update(ref, { name: name });
        batch.update(refEvent, { name: name });

        batch
            .commit()
            .then((_) => {
                onResolve(true);
            })
            .catch((err) => {
                console.log();
                onResolve(false);
            });
    }

    deleteDocument(eventId: string, moduleId: string, folderId: string, docId: string, storageId: string, onResolve) {
        let db = this.aFirestore.firestore;
        let batch = db.batch();
        let ref = db.collection('modules').doc(moduleId).collection('folders').doc(folderId).collection('documents').doc(docId);
        let refEvent = db.collection('events').doc(eventId).collection('documents').doc(docId);

        batch.delete(ref);
        batch.delete(refEvent);

        batch
            .commit()
            .then((_) => {
                this.storage.deleteDocument(eventId, moduleId, folderId, storageId);
                onResolve(true);
            })
            .catch((err) => {
                console.log();
                onResolve(false);

            });
    }

    deleteDocuments(eventId: string, folderId: string, moduleId: string, imageIds: Array<string>, storageIds: Array<string>, onResolve) {
        for (let i = 0; i < imageIds.length; i++) {
            let db = this.aFirestore.firestore;
            let batch = db.batch();
            let ref = db.collection('modules').doc(moduleId).collection('folders').doc(folderId).collection('documents').doc(imageIds[i]);
            let refEvent = db.collection('events').doc(eventId).collection('documents').doc(imageIds[i]);
            batch.delete(ref)
            batch.delete(refEvent);

            batch
                .commit()
                .then((_) => {
                    this.storage.deleteDocument(eventId, moduleId, folderId , storageIds[i]);

                    if (i == imageIds.length - 1) {
                        onResolve(true);
                    }
                })
                .catch((err) => {
                    console.log();
                    if (i == imageIds.length - 1) {
                        onResolve(false);
                    }
                });
        }
    }

    deleteFolders(eventId: string, moduleId: string, folderId: Array<string>, onResolve) {
        let db = this.aFirestore.firestore;
        let batch = this.aFirestore.firestore.batch();
        let ref = db.collection('modules').doc(moduleId).collection('folders');

        let listOrdersRemove = [];
        for (let i = 0; i < folderId.length; i++) {

            ref.doc(folderId[i]).get().then((data) => {
                let folder = data.data();
                listOrdersRemove.push(folder.order);

                //batch.delete(db.collection('modules').doc(moduleId).collection('folders').doc(folderId[i]));

                ref
                    .doc(folderId[i])
                    .collection('documents')
                    .get()
                    .then((values) => {
                        if (values.size >= 1) {
                            values.forEach(element => {
                                let document = element.data();
    
                                batch.delete(db.collection('modules').doc(moduleId).collection('folders').doc(folderId[i]).collection('documents').doc(document.uid));
                                batch.delete(db.collection('events').doc(eventId).collection('documents').doc(document.uid));
    
                                if (document.storageId) {
                                    this.storage.deleteDocument(eventId, moduleId, folderId[i], document.storageId);
                                }
                            });
                            if (i == folderId.length - 1) {
                                batch.delete(db.collection('modules').doc(moduleId).collection('folders').doc(folderId[i]));
                                batch
                                    .commit()
                                    .then((_) => {
                                        this.reorderFoldersAfterAllRemoving(moduleId, listOrdersRemove, (data) => {
                                        });
                                        onResolve(true);
                                    })
                                    .catch(() => {
                                        onResolve(false);
                                    })
                            }
                        } 
                        else {
                            batch.delete(db.collection('modules').doc(moduleId).collection('folders').doc(folderId[i]));
                            if (i == folderId.length - 1) {
                                batch
                                    .commit()
                                    .then((_) => {
                                        this.reorderFoldersAfterAllRemoving(moduleId, listOrdersRemove, (data) => {
                                        });
                                        onResolve(true);
                                    })
                                    .catch(() => {
                                        onResolve(false);
                                    })
                            }
                        }
                    })
                    .catch((err) => {
                        if (i == folderId.length - 1) {
                            onResolve(false);
                        }
                    });
            });
        }
    }

    reorderFoldersAfterAllRemoving(moduleId, listOrders, onResolve) {
        let db = this.aFirestore.firestore;
        let ref = db.collection('modules').doc(moduleId).collection('folders');

        ref.get().then((data) => {
            if (data.size > 0) {
                let listFolders = [];

                data.forEach(element => {
                    listFolders.push(element.data());
                });

                let cont = 0;
                for (let folders of listFolders) {
                    let lessPositions = 0;

                    for (let order of listOrders) {
                        if (folders.order > order) {
                            lessPositions++;
                        }
                    }

                    if (lessPositions > 0) {
                        folders.order = folders.order - lessPositions;
                        ref.doc(folders.uid).update(folders).then(() => {
                            if (cont == listFolders.length - 1) {
                                onResolve(true);
                            }

                            cont++;
                        })
                    } else {
                        if (cont == listFolders.length - 1) {
                            onResolve(true);
                        }

                        cont++;
                    }
                }
            } else {
                onResolve(true);
            }
        })
    }

    // list the document's sessions.
    listSessionsOfDocument(documentId: string, eventId: string) {
        return new Promise((resolve) => {
            let db = this.aFirestore.firestore;

            const ref = db.collection('events').doc(eventId).collection('documents').doc(documentId).collection('sessions')

            ref.get().then((snapshot) => {
                const list = []

                snapshot.forEach((childSnapshot) => {
                    const document = childSnapshot.data()
                    list.push(document)
                })

                resolve(list)
            })
        })
    }

    reorderFoldersList(moduleId: string, foldersList: Array<any>) {
        return new Promise((resolve, reject) => {
            let db = this.aFirestore.firestore;
            let cont = 0;
            let size = foldersList.length;
            foldersList.forEach(el => {
                let folder = el;
                db
                    .collection('modules')
                    .doc(moduleId)
                    .collection('folders')
                    .doc(folder.uid)
                    .update(folder)
                    .then((_) => {
                        cont++;
                        if (cont == size) {
                            resolve(true);
                        }
                    })
                    .catch((error) => {
                        error++;
                        if (error == size) {
                            reject(false);
                        }
                    });
            });
        });
    }

    // inclui a ID e URL da img de capa de uma folder do app, como campos do document folderId do firestore
    // relaciona um arquivo de img no firebase storage com a respectiva folderId no firestore
    saveImage(eventId: string, moduleId: string, folderId: string, image: Image, onResolve) {
        let db = this.aFirestore.firestore
            .collection('modules')
            .doc(moduleId)
            .collection('folders')
            .doc(folderId);
        
        db.get()
            .then((snapshot) => {
                let imageId = snapshot.data().folderCoverId;
                console.log("id img anterior: ", imageId);

                // se ja existe uma img de capa da pasta no storage, exclui a img antiga
                if (imageId) {
                    this.storage.deleteImg(eventId, moduleId, folderId, imageId, PathStorage.documents);
                }

                let update = db.update({folderCoverImg: image.url , folderCoverId: image.storageId});

                update.then(() => {
                    console.log("saveImage works");
                    onResolve(true);
                })
                .catch((error) => {
                    console.log("erro na tentativa de salvar a img", error);
                    onResolve(false);
                })
            })
            .catch((error) => {
                console.log("error", error);
            });
    }

}
