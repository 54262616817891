import { map } from "rxjs/operators";
import { AngularFirestore } from "@angular/fire/firestore";
import { Component, OnInit, ViewChild } from "@angular/core";
import { SwalComponent } from "@sweetalert2/ngx-sweetalert2";
import { Checkin } from "src/app/models/checkin";
import { DbCheckinProvider } from "src/app/providers/database/db-checkin";
import { ActivatedRoute, Router } from "@angular/router";
import { Group } from "src/app/models/group";
import { DbGroupsProvider } from "src/app/providers/database/db-groups";
import { Attendee } from "src/app/models/attendees";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { TranslateService } from "@ngx-translate/core";
import * as jspdf from "jspdf";
import { DragulaService } from "ng2-dragula";
import { DragulaOptions } from "dragula";
import { DbManagerModuleProvider } from "src/app/providers/database/db-manager-module";
import { GlobalService } from "src/app/providers/global/global.service";
import { DbEventsProvider } from "src/app/providers/database/db.events";
import { TypeVisionCheckin } from "src/app/enums/type-vision-checkin";
import { DateTimeService } from "src/app/providers/date-time/date-time.service";

declare let $: any;
type AOA = Array<Array<any>>;

enum modalType {
    create,
    edit
}

@Component({
    selector: "app-checkin",
    templateUrl: "./checkin.component.html",
    styleUrls: ["./checkin.component.scss"],
    providers: [DbCheckinProvider],
})
export class CheckinComponent implements OnInit {
    // get the language of the user.
    public userLanguage: string;

    @ViewChild("successSwal") public successSwal: SwalComponent;
    @ViewChild("errorSwal") public errorSwal: SwalComponent;
    eventId: string = null;
    moduleId: string = null;
    module;
    checkins: Array<Checkin> = [];
    loader: boolean = true;
    loaderModal: boolean = false;
    p: number = 1;

    checkinName: string = null;
    requiredName: boolean = false;

    checkinEdit;
    checkinDeleteId: string = null;
    statusView: boolean = false;
    checkinExportId: string = null;
    checkinExportName: string = null;
    checkinExportTypeVision: number = null;
    checkinExportGroups = [];

    // GROUPS FILTER
    listGroups: Array<Group>;

    selectedGroupsListing: Array<Group> = [];
    selectedGroupsViewer: Array<Group> = [];

    filteredListGroupsListing = [];
    filteredListGroupsViewer = [];

    queryGroupsListing: string = "";
    queryGroupsViewer: string = "";

    attendees: Array<Attendee> = [];
    // export checkin
    dataExportAttendees: AOA = null;
    data: any = null;
    qrCodeValue: string = null;
    term;
    dragulaOptions: DragulaOptions = {
        moves: () => true,
    };
    onReorderShow: boolean = false;
    loaderGeneral: boolean = false;
    checkinConfigs = null;
    loaderConfigs: boolean = false;
    generalSettingsLoader: boolean = false;
    totalAttendeeTxtColor: string = "#000000";
    totalPresentTxtColor: string = "#000000";
    totalAttendeeBgColor: string = "#fafafa";
    totalPresentBgColor: string = "#1fc94f";
    selectedAllInput: boolean = false;
    checkinSelected: Array<boolean> = [];

    typeVisionListing: TypeVisionCheckin = TypeVisionCheckin.GLOBAL_VISION;
    typeVisionViewer: TypeVisionCheckin = TypeVisionCheckin.GLOBAL_VISION;

    readonly globalVision = TypeVisionCheckin.GLOBAL_VISION;
    readonly groupVision = TypeVisionCheckin.GROUP_VISION;

    modalType: modalType;
    readonly modalCreate = modalType.create;
    readonly modalEdit = modalType.edit;

    preparedGroupExport: any;

    constructor(
        private dbCheckin: DbCheckinProvider,
        private route: ActivatedRoute,
        private router: Router,
        private dbGroups: DbGroupsProvider,
        private translateService: TranslateService,
        private dragula: DragulaService,
        private dbModules: DbManagerModuleProvider,
        private global: GlobalService,
        private dbEvent: DbEventsProvider,
        private afs: AngularFirestore,
        private dt: DateTimeService
    ) {
        this.eventId = this.route.parent.params["_value"]["uid"];
        this.moduleId = this.route.snapshot.params["moduleId"];
        dragula.createGroup("bag-checkin-list", this.dragulaOptions);
    }

    ngOnInit() {
        this.getModule();
        this.getEvent();
        this.getGroupsList();
        this.getUserLanguage();

        // heats the function to perform faster.
        this.dbCheckin.getAttendeesWithCheckin(
            null,
            null,
            null,
            null,
            null,
            null,
            (a) => {}
        );

        // start dragula reorder bag
        this.dragula.dropModel("bag-checkin-list").subscribe((value: any) => {
            this.onReorder(value);
        });
    }

    event: any = null;
    getEvent() {
        this.dbEvent.getEvent(this.eventId, (event: Event) => {
            this.event = event;
        });
    }

    // update order of modules
    onReorder(order: any): void {
        this.onReorderShow = true;
        this.checkins = order.targetModel;
        for (let i = 0; i < this.checkins.length; ++i) {
            this.checkins[i].order = i;
        }
    }

    getModule() {
        this.dbCheckin.getCheckinModule(this.moduleId, (module) => {
            this.module = module;
            this.getCheckins();
        });
    }

    // get the language of the user.
    getUserLanguage() {
        this.global.getLanguage((language) => {
            this.userLanguage = language;
        });
    }

    ngAfterContentChecked() {
        let auxRoute = this.router.url.includes("status");
        if (auxRoute == true) {
            this.statusView = true;
        } else {
            this.statusView = false;
        }
    }

    /**
     * @description set the type of the modal responsible for creation and edition of checkIns
     * @param checkin 
     */
    setModalType(checkin = null) {
        if (checkin == null) {
            this.modalType = modalType.create;
        }
        else {
            this.modalType = modalType.edit;
            this.getEditCheckin(checkin);
        }
    }

    /**
     * @description commit changes made in the modal responsible for create/edit checkIns
     * @returns 
     */
    commitChanges() {
        switch (this.modalType) {
            case modalType.create:
                this.createCheckin();
                break;
            case modalType.edit:
                this.editCheckin();
                break;
            default:
                return;
        }
    }

    getCheckins() {
        this.dbCheckin.getCheckinList(
            this.moduleId,
            (checkins: Array<Checkin>) => {
                this.checkins = [];

                if (
                    checkins.length === 1 &&
                    checkins[0].typeVisionViewer === TypeVisionCheckin.GLOBAL_VISION &&
                    checkins[0].visibility
                ) {
                    if (this.eventId && this.moduleId && checkins[0].uid) {
                        this.module[
                            "viewApp"
                        ] = `/event/${this.eventId}/checkin-detail/${this.moduleId}/${checkins[0].uid}`;
                        this.dbModules.updateModule(this.module);
                    }
                } else {
                    if (this.eventId && this.moduleId) {
                        this.module[
                            "viewApp"
                        ] = `/event/${this.eventId}/checkin/${this.moduleId}`;
                        this.dbModules.updateModule(this.module);
                    }
                }

                for (let checkin of checkins) {
                    checkin["qrcodeId"] = this.moduleId + "+" + checkin.uid;
                    this.checkins.push(checkin);
                }

                this.loader = false;
            }
        );
    }

    // create checkin
    createCheckin() {
        this.requiredName = false;

        if (this.checkinName !== "" && this.checkinName !== null) {
            this.loaderModal = true;
            let order = 0;

            if (this.checkins.length == 0) {
                order = 0;
            } else {
                order = this.checkins.length;
            }

            let checkin = new Checkin(
                this.checkinName,
                order,
                this.typeVisionListing,
                this.typeVisionViewer
            );

            if (this.typeVisionListing === TypeVisionCheckin.GROUP_VISION) {
                for (const group of this.selectedGroupsListing) {
                    const groupId = group.uid;
                    checkin.groups[groupId] = group;
                }
            }

            if (this.typeVisionViewer === TypeVisionCheckin.GROUP_VISION) {
                for(const viewerGroup of this.selectedGroupsViewer){
                    const groupId = viewerGroup.uid;
                    checkin.viewerGroups[groupId] = viewerGroup;
                }
            }

            checkin.eventId = this.eventId;
            checkin.moduleId = this.moduleId;

            this.dbCheckin.newCheckin(
                this.moduleId,
                this.eventId,
                checkin,
                (response) => {
                    if (!response["status"]) {
                        this.errorSwal.fire();
                        this.loaderModal = false;
                    } else {
                        this.selectedGroupsListing = [];
                        this.selectedGroupsViewer = []
                        this.typeVisionListing = TypeVisionCheckin.GLOBAL_VISION;
                        this.typeVisionViewer = TypeVisionCheckin.GLOBAL_VISION;
                        $("#createNEditModal").modal("toggle");
                        this.loaderModal = false;
                        this.checkinName = null;
                        this.successSwal.fire();
                    }
                }
            );
        } else {
            this.requiredName = true;
        }
    }

    getEditCheckin(checkin) {
        this.selectedGroupsListing = [];
        this.selectedGroupsViewer = [];

        this.checkinEdit = checkin;
        this.checkinName = checkin.name;
        this.typeVisionListing = checkin.typeVisionListing;
        this.typeVisionViewer = checkin.typeVisionViewer;

        if (this.typeVisionListing === TypeVisionCheckin.GROUP_VISION) {
            for (const uid in checkin.groups) {
                const group = checkin.groups[uid];
                this.selectedGroupsListing.push(group);
            }
        }

        if (this.typeVisionViewer == TypeVisionCheckin.GROUP_VISION) {
            for (const uid in checkin.viewerGroups) {
                const group = checkin.viewerGroups[uid];
                this.selectedGroupsViewer.push(group);
            }
        }
    }

    editCheckin() {
        this.requiredName = false;
        if (this.checkinName !== "" && this.checkinName !== null) {
            this.loaderModal = true;
            this.checkinEdit.groups = {};
            this.checkinEdit.viewerGroups = {};

            if (this.typeVisionListing === TypeVisionCheckin.GROUP_VISION) {
                for (const group of this.selectedGroupsListing) {
                    const groupId = group.uid;
                    this.checkinEdit.groups[groupId] = group;
                }
            }

            if (this.typeVisionViewer == TypeVisionCheckin.GROUP_VISION) {
                for (const viewerGroup of this.selectedGroupsViewer) {
                    const groupId = viewerGroup.uid;
                    this.checkinEdit.viewerGroups[groupId] = viewerGroup;
                }
            }

            this.dbCheckin.editCheckin(
                this.moduleId,
                this.checkinEdit.uid,
                this.checkinName,
                this.typeVisionListing,
                this.typeVisionViewer,
                this.checkinEdit.groups,
                this.checkinEdit.viewerGroups,
                (status) => {
                    if (!status) {
                        this.errorSwal.fire();
                        this.loaderModal = false;
                    } else {
                        this.checkinEdit = null;
                        this.checkinName = "";
                        this.typeVisionListing = TypeVisionCheckin.GLOBAL_VISION;
                        this.typeVisionViewer = TypeVisionCheckin.GLOBAL_VISION;
                        this.selectedGroupsListing = [];
                        this.selectedGroupsViewer = [];
                        $("#createNEditModal").modal("toggle");
                        this.loaderModal = false;
                        this.successSwal.fire();
                    }
                }
            );
        } else {
            this.requiredName = true;
        }
    }

    /**
     * get checkinDeleteId to remove check in.
     * @param uid Checkin uid.
     */
    getDeleteCheckin(uid: string) {
        this.checkinDeleteId = uid;
    }

    /**
     *Delete checkin. Activate the trigger:dbCheckinDelete
     */
    deleteCheckin() {
        this.dbCheckin.deleteCheckin(
            this.moduleId,
            this.checkinDeleteId,
            (status) => {
                if (!status) {
                    this.errorSwal.fire();
                } else {
                    this.successSwal.fire();
                }
            }
        );
    }

    changeVisibility(uid: string, status: boolean) {
        this.dbCheckin.changeVisibility(this.moduleId, uid, status);
    }

    clearForm() {
        this.checkinName = "";
        this.typeVisionListing = TypeVisionCheckin.GLOBAL_VISION;
        this.typeVisionViewer = TypeVisionCheckin.GLOBAL_VISION;
        this.selectedGroupsListing = [];
        this.selectedGroupsViewer = [];
    }

    startCheckinRef(checkinId: string, name: string) {
        this.checkinExportId = checkinId;
    }

    qrCodeStart() {
        if (this.checkinExportId == null) {
            this.checkinExportId =
                this.route.children[0]["params"]["_value"]["checkinId"];
        }
        this.qrCodeValue = this.moduleId + "+" + this.checkinExportId;
    }

    /******************* GROUPS **********************/

    getGroupsList() {
        this.dbGroups.searchModulesAndGroups(this.eventId, (result) => {
            this.listGroups;
            this.listGroups = result["groups"];
        });
    }

    // select track from filtered list
    selectGroupListing(item) {
        if (this.selectedGroupsListing.length > 0) {
            const index = this.selectedGroupsListing.indexOf(item);
            if (index == -1) {
                this.selectedGroupsListing.push(item);
            }
        } else {
            this.selectedGroupsListing.push(item);
        }
        this.queryGroupsListing = "";
        this.filteredListGroupsListing = [];
    }

    selectGroupViewer(item) {
        if (this.selectedGroupsViewer.length > 0) {
            const index = this.selectedGroupsViewer.indexOf(item);
            if (index == -1) {
                this.selectedGroupsViewer.push(item);
            }
        } else {
            this.selectedGroupsViewer.push(item);
        }
        this.queryGroupsViewer = "";
        this.filteredListGroupsViewer = [];
    }

    // remove selected location
    removeGroupListing(item) {
        this.selectedGroupsListing.splice(
            this.selectedGroupsListing.indexOf(item),
            1
        );
    }

    // filter groups
    filterGroupsListing() {
        if (this.queryGroupsListing !== "") {
            this.filteredListGroupsListing = this.listGroups.filter(
                function (el) {
                    return (
                        el.name
                            .toLowerCase()
                            .indexOf(this.queryGroupsListing.toLowerCase()) > -1
                    );
                }.bind(this)
            );
        } else {
            this.filteredListGroupsListing = [];
        }
    }

    filterGroupsViewer() {
        if (this.queryGroupsViewer !== "") {
            this.filteredListGroupsViewer = this.listGroups.filter(
                function (el) {
                    return (
                        el.name
                            .toLowerCase()
                            .indexOf(this.queryGroupsViewer.toLowerCase()) > -1
                    );
                }.bind(this)
            );
        } else {
            this.filteredListGroupsViewer = [];
        }
    }

    async exportCheckin() {
        if (this.checkinExportId == null) {
            this.checkinExportId =
                this.route.children[0]["params"]["_value"]["checkinId"];
        }

        const checkin: any = await this.dbCheckin.getCheckinPromise(
            this.moduleId,
            this.checkinExportId
        );
        this.checkinExportTypeVision =
            typeof checkin.typeVision !== "undefined" &&
            checkin.typeVision !== null
                ? checkin.typeVision
                : TypeVisionCheckin.GLOBAL_VISION;
        const auxGroups =
            typeof checkin.groups !== "undefined" && checkin.groups !== null
                ? checkin.groups
                : {};
        this.checkinExportGroups = [];
        for (const uid in auxGroups) {
            const group = auxGroups[uid];
            this.checkinExportGroups.push(group);
        }

        this.dataExportAttendees = [];
        this.dataExportAttendees = [["Identifier", "Name", "Status"]];
        let cont = 0;
        await this.dbCheckin.exportCheckin(
            this.eventId,
            this.moduleId,
            this.checkinExportId,
            this.checkinExportTypeVision,
            this.checkinExportGroups,
            (attendees) => {
                if (attendees.length > 0) {
                    for (let attendee of attendees) {
                        let row: any;
                        row = this.prepareCheckinExport(attendee);
                        this.dataExportAttendees.push(row);

                        if (cont == attendees.length - 1) {
                            const wscols: XLSX.ColInfo[] = [
                                { wpx: 100 }, // "pixels"
                                { wpx: 100 }, // "pixels"
                                { wpx: 100 }, // "pixels"
                                { hidden: false }, // hide column
                            ];

                            /* At 96 PPI, 1 pt = 1 px */
                            const wsrows: XLSX.RowInfo[] = [
                                { hpx: 25 }, // "pixels"
                            ];

                            /* generate worksheet */
                            const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(
                                this.dataExportAttendees
                            );

                            /* TEST: column props */
                            ws["!cols"] = wscols;

                            /* TEST: row props */
                            ws["!rows"] = wsrows;

                            /* generate workbook and add the worksheet */
                            const wb: XLSX.WorkBook = XLSX.utils.book_new();
                            XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

                            /* save to file */
                            const wbout: string = XLSX.write(wb, {
                                bookType: "xlsx",
                                type: "binary",
                            });
                            saveAs(
                                new Blob([this.s2ab(wbout)]),
                                "checkin.xlsx"
                            );
                            this.data = null;

                            $("#exportLoading").modal("toggle");
                        } else {
                            $("#exportLoading").modal("toggle");

                            // this.errorSwal.fire();
                        }
                        cont++;
                    }
                } else {
                    setTimeout(() => {
                        $("#exportLoading").modal("toggle");
                        this.errorSwal.fire();
                    }, 1000);
                }
            }
        );
    }

    exportData() {
        const createWorkSheet = (attendees) => {
            const content = [
                ["Identifier", "Name", "Email", "Status", "Date", "Time", "locale", "Timezone"],
            ];

            const columnWidth: XLSX.ColInfo[] = [
                { wpx: 130 },   // Identifier
                { wpx: 130 },   // Name
                { wpx: 180 },   // Email
                { wpx: 90 },   // Status
                { wpx: 90 },   // Data
                { wpx: 50 },   // Time
                { wpx: 90 },   // Locale
                { wpx: 100 }    // Timezone
            ];

            const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
                
            for (const attendee of attendees) {
                const row = [];

                row.push(attendee.identifier);
                row.push(attendee.name);
                row.push(attendee.email);

                if (attendee.checkinStatus == true) {
                    row.push(this.translateService.instant("comp.checkin.showed"));

                    if (attendee.checkinMadeAt) {
                        const dateTime: string[] = this.dt.getDateTime(attendee.checkinMadeAt);
                        row.push(dateTime[0]);
                        row.push(dateTime[1]);
                        row.push(this.dt.deviceLocale);
                        row.push(this.dt.deviceTimeZone);
                    }
                } 
                else {
                    row.push(this.translateService.instant("comp.checkin.away"));
                }
                
                content.push(row);
            }

            const worksheet = XLSX.utils.aoa_to_sheet(content);
            worksheet["!cols"] = columnWidth;

            return worksheet;
        }

        this.dbCheckin.getCheckinStatuses(this.moduleId).then((data) => {
            const workbook = XLSX.utils.book_new();
            const attendees = data['attendees'];
            const checkins = data['checkins'];
    
            for (const checkin of checkins) {
                const worksheet = createWorkSheet(attendees[checkin.uid]);

                XLSX.utils.book_append_sheet(
                    workbook,
                    worksheet,
                    checkin.name
                );
            }

            const wbout: string = XLSX.write(workbook, {
                bookType: "xlsx",
                type: "binary",
            });

            saveAs(
                new Blob([this.s2ab(wbout)]),
                "checkIns.xlsx"
            );
            
            $("#exportLoading").modal("toggle");
        }).catch((error) => { 
            console.log(error);
            $("#exportLoading").modal("toggle");
            this.errorSwal.fire();
        })
    }

    // PREPARA UM REGISTRO DE GRUPOS PARA EXPORTAÇÃO
    prepareCheckinExport(attendee: Attendee) {
        const row = [];

        row.push(attendee["identifier"]);
        row.push(attendee["name"]);
        if (attendee["checkinStatus"] == true) {
            row.push(this.translateService.instant("comp.checkin.showed"));
        } else {
            row.push(this.translateService.instant("comp.checkin.away"));
        }
        return row;
    }

    // AJUDA A GERAR O ARQUIVO EXECL
    private s2ab(s: string): ArrayBuffer {
        const buf: ArrayBuffer = new ArrayBuffer(s.length);
        const view: Uint8Array = new Uint8Array(buf);
        for (let i = 0; i !== s.length; ++i) {
            view[i] = s.charCodeAt(i) & 0xff;
        }
        return buf;
    }

    printAllQrCodes() {
        $("#printAll").modal("show");
        let size = this.checkins.length;
        let array = [];
        for (let i = 0; i < size; i++) {
            // get element canvas and make data url string
            let elem = document.getElementById("qrcodesAll" + i);
            const canvas = elem.childNodes[0]
                .childNodes[0] as HTMLCanvasElement;
            const imageData = canvas.toDataURL("image/png").toString();
            // size and position of qrcodes
            let imgWidth = 150;
            let imgHeight = (canvas.height * imgWidth) / canvas.width;
            array.push({
                name: this.checkins[i].name,
                image: imageData,
                width: imgWidth,
                height: imgHeight,
                position: 30,
            });

            if (array.length == size) {
                this.saveAllQrCodes(array);
            }
        }
    }

    saveAllQrCodes(qrcodes) {
        let size = qrcodes.length;
        // start jspdf a4 format in landscape mode using milimiters
        let pdf = new jspdf("l", "mm", "a4"); // A4 size page of PDF
        let pageHeight =
            pdf.internal.pageSize.height || pdf.internal.pageSize.getHeight();
        let pageWidth =
            pdf.internal.pageSize.width || pdf.internal.pageSize.getWidth();
        for (let i = 0; i < size; i++) {
            // add title to top
            pdf.setFontType("bold");
            pdf.text(
                qrcodes[i].name,
                pageWidth / 2,
                pageHeight - 190,
                "center"
            );
            // add qrcode image after title
            pdf.addImage(
                qrcodes[i].image,
                "PNG",
                70,
                qrcodes[i].position,
                qrcodes[i].width,
                qrcodes[i].height
            );
            // generate new page
            if (i < size - 1) {
                pdf.addPage();
            }
            if (i == size - 1) {
                pdf.save("all-qrcodes.pdf"); // Generated PD
                setTimeout(() => {
                    $("#printAll").modal("toggle");
                }, 1000);
            }
        }
    }

    printQr() {
        this.dbCheckin.getCheckin(
            this.moduleId,
            this.checkinExportId,
            (checkin) => {
                this.checkinExportName = checkin["name"];
                const canvas = document.querySelector(
                    "canvas"
                ) as HTMLCanvasElement;
                const imageData = canvas.toDataURL("image/png").toString();
                let imgWidth = 150;
                let imgHeight = (canvas.height * imgWidth) / canvas.width;

                let pdf = new jspdf("l", "mm", "a4"); // A4 size page of PDF
                let pageHeight =
                    pdf.internal.pageSize.height ||
                    pdf.internal.pageSize.getHeight();
                let pageWidth =
                    pdf.internal.pageSize.width ||
                    pdf.internal.pageSize.getWidth();
                const position = 30;
                pdf.setFontType("bold");
                pdf.text(
                    this.checkinExportName,
                    pageWidth / 2,
                    pageHeight - 190,
                    "center"
                );
                pdf.addImage(
                    imageData,
                    "PNG",
                    70,
                    position,
                    imgWidth,
                    imgHeight
                );
                pdf.save("qrcode.pdf"); // Generated PD
            }
        );
    }

    saveReorder() {
        this.dbCheckin
            .reorderCheckinList(this.moduleId, this.checkins)
            .then((success) => {
                this.onReorderShow = false;
                this.loaderGeneral = false;
                /* case success remove display of btn to save new order and display modal success*/
                this.successSwal.fire();
            })
            .catch((error) => {
                this.onReorderShow = false;
                this.loaderGeneral = false;
                // case error, display modal error
                this.errorSwal.fire();
            });
    }

    getCheckinConfigs() {
        this.generalSettingsLoader = true;
        this.checkinExportId =
            this.route.firstChild.snapshot.params["checkinId"];
        this.dbCheckin.getCheckin(
            this.moduleId,
            this.checkinExportId,
            (checkin) => {
                this.checkinConfigs = checkin;
                this.totalAttendeeBgColor = checkin["totalBgColor"];
                this.totalAttendeeTxtColor = checkin["totalTxtColor"];
                this.totalPresentBgColor = checkin["totalPresentBgColor"];
                this.totalPresentTxtColor = checkin["totalPresentTxtColor"];
                this.generalSettingsLoader = false;
            }
        );
    }

    updateCheckinConfigs() {
        this.loaderConfigs = true;
        this.checkinConfigs["totalBgColor"] = this.totalAttendeeBgColor;
        this.checkinConfigs["totalTxtColor"] = this.totalAttendeeTxtColor;
        this.checkinConfigs["totalPresentBgColor"] = this.totalPresentBgColor;
        this.checkinConfigs["totalPresentTxtColor"] = this.totalPresentTxtColor;
        this.dbCheckin.editCheckinConfigs(
            this.moduleId,
            this.checkinExportId,
            this.checkinConfigs,
            (status) => {
                if (status == true) {
                    this.loaderConfigs = false;
                    $("#realtimeCheckinSettings").modal("toggle");
                    this.successSwal.fire();
                } else {
                    this.loaderConfigs = false;
                    this.errorSwal.fire();
                }
            }
        );
    }

    changeConfigAllowFooter($ev) {
        this.checkinConfigs["allowFooter"] = $ev;
    }
    changeConfigAllowUsername($ev) {
        this.checkinConfigs["allowUsername"] = $ev;
    }

    selectedAll() {
        if (this.selectedAllInput) {
            for (let i = 0; i < this.checkins.length; i++) {
                this.checkinSelected[this.checkins[i]["uid"]] = true;
            }
        } else {
            for (let i = 0; i < this.checkins.length; i++) {
                this.checkinSelected[this.checkins[i]["uid"]] = false;
            }
        }
    }

    /**
     *Delete checkins. Activate the trigger:dbCheckinDelete
     *@param moduleId
     *@param listRemove
     *@returns onResolve
     */
    removeSelected() {
        this.loader = true;
        let listRemove = [];

        for (const uid in this.checkinSelected) {
            if (this.checkinSelected[uid]) listRemove.push(uid);
        }

        if (listRemove.length > 0) {
            this.dbCheckin.deleteCheckins(
                this.moduleId,
                listRemove,
                (result) => {
                    if (result) {
                        this.loader = false;
                        this.successSwal.fire();
                        this.selectedAllInput = false;
                        this.checkinSelected = [];
                    } else {
                        this.loader = false;
                        this.errorSwal.fire();
                    }
                }
            );
        } else {
            this.loader = false;
        }
    }

    ngOnDestroy() {
        this.dragula.destroy("bag-checkin-list");
    }
}
