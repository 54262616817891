import { Component, OnInit } from '@angular/core';
import { DbAdminTermsProvider } from 'src/app/providers/database/db-admin-terms';

@Component({
  selector: 'app-privacy-terms',
  templateUrl: './privacy-terms.component.html',
  styleUrls: ['./privacy-terms.component.scss'],
  providers: [DbAdminTermsProvider]
})
export class PrivacyTermsComponent implements OnInit {
  privacy: any = null;
  constructor(private dbTerms: DbAdminTermsProvider) {
    this.dbTerms.getPrivacyTerms((data) => {
      this.privacy = data;
    });
  }

  ngOnInit() {
  }

  savePrivacy() {
    const privacy = this.privacy.replace(/href="/g, 'class="wysiwyg-link" href="');
    this.dbTerms.savePrivacyTerms(privacy, (result) => {
      // save ok or save not ok, resolve feedback for user
    });
  }

}
