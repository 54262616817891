import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { DbCustomPagesProvider } from 'src/app/providers/database/db-custom-pages';
import { GlobalService } from 'src/app/providers/global/global.service';
import { DragulaService } from 'ng2-dragula';
import { DbEventsProvider } from 'src/app/providers/database/db.events';
import { PageInfobooth } from 'src/app/models/page-infobooth';

@Component({
    selector: 'app-custom-pages',
    templateUrl: './custom-pages.component.html',
    styleUrls: ['./custom-pages.component.scss']
})

export class CustomPagesComponent implements OnInit {
    public module = null

    // get the language of the user.
    public userLanguage: string

    pages = []

    eventId: string;
    moduleId: string;

    loader: boolean

    pageEdit = null
    pageRemove = null
    moduleName: string = null;

    onReorderShow: boolean = false;
    loaderGeneral: boolean = false;

    @ViewChild('successSwal') public successSwal: SwalComponent;
    @ViewChild('errorSwal') public errorSwal: SwalComponent;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private dbCustomPage: DbCustomPagesProvider,
        private global: GlobalService,
        private dragula: DragulaService,
        private dbEvent: DbEventsProvider
    ) { }

    ngOnInit() {
        this.loader = true

        this.eventId = this.route.parent.params['_value']['uid'];
        this.moduleId = this.route.snapshot.params['moduleId'];
        this.getUserLanguage()
        this.getModule();
        this.getEvent();
        this.loadPages()

        // start dragula reorder bag
        this.dragula.dropModel('bag-pages-list').subscribe((value: any) => {
            this.onReorder(value);
        });
    }

    event: any = null;
    getEvent() {
        this.dbEvent.getEvent(this.eventId, (event: Event) => {
            this.event = event;
        });
    }

    // update order of modules
    onReorder(order: any): void {
        this.onReorderShow = true;
        this.pages = order.targetModel;
        for (let i = 0; i < (this.pages).length; ++i) {
            this.pages[i].order = i;
        }
    }

    saveReorder() {
        this.dbCustomPage.reorderPagesList(this.moduleId, this.pages)
            .then((success) => {
                this.onReorderShow = false;
                this.loaderGeneral = false
                /* case success remove display of btn to save new order and display modal success*/
                this.successSwal.fire();
            }).catch((error) => {
                this.onReorderShow = false;
                this.loaderGeneral = false
                // case error, display modal error
                this.errorSwal.fire();
            })
    }

    getModule() {
        this.dbCustomPage.getModule(this.moduleId, (module) => {
            this.module = module;
        });
    }

    ngDoCheck() { // verifica se houve mudança no parâmetro do idModule
        this.eventId = this.route.parent.params['_value']['uid'];

        if (this.moduleId !== this.route.snapshot.params['moduleId']) {
            this.loader = true

            this.moduleId = this.route.snapshot.params['moduleId'];
            this.getModule()
            this.getUserLanguage()
            this.loadPages()
        }
    }


    // get the language of the user.
    getUserLanguage() {
        this.global.getLanguage((language) => {
            this.userLanguage = language
        })
    }

    loadPages() {
        this.dbCustomPage.getPages(this.moduleId, (pages) => {
            this.loader = false

            this.pages = []
            for (let page of pages) {
                this.pages[page.order] = page;
            }
        })
    }

    getPageRemove(page) {
        this.pageRemove = page
    }

    removePage() {
        this.dbCustomPage.deletePage(this.pageRemove).then(() => {
            this.successSwal.fire();
        }).catch((error) => {
            this.errorSwal.fire()
        })
    }


    redirectCustomPageEdit(page) {
        const pageId = page.uid
        this.router.navigate(['/event/' + this.eventId + '/custom-pages/' + this.moduleId + '/' + pageId]);
    }


    // cria uma custom page em branco/vazia e retorna a pageId
    // e por fim redireciona para o componente custom page edit
    // dessa forma a custom page é criada e entramos na tela de edição
    pageId: string;
    createPage() {
        let page = new PageInfobooth()
        page.moduleId = this.moduleId;
        page.eventId = this.eventId;
        page.title = {
            PtBR: '',
            EnUS: '',
            EsES: '',
            FrFR: '',
            DeDE: ''
        };
        page.htmlContent = {
            PtBR: '',
            EnUS: '',
            EsES: '',
            FrFR: '',
            DeDE: ''
        };

        this.dbCustomPage.getTotalPagesModule(this.moduleId, (total) => {
            page.order = total;
            
            this.dbCustomPage.createPage(page, (pageId) => {
                if (pageId != null) {
                    this.pageId = pageId;
                    console.log(pageId);
                    this.loader = false;
                    this.router.navigate([`/event/${this.eventId}/custom-pages/${this.moduleId}/edit/${this.pageId}`]);
                }
                else {
                    this.errorSwal.fire()
                    this.loader = false;
                }

            })
        });

    }

}
