import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { AngularFirestore } from '@angular/fire/firestore';
import { DateTime } from 'luxon';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class WherebyService {
    headers: HttpHeaders;
    baseApiUrl: string = environment.platform.apiBaseUrl;

    constructor(
        private http: HttpClient,
        private afs: AngularFirestore
    ) {
        this.headers = new HttpHeaders({
            'Content-Type': "application/json"
        });
    }

    /**
     * Create a meeting on whereby
     * @param meetingData 
     */
    createMeeting(meetingData: any): Promise<HttpResponse<any>> {
        return (this.http.post(this.baseApiUrl + "dbWherebyCreateMeeting", meetingData, {
            headers: this.headers,
            observe: 'response'
        }).toPromise())
    }

    /**
     * Delete a meeting on whereby
     * @param meetingId 
     */
    deleteMeeting(meetingId: string) {
        return (this.http.post(this.baseApiUrl + "dbWherebyDeleteMeeting", {
            meetingId: meetingId
        }, {
            headers: this.headers,
            observe: 'response'
        }).toPromise())
    }

    /**
     * Get specified meeting
     * @param meetingId 
     */
    getMeeting(meetingId: string) {
        return (this.http.post(this.baseApiUrl + "dbWherebyGetMeeting", {
            meetingId: meetingId
        }, {
            headers: this.headers,
            observe: 'response'
        }).toPromise())
    }

    /**
     * Analytics for new access to a room
     * @param eventId 
     * @param userId 
     */
    newAccessToRoom(eventId: string, userId: string) {
        this.afs.collection('analytics').doc(eventId).collection('total-visio-access').add({
            userId: userId,
            date: DateTime.local().toISO()
        });
    }

    /**
     * Analytics for new creation of a room for 2
     * @param eventId 
     * @param userIds 
     */
    createNewRoomFor2(eventId: string, userIds: string[]) {
        this.afs.collection('analytics').doc(eventId).collection('total-visio-for-2').add({
            userIds: userIds,
            date: DateTime.local().toISO()
        });
    }

    /**
     * Create zoom meeting
     * @param meetingData 
     */
    createZoomMeeting(meetingData: any) {
        return (this.http.post(this.baseApiUrl + "dbZoomCreateMeeting", meetingData, {
            headers: this.headers,
            observe: 'response'
        }).toPromise())
    }

    /**
     * Update zoom meeting
     * @param meetingData 
     */
    updateZoomMeeting(meetingData: any) {
        return (this.http.post(this.baseApiUrl + "dbZoomUpdateMeeting", meetingData, {
            headers: this.headers,
            observe: 'response'
        }).toPromise())
    }
}
