import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { DbLocationsProvider } from 'src/app/providers/database/db-locations';
import { Location } from '../../../../models/location';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { TranslateService } from '@ngx-translate/core';
import { OrderType } from 'src/app/paths/orderType';
import { DbEventsProvider } from 'src/app/providers/database/db.events';

declare let $: any;
type AOA = Array<Array<any>>;

@Component({
    selector: 'app-locations',
    templateUrl: './locations.component.html',
    styleUrls: ['./locations.component.scss'],
    providers: [DbLocationsProvider]
})

export class LocationsComponent implements OnInit {
    @ViewChild('successSwal') public successSwal: SwalComponent;
    @ViewChild('errorSwal') public errorSwal: SwalComponent;
    @ViewChild('notExport') public notExport: SwalComponent;
    @ViewChild('inputFile') public inputFile: ElementRef;

    public p: number = 1;
    public eventId: string = null;
    public moduleId: string = null;
    term;
    public loader: boolean = true;
    public loaderBtn: boolean = false;
    public formValidation: FormGroup;
    public formValidationEdit: FormGroup;
    public editLocationid: string = null;
    public editLocationIdentifier: string = null;
    public deleteLocationId: string = null;
    public file = null;
    public fileName: string = null;
    public locations: Array<Location> = [];
    public locationsSelected: Array<boolean> = [];
    public selectedAllInput: boolean = false;
    public blankPage: boolean = true;
    public typeOrder: string = null;

    public nameError: boolean = false;
    public idError: boolean = false;
    public idRequiredError: boolean = false;
    public nameErrorEdit: boolean = false;
    public idErrorEdit: boolean = false;
    public idRequiredErrorEdit: boolean = false;

    // export locations
    dataExportLocations: AOA = null;
    data: any = null;

    // import
    nameFile: string = null;
    loaderImport: boolean = false;
    messageErrors: Array<any> = [];
    totalCreated: number = 0;
    totalUpdated: number = 0;
    totalFail: number = 0;
    successImport: boolean = false;
    errorCheckImport: boolean = false;
    errorImport: boolean = false;
    arrayFailedImport: Array<Location> = [];

    // variables that represent the excel columns.
    localId: number = 0;
    localName: number = 1;
    localImage: number = 2;
    localDescription: number = 3;

    newLocations: Array<Location> = [];


    module: any = null;
    event: any = null;

    constructor(
        private route: ActivatedRoute,
        private fb: FormBuilder,
        private dbLocations: DbLocationsProvider,
        private translateService: TranslateService,
        private dbEvent: DbEventsProvider,
    ) {
        this.eventId = this.route.parent.params['_value']['uid'];
        this.moduleId = this.route.snapshot.params['moduleId'];
    }

    ngOnInit() {
        this.dbLocations.getModule(this.moduleId, (module) => {
            this.module = module;
            this.typeOrder = module['orderLocations'];
            this.getLocations();
        });

        this.getEvent();


        this.formValidation = this.fb.group({
            'name': [null, Validators.compose([Validators.required, Validators.maxLength(50)])],
            'description': [null]
        });

        this.formValidationEdit = this.fb.group({
            'identifierEdit': [null],
            'nameEdit': [null, Validators.compose([Validators.required, Validators.maxLength(50)])],
            'descriptionEdit': [null],
            'imageEdit': [null],
            'uidEdit': [null]
        });
    }

    getEvent() {
        this.dbEvent.getEvent(this.eventId, (event: Event) => {
            this.event = event;
        });
    }

    getLocations() {
        this.dbLocations.getLocationsModule(this.moduleId, this.typeOrder, (locations) => {
            this.locations = locations;
            if (this.locations.length <= 0) {
                this.loader = false;
                this.blankPage = true;
            } else {
                this.loader = false;
                this.blankPage = false;
            }
        });
    }

    changeOrder() {
        this.dbLocations.changeOrderItems(this.moduleId, this.typeOrder, (data) => {
            if (data == true) {
                this.getLocations();
            }
        })

    }

    onUploadChange($ev) {
        this.file = $ev.srcElement.files[0];
        this.fileName = this.file.name;
    }

    addLocation(data) {
        this.idError = false;
        this.nameError = false;
        this.idRequiredError = false;
        this.loaderBtn = true;

        if (data.name !== '' && data.name !== null && data.name !== undefined) {
            const newLocation = new Location();
            let locationIdentifier: string;

            newLocation.name = data.name;
            locationIdentifier = this.newIdLocation(newLocation.name);
            newLocation.description = data.description;
            newLocation.moduleId = this.moduleId;
            newLocation.eventId = this.eventId;
            newLocation.identifier = locationIdentifier;

            this.dbLocations.newLocation(newLocation, this.file, (status) => {
                if (status == true) {
                    $('#newLocation').modal('toggle');
                    this.loaderBtn = false;
                    this.clearForm();
                    this.successSwal.fire();
                } else {
                    this.loaderBtn = false;
                    this.errorSwal.fire();
                }
            })

        } else {
            this.nameError = true;
            this.loaderBtn = false;
        }
    }

    /**
     * create identifier for location.
     * @param name  group's name
     */
    newIdLocation(name: string) {
        let flag = false;
        let aux = name
        let cont = 1

        while (!flag) {
            const index = this.locations.map(function (e) { return e.identifier; }).indexOf(aux);

            if (index === -1) {
                flag = true
            } else {
                aux = `${name}${cont}`
            }

            cont++
        }

        return aux;
    }

    getEditLocation(location) {
        this.editLocationid = location.uid;
        this.editLocationIdentifier = location.identifier;

        this.formValidationEdit.patchValue({
            identifierEdit: location.identifier,
            nameEdit: location.name,
            descriptionEdit: location.description,
            imageEdit: location.image
        });
    }

    editLocation(data) {
        this.idErrorEdit = false;
        this.nameErrorEdit = false;
        this.idRequiredErrorEdit = false;

        if (data.nameEdit != '' && data.nameEdit != null && data.nameEdit != undefined) {
            // this.loaderBtn = true;

            const location = new Location()

            location.uid = this.editLocationid;
            location.identifier = this.editLocationIdentifier;
            location.name = data.nameEdit
            location.description = data.descriptionEdit
            location.image = data.imageEdit
            location.moduleId = this.moduleId
            location.eventId = this.eventId

            this.dbLocations.editLocation(location, this.file, (status) => {
                if (status == true) {
                    this.loaderBtn = false;
                    $('#editLocation').modal('toggle');
                    this.file = null;
                    this.fileName = null;
                    this.successSwal.fire();
                } else {
                    this.loaderBtn = false;
                    this.file = null;
                    this.fileName = null;
                    this.errorSwal.fire();
                }
            });

        } else {
            //erro name
            this.nameErrorEdit = true;
            this.loaderBtn = false;
        }
    }

    clearForm() {
        this.file = null;
        this.fileName = null;
    }

    getDelete(uid: string) {
        this.deleteLocationId = uid;
    }

    deleteLocation() {
        this.loader = true;
        this.dbLocations.deleteLocation(this.eventId, this.moduleId, this.deleteLocationId, (status) => {
            if (status == true) {
                this.successSwal.fire();
                this.loader = false;
            } else {
                this.errorSwal.fire();
                this.loader = false;
            }
        });
    }

    selectedAll() {
        if (this.selectedAllInput) {
            for (let i = 0; i < this.locations.length; i++) {
                this.locationsSelected[this.locations[i]['uid']] = true;
            }
        } else {
            for (let i = 0; i < this.locations.length; i++) {
                this.locationsSelected[this.locations[i]['uid']] = false;
            }
        }
    }

    removeSelected() {
        this.loader = true;
        let listRemove = [];
        // let listRemoveIndexes = [];
        for (let i = 0; i < this.locations.length; i++) {
            if (this.locationsSelected[this.locations[i].uid] == true) {
                listRemove.push(this.locations[i].uid);
                // listRemoveIndexes.push(i);
            }
        }

        this.dbLocations.removeLocations(this.eventId, this.moduleId, listRemove, (data) => {
            if (data) {
                // remove attendee from array
                // let cont = 0;
                // for (let i of listRemoveIndexes) {
                //   this.locations.splice(i - cont, 1);

                //   cont++;
                // }

                //remove all selected box
                for (let i = 0; i < this.locationsSelected.length; i++) {
                    this.locationsSelected[this.locations[i].uid] = false;
                }

                // this.loader = false;
                this.getLocations();
                this.selectedAllInput = false;
                this.successSwal.fire();

            } else {
                this.loader = false;
                this.errorSwal.fire();
            }
        })
    }

    // FORM VALIDATION ERRORS
    get name() {
        return this.formValidation.get('name');
    }

    get nameEdit() {
        return this.formValidationEdit.get('nameEdit');
    }

    /********************************* EXPORT ALL LOCATIONS ************************/
    exportAllLocations() {
        this.dataExportLocations = [];
        this.dataExportLocations = [[
            'Identifier*',
            'Name*',
            'Image (URL)',
            'Description'
        ]];

        let cont = 0;
        if (this.locations.length > 0) {
            for (let location of this.locations) {

                let row: any;
                row = this.prepareLocationsExport(location);
                this.dataExportLocations.push(row);

                if (cont == this.locations.length - 1) {
                    const wscols: XLSX.ColInfo[] = [
                        { wpx: 100 }, // "pixels"
                        { wpx: 100 }, // "pixels"
                        { wpx: 100 }, // "pixels"
                        { wpx: 100 }, // "pixels"
                        { wpx: 100 }, // "pixels"
                        // { hidden: false } // hide column
                    ];

                    /* At 96 PPI, 1 pt = 1 px */
                    const wsrows: XLSX.RowInfo[] = [
                        { hpx: 25 }, // "pixels"
                    ];

                    /* generate worksheet */
                    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.dataExportLocations);

                    /* TEST: column props */
                    ws['!cols'] = wscols;

                    /* TEST: row props */
                    ws['!rows'] = wsrows;

                    /* generate workbook and add the worksheet */
                    const wb: XLSX.WorkBook = XLSX.utils.book_new();
                    XLSX.utils.book_append_sheet(wb, ws, 'Locations');

                    /* save to file */
                    const wbout: string = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
                    saveAs(new Blob([this.s2ab(wbout)]), 'locations.xlsx');
                    this.data = null;
                    setTimeout(() => {
                        $('#exportLoadingLocation').modal('toggle');
                    }, 1000);
                }
                cont++;
            }
        } else {
            // setTimeout(() => {
            //   $('#exportLoadingLocation').modal('toggle');
            // }, 1000);
            // this.notExport.fire();
            const wscols: XLSX.ColInfo[] = [
                { wpx: 100 }, // "pixels"
                { wpx: 100 }, // "pixels"
                { wpx: 100 }, // "pixels"
                { wpx: 100 }, // "pixels"
                { wpx: 100 }, // "pixels"
                // { hidden: false } // hide column
            ];

            /* At 96 PPI, 1 pt = 1 px */
            const wsrows: XLSX.RowInfo[] = [
                { hpx: 25 }, // "pixels"
            ];

            /* generate worksheet */
            const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.dataExportLocations);

            /* TEST: column props */
            ws['!cols'] = wscols;

            /* TEST: row props */
            ws['!rows'] = wsrows;

            /* generate workbook and add the worksheet */
            const wb: XLSX.WorkBook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

            /* save to file */
            const wbout: string = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
            saveAs(new Blob([this.s2ab(wbout)]), 'locations.xlsx');
            this.data = null;
            setTimeout(() => {
                $('#exportLoadingLocation').modal('toggle');
            }, 1000);
        }
    }

    // PREPARA UM REGISTRO DE GRUPOS PARA EXPORTAÇÃO
    prepareLocationsExport(location: Location) {
        const row = [];

        row.push(location.identifier);
        row.push(location.name);
        row.push(location.image);
        row.push(location.description);

        return row;
    }

    // AJUDA A GERAR O ARQUIVO EXECL
    private s2ab(s: string): ArrayBuffer {
        const buf: ArrayBuffer = new ArrayBuffer(s.length);
        const view: Uint8Array = new Uint8Array(buf);
        for (let i = 0; i !== s.length; ++i) {
            view[i] = s.charCodeAt(i) & 0xFF;
        }
        return buf;
    }

    exportTemplate() {
        this.dataExportLocations = [];
        this.dataExportLocations = [[
            'Identifier*',
            'Name*',
            'Image (URL)',
            'Description'
        ]];

        const wscols: XLSX.ColInfo[] = [
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            // { hidden: false } // hide column
        ];

        /* At 96 PPI, 1 pt = 1 px */
        const wsrows: XLSX.RowInfo[] = [
            { hpx: 25 }, // "pixels"
        ];

        /* generate worksheet */
        const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.dataExportLocations);

        /* TEST: column props */
        ws['!cols'] = wscols;

        /* TEST: row props */
        ws['!rows'] = wsrows;

        /* generate workbook and add the worksheet */
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

        /* save to file */
        const wbout: string = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
        saveAs(new Blob([this.s2ab(wbout)]), 'template_locations.xlsx');
        this.data = null;


    }

    /********************************* IMPORT LOCATIONS ************************/

    /* GET EXCEL AND CONVERT TO JSON DATA */
    onFileChange(evt: any) {
        /* wire up file reader */
        this.nameFile = null;
        const target: DataTransfer = <DataTransfer>(evt.target);
        this.nameFile = target.files[0].name; // passa o nome do arquivo para o input

        if (target.files.length !== 1) {
            throw new Error('Cannot use multiple files');
        }

        const reader: FileReader = new FileReader();
        reader.onload = (e: any) => {
            /* read workbook */
            const bstr: string = e.target.result;
            const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

            /* grab first sheet */
            const wsname: string = wb.SheetNames[0];
            const ws: XLSX.WorkSheet = wb.Sheets[wsname];

            /* save data */
            this.data = <AOA>(XLSX.utils.sheet_to_json(ws, { header: 1 }));
        };
        reader.readAsBinaryString(target.files[0]);
    }

    checkImport() {
        this.loaderImport = true;
        this.messageErrors = [];
        this.errorImport = false;
        this.errorCheckImport = false;
        let validRows: number = 0;
        this.newLocations = [];

        // clean of undefined and empty data.
        if (this.data.length > 0) {
            for (const row of this.data) {
                for (let col = 0; col < this.localDescription; col++) {
                    if (typeof row[col] === 'undefined') {
                        row[col] = ""
                    }
                }
            }
        }

        if (this.data.length > 0) {
            let row = 1;
            for (const element of this.data) {

                // IGNORE EMPTY ROWS
                if (this.ignoreRowEmpty(element)) {
                    validRows++;
                    if (row > 1) {
                        const newLocation = new Location();
                        // VALIDATE IDENTIFIERS
                        if (typeof element[this.localId] !== 'undefined' && element[this.localId] !== '') {
                            // convert id type number for string
                            if (typeof element[this.localId] === 'number') {
                                const aux: number = element[this.localId];
                                element[this.localId] = aux.toString();
                            }
                            newLocation.identifier = element[this.localId];
                            this.checkNumberRowIds(newLocation.identifier, row);
                        } else {
                            const column = this.checkLetterCols(this.localId);
                            this.messageErrors.push(column + row + ": " + ' ' + this.translateService.instant('global.required_id'));
                        }

                        // VALIDATE NAME
                        if (typeof element[this.localName] !== 'undefined' && element[this.localName] !== '') {
                            if (typeof element[this.localName] == 'number') {
                                newLocation.name = element[this.localName].toString();
                            } else {
                                newLocation.name = element[this.localName];
                            }
                        } else {
                            const column = this.checkLetterCols(this.localName);

                            this.messageErrors.push(column + row + ": " + ' ' + this.translateService.instant('global.alert_name'));
                        }

                        // VALIDATE IMAGE  
                        if (typeof element[this.localImage] !== 'undefined') {
                            newLocation.image = element[this.localImage];
                        }

                        // VALIDATE DESCRIPTION
                        if (typeof element[this.localDescription] !== 'undefined') {
                            newLocation.description = element[this.localDescription];
                        }

                        this.newLocations.push(newLocation);
                        if (this.messageErrors.length > 0) {
                            this.loaderImport = false;
                            this.errorCheckImport = true;
                        }
                        this.clearInputFile(); // limpa o input do arquivo.
                    }
                }
                row++;
            }

            if (this.newLocations.length == validRows - 1 && this.errorCheckImport == false) {
                this.importLocations();
            }
        } else {
            this.loaderImport = false;
            this.messageErrors.push('global.no_file_selected');
        }
    }

    importLocations() {
        this.dbLocations.importLocations(this.moduleId, this.eventId, this.newLocations, (status) => {
            if (status['result']['fail'].length == 0) {
                if (status['result']['created'] !== undefined) { this.totalCreated = status['result']['created'].length; }
                if (status['result']['updated'] !== undefined) { this.totalUpdated = status['result']['updated'].length; }
                this.successImport = true;
                this.loaderImport = false;
                // this.successSwal.fire();
            } else {
                this.arrayFailedImport = status['result']['fail'];
                if (status['result']['fail'] !== undefined) { this.totalFail = status['result']['fail'].length; }
                this.errorImport = true;
                this.loaderImport = false;
            }
        })
    }

    // CLEAR INPUT AFTER IMPORT DATA
    clearInputFile() {
        this.inputFile.nativeElement.value = '';
        this.nameFile = null;
    }

    clearMessages() {
        this.errorImport = false;
        this.errorCheckImport = false;
        this.messageErrors = [];
        this.successImport = false;
        this.data = [];
        this.loaderImport = false;
        this.totalCreated = 0;
        this.totalUpdated = 0;
        this.totalFail = 0;
        this.newLocations = [];
    }

    /** IGNORE EMPTY ROWS */
    ignoreRowEmpty(row: any) {
        if (row.length < 1 || this.checkRowEmpty(row)) {
            return false;
        }
        return true;
    }

    /**VERIFY IF ROW IS EMPTY */
    checkRowEmpty(row: any) {
        for (const cel of row) {

            if (typeof cel !== 'undefined' && cel.length > 0) {
                return false;
            }
        }
        return true;
    }

    /* CHECK IF HAVE DOUBLE IDENTIFIERS */
    checkNumberRowIds(identifier: any, rowId: number) {
        let cont = 2;
        for (const location of this.newLocations) {
            if (location.identifier === identifier) {
                const column = this.checkLetterCols(this.localId);
                this.messageErrors.push(this.translateService.instant('global.equal_id') + ' ' + column + cont + ' ' + this.translateService.instant('global.and') + ' ' + column + rowId);
            }
            cont++;
        }
    }

    /* RETURN EXCEL COLUMN LETTER OF PARAMETER */
    checkLetterCols(column: number) {
        let letter = "";
        switch (column) {
            case this.localId: {
                letter = "A";
                break;
            }
            case this.localName: {
                letter = "B";
                break;
            }
            case this.localImage: {
                letter = "C";
                break;
            }

            case this.localDescription: {
                letter = "D";
                break;
            }
        }
        return letter;
    }

    async downloadImportError() {
        this.dataExportLocations = [];
        this.dataExportLocations = [[
            'Identifier*',
            'Name*',
            'Image (URL)',
            'Description'
        ]];

        // PEGA OS DADOS DE CADA PARTICIPANTE
        let cont = 0;
        for (let i = 0; i < this.arrayFailedImport.length; i++) {
            let location = this.arrayFailedImport[i];
            let row: any;
            row = await this.prepareLocationsExport(location);
            await this.dataExportLocations.push(row);

            if (cont == this.arrayFailedImport.length - 1) {
                const wscols: XLSX.ColInfo[] = [
                    { wpx: 100 }, // "pixels"
                    { wpx: 100 }, // "pixels"
                    { wpx: 100 }, // "pixels"
                    { wpx: 100 }, // "pixels"
                    { wpx: 100 }, // "pixels"
                    // { hidden: false } // hide column
                ];

                /* At 96 PPI, 1 pt = 1 px */
                const wsrows: XLSX.RowInfo[] = [
                    { hpx: 25 }, // "pixels"
                ];

                /* generate worksheet */
                const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.dataExportLocations);

                /* TEST: column props */
                ws['!cols'] = wscols;

                /* TEST: row props */
                ws['!rows'] = wsrows;

                /* generate workbook and add the worksheet */
                const wb: XLSX.WorkBook = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

                /* save to file */
                const wbout: string = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
                saveAs(new Blob([this.s2ab(wbout)]), 'location_errors.xlsx');
                this.data = null;
            }
            cont++;
        }

    }
}
