import { Component, OnInit, NgModule, OnDestroy } from '@angular/core';
import { DbAdminUserProvider } from '../../providers/database/db-admin-user';
import { GlobalService } from 'src/app/providers/global/global.service';
import { FilterPipe } from 'src/app/pipes/filter.pipe';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';


@Component({
    selector: 'app-god',
    templateUrl: './god.component.html',
    styleUrls: ['./god.component.scss']
})

@NgModule({
    declarations: [FilterPipe],
})

export class GodComponent implements OnInit, OnDestroy {
    godsSubscriptions: Subscription;
    public listUsers = [];
    public p: number = 1;
    public deleteUserId: string;
    deleteUserIndex: number;
    loader: boolean = true;
    userId: string = null;
    typeOrder: string = 'asc';
    term: string = '';

    constructor(
        private dbAdminUser: DbAdminUserProvider,
        private global: GlobalService
    ) {

        this.global.loadService(() => {
            this.userId = this.global.userId;
            this.loadOrder().then(() => {
                this.getGods();
            });
        })
    }

    ngOnInit() {
    }

    /**
     * Unsubscribe all subscriptions
     */
    ngOnDestroy() {
        if (this.godsSubscriptions && !this.godsSubscriptions.closed) {
            this.godsSubscriptions.unsubscribe();
        }
    }

    loadOrder() {
        return new Promise((resolve) => {
            this.userId = this.global.userId;
            this.dbAdminUser.getUser(this.userId, (data) => {
                let user = data.result;
                if (user.godsOrder !== undefined && user.godsOrder !== null && user.godsOrder !== '') {
                    this.typeOrder = user.godsOrder;
                }

                resolve(true);
            })
        })
    }


    getGods() {
        if (this.godsSubscriptions && !this.godsSubscriptions.closed) {
            this.godsSubscriptions.unsubscribe();
        }
        this.godsSubscriptions = this.dbAdminUser.getGods(this.typeOrder).subscribe((users) => {
            this.listUsers = users;
            this.loader = false;
        })
    }

    changeOrder() {
        this.dbAdminUser.changeOrderGods(this.userId, this.typeOrder, (data) => {
            if (data == true) {
                this.getGods();
            }
        })
    }

    getUserRemove(userId, index) {
        this.deleteUserId = userId;
        this.deleteUserIndex = index;
    }

    removeUser() {
        this.loader = true;
        this.dbAdminUser.removeUser(this.deleteUserId).subscribe((data) => {
            if (data == true) {
                this.listUsers.splice(this.deleteUserIndex, 1);
            }
            this.loader = false;
        })
    }

}
