import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DbSurveyProvider } from 'src/app/providers/database/db-survey';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { DbEventsProvider } from 'src/app/providers/database/db.events';
import { Languages } from 'src/app/models/languages';
import { GlobalService } from 'src/app/providers/global/global.service';
import { DragulaService } from 'ng2-dragula';
import { DragulaOptions } from 'dragula';
import { LuxonService } from 'src/app/providers/luxon/luxon.service';
import { Survey } from 'src/app/models/survey';
import { NameModule } from 'src/app/models/name-module';
import { TypeSurvey } from 'src/app/models/type-survey';
import { Question } from 'src/app/models/question';
import { Answer } from 'src/app/models/answer';


type AOA = Array<Array<any>>;
declare let $: any;

@Component({
    selector: 'app-survey',
    templateUrl: './survey.component.html',
    styleUrls: ['./survey.component.scss']
})
export class SurveyComponent implements OnInit {
    @ViewChild('successSwal') public successSwal: SwalComponent;
    @ViewChild('errorSwal') public errorSwal: SwalComponent;
    @ViewChild('errorEmptySwal') public errorEmptySwal: SwalComponent;
    @ViewChild('clearAllResultsSurveySwal') public clearAllResultsSurveySwal: SwalComponent;
    @ViewChild('clearSurveySwal') public clearSurveySwal: SwalComponent;
    @ViewChild('removeSurveySwal') public removeSurveySwal: SwalComponent;
    @ViewChild('clearQuestionSwal') public clearQuestionSwal: SwalComponent;

    languages: Languages = null //event languages
    // get the language of the user.
    public userLanguage: string;
    public surveyExportId: string = null;
    public questionExportId: string = null;
    public exportLanguage: string = null;
    public typeExport: string = null;
    dragulaOptions: DragulaOptions = {
        moves: () => true,
    }

    onReorderShow: boolean = false;
    loaderOrder: boolean = false;

    surveyView: boolean = true;
    moduleId: string = null
    eventId: string = null;
    eventTimezone: string = null;
    loader: boolean = true;
    public surveys: Array<any> = [];
    public surveyRemoveId;
    surveyRemoveIndex;
    public surveyClearId;
    public questionClearId;

    // exportFileType: string = "excel";

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private db: DbSurveyProvider,
        private dbEvent: DbEventsProvider,
        private global: GlobalService,
        private dragula: DragulaService,
        private luxon: LuxonService,
    ) {
        // get module id
        this.moduleId = this.route.snapshot.params['moduleId'];
        this.eventId = this.route.pathFromRoot[1]['params']['_value']['uid'];

        dragula.createGroup('bag-survey', this.dragulaOptions);
    }


    ngAfterContentChecked() {
        let auxRoute = this.router.url;
        if (auxRoute.includes('create-survey') || auxRoute.includes('edit-survey')) {
            this.surveyView = false;
        } else {
            this.surveyView = true;
        }
    }

    ngOnInit() {
        this.db.getSurveys(this.moduleId, (surveys: Array<any>) => {
            this.surveys = [];
            this.surveys = surveys;
            this.loader = false;
        })

        this.getEventTimezone();
        this.getUserLanguage();
        this.getEvent();

        // start dragula reorder bag
        this.dragula.dropModel('bag-survey').subscribe((value: any) => {
            this.onReorder(value);
        });
    }

    ngOnDestroy() {
        // remove dragula reorder bag case exit component
        this.dragula.destroy('bag-survey');
    }

    // update order of modules
    onReorder(order: any): void {
        this.onReorderShow = true;
        this.surveys = order.targetModel;
        for (let i = 0; i < (this.surveys).length; ++i) {
            this.surveys[i].order = i;
        }
    }

    saveNewOrder() {
        this.loaderOrder = true;

        this.db.changeOrder(this.moduleId, this.surveys, (result) => {
            if (result == true) {
                this.successSwal.fire();
            } else {
                this.errorSwal.fire();
            }

            this.loaderOrder = false;
            this.onReorderShow = false;
        })
    }

    getEventTimezone() {
        this.dbEvent.getTimezone(this.eventId, (timezone) => {
            this.eventTimezone = timezone;
        })
    }

    // get the language of the user.
    getUserLanguage() {
        this.global.getLanguage((language) => {
            this.userLanguage = language
        })
    }

    getEvent() {
        this.dbEvent.getEvent(this.eventId, (event) => {
            this.exportLanguage = this.convertLangFormat(event.language);
            this.languages = event.languages;
        })
    }

    convertLangFormat(lang) {
        let formatedLang;

        switch (lang) {
            case 'pt_BR': {
                formatedLang = 'PtBR'
                break;
            }
            case 'en_US': {
                formatedLang = 'EnUS';
                break;
            }
            case 'es_ES': {
                formatedLang = 'EsES';
                break;
            }
            case 'fr_FR': {
                formatedLang = 'FrFR';
                break;
            }
            case 'de_DE': {
                formatedLang = 'DeDE';
                break;
            }
        }
        return formatedLang;
    }

    changeVisibilitySurvey(surveyId, visibility) {
        this.db.changeVisibility(this.moduleId, surveyId, !visibility);
    }

    changeVisibilityQuestion(surveyId, questionId, visibility, surveyIndex, questionIndex) {
        this.db.changeVisibilityQuestion(this.moduleId, surveyId, questionId, !visibility);


        if (visibility) {
            this.surveys[surveyIndex].questions[questionIndex].visibility = false;
        } else {
            this.surveys[surveyIndex].questions[questionIndex].visibility = true;
        }
    }

    enableAll() {
        for (let survey of this.surveys) {
            this.db.changeVisibility(this.moduleId, survey.uid, true);
        }
    }

    disableAll() {
        for (let survey of this.surveys) {
            this.db.changeVisibility(this.moduleId, survey.uid, false);
        }
    }

    expandirQuestions(index, survey) {

        (survey.expand) ? survey.expand = false : survey.expand = true;
        let aux = '.hidden';
        aux = aux.concat(index);

        if (this.surveys[index].questions.length > 0) {
            this.showAndHiddenCollapse(aux);
        } else {
            this.db.getQuestions(this.moduleId, this.surveys[index].uid, (questions) => {
                this.surveys[index].questions = questions;

                setTimeout(() => {
                    this.showAndHiddenCollapse(aux);
                }, 1000);
            })
        }

    }

    /**
     * Hide or show collapse based on a query
     * @param query 
     */
    showAndHiddenCollapse(query) {
        if ($(query).hasClass('hideAll')) {
            $(query).removeClass('hideAll');
            $(query).show();
        } else {
            $(query).addClass('hideAll');
            $(query).hide();
        }
    }

    getRemoveQuestion(surveyId, questionId, index) {

    }

    removeQuestion() {

    }

    getRemoveSurvey(surveyId, index) {
        this.surveyRemoveId = surveyId;
        this.surveyRemoveIndex = index;
        this.removeSurveySwal.fire();
    }

    removeSurvey() {
        this.loader = true;
        this.db.removeSurvey(this.moduleId, this.surveyRemoveId, (data) => {
            if (data.code == 200) {
                this.successSwal.fire();
                this.surveys.splice(this.surveyRemoveIndex, 1);
                this.loader = false;
            }
        })
    }

    /**
     * Clear all results for survey
     */
    clearAllResults() {
        let listSurveysIds = [];
        this.loader = true;
        for (let survey of this.surveys) {
            listSurveysIds.push(survey.uid);
        }

        this.db.clearAllSurveys(this.moduleId, listSurveysIds).subscribe((data: any) => {
            this.loader = false;
            if (data.code == 200) {
                this.successSwal.fire();
            } else {
                this.errorSwal.fire();
            }
        }, (error) => {
            this.loader = false;
            this.errorSwal.fire();
        })
    }

    /**
     * Temp data and launch swal
     * @param survey 
     */
    getSurveyClear(survey) {
        this.surveyClearId = survey.uid;
        this.clearSurveySwal.fire();
    }

    /**
     * Clear result of a survey
     */
    clearSurvey() {
        this.loader = true;
        this.db.clearSurvey(this.moduleId, this.surveyClearId).subscribe((data: any) => {
            this.loader = false;
            if (data.code == 200) {
                this.successSwal.fire();
            } else {
                this.errorSwal.fire();
            }
        }, (error) => {
            this.loader = false;
            this.errorSwal.fire();
        })
    }

    /**
     * Temp data and launch swal
     * @param surveyId 
     * @param questionId 
     */
    getQuestionClear(surveyId, questionId) {
        this.surveyClearId = surveyId;
        this.questionClearId = questionId;
        this.clearQuestionSwal.fire();
    }

    /**
     * Clear a question
     */
    clearQuestion() {
        this.loader = true;
        this.db.clearQuestion(this.moduleId, this.surveyClearId, this.questionClearId).subscribe((data: any) => {
            this.loader = false;
            if (data.code == 200) {
                this.successSwal.fire();
            } else {
                this.errorSwal.fire();
            }
        }, (error) => {
            this.loader = false;
            this.errorSwal.fire();
        })
    }

    exportData() {
        $('#exportModal').modal('hide');

        switch (this.typeExport) {
            case 'survey':
                this.exportOneSurvey(this.surveyExportId, this.exportLanguage)
                break;

            case 'question':
                this.exportQuestion(this.surveyExportId, this.questionExportId, this.exportLanguage)
                break;

            case 'allSurvey':
                this.exportSurveys(this.exportLanguage)
                break;
        }
    }

    exportSurveys(language) {
        $('#exportLoading').modal({ backdrop: 'static', keyboard: false })

        let dataExportSurvey: Array<any> = [];
        let cont = 0;
        for (let survey of this.surveys) {
            this.prepareExportSurvey(survey.uid, language, (data) => {
                console.log("Data: ", data);

                if (data !== null) {
                    dataExportSurvey.push(data)
                }

                if (cont == this.surveys.length - 1) {
                    $('#exportLoading').modal('toggle');
                    if (dataExportSurvey.length > 0) {
                        this.export(dataExportSurvey, 'Surveys');
                    } else {
                        this.errorEmptySwal.fire();
                    }
                }

                cont++;
            })

        }
    }

    exportOneSurvey(surveyId, language) {
        $('#exportLoading').modal({ backdrop: 'static', keyboard: false })

        this.prepareExportSurvey(surveyId, language, (data) => {
            let dataExportSurvey: Array<any> = [];

            if (data !== null) {
                dataExportSurvey.push(data)
                $('#exportLoading').modal('toggle');
                this.export(dataExportSurvey, 'Survey');
            } else {
                $('#exportLoading').modal('toggle');
                this.errorEmptySwal.fire();
            }
        })
    }

    exportQuestion(surveyId, questionId, language) {
        let dataExport = [];
        dataExport = [[
            'Question',
            'Name',
            'E-mail',
            'Answer',
            'Marker',
            'Date'
        ]];

        this.db.exportQuestion(this.moduleId, surveyId, questionId, language, (data) => {
            for (let result of data.result) {
                dataExport.push(this.prepareResult(result));
            }

            let dataExportQuestion = [dataExport];
            this.export(dataExportQuestion, 'Question')
        })
    }

    prepareExportSurvey(surveyId, language, onResolve) {

        let dataExportSurvey = [];
        dataExportSurvey = [[
            'Question',
            'Name',
            'E-mail',
            'Answer',
            'Marker',
            'Date'
        ]];

        this.db.exportSurveys(this.moduleId, surveyId, language, (data) => {
            if (data.code == 200) {
                let listResult = data.result;

                if (listResult.length <= 0) {
                    onResolve(null);
                }
                let cont = 0;
                for (let result of listResult) {
                    dataExportSurvey.push(this.prepareResult(result));

                    if (cont == listResult.length - 1) {
                        onResolve(dataExportSurvey);
                    }
                    cont++;
                }
            } else {
                $('#exportLoading').modal('toggle');
                this.errorSwal.fire();
            }
        })

    }

    prepareResult(result) {
        let array = [];
        array[0] = result.question;
        array[1] = result.name;
        array[2] = result.email;
        array[3] = result.answer;
        array[4] = result.marker;

        let date = this.luxon.convertTimestampToDate(result.timestamp)
        let day = '0' + date.day;
        let month = '0' + date.month;
        let year = date.year;
        let hours = date.hour;
        let minutes = '0' + date.minute;
        let seconds = '0' + date.second;

        array[5] = day.substr(-2) + '/' + month.substr(-2) + '/' + year + ' - ' + hours + ':' +
            minutes.substr(-2) + ':' + seconds.substr(-2);

        return array;

    }

    export(listExport, fileName) {
        let totalFlaps = listExport.length;
        let result: Array<any> = [];
        // if (this.exportFileType == "pdf") {
        //     this.exportPdf(listExport, fileName);
        // }

        /* generate workbook and add the worksheet */
        const wb: XLSX.WorkBook = XLSX.utils.book_new();

        for (let i = 0; i < totalFlaps; i++) {

            const wscols: XLSX.ColInfo[] = [
                { wpx: 100 }, // "pixels"
                { wpx: 100 }, // "pixels"
                { wpx: 100 }, // "pixels"
                { wpx: 100 }, // "pixels"
                { wpx: 100 }, // "pixels"
                { wpx: 100 }, // "pixels"
                { wpx: 100 }, // "pixels"
                { wpx: 100 }, // "pixels"
                { wpx: 100 }, // "pixels"
                { wpx: 100 }, // "pixels"
                { wpx: 100 }, // "pixels"
                { hidden: false } // hide column
            ];

            /* At 96 PPI, 1 pt = 1 px */
            const wsrows: XLSX.RowInfo[] = [
                { hpx: 25 }, // "pixels"
            ];

            /* generate worksheet */
            const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(listExport[i]);

            /* TEST: column props */
            ws['!cols'] = wscols;

            /* TEST: row props */
            ws['!rows'] = wsrows;

            result[i] = ws;


            XLSX.utils.book_append_sheet(wb, result[i], 'Sheet' + (i + 1));
        }

        /* save to file */
        const wbout: string = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
        saveAs(new Blob([this.s2ab(wbout)]), fileName + '.xlsx');
    }

    /**
     * Export document
     * @param listExport 
     * @param filename 
     */
    // exportPdf(listExport, filename: string) {
    //     let docsUrl: string[] = [];

    //     for (let survey of listExport[0]) {
    //         console.log("Ssurvey: ", survey);
    //         if (survey[3] && typeof survey[3] === 'string' && survey[3].includes('firebasestorage.googleapis.com')) {
    //             docsUrl.push(survey[3]);
    //         }
    //     }

    //     console.log("Docs url: ", docsUrl);
    // }

    // AJUDA A GERAR O ARQUIVO EXECL
    private s2ab(s: string): ArrayBuffer {
        const buf: ArrayBuffer = new ArrayBuffer(s.length);
        const view: Uint8Array = new Uint8Array(buf);
        for (let i = 0; i !== s.length; ++i) {
            view[i] = s.charCodeAt(i) & 0xFF;
        }
        return buf;
    }

    typeCreation;
    onChange(event){
        this.typeCreation = event.target.value;
    }

    duplicatedSurveyID;
    sequencedSurveyID;
    selectSurevey(event: any, type: string){
        if(type === 'duplicateSurvey')
        this.duplicatedSurveyID = event.target.value
        else
        this.sequencedSurveyID = event.target.value
    }

    validateModalCreation(){
        this.loader = true;
        switch (this.typeCreation) {
            case 'newSurvey':
                $('#newSurveyModal').modal('hide');
                this.loader = false;
                document.getElementById('createNewSurvey').click();
                break;
            case 'duplicateSurvey':
                if(this.duplicatedSurveyID !== 'none' && this.duplicatedSurveyID !== undefined && this.duplicatedSurveyID !== null) {
                    const duplicatedSurvey = this.surveys.filter(item => item.uid === this.duplicatedSurveyID)[0]
                    const nbr = this.surveys.filter(item => (item.title.PtBR === duplicatedSurvey.title.PtBR &&
                        item.title.EnUS === duplicatedSurvey.title.EnUS && 
                        item.title.EsES === duplicatedSurvey.title.EsES &&
                        item.title.FrFR === duplicatedSurvey.title.FrFR &&
                        item.title.DeDE === duplicatedSurvey.title.DeDE) || 

                        (item.title.PtBR.includes(duplicatedSurvey.title.PtBR)  &&
                            item.title.EnUS.includes(duplicatedSurvey.title.EnUS) && 
                            item.title.EsES.includes(duplicatedSurvey.title.EsES) &&
                            item.title.FrFR.includes(duplicatedSurvey.title.FrFR) &&
                            item.title.DeDE.includes(duplicatedSurvey.title.DeDE) )).length
                        // (item.title.PtBR.substr(0,item.title.PtBR.length - 4) === duplicatedSurvey.title.PtBR &&
                        //     item.title.EnUS.substr(0,item.title.EnUS.length - 4)  === duplicatedSurvey.title.EnUS && 
                        //     item.title.EsES.substr(0,item.title.EsES.length - 4)  === duplicatedSurvey.title.EsES &&
                        //     item.title.FrFR.substr(0,item.title.FrFR.length - 4)  === duplicatedSurvey.title.FrFR &&
                        //     item.title.DeDE.substr(0,item.title.DeDE.length - 4)  === duplicatedSurvey.title.DeDE)).length
                    // console.log(duplicatedSurvey)
                    // console.log(typeof duplicatedSurvey)
                    const survey = new Survey();
                    survey.title = new NameModule(duplicatedSurvey.title.PtBR + ` (${nbr})`, duplicatedSurvey.title.EnUS + ` (${nbr})`, duplicatedSurvey.title.EsES + ` (${nbr})`, duplicatedSurvey.title.FrFR + ` (${nbr})`, duplicatedSurvey.title.DeDE + ` (${nbr})`);
                    survey.type = duplicatedSurvey.type;
                    survey.icon = duplicatedSurvey.icon;
                    survey.iconFamily = duplicatedSurvey.iconFamily;
                    survey.change_answer = duplicatedSurvey.change_answer;
                    survey.view_answered = duplicatedSurvey.view_answered;
                    survey.visibility = duplicatedSurvey.visibility;
                    survey.module_id = duplicatedSurvey.module_id;
                    
                    survey.question = [...duplicatedSurvey.questions].map(q => {
                        const question = new Question()
                        question.mainTitle = q.mainTitle;
                        question.title = q.title;
                        question.infobooth = q.infobooth;
                        question.type = q.type;
                        question.points = q.points;
                        question.visibility = q.visibility;
                        // question.anonymous = q.anonymous;
                        // get answers 
                        this.db.getAnswers(this.moduleId, duplicatedSurvey.uid, q.uid, (list) => {
                            if (question.type == "oneSelect" || question.type == "multipleSelect") {
                                question.answers = [...list].map(a => {
                                    const answer = new Answer()
                                    answer.answer = a.answer
                                    answer.weight = a.weight
                                    return answer
                                });
                            }
                        })
                        return question
                    });

                    // survey.questions = duplicatedSurvey.questions;
                    survey.order = this.surveys.length;
                    survey.weight = duplicatedSurvey.weight;
                    survey.marker = duplicatedSurvey.marker ;
                    survey.forceComplete = duplicatedSurvey.forceComplete;
                    survey.totalQuestions = duplicatedSurvey.totalQuestions;
                    $('#newSurveyModal').modal('hide');
                    switch (survey.type) {
                        case TypeSurvey.Pattern:
                            this.db.createSurvey(this.moduleId, survey, "", null, this.eventTimezone, async (data) => {
                                if (data) {
                                    await this.successSwal.fire()
                                    this.loader = false;
                                }
                            });
                            break;
    
                        case TypeSurvey.AllSessions:
                            this.db.createSurvey(this.moduleId, survey, "", null, this.eventTimezone, async(data) => {
                                if (data) {
                                    await this.successSwal.fire()
                                    this.loader = false;
                                }
                            })
                            break;
    
                        case TypeSurvey.ScheduleModule:
                            // survey.module_id = scheduleModuleId;
                            this.db.createSurvey(this.moduleId, survey, duplicatedSurvey.module_id, null, this.eventTimezone, async(data) => {
                                if (data) {
                                    await this.successSwal.fire()
                                    this.loader = false;
                                }
                            });
                            break;
    
                        /* case TypeSurvey.SessionTrack:
                            // survey.module_id = trackModuleId;
                            this.db.createSurvey(this.moduleId, survey, duplicatedSurvey.module_id, this.selectedTracks, this.eventTimezone, (data) => {
                                if (data) {
                                    // this.returnSurveyList();
                                }
    
                                this.loader = false;
                            });
                            break;
    
                        case TypeSurvey.SpecificSession:
                            // survey.module_id = sessionModuleId;
                            this.db.createSurvey(this.moduleId, survey, duplicatedSurvey.module_id, this.selectedSessions, this.eventTimezone, (data) => {
                                if (data) {
                                    // this.returnSurveyList();
                                }
    
                                this.loader = false;
                            });
                            break; */
    
                        case TypeSurvey.SpecificGroup:
                            this.db.createSurvey(this.moduleId, survey, null, duplicatedSurvey.references, this.eventTimezone, async(data) => {
                                if (data) {
                                    await this.successSwal.fire()
                                    this.loader = false;
                                }
                            });
                            break;
                    }
                } else{
                    $('#newSurveyModal').modal('hide');
                    this.loader = false;
                }
                break;
            case 'sequenceSurvey':
                
                break;
        
            default:
                break;
        }
        
    }
}
