import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';
import { DbAnalyticsProvider } from 'src/app/providers/database/db-analytics';
import { DbEventsProvider } from 'src/app/providers/database/db.events';
import { FormatedEventLanguageService } from 'src/app/providers/formated-event-language/formated-event-language.service';
import { GlobalService } from 'src/app/providers/global/global.service';
import { AppState } from 'src/app/shared/reducers';
import { getFeedsAnalytics } from 'src/app/shared/selectors/analytics.selectors';
import * as moment from 'moment';

@Component({
    selector: 'app-feed-analytics',
    templateUrl: './feed-analytics.component.html',
    styleUrls: ['./feed-analytics.component.scss']
})
export class FeedAnalyticsComponent implements OnInit {
    loader: boolean = true;

    event: any;
    eventId: string;
    userLanguage: string;

    totalFeedsPosts: number = 0;
    totalFeedsLikes: number = 0;
    totalFeedsComments: number = 0;
    bestLikedFeeds: any[] = [];
    bestCommentedFeeds: any[] = [];

    constructor(
        private route: ActivatedRoute,
        private dbEvent: DbEventsProvider,
        private dbAnalytics: DbAnalyticsProvider,
        private global: GlobalService,
        public formatedLanguage: FormatedEventLanguageService,
        private store: Store<AppState>
    ) { }

    ngOnInit() {
        this.route.params.subscribe((params) => {
            this.eventId = params.uid;
            this.getUserLanguage()
            this.getEvent();
        })
    }

    getUserLanguage() {
        this.global.getLanguage((language) => {
            this.userLanguage = this.formatedLanguage.convertLang(language);
        })
    }

    getEvent() {
        this.dbEvent.getEvent(this.eventId, (event) => {
            this.event = event;
            this.formatedLanguage.convertLangFormat(event.language);
            this.dbAnalytics.getFeedsAnalytics(this.eventId).pipe(take(1)).subscribe(() => {
                this.store.select(getFeedsAnalytics).subscribe((feedsAnlytics) => {
                    this.totalFeedsPosts = (feedsAnlytics && feedsAnlytics.totalFeedsPosts) ? feedsAnlytics.totalFeedsPosts : 0;
                    this.totalFeedsLikes = (feedsAnlytics && feedsAnlytics.totalFeedsLikes) ? feedsAnlytics.totalFeedsLikes : 0;
                    this.totalFeedsComments = (feedsAnlytics && feedsAnlytics.totalFeedsComments) ? feedsAnlytics.totalFeedsComments : 0;
                    this.bestLikedFeeds = (feedsAnlytics && feedsAnlytics.bestLikedFeeds) ? feedsAnlytics.bestLikedFeeds : [];
                    this.bestCommentedFeeds = (feedsAnlytics && feedsAnlytics.bestCommentedFeeds) ? feedsAnlytics.bestCommentedFeeds : [];

                    this.loader = false;
                })
            });
        });
    }

    /**
     * Export analytics of chats
     */
    exportAll() {
        this.loader = true;
        this.dbAnalytics.exportFeedsAnalytics(this.eventId, this.userLanguage).subscribe(() => {
            this.loader = false;
        }, (error) => {
            this.loader = false;
        })
    }

    convertDate(date: number) {
        return (moment.unix(date).format("DD/MM/YYYY - HH:mm"));
    }
}
