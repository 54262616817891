import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { Notification } from '../../../../models/notifications';
import { Event } from '../../../../models/event';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DbNotificationsProvider } from 'src/app/providers/database/db-notifications';
import { DbEventsProvider } from 'src/app/providers/database/db.events';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { NotifiicationDateService } from 'src/app/providers/luxon/notification-date.service';
declare let $: any;
type AOA = Array<Array<any>>;

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
  providers: [DbNotificationsProvider]
})
export class NotificationsComponent implements OnInit {
  notificationView: Notification = null;
  public p: number = 1;
  public eventId: string = null;
  public moduleId: string = null;
  term;
  loader: boolean = true;
  notifications: Array<Notification> = [];
  public notificationsSelected: Array<boolean> = [];
  public selectedAllInput: boolean = false;
  public typeOrder: string = null;

  // export notifications
  dataExportNofications: AOA = null;
  data: any = null;

  // import notifications
  public file = null;
  public fileName: string = null;
  nameFile: string = null;
  messageErrors: Array<any> = [];
  errorCheckImport: boolean = false;
  errorImport: boolean = false;
  loaderImport: boolean = false;

  successMsg: boolean = false;
  errorMsg: boolean = false;
  successImport: number = 0;
  failedImport: number = 0;
  failedArray: Array<Notification> = [];
  newNotifications: Array<Notification> = [];
  title_ptBR: number = 0;
  title_enUS: number = 1;
  title_esES: number = 2;
  title_frFR: number = 3;
  title_deDE: number = 4;
  message_ptBR: number = 5;
  message_enUS: number = 6;
  message_esES: number = 7;
  message_frFR: number = 8;
  message_deDE: number = 9;
  scheduled_date: number = 10;
  highlight_pic: number = 11;
  url: number = 12;
  send_to: number = 13;

  event: Event = null;
  event_language: string = null;
  dateNow: number = null;

  @ViewChild('successSwal') public successSwal: SwalComponent;
  @ViewChild('errorSwal') public errorSwal: SwalComponent;
  @ViewChild('notExport') public notExport: SwalComponent;
  @ViewChild('inputFile') public inputFile: ElementRef;

  notificationDeleteUid: string = null;
  module: any = null;
  showNotifPopUp = true;
  showChatPopup = false;
  constructor(
    private route: ActivatedRoute,
    private translateService: TranslateService,
    private daoNotification: DbNotificationsProvider,
    private daoEvent: DbEventsProvider,
    private luxon: NotifiicationDateService
  ) {
    this.eventId = this.route.parent.params['_value']['uid'];
    this.moduleId = this.route.snapshot.params['moduleId'];
  }

  ngOnInit() {
    this.daoNotification.getModule(this.moduleId, (module) => {
      this.module = module;
    });
    this.startEvent();
  }

  startEvent() {
    this.daoEvent.getEvent(this.eventId, (event: Event) => {
      this.event = event;
      this.showNotifPopUp = this.event['showNotifPopup'] !== undefined ? this.event['showNotifPopup'] : true;
      this.showChatPopup = this.event['showChatPopup'] !== undefined ? this.event['showChatPopup'] : true;
      this.event_language = event.language;
      this.typeOrder = event.notificationsOrder;
      if (this.event !== null) {
        this.startNotifications();
      }
    });
  }
  // start notifications
  startNotifications() {
    this.daoNotification.getAllNotifications(this.eventId, this.typeOrder, (notifications: Array<Notification>) => {
      // get date now
      this.dateNow = this.luxon.getTimeStampFromDateNow(new Date(), this.event.timezone);
      this.notifications = [];
      for (let notification of notifications) {
        // start nofication title and content
        notification['principal_title'] = "";
        notification['principal_content'] = "";

        // check event language to use in notification title/content
        switch (this.event_language) {
          case 'pt_BR':
            notification['principal_title'] = notification.headings['pt'];
            notification['principal_content'] = notification.contents['pt'];
            break;
          case 'en_US':
            notification['principal_title'] = notification.headings['en'];
            notification['principal_content'] = notification.contents['en'];
            break;
          case 'es_ES':
            notification['principal_title'] = notification.headings['es'];
            notification['principal_content'] = notification.contents['es'];
            break;
          case 'fr_FR':
            notification['principal_title'] = notification.headings['fr'];
            notification['principal_content'] = notification.contents['fr'];
            break;
          case 'de_DE':
            notification['principal_title'] = notification.headings['de'];
            notification['principal_content'] = notification.contents['de'];
            break;
        }

        if (notification['principal_title'] == "") {
          notification['principal_title'] = this.getNotificationTitle(notification.headings);
        }
        if (notification['principal_content'] == "") {
          notification['principal_content'] = this.getNotificationContent(notification.contents);
        }
      }
      this.notifications = notifications;
      this.loader = false;
    });
  }

  getNotificationTitle(headings) {
    for (let aux in headings) {
      if (headings[aux] !== '') {
        return headings[aux];
      }
    }
  }

  getNotificationContent(contents) {
    for (let aux in contents) {
      if (contents[aux] !== '') {
        return contents[aux];
      }
    }
  }

  // change order of notifications
  changeOrder() {
    this.daoNotification.changeOrderItems(this.eventId, this.typeOrder, (data) => {
      if (data == true) {
        this.startNotifications();
      }
    });
  }

  // select all notifications
  selectedAll() {
    if (this.selectedAllInput) {
      for (let i = 0; i < this.notifications.length; i++) {
        this.notificationsSelected[this.notifications[i]['uid']] = true;
      }
    } else {
      for (let i = 0; i < this.notifications.length; i++) {
        this.notificationsSelected[this.notifications[i]['uid']] = false;
      }
    }
  }

  /********************************* EXPORT ALL NOTIFICATIONS ************************/
  exportAllNotifications() {
    this.dataExportNofications = [];
    this.dataExportNofications = [[
      'Title PT-BR',
      'Title EN-US',
      'Title ES-ES',
      'Title FR-FR',
      'Message DE-DE',
      'Message PT-BR',
      'Message EN-US',
      'Message ES-ES',
      'Message FR-FR',
      'Message DE-DE',
      'Schedule date',
      'Highlight picture',
      'URL to open',
      'Send to (All or group name)'
    ]];

    let cont = 0;
    if (this.notifications.length > 0) {
      for (let notification of this.notifications) {

        let row: any;
        row = this.prepareLocationsExport(notification);
        this.dataExportNofications.push(row);

        if (cont == this.notifications.length - 1) {
          const wscols: XLSX.ColInfo[] = [
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            // { hidden: false } // hide column
          ];

          /* At 96 PPI, 1 pt = 1 px */
          const wsrows: XLSX.RowInfo[] = [
            { hpx: 25 }, // "pixels"
          ];

          /* generate worksheet */
          const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.dataExportNofications);

          /* TEST: column props */
          ws['!cols'] = wscols;

          /* TEST: row props */
          ws['!rows'] = wsrows;

          /* generate workbook and add the worksheet */
          const wb: XLSX.WorkBook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

          /* save to file */
          const wbout: string = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
          saveAs(new Blob([this.s2ab(wbout)]), 'notifications.xlsx');
          this.data = null;
          setTimeout(() => {
            $('#exportNotifications').modal('toggle');
          }, 1000);
        }
        cont++;
      }
    } else {
      setTimeout(() => {
        $('#exportNotifications').modal('toggle');
      }, 1000);
      this.notExport.fire();
    }
  }

  // PREPARA UM REGISTRO DE GRUPOS PARA EXPORTAÇÃO
  prepareLocationsExport(notification: Notification) {
    const row = [];

    row.push(notification.headings['pt_BR']);
    row.push(notification.headings['en_US']);
    row.push(notification.headings['es_ES']);
    row.push(notification.headings['fr_FR']);
    row.push(notification.headings['de_DE']);
    row.push(notification.contents['pt_BR']);
    row.push(notification.contents['en_US']);
    row.push(notification.contents['es_ES']);
    row.push(notification.contents['fr_FR']);
    row.push(notification.contents['de_DE']);
    row.push(notification.scheduled_date);
    row.push(notification.highlight_picture);
    row.push(notification.url);
    row.push(notification.send_to);

    return row;
  }

  // AJUDA A GERAR O ARQUIVO EXECL
  private s2ab(s: string): ArrayBuffer {
    const buf: ArrayBuffer = new ArrayBuffer(s.length);
    const view: Uint8Array = new Uint8Array(buf);
    for (let i = 0; i !== s.length; ++i) {
      view[i] = s.charCodeAt(i) & 0xFF;
    }
    return buf;
  }

  // EXPORT TEMPLATE
  exportTemplate() {
    this.dataExportNofications = [];
    this.dataExportNofications = [[
      'Title PT-BR',
      'Title EN-US',
      'Title ES-ES',
      'Title FR-FR',
      'Message DE-DE',
      'Message PT-BR',
      'Message EN-US',
      'Message ES-ES',
      'Message FR-FR',
      'Message DE-DE',
      'Schedule date',
      'Highlight picture',
      'URL to open',
      'Send to (All or group name)'
    ]];

    const wscols: XLSX.ColInfo[] = [
      { wpx: 100 }, // "pixels"
      { wpx: 100 }, // "pixels"
      { wpx: 100 }, // "pixels"
      { wpx: 100 }, // "pixels"
      { wpx: 100 }, // "pixels"
      { wpx: 100 }, // "pixels"
      { wpx: 100 }, // "pixels"
      { wpx: 100 }, // "pixels"
      { wpx: 100 }, // "pixels"
      { wpx: 100 }, // "pixels"
      { wpx: 100 }, // "pixels"
      { wpx: 100 }, // "pixels"
      { wpx: 100 }, // "pixels"
      { wpx: 100 }, // "pixels"
      { wpx: 100 }, // "pixels"
      // { hidden: false } // hide column
    ];

    /* At 96 PPI, 1 pt = 1 px */
    const wsrows: XLSX.RowInfo[] = [
      { hpx: 25 }, // "pixels"
    ];

    /* generate worksheet */
    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.dataExportNofications);

    /* TEST: column props */
    ws['!cols'] = wscols;

    /* TEST: row props */
    ws['!rows'] = wsrows;

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    const wbout: string = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
    saveAs(new Blob([this.s2ab(wbout)]), 'template_notifications.xlsx');
    this.data = null;
  }

  /********************************* IMPORT NOTIFICATIONS ************************/

  /* GET EXCEL AND CONVERT TO JSON DATA */
  onFileChange(evt: any) {
    /* wire up file reader */
    this.nameFile = null;
    const target: DataTransfer = <DataTransfer>(evt.target);
    this.nameFile = target.files[0].name; // passa o nome do arquivo para o input

    if (target.files.length !== 1) {
      throw new Error('Cannot use multiple files');
    }

    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      /* read workbook */
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

      /* grab first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      this.data = <AOA>(XLSX.utils.sheet_to_json(ws, { header: 1 }));
    };
    reader.readAsBinaryString(target.files[0]);
  }

  // check data before import
  checkImport() {
    this.loaderImport = true;
    this.messageErrors = [];
    this.errorImport = false;
    this.errorCheckImport = false;
    let validRows: number = 0;
    this.newNotifications = [];
    if (this.data.length > 0) {
      let row = 1;
      for (const element of this.data) {
        // IGNORE EMPTY ROWS
        if (this.ignoreRowEmpty(element)) {
          validRows++;
          if (row > 1) {
            const newNotification = new Notification();

            // VALIDATE IF ALL TITLES ARE BLANK
            if (
              typeof element[this.title_ptBR] == 'undefined' &&
              typeof element[this.title_enUS] == 'undefined' &&
              typeof element[this.title_esES] == 'undefined' &&
              typeof element[this.title_frFR] == 'undefined' &&
              typeof element[this.title_deDE] == 'undefined'
            ) {
              this.messageErrors.push(this.translateService.instant('comps.notifications.one_title_required'));
            }

            // VALIDATE PT-BR TITLE  
            if (typeof element[this.title_ptBR] !== 'undefined' && element[this.title_ptBR] !== '') {
              newNotification.headings['pt_BR'] = element[this.title_ptBR];
            }
            // VALIDATE EN-US TITLE  
            if (typeof element[this.title_enUS] !== 'undefined' && element[this.title_enUS] !== '') {
              newNotification.headings['en_US'] = element[this.title_enUS];
            }
            // VALIDATE ES-ES TITLE  
            if (typeof element[this.title_esES] !== 'undefined' && element[this.title_esES] !== '') {
              newNotification.headings['es_ES'] = element[this.title_esES];
            }
            // VALIDATE FR-FR TITLE  
            if (typeof element[this.title_frFR] !== 'undefined' && element[this.title_frFR] !== '') {
              newNotification.headings['fr_FR'] = element[this.title_frFR];
            }
            // VALIDATE DE-DE TITLE  
            if (typeof element[this.title_deDE] !== 'undefined' && element[this.title_deDE] !== '') {
              newNotification.headings['de_DE'] = element[this.title_deDE];
            }

            // VALIDATE IF ALL MESSAGES ARE BLANK
            if (
              typeof element[this.message_ptBR] == 'undefined' &&
              typeof element[this.message_enUS] == 'undefined' &&
              typeof element[this.message_esES] == 'undefined' &&
              typeof element[this.message_frFR] == 'undefined' &&
              typeof element[this.message_deDE] == 'undefined'
            ) {
              this.messageErrors.push(this.translateService.instant('comps.notifications.one_msg_required'));
            }

            // VALIDATE PT-BR MESSAGE  
            if (typeof element[this.message_ptBR] !== 'undefined' && element[this.message_ptBR] !== '') {
              newNotification.contents['pt_BR'] = element[this.message_ptBR];
            }
            // VALIDATE EN-US MESSAGE  
            if (typeof element[this.message_enUS] !== 'undefined' && element[this.message_enUS] !== '') {
              newNotification.contents['en_US'] = element[this.message_enUS];
            }
            // VALIDATE ES-ES MESSAGE  
            if (typeof element[this.message_esES] !== 'undefined' && element[this.message_esES] !== '') {
              newNotification.contents['es_ES'] = element[this.message_esES];
            }
            // VALIDATE FR-FR MESSAGE  
            if (typeof element[this.message_frFR] !== 'undefined' && element[this.message_frFR] !== '') {
              newNotification.contents['fr_FR'] = element[this.message_frFR];
            }
            // VALIDATE DE-DE MESSAGE  
            if (typeof element[this.message_deDE] !== 'undefined' && element[this.message_deDE] !== '') {
              newNotification.contents['de_DE'] = element[this.message_deDE];
            }

            // VALIDATE SCHEDULE DATE  
            if (typeof element[this.scheduled_date] !== 'undefined' && element[this.scheduled_date] !== '') {
              newNotification.scheduled_date = element[this.scheduled_date];
            }

            // VALIDATE HIGHLIGHT PICTURE  
            if (typeof element[this.highlight_pic] !== 'undefined' && element[this.highlight_pic] !== '') {
              newNotification.highlight_picture = element[this.highlight_pic];
            }

            // VALIDATE URL
            if (typeof element[this.url] !== 'undefined' && element[this.url] !== '') {
              newNotification.url = element[this.url];
            }

            // VALIDATE SEND TO
            if (typeof element[this.send_to] !== 'undefined' && element[this.send_to] !== '') {
              newNotification.send_to = element[this.send_to];
            } else {
              this.messageErrors.push(this.translateService.instant('comps.notifications.send_to_required'));
            }

            newNotification.principal_language = this.event.language;

            this.newNotifications.push(newNotification);
            if (this.messageErrors.length > 0) {
              this.loaderImport = false;
              this.errorCheckImport = true;
            }
            this.clearInputFile(); // limpa o input do arquivo.
          }
        }
        row++;
      }
      if (this.newNotifications.length == validRows - 1 && this.errorCheckImport == false) {
        this.importGroup();
      }
    }
  }

  importGroup() {
    // this.dbLocations.importLocations(this.moduleId, this.eventId, this.newLocations, (status) => {
    //   if (status['result']['fail'].length == 0) {
    //     if (status['result']['created'] !== undefined) { this.totalCreated = status['result']['created'].length; }
    //     if (status['result']['updated'] !== undefined) { this.totalUpdated = status['result']['updated'].length; }
    //     this.successImport = true;
    //     this.loaderImport = false;
    //     // this.successSwal.fire();
    //   } else {
    //     this.arrayFailedImport = status['result']['fail'];
    //     if (status['result']['fail'] !== undefined) { this.totalFail = status['result']['fail'].length; }
    //     this.errorImport = true;
    //     this.loaderImport = false;
    //   }
    // })
  }

  // CLEAR INPUT AFTER IMPORT DATA
  clearInputFile() {
    this.inputFile.nativeElement.value = '';
    this.nameFile = null;
  }

  clearMessages() {
    this.errorImport = false;
    this.errorCheckImport = false;
    this.messageErrors = [];
    this.successMsg = false;
    this.data = [];
    this.loaderImport = false;
    this.successImport = 0;
    this.failedImport = 0;
    this.newNotifications = [];
  }

  /** IGNORE EMPTY ROWS */
  ignoreRowEmpty(row: any) {
    if (row.length < 1 || this.checkRowEmpty(row)) {
      return false;
    }
    return true;
  }

  /**VERIFY IF ROW IS EMPTY */
  checkRowEmpty(row: any) {
    for (const cel of row) {
      if (typeof cel !== 'undefined' && cel.length > 0) {
        return false;
      }
    }
    return true;
  }

  removeSelected() {
    this.loader = true;
    let listRemove = [];
    // let listRemoveIndexes = [];

    for (let i = 0; i < this.notifications.length; i++) {
      if (this.notificationsSelected[this.notifications[i].uid] == true) {
        listRemove.push(this.notifications[i].uid);
      }
    }

    this.daoNotification.removeNotifications(this.eventId, listRemove, async (data) => {
      if (data) {
        //remove all selected box
        for (let i = 0; i < this.notificationsSelected.length; i++) {
          this.notificationsSelected[this.notifications[i].uid] = false;
        }
        // this.loader = false;
        this.selectedAllInput = false;
        await this.startNotifications();
        this.successSwal.fire();

      } else {
        this.loader = false;
        this.errorSwal.fire();
      }
    })
  }

  getNotificationDelete(uid: string) {
    this.notificationDeleteUid = uid;
  }

  deleteNotification() {
    this.loader = true;
    this.daoNotification.deleteNotification(this.eventId, this.notificationDeleteUid, (status) => {
      if (status == true) {
        this.successSwal.fire();
        this.loader = false;
      } else {
        this.errorSwal.fire();
        this.loader = false;
      }
    });
  }
  async popupVisibilityChanged(ev, type){
    this.showNotifPopUp = ev;
    await this.daoEvent.updatePopupVisibility(this.eventId,type, ev)
  }
}
