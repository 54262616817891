import { Injectable } from '@angular/core';
import firebase from 'firebase/app';
import { Image } from 'src/app/models/image';
import { PathStorage } from 'src/app/paths/path-storage';
import { StorageService } from '../storage/storage.service';

@Injectable({
    providedIn: 'root'
})

export class DbCustomPagesProvider {
    private db: firebase.firestore.Firestore;

    constructor(
        private storage: StorageService
    ) {
        this.db = firebase.firestore()
    }

    getModule(moduleId: string, onResolve) {
        const ref = this.db.collection('modules').doc(moduleId);

        ref
            .get()
            .then((result) => {
                onResolve(result.data());
            });
    }

    getPage(moduleId: string, pageId: string, onResolve) {
        const ref = this.db.collection('modules').doc(moduleId).collection('pages').doc(pageId)
        let page = null

        ref.onSnapshot((snapshot) => {
            page = snapshot.data()
            onResolve(page)
        })
    }

    getPagePromise(moduleId: string, pageId: string) {
        return this.db
                    .collection('modules')
                    .doc(moduleId)
                    .collection('pages')
                    .doc(pageId)
                    .get();
    }

    getPages(moduleId: string, onResolve) {
        const ref = this.db.collection('modules').doc(moduleId).collection('pages').orderBy('creationDate')

        ref.onSnapshot((snapshot) => {
            const list = []

            snapshot.forEach((childSnapshot) => {
                const page = childSnapshot.data()
                list.push(page)
            })

            onResolve(list)

        })
    }

    getTotalPagesModule(moduleId: string, onResolve) {
        console.log('db function')
        const ref = this.db.collection('modules').doc(moduleId).collection('pages');

        ref.get().then((snapshot) => {
            console.log(snapshot.size)
            onResolve(snapshot.size);
        })
    }    

    // retorna a id da custom page, como parampara da func passado em onResolve 
    createPage(page, onResolve) {
        return new Promise((resolve, reject) => {
            const moduleId = page.moduleId
            const eventId = page.eventId

            page.uid = this.db.collection('modules').doc().id
            const pageId = page.uid


            const batch = this.db.batch()

            const ref1 = this.db.collection('events').doc(eventId).collection('modules').doc(moduleId).collection('pages').doc(pageId)
            const ref2 = this.db.collection('modules').doc(moduleId).collection('pages').doc(pageId)

            console.log('page:', page);

            batch.set(ref1, Object.assign({}, page))
            batch.set(ref2, Object.assign({}, page))

            batch.commit().then(() => {
                onResolve(pageId);
                //resolve(true)
            }).catch((error) => {
                console.error(error);
                onResolve(null);
                //reject(false)
            })

        })
    }

        updatePage(page) {
        return new Promise((resolve, reject) => {
            const pageId = page.uid
            const moduleId = page.moduleId
            const eventId = page.eventId

            // console.log('Module Id: ', moduleId);
            // console.log('page Id: ', pageId);

            const batch = this.db.batch()

            const ref1 = this.db.collection('events').doc(eventId).collection('modules').doc(moduleId).collection('pages').doc(pageId)
            const ref2 = this.db.collection('modules').doc(moduleId).collection('pages').doc(pageId)

            batch.update(ref1, Object.assign({}, page))
            batch.update(ref2, Object.assign({}, page))

            batch.commit().then(() => {
                resolve(true)
            }).catch((error) => {
                console.log(error)
                reject(false)
            })
        })
    }

    deletePage(page) {
        return new Promise((resolve, reject) => {
            const moduleId = page.moduleId
            const eventId = page.eventId
            const pageId = page.uid


            const batch = this.db.batch()

            const ref1 = this.db.collection('events').doc(eventId).collection('modules').doc(moduleId).collection('pages').doc(pageId)
            const ref2 = this.db.collection('modules').doc(moduleId).collection('pages').doc(pageId)

            batch.delete(ref1)
            batch.delete(ref2)

            batch.commit().then(() => {
                this.reorderPages(moduleId, page.order, (data) => {
                    resolve(true)
                })
            }).catch((error) => {
                console.log(error)
                reject(false)
            })

        })
    }

    reorderPages(moduleId, orderDelete, onResolve) {
        let ref = this.db.collection('modules').doc(moduleId).collection('pages');

        ref.get().then((data) => {
            if(data.size > 0) {
                let listPages = [];

                data.forEach(element => {
                    listPages.push(element.data());    
                });

                let cont = 0;
                for(let page of listPages) {
                    if(page.order > orderDelete) {
                        page.order--;

                        ref.doc(page.uid).update(page).then(() => {
                            if(cont == listPages.length - 1) {
                                onResolve(true);
                            }

                            cont ++;
                        })
                    } else {
                        if(cont == listPages.length - 1) {
                            onResolve(true);
                        }

                        cont ++;
                    }
                }
            } else {
                onResolve(true);
            }
        })
    }

    reorderPagesList(moduleId: string, pagesList: Array<any>) {
        return new Promise((resolve, reject) => {
            let cont = 0;
            let size = pagesList.length;
            pagesList.forEach(el => {
                let page = el;
                
                this.db
                .collection('modules')
                .doc(moduleId)
                .collection('pages')
                .doc(page.uid)
                .update(page)
                .then((_) => {
                    cont++;
                    if (cont == size) {
                        resolve(true);
                    }
                })
                .catch((error) => {
                    error++;
                    if (error == size) {
                        reject(false);
                    }
                });
            });
        });
    }

    // inclui a ID e URL da img de capa de uma custom page do app, como campos do document pageId do firestore
    // relaciona um arquivo de img no firebase storage com a respectiva pageId no firestore
    saveCoverImage(eventId: string, moduleId: string, pageId: string, image: Image, onResolve) {
        let ref = this.db
            .collection('modules')
            .doc(moduleId)
            .collection('pages')
            .doc(pageId);
        
        ref.get()
            .then((snapshot) => {
                let imageId = snapshot.data().pageCoverId;
                console.log("id img anterior: ", imageId);

                // se ja existe uma img de capa da pasta no storage, exclui a img antiga
                if (imageId) {
                    this.storage.deleteImg(eventId, moduleId, pageId, imageId, PathStorage.customPages);
                }

                let update = ref.update({pageCoverImg: image.url , pageCoverId: image.storageId});

                update.then(() => {
                    console.log("saveImage works");
                    onResolve(true);
                })
                .catch((error) => {
                    console.log("erro na tentativa de salvar a img", error);
                    onResolve(false);
                })
            })
            .catch((error) => {
                console.log(error);
            });
    }

}