import { Injectable } from '@angular/core';
import firebase from 'firebase/app';
import { TypeModule } from 'src/app/enums/type-module';
import { StorageService } from '../storage/storage.service';
import { UUID } from 'angular2-uuid';
import { PathStorage } from 'src/app/paths/path-storage';
import { DbQuizProvider } from './db-quiz';
import { DbAskQuestionProvider } from './db-ask-question';
import { DbSurveyProvider } from './db-survey';
import { ModuleQuiz } from "../../models/modules/module-quiz";
import { ModuleSurvey } from "../../models/modules/module-survey";
import { ModuleAskQuestion } from "../../models/modules/module-ask-question";
@Injectable({
    providedIn: 'root'
})

export class DbInteractivityProvider {

    private db: firebase.firestore.Firestore

    moduleQuiz: ModuleQuiz;
    moduleSurvey: ModuleSurvey;
    moduleAskQuestion: ModuleAskQuestion;

    constructor(
        private storage: StorageService,
        private dbQuiz: DbQuizProvider,
        private dbAskQuestion: DbAskQuestionProvider,
        private dbSurvey: DbSurveyProvider
        ) {
            this.db = firebase.firestore()
        }
        
    newBanner(imgQuiz: File, imgAskQuestion: File, imgSurvey: File, eventId: string, moduleId: string, onResolve) {

        const ref = this.db.collection('events').doc(eventId).collection('modules');

        if (imgQuiz !== undefined || imgAskQuestion !== undefined || imgSurvey !== undefined) {
            
            if (imgQuiz !== undefined) {
                this.dbQuiz.getQuizModule(eventId, (res) => {
                    this.moduleQuiz = res[0];
                    
                    let imgId = UUID.UUID();
                    
                    this.storage.uploadImg(imgQuiz, eventId, moduleId, this.moduleQuiz.uid, imgId, PathStorage.quiz_module, (url) => {
                        this.moduleQuiz.imgQuiz = url;
                        
                        ref
                        .doc(this.moduleQuiz.uid)
                        .set(this.moduleQuiz);
                    });
                });
            }
            
        if (imgAskQuestion !== undefined) {
            let imgId = UUID.UUID();

            this.dbAskQuestion.getAskQuestionModule(eventId, (res) => {
                this.moduleAskQuestion = res[0];
                
                this.storage.uploadImg(imgAskQuestion, eventId, moduleId, this.moduleAskQuestion.uid, imgId, PathStorage.ask_question_module, (url) => {
                    this.moduleAskQuestion.imgAskQuestion = url;
                    
                    ref
                    .doc(this.moduleAskQuestion.uid)
                    .set(this.moduleAskQuestion);
                });
            });
        }
        
        if (imgSurvey !== undefined) {
            let imgId = UUID.UUID();
            
            this.dbSurvey.getSurveyModule(eventId, (res) => {
                this.moduleSurvey = res[0];
                
                this.storage.uploadImg(imgSurvey, eventId, moduleId, this.moduleSurvey.uid, imgId, PathStorage.survey_module, (url) => {
                    this.moduleSurvey.imgSurvey = url;
                    
                    ref
                    .doc(this.moduleSurvey.uid)
                    .set(this.moduleSurvey);
                })
            });
        }

        onResolve(true);
    }else{
        onResolve(false);
    }
        
    }

    getModule(moduleId: string, onResolve) {
        const ref = this.db.collection('modules').doc(moduleId);

        ref
            .get()
            .then((result) => {
                onResolve(result.data());
            });

    }

    getInteractivityModuleByEventId(eventId, onResolve) {
        let ref = this.db
            .collection('modules')
            .where('eventId', '==', eventId)
            .where('type', '==', TypeModule.INTERACTIVITY)

        ref.get()
            .then((data) => {
                let module = null;

                data.forEach(element => {
                    module = element.data();
                });

                onResolve(module)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    getListColorsCharts(moduleId, onResolve) {
        let ref = this.db
            .collection('modules')
            .doc(moduleId)
            .collection('colorsCharts')
            .orderBy('order', 'asc');

        ref.get()
            .then((data) => {

                if (data.size <= 0) {
                    onResolve(null);
                }
                let listColors: Array<any> = [];

                data.forEach(element => {
                    let color = element.data();
                    if (color !== undefined) {
                        listColors.push(color);
                    }
                });

                onResolve(listColors);
            })
    }

    addNewColorCharts(moduleId, color, onResolve) {
        let ref = this.db.collection('modules').doc(moduleId).collection('colorsCharts');
        let order = 0;

        ref.get()
            .then((data) => {
                if (data.size > 0) {
                    order = data.size;
                }

                let doc = ref.doc();
                let colorId = doc.id;

                let aux = {
                    color: color,
                    order: order,
                    uid: colorId
                }

                ref.doc(colorId).set(aux).then(() => {
                    onResolve(aux);
                })
            })
    }

    removeColorChart(moduleId: string, colorId: string, onResolve) {
        let ref = this.db
            .collection('modules')
            .doc(moduleId)
            .collection('colorsCharts')
            .doc(colorId);

        ref.get()
            .then((data) => {
                let color = data.data();
                let order = color.order;
                this.reorderColors(moduleId, order, (data) => {
                    if (data) {
                        ref.delete()
                            .then(() => {
                                onResolve(true)
                            })
                            .catch((err) => {
                                onResolve(err)
                            })
                    }
                })
            })

    }


    reorderColors(moduleId, removeOrder, onResolve) {

        let db = this.db;
        let batch = db.batch()

        let ref = db
            .collection('modules')
            .doc(moduleId)
            .collection('colorsCharts');

        ref.get().then((data) => {
            if (data.size > 0) {
                let listColors = []
                data.forEach(element => {
                    let color = element.data()
                    listColors.push(color)
                });

                for (let color of listColors) {
                    if (color.order > removeOrder) {
                        let newOrder = color.order - 1;

                        let refColor = ref.doc(color.uid);
                        batch.update(refColor, { order: newOrder })
                    }
                }

                batch.commit()
                    .then(() => {
                        onResolve(true)
                    })
                    .catch((err) => {
                        onResolve(false)
                    })

            } else {
                onResolve(true)
            }
        })
    }

    changeOrderColors(moduleId: string, listColors: any, onResolve) {
        let batch = this.db.batch();

        for (let color of listColors) {
            let ref = this.db.collection('modules').doc(moduleId).collection('colorsCharts').doc(color.uid)
            batch.update(ref, { order: color.order })
        }

        batch.commit()
            .then(() => {
                onResolve(true)
            })
            .catch((e) => {
                onResolve(false)
            })
    }


    updateChartOptions(eventId: string, options: any, onResolve) {
        let batch = this.db.batch();

        this.getInteractivityModuleByEventId(eventId, (module) => {
            let moduleId = module.uid;

            let refModule = this.db
                .collection('modules')
                .doc(moduleId)

            let refEvent = this.db
                .collection('events')
                .doc(eventId)
                .collection('modules')
                .doc(moduleId)

            let optionsObj = {};
            optionsObj['optionsChart'] = options;

            batch.update(refModule, optionsObj);
            batch.update(refEvent, optionsObj);

            batch.commit()
                .then((data) => {
                    onResolve(true);
                })
                .catch((err) => {
                    onResolve(false)
                })
        })

    }

    getOptionsChart(eventId: string, onResolve) {
        this.getInteractivityModuleByEventId(eventId, (module) => {
            let moduleId = module.uid;

            let refQuestion = this.db
                .collection('modules')
                .doc(moduleId)

            refQuestion.get()
                .then((data) => {
                    let doc = data.data();

                    if (doc['optionsChart'] == undefined) {
                        onResolve(null)
                    }

                    onResolve(doc['optionsChart'])
                })
                .catch((err) => {
                    console.log(err)
                })
        })

    }
}