import {
    ActionReducerMap,
    MetaReducer
} from '@ngrx/store';
import { environment } from 'src/environments/environment';

// Analytics
import { IAnalyticsState } from '../interfaces/analytics.interfaces';
import { analyticsReducer } from './analytics.reducers';


export interface AppState {
    analytics: IAnalyticsState,
}

export const reducers: ActionReducerMap<AppState> = {
    analytics: analyticsReducer
};

export const metaReducers: MetaReducer<AppState>[] = !environment.production ? [] : [];