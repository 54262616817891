import { Module } from '../modules/module';
import { TypeModule } from '../../enums/type-module';
import { Group } from '../group';
import { NameModule } from '../name-module';

export class ModuleSpeaker extends Module {
    speakers: Array<any>;
    typeVision: number;
    access_groups: string; //uid of the group that is allowed access to the module
    selected: boolean;
    orderUsers: string; // order type for display attendees in backoffice/application
    allowedEditProfile; // allows the attendee to edit your profile
    editProfileFirstAccess; // if enabled, the attendee is required to edit the profile on the first access
    groups: Array<Group>
    visOnlyForLoggedUser: boolean;

    constructor(name: NameModule, icon: string, typeVision: number, eventId: string, order: number, visOnlyForLoggedUser?: boolean) {
        super(name, icon, TypeModule.SPEAKER, eventId, order, true, true, true)
        this.typeVision = typeVision;
        this.access_groups = null;
        this.selected = false;
        this.speakers = [];
        this.orderUsers = 'asc';
        this.allowedEditProfile = true;
        this.editProfileFirstAccess = false;
        this.groups = [];
        if (visOnlyForLoggedUser === undefined) {
            this.visOnlyForLoggedUser = false;
        } else {
            this.visOnlyForLoggedUser = visOnlyForLoggedUser;
        }
    }
}