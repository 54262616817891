import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { PathApi } from '../../paths/path-api';
import { DbMailingProvider } from '../database/db-mailing';
import { Email } from 'src/app/models/email';

@Injectable({
    providedIn: 'root'
})
export class EmailProvider {
    public headers;
    public requestOptions;

    constructor(private http: HttpClient, private dbMailing: DbMailingProvider) {
        this.headers = new HttpHeaders();
        this.headers.append("Accept", 'application/json');
        this.headers.append("Content-Type", 'application/json');
        this.requestOptions = { headers: this.headers };
    }

    sendEmailToUser(email, onResolve) {
        this.http.post(PathApi.baseUrl + PathApi.emailsSendToUser, email, this.requestOptions)
            .subscribe((status) => {
                onResolve(status)
            });
    }

    sendEmailResetPassword(emails, titleEmail, passTitle, uidUser, onResolve) {
        let body = {
            emails: emails,
            title: titleEmail,
            uid: uidUser,
            password_title: passTitle
        }
        this.http.post(PathApi.baseUrl + PathApi.emailsResetPasswordUser, body, this.requestOptions)
            .subscribe((status) => {
                onResolve(status)
            });
    }
    sendEmailRoomPairInvite(email, onResolve) { }
    sendEmailRoomPairAccept(email, onResolve) { }
    sendEmailRoomPairDecline(email, onResolve) { }

    // sendEmailViaServer(emailReq, email: Email){
    //     this.http.post(PathApi.baseUrl + PathApi.emailsSendToUser, emailReq, this.requestOptions)
    //         .subscribe(async status => {
    //             await this.dbMailing.createEmail(email.eventId,email,emailReq.body,email.design)
    //             return Promise.resolve(status)
    //         })
    // }
}