import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Location } from '../../models/location';
import { StorageService } from '../storage/storage.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { PathApi } from '../../paths/path-api';

@Injectable({
    providedIn: 'root'
})

export class DbLocationsProvider {
    public headers;
    public requestOptions;

    constructor(private aFirestore: AngularFirestore, private storage: StorageService, private http: HttpClient) {
        this.headers = new Headers();
        this.headers.append("Accept", 'application/json');
        this.headers.append("Content-Type", 'application/json');
        this.requestOptions = { headers: this.headers };
    }



    getModule(moduleId: string, onResolve) {
        let db = this.aFirestore.firestore;

        db
            .collection('modules')
            .doc(moduleId)
            .onSnapshot((value) => {
                onResolve(value.data());
            });
    }

    getLocationsModule(moduleId: string, typeOrder: string, onResolve) {
        let db = this.aFirestore.firestore;
        let ref;

        switch (typeOrder) {
            case 'asc': //a-z
                ref = db.collection('modules').doc(moduleId).collection('locations')
                    .orderBy('name', 'asc');
                break;

            case 'desc': //z-a
                ref = db.collection('modules').doc(moduleId).collection('locations')
                    .orderBy('name', 'desc');
                break;

            case 'oldest'://antiho-recente
                ref = db.collection('modules').doc(moduleId).collection('locations')
                    .orderBy('createdAt', 'asc');
                break;

            case 'recent': //recente-antigo
                ref = db.collection('modules').doc(moduleId).collection('locations')
                    .orderBy('createdAt', 'desc');
                break;
        }

        let array = [];
        ref.onSnapshot((data) => {
            array = [];
            data.forEach(doc => {
                array.push(doc.data());
            });
            onResolve(array);
        });
    }

    getLocationsEvent(eventId: string, typeOrder: string, onResolve) {
        let db = this.aFirestore.firestore
        let ref;

        switch (typeOrder) {
            case 'asc': //a-z
                ref = db.collection('events').doc(eventId).collection('locations')
                    .orderBy('name', 'asc');
                break;

            case 'desc': //z-a
                ref = db.collection('events').doc(eventId).collection('locations')
                    .orderBy('name', 'desc');
                break;

            case 'oldest'://antiho-recente
                ref = db.collection('events').doc(eventId).collection('locations')
                    .orderBy('createdAt', 'asc');
                break;

            case 'recent': //recente-antigo
                ref = db.collection('events').doc(eventId).collection('locations')
                    .orderBy('createdAt', 'desc');
                break;
        }

        let array = [];

        ref.get()
            .then((snapshot) => {
                snapshot.forEach((location) => {
                    array.push(location.data())
                })
                onResolve(array)
            })

    }

    changeOrderItems(moduleId, typeOrder, onResolve) {
        let db = this.aFirestore.firestore;
        db.collection("modules").doc(moduleId).update({ orderLocations: typeOrder })
            .then((_) => {
                onResolve(true);
            })
            .catch((__) => {
                onResolve(false);
            });
    }

    newLocation(location: Location, image, onResolve) {
        const eventId = location.eventId
        const moduleId = location.moduleId

        location = Object.assign({}, location)

        let firestore = this.aFirestore.firestore;

        let refEvent = firestore.collection('events').doc(eventId).collection('locations').doc()
        location.uid = refEvent.id;

        let refModule = firestore.collection('modules').doc(moduleId).collection('locations').doc(location.uid)


        let batch = firestore.batch()


        if (image !== null) {
            this.storage.locationPicture(image, eventId, moduleId, location.uid, (url) => {

                if (url) {
                    location.image = url

                    batch.set(refModule, location);
                    batch.set(refEvent, location);

                    batch.commit()
                        .then((_) => {
                            onResolve(true);
                        })
                        .catch((error) => {
                            this.storage.deleteLocation(eventId, moduleId, refModule.id);
                            onResolve(false);
                        });
                }
            });
        } else {
            batch.set(refModule, location);
            batch.set(refEvent, location);

            batch.commit()
                .then((_) => {
                    onResolve(true);
                })
                .catch((error) => {
                    onResolve(false);
                });
        }

    }

    async editLocation(location: Location, image, onResolve) {
        const moduleId = location.moduleId
        const eventId = location.eventId
        const locationId = location.uid

        let firestore = this.aFirestore.firestore;

        let refEvent = firestore.collection('events').doc(eventId).collection('locations').doc(locationId);
        let refModule = firestore.collection('modules').doc(moduleId).collection('locations').doc(locationId);

        location = Object.assign({}, location)

        let batch = firestore.batch();

        // Checks if the location has image.
        if (image !== null) {
            // save the image to storage
            this.storage.locationPicture(image, eventId, moduleId, locationId, async (url) => {
                if (url) {
                    location.image = url

                    batch.update(refModule, location);
                    batch.update(refEvent, location);

                    // calls the trigger dbLocationsEditLocation from the API.
                    batch.commit().then((_) => {
                        onResolve(true);
                    }).catch((error) => {
                        onResolve(false);
                    });
                } else {
                    onResolve(false)
                }
            });
        } else {
            // calls the trigger dbLocationsEditLocation from the API.
            batch.update(refModule, location);
            batch.update(refEvent, location);

            batch.commit().then((_) => {
                onResolve(true);
            }).catch((error) => {
                 onResolve(false);
            });
        }
    }

    deleteLocation(eventId: string, moduleId: string, locationId: string, onResolve) {
        let firestore = this.aFirestore.firestore;
        let batch = firestore.batch();

        // enable api trigger dbLocationsDeleteLocation
        batch.delete(firestore.collection('events').doc(eventId).collection('locations').doc(locationId))
        batch.delete(firestore.collection('modules').doc(moduleId).collection('locations').doc(locationId))

        batch.commit().then((_) => {
            onResolve(true);
        }).catch((error) => {
            onResolve(false);
        });
    }

    removeLocations(eventId: string, moduleId: string, listRemoveUids: Array<string>, onResolve) {
        let firestore = this.aFirestore.firestore;
        let batch = firestore.batch();

        for (const locationId of listRemoveUids) {
            // enable api trigger dbLocationsDeleteLocation
            batch.delete(firestore.collection('events').doc(eventId).collection('locations').doc(locationId))
            batch.delete(firestore.collection('modules').doc(moduleId).collection('locations').doc(locationId))
        }

        batch.commit().then((_) => {
            onResolve(true);
        }).catch((error) => {
            onResolve(false);
        });
    }

    existIdentifier(moduleId, identifier, locationId, onResolve) {
        let db = this.aFirestore.firestore;

        let moduleRef = db.collection('modules').doc(moduleId).collection('locations').where('identifier', '==', identifier);

        moduleRef.get()
            .then((data) => {
                if (data.size > 0) { //verifica se o identifier já exite
                    //no caso de não haver um id, quer dizer que é um create e nesse caso não é necessário verificar se o identifier é do próprio participante
                    if (locationId == null) {
                        onResolve(false)
                    }
                    //verifica se o identifier existente é do próprio participante
                    moduleRef.where('uid', '==', locationId).get().then((result) => {
                        //caso seja retorna false
                        if (result.size > 0) {
                            onResolve(false);
                        } else { // caso nao seja retorna que o identifier já existe
                            onResolve(true);
                        }
                    })
                } else {
                    onResolve(false);
                }
            })
    }

    getLocationsIds(moduleId: string) {
        return new Promise((resolve, reject) =>{
            let db = this.aFirestore.firestore;
            let ref = db.collection('modules')
            .doc(moduleId)
            .collection('locations')
            .orderBy("identifier", "asc");

            ref.get().then((data) => {
                let listIds: Array<number> = [];
                data.forEach(element => {
                    let track = element.data();
                    listIds.push(track.identifier);
                });
    
                resolve(listIds);
            }).catch((error) =>{
                console.error(error)
                reject(false)
            })
        })
    }

    // ================================================================== SESSIONS===================================================================
    // get sessions of location
    getSessionsOfLocation(reference) {
        return new Promise((resolve) => {
            const sessions = []
            reference
                .get()
                .then((snapshot) => {
                    snapshot.forEach((childSnapshot) => {
                        const session = childSnapshot.data()
                        sessions.push(session)
                    })

                    resolve(sessions)
                })
        })
    }

    importLocations(moduleId: string, eventId: string, locations: Array<Location>, onResolve) {
        let body = {
            moduleId: moduleId,
            eventId: eventId,
            locations: locations
        }

        this.http.post(PathApi.baseUrl + PathApi.dbLocationsImportLocations, body, this.requestOptions)
            .subscribe((status) => {
                onResolve(status);
            });
    }
}