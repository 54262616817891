import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { PathApi } from '../../paths/path-api';
import firebase from 'firebase/app';
import { AngularFirestore } from '@angular/fire/firestore';
import { PlataformRoutersService } from '../plataform-routes/plataform-routers.service';
import { AttendeeFields } from 'src/app/models/attendee-fields';
import { TypeModule } from 'src/app/enums/type-module';
import { PageInfobooth } from 'src/app/models/page-infobooth';
import { ModuleEvent } from 'src/app/models/modules/module-event';
import { ModuleAttendee } from 'src/app/models/modules/module-attendees';
import { reject } from 'q';

@Injectable({
    providedIn: 'root'
})

export class DbManagerModuleProvider {
    public headers;
    public requestOptions;
    private db: firebase.firestore.Firestore

    constructor(
        private http: HttpClient,
        private aFirestore: AngularFirestore,
        private plataformRouters: PlataformRoutersService,
    ) {
        this.headers = new HttpHeaders();
        this.headers.append("Accept", 'application/json');
        this.headers.append("Content-Type", 'application/json');
        this.requestOptions = { headers: this.headers };

        this.db = firebase.firestore()
    }

    getModule(moduleId, onResolve) {
        const ref = this.db.collection('modules').doc(moduleId)

        ref.get().then((snapshot) => {
            onResolve(snapshot.data())
        })
    }

    createModule(module: any) {
        return new Promise((resolve, reject) => {
            const eventId = module.eventId
            // convert typescript objects to jsons
            module = Object.assign({}, module)
            module.name = Object.assign({}, module.name)


            // start batch
            let batch = this.db.batch();

            let ref1 = this.db.collection('events').doc(eventId).collection('modules').doc()
            module.uid = ref1.id

            ref1 = this.db.collection('events').doc(eventId).collection('modules').doc(module.uid)
            const ref2 = this.db.collection('modules').doc(module.uid)

            // define module routes
            if (module.type === TypeModule.GENERIC) {
                module.viewBackOffice = '';
                module.viewApp = module.viewApp;
            } else if (module.type === TypeModule.EXTERNAL_LINK && module.link) {
                module.viewApp = module.link
                module.viewBackOffice = module.link
            } else {
                const url = this.plataformRouters.relatesUrls(module.type, module.uid, null, module.eventId);
                if (url && url.backoffice) {
                    module.viewBackOffice = url.backoffice;
                }
                if (url && url.app) {
                    module.viewApp = url.app;
                }
            }

            if (module.type == TypeModule.ATTENDEE || module.type == TypeModule.SPEAKER) {
                module.fields = Object.assign({}, new AttendeeFields());
            }

            // create the page for the infobooth module.
            if (module.type === TypeModule.INFOBOOTH) {
                const page = new PageInfobooth()
                page.title = Object.assign({}, page.title);
                page.htmlContent = Object.assign({}, page.htmlContent);
                page.uid = this.db.collection('modules').doc(module.uid).collection('pages').doc().id
                page.moduleId = module.uid
                page.eventId = module.eventId

                // routers withpage
                const url = this.plataformRouters.relatesUrls(module.type, module.uid, page.uid, module.eventId);
                if (url && url.backoffice) {
                    module.viewBackOffice = url.backoffice;
                }
                if (url && url.app) {
                    module.viewApp = url.app;
                }

                const ref1 = this.db.collection('events').doc(eventId).collection('modules').doc(module.uid).collection('pages').doc(page.uid)
                const ref2 = this.db.collection('modules').doc(module.uid).collection('pages').doc(page.uid)
                batch.set(ref1, Object.assign({}, page))
                batch.set(ref2, Object.assign({}, page))
            }

            batch.set(ref1, module)
            batch.set(ref2, module)

            // Commit the batch
            batch.commit().then((batchOk) => {
                resolve(true);
            }).catch((error) => {
                reject(error);
            })
        })
    }


    // update modules
    updateModule(module: any) {
        return new Promise(async (resolve, reject) => {
            const eventId = module.eventId
            const moduleId = module.uid

            // convert typescript objects to jsons
            module = Object.assign({}, module)

            // start batch
            let batch = this.db.batch();

            // module paths.
            const ref1 = this.db.collection('events').doc(eventId).collection('modules').doc(module.uid)
            const ref2 = this.db.collection('modules').doc(moduleId)

            batch.set(ref1, module)
            batch.set(ref2, module)

            // Commit the batch
            batch.commit().then((batchOk) => {
                console.log(batchOk);
                resolve(true);
            }).catch((error) => {
                console.log(error);
                reject(error);
            })
        })
    }

    removeModule(module: any) {
        return new Promise((resolve, reject) => {
            const moduleId = module.uid
            const eventId = module.eventId

            // start batch
            let batch = this.db.batch();

            // enable trigger on dbModulesDelete api
            const ref1 = this.db.collection('events').doc(eventId).collection('modules').doc(moduleId)
            const ref2 = this.db.collection('modules').doc(moduleId)

            batch.delete(ref1)
            batch.delete(ref2)

            batch.commit().then(() => {
                resolve(true)
            }).catch((error) => {
                reject(false)
            })
        })
    }

    // update array of modules
    updateModules(modules: Array<any>) {
        return new Promise((resolve, reject) => {
            // start batch
            let batch = this.db.batch();

            for (let module of modules) {

                // convert typescript objects to jsons
                module = Object.assign({}, module)

                const eventId = module.eventId
                const moduleId = module.uid
                let ref1 = this.db.collection('events').doc(eventId).collection('modules').doc(moduleId)
                const ref2 = this.db.collection('modules').doc(moduleId)

                batch.update(ref1, module)
                batch.update(ref2, module)
            }

            // Commit the batch
            batch.commit().then((batchOk) => {
                resolve(true);
            }).catch((error) => {
                reject(error);
            })
        })
    }



    searchGroupModule(eventId: string) {
        return new Promise((resolve) => {
            let db = this.aFirestore.firestore;

            db
                .collection('events')
                .doc(eventId)
                .collection('modules')
                .where('type', '==', TypeModule.MANAGER_GROUP)
                .get()
                .then((value) => {
                    value.forEach(element => {
                        resolve(element.data());
                    });
                })
        })
    }

    // return groups module
    getAccess_groupsModule(moduleId: string) {
        return new Promise((resolve) => {
            let ref = this.db.collection('modules').doc(moduleId)

            ref.get().then((snapshot) => {
                let groups = []

                if (typeof snapshot.data().access_groups !== 'undefined') {
                    const aux = snapshot.data().access_groups

                    for (let uid in aux) {
                        groups.push(aux[uid])
                    }
                }

                resolve(groups)
            })
        })
    }






    getModules(eventId, onResolve) {
        let ref = this.db.collection("events").doc(eventId).collection('modules').where('order', '>=', 0).orderBy('order')

        ref.get().then((snapshot) => {
            const list = []

            snapshot.forEach((document) => {
                const module = document.data()
                list.push(module)
            })

            onResolve(list)
        })
    }

    changeHomePage(eventId: string, home: string, onResolve) {
        let db = this.aFirestore.firestore;

        db
            .collection('events')
            .doc(eventId)
            .update({
                homePage: home
            })
            .then((_) => {
                onResolve(true);
            })
            .catch((e) => {
                onResolve(false);
            });
    }

    getAttendeeModulesByEvent(eventId: string, onResolve) {
        let ref = this.db.collection("events").doc(eventId).collection('modules').where('type', '==', TypeModule.ATTENDEE);

        ref
            .get()
            .then((snapshot) => {
                let modules = [];
                if (snapshot.size >= 1) {
                    snapshot.forEach(element => {
                        modules.push(element.data());
                    });
                }
                onResolve(modules);
            })
    }
}
