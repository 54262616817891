import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from '../../providers/auth/auth.service';
import { PathApi } from '../../paths/path-api';
import { AngularFirestore, DocumentData } from '@angular/fire/firestore';
import { Attendee } from 'src/app/models/attendees';
import firebase from 'firebase/app';
import { eventCustomField } from 'src/app/models/eventCustomField';
import { StorageService } from 'src/app/providers/storage/storage.service';
import { TypeModule } from 'src/app/enums/type-module';
import { Checkin } from 'src/app/models/checkin';
import { NameModule } from 'src/app/models/name-module';
import { map, tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class DbAttendeesProvider {
    private db: firebase.firestore.Firestore;
    public headers;
    public requestOptions;
    public documentOnlyOnProfil: boolean;
    public documentProfilNParticipants: boolean;



    constructor(private auth: AuthService, private http: HttpClient, private aFirestore: AngularFirestore, private storage: StorageService) {
        this.headers = new HttpHeaders();
        this.headers.append("Accept", 'application/json');
        this.headers.append("Content-Type", 'application/json');
        this.requestOptions = { headers: this.headers, params: {} };

        this.db = firebase.firestore();
    }

    attendeeModule(moduleId: string, onResolve) {
        let db = this.aFirestore.firestore;
        db
            .collection('modules')
            .doc(moduleId)
            .get()
            .then((doc) => {
                let module = doc.data();
                onResolve(module);
            })
            .catch((err) => onResolve(err));
    }

    getAttendeesByEvent(eventId: string, onResolve) {
        let db = this.aFirestore.firestore;
        let ref = db.collection('events').doc(eventId).collection('attendees');

        ref
            .onSnapshot((snapshot) => {
                let attendees = [];
                if (snapshot.size >= 1) {
                    snapshot.forEach(element => {
                        attendees.push(element.data());
                    });
                }
                onResolve(attendees);
            });
    }

    /**
     * Get attendees for event
     * @param eventId 
     */
    getAttendeesForEvent(eventId: string) {
        return (this.aFirestore.collection('events')
            .doc(eventId)
            .collection<Attendee>('attendees')
            .valueChanges()
        )
    }

    getAttendees(moduleId, typeOrder, onResolve) {
        let firestore = this.aFirestore.firestore;
        let refAttendees;

        switch (typeOrder) {
            case 'asc': //a-z
                refAttendees = firestore.collection('modules').doc(moduleId).collection('attendees')
                    .orderBy('queryName', 'asc');
                break;

            case 'desc': //z-a
                refAttendees = firestore.collection('modules').doc(moduleId).collection('attendees')
                    .orderBy('queryName', 'desc');
                break;

            case 'oldest'://antiho-recente
                refAttendees = firestore.collection('modules').doc(moduleId).collection('attendees')
                    .orderBy('createdAt', 'asc');
                break;

            case 'recent': //recente-antigo
                refAttendees = firestore.collection('modules').doc(moduleId).collection('attendees')
                    .orderBy('createdAt', 'desc');
                break;

            case 'id': //ID
                refAttendees = firestore.collection('modules').doc(moduleId).collection('attendees')
                    .orderBy('identifier');
                break;
        }

        refAttendees.get()
            .then((data) => {
                let listAttendees = [];
                data.forEach(
                    (doc) => {
                        let attendee = doc.data();
                        listAttendees.push(attendee);
                    }
                )
                let result = {
                    code: 200,
                    message: 'success',
                    result: listAttendees
                };

                onResolve(result)
            })
            .catch((error) => {

            })
    }

    getAttendee(eventId, moduleId, attendeeId, onResolve) {
        let firestore = this.aFirestore.firestore;

        firestore.collection("events").doc(eventId).collection("attendees").doc(attendeeId).onSnapshot((data) => {
            let result = {
                code: 200,
                message: 'success',
                result: data.data()
            };

            onResolve(result)
        })
    }

    async getAttendeesFromArrayIdsPromise(eventId: string, attendeeIds: string[]) : Promise<any[]>{
        let firestore = this.aFirestore.firestore;
        const length = Math.ceil(attendeeIds.length / 10);
        const promises: Array<Promise<any>> = []
        for(let i = 0 ; i < length ; i++){
            promises.push(firestore.collection("events").doc(eventId).collection("attendees").where('uid','in',[...attendeeIds].splice(i*10,10)).get())
        }
        const tab = await Promise.all(promises);
        return tab.map(ref => [...ref.docs].map(_ => _.data())).reduce((acc, curr) => [...acc].concat(curr), []) 
    }

    /**
    *get all event participant modules.
    *@param eventId
    *@returns onResolve
  */
    getModulesAttendeeEvent(eventId: string, onResolve) {
        let db = this.aFirestore.firestore;
        const modules = []

        const ref = db.collection('events').doc(eventId).collection('modules').where('type', '==', TypeModule.ATTENDEE)
        ref.onSnapshot((snapshot) => {
            snapshot.forEach((element) => {
                const module = element.data()
                modules.push(module)
            })

            onResolve(modules)
        })
    }


    verifyExistAttendeeByEmail(eventId: string, email: string, onResolve) {
        let db = this.aFirestore.firestore;

        db.collection('events').doc(eventId).collection('attendees').where("email", "==", email).get()
            .then((data) => {
                let docNotExist = true;
                data.forEach(doc => {
                    if (doc.exists) {
                        docNotExist = false;
                        onResolve(true);
                    }
                });

                if (docNotExist == true) {
                    onResolve(false);
                }
            })
            .catch((error) => {

            })
    }

    verifyExistAttendeeByIdentifier(eventId, identifier, onResolve) {
        let db = this.aFirestore.firestore;

        db.collection('events').doc(eventId).collection('attendees').where("identifier", "==", identifier).get()
            .then((data) => {
                let docNotExist = true;

                data.forEach(doc => {
                    if (doc.exists) {
                        docNotExist = false;
                        onResolve(true);
                    }
                })

                if (docNotExist == true) {
                    onResolve(false)
                }
            })
            .catch((error) => {

            })
    }

    verifyIdentifierValidByAttendee(eventId, identifier, attendeeId, onResolve) {
        let db = this.aFirestore.firestore;

        db.collection('events')
            .doc(eventId)
            .collection('attendees')
            .where("identifier", "==", identifier)
            .get()
            .then((data) => {
                let docNotExist = true;

                data.forEach(doc => {
                    if (doc.exists) {
                        let attendee = doc.data();

                        if (attendeeId !== attendee.uid) {
                            onResolve(false);
                        } else {
                            onResolve(true);
                        }
                    }
                })

                if (docNotExist) {
                    onResolve(true);
                }
            })
            .catch((error) => {

            })
    }


    verifyEmailValidByAttendee(email, attendeeId, onResolve) {
        let db = this.aFirestore.firestore;

        db
            .collection('users')
            .where("email", "==", email)
            .get()
            .then((data) => {

                if (data.size == 0) {
                    onResolve(true);
                }

                data.forEach(element => {
                    let user = element.data();

                    if (user.uid !== attendeeId) {
                        onResolve(false)

                    } else {
                        onResolve(true);
                    }
                });
            })
    }


    async createAttendee(eventId: string, moduleId: string, attendee: Attendee, listCustomFields: DocumentData[], existUser: boolean, userEmail: string, profilePhoto: string, onResolve) {
        attendee.$moduleId = moduleId;

        if (existUser) {// caso o usuário já exista
            this.db.collection('users').where("email", "==", userEmail).get()
                .then((data) => {
                    data.forEach(
                        async doc => {
                            let docAttendee = doc.data();
                            attendee.$uid = docAttendee.uid;

                            let batch = this.db.batch();

                            let aux: any = Object.assign({}, attendee)

                            let refAttendee = this.db.collection("events").doc(eventId).collection("attendees").doc(attendee.$uid);
                            batch.set(refAttendee, aux);

                            let refAttendeeModule = this.db.collection('modules').doc(moduleId).collection('attendees').doc(attendee.$uid);
                            batch.set(refAttendeeModule, aux);

                            if (docAttendee.firstAccess == true) {
                                if (attendee.$emailRecovery !== undefined && attendee.$emailRecovery !== null && attendee.$emailRecovery !== '') {
                                    let refUser = this.db.collection('users').doc(docAttendee.uid);
                                    batch.update(refUser, { emailRecovery: attendee.$emailRecovery });
                                }
                            }

                            //get event checkins
                            const checkins = await this.getCheckinsEvent(eventId)

                            for (const checkin of checkins) {
                                const checkinId = checkin.uid
                                const moduleCheckinId = checkin.moduleId
                                aux.checkinStatus = false

                                let ref = this.db.collection('modules').doc(moduleCheckinId).collection('checkins').doc(checkinId).collection('attendees').doc(attendee.$uid)
                                batch.set(ref, aux)
                            }


                            batch.commit()
                                .then((data) => {
                                    //Adiciona os custom Fields ao novo attendee
                                    let batchCustom = this.db.batch();
                                    for (let custom of listCustomFields) {
                                        //console.log(custom)
                                        let refCustomAttendeeEvent = this.db.collection('events').doc(eventId).collection('attendees').doc(attendee.$uid)
                                            .collection('customFields').doc(custom.uid);
                                        batchCustom.set(refCustomAttendeeEvent, custom);

                                        let refCustomAttendeeModule = this.db.collection('modules').doc(moduleId).collection('attendees').doc(attendee.$uid)
                                            .collection('customFields').doc(custom.uid);
                                        batchCustom.set(refCustomAttendeeModule, custom);
                                    }

                                    let updateCustomFields = null;
                                    batchCustom.commit()
                                        .then((data) => {
                                            updateCustomFields = {
                                                code: 200,
                                                message: 'success',
                                                result: data
                                            }
                                        }).catch((error) => {
                                            updateCustomFields = {
                                                code: 500,
                                                message: 'error',
                                                result: error
                                            }
                                        })

                                    //adiciona o evento ao path users
                                    this.db.collection('users').doc(attendee.$uid).update({
                                        events: firebase.firestore.FieldValue.arrayUnion(eventId),
                                        attendeeModules: firebase.firestore.FieldValue.arrayUnion(moduleId)
                                    })
                                        .catch((error) => {
                                            let result = {
                                                code: 500,
                                                message: 'error when adding event to user',
                                                result: error
                                            }

                                            onResolve(result);
                                        })

                                    if (profilePhoto != null && profilePhoto != undefined) {
                                        this.storage.profilePictureAttendees(profilePhoto, eventId, attendee.$uid, (url) => {
                                            let batch = this.db.batch();
                                            batch.update(refAttendee, { photoUrl: url })
                                            batch.update(refAttendeeModule, { photoUrl: url })

                                            batch.commit()
                                                .then((data) => {
                                                    let result = {
                                                        code: 201,
                                                        message: 'success',
                                                        result: data
                                                    };

                                                    let AllUpdates = {
                                                        attendee: result,
                                                        customFields: updateCustomFields
                                                    }

                                                    onResolve(AllUpdates)
                                                })
                                                .catch((error) => {
                                                    let result = {
                                                        code: 500,
                                                        message: 'error-upload-profile-image',
                                                        result: error
                                                    }

                                                    let AllUpdates = {
                                                        attendee: result,
                                                        customFields: updateCustomFields
                                                    }

                                                    onResolve(AllUpdates)
                                                })
                                        })
                                    } else {
                                        let result = {
                                            code: 201,
                                            message: 'success',
                                            result: data
                                        };

                                        let AllUpdates = {
                                            attendee: result,
                                            customFields: updateCustomFields
                                        }

                                        onResolve(AllUpdates)
                                    }

                                })
                                .catch((error) => {
                                    let result = {
                                        code: 500,
                                        message: 'success',
                                        result: error
                                    };

                                    let AllUpdates = {
                                        attendee: result,
                                        customFields: null
                                    }

                                    onResolve(AllUpdates)
                                })
                        }
                    );
                })

        } else { //caso o usuário não exista
            let batch = this.db.batch();

            let ref = this.db.collection('users').doc();
            attendee.$uid = ref.id;

            let userAttr = this.generateUserObject(attendee, eventId, moduleId);

            let auxUser: any = Object.assign({}, userAttr);
            batch.set(ref, auxUser)

            let auxAttendee: any = Object.assign({}, attendee)
            let refAttendee = this.db.collection("events").doc(eventId).collection("attendees").doc(attendee.$uid);
            batch.set(refAttendee, auxAttendee);

            let refAttendeeModule = this.db.collection('modules').doc(moduleId).collection('attendees').doc(attendee.$uid);
            batch.set(refAttendeeModule, auxAttendee);


            //get event checkins
            const checkins = await this.getCheckinsEvent(eventId)

            for (const checkin of checkins) {
                const checkinId = checkin.uid
                const moduleCheckinId = checkin.moduleId

                auxAttendee.checkinStatus = false
                let ref = this.db.collection('modules').doc(moduleCheckinId).collection('checkins').doc(checkinId).collection('attendees').doc(attendee.$uid)
                batch.set(ref, auxAttendee)
            }

            batch.commit()
                .then((data) => {
                    //Adiciona os custom Fields ao novo attendee
                    let batchCustom = this.db.batch();
                    console.log(listCustomFields)
                    for (let custom of listCustomFields) {
                        let refCustomAttendeeEvent = this.db.collection('events').doc(eventId).collection('attendees').doc(attendee.$uid)
                            .collection('customFields').doc(custom.uid);
                        batchCustom.set(refCustomAttendeeEvent, custom);

                        let refCustomAttendeeModule = this.db.collection('modules').doc(moduleId).collection('attendees').doc(attendee.$uid)
                            .collection('customFields').doc(custom.uid);
                        batchCustom.set(refCustomAttendeeModule, custom);
                    }

                    let updateCustomFields = null;
                    batchCustom.commit()
                        .then((data) => {
                            updateCustomFields = {
                                code: 200,
                                message: 'success',
                                result: data
                            }
                        }).catch((error) => {
                            updateCustomFields = {
                                code: 500,
                                message: 'error',
                                result: error
                            }
                        })

                    if (profilePhoto != null && profilePhoto != undefined) {
                        this.storage.profilePictureAttendees(profilePhoto, eventId, attendee.$uid, (url) => {
                            let batchPhoto = this.db.batch();

                            batchPhoto.update(refAttendee, { photoUrl: url })
                            batchPhoto.update(refAttendeeModule, { photoUrl: url })

                            batchPhoto.commit()
                                .then((data) => {
                                    let result = {
                                        code: 201,
                                        message: 'success',
                                        result: data
                                    };

                                    let AllUpdates = {
                                        attendee: result,
                                        customFields: updateCustomFields
                                    }

                                    onResolve(AllUpdates)
                                })
                                .catch((error) => {
                                    let result = {
                                        code: 500,
                                        message: 'error-upload-profile-image',
                                        result: error
                                    }

                                    let AllUpdates = {
                                        attendee: result,
                                        customFields: updateCustomFields
                                    }

                                    onResolve(AllUpdates)
                                })
                        })
                    } else {
                        let result = {
                            code: 201,
                            message: 'success',
                            result: data
                        };

                        let AllUpdates = {
                            attendee: result,
                            customFields: updateCustomFields
                        }

                        onResolve(AllUpdates)
                    }
                })
                .catch((error) => {
                    let result = {
                        code: 500,
                        message: 'success',
                        result: error
                    };

                    let AllUpdates = {
                        attendee: result,
                        customFields: null
                    }

                    onResolve(AllUpdates)
                })
        }
    }

    generateUserObject(data, eventId, moduleId) {
        let dataFormat = {
            uid: data.uid,
            name: data.name,
            queryName: data.name.toLowerCase(),
            type: data.type,
            email: "",
            language: "",
            description: "",
            photoUrl: "",
            company: "",
            title: "",
            phone: "",
            emailRecovery: "",
            events: data.events,
            attendeeModules: data.attendeeModules,
            firstAccess: true //sempre true
        };

        if (data.email != undefined) {
            dataFormat.email = data.email;
        }

        if (data.language != undefined) {
            dataFormat.language = data.language;
        }

        if (data.description != undefined) {
            dataFormat.description = data.description;
        }

        if (data.photoUrl != undefined) {
            dataFormat.photoUrl = data.photoUrl;
        }

        if (data.company != undefined) {
            dataFormat.company = data.company;
        }

        if (data.title != undefined) {
            dataFormat.title = data.title;
        }

        if (data.phone != undefined) {
            dataFormat.phone = data.phone;
        }

        if (data.emailRecovery != undefined) {
            dataFormat.emailRecovery = data.emailRecovery;
        }

        dataFormat.events = firebase.firestore.FieldValue.arrayUnion(eventId)

        dataFormat.attendeeModules = firebase.firestore.FieldValue.arrayUnion(moduleId);

        return dataFormat;
    }

    checkCustomAlreadyCreated(customFields: eventCustomField[], custom: eventCustomField) {
    }

    // updating documents visibility choices
    updateModuleDocumentsVisibility(moduleId: string, field: any, onResolve) {
        const ref = this.db.collection('modules')
                            .doc(moduleId)

        ref.update(field)
            .then(() => {
                onResolve(true)
            })
            .catch((error) => {
                onResolve(error);
            });
    }

    // updating documents visibility choices
    updateUserDocumentsVisibility(eventId: string, moduleId: string, attendeeId: string, field: any, onResolve) {
        const ref1 = this.db.collection('events')
                            .doc(eventId)
                            .collection('attendees')
                            .doc(attendeeId);

        const ref2 = this.db.collection('modules')
                            .doc(moduleId)
                            .collection('attendees')
                            .doc(attendeeId);
                            
        ref1.update(field)
            .then(() => {
                ref2.update(field).then(() => {
                    onResolve(true);
                })
                .catch((error) => {
                    onResolve(error);
                });
            })
            .catch((error) => {
                onResolve(error);
            });
    }

    async editAttendee(eventId, moduleId, moduleName, attendee, listCustomFields, customUser, photoProfile, changeEmail, onResolve) {
        attendee = Object.assign({}, attendee);
        let batchCustom = this.db.batch();
        if (customUser) {
            for (let custom of customUser) {
                if (listCustomFields.some((field) => field.uid === custom.uid)) {
                    if (custom.type == 'text') {
                        const refCustomAttendeeEvent = this.db.collection('events').doc(eventId).collection('attendees').doc(attendee.uid)
                        // .collection('customFields').doc(custom.uid);
    
                        batchCustom.update(refCustomAttendeeEvent, { customFields: attendee.customFields });
    
                        const refCustomAttendeeModule = this.db.collection('modules').doc(moduleId).collection('attendees').doc(attendee.uid)
                        // .collection('customFields').doc(custom.uid);
    
                        batchCustom.update(refCustomAttendeeModule, { customFields: attendee.customFields });
    
                    }
                    // else if (custom.type == 'select') {
                    //     let refCustomAttendeeEvent = this.db.collection('events').doc(eventId).collection('attendees').doc(attendee.uid)
                    //         .collection('customFields').doc(custom.uid);
    
                    //     batchCustom.update(refCustomAttendeeEvent, { value: custom.value });
    
                    //     let refCustomAttendeeModule = this.db.collection('modules').doc(moduleId).collection('attendees').doc(attendee.uid)
                    //         .collection('customFields').doc(custom.uid);
    
                    //     batchCustom.update(refCustomAttendeeModule, { value: custom.value });
                    // }
                } else {
                    // Create custom field doc if not already created
                    if (custom.type == 'text') {
                        const refCustomAttendeeEvent = this.db.collection('events').doc(eventId).collection('attendees').doc(attendee.uid)
                        // .collection('customFields').doc(custom.uid);
    
                        batchCustom.set(refCustomAttendeeEvent, { customFields: attendee.customFields });
    
                        const refCustomAttendeeModule = this.db.collection('modules').doc(moduleId).collection('attendees').doc(attendee.uid)
                        // .collection('customFields').doc(custom.uid);
    
                        batchCustom.set(refCustomAttendeeModule, { customFields: attendee.customFields });
    
                    }
                    // else if (custom.type == 'select') {
                    //     let refCustomAttendeeEvent = this.db.collection('events').doc(eventId).collection('attendees').doc(attendee.uid)
                    //         .collection('customFields').doc(custom.uid);
    
                    //     batchCustom.set(refCustomAttendeeEvent, custom);
    
                    //     let refCustomAttendeeModule = this.db.collection('modules').doc(moduleId).collection('attendees').doc(attendee.uid)
                    //         .collection('customFields').doc(custom.uid);
    
                    //     batchCustom.set(refCustomAttendeeModule, custom);
                    // }
                }
            }
        }
        

        let updateCustomFields = null;

        batchCustom.commit()
            .then((data) => {
                updateCustomFields = {
                    code: 200,
                    message: 'success',
                    result: data
                }
            }).catch((error) => {
                console.log("Errorrr: ", error);
                updateCustomFields = {
                    code: 500,
                    message: 'error',
                    result: error
                }
            })

        let batch = this.db.batch();

        let refAttendees = this.db.collection("events").doc(eventId).collection("attendees").doc(attendee.uid);
        batch.update(refAttendees, attendee);

        let refAttendeesModule = this.db.collection("modules").doc(moduleId).collection("attendees").doc(attendee.uid);
        batch.update(refAttendeesModule, attendee);
        let refUser = this.db.collection('users').doc(attendee.uid);
        let emailRecovery = attendee.emailRecovery;

        if (changeEmail) {
            if (emailRecovery !== '') {
                batch.update(refUser, { email: attendee.email, emailRecovery: emailRecovery });
            } else {
                batch.update(refUser, { email: attendee.email });
            }
        } else {
            if (emailRecovery !== '') {
                batch.update(refUser, { email: attendee.email, emailRecovery: emailRecovery });
            }
        }


        // get event checkins
        const checkins = await this.getCheckinsEvent(eventId)
        for (const checkin of checkins) {
            const checkinId = checkin.uid
            const moduleCheckinId = checkin.moduleId

            let checkinStatus = await this.getCheckinStatus(attendee.uid, checkinId, moduleCheckinId);

            attendee.checkinStatus = (checkinStatus) ? true : false;

            let ref = this.db.collection('modules').doc(moduleCheckinId).collection('checkins').doc(checkinId).collection('attendees').doc(attendee.uid)
            batch.update(ref, attendee)
        }



        batch.commit()
            .then((data) => {
                if (photoProfile != null && photoProfile != "" && photoProfile != undefined) {
                    this.storage.profilePictureAttendees(photoProfile, eventId, attendee.uid, (url) => {
                        let batchPhoto = this.db.batch();
                        batchPhoto.update(refAttendees, { photoUrl: url });
                        batchPhoto.update(refAttendeesModule, { photoUrl: url });

                        batchPhoto.commit()
                            .then((data) => {
                                if (attendee.firstAccess == false) {
                                    this.auth.updateUserAuth(attendee.uid, attendee, (_) => { });
                                }
                                let result = {
                                    code: 200,
                                    message: 'success',
                                    result: data
                                };

                                let AllUpdates = {
                                    attendee: result,
                                    customFields: updateCustomFields
                                }
                                onResolve(AllUpdates)

                            })
                            .catch((error) => {
                                let result = {
                                    code: 500,
                                    message: 'error-upload-image',
                                    result: error
                                };

                                let AllUpdates = {
                                    attendee: result,
                                    customFields: updateCustomFields
                                }
                                onResolve(AllUpdates)
                            })
                    })
                } else {
                    if (attendee.firstAccess == false) {
                        this.auth.updateUserAuth(attendee.uid, attendee, (_) => { });
                    }
                    let result = {
                        code: 200,
                        message: 'success',
                        result: data
                    };

                    let AllUpdates = {
                        attendee: result,
                        customFields: updateCustomFields
                    }
                    onResolve(AllUpdates)
                }
            })
            .catch((error) => {
                let result = {
                    code: 500,
                    message: 'error',
                    result: error
                };

                let AllUpdates = {
                    attendee: result,
                    customFields: updateCustomFields
                }
                onResolve(AllUpdates)
            })
    }

    removeAttendees(eventId, moduleId, listRemoveUids, onResolve) {
        console.log('debut de la fonction RemoveAttendees...')
        // for (const attendeeId of listRemoveUids) {
        //     // ACTIVE trigger dbAttendeesDelete
        //     this.db.collection('modules').doc(moduleId).collection('attendees').doc(attendeeId).delete()
        // }

        // onResolve(true)

        let batchesArray = [];
        batchesArray.push(this.db.batch());
        let batchIndex = 0
        let batchCounter = 0

        for (const attendeeId of listRemoveUids) {
            batchCounter++
            console.log(attendeeId)
            // ACTIVE trigger dbAttendeesDelete
            const ref = this.db.collection('modules').doc(moduleId).collection('attendees').doc(attendeeId);

            batchesArray[batchIndex].delete(ref)

            if (batchCounter === 450) {
                batchesArray.push(this.db.batch())
                batchIndex++;
                batchCounter = 0;
            }
        }

        let batchSize = batchesArray.length
        let contBatchCommit = 0;
        let response = true

        batchesArray.forEach((batch) => {
            batch.commit()
                .then((_) => {
                    if (contBatchCommit == batchSize - 1) {
                        console.log('success')
                        onResolve(response)
                    }

                    contBatchCommit++;
                })
                .catch((error) => {
                    response = false
                    console.log('failed')
                    if (contBatchCommit == batchSize - 1) {
                        onResolve(response)
                    }

                    contBatchCommit++;
                })
        })

    }



    removeAttendeeAllEvents(userId, onResolve) {
        let refUser = this.db.collection('users').doc(userId);

        refUser.get()
            .then((data) => {
                let user = data.data();

                let batch = this.db.batch();
                for (let moduleId of user.attendeeModules) {
                    let refModule = this.db.collection('modules').doc(moduleId).collection('attendees').doc(user.uid);
                    batch.delete(refModule);
                }

                for (let eventId of user.events) {
                    let refEvent = this.db.collection('events').doc(eventId).collection('attendees').doc(user.uid);
                    batch.delete(refEvent);
                }

                this.auth.removeUserAuth(user.uid, (data) => {
                    if (data.result == true) {

                        batch.commit()
                            .then(() => {
                                onResolve(true);
                            })
                            .catch((err) => {
                                onResolve(err)
                            })
                    }
                })

            })
    }

    breakArray(base, max) {
        var resultado = [[]];
        var grupo = 0;

        if (base.length < max) {
            resultado[grupo] = base;
            return resultado;
        }

        for (var indice = 0; indice < base.length; indice++) {
            if (resultado[grupo] === undefined) {
                resultado[grupo] = [];
            }

            resultado[grupo].push(base[indice]);

            if ((indice + 1) % max === 0) {
                grupo = grupo + 1;
            }
        }

        return resultado;
    }


    changeOrderItems(eventId: string, moduleId, typeOrder, onResolve) {
        let db = this.aFirestore.firestore;
        db.collection("modules").doc(moduleId).update({ orderUsers: typeOrder })
        db.collection("events").doc(eventId).collection("modules")
            .doc(moduleId)
            .update({ orderUsers: typeOrder })
            .then((_) => {
                onResolve(true);
            })
            .catch((__) => {
                onResolve(false);
            });
    }

    importAttendees(eventId: string, moduleId: string, nameModule: NameModule, attendees: Array<Attendee>, groupModuleId, onResolve) {
        let body = {
            eventId: eventId,
            moduleId: moduleId,
            nameModule: nameModule,
            attendees: attendees,
            groupModuleId: groupModuleId
        }
        this.http.post(PathApi.baseUrl + PathApi.dbAttendeeImportAttendee, body, this.requestOptions)
            .subscribe((result) => {
                onResolve(result)
            });
    }

    takeChangesInModule(moduleId: string, eventId: string, changes) {
        let db = this.aFirestore.firestore;

        let refModule = db.collection('modules').doc(moduleId);
        let refEventModule = db.collection('events').doc(eventId).collection('modules').doc(moduleId);

        refModule.update(changes);
        refEventModule.update(changes);
    }


    //=======================================CUSTOM FIELDS =========================================

    updateModule(eventId: string, moduleId: string, fieldsCustom) {
        let db = this.aFirestore.firestore;
        let refModule = db.collection('modules').doc(moduleId);
        let refEvent = this.db.collection('events').doc(eventId).collection('modules').doc(moduleId);
        let batch = db.batch();

        batch.update(refEvent, { fieldsCustom: fieldsCustom });
        batch.update(refModule, { fieldsCustom: fieldsCustom });

        batch.commit()
            .then(() => {
                // console.log('success')
            })
            .catch(() => {
                console.log('error')
            })
        // ref.update({ fieldsCustom: fieldsCustom });
    }

    public async getCustomFields(eventId: string, moduleId: string) {
        // const db = this.aFirestore.firestore;
        // const ref = db.collection('modules').doc(moduleId).collection('customFields');
        // ref.get()
        //     .then((data) => {
        //         let listCustomField = [];
        //         data.forEach(element => {
        //             let custom = element.data();
        //             listCustomField.push(custom);
        //         });

        //         let result = {
        //             message: "success",
        //             result: listCustomField
        //         }
        //         onResolve(result);
        //     })
        //     .catch((error) => {

        //         let result = {
        //             message: "error",
        //             result: error
        //         }
        //         onResolve(result);
        //     })
        const ref = this.db.collection(`events/${eventId}/modules/${moduleId}/customFields`);
        const result = await ref.orderBy("order").get();
        if (!result) {
            return Promise.reject();
        }
        return Promise.resolve(result.docs.map((doc) => doc.data()));
    }


    getCustomFieldsOfUser(moduleId, attendeeId, onResolve) {
        let db = this.aFirestore.firestore;
        // let ref = db.collection('modules').doc(moduleId).collection('attendees').doc(attendeeId).collection('customFields');
        let ref = db.collection('modules').doc(moduleId).collection('attendees').doc(attendeeId);

        ref.get().then((data) => {
            let listCustomFields = [];

            listCustomFields = data.data()['customFields'];
            onResolve(listCustomFields);
        });
    }

    getCustomFieldsFirstTime(moduleId, attendeeId, onResolve) {
        let db = this.aFirestore.firestore;
        let ref = db.collection('modules').doc(moduleId).collection('attendees').doc(attendeeId).collection('customFields');
        //let ref = db.collection('modules').doc(moduleId).collection('attendees').doc(attendeeId);

        ref.get()
            .then((data) => {
                let listCustomField = [];
                data.forEach(element => {
                    let custom = element.data();
                    listCustomField.push(custom);
                });
                console.log(listCustomField)
                let result = {
                    message: "success",
                    result: listCustomField
                }
                onResolve(result);
            })
            .catch((error) => {

                let result = {
                    message: "error",
                    result: error
                }
                onResolve(result);
            })
    }

    getCustomFieldsOfUserFirstTime(moduleId, attendeeId, onResolve) {
        let db = this.aFirestore.firestore;
        let ref = db.collection('modules').doc(moduleId).collection('attendees').doc(attendeeId).collection('customFields');
        //let ref = db.collection('modules').doc(moduleId).collection('attendees').doc(attendeeId);

        ref.get()
        .then((data) => {
            let listCustomField = [];
            data.forEach(element => {
                listCustomField.push(element.data());
            });
            console.log(listCustomField)
            let result = {
                message: "success",
                result: listCustomField
            }
            onResolve(result);
        })
        .catch((error) => {

            let result = {
                message: "error",
                result: error
            }
            onResolve(result);
        })
    }

    // getOptionsCustomField(moduleId, customId, onResolve) {
    //     let db = this.aFirestore.firestore;

    //     let ref = db.collection('modules').doc(moduleId).collection('customFields').doc(customId).collection('options');

    //     ref.get()
    //         .then((data) => {
    //             let listOption = [];
    //             data.forEach(element => {
    //                 let option = element.data();

    //                 listOption.push(option);
    //             });

    //             let result = {
    //                 message: 'success',
    //                 result: listOption
    //             }

    //             onResolve(result)
    //         })
    //         .catch((error) => {
    //             let result = {
    //                 message: 'error',
    //                 result: error
    //             }

    //             onResolve(result)
    //         })
    // }

    getCustomFieldOptions(moduleId: string, customId: string, onResolve) {
        let db = this.aFirestore.firestore;

        let ref = db.collection('modules')
            .doc(moduleId)
            .collection('customFields')
            .doc(customId)
            .collection('options');

        ref.get().then((data) => {
            let listOptions = [];

            data.forEach(element => {
                let option = element.data();

                listOptions.push(option);
            });

            onResolve(listOptions);
        })
    }

    // createCustomField(eventId: string, moduleId: string, customField: eventCustomField, listOptions: Array<eventCustomFieldAnswerOption>, onResolve) {
    //     let refModuleCustom = this.db.collection('modules').doc(moduleId).collection('customFields').doc();
    //     let refModule = this.db.collection('modules').doc(moduleId);
    //     let refEvent = this.db.collection('events').doc(eventId).collection('modules').doc(moduleId);
    //     customField.uid = refModuleCustom.id;
    //     customField = Object.assign({}, customField);
    //     customField.name = Object.assign({}, customField.name);
    //     customField.textValue = Object.assign({}, customField.textValue);
    //     refModuleCustom.set(customField)
    //         .then(() => {
    //             refModule
    //                 .get()
    //                 .then((snapshot) => {
    //                     let module = snapshot.data();
    //                     let order;
    //                     if (module['fieldsCustom'] == undefined || Object.keys(module['fieldsCustom']).length === 0) {
    //                         order = 0;
    //                     } else {
    //                         order = Object.keys(module['fieldsCustom']).length;
    //                     }

    //                     let updates = {};
    //                     updates[`fieldsCustom.${customField.uid}`] = {
    //                         required: false,
    //                         unique_edit: false,
    //                         hide_field: false,
    //                         order: order
    //                     };

    //                     let batch = this.db.batch();
    //                     batch.update(refModule, updates);
    //                     batch.update(refEvent, updates);

    //                     batch.commit()
    //                         .then(() => {
    //                             console.log('success')
    //                         })
    //                         .catch(() => {
    //                             console.log('error')
    //                         })

    //                     // if (listOptions != null && listOptions != undefined) {
    //                     //     if (listOptions.length > 0) {
    //                     //         for (let option of listOptions) {
    //                     //             option = Object.assign({}, option);
    //                     //             option.answer = Object.assign({}, option.answer);
    //                     //             refModuleCustom.collection('options').add(option)
    //                     //                 .then((docRef) => {
    //                     //                     docRef.update({
    //                     //                         uid: docRef.id
    //                     //                     })
    //                     //                     onResolve({
    //                     //                         code: 200,
    //                     //                         result: customField
    //                     //                     })
    //                     //                 })
    //                     //                 .catch((error) => {
    //                     //                     console.log(error)
    //                     //                     onResolve({
    //                     //                         code: 400,
    //                     //                         result: error
    //                     //                     })
    //                     //                 })
    //                     //         }
    //                     //     }
    //                     // } else {
    //                     onResolve({
    //                         code: 200,
    //                         result: customField
    //                     });
    //                     // }
    //                 });
    //         })
    //         .catch((e) => {

    //             console.log(e)
    //             onResolve({
    //                 code: 400,
    //                 result: e
    //             });
    //         });
    // }

    /**
     * 
     * @param eventId 
     * @param moduleId 
     * @param customField 
     * @param listOptions 
     * @returns 
     */
    async createCustomField(
        eventId: string, 
        moduleId: string, 
        customField: eventCustomField
    ) {
        const refModule = this.db.doc(`modules/${moduleId}`);
        const refEvent = this.db.doc(`events/${eventId}/modules/${moduleId}`);
        const refCustomFieldsDoc = this.db.collection(
            `events/${eventId}/modules/${moduleId}/customFields`
        ).doc();

        customField.uid = refCustomFieldsDoc.id;
        customField = Object.assign({}, customField);
        customField.name = Object.assign({}, customField.name);
        if (customField.type == "text") {
            customField.textValue = Object.assign({}, customField.textValue);
        }

        const customFieldInfo = {};
        customFieldInfo[`fieldsCustom.${customField.uid}`] = {
            required: false,
            unique_edit: false,
            hide_field: false,
            order: customField.order
        };

        const batch = this.db.batch();
        batch.update(refModule, customFieldInfo);
        batch.update(refEvent, customFieldInfo);

        const r = await Promise.all([
            refCustomFieldsDoc.set(customField),
            batch.commit()
        ]);
        if (!r) { return Promise.reject(); }

        return Promise.resolve(customField);
    }

    editCustomField(eventId, moduleId, customField, oldCustomField, listOptions, listOptionsRemove, onResolve) {

        let body = {
            eventId: eventId,
            moduleId: moduleId,
            customField: customField,
            oldCustomField: oldCustomField,
            listOptionsCustomEdit: listOptions,
            listOptionsCustomRemove: listOptionsRemove
        }

        this.http.post(PathApi.baseUrl + PathApi.dbCustomFieldAttendeeEdit, body, this.requestOptions).subscribe(
            data => {
                onResolve(data)
            },
            err => {
                onResolve(err)
            }
        )
    }

    async updateCustomField(
        eventId: string,
        moduleId: string,
        customFieldId: string,
        data: any
    ) {
        const batch = this.db.batch();
        const snapshot = await this.db
            .collection(`events/${eventId}/attendees`)
            .get();
        
        if (!snapshot) {
            return Promise.reject();
        }

        snapshot.docs.forEach((doc) => {
            const ref = this.db.doc(
                `events/${eventId}/attendees/${doc.id}/customFields/${customFieldId}`
            );
            batch.update(ref, data);
        });
        const ref = this.db.doc(
            `events/${eventId}/modules/${moduleId}/customFields/${customFieldId}`
        );
        batch.update(ref, data);
        
        return batch.commit();
    }

    updateOptionCustomField(moduleId, customId, option) {
        let db = this.aFirestore.firestore;

        let ref = db.collection('modules').doc(moduleId).collection('customFields').doc(customId).collection("options").doc(option.uid);

        ref.update(option);
    }

    createOptionCustomField(moduleId, customId, option) {
        let db = this.aFirestore.firestore;
        option = Object.assign({}, option);

        let ref = db.collection('modules').doc(moduleId).collection('customFields').doc(customId).collection("options").doc();
        option.uid = ref.id;

        ref.set(option);
    }

    getFieldOptionsCustom(moduleId: string, onResolve) {
        let db = this.aFirestore.firestore;

        db
            .collection('modules')
            .doc(moduleId)
            .get()
            .then((value) => {
                let aux = value.data();
                onResolve(aux['fieldsCustom']);
            });
    }

    removeOptionOfAttendees(eventId, moduleId, customId, optionId) {
        let db = this.aFirestore.firestore;

        db.collection('modules')
            .doc(moduleId)
            .collection('attendees')
            .get().then((data) => {
                //passa por todos os documentos
                data.forEach(
                    (doc) => {
                        let attendee = doc.data();

                        let refAttendeeEvent = db.collection('events').doc(eventId).collection("attendees").doc(attendee.uid)
                            .collection('customFields').doc(customId);

                        let refAttendeeModule = db.collection('modules').doc(moduleId).collection('attendees').doc(attendee.uid)
                            .collection('customFields').doc(customId);


                        refAttendeeModule.get().
                            then((data) => {
                                let custom = data.data();

                                if (custom.value == optionId) {
                                    let batch = db.batch();

                                    batch.update(refAttendeeEvent, { value: "" });
                                    batch.update(refAttendeeModule, { value: "" });

                                    batch.commit()
                                        .then(() => {
                                        })
                                        .catch(() => {
                                        })
                                }
                            })
                    }
                )
            })
    }

    removeOptionCustomField(moduleId, customId, optionId) {
        let db = this.aFirestore.firestore;
        let ref = db.collection('modules').doc(moduleId).collection('customFields').doc(customId).collection("options").doc(optionId);

        ref.delete();
    }

    clearUsersResultCustom(eventId, moduleId, customId) {
        let db = this.aFirestore.firestore;

        db.collection('modules')
            .doc(moduleId)
            .collection('attendees')
            .get().then((data) => {
                //passa por todos os documentos
                data.forEach(
                    (doc) => {
                        let attendee = doc.data();

                        let refAttendeeEvent = db.collection('events').doc(eventId).collection("attendees").doc(attendee.uid)
                            .collection('customFields').doc(customId);

                        let refAttendeeModule = db.collection('modules').doc(moduleId).collection('attendees').doc(attendee.uid)
                            .collection('customFields').doc(customId);

                        let batch = db.batch();

                        batch.update(refAttendeeEvent, { value: '' });
                        batch.update(refAttendeeModule, { value: '' });

                        batch.commit()
                            .then(() => {
                            })
                            .catch(() => {
                            })

                    }
                )
            })
    }

    /**
     * Delete custom field
     * @param eventId 
     * @param moduleId 
     * @param customId 
     */
    async deleteCustomField(eventId, moduleId, customId) {
        try {

            let refModule = this.aFirestore.collection('modules').doc(moduleId)
            let refModuleCustom = this.aFirestore.collection('modules').doc(moduleId).collection('customFields').doc(customId);
            let refModuleCustomAttendees = this.aFirestore.collection('modules').doc(moduleId).collection('attendees');
            let refEventCustomAttendees = this.aFirestore.collection('events')
                .doc(moduleId).collection('modules').doc(customId)
                .collection('attendees');


            // Get custom module data
            let moduleCustom = await refModuleCustom.get().pipe(
                map((doc) => {
                    return (doc.data());
                })
            ).toPromise();

            let aux: any = await refModule.get().pipe(
                map((doc) => {
                    return (doc.data());
                })
            ).toPromise();

            if (moduleCustom.type == 'select') {
                let refCustomOptions = this.aFirestore
                    .collection('modules')
                    .doc(moduleId)
                    .collection('customFields')
                    .doc(customId).collection('options');

                // Get all custom options and delete them
                await refCustomOptions.get().pipe(
                    map((docs) => {
                        return (docs.docs.map((doc) => {
                            return (doc.data());
                        }))
                    }),
                    tap((customOptions) => {
                        customOptions.forEach((customOption) => {
                            refCustomOptions.doc(customOption.uid).delete();
                        })
                    })
                ).toPromise();
            }

            // Delete field value on module
            aux = aux['fieldsCustom'];
            let orderItemDelete = aux[customId].order;

            let updateFields = {};
            for (let itemId in aux) {
                if (aux[itemId].order > orderItemDelete) {
                    updateFields[`fieldsCustom.${itemId}.order`] = aux[itemId].order - 1;
                }
            }

            let FieldValue = firebase.firestore.FieldValue;
            updateFields[`fieldsCustom.${customId}`] = FieldValue.delete();

            await refModule.update(updateFields);
            await refModuleCustom.delete();

            // Get list of attendees
            let listAttendees = await this.aFirestore.collection('modules')
                .doc(moduleId)
                .collection('attendees')
                .get().pipe(
                    map((docs) => {
                        return (docs.docs.map((doc) => {
                            return (doc.data());
                        }));
                    })
                ).toPromise();
            if (listAttendees.length >= 1) {

                let totalAttendees = listAttendees.length;
                let batch = this.aFirestore.firestore.batch();
                for (let i = 0; i < totalAttendees; i++) {
                    let attendee = listAttendees[i];

                    // Delete custom field for attendee on events and modules collections
                    let refAttendeeEvent = this.aFirestore.collection('events')
                        .doc(eventId)
                        .collection("attendees")
                        .doc(attendee.uid).ref;
                    //.collection('customFields')
                    //.doc(customId).ref;
                    refAttendeeEvent.get()
                        .then(async att => {
                            const fields = att.data().customFields;
                            if (fields) {
                                let index = 0;
                                console.log(fields);
                                for (index; index < fields.length; index++) {
                                    if (fields[index].uid === customId) {
                                        updateFields[`customFields.${index}`] = FieldValue.delete();
                                        await refAttendeeEvent.update(updateFields);
                                    }
                                }
                            }
                        });

                    let refAttendeeModule = this.aFirestore.collection('modules')
                        .doc(moduleId)
                        .collection('attendees')
                        .doc(attendee.uid).ref;
                    //.collection('customFields')
                    //.doc(customId).ref;
                    refAttendeeModule.get()
                        .then(async att => {
                            const fields = att.data().customFields;
                            if (fields) {
                                let index = 0;
                                for (index; index < fields.length; index++) {
                                    if (fields[index].uid === customId) {
                                        updateFields[`customFields.${index}`] = FieldValue.delete();
                                        await refAttendeeModule.update(updateFields);
                                    }
                                }
                            }

                        });


                    //.collection('customFields')
                    //.doc(customId).ref;

                    //batch.delete(refAttendeeEvent);
                    //batch.delete(refAttendeeModule);
                }
                await batch.commit();
                return ({
                    code: 200,
                    message: 'success',
                    result: 'removed'
                });
            } else {
                return ({
                    code: 200,
                    message: 'success',
                    result: 'removed'
                });
            }
        } catch (error) {
            throw error;
        }
    }



    // ================================= CONFIG MODULE ====================================

    getAllowedEditProfile(moduleId, onResolve) {
        let db = this.aFirestore.firestore;

        db.collection('modules').doc(moduleId).get()
            .then((data) => {
                let aux = data.data();
                let result = aux.allowedEditProfile;

                onResolve(result)
            })
    }

    changeAllowedEditProfile(moduleId, eventId, status, onResolve) {
        let db = this.aFirestore.firestore;

        db.collection('events').doc(eventId).collection('modules').doc(moduleId).update({
            allowedEditProfile: status
        })
        db.collection('modules').doc(moduleId).update({
            allowedEditProfile: status
        }).then(() => {
            onResolve("success");
        }).catch(() => {
            onResolve("error");
        })
    }



    // ================================== EXPORT ATTENDEES =================================

    public listAttendees = [];
    public errorExport = false;
    exportAttendees(moduleId: string, typeOrder: string, onResolve) {
        this.requestOptions.firstGet = true;
        this.requestOptions.lastDocument = null;
        this.listAttendees = [];

        this.getAttendeesExport(moduleId, typeOrder, (data) => {

            if (!this.errorExport) {
                let result = {
                    code: 200,
                    message: 'success',
                    result: this.listAttendees
                }

                onResolve(result);
            } else {
                let error = {
                    code: 404,
                    message: 'error',
                    result: null
                }

                onResolve(error)
            }
        })
    }


    getAttendeesExport(moduleId: string, typeOrder: string, onResolve) {
        let sizeArray = null;

        this.http.post(PathApi.baseUrl + PathApi.dbAttendeesExportAttendees + '?moduleId=' + moduleId + '&typeOrder=' +
            typeOrder, this.requestOptions)
            .subscribe((data) => {
                console.log(data['code'])
                //console.log(data['result'])
                if (data['code'] == 200) {
                    this.listAttendees = this.listAttendees.concat(data['result'])
                    sizeArray = data['result'].length;
                    this.requestOptions.firstGet = false;
                    this.requestOptions.lastDocument = data['lastDocument'];

                    if (sizeArray < 500) {
                        onResolve(data['result'])
                    } else {
                        this.getAttendeesExport(moduleId, typeOrder, onResolve);
                    }
                } else {
                    this.errorExport = true;
                }
            })
    }

    getAttendeesFieldOptions(moduleId: string, onResolve) {
        let db = this.aFirestore.firestore;

        db
            .collection('modules')
            .doc(moduleId)
            .get()
            .then((value) => {
                let aux = value.data();
                onResolve(aux['fields']);
            });
    }

    getCustomAttendeesFieldOptions(moduleId: string, onResolve) {
        let db = this.aFirestore.firestore;

        db
            .collection('modules')
            .doc(moduleId)
            .get()
            .then((value) => {
                let aux = value.data();
                onResolve(aux['fieldsCustom']);
            });
    }

    changeRequiredField(eventId: string, moduleId: string, field: string, value: boolean, allFields) {
        let db = this.aFirestore.firestore;

        db.collection('events')
            .doc(eventId)
            .collection('modules')
            .doc(moduleId)
            .update(
                {
                    fields: allFields
                }
            )

        allFields[field].required = value;

        db
            .collection('modules')
            .doc(moduleId)
            .update({
                fields: allFields
            });
    }

    changeUniqueEditField(eventId: string, moduleId: string, field: string, value: boolean, allFields) {
        let db = this.aFirestore.firestore;

        allFields[field].unique_edit = value;
        db.collection('events')
            .doc(eventId)
            .collection('modules')
            .doc(moduleId)
            .update(
                {
                    fields: allFields
                }
            )

        db
            .collection('modules')
            .doc(moduleId)
            .update({
                fields: allFields
            });
    }

    changeHideField(eventId: string, moduleId: string, field: string, value: boolean, allFields) {
        let db = this.aFirestore.firestore;

        allFields[field].hide_field = value;

        db.collection('events')
            .doc(eventId)
            .collection('modules')
            .doc(moduleId)
            .update(
                {
                    fields: allFields
                }
            )

        db
            .collection('modules')
            .doc(moduleId)
            .update({
                fields: allFields
            });
    }


    changeRequiredFieldCustom(eventId: string, moduleId: string, field: string, value: boolean, allFields) {
        let db = this.aFirestore.firestore;
        let refModule = db.collection('modules').doc(moduleId);
        let refEvent = db.collection('events').doc(eventId).collection('modules').doc(moduleId);
        let batch = db.batch();

        allFields[field].required = value;


        batch.update(refModule, { fieldsCustom: allFields });
        batch.update(refEvent, { fieldsCustom: allFields });

        batch.commit()
            .then(() => {
                console.log('success');
            })
            .catch(() => {
                console.log('error');
            })
    }

    changeUniqueEditFieldCustom(eventId: string, moduleId: string, field: string, value: boolean, allFields) {
        let db = this.aFirestore.firestore;
        let refModule = db.collection('modules').doc(moduleId);
        let refEvent = db.collection('events').doc(eventId).collection('modules').doc(moduleId);
        let batch = db.batch();

        allFields[field].unique_edit = value;

        batch.update(refModule, { fieldsCustom: allFields });
        batch.update(refEvent, { fieldsCustom: allFields });

        batch.commit()
            .then(() => {
                console.log('success');
            })
            .catch(() => {
                console.log('error');
            })
    }

    changeHideFieldCustom(eventId: string, moduleId: string, field: string, value: boolean, allFields) {
        let db = this.aFirestore.firestore;
        let refModule = db.collection('modules').doc(moduleId);
        let refEvent = db.collection('events').doc(eventId).collection('modules').doc(moduleId);
        let batch = db.batch();

        allFields[field].hide_field = value;

        batch.update(refModule, { fieldsCustom: allFields });
        batch.update(refEvent, { fieldsCustom: allFields });

        batch.commit()
            .then(() => {
                console.log('success');
            })
            .catch(() => {
                console.log('error');
            })
    }

    changeOrderCustoms(eventId: string, moduleId: string, allFields, onResolve) {
        let db = this.aFirestore.firestore;
        let refModule = db.collection('modules').doc(moduleId);
        let refEvent = db.collection('events').doc(eventId).collection('modules').doc(moduleId);
        let batch = db.batch();

        batch.update(refModule, { fieldsCustom: allFields })
        batch.update(refEvent, { fieldsCustom: allFields })

        batch.commit()
            .then(() => {
                console.log('success')
                onResolve(true)
            })
            .catch(() => {
                console.log('error')
                onResolve(false)
            })
    }

    /**
     * 
     * @param eventId 
     * @param moduleId 
     * @param customFields array with all custom fields data
     * @returns 
     */
    public updateCustomFieldsOrder(eventId: string, moduleId: string, customFields: any[]) {
        const batch = this.db.batch();

        for (const customField of customFields) {
            // const ref1 = this.db.doc(`modules/${moduleId}/customFields/${customField.uid}`);
            const ref2 = this.db.doc(
                `events/${eventId}/modules/${moduleId}/customFields/${customField.uid}`
            );
            // batch.update(ref1, { order: customField.order});
            batch.update(ref2, { order: customField.order });
        }
        return batch.commit();
    }

    /**
     * Change chat allow for module
     * @param eventId
     * @param moduleId
     * @param status
     */
    changeChatUse(eventId: string, moduleId: string, status: boolean) {
        let db = this.aFirestore.firestore;
        let eventRef = db.collection('events').doc(eventId).collection('modules').doc(moduleId);
        let moduleRef = db.collection('modules').doc(moduleId);

        eventRef.update({ allow_chat: status });
        moduleRef.update({ allow_chat: status });
    }

    /**
     * Change chat allow for module
     * @param eventId
     * @param moduleId
     * @param status
     */
    changeFilterVisibility(moduleId: string, status: boolean,type: string, onResolve) {
        let db = this.aFirestore.firestore;
        // let eventRef = db.collection('events').doc(eventId).collection('modules').doc(moduleId);
        let moduleRef = db.collection('modules').doc(moduleId);

        const obj = {}
        if(type === 'company'){
            obj['allowFilterCompany'] = status
        } else if(type === 'post') {
            obj['allowFilterPost'] = status
        } else if (type === 'customs') {
            obj['allowFilterCustomFields'] = status
        } else {
            obj[type] = status
        }
        moduleRef.update(obj).then(() => {
            onResolve({type: type, value: true});
        })
        .catch(() => {
            onResolve({type: type, value: false});
        });
    }

    editPropVisibility(moduleId: string, prop: string, status: any, onResolve) {
        let db = this.aFirestore.firestore;
        const obj = {}
        obj[prop] = status
        db.collection('modules').doc(moduleId)
            .update(obj)
            .then(() => {
                onResolve(true);
            })
            .catch(() => {
                onResolve(false);
            });
    }

    /**
     * Change visio allow for module
     * @param eventId
     * @param moduleId
     * @param status
     */
    changeVisioUse(eventId: string, moduleId: string, status: boolean) {
        let db = this.aFirestore.firestore;
        let eventRef = db.collection('events').doc(eventId).collection('modules').doc(moduleId);
        let moduleRef = db.collection('modules').doc(moduleId);

        eventRef.update({ allow_visio: status });
        moduleRef.update({ allow_visio: status });
    }

    resetAccount(attendee: Attendee) {
        return new Promise((resolve, reject) => {
            this.auth.verifyEmailAuth(attendee['email'])
                .then((response) => {
                    if (response['code'] == 0) {
                        // user exist
                        resolve(0);
                        this.reconfigureUser(response['uid'], attendee)
                            .then((snap) => {
                                if (snap) {
                                    resolve(0);
                                } else {
                                    reject(2);
                                }
                            })
                    } else if (response['code'] == 1) {
                        // user doesn't exist
                        resolve(1);
                    } else if (response['code'] == 2) {
                        // error to find e-mail
                        reject(2);
                    }
                })
        })
    }

    reconfigureUser(authUid: string, attendee: Attendee) {
        return new Promise((resolve, reject) => {
            let db = this.aFirestore.firestore;
            let oldUid;
            let user = null;

            db
                .collection("users")
                .where('email', '==', attendee['email'])
                .get()
                .then((snapshot) => {
                    if (snapshot.size >= 1) {
                        snapshot.forEach(element => {
                            if (user == null) {
                                user = element.data();
                                let aux = user;
                                oldUid = user.uid;

                                let eventsId = aux.events;
                                let data = {
                                    name: aux.name,
                                    type: aux.type,
                                    email: aux.email,
                                    photoUrl: aux.photoUrl,
                                    createdAt: aux.createdAt,
                                    company: aux.company,
                                    title: aux.title,
                                    description: aux.description,
                                    recoveryEmail: aux.recoveryEmail,
                                    phone: null,
                                    uid: authUid,
                                    events: aux.events,
                                    attendeeModules: aux.attendeeModules,
                                    firstAccess: false,
                                    language: aux.language
                                }

                                let userObj = this.userModelData(data);
                                let cont = 0;
                                for (let idEvent of eventsId) {
                                    let batch = null;
                                    batch = db.batch();

                                    // apgar no path users antigo
                                    let oldUserRef = db.collection("users").doc(oldUid);
                                    batch.delete(oldUserRef);

                                    // adicionar no path users o novo
                                    let newUserRef = db.collection("users").doc(authUid);
                                    batch.set(newUserRef, userObj);

                                    let oldAttendeeRef = db.collection("events").doc(idEvent).collection("attendees").doc(oldUid);
                                    let newAttendeeRef = db.collection("events").doc(idEvent).collection("attendees").doc(authUid);

                                    let listCustomField = [];
                                    oldAttendeeRef.collection('customFields').get().then((data) => {
                                        data.forEach(doc => {
                                            let custom = doc.data();
                                            listCustomField.push(custom);
                                        });
                                    })

                                    oldAttendeeRef.get().then((data) => {
                                        let attendee = data.data();
                                        attendee.uid = authUid;
                                        attendee.firstAccess = false;
                                        let oldModulesAttendeeRef = db.collection("modules").doc(attendee.moduleId).collection('attendees')
                                            .doc(oldUid);
                                        let newModulesAttendeeRef = db.collection("modules").doc(attendee.moduleId).collection('attendees')
                                            .doc(authUid);

                                        // apagar no path events -> attendes
                                        batch.delete(oldAttendeeRef);
                                        // apagar no path modules -> attendees antigo
                                        batch.delete(oldModulesAttendeeRef);

                                        // adicionar no path events -> attendees
                                        batch.set(newAttendeeRef, attendee);
                                        // adicionar no path modules -> attendees novo
                                        batch.set(newModulesAttendeeRef, attendee);

                                        batch.commit().then((batchOk) => {
                                            let batchCustom = db.batch();
                                            for (let custom of listCustomField) {
                                                let refCustomEventAttendee = newAttendeeRef.collection('customFields').doc(custom.uid);
                                                let refCustomModuleAttendee = newModulesAttendeeRef.collection('customFields').doc(custom.uid);

                                                batchCustom.set(refCustomEventAttendee, custom);
                                                batchCustom.set(refCustomModuleAttendee, custom);
                                            }

                                            batchCustom.commit().then(() => {
                                            })

                                            if (cont == eventsId.length - 1) {
                                                resolve(true);
                                            }
                                            cont++;
                                        }).catch((batchError) => {
                                            // remove da autenticação caso dê erro no banco
                                            // admin.auth().deleteUser(authUid);

                                            reject(false);
                                        })
                                    });
                                }
                            }
                        });
                    }
                });
        })

    }

    userModelData(user) {
        let userFormat = {
            name: null,
            type: null,
            email: "",
            language: "",
            description: "",
            photoUrl: "",
            company: "",
            title: "",
            recoveryEmail: "",
            events: null,
            attendeeModules: null,
            firstAccess: null,
            uid: "",
            edited_profile: false
        };

        if (user.name != undefined) {
            userFormat.name = user.name;
        }

        if (user.type != undefined) {
            userFormat.type = user.type;
        }

        if (user.email != undefined) {
            userFormat.email = user.email;
        }

        if (user.language != undefined) {
            userFormat.language = user.language;
        }

        if (user.description != undefined) {
            userFormat.description = user.description;
        }

        if (user.photoUrl != undefined) {
            userFormat.photoUrl = user.photoUrl;
        }

        if (user.company != undefined) {
            userFormat.company = user.company;
        }

        if (user.title != undefined) {
            userFormat.title = user.title;
        }

        if (user.recoveryEmail != undefined) {
            userFormat.recoveryEmail = user.recoveryEmail;
        }

        if (user.events != undefined) {
            userFormat.events = user.events;
        }

        userFormat.attendeeModules = user.attendeeModules

        if (user.firstAccess != undefined) {
            userFormat.firstAccess = user.firstAccess;
        }

        if (user.uid != undefined) {
            userFormat.uid = user.uid;
        }

        return userFormat;
    }



    /**
    * get all checks in of the event.
    * @param {string} eventId
    * @returns Promise<Checkin[]>
    */

    getCheckinsEvent(eventId: string): Promise<Checkin[]> {
        return new Promise((resolve) => {
            let db = this.aFirestore.firestore
            let checkins = []

            // get checkins module
            db.collection('events')
                .doc(eventId)
                .collection('modules')
                .where('type', '==', TypeModule.CHECKIN)
                .get()
                .then((snapshot) => {
                    if (snapshot.size <= 0)
                        resolve([])

                    // get all module checkins.
                    snapshot.forEach((element) => {
                        const module = element.data()
                        const moduleId = module.uid

                        db.collection('modules')
                            .doc(moduleId)
                            .collection('checkins')
                            .get()
                            .then((childSnapshot) => {
                                childSnapshot.forEach((element) => {
                                    const checkin = element.data()
                                    checkins.push(checkin)
                                })

                                resolve(checkins)
                            })
                    })
                })
        })
    }


    /**
     * get participant checkin status.
     * @param {string} attendeeId
     * @param {string} checkinId
     * @param {string} checkinModuleId
     * @returns string
     */

    getCheckinStatus(attendeeId: string, checkinId: string, checkinModuleId: string) {
        return (
            this.aFirestore
                .collection('modules')
                .doc(checkinModuleId)
                .collection('checkins')
                .doc(checkinId)
                .collection('attendees')
                .doc(attendeeId)
                .get()
                .pipe(
                    map((snapshot) => {
                        return (snapshot.data().checkinStatus);
                    })
                ).toPromise()
        )
    }
}
