import { isIdentifier } from '@angular/compiler';
import {
    Component,
    OnInit,
    ViewChild,
    NgModule,
    ElementRef,
    NgZone,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { DbAttendeesProvider } from "src/app/providers/database/db-attendees";
import { Attendee } from "src/app/models/attendees";
import { SwalComponent } from "@sweetalert2/ngx-sweetalert2";
import { EmailProvider } from "../../../../../providers/email/email.service";
import { OrderType } from "src/app/paths/orderType";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { RegexProvider } from "src/app/providers/regex/regex.service";
import { TranslateService } from "@ngx-translate/core";
import { eventCustomField, CFSAnswer } from "../../../../../models/eventCustomField";
// import { eventCustomFieldAnswerOption } from "../../../../../models/eventCustomFieldAnswerOption";
import { FormGroup, Validators, FormBuilder } from "@angular/forms";
import { FilterPipe } from "src/app/pipes/filter.pipe";
import { DbGroupsProvider } from "src/app/providers/database/db-groups";
import { DbEventsProvider } from "src/app/providers/database/db.events";
import { DragulaService } from "ng2-dragula";
import { DragulaOptions } from "dragula";
import { Event } from "../../../../../models/event";
import { GlobalService } from "src/app/providers/global/global.service";
import { Languages } from "src/app/models/languages";
import { MenuIcons, icons } from "src/app/models/menu-icons";
import { PathIcons } from "src/app/paths/path-icons";
import * as jspdf from "jspdf";
import { take } from "rxjs/operators";
import { NameModule } from 'src/app/models/name-module';
// declare let LZString: any;
// declare let JSONC: any;
// import * as JSONC from 'jsoncomp';

declare let $: any;
type AOA = Array<Array<any>>;

@Component({
    selector: "app-attendees",
    templateUrl: "./attendees.component.html",
    styleUrls: ["./attendees.component.scss"],
})
@NgModule({
    declarations: [FilterPipe],
})
export class AttendeesComponent implements OnInit {
    @ViewChild("successSwal") public successSwal: SwalComponent;
    @ViewChild("errorSwal") public errorSwal: SwalComponent;
    @ViewChild("notReconfiguredSwal") public notReconfiguredSwal: SwalComponent;
    @ViewChild("confirmResetAccount") public confirmResetAccount: SwalComponent;
    @ViewChild("successEmailSwal") public successEmailSwal: SwalComponent;
    @ViewChild("errorEmailSwal") public errorEmailSwal: SwalComponent;
    @ViewChild("successPasswordSwal") public successPasswordSwal: SwalComponent;
    @ViewChild("errorPasswordSwal") public errorPasswordSwal: SwalComponent;
    @ViewChild("notExport") public notExport: SwalComponent;
    @ViewChild("inputFile") public inputFile: ElementRef;

    dragulaOptions: DragulaOptions = {
        moves: () => true,
    };

    onReorderShow: boolean = false;
    public module = null;
    public nameModule = null;
    public p: number = 1;
    public deleteAttendeeId: string;
    deleteAttendeeIndex: number;
    public listAttendees: Array<Attendee> = [];
    public allListAttendees: Array<Attendee> = [];
    public loader: boolean = true;
    public eventId: string = null;
    public moduleId: string = null;
    public emailsAttendee: string = null;
    public emailTitle: string = null;
    public emailBody: string = null;
    public attendeeObjEmail: Attendee = null;
    public attendeeRemoveId;
    public selectedAllInput: boolean = false;
    term: string = "";
    typeOrder: string = null;
    public attendeeSelected = [];

    public allowedEditProfileVal;

    public totalAttendeeUpdated: number = 0;
    public totalAttendeeCreated: number = 0;
    public totalAttendeeFail: number = 0;
    public docChoice: string;


    //CUSTOM FIELDS
    // Custom Field - Globais.
    errorFormCustomCreate: boolean;
    errorFormCustomEdit: boolean;
    formCustom: FormGroup;
    formCustomEdit: FormGroup;
    hideElement: boolean = false; // habilita ou desabilita o campo de edicao do Custom field
    // listCustomField: Array<eventCustomField>; // array list custom field attendee.
    listCustomField: any[]; // array list custom field attendee.
    editCustomField: eventCustomField;
    showTypeCustom: boolean = false; // mostra ou oculta o campo para criação de opções de resposta
    showTypeCustomEdit: boolean = false; // mostra ou oculta o campo para criação de opções de resposta do edit
    listOptionsCustom: CFSAnswer[] = [
        new CFSAnswer(0),
    ];
    listOptionsCustomEdit: Array<CFSAnswer> = [new CFSAnswer(0)];
    listOptionsCustomEditDelete: Array<string> = [];

    // EXCEL PROPERTIES
    identifier: number = 0;
    name: number = 1;
    email: number = 2;
    emailRecovery: number = 3;
    title: number = 4;
    company: number = 5;
    description: number = 6;
    picture: number = 7;
    website: number = 8;
    facebook: number = 9;
    twitter: number = 10;
    linkedin: number = 11;
    instagram: number = 12;
    groups: number = 13;
    documents: number = 14;
    customField: number = 15;

    // EXCEL PROPOERTIES TRANSLATED
    translatedIdentifier: number = 0;
    translatedTitle: number = 1;
    translatedDescription: number = 2;
    customFieldTranslated: number = 3;

    data: any = null;
    dataImportPtBR: any = null;
    dataImportEnUS: any = null;
    dataImportEsES: any = null;
    dataImportFrFR: any = null;
    dataImportDeDE: any = null;
    exportAttendees: AOA = null;
    newAttendees: Array<Attendee>; // new Attendees.
    messageErrors: Array<String>; // array with error messages.
    nameFile: string = null; // excel input change name
    theError: boolean = false; // display error messages
    theErrorApi: boolean = false; // display error after send data to API
    theErrorApiDb: boolean = false; // display error after send data to API and have DB error
    successMsg: boolean = false; // display success message
    loaderImport: boolean = false; // loader after click button import
    someAttendeesExcelDownload: Array<Attendee> = []; // attendees with errors to download excel
    dataExportAttendees: AOA = null; // data export all attendees
    dataExportAttendees_PtBR: AOA = null;
    dataExportAttendees_EnUS: AOA = null;
    dataExportAttendees_EsES: AOA = null;
    dataExportAttendees_FrFR: AOA = null;
    dataExportAttendees_DeDE: AOA = null;
    attendeeErrorsApi: Array<Attendee> = [];
    editProfileVal: boolean = false;
    requiredEditProfileVal: boolean = false;
    loaderBtnCustomField: boolean = false;
    loaderEditCustomField: boolean = false;
    fieldOptions;
    fieldOptionsCustom = null;
    moduleGroupId: string = null;
    listGroup = [];
    groupFilter: string = "all";
    invalidCustomFields: Array<any> = [];
    invalidGroups: Array<any> = [];
    invalidDocuments: Array<any> = [];
    theErrorGroup: boolean = false;
    theErrorDocument: boolean = false;
    invalidEmailInAttendee: Array<any> = [];
    theErrorEmailInAttendee: boolean = false;
    theErrorCustom: boolean = false;
    loaderDeleteCustom: boolean = false;
    uidCustomFieldDelete: string = null;
    indexCustomFieldDelete: number;

    allowChatSetting: boolean = false;
    allowChatUse: boolean = null;
    allowFilterCompany: boolean = null;
    allowFilterPost: boolean = null;
    allowFilterCustomFields: boolean = null;
    allowFilterCustomsFieldsArray: boolean[] = [false];
    showLetters: boolean = null;
    emailVisibilty: boolean = null;
    btnVisioVisibilty: boolean = null;
    showOnlyLoggedUsersVisibilty: boolean = null;
    // allowVisioSetting: boolean = false;
    // allowVisioUse: boolean = null;
    allowPublicEventOptions: boolean = false;
    defaultModulePublicAttendees: boolean = false;
    event: Event;

    loaderIndividualEmail: boolean = false;
    errorEmailTitle: boolean = false;
    errorEmailBody: boolean = false;
    attendeeResetPasswordEmails: any = null;
    attendeeResetPass: Attendee = null;

    first_access_language: string = null;

    // get the language of the user.
    public userLanguage: string;
    languages: Languages = null; //event languages
    activeCustomFieldLanguage: string = "PtBR";
    principalEventLang: string = "PtBR";

    //icons custom fields
    iconsImg: MenuIcons[] = icons;
    selectedIcon: string = PathIcons.icon_quiz;
    selectedIconFamily: string = "material-icons";
    selectedIconEdit: string = PathIcons.icon_quiz;
    selectedIconFamilyEdit: string = "material-icons";
    msgCustomFieldError: string;

    disableFilterComVisibility = false; // prevent multi click on filterVisibility company
    disableFilterPostVisibility = false; // prevent multi click on filterVisibility post
    disableCustomFieldsFilterVisibility = false;
    disableCustomFieldsFilterArrayVisibility: boolean[] = [];
    disableLettreVisibility = false; // prevent multi click on lettersVisibility
    customNameArray: any = [];

    companyFilterText: string = ''
    postFilterText: string = ''
    disabledCompanyFilterText: boolean = false
    disabledPostFilterText: boolean = false

    constructor(
        private route: ActivatedRoute,
        private dbAttendee: DbAttendeesProvider,
        private emailSvc: EmailProvider,
        private regex: RegexProvider,
        private translateService: TranslateService,
        private fb: FormBuilder,
        private global: GlobalService,
        private dbGroups: DbGroupsProvider,
        private dbEvent: DbEventsProvider,
        private dragula: DragulaService,
        private zone: NgZone
    ) {
        this.eventId = this.route.parent.params["_value"]["uid"];
        this.moduleId = this.route.snapshot.params["moduleId"];
        dragula.createGroup("bag-custom", this.dragulaOptions);

        // CUSTOM FIELD
        this.listCustomField = []; // array list custom field attendee.
        this.hideElement = false; // habilita ou desabilita o campo de edicao do Custom field

        // Custom field - Iniciando Formulário para edição e criação.
        this.formCustom = fb.group({
            // criando formCustom para pegar as informações do formulário custom field
            name: this.fb.group({
                PtBR: [
                    "",
                    Validators.compose([
                        Validators.required,
                        Validators.maxLength(80),
                    ]),
                ],
                EnUS: [
                    "",
                    Validators.compose([
                        Validators.required,
                        Validators.maxLength(80),
                    ]),
                ],
                EsES: [
                    "",
                    Validators.compose([
                        Validators.required,
                        Validators.maxLength(80),
                    ]),
                ],
                FrFR: [
                    "",
                    Validators.compose([
                        Validators.required,
                        Validators.maxLength(80),
                    ]),
                ],
                DeDE: [
                    "",
                    Validators.compose([
                        Validators.required,
                        Validators.maxLength(80),
                    ]),
                ],
            }),
            group_type: ["text"],
            group_exibicao: ["1"],
            group_edicao: ["2"],
        });

        this.formCustomEdit = fb.group({
            // criando formCustom para pegar as informações do formulário custom field
            name: this.fb.group({
                PtBR: [
                    "",
                    Validators.compose([
                        Validators.required,
                        Validators.maxLength(80),
                    ]),
                ],
                EnUS: [
                    "",
                    Validators.compose([
                        Validators.required,
                        Validators.maxLength(80),
                    ]),
                ],
                EsES: [
                    "",
                    Validators.compose([
                        Validators.required,
                        Validators.maxLength(80),
                    ]),
                ],
                FrFR: [
                    "",
                    Validators.compose([
                        Validators.required,
                        Validators.maxLength(80),
                    ]),
                ],
                DeDE: [
                    "",
                    Validators.compose([
                        Validators.required,
                        Validators.maxLength(80),
                    ]),
                ],
            }),
            type: [null],
            group_exibicao: [null],
            group_edicao: [null],
            uid: [null],
        });
    }

    resetCustom() {
        this.dbAttendee.getCustomFields(this.eventId, this.moduleId).then((docs) => {
            let listIds = [];
            for (let doc of docs) {
                listIds.push(doc.uid);
            }

            let fieldsCustom = {};
            let cont = 0;
            for (let uid of listIds) {
                fieldsCustom[uid] = {
                    order: cont,
                    required: false,
                    hide_field: false,
                    unique_edit: false,
                };
                cont++;
            }

            this.dbAttendee.updateModule(
                this.eventId,
                this.moduleId,
                fieldsCustom
            );
            this.loadFieldOptionsCustom();
        })
        // this.dbAttendee.getCustomFields(this.moduleId, (fields) => {
        //     let listIds = [];
        //     for (let field of fields["result"]) {
        //         listIds.push(field.uid);
        //     }

        //     let fieldsCustom = {};
        //     let cont = 0;
        //     for (let uid of listIds) {
        //         fieldsCustom[uid] = {
        //             order: cont,
        //             required: false,
        //             hide_field: false,
        //             unique_edit: false,
        //         };
        //         cont++;
        //     }

        //     this.dbAttendee.updateModule(
        //         this.eventId,
        //         this.moduleId,
        //         fieldsCustom
        //     );
        //     this.loadFieldOptionsCustom();
        // });
    }

    ngOnInit() {
        // start dragula reorder bag
        this.dragula.dropModel("bag-custom").subscribe((value: any) => {
            this.onReorder(value);
        });

        // $(document).ready(function () {
        //   $("body").tooltip({ selector: '[data-toggle=tooltip]' });
        // });
        this.getAllowedEditProfile();
        this.getModule();
        this.getUserLanguage();
        this.loadFieldsOptions();
        this.loadFieldOptionsCustom();
        this.getEvent();
        this.dbGroups.searchModulesAndGroups(this.eventId, (result) => {
            $(document).on("click", " .dropdown-menu", function (e) {
                e.stopPropagation();
            });
            this.listGroup = [];
            this.listGroup = result["groups"];
            this.moduleGroupId = result["moduleId"];
        });

        this.dbEvent.getEvent(this.eventId, (event) => {
         
        });
    }

    ngDoCheck() {
        // verifica se houve mudança no parâmetro do idModule
        if (this.moduleId !== this.route.params["_value"]["moduleId"]) {
            this.zone.run(() => {
                this.fieldOptionsCustom = null;
                this.listCustomField = [];
                this.moduleId = this.route.params["_value"]["moduleId"];

                this.getAllowedEditProfile();
                this.getModule();
                this.getUserLanguage();
                this.getCustomFields();
                this.loadFieldsOptions();
                this.loadFieldOptionsCustom();
                this.dbGroups.searchModulesAndGroups(this.eventId, (result) => {
                    $(document).on("click", " .dropdown-menu", function (e) {
                        e.stopPropagation();
                    });
                    this.listGroup = [];
                    this.listGroup = result["groups"];
                    this.moduleGroupId = result["moduleId"];
                });
            });
        }
    }

    setIcon(item) {
        this.selectedIcon = item.icon;
        this.selectedIconFamily = item.family;
        $(".dropdown-menu").removeClass("show");
    }

    setIconEdit(item) {
        this.selectedIconEdit = item.icon;
        this.selectedIconFamilyEdit = item.family;
    }

    // get the language of the user.
    // get the language of the user.
    userLanguageFormat: string = "PtBR";
    getUserLanguage() {
        this.global.getLanguage((language) => {
            this.userLanguage = language;
            this.userLanguageFormat = this.convertLangFormat(language);
        });
    }

    ngOnDestroy() {
        // remove dragula reorder bag case exit component
        this.dragula.destroy("bag-custom");
    }

    getEvent() {
        this.dbEvent.getEvent(this.eventId, (event: Event) => {
            if (typeof event !== "undefined" && event !== null) {
                this.event = event;
                this.requiredEditProfileVal = event.required_edit_profile;
                this.allowChatSetting = event.allow_chat;
                this.allowChatUse = event.allow_chat;
                // this.allowVisioSetting = event.allow_visio;
                // this.allowVisioUse = event.allow_visio;
                this.allowPublicEventOptions = event.visibility;
                this.principalEventLang = this.convertLangFormat(
                    this.event.language
                );
                this.activeCustomFieldLanguage = this.convertLangFormat(
                    this.event.language
                );
                this.languages = event.languages;
                if (this.moduleId == event.default_attendee_module) {
                    this.defaultModulePublicAttendees = true;
                }
            }
        });
    }
    // update order of modules
    onReorder(order: any): void {
        this.onReorderShow = true;
        this.listCustomField = order.targetModel;
        for (let i = 0; i < this.listCustomField.length; ++i) {
            this.listCustomField[i].order = i;
            // let customId = this.listCustomField[i].uid;
            // this.fieldOptionsCustom[customId].order = i;
        }
    }

    updateDocVisibility() {
        // Initialize the docVisibility field for the first time a document is added to the event
        // 'Profil' is the default choice

        if (!this.docChoice || this.docChoice === undefined || this.docChoice === '') {
            this.docChoice = 'Profil';
        }

        this.dbAttendee.updateModuleDocumentsVisibility(this.moduleId, { docVisibility: this.docChoice }, (status) => {
            if (!status) {
                console.error('failed to update docVisibility');
            }
        });
    }

    saveNewOrder() {
        this.dbAttendee.changeOrderCustoms(
            this.eventId,
            this.moduleId,
            this.fieldOptionsCustom,
            (result) => {
                if (result == true) {
                    this.successSwal.fire();
                } else {
                    this.errorSwal.fire();
                }
            }
        );
    }

    saveCustomFieldsChanges() {
        this.dbAttendee.updateCustomFieldsOrder(
            this.eventId, 
            this.moduleId, 
            this.listCustomField
        ).catch((error) => { console.error(error); });
    }

    getCustomFieldsName() {
        return new Promise((resolve, reject) => {
            this.dbAttendee.getCustomAttendeesFieldOptions(
                this.moduleId,
                (fields) => {
                    this.fieldOptionsCustom = fields;
                    this.dbAttendee.getCustomFields(this.eventId, this.moduleId).then((docs) => {
                        for (let i = 0; i < docs.length; i++) {
                            const doc = docs[i];
                            if (!doc) { 
                                continue;
                            }

                            const id = doc.uid;
                            const position = this.fieldOptionsCustom[id].order;
                            this.customNameArray[position] = doc.name[
                                this.convertLangFormat(this.global.language)
                            ];
                        }

                        const customVisOnFront = [];
                        if (this.customNameArray.length > 0) {
                            for (let i = 0; i < docs.length; i++) {
                                const doc = docs[i];
                                if (doc.exibicao === "1") {
                                    customVisOnFront.push(doc.name[
                                        this.convertLangFormat(this.global.language)
                                    ]);
                                }
                            }
                            this.customNameArray = customVisOnFront;
                        }

                        if (this.customNameArray.length > 0)
                            resolve(this.customNameArray);
                        else
                            reject(
                                "Unable de resolve getCustomFieldsName fuction..."
                            );
                    });
                }
            );
        });
    }

    getModule() {
        this.dbAttendee.attendeeModule(this.moduleId, async (module) => {
            this.module = module;
            this.nameModule = module.name;
            this.typeOrder = module["orderUsers"];
            this.allowChatUse = module["allow_chat"];
            this.allowFilterCompany =
                module["allowFilterCompany"] !== undefined
                    ? module["allowFilterCompany"]
                    : false;
            this.allowFilterCustomFields =
                module["allowFilterCustomFields"] !== undefined
                    ? module["allowFilterCustomFields"]
                    : false;
            this.allowFilterPost =
                module["allowFilterPost"] !== undefined
                    ? module["allowFilterPost"]
                    : false;
            this.showLetters =
                module["showLetters"] !== undefined
                    ? module["showLetters"]
                    : true;
            this.emailVisibilty =
                module["emailVisibilty"] !== undefined
                    ? module["emailVisibilty"]
                    : false;
            this.btnVisioVisibilty =
                module["btnVisioVisibilty"] !== undefined
                    ? module["btnVisioVisibilty"]
                    : false;
            this.showOnlyLoggedUsersVisibilty =
                this.module["showOnlyLoggedUsers"] !== undefined
                    ? module["showOnlyLoggedUsers"]
                    : false;
            this.companyFilterText = this.module['companyFilterText'] ? this.module['companyFilterText'] : ''
            this.postFilterText = this.module['postFilterText'] ? this.module['postFilterText'] : '';
            
            this.docChoice = module['docVisibility'] ? this.module['docVisibility'] : 'Profil';
            

            await this.getCustomFieldsName().catch((e) => {
                console.error(e);
            });

            if (this.customNameArray && this.customNameArray.length > 0) {
                for (let i = 0; i < this.customNameArray.length; i++) {
                    // Little code to format name of custom fields to be set to firestore database
                    let typeArray = this.customNameArray[i].split(" ");
                    let formatType = "";
                    let j = 0;
                    for (let ch of typeArray) {
                        j++;
                        formatType = formatType.concat(ch);
                        if (j !== typeArray.length) {
                            formatType = formatType.concat("_");
                        }
                    }
                    this.disableCustomFieldsFilterArrayVisibility.push(false);
                    this.allowFilterCustomsFieldsArray[i] =
                        module["allowFilterCustomField_" + formatType] !==
                        undefined
                            ? module["allowFilterCustomField_" + formatType]
                            : false;
                }
            }
            // this.allowVisioUse = module['allow_visio'];

            if (this.isEmpty(module.fieldsCustom)) {
                this.resetCustom();
            }
            if (!module.firstAccessCodeLanguage) {
                this.first_access_language = this.event.language;
                this.changeFirstAccessLanguage();
            } else {
                this.first_access_language = module.firstAccessCodeLanguage;
            }
            this.loadAttendees();
        });
    }

    loadAttendees() {
        this.loader = true;
        this.listAttendees = [];
        this.allListAttendees = [];
        this.dbAttendee.getAttendees(this.moduleId, this.typeOrder, (data) => {
            let list = data.result;
            let size = list.length;
            for (let i = 0; i < size; i++) {
                let aux = list[i];
                this.listAttendees.push(this.instantiateAttendee(aux));
                this.allListAttendees.push(this.instantiateAttendee(aux));
            }
            if (list && this.listAttendees.length > 0) {
                this.msgCustomFieldError = this.translateService.instant(
                    "global.customField_create_error"
                );
            } else {
                this.msgCustomFieldError = "";
            }
            this.loader = false;
        });
    }

    filterAttendeeGroup() {
        if (this.groupFilter == "all") {
            this.listAttendees = this.allListAttendees;
        } else {
            this.listAttendees = [];
            for (let attendee of this.allListAttendees) {
                for (let group of attendee.$groups) {
                    if (group.uid == this.groupFilter) {
                        this.listAttendees.push(attendee);
                    }
                }
            }
        }
    }

    loadFieldsOptions() {
        this.dbAttendee.getAttendeesFieldOptions(this.moduleId, (fields) => {
            this.fieldOptions = fields;
        });
    }

    loadFieldOptionsCustom() {
        this.dbAttendee.getCustomAttendeesFieldOptions(
            this.moduleId,
            (fields) => {
                this.fieldOptionsCustom = fields;
                this.getCustomFields();
            }
        );
    }

    instantiateAttendee(data) {
        let name = data.name;
        let email = data.email;
        let attendee = new Attendee(name, email, 5, this.eventId);

        attendee.$uid = data.uid;
        attendee.$emailRecovery = data.emailRecovery;
        attendee.$queryName = data.queryName;
        attendee.$identifier = data.identifier;
        attendee.$description = data.description;
        attendee.$company = data.company;
        attendee.$title = data.title;
        attendee.$photoUrl = data.photoUrl;
        attendee.$website = data.website;
        attendee.$facebook = data.facebook;
        attendee.$instagram = data.instagram;
        attendee.$linkedin = data.linkedin;
        attendee.$twitter = data.twitter;
        attendee.$createdAt = data.createdAt;
        attendee.$firstAccess = data.firstAccess;
        let groups = [];
        for (const uid in data.groups) {
            groups.push(data.groups[uid]);
        }
        attendee.$groups = groups.sort((a, b) =>
            a.name < b.name ? -1 : a.name > b.name ? 1 : 0
        );
        return attendee;
    }

    getAttendeeForSendEmail(attendee: Attendee) {
        this.attendeeObjEmail = attendee;
        if (attendee["emailRecovery"] !== null) {
            this.emailsAttendee =
                attendee["name"] +
                " <" +
                attendee["email"] +
                "; " +
                attendee["emailRecovery"] +
                ">";
        } else {
            this.emailsAttendee =
                attendee["name"] + " <" + attendee["email"] + ">";
        }
    }

    sendEmailToAttendee() {
        let attendeeEmails = [];
        this.errorEmailBody = false;
        this.errorEmailTitle = false;

        if (this.attendeeObjEmail["emailRecovery"] !== null) {
            attendeeEmails = [
                this.attendeeObjEmail["email"],
                this.attendeeObjEmail["emailRecovery"],
            ];
        } else {
            attendeeEmails = [this.attendeeObjEmail["email"]];
        }

        let email = {
            title: this.emailTitle,
            body: this.emailBody,
            email: attendeeEmails,
        };

        if (this.emailTitle !== null) {
            if (this.emailBody !== null) {
                this.loaderIndividualEmail = true;
                this.emailSvc.sendEmailToUser(email, (data) => {
                    this.loaderIndividualEmail = false;
                    if (data["result"] == true) {
                        this.successEmailSwal.fire();
                        $("#sendEmailUser").modal("toggle");
                        this.emailTitle = null;
                        this.emailBody = null;
                        this.attendeeObjEmail = null;
                    } else {
                        this.errorEmailSwal.fire();
                    }
                });
            } else {
                // body required
                this.errorEmailBody = true;
            }
        } else {
            // title required
            this.errorEmailTitle = true;
        }
    }

    getUserResetPassword(attendee: Attendee) {
        this.attendeeResetPass = attendee;
        if (attendee["emailRecovery"] !== null) {
            this.attendeeResetPasswordEmails = [
                attendee["email"],
                attendee["emailRecovery"],
            ];
        } else {
            this.attendeeResetPasswordEmails = [attendee["email"]];
        }
    }

    resetUserPassword() {
        $("#exportLoading").modal("show");
        this.translateService
            .getTranslation(this.first_access_language)
            .pipe(take(1))
            .subscribe((translation) => {
                let title = translation.global.reset_pass_email_password_title;
                let subject = translation.global.reset_pass_email_subject;
                this.emailSvc.sendEmailResetPassword(
                    this.attendeeResetPasswordEmails,
                    subject,
                    title,
                    this.attendeeResetPass["uid"],
                    (status) => {
                        if (status["result"] == true) {
                            $("#exportLoading").modal("toggle");
                            this.successPasswordSwal.fire();
                        } else {
                            $("#exportLoading").modal("toggle");
                            this.errorPasswordSwal.fire();
                        }
                    }
                );
            });
    }

    changeOrder() {
        this.dbAttendee.changeOrderItems(
            this.eventId,
            this.moduleId,
            this.typeOrder,
            (data) => {
                if (data == true) {
                    this.loadAttendees();
                }
            }
        );
    }

    updateFieldRequired(field, status) {
        this.dbAttendee.changeRequiredField(
            this.eventId,
            this.moduleId,
            field,
            status,
            this.fieldOptions
        );
    }

    updatUniqueEditField(field, status) {
        this.dbAttendee.changeUniqueEditField(
            this.eventId,
            this.moduleId,
            field,
            status,
            this.fieldOptions
        );
    }

    updateHideField(field, status) {
        this.dbAttendee.changeHideField(
            this.eventId,
            this.moduleId,
            field,
            status,
            this.fieldOptions
        );
    }

    updateFieldRequiredCustom(field, status) {
        this.dbAttendee.changeRequiredFieldCustom(
            this.eventId,
            this.moduleId,
            field,
            status,
            this.fieldOptionsCustom
        );
    }

    updatUniqueEditFieldCustom(field, status) {
        this.dbAttendee.changeUniqueEditFieldCustom(
            this.eventId,
            this.moduleId,
            field,
            status,
            this.fieldOptionsCustom
        );
    }

    updateHideFieldCustom(field, status) {
        this.dbAttendee.changeHideFieldCustom(
            this.eventId,
            this.moduleId,
            field,
            status,
            this.fieldOptionsCustom
        );
    }

    orderAttendees() {
        let aux;
        switch (this.typeOrder) {
            case OrderType.A_Z:
                aux = this.listAttendees;
                this.listAttendees = [];
                this.listAttendees = aux.sort(function (a, b) {
                    if (a.$queryName < b.$queryName) {
                        return -1;
                    }
                    if (a.$queryName > b.$queryName) {
                        return 1;
                    }
                    return 0;
                });
                break;

            case OrderType.Z_A:
                aux = this.listAttendees;
                this.listAttendees = [];
                this.listAttendees = aux.sort(function (a, b) {
                    if (a.$queryName < b.$queryName) {
                        return 1;
                    }
                    if (a.$queryName > b.$queryName) {
                        return -1;
                    }
                    return 0;
                });
                break;

            case OrderType.recentFirst:
                aux = this.listAttendees;
                this.listAttendees = [];
                this.listAttendees = aux.sort(
                    (a, b) => b.$createdAt - a.$createdAt
                ); // For descending sort
                break;

            case OrderType.oldFirst:
                aux = this.listAttendees;
                this.listAttendees = [];
                this.listAttendees = aux.sort(
                    (a, b) => a.$createdAt - b.$createdAt
                ); // For ascending sort
                break;
        }
    }

    selectedAll() {
        if (this.selectedAllInput) {
            for (let i = 0; i < this.listAttendees.length; i++) {
                this.attendeeSelected[this.listAttendees[i]["uid"]] = true;
            }
        } else {
            for (let i = 0; i < this.listAttendees.length; i++) {
                this.attendeeSelected[this.listAttendees[i]["uid"]] = false;
            }
        }
    }

    // get individual attendee to be deleted
    getRemoveAttendee(attendeeId, index) {
        this.attendeeRemoveId = attendeeId;
    }

    // delete attendee from event
    removeAttendee() {
        this.loader = true;
        let listRemove = [this.attendeeRemoveId];

        this.dbAttendee.removeAttendees(this.eventId, this.moduleId, listRemove, (data) => {
            if (data) {
                this.attendeeRemoveId = null;
                this.loadAttendees();
                this.successSwal.fire();
            } else {
                this.errorSwal.fire();
                this.loader = false;
            }
        });
    }

    attendeeIndexById(attendeeId) {
        return this.listAttendees
            .map(function (e) {
                return e.$uid;
            })
            .indexOf(attendeeId);
    }

    removeSelected() {
        this.loader = true;
        let listRemove = [];

        for (let i = 0; i < this.listAttendees.length; i++) {
            if (this.attendeeSelected[this.listAttendees[i].$uid] == true) {
                listRemove.push(this.listAttendees[i].$uid);
            }
        }

        this.dbAttendee.removeAttendees(
            this.eventId,
            this.moduleId,
            listRemove,
            async (data) => {
                if (data) {
                    //remove all selected box
                    for (let i = 0; i < this.attendeeSelected.length; i++) {
                        this.attendeeSelected[this.listAttendees[i].$uid] =
                            false;
                    }
                    // this.loader = false;
                    this.selectedAllInput = false;
                    await this.loadAttendees();
                    this.successSwal.fire();
                } else {
                    this.loader = false;
                    this.errorSwal.fire();
                }
            }
        );
    }

    // ------------------------------------------------------------------------
    // TODOS OS MÉTODOS E ACESSO AO BANCO RELACIONADO AO CUSTOM FIELD ATTENDEE.
    // ------------------------------------------------------------------------

    getCustomFields() {
        // this.dbAttendee.getCustomFields(this.moduleId, (data) => {
        //     this.listCustomField = [];
        //     if (data.message == "success") {
        //         //percorre os custom fields
        //         for (let i = 0; i < data.result.length; i++) {
        //             //pega o id do custom field
        //             let id = data.result[i].uid;
        //             //com base no id, verifica a posição do custom field que está armazenada no fieldOptionsCustom
        //             let position = this.fieldOptionsCustom[id].order;
        //             //insere o custom field na sua posição
        //             this.listCustomField[position] = data.result[i];
        //             this.customNameArray[position] =
        //                 data.result[i].name[
        //                     this.convertLangFormat(this.global.language)
        //                 ];
        //         }
        //     }
        // });
        this.dbAttendee.getCustomFields(this.eventId, this.moduleId).then((customFields) => {
            this.listCustomField = customFields;
        })
    }

    changeCustomExibition() {
        let exibicaoValue = this.formCustom.value.group_exibicao;
        let ctrlEdicao = this.formCustom.get("group_edicao");

        if (exibicaoValue == "3") {
            this.formCustom.controls["group_edicao"].setValue("3");
            ctrlEdicao.disable();
        } else {
            ctrlEdicao.enable();
        }
    }

    //mostra ou oculta o bloco de adicionar opções de resposta do custom field
    changeTypeCustom() {
        //pega o valor do campo relacionado ao tipo do campo
        let value = this.formCustom.controls.group_type.value;

        //caso o valor do campo seja 'texto' oculta o campo e caso seja 'select' exibe
        if (value == "text") {
            this.showTypeCustom = false;
        } else {
            this.showTypeCustom = true;
        }
    }

    //mostra ou oculta o bloco de adicionar opções de resposta do custom field
    changeTypeCustomEdit() {
        //pega o valor do campo relacionado ao tipo do campo
        let value = this.formCustomEdit.controls.type.value;

        //caso o valor do campo seja 'texto' oculta o campo e caso seja 'select' exibe
        if (value == "text") {
            this.showTypeCustomEdit = false;
        } else {
            this.showTypeCustomEdit = true;
            this.addNewOptionCustomEdit();
        }
    }

    changeGroupExibicao() {
        if (this.formCustomEdit.controls.group_exibicao.value == "3") {
            this.formCustomEdit.get("group_edicao").disable();
        } else {
            this.formCustomEdit.get("group_edicao").enable();
        }
    }

    addNewOptionCustom() {
        const order = this.listOptionsCustom.length;
        this.listOptionsCustom.push(new CFSAnswer(order));
    }

    addNewOptionCustomEdit(): void {
        const el = <HTMLInputElement>document.getElementById("cfInpAddAnswerOp");
        if (!el.value.trim()) {
            return;
        }
        const value =  new NameModule("", "", "", "", "");
        value[this.activeCustomFieldLanguage] = el.value;
        const optionName = this.customFieldInstantiateName(value);
        if (!optionName.status) {
            console.error("An error occurred in AttendeesComponent.customFieldInstantiateName(...)");
            return;
        }

        const order = this.listOptionsCustomEdit.length;
        this.listOptionsCustomEdit.push(new CFSAnswer(order, optionName.names));
        el.value = "";
    }

    getDeleteInputOption(index) {
        this.listOptionsCustom.splice(index, 1);
    }

    getDeleteInputOptionEdit(index) {
        let optionId = this.listOptionsCustomEdit[index].id;

        if (optionId !== undefined) {
            this.listOptionsCustomEditDelete.push(optionId);
        }

        this.listOptionsCustomEdit.splice(index, 1);
    }

    customFieldNameRequired: boolean = false;
    customFieldOptionNameRequired: boolean = false;
    customFieldCreateAllow: boolean = true;

    createCustomField() {
        this.customFieldNameRequired = false;
        this.customFieldOptionNameRequired = false;
        this.loaderBtnCustomField = true;
        this.errorFormCustomCreate = false;
        this.customFieldCreateAllow = true;

        const data = this.formCustom.getRawValue();
        const customName = this.customFieldInstantiateName(data.name);
        if (!customName.status) {
            this.customFieldNameRequired = true;
            this.loaderBtnCustomField = false;
            return;
        }

        const customField = new eventCustomField( // Criando o Objeto para enviar ao banco.
            customName.names,
            data.group_type,
            data.group_exibicao,
            data.group_edicao
        );
        customField.icon = this.selectedIcon;
        customField.iconFamily = this.selectedIconFamily;
        customField.order = this.listCustomField.length;

        if (customField.type == "s") {   // select one answer
            // verify if at least one possible answer has any character in it
            customField.answerOptions = [];
            customField.answerSelected = []; // this should remain empty when sended to the db
            for (let option of this.listOptionsCustom) {
                const answerName = this.validateAnswerValue(option.value);
                if (!answerName.status) {
                    this.customFieldCreateAllow = false;
                    this.customFieldOptionNameRequired = true;
                    this.loaderBtnCustomField = false;
                    continue;
                }
                option.value = Object.assign({}, answerName.answer);
                customField.answerOptions.push(Object.assign({}, option));
            }
            if (customField.answerOptions.length == 0) {
                $("#warningSelectOption").modal("show");
                return;
            }
        }

        this.dbAttendee.createCustomField(
            this.eventId,
            this.moduleId,
            customField
        ).then((customFieldData) => {
            //coloca o novo custom field no array
            if (this.fieldOptionsCustom == undefined)
                this.fieldOptionsCustom = {};
            this.fieldOptionsCustom[customFieldData.uid] = {
                order: this.listCustomField.length,
                required: false,
                unique_edit: false,
            };

            let checkExist = this.checkIndexExists(
                this.listCustomField,
                customFieldData.uid
            );
            if (checkExist == -1) {
                this.listCustomField.push(customFieldData);
                //exibe alerta de sucesso
                this.successSwal.fire();
            }
            // this.listCustomField.push(customFieldData)
            this.loaderBtnCustomField = false;
            //reseta o form
            this.formCustom.patchValue({
                name: {
                    PtBR: "",
                    EnUS: "",
                    EsES: "",
                    FrFR: "",
                    DeDE: "",
                },
                // group_type: 'text',
                // group_exibicao: '1',
                // group_edicao: '2'
            });

            // this.formCustom.get('group_edicao').enable();

            //reseta a lista de respostas
            this.listOptionsCustom = [new CFSAnswer(0)];
            //oculta o bloco de respostas
            // this.showTypeCustom = false;
        }).catch((error) => {
            console.error(error);
            this.loaderBtnCustomField = false;
            this.errorSwal.fire();
        });
    }

    checkIndexExists(array, itemId) {
        return array
            .map(function (e) {
                return e.uid;
            })
            .indexOf(itemId);
    }

    customFieldInstantiateName(names) {
        let obj = {
            status: 1,
            names: null,
        };
        let principalLanguage = this.convertLangFormat(this.event.language);
        if (names[principalLanguage] == "") {
            obj.status = 0;
            // error, blank name - required name
        } else {
            for (let lang in names) {
                if (lang !== principalLanguage) {
                    if (names[lang] == "") {
                        names[lang] = names[principalLanguage];
                    }
                }
            }
            obj.status = 1;
            obj.names = names;
        }
        return obj;
    }

    /**
     * @returns an object with two parms, status (boolean) that gives the 
     * result of the validation; and answer (NameModule) with all languages 
     * with some value, i.e., if the field of a language is empty, it receives
     * the value of the event main language
     */
    validateAnswerValue(answer: NameModule) {
        const principalLanguage = this.convertLangFormat(this.event.language);

        if (answer[principalLanguage] == "") {
            return { status: false, answer: null };
        }

        for (let lang in answer) {
            if (
                answer[lang] !== principalLanguage &&
                answer[lang].trim() == ""
            ) {
                answer[lang] = answer[principalLanguage];
            }
        }
        return { status: true, answer: answer }
    }

    // ativa a visualização do campo de edição custom field

    getEditCustom(index: string) {
        this.listOptionsCustomEdit = [];
        this.editCustomField = this.listCustomField[index];
        this.selectedIconEdit = this.listCustomField[index].icon;
        this.selectedIconFamilyEdit = this.listCustomField[index].iconFamily;

        //esvazia o array de opções removidas
        this.listOptionsCustomEditDelete = [];

        const aux = `.hidden${index}`;
        if ($(aux).is(":visible")) {
            $(aux).hide();
        } else {
            $(".hideAll").hide();
            $(aux).show();
        }

        const customField = this.listCustomField[index];
        this.formCustomEdit.patchValue({
            uid: customField.uid,
            name: {
                PtBR: customField.name["PtBR"],
                EnUS: customField.name["EnUS"],
                EsES: customField.name["EsES"],
                FrFR: customField.name["FrFR"],
                DeDE: customField.name["DeDE"],
            },
            type: customField.type,
            group_exibicao: customField.exibicao,
            group_edicao: customField.edicao,
        });

        if (customField.type == "s") {
            this.showTypeCustomEdit = true;
            // this.getOptionsCustomFields(customField.uid);
            this.listOptionsCustomEdit = [...customField.answerOptions];
        } else {
            this.showTypeCustomEdit = false;
        }

        if (customField.exibicao == "3") {
            this.formCustomEdit.get("group_edicao").disable();
        } else {
            this.formCustomEdit.get("group_edicao").enable();
        }
    }

    // getOptionsCustomFields(customId) {
    //     this.dbAttendee.getOptionsCustomField(
    //         this.moduleId,
    //         customId,
    //         (data) => {
    //             if (data.message == "success") {
    //                 this.listOptionsCustomEdit = data.result;
    //             } else {
    //             }
    //         }
    //     );
    // }

    isEmpty(obj) {
        for (var key in obj) {
            if (obj.hasOwnProperty(key)) {
                return false;
            }
        }
        return true;
    }

    // Editar o custom field selecionado.
    customFieldNameRequiredEdit: boolean = false;
    editCustomFieldAttendee(
        data: any,
        cf: eventCustomField,
        index: number
    ) {
        this.loaderEditCustomField = true;
        this.customFieldNameRequiredEdit = false;

        let toBeUpdated = {};
        if (
            data.name[this.activeCustomFieldLanguage] != 
            cf.name[this.activeCustomFieldLanguage]
        ) {
            const customName = this.customFieldInstantiateName(data.name);
            if (!customName.status) {
                this.customFieldNameRequiredEdit = true;
                return;
            }
            toBeUpdated['name'] = customName.names;
        }
        if (data.group_exibicao != cf.exibicao) {
            toBeUpdated['exibicao'] = data.group_exibicao;
        }
        if (data.group_edicao != cf.edicao || data.group_edicao == undefined) {
            if (data.group_exibicao == '3') {
                // if data.group_exibicao was changed to '3', data.group_edicao
                // has value 'undefined'
                toBeUpdated['edicao'] = '3';
            } else {
                toBeUpdated['edicao'] = data.group_edicao;
            }
        }
        if (
            cf.answerOptions.length != this.listOptionsCustomEdit.length ||
            cf.answerOptions.some((option, i) => {
                return option.id != this.listOptionsCustomEdit[i].id;
            })
        ) { // check if both arrays are equal
            toBeUpdated['answerOptions'] = this.listOptionsCustomEdit.map((e) => {
                e.value = Object.assign({}, e.value);
                return Object.assign({}, e);
            });
        }

        this.dbAttendee.updateCustomField(
            this.eventId,
            this.moduleId,
            cf.uid,
            toBeUpdated
        ).then(() => {
            for (const [key, value] of Object.entries(toBeUpdated)) {
                cf[key] = value;
            }

            this.listCustomField[index] = cf;
            $(".hideAll").hide();
            this.successSwal.fire();
        }).catch((error) => {
            console.error(error);
            this.errorSwal.fire();
        }).finally(() => {
            this.loaderEditCustomField = false;
        });
    }

    editAttendeeCF(customField: any[]) {

    }

    updateOptionCustomField(customId, option) {
        this.dbAttendee.updateOptionCustomField(
            this.moduleId,
            customId,
            option
        );
    }

    createOptionCustomField(customId, option) {
        this.dbAttendee.createOptionCustomField(
            this.moduleId,
            customId,
            option
        );
    }

    removeOptionOfAttendees(customId, optionId) {
        this.dbAttendee.removeOptionOfAttendees(
            this.eventId,
            this.moduleId,
            customId,
            optionId
        );
    }

    removeOptionCustomField(customId, optionId) {
        this.dbAttendee.removeOptionCustomField(
            this.moduleId,
            customId,
            optionId
        );
    }

    clearUsersResultCustom(customId) {
        this.dbAttendee.clearUsersResultCustom(
            this.eventId,
            this.moduleId,
            customId
        );
    }

    getDeleteCustomField(customField, index) {
        this.uidCustomFieldDelete = customField.uid;
        this.indexCustomFieldDelete = index;
    }

    async removeCustomFieldAttendee() {
        this.loaderDeleteCustom = true;
        try {
            let result = await this.dbAttendee.deleteCustomField(
                this.eventId,
                this.moduleId,
                this.uidCustomFieldDelete
            );
            if (result.code == 200) {
                this.listCustomField.splice(this.indexCustomFieldDelete, 1);
                this.successSwal.fire();
                this.loaderDeleteCustom = false;
            } else {
                this.errorSwal.fire();
                this.loaderDeleteCustom = false;
            }
        } catch (error) {
            this.errorSwal.fire();
            this.loaderDeleteCustom = false;
        }
    }

    getAllowedEditProfile() {
        this.dbAttendee.getAllowedEditProfile(this.moduleId, (data) => {
            this.allowedEditProfileVal = data;
        });
    }

    changeAllowedEditProfile() {
        let newValue;

        if (this.allowedEditProfileVal) {
            newValue = false;
        } else {
            newValue = true;
        }

        this.dbAttendee.changeAllowedEditProfile(
            this.moduleId,
            this.eventId,
            newValue,
            (data) => {
                if (data == "success") {
                    this.allowedEditProfileVal = newValue;
                } else {
                    this.errorSwal.fire();
                }
            }
        );
    }

    filterCustomEditAttendee(itemList: eventCustomField[]): eventCustomField[] {
        let result: eventCustomField[] = [];
        //your filter logic here
        for (let custom of itemList) {
            if (custom !== undefined && custom !== null) {
                if (custom.edicao != "3") {
                    result.push(custom);
                }
            }
        }

        return result;
    }

    /******************************************************** EXCEL IMPORT **************************************************************/

    /* GET EXCEL AND CONVERT TO JSON DATA */
    sheetValidFormat: boolean = false;
    onFileChange(evt: any) {
        this.preCheckError = false;
        this.sheetValidFormat = false;
        /* wire up file reader */
        const target: DataTransfer = <DataTransfer>evt.target;
        this.nameFile = target.files[0].name; // passa o nome do arquivo para o input

        if (target.files.length !== 1) {
            throw new Error("Cannot use multiple files");
        }

        const reader: FileReader = new FileReader();
        reader.onload = (e: any) => {
            /* read workbook */
            const bstr: string = e.target.result;
            const wb: XLSX.WorkBook = XLSX.read(bstr, { type: "binary" });
            this.messageErrors = [];
            for (let i = 0; i < wb.SheetNames.length; i++) {
                let sheetAuxName: string = wb.SheetNames[i];
                let wsAux: XLSX.WorkSheet = wb.Sheets[sheetAuxName];
                let sheetAuxData = <AOA>(
                    XLSX.utils
                        .sheet_to_json(wsAux, { header: 1 })
                        .filter((o) => !Object.keys(o).every((k) => !o[k]))
                );
                if (sheetAuxName.includes("Principal - ")) {
                    this.sheetValidFormat = true;
                }
                if (sheetAuxName !== "Principal - " + this.event.language) {
                    this.preCheckImport(sheetAuxData, sheetAuxName);

                    if (sheetAuxName == "pt-BR") {
                        this.dataImportPtBR = sheetAuxData;
                    }
                    if (sheetAuxName == "en-US") {
                        this.dataImportEnUS = sheetAuxData;
                    }
                    if (sheetAuxName == "es-ES") {
                        this.dataImportEsES = sheetAuxData;
                    }
                    if (sheetAuxName == "fr-FR") {
                        this.dataImportFrFR = sheetAuxData;
                    }
                    if (sheetAuxName == "de-DE") {
                        this.dataImportDeDE = sheetAuxData;
                    }
                }
            }
            /* grab first sheet */
            const wsname: string = wb.SheetNames[0];
            const ws: XLSX.WorkSheet = wb.Sheets[wsname];

            let datas = <AOA>(
                XLSX.utils
                    .sheet_to_json(ws, { header: 1 })
                    .filter((o) => !Object.keys(o).every((k) => !o[k]))
            );
            if (datas.length < 1000) {
                /* save data */
                this.data = <AOA>(
                    XLSX.utils
                        .sheet_to_json(ws, { header: 1 })
                        .filter((o) => !Object.keys(o).every((k) => !o[k]))
                );
            } else {
                this.messageErrors.push(
                    this.translateService.instant(
                        "global.required_docs_length_inf_1000"
                    )
                );
                this.preCheckError = true;
                this.theError = true;
            }
        };
        reader.readAsBinaryString(target.files[0]);
    }

    preCheckError: boolean = false;
    preCheckImport(data, tabName) {
        if (data.length > 0) {
            let row = 1;
            for (const element of data) {
                if (this.ignoreRowEmpty(element)) {
                    if (typeof element[this.identifier] !== "undefined") {
                        // converte ids números para strings
                        if (typeof element[this.identifier] === "number") {
                            const aux: number = element[this.identifier];
                            element[this.identifier] = aux.toString();
                        }
                    } else {
                        const column = this.checkLetterCols(this.identifier);
                        this.messageErrors.push(
                            tabName +
                                " -> " +
                                column +
                                row +
                                ": " +
                                " " +
                                this.translateService.instant(
                                    "global.required_id"
                                )
                        );
                        this.preCheckError = true;
                    }
                    row++;
                }
            }
        }
    }

    invalidFormatSheetMsg: string = null;
    checkImport() {
        this.invalidFormatSheetMsg = null;
        this.loaderImport = true;
        this.theError = false;
        this.theErrorApi = false;
        this.theErrorApiDb = false;
        this.successMsg = false;
        this.newAttendees = [];
        this.messageErrors = [];
        this.attendeeErrorsApi = [];
        let validRows: number = 0;

        if (this.data !== null)
            this.data = this.transformIdentifiersIntoString(this.data);

        if (this.dataImportEnUS !== null)
            this.dataImportEnUS = this.transformIdentifiersIntoString(
                this.dataImportEnUS
            );

        if (this.dataImportPtBR !== null)
            this.dataImportPtBR = this.transformIdentifiersIntoString(
                this.dataImportPtBR
            );

        if (this.dataImportEsES !== null)
            this.dataImportEsES = this.transformIdentifiersIntoString(
                this.dataImportEsES
            );

        if (this.dataImportFrFR !== null)
            this.dataImportFrFR = this.transformIdentifiersIntoString(
                this.dataImportFrFR
            );

        if (this.dataImportDeDE !== null)
            this.dataImportDeDE = this.transformIdentifiersIntoString(
                this.dataImportDeDE
            );

        if (!this.preCheckError) {
            if (this.data !== null) {
                if (this.sheetValidFormat) {
                    let row = 1;
                    let fieldNames = this.data[0];
                    for (const element of this.data) {
                        // IGNORE EMPTY ROWS
                        if (this.ignoreRowEmpty(element)) {
                            validRows++;
                            if (row > 1) {
                                const newAttendees = new Attendee(
                                    null,
                                    "",
                                    5,
                                    this.eventId
                                );
                                // VALIDATE IDENTIFIERS
                                if (
                                    typeof element[this.identifier] !==
                                    "undefined"
                                ) {
                                    // converte ids números para strings
                                    if (
                                        typeof element[this.identifier] ===
                                        "number"
                                    ) {
                                        const aux: number =
                                            element[this.identifier];
                                        element[this.identifier] =
                                            aux.toString();
                                    }
                                    newAttendees.$identifier =
                                        element[this.identifier];
                                    this.checkNumberRowIds(
                                        newAttendees.$identifier,
                                        row
                                    );
                                } else {
                                    const column = this.checkLetterCols(
                                        this.identifier
                                    );
                                    this.messageErrors.push(
                                        "Principal -> " +
                                            column +
                                            row +
                                            ": " +
                                            " " +
                                            this.translateService.instant(
                                                "global.required_id"
                                            )
                                    );
                                }

                                // VALIDATE E-MAILS
                                if (
                                    typeof element[this.email] !==
                                        "undefined" &&
                                    element[this.email] !== ""
                                ) {
                                    if (
                                        this.regex.validateEmail(
                                            element[this.email]
                                        )
                                    ) {
                                        newAttendees.$email =
                                            this.regex.removeAccents(
                                                element[this.email]
                                            );
                                        newAttendees.$email =
                                            newAttendees.$email.toLowerCase();
                                        this.checkNumberRowEmail(
                                            newAttendees.$email,
                                            row
                                        );
                                    } else {
                                        const column = this.checkLetterCols(
                                            this.email
                                        );
                                        this.messageErrors.push(
                                            "Principal -> " +
                                                column +
                                                row +
                                                ":" +
                                                " " +
                                                this.translateService.instant(
                                                    "global.invalid_email"
                                                )
                                        );
                                    }
                                } else {
                                    const column = this.checkLetterCols(
                                        this.email
                                    );
                                    this.messageErrors.push(
                                        "Principal -> " +
                                            column +
                                            row +
                                            ":" +
                                            " " +
                                            this.translateService.instant(
                                                "global.required_email"
                                            )
                                    );
                                }

                                if (
                                    typeof element[this.emailRecovery] !==
                                        "undefined" &&
                                    element[this.emailRecovery] !== ""
                                ) {
                                    if (
                                        this.regex.validateEmail(
                                            element[this.emailRecovery]
                                        )
                                    ) {
                                        newAttendees.$emailRecovery =
                                            this.regex.removeAccents(
                                                element[this.emailRecovery]
                                            );
                                        newAttendees.$emailRecovery =
                                            newAttendees.$emailRecovery.toLowerCase();
                                    } else {
                                        const column = this.checkLetterCols(
                                            this.emailRecovery
                                        );
                                        this.messageErrors.push(
                                            "Principal -> " +
                                                column +
                                                row +
                                                ":" +
                                                " " +
                                                this.translateService.instant(
                                                    "global.invalid_email"
                                                )
                                        );
                                    }
                                }

                                // VALIDATE NAME
                                if (typeof element[this.name] !== "undefined") {
                                    let name = element[this.name].toString();
                                    name.trim();
                                    newAttendees.$name = name;
                                    newAttendees.$queryName =
                                        name.toUpperCase();
                                } else {
                                    const column = this.checkLetterCols(
                                        this.name
                                    );
                                    this.messageErrors.push(
                                        "Principal -> " +
                                            column +
                                            row +
                                            ": " +
                                            " " +
                                            this.translateService.instant(
                                                "global.alert_name"
                                            )
                                    );
                                }

                                let indexPrincipalLanguage =
                                    this.compareIdentifierAttendee(
                                        newAttendees["identifier"],
                                        this.data
                                    );
                                if (indexPrincipalLanguage > -1) {
                                    this.checkCustomFieldImport(
                                        this.data[0],
                                        this.data[indexPrincipalLanguage],
                                        this.principalEventLang,
                                        this.customField
                                    );
                                }

                                // // PtBR
                                let indexTranslatedPtBr =
                                    this.compareIdentifierAttendee(
                                        newAttendees["identifier"],
                                        this.dataImportPtBR
                                    );
                                if (
                                    indexTranslatedPtBr > -1 &&
                                    this.principalEventLang !== "PtBR"
                                ) {
                                    let fieldNamesPtBR = this.dataImportPtBR[0];
                                    newAttendees.$title["PtBR"] =
                                        typeof this.dataImportPtBR[
                                            indexTranslatedPtBr
                                        ][this.translatedTitle] !==
                                            "undefined" &&
                                        this.dataImportPtBR[
                                            indexTranslatedPtBr
                                        ][this.translatedTitle] !== null
                                            ? this.dataImportPtBR[
                                                  indexTranslatedPtBr
                                              ][this.translatedTitle]
                                            : "";
                                    newAttendees.$description["PtBR"] =
                                        typeof this.dataImportPtBR[
                                            indexTranslatedPtBr
                                        ][this.translatedDescription] !==
                                            "undefined" &&
                                        this.dataImportPtBR[
                                            indexTranslatedPtBr
                                        ][this.translatedDescription] !== null
                                            ? this.dataImportPtBR[
                                                  indexTranslatedPtBr
                                              ][this.translatedDescription]
                                            : "";
                                    this.checkCustomFieldImport(
                                        fieldNamesPtBR,
                                        this.dataImportPtBR[
                                            indexTranslatedPtBr
                                        ],
                                        "PtBR",
                                        this.customFieldTranslated
                                    );
                                }

                                // EnUS
                                let indexTranslatedEnUs =
                                    this.compareIdentifierAttendee(
                                        newAttendees["identifier"],
                                        this.dataImportEnUS
                                    );
                                if (
                                    indexTranslatedEnUs > -1 &&
                                    this.principalEventLang !== "EnUS"
                                ) {
                                    let fieldNamesEnUS = this.dataImportEnUS[0];
                                    newAttendees.$title["EnUS"] =
                                        typeof this.dataImportEnUS[
                                            indexTranslatedEnUs
                                        ][this.translatedTitle] !==
                                            "undefined" &&
                                        this.dataImportEnUS[
                                            indexTranslatedEnUs
                                        ][this.translatedTitle] !== null
                                            ? this.dataImportEnUS[
                                                  indexTranslatedEnUs
                                              ][this.translatedTitle]
                                            : "";
                                    newAttendees.$description["EnUS"] =
                                        typeof this.dataImportEnUS[
                                            indexTranslatedEnUs
                                        ][this.translatedDescription] !==
                                            "undefined" &&
                                        this.dataImportEnUS[
                                            indexTranslatedEnUs
                                        ][this.translatedDescription] !== null
                                            ? this.dataImportEnUS[
                                                  indexTranslatedEnUs
                                              ][
                                                  this.translatedDescription
                                              ].replace(
                                                  /href="/g,
                                                  'class="wysiwyg-link" href="'
                                              )
                                            : "";
                                    this.checkCustomFieldImport(
                                        fieldNamesEnUS,
                                        this.dataImportEnUS[
                                            indexTranslatedEnUs
                                        ],
                                        "EnUS",
                                        this.customFieldTranslated
                                    );
                                }

                                // EsES
                                let indexTranslatedEsEs =
                                    this.compareIdentifierAttendee(
                                        newAttendees["identifier"],
                                        this.dataImportEsES
                                    );
                                if (
                                    indexTranslatedEsEs > -1 &&
                                    this.principalEventLang !== "EsES"
                                ) {
                                    let fieldNamesEsES = this.dataImportEsES[0];
                                    newAttendees.$title["EsES"] =
                                        typeof this.dataImportEsES[
                                            indexTranslatedEsEs
                                        ][this.translatedTitle] !==
                                            "undefined" &&
                                        this.dataImportEsES[
                                            indexTranslatedEsEs
                                        ][this.translatedTitle] !== null
                                            ? this.dataImportEsES[
                                                  indexTranslatedEsEs
                                              ][this.translatedTitle]
                                            : "";
                                    newAttendees.$description["EsES"] =
                                        typeof this.dataImportEsES[
                                            indexTranslatedEsEs
                                        ][this.translatedDescription] !==
                                            "undefined" &&
                                        this.dataImportEsES[
                                            indexTranslatedEsEs
                                        ][this.translatedDescription] !== null
                                            ? this.dataImportEsES[
                                                  indexTranslatedEsEs
                                              ][
                                                  this.translatedDescription
                                              ].replace(
                                                  /href="/g,
                                                  'class="wysiwyg-link" href="'
                                              )
                                            : "";
                                    this.checkCustomFieldImport(
                                        fieldNamesEsES,
                                        this.dataImportEsES[
                                            indexTranslatedEsEs
                                        ],
                                        "EsES",
                                        this.customFieldTranslated
                                    );
                                }

                                // FrFR
                                let indexTranslatedFrFr =
                                    this.compareIdentifierAttendee(
                                        newAttendees["identifier"],
                                        this.dataImportFrFR
                                    );
                                if (
                                    indexTranslatedFrFr > -1 &&
                                    this.principalEventLang !== "FrFR"
                                ) {
                                    let fieldNamesFrFR = this.dataImportFrFR[0];
                                    newAttendees.$title["FrFR"] =
                                        typeof this.dataImportFrFR[
                                            indexTranslatedFrFr
                                        ][this.translatedTitle] !==
                                            "undefined" &&
                                        this.dataImportFrFR[
                                            indexTranslatedFrFr
                                        ][this.translatedTitle] !== null
                                            ? this.dataImportFrFR[
                                                  indexTranslatedFrFr
                                              ][this.translatedTitle]
                                            : "";
                                    newAttendees.$description["FrFR"] =
                                        typeof this.dataImportFrFR[
                                            indexTranslatedFrFr
                                        ][this.translatedDescription] !==
                                            "undefined" &&
                                        this.dataImportFrFR[
                                            indexTranslatedFrFr
                                        ][this.translatedDescription] !== null
                                            ? this.dataImportFrFR[
                                                  indexTranslatedFrFr
                                              ][
                                                  this.translatedDescription
                                              ].replace(
                                                  /href="/g,
                                                  'class="wysiwyg-link" href="'
                                              )
                                            : "";
                                    this.checkCustomFieldImport(
                                        fieldNamesFrFR,
                                        this.dataImportFrFR[
                                            indexTranslatedFrFr
                                        ],
                                        "FrFR",
                                        this.customFieldTranslated
                                    );
                                }

                                // DeDE
                                let indexTranslatedDeDe =
                                    this.compareIdentifierAttendee(
                                        newAttendees["identifier"],
                                        this.dataImportDeDE
                                    );
                                if (
                                    indexTranslatedDeDe > -1 &&
                                    this.principalEventLang !== "DeDE"
                                ) {
                                    let fieldNamesDeDE = this.dataImportDeDE[0];
                                    newAttendees.$title["DeDE"] =
                                        typeof this.dataImportDeDE[
                                            indexTranslatedDeDe
                                        ][this.translatedTitle] !==
                                            "undefined" &&
                                        this.dataImportDeDE[
                                            indexTranslatedDeDe
                                        ][this.translatedTitle] !== null
                                            ? this.dataImportDeDE[
                                                  indexTranslatedDeDe
                                              ][this.translatedTitle]
                                            : "";
                                    newAttendees.$description["DeDE"] =
                                        typeof this.dataImportDeDE[
                                            indexTranslatedDeDe
                                        ][this.translatedDescription] !==
                                            "undefined" &&
                                        this.dataImportDeDE[
                                            indexTranslatedDeDe
                                        ][this.translatedDescription] !== null
                                            ? this.dataImportDeDE[
                                                  indexTranslatedDeDe
                                              ][
                                                  this.translatedDescription
                                              ].replace(
                                                  /href="/g,
                                                  'class="wysiwyg-link" href="'
                                              )
                                            : "";
                                    this.checkCustomFieldImport(
                                        fieldNamesDeDE,
                                        this.dataImportDeDE[
                                            indexTranslatedDeDe
                                        ],
                                        "DeDE",
                                        this.customFieldTranslated
                                    );
                                }

                                if (
                                    typeof element[this.title] !== "undefined"
                                ) {
                                    newAttendees.$title[
                                        this.principalEventLang
                                    ] = element[this.title];
                                }

                                if (
                                    typeof element[this.description] !==
                                    "undefined"
                                ) {
                                    newAttendees.$description[
                                        this.principalEventLang
                                    ] = element[this.description]
                                        .toString()
                                        .replace(
                                            /href="/g,
                                            'class="wysiwyg-link" href="'
                                        );
                                }

                                if (
                                    typeof element[this.picture] !== "undefined"
                                ) {
                                    newAttendees.$photoUrl =
                                        element[this.picture];
                                }

                                if (
                                    typeof element[this.company] !== "undefined"
                                ) {
                                    newAttendees.$company =
                                        element[this.company];
                                }

                                if (
                                    typeof element[this.website] !== "undefined"
                                ) {
                                    newAttendees.$website =
                                        element[this.website];
                                }

                                if (
                                    typeof element[this.facebook] !==
                                    "undefined"
                                ) {
                                    newAttendees.$facebook =
                                        element[this.facebook];
                                }

                                if (
                                    typeof element[this.twitter] !== "undefined"
                                ) {
                                    newAttendees.$twitter =
                                        element[this.twitter];
                                }

                                if (
                                    typeof element[this.linkedin] !==
                                    "undefined"
                                ) {
                                    newAttendees.$linkedin =
                                        element[this.linkedin];
                                }

                                if (
                                    typeof element[this.instagram] !==
                                    "undefined"
                                ) {
                                    newAttendees.$instagram =
                                        element[this.instagram];
                                }

                                if (
                                    typeof element[this.groups] !== "undefined"
                                ) {
                                    newAttendees.$groups = element[this.groups];
                                }

                                if (
                                    typeof element[this.documents] !==
                                    "undefined"
                                ) {
                                    newAttendees.$documents =
                                        element[this.documents];
                                }

                                newAttendees.$language = this.event["language"];

                                newAttendees.$customFields = [];
                                newAttendees.$customFields = this.allCustoms;
                                this.newAttendees.push(newAttendees);
                                this.allCustoms = [];

                                if (this.messageErrors.length > 0) {
                                    this.loaderImport = false;
                                    this.theError = true;
                                    this.theErrorApi = false;
                                    this.successMsg = false;
                                    this.theErrorApiDb = false;
                                    this.invalidFormatSheetMsg = null;
                                    this.sheetValidFormat = false;
                                }
                                this.clearInputFile(); // limpa o input do arquivo.
                            }
                        }
                        row++;
                    }

                    // this.loaderImport = false //apagar

                    if (
                        this.newAttendees.length == validRows - 1 &&
                        this.theError == false
                    ) {
                        this.importAttendees();
                    }
                } else {
                    // formato de planilha invalido
                    this.loaderImport = false;
                    this.theError = false;
                    this.theErrorApi = false;
                    this.successMsg = false;
                    this.theErrorApiDb = false;
                    this.invalidFormatSheetMsg = this.translateService.instant(
                        "global.invalid_spreadsheet"
                    );
                }
            } else {
                this.loaderImport = false;
                this.theError = true;
                this.messageErrors.push(
                    this.translateService.instant("global.no_file_selected")
                );
            }
        } else {
            this.loaderImport = false;
            this.theError = true;
            this.theErrorApi = false;
            this.successMsg = false;
            this.theErrorApiDb = false;
            this.invalidFormatSheetMsg = null;
        }
    }

    importAttendees() {
        // call API to import attendees
        this.dbAttendee.importAttendees(
            this.eventId,
            this.moduleId,
            this.nameModule,
            this.newAttendees,
            this.moduleGroupId,
            (status) => {
                if (status["message"] == "success") {
                    // import success
                    if (
                        status["result"]["emailDouble"].length == 0 &&
                        status["result"]["idDouble"].length == 0
                    ) {
                        this.theError = false;
                        this.theErrorApi = false;
                        this.theErrorApiDb = false;
                        this.loaderImport = false;
                        this.successMsg = true;
                        this.messageErrors = [];
                        this.loadAttendees();
                        if (status["result"]["success"] !== undefined) {
                            this.totalAttendeeCreated =
                                status["result"]["success"].length;
                        }
                        if (status["result"]["updateds"] !== undefined) {
                            this.totalAttendeeUpdated =
                                status["result"]["updateds"].length;
                        }
                        if (status["result"]["fail"].length >= 1) {
                            this.attendeeErrorsApi = status["result"]["fail"];
                            this.totalAttendeeFail =
                                status["result"]["fail"].length;
                            this.theErrorApi = true;
                        }

                        if (status["result"]["failGroups"].length >= 1) {
                            this.invalidGroups = status["result"]["failGroups"];
                            this.theErrorGroup = true;
                        }

                        if (status["result"]["failDocuments"].length >= 1) {
                            this.invalidDocuments =
                                status["result"]["failDocuments"];
                            this.theErrorDocument = true;
                        }

                        if (
                            status["result"]["emailInAttendee"] !== undefined &&
                            status["result"]["emailInAttendee"].length >= 1
                        ) {
                            for (let attendee of status["result"][
                                "emailInAttendee"
                            ]) {
                                this.attendeeErrorsApi.push(attendee);
                                this.messageErrors.push(
                                    attendee["email"] +
                                        " - " +
                                        this.translateService.instant(
                                            "global.import_attendee_error_email_attendee"
                                        )
                                );
                            }
                            this.theError = true;
                            this.theErrorEmailInAttendee = true;
                        }

                        if (status["result"]["failCustomField"].length >= 1) {
                            this.invalidCustomFields =
                                status["result"]["failCustomField"];
                            this.theErrorCustom = true;
                        }
                    } else if (
                        status["result"]["emailDouble"].length > 0 &&
                        status["result"]["idDouble"].length == 0
                    ) {
                        // attendees with double e-mail in event
                        this.theError = false;
                        this.theErrorApiDb = false;
                        this.loaderImport = false;
                        this.successMsg = false;
                        this.theErrorApi = true;
                        this.messageErrors = [];
                        for (let attendee of status["result"]["emailDouble"]) {
                            this.attendeeErrorsApi.push(attendee);
                            if (attendee.type <= 3) {
                                this.messageErrors.push(
                                    attendee["email"] +
                                        " - " +
                                        this.translateService.instant(
                                            "global.email_invalid_attendee"
                                        )
                                );
                            } else {
                                this.messageErrors.push(
                                    attendee["email"] +
                                        " - " +
                                        this.translateService.instant(
                                            "global.email_double"
                                        )
                                );
                            }
                        }
                        for (let attendeeFail of status["result"]["fail"]) {
                            this.attendeeErrorsApi.push(attendeeFail);
                            this.messageErrors.push(
                                attendeeFail["email"] +
                                    " - " +
                                    this.translateService.instant(
                                        "comp.attendees.attendee_failed_msg"
                                    )
                            );
                        }
                        this.loadAttendees();
                    } else if (
                        status["result"]["idDouble"].length > 0 &&
                        status["result"]["emailDouble"].length == 0
                    ) {
                        // attendees with double identifier in event
                        this.theError = false;
                        this.theErrorApiDb = false;
                        this.loaderImport = false;
                        this.successMsg = false;
                        this.theErrorApi = true;

                        this.messageErrors = [];
                        for (let attendee of status["result"]["idDouble"]) {
                            this.attendeeErrorsApi.push(attendee);
                            this.messageErrors.push(
                                attendee["identifier"] +
                                    " - " +
                                    this.translateService.instant(
                                        "global.identifier_double"
                                    )
                            );
                        }
                        for (let attendeeFail of status["result"]["fail"]) {
                            this.attendeeErrorsApi.push(attendeeFail);
                            this.messageErrors.push(
                                attendeeFail["email"] +
                                    " - " +
                                    this.translateService.instant(
                                        "comp.attendees.attendee_failed_msg"
                                    )
                            );
                        }
                        this.loadAttendees();
                    } else if (
                        status["result"]["emailDouble"].length == 0 &&
                        status["result"]["idDouble"].length == 0 &&
                        status["result"]["success"].length == 0
                    ) {
                        // the API did not respond correctly so did not import participants
                        this.theError = false;
                        this.successMsg = false;
                        this.loaderImport = false;
                        this.theErrorApi = false;
                        this.theErrorApiDb = true;
                        this.messageErrors = [];
                        this.loadAttendees();
                    }
                }
            }
        );
    }

    /**
     * transforms the data type of the identifiers column.
     * @param {*} spreadsheet
     * @returns any
     */

    transformIdentifiersIntoString(spreadsheet: any): any {
        for (const row of spreadsheet) {
            if (
                typeof row[this.translatedIdentifier] !== "undefined" &&
                row[this.translatedIdentifier] !== null
            )
                row[this.translatedIdentifier] =
                    row[this.translatedIdentifier].toString();
        }

        return spreadsheet;
    }

    /**
     * fetch the index of the spreadsheet (excel) corresponding to the identifier.
     * @param {any} spreadsheet
     * @param {string} identifier
     * @returns number
     */

    compareIdentifierAttendee(identifier: string, spreadsheet: any): number {
        // try {
        const aux = this.translatedIdentifier;

        if (
            typeof spreadsheet === "undefined" ||
            spreadsheet === null ||
            spreadsheet.length <= 0
        )
            return -1;

        for (let column = 0; column < spreadsheet.length; column++) {
            let row = spreadsheet[column];

            if (
                typeof row[aux] !== "undefined" &&
                row[aux] !== null &&
                row[aux] === identifier
            )
                return column;
        }

        return -1;
    }

    allCustoms: Array<any> = [];
    checkCustomFieldImport(fieldNames, element, language, customFieldPosition) {
        if (this.allCustoms.length == 0) {
            let indexCustomAnswer = 0;
            for (let i = customFieldPosition; i < fieldNames.length; i++) {
                let index = this.verifyCustomField(
                    this.listCustomField,
                    fieldNames[i],
                    language
                );
                let custom;

                if (index !== -1) {
                    custom = this.instantiateCustomField(
                        this.listCustomField[index].uid,
                        this.listCustomField[index]
                    );
                    if (
                        typeof element[
                            customFieldPosition + indexCustomAnswer
                        ] !== "undefined"
                    ) {
                        let answer =
                            element[customFieldPosition + indexCustomAnswer];
                        if (custom.type == "select") {
                            custom.value = answer;
                        } else {
                            custom.textValue[language] = answer;
                        }
                    } else {
                        custom.value = null;
                        custom.textValue[language] = "";
                    }
                } else {
                    custom = null;
                    let msgCustomError =
                        this.translateService.instant(
                            "global.customfield_name_error_import"
                        ) + fieldNames[i];
                    let indexError =
                        this.verifyCustomFieldErrorExist(msgCustomError);
                    if (indexError == -1) {
                        this.messageErrors.push(msgCustomError);
                    }
                }

                if (custom !== null) this.allCustoms.push(custom);
                indexCustomAnswer++;
            }

            // add others custom fields case in the sheet do not have all
            for (let customField of this.listCustomField) {
                let index = this.verifyCustomFieldIndex(
                    this.allCustoms,
                    customField.uid
                );
                if (index == -1) {
                    customField.value = null;
                    customField.textValue[language] = "";
                    this.allCustoms.push(
                        this.instantiateCustomField(
                            customField.uid,
                            customField
                        )
                    );
                }
            }
        } else {
            let indexCustomAnswer = 0;
            for (let i = customFieldPosition; i < fieldNames.length; i++) {
                let index = this.verifyCustomField(
                    this.allCustoms,
                    fieldNames[i],
                    language
                );
                let custom;

                if (index !== -1) {
                    custom = this.instantiateCustomField(
                        this.allCustoms[index].uid,
                        this.allCustoms[index]
                    );
                    if (
                        typeof element[
                            customFieldPosition + indexCustomAnswer
                        ] !== "undefined"
                    ) {
                        let answer =
                            element[customFieldPosition + indexCustomAnswer];
                        if (custom.type == "select") {
                            custom.value = answer;
                        } else {
                            custom.textValue[language] = answer;
                        }
                    } else {
                        custom.value = null;
                        custom.textValue[language] = "";
                    }
                } else {
                    custom = null;
                    let msgCustomError =
                        this.translateService.instant(
                            "global.customfield_name_error_import"
                        ) + fieldNames[i];
                    let indexError =
                        this.verifyCustomFieldErrorExist(msgCustomError);
                    if (indexError == -1) {
                        this.messageErrors.push(msgCustomError);
                    }
                }

                if (custom !== null) {
                    this.allCustoms[index]["textValue"][language] =
                        custom.textValue[language];
                    this.allCustoms[index]["value"] = custom.value;
                }
                indexCustomAnswer++;
            }

            // add others custom fields case in the sheet do not have all
            for (let customField of this.allCustoms) {
                let index = this.verifyCustomFieldIndex(
                    this.allCustoms,
                    customField.uid
                );
                if (index == -1) {
                    customField.value = null;
                    customField.textValue[language] = "";
                    this.allCustoms.push(
                        this.instantiateCustomField(
                            customField.uid,
                            customField
                        )
                    );
                }
            }
        }
        return this.allCustoms;
    }

    verifyCustomField(arrayCustom, nameCustom, language) {
        return arrayCustom
            .map(function (e) {
                return e.name[language];
            })
            .indexOf(nameCustom);
    }

    verifyCustomFieldIndex(arrayCustom, customId) {
        if (arrayCustom !== null) {
            return arrayCustom
                .map(function (e) {
                    return e.uid;
                })
                .indexOf(customId);
        } else {
            return 1;
        }
    }

    verifyCustomFieldErrorExist(newError) {
        return this.messageErrors
            .map(function (e) {
                return e;
            })
            .indexOf(newError);
    }

    /** IGNORE EMPTY ROWS */
    ignoreRowEmpty(row: any) {
        if (row.length < 1 || this.checkRowEmpty(row)) {
            return false;
        }
        return true;
    }

    /**VERIFY IF ROW IS EMPTY */
    checkRowEmpty(row: any) {
        for (const cel of row) {
            if (typeof cel !== "undefined" && cel.length > 0) {
                return false;
            }
        }
        return true;
    }

    /* CHECK IF HAVE DOUBLE IDENTIFIERS */
    checkNumberRowIds(identifier: string, rowId: number) {
        let cont = 2;
        for (const attendee of this.newAttendees) {
            if (attendee.$identifier === identifier) {
                const column = this.checkLetterCols(this.identifier);
                this.messageErrors.push(
                    this.translateService.instant("global.equal_id") +
                        " " +
                        column +
                        cont +
                        " " +
                        this.translateService.instant("global.and") +
                        " " +
                        column +
                        rowId
                );
            }
            cont++;
        }
    }

    /* RETURN EXCEL COLUMN LETTER OF PARAMETER */
    checkLetterCols(column: number) {
        let letter = "";
        switch (column) {
            case this.identifier: {
                letter = "A";
                break;
            }
            case this.name: {
                letter = "B";
                break;
            }
            case this.email: {
                letter = "C";
                break;
            }

            case this.emailRecovery: {
                letter = "D";
                break;
            }

            case this.groups: {
                letter = "M";
                break;
            }
        }
        return letter;
    }

    //VERIFICA SE EXISTE REPETIÇÃO DE E-MAILS NO EXCEL*/
    checkNumberRowEmail(email: string, rowId: number) {
        let cont = 2;
        for (const attendee of this.newAttendees) {
            if (attendee.$email === email) {
                const column = this.checkLetterCols(this.email);
                this.messageErrors.push(
                    this.translateService.instant("global.equal_email") +
                        " " +
                        column +
                        cont +
                        " " +
                        this.translateService.instant("global.and") +
                        " " +
                        column +
                        rowId
                );
            }
            cont++;
        }
    }

    // CLEAR INPUT AFTER IMPORT DATA
    clearInputFile() {
        this.inputFile.nativeElement.value = "";
        this.nameFile = null;
    }

    clearMessages() {
        this.theError = false;
        this.theErrorApi = false;
        this.theErrorApiDb = false;
        this.messageErrors = [];
        this.successMsg = false;
        this.attendeeErrorsApi = [];
        this.theErrorCustom = false;
        this.theErrorGroup = false;
        this.theErrorDocument = false;
        this.theErrorEmailInAttendee = false;
        this.sheetValidFormat = false;
        this.invalidFormatSheetMsg = null;
    }

    downloadAttendeesWithErrors() {
        this.someAttendeesExcelDownload;
    }

    // EXPORTA O EXCEL COM OS ATTENDEES
    async exportAllAttendees() {
        $("#exportLoading").modal("show");
        $("#selectExportOption").modal("toggle");
        this.dataExportAttendees = [];
        this.dataExportAttendees_DeDE = [];
        this.dataExportAttendees_EnUS = [];
        this.dataExportAttendees_EsES = [];
        this.dataExportAttendees_FrFR = [];
        this.dataExportAttendees_PtBR = [];

        this.dataExportAttendees = [
            [
                "Identifier*",
                "Name*",
                "E-mail*",
                "Recovery e-mail",
                "Title",
                "Company",
                "Description",
                "Photo (URL)",
                "Site",
                "Facebook",
                "Twitter",
                "LinkedIn",
                "Instagram",
                "Groups",
                "Documents",
            ],
        ];

        this.dataExportAttendees_DeDE = [
            ["Identifier*", "Title", "Description"],
        ];
        this.dataExportAttendees_EnUS = [
            ["Identifier*", "Title", "Description"],
        ];
        this.dataExportAttendees_EsES = [
            ["Identifier*", "Title", "Description"],
        ];
        this.dataExportAttendees_FrFR = [
            ["Identifier*", "Title", "Description"],
        ];
        this.dataExportAttendees_PtBR = [
            ["Identifier*", "Title", "Description"],
        ];

        // ADD NAME OF CUSTOM FIELD AT TOP
        for (const custom of this.listCustomField) {
            if (custom["name"] !== undefined) {
                this.dataExportAttendees[0].push(
                    custom["name"][this.principalEventLang]
                );
            }
        }

        // GET DATA OF ALL PARTICIPANTS
        await this.dbAttendee.exportAttendees(
            this.moduleId,
            this.typeOrder,
            async (attendeesArray) => {
                // let array = attendeesArray['result'];
                // let decompress = LZString.decompress(array);
                // attendeesArray['result'] = JSON.parse(decompress);
                // attendeesArray['result'] = JSON.parse(attendeesArray['result']);

                if (
                    attendeesArray["message"] == "success" &&
                    attendeesArray["result"].length > 0
                ) {
                    let cont = 0;
                    /* generate workbook and add the worksheet */
                    const wb: XLSX.WorkBook = XLSX.utils.book_new();
                    for (let i = 0; i < attendeesArray["result"].length; i++) {
                        let attendee = attendeesArray["result"][i];
                        let row: any;
                        // Sort groups here
                        row = await this.preparesAttendeesExport(attendee);
                        await this.dataExportAttendees.push(row);

                        let row_PtBR: any;
                        let row_EnUS: any;
                        let row_EsES: any;
                        let row_FrFR: any;
                        let row_DeDE: any;

                        if (this.languages.PtBR) {
                            if (i == 0) {
                                let customFieldArray =
                                    this.getCustomFieldNameSheetTitle(
                                        attendeesArray["result"],
                                        "PtBR"
                                    );
                                if (customFieldArray) {
                                    for (let custom of customFieldArray) {
                                        this.dataExportAttendees_PtBR[0].push(
                                            custom
                                        );
                                    }
                                }
                            }
                            row_PtBR =
                                await this.prepareAttendeesTraslatedExport(
                                    attendee,
                                    "PtBR"
                                );
                            await this.dataExportAttendees_PtBR.push(row_PtBR);
                        }

                        if (this.languages.EnUS) {
                            if (i == 0) {
                                let customFieldArray =
                                    this.getCustomFieldNameSheetTitle(
                                        attendeesArray["result"],
                                        "EnUS"
                                    );
                                if (customFieldArray) {
                                    for (let custom of customFieldArray) {
                                        this.dataExportAttendees_EnUS[0].push(
                                            custom
                                        );
                                    }
                                }
                            }
                            row_EnUS =
                                await this.prepareAttendeesTraslatedExport(
                                    attendee,
                                    "EnUS"
                                );
                            await this.dataExportAttendees_EnUS.push(row_EnUS);
                        }

                        if (this.languages.EsES) {
                            if (i == 0) {
                                let customFieldArray =
                                    this.getCustomFieldNameSheetTitle(
                                        attendeesArray["result"],
                                        "EsES"
                                    );
                                if (customFieldArray) {
                                    for (let custom of customFieldArray) {
                                        this.dataExportAttendees_EsES[0].push(
                                            custom
                                        );
                                    }
                                }
                            }
                            row_EsES =
                                await this.prepareAttendeesTraslatedExport(
                                    attendee,
                                    "EsES"
                                );
                            await this.dataExportAttendees_EsES.push(row_EsES);
                        }

                        if (this.languages.FrFR) {
                            if (i == 0) {
                                let customFieldArray =
                                    this.getCustomFieldNameSheetTitle(
                                        attendeesArray["result"],
                                        "FrFR"
                                    );
                                if (customFieldArray) {
                                    for (let custom of customFieldArray) {
                                        this.dataExportAttendees_FrFR[0].push(
                                            custom
                                        );
                                    }
                                }
                            }
                            row_FrFR =
                                await this.prepareAttendeesTraslatedExport(
                                    attendee,
                                    "FrFR"
                                );
                            await this.dataExportAttendees_FrFR.push(row_FrFR);
                        }

                        if (this.languages.DeDE) {
                            if (i == 0) {
                                let customFieldArray =
                                    this.getCustomFieldNameSheetTitle(
                                        attendeesArray["result"],
                                        "DeDE"
                                    );
                                if (customFieldArray) {
                                    for (let custom of customFieldArray) {
                                        this.dataExportAttendees_DeDE[0].push(
                                            custom
                                        );
                                    }
                                }
                            }
                            row_DeDE =
                                await this.prepareAttendeesTraslatedExport(
                                    attendee,
                                    "DeDE"
                                );
                            await this.dataExportAttendees_DeDE.push(row_DeDE);
                        }

                        if (cont == attendeesArray["result"].length - 1) {
                            const wscols: XLSX.ColInfo[] = this.returnColmns();

                            /* At 96 PPI, 1 pt = 1 px */
                            const wsrows: XLSX.RowInfo[] = [
                                { hpx: 30 }, // "pixels"
                            ];

                            /* generate worksheet */
                            const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(
                                this.dataExportAttendees
                            );

                            /* TEST: column props */
                            ws["!cols"] = wscols;

                            /* TEST: row props */
                            ws["!rows"] = wsrows;

                            /* generate workbook and add the worksheet */
                            const wb: XLSX.WorkBook = XLSX.utils.book_new();
                            XLSX.utils.book_append_sheet(
                                wb,
                                ws,
                                "Principal - " + this.event.language
                            );
                            if (
                                this.languages.PtBR &&
                                this.principalEventLang !== "PtBR"
                            ) {
                                /* generate worksheet */
                                const ws: XLSX.WorkSheet =
                                    XLSX.utils.aoa_to_sheet(
                                        this.dataExportAttendees_PtBR
                                    );
                                /* TEST: column props */
                                ws["!cols"] = wscols;
                                /* TEST: row props */
                                ws["!rows"] = wsrows;
                                XLSX.utils.book_append_sheet(wb, ws, "pt-BR");
                            }

                            if (
                                this.languages.EnUS &&
                                this.principalEventLang !== "EnUS"
                            ) {
                                /* generate worksheet */
                                const ws: XLSX.WorkSheet =
                                    XLSX.utils.aoa_to_sheet(
                                        this.dataExportAttendees_EnUS
                                    );
                                /* TEST: column props */
                                ws["!cols"] = wscols;
                                /* TEST: row props */
                                ws["!rows"] = wsrows;
                                XLSX.utils.book_append_sheet(wb, ws, "en-US");
                            }

                            if (
                                this.languages.EsES &&
                                this.principalEventLang !== "EsES"
                            ) {
                                /* generate worksheet */
                                const ws: XLSX.WorkSheet =
                                    XLSX.utils.aoa_to_sheet(
                                        this.dataExportAttendees_EsES
                                    );
                                /* TEST: column props */
                                ws["!cols"] = wscols;
                                /* TEST: row props */
                                ws["!rows"] = wsrows;
                                XLSX.utils.book_append_sheet(wb, ws, "es-ES");
                            }

                            if (
                                this.languages.FrFR &&
                                this.principalEventLang !== "FrFR"
                            ) {
                                /* generate worksheet */
                                const ws: XLSX.WorkSheet =
                                    XLSX.utils.aoa_to_sheet(
                                        this.dataExportAttendees_FrFR
                                    );
                                /* TEST: column props */
                                ws["!cols"] = wscols;
                                /* TEST: row props */
                                ws["!rows"] = wsrows;
                                XLSX.utils.book_append_sheet(wb, ws, "fr-FR");
                            }

                            if (
                                this.languages.DeDE &&
                                this.principalEventLang !== "DeDE"
                            ) {
                                /* generate worksheet */
                                const ws: XLSX.WorkSheet =
                                    XLSX.utils.aoa_to_sheet(
                                        this.dataExportAttendees_DeDE
                                    );
                                /* TEST: column props */
                                ws["!cols"] = wscols;
                                /* TEST: row props */
                                ws["!rows"] = wsrows;
                                XLSX.utils.book_append_sheet(wb, ws, "de-DE");
                            }

                            /* save to file */
                            const wbout: string = XLSX.write(wb, {
                                bookType: "xlsx",
                                type: "binary",
                            });
                            saveAs(
                                new Blob([this.s2ab(wbout)]),
                                "attendees.xlsx"
                            );
                            this.data = null;
                            $("#exportLoading").modal("toggle");
                        }
                        cont++;
                    }
                } else {
                    // deu erro
                    // $('#exportLoading').modal('toggle')
                    // this.notExport.fire();
                    let cont = 0;
                    for (let custom of this.listCustomField) {
                        this.dataExportAttendees_PtBR[0].push(
                            custom["name"]["PtBR"]
                        );
                        this.dataExportAttendees_EnUS[0].push(
                            custom["name"]["EnUS"]
                        );
                        this.dataExportAttendees_EsES[0].push(
                            custom["name"]["EsES"]
                        );
                        this.dataExportAttendees_FrFR[0].push(
                            custom["name"]["FrFR"]
                        );
                        this.dataExportAttendees_DeDE[0].push(
                            custom["name"]["DeDE"]
                        );
                        cont++;
                    }

                    if (cont == this.listCustomField.length) {
                        const wscols: XLSX.ColInfo[] = this.returnColmns();

                        /* At 96 PPI, 1 pt = 1 px */
                        const wsrows: XLSX.RowInfo[] = [
                            { hpx: 30 }, // "pixels"
                        ];

                        /* generate worksheet */
                        const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(
                            this.dataExportAttendees
                        );

                        /* TEST: column props */
                        ws["!cols"] = wscols;

                        /* TEST: row props */
                        ws["!rows"] = wsrows;

                        /* generate workbook and add the worksheet */
                        const wb: XLSX.WorkBook = XLSX.utils.book_new();
                        XLSX.utils.book_append_sheet(
                            wb,
                            ws,
                            "Principal - " + this.event.language
                        );

                        if (
                            this.languages.PtBR &&
                            this.principalEventLang !== "PtBR"
                        ) {
                            /* generate worksheet */
                            const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(
                                this.dataExportAttendees_PtBR
                            );
                            /* TEST: column props */
                            ws["!cols"] = wscols;
                            /* TEST: row props */
                            ws["!rows"] = wsrows;
                            XLSX.utils.book_append_sheet(wb, ws, "pt-BR");
                        }

                        if (
                            this.languages.EnUS &&
                            this.principalEventLang !== "EnUS"
                        ) {
                            /* generate worksheet */
                            const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(
                                this.dataExportAttendees_EnUS
                            );
                            /* TEST: column props */
                            ws["!cols"] = wscols;
                            /* TEST: row props */
                            ws["!rows"] = wsrows;
                            XLSX.utils.book_append_sheet(wb, ws, "en-US");
                        }

                        if (
                            this.languages.EsES &&
                            this.principalEventLang !== "EsES"
                        ) {
                            /* generate worksheet */
                            const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(
                                this.dataExportAttendees_EsES
                            );
                            /* TEST: column props */
                            ws["!cols"] = wscols;
                            /* TEST: row props */
                            ws["!rows"] = wsrows;
                            XLSX.utils.book_append_sheet(wb, ws, "es-ES");
                        }

                        if (
                            this.languages.FrFR &&
                            this.principalEventLang !== "FrFR"
                        ) {
                            /* generate worksheet */
                            const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(
                                this.dataExportAttendees_FrFR
                            );
                            /* TEST: column props */
                            ws["!cols"] = wscols;
                            /* TEST: row props */
                            ws["!rows"] = wsrows;
                            XLSX.utils.book_append_sheet(wb, ws, "fr-FR");
                        }

                        if (
                            this.languages.DeDE &&
                            this.principalEventLang !== "DeDE"
                        ) {
                            /* generate worksheet */
                            const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(
                                this.dataExportAttendees_DeDE
                            );
                            /* TEST: column props */
                            ws["!cols"] = wscols;
                            /* TEST: row props */
                            ws["!rows"] = wsrows;
                            XLSX.utils.book_append_sheet(wb, ws, "de-DE");
                        }

                        /* save to file */
                        const wbout: string = XLSX.write(wb, {
                            bookType: "xlsx",
                            type: "binary",
                        });
                        saveAs(new Blob([this.s2ab(wbout)]), "attendees.xlsx");
                        this.data = null;
                        $("#exportLoading").modal("toggle");
                    }
                }
            }
        );
        $("#selectExportOption").modal("hide");
        $("#exportLoading").modal("hide");
    }

    // PREPARA UM REGISTRO DE ATTENDEE PARA EXPORTAÇÃO
    preparesAttendeesExport(attendee) {
        const row = [];

        row.push(attendee.identifier);
        row.push(attendee.name);
        row.push(attendee.email);
        row.push(attendee.emailRecovery);
        row.push(attendee.title[this.principalEventLang]);
        row.push(attendee.company);
        if (
            attendee.description[this.principalEventLang] !== null &&
            attendee.description[this.principalEventLang] !== undefined &&
            attendee.description[this.principalEventLang] !== ""
        ) {
            row.push(attendee.description[this.principalEventLang]);
        } else {
            row.push("");
        }
        row.push(attendee.photoUrl);
        row.push(attendee.website);
        row.push(attendee.facebook);
        row.push(attendee.twitter);
        row.push(attendee.linkedin);
        row.push(attendee.instagram);
        row.push(this.preparedGroupExport(attendee.groups));
        row.push(this.preparedDocumentExport(attendee.documents));

        this.listCustomField = [];

        // Reordonnation des custom fields
        const arrayCustoms = attendee.customField;
        if (arrayCustoms) {
            for (let i = 0; i < arrayCustoms.length; i++) {
                let id = arrayCustoms[i].uid;
                let position = this.fieldOptionsCustom[id].order;
                this.listCustomField[position] = arrayCustoms[i];
            }
        }

        if (this.listCustomField.length > 0) {
            for (const custom of this.listCustomField) {
                if (custom.type === "text") {
                    row.push(custom.textValue[this.principalEventLang]);
                } else {
                    row.push(custom.value[this.principalEventLang]);
                }
            }
        }

        return row;
    }

    // AJUDA A GERAR O ARQUIVO EXECL
    private s2ab(s: string): ArrayBuffer {
        const buf: ArrayBuffer = new ArrayBuffer(s.length);
        const view: Uint8Array = new Uint8Array(buf);
        for (let i = 0; i !== s.length; ++i) {
            view[i] = s.charCodeAt(i) & 0xff;
        }
        return buf;
    }

    getCustomFieldNameSheetTitle(attendeesArray, language) {
        let customArray = [];
        if (attendeesArray[0] && attendeesArray[0]["customField"]) {
            for (let custom of attendeesArray[0]["customField"]) {
                customArray.push(custom["name"][language]);
            }

            if (customArray) {
                if (
                    customArray.length ===
                    attendeesArray[0]["customField"].length
                ) {
                    return customArray;
                }
            }
        }
    }

    returnColmns() {
        let array = [
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            { wpx: 100 }, // "pixels"
            // { hidden: false } // hide column
        ];
        return array;
    }

    // PREPARA UM REGISTRO DE DADOS SECUNDÁRIOS TRADUZIDOS PARA EXPORTAÇÃO
    prepareAttendeesTraslatedExport(attendee, lang) {
        const row = [];
        if (attendee) {
            row.push(attendee.identifier);
            row.push(attendee.title[lang]);
            if (
                attendee.description[lang] !== null &&
                attendee.description[lang] !== undefined
            ) {
                row.push(attendee.description[lang]);
            } else {
                row.push("");
            }
            if (attendee.customField) {
                for (const custom of attendee.customField) {
                    if (custom.type == "text") {
                        row.push(custom.textValue[lang]);
                    } else {
                        row.push(custom.value[this.userLanguageFormat]);
                    }
                }
            }
        }
        return row;
    }

    preparedGroupExport(list: any) {
        let myDatas = "";
        if (list !== null && list !== undefined) {
            let groups = [];

            for (const uid in list) {
                groups.push(list[uid]);
            }

            for (
                let i = 0;
                i <
                groups.sort((a, b) =>
                    a.name < b.name ? -1 : a.name > b.name ? 1 : 0
                ).length;
                i++
            ) {
                i < groups.length - 1
                    ? (myDatas += groups[i].name + ";")
                    : (myDatas += groups[i].name);
            }
        }
        return myDatas;
    }

    preparedDocumentExport(list: any) {
        let myDatas = "";
        let i = 1;
        if (list !== null && list !== undefined) {
            let array = Object.values(list)
            for (const data of array) {
                if (i < array.length) {
                    myDatas += data['identifier'] + ";";
                } else {
                    myDatas += data['identifier'];
                }
                i++;
            }

            if (list.length <= 0) {
                myDatas = null;
            }
        }
        return myDatas;
    }

    async downloadAttendeesImportError() {
        this.dataExportAttendees = [];
        this.dataExportAttendees_DeDE = [];
        this.dataExportAttendees_EnUS = [];
        this.dataExportAttendees_EsES = [];
        this.dataExportAttendees_FrFR = [];
        this.dataExportAttendees_PtBR = [];
        this.dataExportAttendees = [
            [
                "Identifier*",
                "Name*",
                "E-mail*",
                "Recovery e-mail",
                "Title",
                "Company",
                "Description",
                "Photo (URL)",
                "Site",
                "Facebook",
                "Twitter",
                "LinkedIn",
                "Instagram",
                "Groups",
                "Documents",
            ],
        ];

        this.dataExportAttendees_DeDE = [
            ["Identifier*", "Title", "Description"],
        ];
        this.dataExportAttendees_EnUS = [
            ["Identifier*", "Title", "Description"],
        ];
        this.dataExportAttendees_EsES = [
            ["Identifier*", "Title", "Description"],
        ];
        this.dataExportAttendees_FrFR = [
            ["Identifier*", "Title", "Description"],
        ];
        this.dataExportAttendees_PtBR = [
            ["Identifier*", "Title", "Description"],
        ];

        // PEGA OS DADOS DE CADA PARTICIPANTE
        let cont = 0;

        /* generate workbook and add the worksheet */
        const wb: XLSX.WorkBook = XLSX.utils.book_new();

        for (let i = 0; i < this.attendeeErrorsApi.length; i++) {
            let attendee = this.attendeeErrorsApi[i];
            let row: any;
            row = await this.preparesAttendeesExport(attendee);
            await this.dataExportAttendees.push(row);
            let row_PtBR: any;
            let row_EnUS: any;
            let row_EsES: any;
            let row_FrFR: any;
            let row_DeDE: any;
            if (this.languages.PtBR) {
                if (i == 0) {
                    let customFieldArray = this.getCustomFieldNameSheetTitle(
                        this.attendeeErrorsApi,
                        "PtBR"
                    );
                    for (let custom of customFieldArray) {
                        this.dataExportAttendees_PtBR[0].push(custom);
                    }
                }
                row_PtBR = await this.prepareAttendeesTraslatedExport(
                    attendee,
                    "PtBR"
                );
                await this.dataExportAttendees_PtBR.push(row_PtBR);
            }

            if (this.languages.EnUS) {
                if (i == 0) {
                    let customFieldArray = this.getCustomFieldNameSheetTitle(
                        this.attendeeErrorsApi,
                        "EnUS"
                    );
                    for (let custom of customFieldArray) {
                        this.dataExportAttendees_EnUS[0].push(custom);
                    }
                }
                row_EnUS = await this.prepareAttendeesTraslatedExport(
                    attendee,
                    "EnUS"
                );
                await this.dataExportAttendees_EnUS.push(row_EnUS);
            }

            if (this.languages.EsES) {
                if (i == 0) {
                    let customFieldArray = this.getCustomFieldNameSheetTitle(
                        this.attendeeErrorsApi,
                        "EsES"
                    );
                    for (let custom of customFieldArray) {
                        this.dataExportAttendees_EsES[0].push(custom);
                    }
                }
                row_EsES = await this.prepareAttendeesTraslatedExport(
                    attendee,
                    "EsES"
                );
                await this.dataExportAttendees_EsES.push(row_EsES);
            }

            if (this.languages.FrFR) {
                if (i == 0) {
                    let customFieldArray = this.getCustomFieldNameSheetTitle(
                        this.attendeeErrorsApi,
                        "FrFR"
                    );
                    if (customFieldArray) {
                        for (let custom of customFieldArray) {
                            this.dataExportAttendees_FrFR[0].push(custom);
                        }
                    }
                }
                row_FrFR = await this.prepareAttendeesTraslatedExport(
                    attendee,
                    "FrFR"
                );
                await this.dataExportAttendees_FrFR.push(row_FrFR);
            }

            if (this.languages.DeDE) {
                if (i == 0) {
                    let customFieldArray = this.getCustomFieldNameSheetTitle(
                        this.attendeeErrorsApi,
                        "DeDE"
                    );
                    for (let custom of customFieldArray) {
                        this.dataExportAttendees_DeDE[0].push(custom);
                    }
                }
                row_DeDE = await this.prepareAttendeesTraslatedExport(
                    attendee,
                    "DeDE"
                );
                await this.dataExportAttendees_DeDE.push(row_DeDE);
            }
            if (cont == this.attendeeErrorsApi.length - 1) {
                const wscols: XLSX.ColInfo[] = this.returnColmns();

                /* At 96 PPI, 1 pt = 1 px */
                const wsrows: XLSX.RowInfo[] = [
                    { hpx: 30 }, // "pixels"
                ];

                /* generate worksheet */
                const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(
                    this.dataExportAttendees
                );
                /* TEST: column props */
                ws["!cols"] = wscols;
                /* TEST: row props */
                ws["!rows"] = wsrows;
                XLSX.utils.book_append_sheet(
                    wb,
                    ws,
                    "Principal - " + this.event.language
                );

                // ADDITIONAL DATA TRANSLATED
                if (this.languages.PtBR && this.principalEventLang !== "PtBR") {
                    /* generate worksheet */
                    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(
                        this.dataExportAttendees_PtBR
                    );
                    /* TEST: column props */
                    ws["!cols"] = wscols;
                    /* TEST: row props */
                    ws["!rows"] = wsrows;
                    XLSX.utils.book_append_sheet(wb, ws, "pt-BR");
                }

                if (this.languages.EnUS && this.principalEventLang !== "EnUS") {
                    /* generate worksheet */
                    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(
                        this.dataExportAttendees_EnUS
                    );
                    /* TEST: column props */
                    ws["!cols"] = wscols;
                    /* TEST: row props */
                    ws["!rows"] = wsrows;
                    XLSX.utils.book_append_sheet(wb, ws, "en-US");
                }

                if (this.languages.EsES && this.principalEventLang !== "EsES") {
                    /* generate worksheet */
                    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(
                        this.dataExportAttendees_EsES
                    );
                    /* TEST: column props */
                    ws["!cols"] = wscols;
                    /* TEST: row props */
                    ws["!rows"] = wsrows;
                    XLSX.utils.book_append_sheet(wb, ws, "es-ES");
                }

                if (this.languages.FrFR && this.principalEventLang !== "FrFR") {
                    /* generate worksheet */
                    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(
                        this.dataExportAttendees_FrFR
                    );
                    /* TEST: column props */
                    ws["!cols"] = wscols;
                    /* TEST: row props */
                    ws["!rows"] = wsrows;
                    XLSX.utils.book_append_sheet(wb, ws, "fr-FR");
                }

                if (this.languages.DeDE && this.principalEventLang !== "DeDE") {
                    /* generate worksheet */
                    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(
                        this.dataExportAttendees_DeDE
                    );
                    /* TEST: column props */
                    ws["!cols"] = wscols;
                    /* TEST: row props */
                    ws["!rows"] = wsrows;
                    XLSX.utils.book_append_sheet(wb, ws, "de-DE");
                }

                /* save to file */
                const wbout: string = XLSX.write(wb, {
                    bookType: "xlsx",
                    type: "binary",
                });
                saveAs(new Blob([this.s2ab(wbout)]), "import_error.xlsx");
                this.data = null;
            }
            cont++;
        }
    }

    exportTemplate() {
        this.dataExportAttendees = [];
        this.dataExportAttendees_DeDE = [];
        this.dataExportAttendees_EnUS = [];
        this.dataExportAttendees_EsES = [];
        this.dataExportAttendees_FrFR = [];
        this.dataExportAttendees_PtBR = [];
        this.dataExportAttendees = [
            [
                "Identifier*",
                "Name*",
                "E-mail*",
                "Recovery e-mail",
                "Title",
                "Company",
                "Description",
                "Photo (URL)",
                "Site",
                "Facebook",
                "Twitter",
                "LinkedIn",
                "Instagram",
                "Groups",
            ],
        ];

        this.dataExportAttendees_DeDE = [
            ["Identifier*", "Title", "Description"],
        ];
        this.dataExportAttendees_EnUS = [
            ["Identifier*", "Title", "Description"],
        ];
        this.dataExportAttendees_EsES = [
            ["Identifier*", "Title", "Description"],
        ];
        this.dataExportAttendees_FrFR = [
            ["Identifier*", "Title", "Description"],
        ];
        this.dataExportAttendees_PtBR = [
            ["Identifier*", "Title", "Description"],
        ];

        for (let custom of this.listCustomField) {
            this.dataExportAttendees[0].push(
                custom.name[this.principalEventLang]
            );
            this.dataExportAttendees_PtBR[0].push(custom.name.PtBR);
            this.dataExportAttendees_EnUS[0].push(custom.name.EnUS);
            this.dataExportAttendees_EsES[0].push(custom.name.EsES);
            this.dataExportAttendees_FrFR[0].push(custom.name.FrFR);
            this.dataExportAttendees_DeDE[0].push(custom.name.DeDE);
        }

        /* generate workbook and add the worksheet */
        const wb: XLSX.WorkBook = XLSX.utils.book_new();

        const wscols: XLSX.ColInfo[] = this.returnColmns();

        /* At 96 PPI, 1 pt = 1 px */
        const wsrows: XLSX.RowInfo[] = [
            { hpx: 30 }, // "pixels"
        ];

        /* generate worksheet */
        const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(
            this.dataExportAttendees
        );
        /* TEST: column props */
        ws["!cols"] = wscols;
        /* TEST: row props */
        ws["!rows"] = wsrows;
        XLSX.utils.book_append_sheet(
            wb,
            ws,
            "Principal - " + this.event.language
        );

        // ADDITIONAL DATA TRANSLATED
        if (this.languages.PtBR && this.principalEventLang !== "PtBR") {
            /* generate worksheet */
            const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(
                this.dataExportAttendees_PtBR
            );
            /* TEST: column props */
            ws["!cols"] = wscols;
            /* TEST: row props */
            ws["!rows"] = wsrows;
            XLSX.utils.book_append_sheet(wb, ws, "pt-BR");
        }

        if (this.languages.EnUS && this.principalEventLang !== "EnUS") {
            /* generate worksheet */
            const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(
                this.dataExportAttendees_EnUS
            );
            /* TEST: column props */
            ws["!cols"] = wscols;
            /* TEST: row props */
            ws["!rows"] = wsrows;
            XLSX.utils.book_append_sheet(wb, ws, "en-US");
        }

        if (this.languages.EsES && this.principalEventLang !== "EsES") {
            /* generate worksheet */
            const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(
                this.dataExportAttendees_EsES
            );
            /* TEST: column props */
            ws["!cols"] = wscols;
            /* TEST: row props */
            ws["!rows"] = wsrows;
            XLSX.utils.book_append_sheet(wb, ws, "es-ES");
        }

        if (this.languages.FrFR && this.principalEventLang !== "FrFR") {
            /* generate worksheet */
            const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(
                this.dataExportAttendees_FrFR
            );
            /* TEST: column props */
            ws["!cols"] = wscols;
            /* TEST: row props */
            ws["!rows"] = wsrows;
            XLSX.utils.book_append_sheet(wb, ws, "fr-FR");
        }

        if (this.languages.DeDE && this.principalEventLang !== "DeDE") {
            /* generate worksheet */
            const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(
                this.dataExportAttendees_DeDE
            );
            /* TEST: column props */
            ws["!cols"] = wscols;
            /* TEST: row props */
            ws["!rows"] = wsrows;
            XLSX.utils.book_append_sheet(wb, ws, "de-DE");
        }
        /* save to file */
        const wbout: string = XLSX.write(wb, {
            bookType: "xlsx",
            type: "binary",
        });
        saveAs(new Blob([this.s2ab(wbout)]), "template_attendees.xlsx");
        this.data = null;
    }

    instantiateCustomField(uid: string, data: any) {
        const custom = new eventCustomField(
            data.name,
            data.type,
            data.exibicao,
            data.edicao
        );
        if (typeof uid !== "undefined") {
            custom.uid = uid;
        }
        return custom;
    }

    requiredEditProfile($ev) {
        this.dbEvent.changeRequiredProfileEdit(this.eventId, $ev);
    }

    /**
     * Allow or block chat use between participants
     * @param $ev
     */
    allowChatUseChange($ev) {
        this.allowChatUse = $ev;
        this.dbAttendee.changeChatUse(
            this.eventId,
            this.moduleId,
            this.allowChatUse
        );
    }

    /**
     * Allow or block filter on list attendees
     * @param $ev
     */
    allowFilterChanged($ev, type: string) {
        /* ----litle code to format name of custom fields 
         to be set to firestore database ---- */
        let typeArray = type.split(" ");
        let formatType = "";
        let i = 0;
        for (let ch of typeArray) {
            i++;
            formatType = formatType.concat(ch);
            if (i !== typeArray.length) {
                formatType = formatType.concat("_");
            }
        }
        /* ------------------------------------- */
        let value = null;
        if (type === "company") {
            value = this.allowFilterCompany = $ev;
            this.disableFilterComVisibility = true;
        } else if (type === "post") {
            value = this.allowFilterPost = $ev;
            this.disableFilterPostVisibility = true;
        } else if (type === "customs") {
            value = this.allowFilterCustomFields = $ev;
            this.disableCustomFieldsFilterVisibility = true;
        } else {
            for (let i = 0; i < this.customNameArray.length; i++) {
                if (
                    type ===
                    "allowFilterCustomField_" + this.customNameArray[i]
                ) {
                    value = this.allowFilterCustomsFieldsArray[i] = $ev;
                    this.disableCustomFieldsFilterArrayVisibility[i] = true;
                }
            }
        }
        this.dbAttendee.changeFilterVisibility(
            this.moduleId,
            value,
            formatType,
            (result) => {
                if (!result.value) {
                    this.allowFilterCompany =
                        result.type === "company" && !this.allowFilterCompany;
                    this.allowFilterPost =
                        result.type === "post" && !this.allowFilterPost;
                    this.allowFilterCustomFields =
                        result.type === "customs" &&
                        !this.allowFilterCustomFields;
                    for (let i = 0; i < this.customNameArray.lentgth; i++) {
                        this.allowFilterCustomsFieldsArray[i] =
                            result.type === this.customNameArray[i] &&
                            !this.allowFilterCustomsFieldsArray[i];
                    }
                }
                this.disableFilterComVisibility = false;
                this.disableFilterPostVisibility = false;
                this.disableCustomFieldsFilterVisibility = false;

                // disable generic custom fields
                for (let i = 0; i < this.customNameArray.length; i++) {
                    this.disableCustomFieldsFilterArrayVisibility[i] = false;
                }
            }
        );
    }

    /**
     * Allow or block visio use between participants
     * @param $ev
     */
    // allowVisioUseChange($ev) {
    //     this.allowVisioUse = $ev;
    //     this.dbAttendee.changeVisioUse(this.eventId, this.moduleId, this.allowVisioUse);
    // }

    setDefaultAttendeeModule($ev) {
        this.defaultModulePublicAttendees = $ev;
        if ($ev == true)
            this.dbEvent.updateEventItems(
                this.eventId,
                { default_attendee_module: this.moduleId },
                (result) => {}
            );
        if ($ev == false)
            this.dbEvent.updateEventItems(
                this.eventId,
                { default_attendee_module: "" },
                (result) => {}
            );
    }

    changeFirstAccessLanguage() {
        this.dbAttendee.takeChangesInModule(this.moduleId, this.eventId, {
            firstAccessCodeLanguage: this.first_access_language,
        });
    }

    userResetReference: Attendee = null;
    resetUserAccountProblem() {
        $("#exportLoading").modal("show");
        this.dbAttendee
            .resetAccount(this.userResetReference)
            .then((response) => {
                if (response == 0) {
                    this.userResetReference = null;
                    setTimeout(() => {
                        $("#exportLoading").modal("toggle");
                        this.successSwal.fire();
                    }, 200);
                } else if (response == 1) {
                    this.userResetReference = null;
                    this.notReconfiguredSwal.fire();
                    setTimeout(() => {
                        $("#exportLoading").modal("toggle");
                    }, 150);
                }
            })
            .catch((error) => {
                this.userResetReference = null;
                this.errorSwal.fire();
                setTimeout(() => {
                    $("#exportLoading").modal("toggle");
                }, 150);
            });
    }

    printQrCodes() {
        $("#exportLoading").modal("show");
        $("#selectExportOption").modal("toggle");
        let size = this.listAttendees.length;
        let array = [];
        for (let i = 0; i < size; i++) {
            // get element canvas and make data url string
            let elem = document.getElementById(`qrcodesAll${i}`);
            const canvas = elem.childNodes[0]
                .childNodes[0] as HTMLCanvasElement;
            const imageData = canvas.toDataURL("image/png").toString();
            // size and position of qrcodes
            let imgWidth = 150;
            let imgHeight = (canvas.height * imgWidth) / canvas.width;
            array.push({
                name: this.listAttendees[i].$name,
                image: imageData,
                width: imgWidth,
                height: imgHeight,
                position: 30,
            });

            if (array.length == size) {
                this.saveAllQrCodes(array);
            }
        }
    }

    saveAllQrCodes(qrcodes) {
        let size = qrcodes.length;
        // start jspdf a4 format in landscape mode using milimiters
        let pdf = new jspdf("l", "mm", "a4"); // A4 size page of PDF
        let pageHeight =
            pdf.internal.pageSize.height || pdf.internal.pageSize.getHeight();
        let pageWidth =
            pdf.internal.pageSize.width || pdf.internal.pageSize.getWidth();
        for (let i = 0; i < size; i++) {
            // add title to top
            pdf.setFontType("bold");
            pdf.text(
                qrcodes[i].name,
                pageWidth / 2,
                pageHeight - 190,
                "center"
            );
            // add qrcode image after title
            pdf.addImage(
                qrcodes[i].image,
                "PNG",
                70,
                qrcodes[i].position,
                qrcodes[i].width,
                qrcodes[i].height
            );
            // generate new page
            if (i < size - 1) {
                pdf.addPage();
            }
            if (i == size - 1) {
                pdf.save("attendees-qrcodes.pdf"); // Generated PD
                setTimeout(() => {
                    $("#exportLoading").modal("toggle");
                }, 1000);
            }
        }
    }

    convertLangFormat(lang) {
        let formatedLang;
        switch (lang) {
            case "pt_BR": {
                formatedLang = "PtBR";
                break;
            }
            case "en_US": {
                formatedLang = "EnUS";
                break;
            }
            case "es_ES": {
                formatedLang = "EsES";
                break;
            }
            case "fr_FR": {
                formatedLang = "FrFR";
                break;
            }
            case "de_DE": {
                formatedLang = "DeDE";
                break;
            }
        }
        return formatedLang;
    }

    propertyChanged($ev, property: string) {
        let value = null;
        if (property === "showLetters") {
            value = this.showLetters = $ev;
            this.disableLettreVisibility = true;
        } else if (property === "emailVisibilty") {
            value = this.emailVisibilty = $ev;
        } else if (property === "showBtnVisio") {
            value = this.btnVisioVisibilty = $ev;
        } else if (property === "showOnlyLoggedUsers") {
            value = this.showOnlyLoggedUsersVisibilty = $ev;
        }
        this.disableLettreVisibility = true;

        this.dbAttendee.editPropVisibility(
            this.moduleId,
            property,
            value,
            (result) => {
                if (!result && property == "showLetters") {
                    this.showLetters = !this.showLetters;
                } else if (!result && property == "emailVisibilty") {
                    this.emailVisibilty = !this.emailVisibilty;
                } else if (!result && property == "showBtnVisio") {
                    this.btnVisioVisibilty = !this.btnVisioVisibilty;
                } else if (!result && property === "showOnlyLoggedUsers") {
                    this.showOnlyLoggedUsersVisibilty =
                        !this.showOnlyLoggedUsersVisibilty;
                }
                this.disableLettreVisibility = false;
            }
        );
    }

    setTexts(type: string){
        if(type === 'company'){
            this.companyFilterText = this.companyFilterText.trim()
            this.disabledCompanyFilterText = true
            this.dbAttendee.editPropVisibility(
                this.moduleId,
                'companyFilterText',
                this.companyFilterText,
                (result) => {
                    this.disabledCompanyFilterText = false
                })
        } else if(type === 'post'){
            this.postFilterText = this.postFilterText.trim()
            this.disabledPostFilterText = true
            this.dbAttendee.editPropVisibility(
                this.moduleId,
                'postFilterText',
                this.postFilterText,
                (result) => {
                    this.disabledPostFilterText = false
                })
        }
    }
}
