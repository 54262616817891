import { Component, NgModule, OnInit,ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { DestinationType } from 'src/app/enums/type-destination-email';
import { Email } from 'src/app/models/email';
import { FilterPipe } from 'src/app/pipes/filter.pipe';
import { DbGroupsProvider } from 'src/app/providers/database/db-groups';
import { DbMailingProvider } from 'src/app/providers/database/db-mailing';
import Swal from 'sweetalert2';

declare let $: any;


@Component({
    selector: 'app-mailing',
    templateUrl: './mailing.component.html',
    styleUrls: ['./mailing.component.scss']
})

@NgModule({
    declarations: [FilterPipe],
})

export class MailingComponent implements OnInit {

    @ViewChild('successEmailSwal') public successEmailSwal: SwalComponent;
    @ViewChild('errorEmailSwal') public errorEmailSwal: SwalComponent;
    @ViewChild('warningSwal') public warningSwal: SwalComponent;
    @ViewChild('multiDeleteSwal') public multiDeleteSwal: SwalComponent;
    @ViewChild('successSwal') public successSwal: SwalComponent;
    @ViewChild('errorSwal') public errorSwal: SwalComponent;

    public page: number = 1;
    numberPerPage = 15;
    selectedAllInput: boolean = false;
    oneItemAtLeastIsSelected = false;

    eventId: string;
    emails = [];
    loader: boolean = true;
    selectedEmails: Array<boolean> = [];
    typeOrder= 'desc';

    emailIdToDuplicate: string;
    duplicatedEmailTitle: string;
    errorduplicatedEmailTitle: boolean;

    groups = [];

    constructor(
        private route: ActivatedRoute,
        private dbMailing: DbMailingProvider,
        private router: Router,
        private translateService: TranslateService,
        private dbGroups: DbGroupsProvider,
    ) {
        this.eventId = this.route.parent.params['_value']['uid'];
        this.dbGroups.getGroupsOfEvent(this.eventId, (result) => {
            this.groups = result.sort((a,b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 0) ;
        })
    }

    ngOnInit(){
        this.getEmails()
        $('#sentTest').modal('hide');
    }

    async deleteEmail(emailId: string){
        const res = await this.warningSwal.fire()
        if(res.isDismissed){
            console.log("bye")
            return
        }
        this.loader = true;
        const value = await this.dbMailing.deleteEmail(this.eventId,emailId);
        if(value){
            this.getEmails()
        }
    }


    prepareDuplication(emailId: string){
        this.emailIdToDuplicate = emailId
        $('#duplicate').modal('toggle');
        $('#duplicate').modal('show');
    }

    async duplicateEmail(){
        this.errorduplicatedEmailTitle = false;
        if(!this.duplicatedEmailTitle.trim()){
            this.errorduplicatedEmailTitle = true;
            return;
        }
        Swal.fire(this.translateService.instant('comp.mailing.swal_waiting'))
        Swal.showLoading()
        const res = await this.dbMailing.duplicateEmail(this.eventId, this.emailIdToDuplicate,this.duplicatedEmailTitle)
        Swal.hideLoading()
        $('#duplicate').modal('hide');
        if(res.value){
            const id = res.id
            this.router.navigate([`/event/${this.eventId}/mailing/${id}/edit`])
        } else {
            this.errorSwal.fire()
        }
    }

    async getEmails(){
        const tab = await this.dbMailing.getEmails(this.eventId);
        this.emails = [...tab].map(e => {
            e.total = e.deliveredAt > 0 ? e.receivers.split(',').length : 0
            this.selectedEmails[e.uid] = false;
            e.openCounter = e.total > 0 ? Math.round((e.openCounter * 100) / e.total) + ' %' : '--'
            return e
        });

        this.loader = this.emails.length > 0 && false;
    }

    getDestination(des): string{
        switch (des as DestinationType) {
            case DestinationType.ALL:
                return this.translateService.instant('comp.mailing.destination_all');
            case DestinationType.GROUPS:
                return this.translateService.instant('comp.mailing.destination_groups');
            case DestinationType.CONNECTED_USERS:
                return this.translateService.instant('comp.mailing.destination_connected');
            case DestinationType.NOT_CONNECTED_USERS:
                return this.translateService.instant('comp.mailing.destination_not_connected');
            case DestinationType.SPECIFIC:
                return this.translateService.instant('comp.mailing.destination_specific');
        }
    }

    getGroupsTitle(groups: string[]){
        return groups.length > 0 ? [...this.groups].filter(gr => groups.some(g => g == gr.uid)).map(x => x.name).join('; ') : ''
    }

      // select all notifications
    selectedAll() {
        this.emails.forEach(e => {
            this.selectedEmails[e['uid']] = this.selectedAllInput;
        })
        this.oneItemAtLeastIsSelected = this.selectedAllInput
    }

    async removeSelected() {
        const res = await this.multiDeleteSwal.fire()
        if(res.isDismissed){
            return
        }
        this.loader = true;
        let listRemove = [...this.emails].filter(e => this.selectedEmails[e.uid] === true).map(x => x.uid)

        const value = await this.dbMailing.deleteMultipleEmails(this.eventId, listRemove);
        this.loader = false;
        if(value){
            this.getEmails()
            this.successSwal.fire();
        } else {
            this.errorSwal.fire();
        }
    }

    changeOrder(){
        switch (this.typeOrder) {
            case 'desc':
                this.emails.sort((a,b) => a.createdAt > b.createdAt? -1 : a.createdAt < b.createdAt ? 1 : 0 );
                break;
            case 'asc':
                this.emails.sort((a,b) => a.createdAt > b.createdAt ? 1 : a.createdAt < b.createdAt ? -1 : 0 );
                break;
            case 'a_z':
                this.emails.sort((a,b) => a.title.toLowerCase() > b.title.toLowerCase() ? 1 : a.title.toLowerCase() < b.title.toLowerCase() ? -1 : 0 );
                break;
            case 'z_a':
                this.emails.sort((a,b) => a.title.toLowerCase() > b.title.toLowerCase() ? -1 : a.title.toLowerCase() < b.title.toLowerCase() ? 1 : 0 );
                break;
        
            default:
                break;
        }
    }

    selectEmailChange(uid){
        console.log(this.selectedEmails[uid])
        this.oneItemAtLeastIsSelected = Object.values(Object.assign({}, this.selectedEmails)).some(x => x)
        this.selectedAllInput = Object.values(Object.assign({}, this.selectedEmails)).every(x => x)
    }

    navigateToNewEmail(){
        this.router.navigate([`/event/${this.eventId}/mailing/create`])
    }

    navigateToEmailDetail(id){
        this.router.navigate([`/event/${this.eventId}/mailing/${id}/edit`])
    }
}
