import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DbEventsProvider } from 'src/app/providers/database/db.events';
import { Event } from '../../../../../models/event';
import { DbGroupsProvider } from 'src/app/providers/database/db-groups';
import { Group } from 'src/app/models/group';
import { Notification } from '../../../../../models/notifications';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { NotifiicationDateService } from 'src/app/providers/luxon/notification-date.service';
import { DbNotificationsProvider } from 'src/app/providers/database/db-notifications';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { StorageService } from 'src/app/providers/storage/storage.service';
import { UUID } from 'angular2-uuid';
import { Attendee } from 'src/app/models/attendees';
import { DbAttendeesProvider } from 'src/app/providers/database/db-attendees';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { DbScheduleProvider } from 'src/app/providers/database/db-schedule';
import { DbAppointments } from 'src/app/providers/database/db-appointments';
import { DbPersonalScheduleProvider } from "src/app/providers/database/db-personal-schedule";
import { TypeModule } from 'src/app/enums/type-module';
import { DateTime } from 'luxon';

declare let $: any;

@Component({
    selector: 'app-create-notification',
    templateUrl: './create-notification.component.html',
    styleUrls: ['./create-notification.component.scss']
})
export class CreateNotificationComponent implements OnInit {
    formValidation: FormGroup;
    eventId: string = null;
    moduleId: string = null;
    event: Event = null;
    requiredTitle: boolean = false;
    requiredTitleLanguage: boolean = false;
    requiredMessage: boolean = false;
    requiredMessageLanguage: boolean = false;
    requiredSendTo: boolean = false;
    requiredDate: boolean = false;
    requiredTime: boolean = false;
    requiredWhenSend: boolean = false;
    requiredGroups: boolean = false;
    notificationCheckError: boolean = false;
    errorMinutesReminder = false;

    // GROUPS FILTER
    listGroup: Array<Group> = [];
    selectedGroup: Array<Group> = [];
    filteredListGroups = [];
    queryGroup = '';

    whenSend = null;
    scheduledInputs: boolean = false;
    groupsInput: boolean = false;
    sendTo = null;
    fileUrl: string = null;
    file: any = null;
    customErrorModal: string = null;
    loader: boolean = false;
    showTotalUserReceive: boolean = false;
    numberAttendeesReceive: number = 0;
    allAttendees: Array<Attendee> = [];
    @ViewChild('successSwal') public successSwal: SwalComponent;
    @ViewChild('errorSwal') public errorSwal: SwalComponent;
    @ViewChild('errorTitleSwal') public errorTitleSwal: SwalComponent;
    @ViewChild('errorMsgSwal') public errorMsgSwal: SwalComponent;
    @ViewChild('errordateSwal') public errordateSwal: SwalComponent;
    @ViewChild('errorEmptySelectionSwal') public errorEmptySelectionSwal: SwalComponent;
    @ViewChild('warningSwal') public warningSwal: SwalComponent;
    beforeModulesList = []
    showBeforeModulesList = false;
    selectedModule;
    showBeforeXXMinutes = false;
    minutesReminder = 5;
    notifTypes = []

    constructor(
        private fb: FormBuilder,
        private route: ActivatedRoute,
        private dbEvent: DbEventsProvider,
        private dbGroups: DbGroupsProvider,
        private dbNotification: DbNotificationsProvider,
        private luxon: NotifiicationDateService,
        private router: Router,
        private storage: StorageService,
        private dbAttendees: DbAttendeesProvider,
        private dbSchedule: DbScheduleProvider,
        private translateService: TranslateService,
        private dbAppointments: DbAppointments,
        private dbPersonalSchedule: DbPersonalScheduleProvider
    ) {
        this.eventId = this.route.parent.params['_value']['uid'];
        this.moduleId = this.route.snapshot.params['moduleId'];
        this.formValidation = fb.group({
            'title_pt_BR': [''],
            'title_en_US': [''],
            'title_es_ES': [''],
            'title_fr_FR': [''],
            'title_de_DE': [''],
            'msg_pt_BR': [''],
            'msg_en_US': [''],
            'msg_es_ES': [''],
            'msg_fr_FR': [''],
            'msg_de_DE': [''],
            'description_pt_BR': [''],
            'description_en_US': [''],
            'description_es_ES': [''],
            'description_fr_FR': [''],
            'description_de_DE': [''],
            'url': [''],
            'send_to': [''],
            'when_send': [''],
            'date': [''],
            'time': [''],
            'groups': ['']
        });
        this.getEvent();
        this.getGroupsList();
        this.getAllAttendees();

        this.dbEvent.getModulesToSendNotifs(this.eventId).then(tab => {
            // console.log(tab)
            let lang = this.event.language.split('_').map(x=>x.charAt(0).toUpperCase() + x.slice(1)).join('')
            this.event.language
            this.beforeModulesList = [...tab].map(x => {
                return {key: `${x.uid}-${x.type}`, value: x.name[lang]}
            })
        })
        // please respect id and value formats cuz it's used in firebase cloud functions !!
        this.notifTypes = [
            {id: '0', value: true, text: this.translateService.instant('comp.notifications.type.in_app')},
            {id: '1', value: false, text: this.translateService.instant('comp.notifications.type.push_mobile')},
            {id: '2', value: false, text: this.translateService.instant('comp.notifications.type.email')},
            {id: '3', value: false, text: this.translateService.instant('comp.notifications.type.web_push')}
        ]
    }

    ngOnInit() {
    }


    getEvent() {
        this.dbEvent.getEvent(this.eventId, (event: Event) => {
            this.event = event;
        });
    }

    getGroupsList() {
        this.dbGroups.searchModulesAndGroups(this.eventId, (result) => {
            this.listGroup = result['groups'];
        });
    }

    getAllAttendees() {
        this.dbAttendees.getAttendeesByEvent(this.eventId, (attendees: Array<Attendee>) => {
            this.allAttendees = [];
            this.allAttendees = attendees;
        });
    }

    /******************* GROUPS **********************/

    // filter groups
    filterGroups() {
        if (this.queryGroup !== "") {
            this.filteredListGroups = this.listGroup.filter(function (el) {
                return el.name.toLowerCase().indexOf(this.queryGroup.toLowerCase()) > -1;
            }.bind(this));
        } else {
            this.filteredListGroups = [];
        }
    }

    // select track from filtered list
    selectGroup(item) {
        if (this.selectedGroup.length > 0) {
            const index = this.selectedGroup.indexOf(item);
            if (index == -1) {
                this.selectedGroup.push(item);
            }
        } else {
            this.selectedGroup.push(item);
        }
        this.getGroupPeopleReceiveNotification();
        this.queryGroup = '';
        this.filteredListGroups = [];
    }

    // remove selected location
    removeGroup(item) {
        this.selectedGroup.splice(this.selectedGroup.indexOf(item), 1);
        this.getGroupPeopleReceiveNotification();
    }

    getGroupPeopleReceiveNotification() {
        let auxAttendees = [];
        let contGroups = 0;
        this.showTotalUserReceive = false;
        this.selectedGroup.forEach(group => {
            let contAttendees = 0;
            for (let i in this.allAttendees) {
                let attendeeGroups = this.allAttendees[i]['groups'];
                if (attendeeGroups[group.uid] !== undefined && this.allAttendees[i]['notification'] !== undefined) {
                    auxAttendees.push(this.allAttendees[i]);
                }
                contAttendees++;
                if (contAttendees == this.allAttendees.length) {
                    contGroups++;
                }
            }
            if (contGroups == this.selectedGroup.length) {
                if(this.notifTypes.some(x => x.value && x.id === '1')){
                    this.numberAttendeesReceive = this.removeDoubleUser(auxAttendees);
                    this.showTotalUserReceive = true;
                }
            }
        });
    }

    removeDoubleUser(array) {
        let finalArray = array.filter(function (el, i) {
            return array.indexOf(el) == i;
        });
        return finalArray.length;
    }

    whenSendRadios($event) {
        this.whenSend = $event.target.value;
        if (this.whenSend == 'now') {
            this.scheduledInputs = false;
            this.formValidation.patchValue({
                date: null,
                time: null
            });
            this.showBeforeModulesList = false;
            this.showBeforeXXMinutes = false;
        } else if(this.whenSend == 'scheduled') {
            this.scheduledInputs = true;
            this.showBeforeModulesList = false;
            this.showBeforeXXMinutes = false;
        } else {
            this.showBeforeModulesList = true;
            this.scheduledInputs = false;
            this.selectedModule = this.beforeModulesList[0].key
            this.showBeforeXXMinutes = true;
        }
    }
    disableSendToGroup = false;
    selectModule(ev) {
        console.log(ev.target.value)
        const value = ev.target.value
        this.selectedModule = ev.target.value
        if(this.selectedModule.split('-')[1] == TypeModule.APPOINTMENTS){
            this.disableSendToGroup = true;
            document.getElementById('sendTo').click()
        }else {
            this.disableSendToGroup = false;
        }
        this.showBeforeXXMinutes = true;
    }

    setMinutesReminder(ev){
        this.minutesReminder = Number(ev.target.value);
    }

    // onChange funciton to get file from input file and handle image
    uploadPicture($event: any) {
        this.file = $event.srcElement.files[0];
        this.storage.notificationPicture(this.file, this.eventId, this.moduleId, UUID.UUID(), (url) => {
            this.fileUrl = url;
        });
    }

    sendToRadios($event) {
        this.sendTo = $event.target.value;
        let auxAttendees = [];
        this.showTotalUserReceive = false;
        this.numberAttendeesReceive = 0;
        if (this.sendTo == 'all') {
            this.groupsInput = false;
            this.selectedGroup = [];
            this.allAttendees.forEach(element => {
                if (element['notification'] !== undefined) {
                    auxAttendees.push(element);
                    if(this.notifTypes.some(x => x.value && x.id === '1')){
                        this.showTotalUserReceive = true;
                        this.numberAttendeesReceive = auxAttendees.length;
                    }
                }
            });
        } else {
            this.groupsInput = true;
        }
    }

    async checkNotification(data) {
        this.requiredMessage = false;
        this.requiredSendTo = false;
        this.requiredTitle = false;
        this.requiredDate = false;
        this.requiredTime = false;
        this.requiredWhenSend = false;
        this.requiredGroups = false;
        this.requiredTitleLanguage = false;
        this.requiredMessageLanguage = false;
        this.notificationCheckError = false;
        this.errorMinutesReminder = false;
        // start notification object
        let notification = new Notification();

        // notification principal language, to set the principal lang on send notification
        notification.principal_language = this.event.language;
        let auxLang;
        switch (this.event.language) {
            case 'pt_BR':
                auxLang = 'pt';
                break;
            case 'en_US':
                auxLang = 'en';
                break;
            case 'es_ES':
                auxLang = 'es';
                break;
            case 'fr_FR':
                auxLang = 'fr';
                break;
            case 'de_DE':
                auxLang = 'de';
                break;
        }

        // min 1 title is required
        if (
            (data.title_pt_BR == '' || data.title_pt_BR == undefined) &&
            (data.title_en_US == '' || data.title_en_US == undefined) &&
            (data.title_es_ES == '' || data.title_es_ES == undefined) &&
            (data.title_fr_FR == '' || data.title_fr_FR == undefined) &&
            (data.title_de_DE == '' || data.title_de_DE == undefined)
        ) {
            // required to fill only title
            this.requiredTitle = true;
            this.notificationCheckError = true;
            $('#collapseOfHeadings').collapse('show');
            this.errorTitleSwal.fire();
        }

        // notification titles (5 languages)
        notification.headings = {
            pt: data.title_pt_BR,
            en: data.title_en_US,
            es: data.title_es_ES,
            fr: data.title_fr_FR,
            de: data.title_de_DE
        }
        if (notification.headings[auxLang] == '' && notification.headings[auxLang]) {
            this.notificationCheckError = true;
            this.requiredTitleLanguage = true;
        }

        if (data.title_en_US == '' || data.title_en_US == undefined) {
            notification.headings['en'] = notification.headings[auxLang];
        }

        // min 1 msg is required
        if (
            (data.msg_pt_BR == '' || data.msg_pt_BR == undefined) &&
            (data.msg_en_US == '' || data.msg_en_US == undefined) &&
            (data.msg_es_ES == '' || data.msg_es_ES == undefined) &&
            (data.msg_fr_FR == '' || data.msg_fr_FR == undefined) &&
            (data.msg_de_DE == '' || data.msg_de_DE == undefined)
        ) {
            // required to fill only message
            this.requiredMessage = true;
            this.notificationCheckError = true;
            this.customErrorModal = 'É necessário preencher o título';
            $('#collapseOfContents').collapse('show');
            this.errorMsgSwal.fire();
        }

        // notification messages (5 languages)
        notification.contents = {
            pt: data.msg_pt_BR,
            en: data.msg_en_US,
            es: data.msg_es_ES,
            fr: data.msg_fr_FR,
            de: data.msg_de_DE
        }
        if (notification.contents[auxLang] == '' && notification.contents[auxLang]) {
            this.notificationCheckError = true;
            this.requiredMessageLanguage = true;
        }
        if (data.msg_en_US == '' || data.msg_en_US == undefined) {
            notification.contents['en'] = notification.contents[auxLang];
        }

        notification.descriptions = {
            'pt-BR': data.description_pt_BR,
            'en-US': data.description_en_US,
            'es-ES': data.description_es_ES,
            'fr-FR': data.description_fr_FR,
            'de-DE': data.description_de_DE,
        }

        // send to (all or group);
        if (this.sendTo == null) {
            this.requiredSendTo = true;
            this.notificationCheckError = true;
        }
        notification.send_to = this.sendTo;

        if (this.sendTo == 'group') {
            // case have selected groups, push uid of the groups
            notification.groups_ids = [];
            if (this.selectedGroup.length >= 1) {
                this.selectedGroup.forEach(element => {
                    notification.groups_ids.push(element.uid);
                });
            } else {
                this.requiredGroups = true;
                this.notificationCheckError = true;
            }
        } else if (this.sendTo == 'all') {
            this.selectedGroup = [];
            notification.groups_ids = [];
        }

        // case is scheduled notification
        if (this.whenSend == null) {
            this.requiredWhenSend = true;
            this.notificationCheckError = true;
        }
        notification.scheduled = this.scheduledInputs;
        if (this.showBeforeModulesList == true) {
            if(this.minutesReminder < 1) {
                this.errorMinutesReminder = true;
                return ;
            }
            const [moduleID,moduleType] = this.selectedModule.split('-')
            console.log(moduleID,moduleType)
            if(Number(moduleType) == 0) { // Schedule
                let list = await this.dbSchedule.getPromiseSessionsModule(moduleID)
                if (this.sendTo == 'group'){
                    list = [...list].filter(s => {
                        const sessionGroups = Object.keys(s.groups);
                        return [...this.selectedGroup].map(gr => gr.uid).some(_ => sessionGroups.includes(_))
                    })
                }
                const tabDates = [...list].map(x => {
                    const {day,month, year,hour,minute,second, millisecond} = x.newStartTime
                    const date = moment(`${month}/${day}/${year} ${hour}:${minute}:${second}:${millisecond}`)
                    const now = moment()
                    date.subtract(this.minutesReminder,'minutes')
                    if(now.diff(date, 'minutes') > 0 ){
                        return null;
                    } else {
                        notification.delivery_date = date.valueOf()
                        return (notification.delivery_date)
                    }
                }).filter(_ => _ !== null)

                this.createMultipleNotifs(notification, tabDates);
                return;
            } else if(Number(moduleType) == 37) { // Appointments
                const listAppointment = await this.dbAppointments.getAllAppointmentsPromise(this.eventId, moduleID)
                console.log('tabb=',listAppointment)
                const userIds = []
                const tabDates = [...listAppointment].map(x => {
                    const {day,month, year,hour,minute,second, millisecond} = x.startDateTime
                    const date = moment(`${month}/${day}/${year} ${hour}:${minute}:${second}:${millisecond} `)
                    const now = moment()
                    date.subtract(this.minutesReminder,'minutes')
                    if(now.diff(date, 'minutes') > 0 ){
                        return null;
                    } else {
                        // notification.delivery_date = moment(date).subtract(this.minutesReminder, 'minutes').valueOf()
                        notification.delivery_date = date.valueOf()
                        userIds[`${notification.delivery_date}`] = [x.invited.uid , x.applicant.uid]
                        return (notification.delivery_date)
                    }
                }).filter(_ => _ !== null)
                console.log(tabDates)
                const userAndDates = [...tabDates].map(d => {
                    return {
                        userIds: userIds[`${d}`],
                        rdv: d
                    }
                })

                this.createMultipleNotifs(notification, userAndDates, 'rdv');
                return;
            }else if(Number(moduleType) == 13) { // Personal Schedule
                const result = await this.dbPersonalSchedule.getSessionsModuleForAttendees(moduleID)
                let persSessionArr = []
                console.log(result)
                // return;
                if (this.sendTo == 'all'){
                    persSessionArr = [...result]
                } else if (this.sendTo == 'group'){
                    persSessionArr = [...result].map(x => {
                        const clonedObj = {...x}
                        const sessions = [...clonedObj.sessions].filter(s => {
                            const sessionGroups = Object.keys(s.groups);
                            return [...this.selectedGroup].map(gr => gr.uid).some(_ => sessionGroups.includes(_))
                        })
                        clonedObj.sessions = sessions
                        return clonedObj
                    }).filter(x => x.sessions.length > 0)
                }

                let userAndDates = [...persSessionArr].map(x => {
                    x = {...x}
                    x.sessions = [...x.sessions].map(s => {
                        const date = moment(s.startTime * 1000)
                        const now = moment()
                        date.subtract(this.minutesReminder,'minutes')
                        if(now.diff(date, 'minutes') > 0 ){
                            return null;
                        } else {
                            notification.delivery_date = date.valueOf()
                            return (notification.delivery_date)
                        }
                    }).filter(_ => _ !== null)
                    return x;
                }).filter(item => item.sessions.length > 0)
                // console.log(userAndDates)
                this.createMultipleNotifs(notification, userAndDates, 'personnal');
                return;
            }
        }
        else if (this.scheduledInputs == true) {
            if (data.date !== '' && data.date !== null) {
                if (data.time !== '' && data.time !== null) {
                    notification.scheduled_date = this.luxon.convertToRFC(data.date, data.time, this.event.timezone);
                } else {
                    // required to set time
                    this.requiredTime = true;
                    this.notificationCheckError = true;
                }
            } else {
                // required to set date
                this.requiredDate = true;
                this.notificationCheckError = true;
            }
        } else {
            notification.scheduled_date = '';
        }

        if (!notification.delivery_date) {
            if (data.date && data.time) {
                const tmp = data.date + 'T' + data.time + ':00';
                notification.delivery_date = this.luxon.mkTimestamp(
                    tmp, 
                    this.event.timezone
                );
            } else {
                notification.delivery_date = this.luxon.mkTimestamp(
                    new Date().toDateString(), 
                    this.event.timezone
                );
            }
        }

        if (moment().diff(moment(new Date(notification.delivery_date)), 'minutes') > 0) {
            this.notificationCheckError = true;
            this.errordateSwal.fire();
            return;
        }

        // notification highglight image (display in notification details on app)
        notification.highlight_picture = this.fileUrl;
        // url to open on click in notification
        notification.url = data.url;

        notification.type = this.notifTypes.filter(x => x.value === true).map(x => x.id).join(',')

        if (this.notificationCheckError == false) {
            if(notification.type.includes('2')){
                if(moment(notification.delivery_date).diff(moment(),'days') > 3){
                    const res = await this.warningSwal.fire()
                    if(res.isConfirmed){
                        this.createNotification(notification);
                    }
                } else {
                    this.createNotification(notification);
                }
            } else {
                this.createNotification(notification);
            }
        }
    }

    async createMultipleNotifs(notification: Notification, arr: Array<any>, type?: string){
        const listNotifs = []

        if(type === 'personnal') {
            arr.forEach(item => {
                item.sessions.forEach(date => {
                    const notif = {...notification}
                    notif.delivery_date = date
                    notif.scheduled = true
                    const scheduled_date =  new Date(date)
                    notif.scheduled_date = this.luxon.convertToRFC(`${scheduled_date.getFullYear()}-${scheduled_date.getMonth()+1}-${scheduled_date.getDate()} `, `${scheduled_date.getHours()}:${scheduled_date.getMinutes()}`, this.event.timezone);
                    notif.users_ids = [item.userId]
                    notif.forModule = TypeModule.PERSONALSCHEDULE
                    notif.type = this.notifTypes.filter(x => x.value === true).map(x => x.id).join(',')
                    listNotifs.push(notif)
                })
            })

        } else if(type === 'rdv') {
            arr.forEach(item => {
                const notif = {...notification}
                notif.delivery_date = item.rdv
                notif.scheduled = true
                const scheduled_date =  new Date(item.rdv)
                notif.scheduled_date = this.luxon.convertToRFC(`${scheduled_date.getFullYear()}-${scheduled_date.getMonth()+1}-${scheduled_date.getDate()} `, `${scheduled_date.getHours()}:${scheduled_date.getMinutes()}`, this.event.timezone);
                notif.users_ids = [...item.userIds]
                notif.forModule = TypeModule.APPOINTMENTS
                notif.type = this.notifTypes.filter(x => x.value === true).map(x => x.id).join(',')
                listNotifs.push(notif)
            })
        } else {
            arr.forEach(d => {
                const notif = {...notification}
                notif.delivery_date = d
                notif.scheduled = true
                const scheduled_date =  new Date(d)
                // let [dayWeek, day, month, year, time, zone] = this.luxon.convertToRFC(`${scheduled_date.getFullYear()}-${scheduled_date.getMonth()+1}-${scheduled_date.getDate()} `, `${scheduled_date.getHours()}:${scheduled_date.getMinutes()}`, this.event.timezone).split(' ');
                // month = scheduled_date.getMonth()+1 > 9 ? `${scheduled_date.getMonth()+1}`: `0${scheduled_date.getMonth()+1}`;
                // notif.scheduled_date = this.luxon.convertToRFC(`${scheduled_date.getFullYear()}-${scheduled_date.getMonth()+1}-${scheduled_date.getDate()} `, `${scheduled_date.getHours()}:${scheduled_date.getMinutes()}`, this.event.timezone);
                notif.scheduled_date = this.luxon.convertToRFC(`${scheduled_date.getFullYear()}-${scheduled_date.getMonth()+1}-${scheduled_date.getDate()} `, `${scheduled_date.getHours()}:${scheduled_date.getMinutes()}`, this.event.timezone);
                // const finalDate = `${year}-${month}-${day} ${time} GMT${zone}`
                // notif.scheduled_date = finalDate

                notif.type = this.notifTypes.filter(x => x.value === true).map(x => x.id).join(',')
                listNotifs.push(notif)
            })
        }
        console.log('listNotifs = ',listNotifs)
        if(listNotifs.length > 0) {
            const notifsEmail = [...listNotifs].filter(n => {
                return n.type.includes('2') && moment(n.delivery_date).diff(moment(),'days') > 3
            })
            if(notifsEmail.length > 0){
                this.warningSwal.text = this.translateService.instant("global.modals.warning_emails_scheduled_date", {
                    nbrNotifs: notifsEmail.length,
                    nbrTotal: listNotifs.length
                });
                const res = await this.warningSwal.fire()
                if(res.isConfirmed){
                    this.dbNotification.createMultipleNotification(this.eventId, this.moduleId,listNotifs).then(()=>{
                        this.successSwal.fire();
                    })
                }
            } else {
                this.dbNotification.createMultipleNotification(this.eventId, this.moduleId,listNotifs).then(()=>{
                    this.successSwal.fire();
                })
            }
        } else {
            this.errorEmptySelectionSwal.fire()
        }
    }

    createNotification(notification: Notification) {
        console.log(notification)
        this.loader = true;
        this.dbNotification.createNotification(this.eventId, this.moduleId, notification, (status) => {
            if (status == true) {
                this.successSwal.fire();
                this.loader = false;
            } else {
                this.errorSwal.fire();
                this.loader = false;
            }
        });
    }

    redirectToList() {
        this.router.navigate([`/event/${this.eventId}/notifications/${this.moduleId}`]);
    }


    selectNotifType(id) {
        this.notifTypes = [...this.notifTypes].map(x => {
            // x.value = false
            if(x.id === id){
                x.value = !x.value
            }
            return x
        })
    }
}
