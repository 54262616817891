import { NameModule } from "../name-module";

export class Module {
    uid: string;
    name: NameModule //names multi languages
    eventId: string;
    icon: string
    iconFamily: string;
    viewBackOffice: string; //component
    viewApp: string; //component
    habilitedBackOffice: boolean; //controla se o módulo é habilitado/desabilitado no backoffice
    habilitedApp: boolean; //controla se o módulo é habilitado/desabilitado no app
    order: number;
    remove: boolean; // verifica se o módulo pode ser removedo
    type: number;
    total_access: number;
    
    constructor(name: NameModule, icon: string, type: number, eventId: string, order: number, remove: boolean, habilitedApp: boolean, habilitedBackoffice: boolean) {
        this.uid = null;
        this.name = name;
        this.eventId = eventId;
        this.type = type;
        this.order = order;
        this.remove = remove;

        this.habilitedApp = habilitedApp;
        this.habilitedBackOffice = habilitedBackoffice;

        this.icon = icon
        this.iconFamily = 'material-icons';

        this.viewBackOffice = "";
        this.viewApp = "";
        this.total_access = 0;
    }

}