import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CloudOptions, ZoomOnHoverOptions } from 'angular-tag-cloud-module';
import { DbWordCloudProvider } from '../providers/database/db-word-cloud';
import { WordCloud } from '../models/word-cloud';

@Component({
  selector: 'app-word-cloud-result',
  templateUrl: './word-cloud-result.component.html',
  styleUrls: ['./word-cloud-result.component.scss']
})
export class WordCloudResultComponent implements OnInit {
  eventId: string = null;
  moduleId: string = null;
  cloudId: string = null;
  listWordAux: Array<any> = null;

  constructor(private route: ActivatedRoute, private dbWordCloud: DbWordCloudProvider) {
    this.eventId = this.route.snapshot.params['eventId'];
    this.moduleId = this.route.snapshot.params['moduleId'];
    this.cloudId = this.route.snapshot.params['cloudId'];
  }

  ngOnInit() {
    this.getWords();
  }

  getWords() {
    this.dbWordCloud.getWords(this.moduleId, this.cloudId, (list) => {
      let listAux = [];
      for (let aux of list) {
        listAux.push(aux.answer)
      }

      this.contAnswers(listAux)
    });
  }

  /**
   * contabiliza as respostas repetidas
   * Percorre cada item da lista e salva o elemento em na variavel 'anterior'
   */
  contAnswers(list) {
    let cont = 0;
    this.listWordAux = [];
    for (const answer of list) {
      if (this.listWordAux == null) { // Primeira interacao do for, se a lista for null instancia ela;
        this.listWordAux = new Array();
      }

      let index = this.checkIndexExists(this.listWordAux, answer) 
      
      if(index === - 1) {
        let word = {};
        word['answer'] = answer;
        word['total'] = 1; 
        this.listWordAux.push(word);
      } else {
        this.listWordAux[index].total += 1;
      }
    }

    this.createCloud(this.listWordAux);

  }

  checkIndexExists(array, item) {
    return array.map(function (e) { return e.answer.toLowerCase(); }).indexOf(item.toLowerCase());
  }


  /**
   * Cria o JSON para criar a nuvem
   */
  createCloud(list) {
    this.data = [];
    let obj = {};
    let cont = 0;
    for (const item of list) {
      if (cont == 0) {
        this.data = [];
      }
      obj = {
        text: item.answer,
        weight: item.total
      };

      this.data.push(obj);
      cont++;
    }
  }


  /**
   * Dados de config. modulo
   */
  options: CloudOptions = {
    overflow: false,
    randomizeAngle: true
  };

  zoomOnHoverOptions: ZoomOnHoverOptions = {
    scale: 1.7, // Elements will become 130 % of current zize on hover
    transitionTime: 1.2, // it will take 1.2 seconds until the zoom level defined in scale property has been reached
    delay: 0.2 // Zoom will take affect after 0.8 seconds
  };

  data: any = [];

}
