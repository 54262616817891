import { Component, OnInit, NgModule } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { Quiz } from '../../../../../../models/quiz';
import { Question } from '../../../../../../models/quiz-question';
import { Answer } from '../../../../../../models/quiz-answer';
import { Group } from '../../../../../../models/group';
import { Session } from '../../../../../../models/session';
import { Track } from '../../../../../../models/track';
import { TypeQuiz } from '../../../../../../models/type-quiz';
import { DbQuizProvider } from '../../../../../../providers/database/db-quiz';
import { DbEventsProvider } from 'src/app/providers/database/db.events';
import { DbScheduleProvider } from 'src/app/providers/database/db-schedule';
import { DbGroupsProvider } from 'src/app/providers/database/db-groups';
import { MenuIcons, icons } from '../../../../../../models/menu-icons';
import { PathIcons } from 'src/app/paths/path-icons';
import { GlobalService } from 'src/app/providers/global/global.service';
import { Languages } from 'src/app/models/languages';
import { NameModule } from 'src/app/models/name-module';
import { GetNameSession } from 'src/app/pipes/get-name-session';
import { FormatedMultiIdiomaService } from 'src/app/providers/formated-multi-idioma/formated-multi-idioma.service';
import { ImageCroppedEvent } from 'ngx-image-cropper';

declare let $: any;

@Component({
    selector: 'app-create-quiz',
    templateUrl: './create-quiz.component.html',
    styleUrls: ['./create-quiz.component.scss']
})

@NgModule({
    declarations: [
        GetNameSession
    ],
})

export class CreateQuizComponent implements OnInit {
    public userLanguage: string   // get the language of the user.
    public eventLanguage: string   // get the language of the event.

    term;
    quizModuleId: string = null;
    moduleId: string = null
    eventId: string = null;
    languages: Languages = null //event languages
    activeLanguage: string = null;
    eventTimezone: string = null;
    file: any = null;
    fileName: string = null;

    // public maxResponses = false;
    // QuestionMaxResponses = false;
    hideAddQuestion = true;

    public quizName: NameModule = new NameModule('', '', '', '', '');

    public changeAnswers: boolean = false;
    public viewAnswered: boolean = false;
    public listQuestions: Array<Question> = [];
    public listAnswers: Array<Answer> = [new Answer()];
    public questionTitle: NameModule = new NameModule('', '', '', '', '');
    public questionInfobooth: NameModule = new NameModule('', '', '', '', '');
    public questionType: string = 'oneSelect';
    public difusionType: string = "no";
    public chartType: string = "BarChart";
    public operation: string = "Criar";
    public indexEdit: number = null;
    public searchFor: string = 'Pattern';
    public indexCorrectAnswer: string = null;
    public activeTime: boolean = false;
    public maxTimer: number = null;
    public totalQuizs: number = null;

    public includeImgToAnswers: boolean = false;
    public questionsNumberVisibility: boolean = false;

    public chartBlockVisibility: boolean = true;
    public answersOptionsBlockVisibility: boolean = true;
    public activeMarker: boolean = false;
    public activeWeight: boolean = false;

    // Errors
    public msgError = false;
    public errorLevantamento = false;
    public errorNoQuestions = false;
    public errorTitle = false;
    public errorSelectQuestion = false;
    public errorOptionAnswer = false;
    public errorOptionGraphic = false;
    public errorSelectDifusion: boolean = false;
    iconsImg: MenuIcons[] = icons;
    selectedIcon: string = PathIcons.icon_quiz;
    selectedIconFamily: string = 'material-icons';

    loader: boolean = false;


    // ============================================ TIPO DE LEVANTAMENTO ====================================================

    public quizType: string = TypeQuiz.Pattern;

    quizLinkedToSession: boolean = false;
    SelectedSessionVisibility: boolean = false;
    tracksAttendeeVisibility: boolean = false;
    specificTrackVisibility: boolean = false;
    scheduleModuleVisibility: boolean = false;
    scheduleModuleTrackVisibility: boolean = false;
    scheduleModuleSessionVisibility: boolean = false;
    specificGroupVisibility: boolean = false;

    // tracks
    tracks: Array<Track>;
    public group = []; // array de ids
    public query = ''; // string digitada pelo usuário
    public filteredList = []; // lista de sugestões exibidas
    public selectedTracks = []; // array com os tracks selecionados

    // sessions
    sessions: Array<Session>;
    sessionsNames: Array<any>;
    public session = [];
    public querySession = ''; // string digitada pelo usuário
    public filteredSession = []; // lista de sugestões exibidas
    public selectedSessions = []; // array com os tracks selecionados

    // groups
    groups: Array<Group>;
    groupsNames: Array<any>;
    public ManagerGroup = []; // array de ids
    public queryGroup = ''; // string digitada pelo usuário
    public filteredGroup = []; // lista de sugestões exibidas
    public selectedGroups = []; // array com os tracks selecionados

    scheduleModules: Array<any> = [];
    //====================================== FIM TIPO DE LEVANTAMENTO ==============================================

    croppedImage;
    listCroppedImage = [];
    imageChangedEvent: any;
    cropperShow: boolean;
    sizeImgMax: number = 2097152;
    invalidSizeImg: boolean = false;
    fileNameCropped: string;
    selectedAnswer: number;

    imgFile: File;
    imgFileName: string = "";

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private location: Location,
        private db: DbQuizProvider,
        private dbEvent: DbEventsProvider,
        private dbSession: DbScheduleProvider,
        private dbGroup: DbGroupsProvider,
        private global: GlobalService,
        private formatIdioma: FormatedMultiIdiomaService
    ) {
        // get module id
        this.eventId = this.eventId = this.route.pathFromRoot[1]['params']['_value']['uid'];
        this.quizModuleId = this.route.parent.params['_value']['moduleId'];
        this.load();
    }

    ngOnInit() {
        $("label.icon-selector").click(function () {
            $("#dropdownMenuButton").dropdown("toggle");
        });
        this.getUserLanguage();
        this.getEvent();
        this.getTotalQuizs();
    }

    getTotalQuizs() {
        this.db.getTotalQuizs(this.quizModuleId, (data) => {
            this.totalQuizs = data;
        })
    }

    getEvent() {
        this.dbEvent.getEvent(this.eventId, (event) => {
            this.eventTimezone = event.timezone;
            this.eventLanguage = event.language;
            this.activeLanguage = this.formatIdioma.convertLangFormat(event.language);
            this.languages = event.languages;
        })
    }

    // get the language of the user.
    getUserLanguage() {
        this.global.getLanguage((language) => {
            this.userLanguage = language
        })
    }

    addAnswerOption() {
        let answer = new Answer();
        this.listAnswers.push(answer);
    }

    removeAnswerOption(index) {
        this.listAnswers.splice(index, 1);
        this.listCroppedImage.splice(index, 1);
    }

    addQuestion() {
        this.errorTitle = false;
        this.errorSelectQuestion = false;
        this.errorOptionGraphic = false;
        this.errorOptionAnswer = false;
        this.msgError = false;

        //Caso o usuário não tenha inserido o titulo da pergunta
        let mainLanguage = this.formatIdioma.mainLanguageIsFilled(this.questionTitle, this.eventLanguage);
        this.questionTitle = this.formatIdioma.fillEmptyLanguage(this.questionTitle, this.eventLanguage);
        if (this.questionTitle == undefined || this.questionTitle == null || !mainLanguage) {
            this.msgError = true;
            this.errorTitle = true;
        }

        this.questionInfobooth = this.formatIdioma.fillEmptyLanguage(this.questionInfobooth, this.eventLanguage);
        this.questionInfobooth = this.replaceLinkInfooboth(this.questionInfobooth);

        if (this.questionType == undefined || this.questionType == null || this.questionType == "") {
            this.msgError = true;
            this.errorSelectQuestion = true;
        }

        if (this.questionType == 'oneSelect' || this.questionType == 'multipleSelect') {
            //Caso não tenha adicionado nenhuma resposta possível
            let find = false;

            for (let answer of this.listAnswers) {
                let mainLanguageAnswer = this.formatIdioma.mainLanguageIsFilled(answer.answer, this.eventLanguage);
                answer.answer = this.formatIdioma.fillEmptyLanguage(answer.answer, this.eventLanguage);
                if (answer.answer != undefined && answer.answer != null && mainLanguageAnswer) {
                    find = true;
                }
            }

            if (find == false) {
                this.msgError = true;
                this.errorOptionAnswer = true;
            }

            if (this.chartType == undefined || this.chartType == null || this.chartType == "") {
                this.msgError = true;
                this.errorOptionGraphic = true;
            }
        }

        if (this.questionType == "evaluation") {
            if (this.chartType == undefined || this.chartType == null || this.chartType == "") {
                this.msgError = true;
                this.errorOptionGraphic = true;
            }
        }

        if (this.difusionType == undefined || this.difusionType == null || this.difusionType == "") {
            this.msgError = true;
            this.errorSelectDifusion = true;
        }

        if (this.msgError == false) {
            this.sendQuestion();
        }
    }

    sendQuestion() {
        let question = new Question();
        question.title = this.questionTitle;
        question.infobooth = this.questionInfobooth;
        question.type = this.questionType;
        question.graphic = "BarChart";
        question.image = this.file;
        question.graphicDifusion = (this.difusionType == 'yes') ? true : false;        
        if (question.type == "oneSelect" || question.type == "multipleSelect") {
            let answers: Array<Answer> = [];
            for (let answer of this.listAnswers) {
                if (answer.answer != undefined) {
                    if (answer.correct == undefined) {
                        answer.correct = false;
                    }

                    // answer.answer = Object.assign({}, answer.answer);
                    answers.push(answer);
                }
            }

            question.answers = answers;
        }

        if (this.operation == "Criar") {
            this.listQuestions.push(question);
        } else if (this.operation == "Editar") {
            // question.title = Object.assign({}, question.title)
            this.listQuestions[this.indexEdit] = question;
        }

        this.resetQuestion();
    }

    getEditQuestion(index) {
        this.indexEdit = index;
        this.listAnswers = this.listQuestions[index].answers;
        this.questionTitle = this.listQuestions[index].title;
        this.questionInfobooth = this.listQuestions[index].infobooth;
        this.questionType = this.listQuestions[index].type;
        this.chartType = this.listQuestions[index].graphic;
        this.operation = "Editar";
        this.difusionType = (this.listQuestions[index].graphicDifusion) ? 'yes' : 'no';

        if (this.questionType == 'oneSelect') {
            for (let i = 0; i < this.listAnswers.length; i++) {
                if (this.listAnswers[i].correct == true) {
                    this.indexCorrectAnswer = i.toString();
                }
            }
        }
    }


    resetQuestion() {
        this.listAnswers = [new Answer()];
        this.questionTitle = new NameModule('', '', '', '', '');
        this.questionInfobooth = new NameModule('', '', '', '', '');
        this.listAnswers[0].correct = false;
        this.operation = "Criar";
        this.indexCorrectAnswer = null;
        this.file = null;
        this.fileName = null;
        this.selectedAnswer = -1;
        this.fileNameCropped = null;
    }

    removeQuestion(index) {
        this.listQuestions.splice(index, 1);
    }

    setCorrect() {
        let index = Number(this.indexCorrectAnswer);

        for (let i = 0; i < this.listAnswers.length; i++) {
            if (i != index) {
                this.listAnswers[i].correct = false;
            } else {
                this.listAnswers[i].correct = true;
            }
        }
    }

    onUploadChange($ev) {
        this.file = $ev.srcElement.files[0];
        this.fileName = this.file.name;
    }

    uploadImg($event) {
        this.imgFile = $event.srcElement.files[0];
        this.imgFileName = this.imgFile.name;
    }

    setIcon(item) {
        this.selectedIcon = item.icon;
        this.selectedIconFamily = item.family;
        $('.dropdown-menu').removeClass('show');
    }

    createQuiz() {
        let scheduleModuleId = $("#scheduleModuleQuiz").val();
        let trackModuleId = $("#scheduleModuleTrackQuiz").val();
        let sessionModuleId = $("#scheduleModuleSessionQuiz").val();
        let iconQuiz = this.selectedIcon;
        let iconFamilyQuiz = this.selectedIconFamily;
        this.loader = true;

        this.errorLevantamento = false;
        this.errorNoQuestions = false;

        let mainLanguage = this.formatIdioma.mainLanguageIsFilled(this.quizName, this.eventLanguage);
        if (this.quizName == undefined || this.quizName == null || !mainLanguage) {
            this.errorLevantamento = true;
            this.loader = false;
        }
        else {

            this.quizName = this.formatIdioma.fillEmptyLanguage(this.quizName, this.eventLanguage);

            if (this.listQuestions.length < 1) {
                this.errorNoQuestions = true;
                this.loader = false;
            } else {
                const quiz = new Quiz();
                quiz.title = new NameModule(this.quizName.PtBR, this.quizName.EnUS, this.quizName.EsES, this.quizName.FrFR, this.quizName.DeDE);
                quiz.type = this.searchFor;
                quiz.icon = iconQuiz;
                quiz.iconFamily = iconFamilyQuiz;
                // quiz.max_responses = maxResponses;
                quiz.change_answer = this.changeAnswers;
                quiz.view_answered = this.viewAnswered;
                quiz.active_timer = this.activeTime;
                quiz.timer_questions = this.maxTimer;
                quiz.visibility = true;
                quiz.module_id = this.moduleId;
                quiz.question = this.listQuestions;
                quiz.order = this.totalQuizs;
                quiz.weight = this.activeWeight;
                quiz.marker = this.activeMarker;
                quiz.totalQuestions = this.listQuestions.length;

                quiz.questionsNumberVisibility = this.questionsNumberVisibility;
                quiz.includeImgToAnswers = this.includeImgToAnswers;
                console.log('questionsNumberVisibility', quiz.questionsNumberVisibility);
                console.log('includeImgToAnswers',quiz.includeImgToAnswers);

                switch (quiz.type) {
                    case TypeQuiz.Pattern:
                        this.db.createQuiz(this.imgFile, this.quizModuleId, quiz, "", null, this.eventTimezone, this.eventId, (data) => {
                            if (data) {
                                this.returnQuizList();
                            }   
                            
                            this.loader = false;
                        }); 
                        break;

                    case TypeQuiz.AllSessions:                            
                            this.db.createQuiz(this.imgFile, this.quizModuleId, quiz, "", null, this.eventTimezone, this.eventId, (data) => {
                                if (data) {
                                    this.returnQuizList();
                                }

                                this.loader = false;
                            })
                            break;

                    case TypeQuiz.ScheduleModule:
                        quiz.module_id = scheduleModuleId;
                        this.db.createQuiz(this.imgFile, this.quizModuleId, quiz, scheduleModuleId, null, this.eventTimezone, this.eventId, (data) => {
                            if (data) {
                                this.returnQuizList();
                            }

                            this.loader = false;
                        });
                        break;

                    case TypeQuiz.SessionTrack:
                        quiz.module_id = trackModuleId;
                        this.db.createQuiz(this.imgFile, this.quizModuleId, quiz, trackModuleId, this.selectedTracks, this.eventTimezone, this.eventId, (data) => {
                            if (data) {
                                this.returnQuizList();
                            }

                            this.loader = false;
                        });
                        break;

                    case TypeQuiz.SpecificSession:
                        quiz.module_id = sessionModuleId;
                        this.db.createQuiz(this.imgFile, this.quizModuleId, quiz, sessionModuleId, this.selectedSessions, this.eventTimezone, this.eventId, (data) => {
                            if (data) {
                                this.returnQuizList();
                            }

                            this.loader = false;
                        });
                        break;

                    case TypeQuiz.SpecificGroup:
                        this.db.createQuiz(this.imgFile, this.quizModuleId, quiz, null, this.selectedGroups, this.eventTimezone, this.eventId, (data) => {
                            if (data) {
                                this.returnQuizList();
                            }

                            this.loader = false;
                        });
                        break;
                }
            }
        }
    }

    replaceLinkInfooboth(data) {
        data['PtBR'] = data['PtBR'].replace(/href="/g, 'class="wysiwyg-link" href="');
        data['EnUS'] = data['EnUS'].replace(/href="/g, 'class="wysiwyg-link" href="');
        data['EsES'] = data['EsES'].replace(/href="/g, 'class="wysiwyg-link" href="');
        data['FrFR'] = data['FrFR'].replace(/href="/g, 'class="wysiwyg-link" href="');
        data['DeDE'] = data['DeDE'].replace(/href="/g, 'class="wysiwyg-link" href="');

        return data;
    }

    returnQuizList() {
        this.router.navigate([`/event/${this.eventId}/interactivity/quiz/${this.quizModuleId}`]);
    }


    //======================================== SURVEY TYPE ====================================================

    //verifica se o quiz será vinculado ou não a uma sessão
    changeTypeQuiz() {
        if ($("#quizLinkedToSession").is(":checked")) {
            this.quizLinkedToSession = true;

            //caso o quiz sejá vinculado a uma sessão, automaticamente o valor inicial se torna todas as sessões
            this.quizType = TypeQuiz.AllSessions;
        } else {
            this.quizLinkedToSession = false;
            this.selectedSessions = [];
            this.quizType = TypeQuiz.Pattern;

            this.SelectedSessionVisibility = false;
            this.tracksAttendeeVisibility = false;
            this.scheduleModuleTrackVisibility = false;
            this.scheduleModuleSessionVisibility = false;
            this.specificTrackVisibility = false;
            this.specificGroupVisibility = false;
        }
    }


    //caso o quiz seja vinculado as sessões, verifica se será em todas ou em uma especifica e altera o quizType
    changeSessionType() {
        switch (this.searchFor) {
            case 'AllSessions':
                this.quizType = TypeQuiz.AllSessions;
                this.SelectedSessionVisibility = false;
                this.tracksAttendeeVisibility = false;
                this.scheduleModuleTrackVisibility = false;
                this.scheduleModuleSessionVisibility = false;
                this.specificTrackVisibility = false;
                this.specificGroupVisibility = false;

                //Esvazia o array de sessões selecionadas posteriormente.
                this.selectedSessions = [];
                break;

            case 'ScheduleModule':
                this.quizType = TypeQuiz.ScheduleModule;
                this.SelectedSessionVisibility = false;
                this.tracksAttendeeVisibility = false;
                this.scheduleModuleVisibility = true;
                this.scheduleModuleTrackVisibility = false;
                this.scheduleModuleSessionVisibility = false;
                this.specificTrackVisibility = false;
                this.specificGroupVisibility = false;
                break;

            case 'SessionTrack':
                this.quizType = TypeQuiz.SessionTrack;
                this.SelectedSessionVisibility = false;
                this.tracksAttendeeVisibility = false;
                this.scheduleModuleVisibility = false;
                this.scheduleModuleSessionVisibility = false;
                this.scheduleModuleTrackVisibility = true;
                this.specificGroupVisibility = false;
                break;

            case 'SpecificSession':
                this.quizType = TypeQuiz.SpecificSession;
                this.SelectedSessionVisibility = false;
                this.tracksAttendeeVisibility = false;
                this.scheduleModuleVisibility = false;
                this.scheduleModuleTrackVisibility = false;
                this.scheduleModuleSessionVisibility = true;
                this.specificTrackVisibility = false;
                this.specificGroupVisibility = false;
                break;

            case 'SpecificGroup':
                this.loadGroups();
                this.quizType = TypeQuiz.SpecificGroup;
                this.SelectedSessionVisibility = false;
                this.tracksAttendeeVisibility = false;
                this.scheduleModuleVisibility = false;
                this.scheduleModuleTrackVisibility = false;
                this.scheduleModuleSessionVisibility = false;
                this.specificTrackVisibility = false;
                this.specificGroupVisibility = true;
                break;
        }
    }


    moduleSelectedChange() {
        var track = $("#scheduleModuleTrackQuiz").val();
        var session = $("#scheduleModuleSessionQuiz").val();
        var specificModule = $("#scheduleModuleQuiz").val();

        if (track == undefined || track == null || track == '') {
            this.specificTrackVisibility = false;
        } else {
            this.specificTrackVisibility = true;
            this.moduleId = track;
            this.loadTracks();
        }

        if (session == undefined || session == null || session == '') {
            this.SelectedSessionVisibility = false;
        } else {
            this.SelectedSessionVisibility = true;
            this.moduleId = session;
            this.loadSessions();
        }
    }


    load() {
        this.dbEvent.getModulesSchedule(this.eventId, (data) => {
            this.scheduleModules = data;
        });
    }


    // LOAD ALL TRACKS OF SCHEDULE MODULE 
    private loadTracks() {
        this.dbSession.getModuleTracks(this.moduleId, (tracks) => {
            this.tracks = [];
            this.tracks = tracks;
        })
    }

    // receive group name and return index of the group  to get id group
    findIdTrack(nameGroup: string) {
        const index = this.group.map(function (e) { return e.name; }).indexOf(nameGroup);
        return index;
    }

    // autocomplete
    filter() { //  função usa a query variável para filtrar tracks, então ela armazena o resultado no filteredList.
        if (this.query !== "") {
            this.filteredList = this.tracks.filter(function (el) {
                return el.name.toLowerCase().indexOf(this.query.toLowerCase()) > -1;
            }.bind(this));
        } else {
            this.filteredList = [];
        }
    }

    // atribuir o item selecionado para a variável de consulta, a fim de fazê-lo aparecer na entrada, e para fazer a lista de sugestões de desaparecer
    select(item) {
        if (this.selectedTracks.length > 0) {
            const index = this.selectedTracks.indexOf(item);
            if (index == -1) {
                this.selectedTracks.push(item);
            }
        } else {
            this.selectedTracks.push(item);
        }
        this.query = '';
        this.filteredList = [];
    }

    remove(item) { // remove group from display list
        this.selectedTracks.splice(this.selectedTracks.indexOf(item), 1);
    }



    loadSessions() {
        this.dbSession.getSessionsModule(this.moduleId, (list: Array<Session>) => {
            this.sessions = [];
            this.session = list;

            this.sessionsNames = [];
            for (const session of list) {
                this.sessionsNames.push(new GetNameSession().transform(session, [this.userLanguage, this.eventLanguage]));
            }
        })
    }

    // autocomplete
    filterSession() { //  função usa a query variável para filtrar tracks, então ela armazena o resultado no filteredList.
        if (this.querySession !== "") {
            this.filteredSession = this.sessionsNames.filter(function (el) {
                return el.toLowerCase().indexOf(this.querySession.toLowerCase()) > -1;
            }.bind(this));
        } else {
            this.filteredSession = [];
        }
    }

    // receive session name and return index of the session to get id session
    findIdSession(nameSession: string) {
        let index = -1

        // search session using user language
        switch (this.userLanguage) {
            case ('pt_BR'):
                index = this.session.map(function (e) { return e.name.PtBR; }).indexOf(nameSession);
                break

            case ('en_US'):
                index = this.session.map(function (e) { return e.name.EnUS; }).indexOf(nameSession);
                break

            case ('es_ES'):
                index = this.session.map(function (e) { return e.name.EsES; }).indexOf(nameSession);
                break

            case ('fr_FR'):
                index = this.session.map(function (e) { return e.name.FrFR; }).indexOf(nameSession);
                break

            case ('de_DE'):
                index = this.session.map(function (e) { return e.name.DeDE; }).indexOf(nameSession);
                break
        }

        // search session using principal event language
        if (index <= -1) {
            switch (this.eventLanguage) {
                case ('pt_BR'):
                    index = this.session.map(function (e) { return e.name.PtBR; }).indexOf(nameSession);
                    break

                case ('en_US'):
                    index = this.session.map(function (e) { return e.name.EnUS; }).indexOf(nameSession);
                    break

                case ('es_ES'):
                    index = this.session.map(function (e) { return e.name.EsES; }).indexOf(nameSession);
                    break

                case ('fr_FR'):
                    index = this.session.map(function (e) { return e.name.FrFR; }).indexOf(nameSession);
                    break

                case ('de_DE'):
                    index = this.session.map(function (e) { return e.name.DeDE; }).indexOf(nameSession);
                    break
            }
        }

        return index;
    }

    // atribuir o item selecionado para a variável de consulta, a fim de fazê-lo aparecer na entrada, e para fazer a lista de sugestões de desaparecer
    selectSession(item) {
        const index = this.findIdSession(item);
        if (index > -1) {
            this.selectedSessions.push(this.session[index]);
        }
        this.querySession = '';
        this.filteredSession = [];
    }

    removeSession(item) { // remove group from display list
        this.selectedSessions.splice(this.selectedSessions.indexOf(item), 1);
        // this.dao.notAllowGroupCheckin(this.eventId, this.groupId);
    }

    loadGroups() {
        this.dbGroup.searchModulesAndGroups(this.eventId, (response) => {
            this.groups = [];
            this.groups = response['groups'];
        });
    }

    // autocomplete
    filterGroup() { //  função usa a query variável para filtrar tracks, então ela armazena o resultado no filteredList.
        if (this.queryGroup !== "") {
            this.filteredGroup = this.groups.filter(function (el) {
                return el.name.toLowerCase().indexOf(this.queryGroup.toLowerCase()) > -1;
            }.bind(this));
        } else {
            this.filteredGroup = [];
        }
    }

    // receive group name and return index of the group to get id group
    findIdGroup(nameGroup: string) {
        const index = this.group.map(function (e) { return e.name; }).indexOf(nameGroup);
        return index;
    }

    // atribuir o item selecionado para a variável de consulta, a fim de fazê-lo aparecer na entrada, e para fazer a lista de sugestões de desaparecer
    selectGroup(item) {
        if (this.selectedGroups.length > 0) {
            const index = this.selectedGroups.indexOf(item);
            if (index == -1) {
                this.selectedGroups.push(item);
            }
        } else {
            this.selectedGroups.push(item);
        }
        this.queryGroup = '';
        this.filteredGroup = [];
    }

    removeGroup(item) { // remove group from display list
        this.selectedGroups.splice(this.selectedGroups.indexOf(item), 1);
        // this.dao.notAllowGroupCheckin(this.eventId, this.groupId);
    }


    cancel() {
        this.location.back();
    }

    imageCropped(event: ImageCroppedEvent) {
        // Get base 64 image
        this.croppedImage = event.base64;
    }

    onUploadCroppedChange($event: any) {
        this.invalidSizeImg = false;
        const file: File = $event.target.files[0];
        this.fileNameCropped = file.name;

        if (file.size < this.sizeImgMax) {
            this.cropperShow = true;
            this.imageChangedEvent = event;
        } else {
            this.invalidSizeImg = true;
        }
    }

    saveAnswerPicture() {
        if (!this.invalidSizeImg && this.croppedImage) {
            this.listCroppedImage[this.selectedAnswer] = this.croppedImage;
            const aux = this.croppedImage.split(',');
            this.listAnswers[this.selectedAnswer].img = aux[1]
            this.clearCropper();
            $('#selectPicture').modal('toggle')
        }
    }

    clearCropper() {
        this.invalidSizeImg = false;
        this.cropperShow = false;
        this.selectedAnswer = -1;
        this.fileNameCropped = null;
    }

    selectImg(id){
        this.selectedAnswer = id;
    }
}
