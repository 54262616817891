import { Injectable, NgModule } from '@angular/core';
import { } from '@angular/platform-browser';

@Injectable()

export class RegexProvider {
    constructor() { }

    public validateName(text: string) {
        const regex = /^[A-Z][A-Za-z1-9 ]*$/;
        return regex.test(text);
    }

    public validateEmail(email) {
        const regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        // const regex =  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/    
        return regex.test(email);
    }

    removeAccents(newStringComAcento) {
        var string = newStringComAcento;
        var mapaAcentosHex = {
            //   a : /[\xE0-\xE6]/g,
            //   e : /[\xE8-\xEB]/g,
            //   i : /[\xEC-\xEF]/g,
            //   o : /[\xF2-\xF6]/g,
            //   u : /[\xF9-\xFC]/g,
            //   c : /\xE7/g,
            //   n : /\xF1/g
            a: /[\xE0-\xE6]/g,
            A: /[\xC0-\xC6]/g,
            e: /[\xE8-\xEB]/g,
            E: /[\xC8-\xCB]/g,
            i: /[\xEC-\xEF]/g,
            I: /[\xCC-\xCF]/g,
            o: /[\xF2-\xF6]/g,
            O: /[\xD2-\xD6]/g,
            u: /[\xF9-\xFC]/g,
            U: /[\xD9-\xDC]/g,
            c: /\xE7/g,
            C: /\xC7/g,
            n: /\xF1/g,
            N: /\xD1/g,
        };

        for (var letra in mapaAcentosHex) {
            var expressaoRegular = mapaAcentosHex[letra];
            string = string.replace(expressaoRegular, letra);
        }

        return string;
    }


    public removeHtml(text: string) {
        let newText;;
        const regex = /(<([^>]+)>)/ig;
        const parser = new DOMParser;

        if (text !== null && typeof text !== 'undefined') {
            newText = text.replace(regex, '');
        }

        let finaltext = parser.parseFromString(newText, 'text/html').body.textContent;

        return finaltext;
    }

    removeSpecialCharactersAndSpaces(text: string) {
        let finalString = text.replace(/[^A-Z0-9]/ig, "");
        return finalString;
    }

    removeSpecialCharacters(text: string) {
        let finalString = text.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '');
        return finalString;
    }

    verifyHex(text: string) {
        let str = text.toUpperCase();

        if (str.length == 7) {
            let array = str.split('');
            let valid = true;

            if (array[0] !== '#') {
                valid = false;
            }

            for (let i = 1; i <= 6; i++) {
                let reg = new RegExp("[0-9a-fA-F]");
                let res = reg.test(array[i]);

                if (res == false) {
                    valid = false;
                }
            }

            return valid;

        } else {
            return false;
        }
    }

}