import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
// import { map } from 'rxjs/operators';
// import { Attendee } from 'src/app/models/attendees';
import { PathApi } from 'src/app/paths/path-api';
// import { Observable } from 'rxjs';
import { AngularFirestore } from '@angular/fire/firestore';
// import * as firebase from "firebase/app";

@Injectable({
    providedIn: 'root'
})
export class SymplaIntegrationService {
    customFields: any[] = null;
    attendeesData: any[] = null;

    constructor(
        private httpClient: HttpClient,
        // private firestore: AngularFirestore
    ) {}

    public getEvents(apiToken: string) {
        return this.httpClient.get(
            "https://api.sympla.com.br/public/v4/events",
            { headers: { "s_token": apiToken } }
        ).toPromise();
    }

    private async getAttendees(apiToken: string, symplaEventId: number) {
        const request = (page: number = 1) => {
            return this.httpClient.get(
                `https://api.sympla.com.br/public/v3/events/${symplaEventId}/participants`,
                { 
                    headers: { "s_token": apiToken },
                    params: {
                        'fields': "first_name,last_name,email,event_id,id", 
                        'page_size': "100", // default 100
                        "page": `${page}`
                    }
                }
            ).toPromise();
        }

        const firstPage = await request();
        if (!firstPage) {
            return Promise.reject();
        } else if (firstPage['pagination']['total_page'] > 1) {
            const requests: any[] = [];
            for (let i = 1; i <= firstPage['pagination']['total_page']; i++) {
                requests.push(request(i));
            }
            const promise = new Promise<any>(async (resolve, reject) => {
                const req = await Promise.all(requests);
                if (!req) {
                    reject();
                } else {
                    resolve([firstPage, ...req]);
                }
            });
            return promise;
        }
        return Promise.resolve([firstPage]);


        // const params = new HttpParams();
        // params.set('page_size', "1");
        // // params.set('page', `${page}`);
        // this.httpClient.get(
        //     `https://api.sympla.com.br/public/v3/events/${symplaEventId}/participants`,
        //     { 
        //         headers: { "s_token": apiToken },
        //         params: params
        //     }
        // ).pipe(map((result) => {
        //     // console.log(`result['data']: ${JSON.stringify(result['data'])}`)
        //     // const attendees: Array<any> = result['data'];
        //     // return attendees.map((attendee) => {
        //     //     const tmp = new Attendee(
        //     //         `${attendee.first_name} ${attendee.last_name}`,
        //     //         attendee.email,
        //     //         5,
        //     //         eventId
        //     //     );
        //     //     tmp.$uid = `SYMPLAeid${attendee.event_id}uid${attendee.id}`;
        //     //     return tmp;
        //     // })
        // })).toPromise();
    }

    /**
     * @returns a json object like with the fallowing properties: (status: "200"
     * or "400" or "500"), (message: string), (result: array of created attendee IDs 
     * (on success) or array of type Attendee with the those not created (on 
     * failure with code 500))
     */
    private createAttendees(eventId: string, attendeePage: any[], attendeeModuleId: string, eventLanguage: string) {
        const headers = new HttpHeaders();
        headers.append("Accept", 'application/json');
        headers.append("Content-Type", 'application/json');
        const requestOptions = { headers: headers };

        const body = {
            eventId: eventId,
            attendeePage: attendeePage,
            attendeeModuleId: attendeeModuleId,
            language: eventLanguage
        }
        return this.httpClient.post(PathApi.baseUrl + "dbAttendeesSymplaImport", body, requestOptions).toPromise()
    }

    public async importSymplaAttendees(
        apiToken: string, 
        symplaEventId: number,
        eventId: string,
        attendeeModuleId: string,
        eventLanguage: string
    ) {
        const result = await this.getAttendees(apiToken, symplaEventId);
        if (!result) {
            return Promise.reject();
        }

        const attendeePages = result.map(r => r["data"]);

        return Promise.all(attendeePages.map((attendeePage) => {
            return this.createAttendees(
                eventId,
                attendeePage,
                attendeeModuleId,
                eventLanguage
            )
        }));
    }

    // public async importAttendees(
    //     page: number, 
    //     pageLength: number, 
    //     attendeesData: JSON[], 
    //     attendeeModuleId: string,
    //     eventId: string,
    //     language: string
    // ) {
    //     if (!this.customFields || ) {
    //         const customFields = await this.firestore
    //             .collection(`modules/${attendeeModuleId}/customFields`)
    //             .get()
    //             .toPromise();

    //         if (!customFields) { return Promise.reject(); }
    //         this.customFields = customFields[0].docs.map(custom => custom.data());
    //     }

    //     if (!this.attendeesData) {
    //         const attendees = this.firestore
    //             .collection(`events/${eventId}/attendees`)
    //             .get()
    //             .toPromise();
    //     }

    //     firebase.default.firestore.FieldValue.arrayUnion()

    // }
}
