import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from '../../providers/auth/auth.service';
import { PathApi } from '../../paths/path-api';
import firebase from 'firebase/app';
import { AngularFirestore } from '@angular/fire/firestore';
import { TypeModule } from 'src/app/enums/type-module';
import { Question } from '../../../app/models/ask-question';
import { resolve } from 'q';

@Injectable({
    providedIn: 'root'
})
export class DbWordCloudProvider {
    public headers;
    public requestOptions;
    public db;

    constructor(private auth: AuthService, private http: HttpClient, private aFirestore: AngularFirestore) {
        this.headers = new HttpHeaders();
        this.db = aFirestore.firestore;
        this.headers.append("Accept", 'application/json');
        this.headers.append("Content-Type", 'application/json');
        this.requestOptions = { headers: this.headers };
    }

    getWordCloudModule(eventId: string, onResolve) {
        let db = this.aFirestore.firestore;
        let ref = db.collection('events').doc(eventId).collection('modules');
        
        ref
        .where('type', '==', TypeModule.WORDCLOUD)
        .get()
        .then((snapshot) => {
            let auxModule = [];
            if (snapshot.size >= 1) {
                snapshot.forEach(element => {
                    auxModule.push(element.data());
                });
            }
            onResolve(auxModule);
        })
        .catch((e) => {
            console.log();
            onResolve(e);
        })
    }

    createWordCloud(moduleId, wordCloud, onResolve) {
        let db = this.aFirestore.firestore;

        let ref = db.collection('modules').doc(moduleId).collection('wordClouds').doc();
        wordCloud.uid = ref.id;

        wordCloud = Object.assign({}, wordCloud);

        ref.set(wordCloud).then(() => {
            onResolve(true);
        })
    }

    getAllWordCloud(moduleId, onResolve) {
        let db = this.aFirestore.firestore;

        let ref = db.collection('modules').doc(moduleId).collection('wordClouds');

        ref.onSnapshot((data) => {
        // ref.get().then((data) => {

            let listClouds = [];
            data.forEach(element => {
                let cloud = element.data();
                listClouds.push(cloud);
            });

            console.log(listClouds)
            onResolve(listClouds)
        })
    }

    getWords(moduleId: string, cloudId: string, onResolve) {
        let db = this.aFirestore.firestore;

        let ref = db
        .collection('modules')
        .doc(moduleId)
        .collection('wordClouds')
        .doc(cloudId)
        .collection('words');

        let listWords = [];

        ref.get().then((data) => {
            console.log(data)
            if(data.size > 0) {
                data.forEach(element => {
                    listWords.push(element.data());
                });

                onResolve(listWords)
            } else {
                onResolve(listWords)
            }
        })
    }

    editWordCloud(moduleId, cloudId, cloud, onResolve) {
        let db = this.aFirestore.firestore;

        cloud = Object.assign({}, cloud);
        let ref = db.collection('modules').doc(moduleId).collection('wordClouds').doc(cloudId);

        ref.update(cloud)
        .then(() => {
            onResolve(true)
        })
        .catch(() => {
            onResolve(false);
        })
    }

    setWordCloudVisibility(moduleId, cloudId, status, onResolve) {
        console.log(status)
        let db = this.aFirestore.firestore;
        let ref = db.collection('modules').doc(moduleId).collection('wordClouds').doc(cloudId);
        
        ref.update({ visibility: status })
        .then(() => {
            console.log('Resolve TRUE')
            onResolve(true);
        })
        .catch(() => {
            console.log('Resolve FALSE')
            onResolve(false);
        })
    }

    exportCloud(moduleId: string, cloudId: string, onResolve) {
        this.http.get(PathApi.baseUrl + PathApi.dbWordCloudExport + '?moduleId=' + moduleId + '&cloudId=' + cloudId, this.requestOptions)
        .subscribe((data) => {
            onResolve(data)
        },
        (error) => {
            onResolve(error);
        })
    }

    clearResult(moduleId: string, cloudId: string, onResolve) {
        this.http.delete(PathApi.baseUrl + PathApi.dbWordCloudClearResults + '?moduleId=' + moduleId + '&cloudId=' + cloudId, this.requestOptions)
        .subscribe((data) => {
            onResolve(data);
        })
    }

    deleteWordCloud(moduleId: string, cloudId: string, onResolve) {
        this.http.delete(PathApi.baseUrl + PathApi.dbWordCloudDelete + '?moduleId=' + moduleId + '&cloudId=' + cloudId, this.requestOptions)
        .subscribe((data) => {
            onResolve(data);
        })
    }
}