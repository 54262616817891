export class Participant {
  uid: string;
  name: string;
  creator: boolean;
  email?: string;
  emailRecovery?: string;
  avatar?: string;


  constructor(
    uid: string,
    name: string,
    creator: boolean,
    email: string = "",
    emailRecovery: string = "",
    avatar: string = ""
  ) {
    this.uid = uid;
    this.name = name;
    this.email = email;
    this.emailRecovery = emailRecovery;
    this.creator = creator;
    this.avatar = avatar;
  }
}
