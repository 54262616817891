import { Module } from '../modules/module';
import { TypeModule } from '../../enums/type-module';

export class ModuleWordCloud extends Module {
    typeVision: number;
    access_groups: any; // uid of the group that is allowed access to the module
    selected: boolean;
    visOnlyForLoggedUser?: boolean;


    constructor(name: any, icon: string, typeVision: number, eventId: string, order: number, visOnlyForLoggedUser?: boolean) {
        super(name, icon, TypeModule.WORDCLOUD, eventId, order, false, false, false)
        this.typeVision = typeVision;
        this.access_groups = {};
        this.selected = false;
        if (visOnlyForLoggedUser === undefined) {
            this.visOnlyForLoggedUser = false;
        } else {
            this.visOnlyForLoggedUser = visOnlyForLoggedUser;
        }
    }
}