import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { DbCustomPagesProvider } from 'src/app/providers/database/db-custom-pages';
import { GlobalService } from 'src/app/providers/global/global.service';
import { Languages } from 'src/app/models/languages';
import { DbEventsProvider } from 'src/app/providers/database/db.events';
import { Location } from '@angular/common';
import { icons, MenuIcons } from 'src/app/models/menu-icons';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { EmailEditorComponent } from "angular-email-editor";
import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { StorageService } from 'src/app/providers/storage/storage.service';
import { TranslateService } from '@ngx-translate/core';
import { RegexProvider } from 'src/app/providers/regex/regex.service';
import { UUID } from 'angular2-uuid';
import { Image } from 'src/app/models/image';
import { PathStorage } from 'src/app/paths/path-storage';

declare var $: any;

@Component({
    selector: 'app-custom-page-edit',
    templateUrl: './custom-page-edit.component.html',
    styleUrls: ['./custom-page-edit.component.scss']
})

export class CustomPageEditComponent implements OnInit {
    iconsImg: MenuIcons[] = icons;
    base64CustomIcon: SafeResourceUrl = null;
    selectedIconEdit: string = null
    selectedIconEditFamily: string = null;
    customIconFile = null;
    imageType: string = null;
    public module = null

    // bloc editor
    emailEditorActive: boolean;
    editedContent;
    pageContent: any;
    emptyTemplate = {
        counters: { u_column: 1, u_row: 1, u_content_text: 1, u_content_heading: 1 },
        body: {
            rows: [
                {
                    cells: [1],
                    columns: [
                        {
                            contents: [],
                            values: {
                                backgroundColor: "#FFFFFF",
                                padding: "0px",
                                border: {},
                                _meta: { htmlID: "u_column_1", htmlClassNames: "u_column" },
                            },
                        },
                    ],
                    values: {
                        displayCondition: null,
                        columns: false,
                        backgroundColor: "#FFFFFF",
                        columnsBackgroundColor: "#FFFFFF",
                        backgroundImage: {
                            url: "",
                            fullWidth: true,
                            repeat: false,
                            center: true,
                            cover: false,
                        },
                        padding: "0px",
                        hideDesktop: false,
                        _meta: { htmlID: "u_row_1", htmlClassNames: "u_row" },
                        selectable: true,
                        draggable: true,
                        duplicatable: true,
                        deletable: true,
                        hideable: true,
                    },
                },
            ],
            values: {
                textColor: "#000000",
                backgroundColor: "#FFFFFF",
                backgroundImage: {
                    url: "",
                    fullWidth: true,
                    repeat: false,
                    center: true,
                    cover: false,
                },
                contentWidth: "1200px",
                contentAlign: "center",
                fontFamily: { label: "Arial", value: "arial,helvetica,sans-serif" },
                preheaderText: "",
                linkStyle: {
                    body: true,
                    linkColor: "#0000ee",
                    linkHoverColor: "#0000ee",
                    linkUnderline: true,
                    linkHoverUnderline: true,
                },
                _meta: { htmlID: "u_body", htmlClassNames: "u_body" },
            },
        },
        schemaVersion: 6,
    };

    @ViewChild(EmailEditorComponent)
    private emailEditor: EmailEditorComponent;

    // get the language of the user.
    public userLanguage: string

    public eventId: string = this.route.parent.params['_value']['uid'];

    public moduleId: string = this.route.snapshot.params['moduleId'];
    public pageId: string = this.route.snapshot.params['pageId']

    pageEdit = null
    htmlContent = {
        PtBR: '',
        EnUS: '',
        EsES: '',
        FrFR: '',
        DeDE: ''
    };
    title = {
        PtBR: '',
        EnUS: '',
        EsES: '',
        FrFR: '',
        DeDE: ''
    };
    languages: Languages = null //event languages
    event: any;
    principalEventLanguageFormated: string = 'PtBR';
    activeCreateLanguage: string = 'PtBR';
    loader: boolean

    @ViewChild('successSwal') public successSwal: SwalComponent;
    @ViewChild('errorSwal') public errorSwal: SwalComponent;


    invalidSize: boolean = false;
    translateMsg: string;
    dropConfig: DropzoneConfigInterface = {
        // Change this to your upload POST address:
        url: 'https://us-central1-high5event-a48b5.cloudfunctions.net/utilitiesDropzoneUpload',
        maxFilesize: 50,
        acceptedFiles: 'image/*',
        autoReset: 15000,
        maxFiles: 1
    }

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private translateService: TranslateService,
        private storage: StorageService,
        private regex: RegexProvider,
        private dbCustomPage: DbCustomPagesProvider,
        private global: GlobalService,
        private dbEvent: DbEventsProvider,
        public location: Location,
        private sanitizer: DomSanitizer
    ) {
        this.translateMsg = this.translateService.instant('comp.gallery.dragdrop_msg');
    }

    ngOnInit() {
        this.loadPage();
        this.getModule();
        this.getUserLanguage();
        this.startEvent();
    }

    // get the language of the user.
    getUserLanguage() {
        this.global.getLanguage((language) => {
            this.userLanguage = language
        })
    }

    startEvent() {
        this.dbEvent.getEvent(this.eventId, (event) => {
            this.event = event;
            this.principalEventLanguageFormated = this.convertLangFormat(this.event.language);
            this.activeCreateLanguage = this.principalEventLanguageFormated;
            this.languages = event.languages;
        });
    }

    getModule() {
        this.dbCustomPage.getModule(this.moduleId, (module) => {
            this.module = module;
        });
    }

    // loadPage() {
    //   this.loader = true

    //   this.dbCustomPage.getPage(this.moduleId, this.pageId, (page) => {
    //     if (typeof page !== 'undefined' && page !== null) {
    //       this.pageEdit = page
    //       this.htmlContent = page.htmlContent
    //       this.title = page.title
    //       this.selectedIconEdit = page.icon
    //       this.base64CustomIcon = this.sanitizer.bypassSecurityTrustResourceUrl(page.icon)
    //       this.selectedIconEditFamily = page.iconFamily
    //       this.emailEditorActive = this.pageEdit.emailEditorActive !== undefined ? this.pageEdit.emailEditorActive : false;
    //       console.log(this.pageEdit)
    //     }

    //     this.loader = false
    //   })
    // }

    async loadPage() {
        this.loader = true;
        const page = await this.dbCustomPage.getPagePromise(this.moduleId, this.pageId);
        this.pageEdit = page ? page.data() : {};

        this.htmlContent = page.data().htmlContent
        this.pageContent = page.data() ? page.data().pageContent : {};
        this.title = page.data().title
        this.selectedIconEdit = page.data().icon
        this.base64CustomIcon = this.sanitizer.bypassSecurityTrustResourceUrl(page.data().icon)
        this.selectedIconEditFamily = page.data().iconFamily
        this.emailEditorActive = this.pageEdit.emailEditorActive !== undefined ? this.pageEdit.emailEditorActive : false;
        console.log(this.pageEdit)

        this.loader = false;
    }

    editorLoaded() {
        const design = this.pageContent ? JSON.parse(this.pageContent)
            : this.emptyTemplate;

        setTimeout(() => {
            this.emailEditor.loadDesign(design)
        }, 500)
    }

    errorPrincipalLanguageBlank: boolean = false;
    updatePage() {
        this.loader = true

        this.errorPrincipalLanguageBlank = false;
        this.pageEdit.title = this.title;
        this.pageEdit.icon = this.selectedIconEdit;
        this.pageEdit.emailEditorActive = this.emailEditorActive;

        if (this.pageEdit.icon && this.pageEdit.icon.length > 1048487) {
            console.error('Icon too large !!')
            this.errorSwal.fire();
            return;
        }
        if (this.selectedIconEditFamily !== null) this.pageEdit.iconFamily = this.selectedIconEditFamily
        if (this.pageEdit.title[this.principalEventLanguageFormated] !== '') {
            for (let lang in this.pageEdit.title) {
                if (this.pageEdit.title[lang] == '') {
                    this.pageEdit.title[lang] = this.pageEdit.title[this.principalEventLanguageFormated];
                }
            }
        } else {
            this.errorPrincipalLanguageBlank = true;
        }

        if (this.emailEditorActive) {
            // Bloc editor used
            // update 
            this.emailEditor.editor.exportHtml((data) => {
                const design = JSON.stringify(data.design);
                let htmlContent = data.chunks.body;
                //   let htmlContent = data.chunks.body;
                htmlContent = htmlContent.trim();
                this.pageEdit.pageHTMLContent = htmlContent;
                this.pageEdit.pageContent = design;

                this.dbCustomPage.updatePage(this.pageEdit).then((success) => {
                    this.successSwal.fire();
                    this.loader = false;
                    return;
                    // this.location.back();
                }).catch((error) => {
                    this.errorSwal.fire();
                    this.loader = false;
                })
            });
        } else {
            // ckeditor user
            const contentHtml = {
                PtBR: this.htmlContent.PtBR.replace(/href="/g, 'class="wysiwyg-link" href="'),
                EnUS: this.htmlContent.EnUS.replace(/href="/g, 'class="wysiwyg-link" href="'),
                EsES: this.htmlContent.EsES.replace(/href="/g, 'class="wysiwyg-link" href="'),
                FrFR: this.htmlContent.FrFR.replace(/href="/g, 'class="wysiwyg-link" href="'),
                DeDE: this.htmlContent.DeDE.replace(/href="/g, 'class="wysiwyg-link" href="')
            }
            this.pageEdit.htmlContent = contentHtml;
            this.dbCustomPage.updatePage(this.pageEdit).then((success) => {
                this.successSwal.fire();
                this.loader = false;
                // this.location.back();
            }).catch((error) => {
                this.errorSwal.fire();
                this.loader = false;
            })
        }
    }


    redirectList() {
        this.router.navigate(['/event/' + this.eventId + '/custom-pages/' + this.moduleId]);
    }

    convertLangFormat(lang) {
        let formatedLang;
        switch (lang) {
            case 'pt_BR': {
                formatedLang = 'PtBR'
                break;
            }
            case 'en_US': {
                formatedLang = 'EnUS';
                break;
            }
            case 'es_ES': {
                formatedLang = 'EsES';
                break;
            }
            case 'fr_FR': {
                formatedLang = 'FrFR';
                break;
            }
            case 'de_DE': {
                formatedLang = 'DeDE';
                break;
            }
        }
        return formatedLang;
    }

    setIconEdit(index) {
        this.selectedIconEdit = this.iconsImg[index].icon;
        this.selectedIconEditFamily = this.iconsImg[index].family;
        this.base64CustomIcon = null;
        this.customIconFile = null;
        this.imageType = null;
        $('.dropdown-menu').removeClass('show');
    }

    // use custom icon image
    importCustomIcon($ev) {
        this.customIconFile = null;
        this.imageType = null;
        this.base64CustomIcon = null;
        this.customIconFile = $ev.srcElement.files[0];
        this.imageType = this.customIconFile.type;
        if (this.customIconFile !== null) {
            const reader = new FileReader();
            reader.onload = this.handleReaderLoaded.bind(this);
            reader.readAsBinaryString(this.customIconFile);
        }
    }
    // handle image to base64 to display for use before upload to storage
    handleReaderLoaded(e) {
        let imgType: string;

        if (this.imageType == 'image/gif') {
            imgType = 'data:image/gif;base64,';
        } else if (this.imageType == 'image/png') {
            imgType = 'data:image/png;base64,';
        } else if (this.imageType == 'image/jpg') {
            imgType = 'data:image/jpg;base64,';
        } else if (this.imageType == 'image/jpeg') {
            imgType = 'data:image/jpeg;base64,';
        } else {
            imgType = 'data:image/svg+xml;base64,'
        }
        // imgType = `data:${this.imageType};base64,`
        try {
            this.base64CustomIcon = this.sanitizer.bypassSecurityTrustResourceUrl(`${imgType}${btoa(e.target.result)}`);;
        } catch (error) {
            console.log(error)
        }

        this.selectedIconEdit = `${imgType}${btoa(e.target.result)}`;
        this.selectedIconEditFamily = 'custom-icon';
        // this.selectedEditIcon = this.base64CustomIcon;
        // this.selectedEditIconFamily = 'custom-icon';
    }


    // função para upload de img de capa para a custom page
    upload(fileList: FileList) {
        this.invalidSize = false;
        // arquivo
        const file = fileList[0];
        let name = fileList[0].name;
        let aux = name.split('.');
        const imgName = aux[0].toString().toUpperCase();
        const finalName = this.regex.removeAccents(imgName);
        const type = aux[1];
        let imgId = UUID.UUID();

        // VERIFICA O TAMANHO DA IMAGEM
        if (file.size <= this.event.gallery_file_limit) {
            this.storage.uploadImg(file, this.eventId, this.moduleId, this.pageId, imgId, PathStorage.customPages ,(url) => {
                let image = new Image();
                image.name = finalName;
                image.url = url;
                image.storageId = imgId;
                image.type = type;
                image.createdAt = Date.now() / 1000 | 0;
                this.dbCustomPage.saveCoverImage(this.eventId, this.moduleId, this.pageId, image, (status) => {
                    if (!status) {
                        this.storage.deleteImg(this.eventId, this.moduleId, this.pageId, imgId, PathStorage.customPages);
                    }
                });
            });
        } else {
            this.invalidSize = true;
        }

    }

}
