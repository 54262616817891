import { Component, OnInit, ViewChild, NgModule } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { Question } from 'src/app/models/training-question';
import { Answer } from 'src/app/models/training-answer';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { Track } from 'src/app/models/track';
import { Session } from 'src/app/models/session';
import { Group } from 'src/app/models/group';
import { TypeTraining } from 'src/app/models/type-training';
import { DbEventsProvider } from 'src/app/providers/database/db.events';
import { DbScheduleProvider } from 'src/app/providers/database/db-schedule';
import { DbGroupsProvider } from 'src/app/providers/database/db-groups';
import { DbTrainingProvider } from 'src/app/providers/database/db-training';
import { Training } from 'src/app/models/training';
import { MenuIcons, icons } from '../../../../../../models/menu-icons';
import { PathIcons } from 'src/app/paths/path-icons';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { GlobalService } from 'src/app/providers/global/global.service';
import { Languages } from 'src/app/models/languages';
import { NameModule } from 'src/app/models/name-module';
import { GetNameSession } from 'src/app/pipes/get-name-session';
import { GetNameTrack } from 'src/app/pipes/get-name-track';
import { FormatedMultiIdiomaService } from 'src/app/providers/formated-multi-idioma/formated-multi-idioma.service';

declare let $: any;

@Component({
  selector: 'app-edit-training',
  templateUrl: './edit-training.component.html',
  styleUrls: ['./edit-training.component.scss']
})

@NgModule({
  declarations: [
    GetNameSession
  ],
})

export class EditTrainingComponent implements OnInit {

  public userLanguage: string   // get the language of the user.
  public eventLanguage: string // get the language of the event.


  @ViewChild('successSwal') public successSwal: SwalComponent;
  @ViewChild('errorDeleteLastQuestion') public errorDeleteLastQuestion: SwalComponent;

  eventId: string = null;
  eventTimezone: string = null;
  moduleId: string = null;
  trainingId: string = null;
  languages: Languages = null //event languages
  activeLanguage: string = null;

  public questions: Array<Question> = [];
  public answers: Array<Answer> = [];
  public typeQuestion: string;
  public typeNewQuestion: string = 'oneSelect';

  public listAuxInput: Array<any> = [];
  loaderQuestions: Array<boolean> = [];

  questionId: string = null;
  iconsImg: MenuIcons[] = icons;
  selectedIcons;
  selectedIcon: string = null;
  selectedIconFamily: string = null;
  formGroup: FormGroup;
  formEditQuestion: FormGroup;
  formNewQuestion: FormGroup;
  addQuestionShow: boolean = false;
  editQuestionShow: boolean = false;
  allowShowAddQuestion: boolean = true;

  editQuestionIndex: number = null;
  correctAnswerNewQuestion: number;

  blockGraphicTypevisibilityEdit = true;
  blockOptionsAnswersVisibilityEdit = true;
  blockGraphicTypevisibilityCreate = true;
  blockOptionsAnswersVisibilityCreate = true;
  activeMarker: boolean = false;
  activeWeight: boolean = false;

  public errorTitleEdit = false;
  public errorSelectQuestionEdit = false;
  public errorOptionAnswerEdit = false;
  public errorSelectChartTypeEdit = false;
  public errorTitleCreate = false;
  public errorSelectQuestionCreate = false;
  public errorOptionAnswerCreate = false;
  public errorSelectChartTypeCreate = false;

  training;
  trainingTitle;
  trainingIcon;

  fileNew: any = null;
  fileNameNew: string = null;
  fileEdit: any = null;
  fileNameEdit: string = null;

  activeTimerEdit = false;
  // trainingMaxResponses;
  // trainingChangeAnswer = false;
  // trainingMaxResponsesVisibility = false;

  trainingLinkedToSession: boolean = false;
  SelectedSessionVisibility: boolean = false;
  tracksAttendeeVisibility: boolean = false;
  specificTrackVisibility: boolean = false;
  scheduleModuleVisibility: boolean = false;
  scheduleModuleTrackVisibility: boolean = false;
  scheduleModuleSessionVisibility: boolean = false;
  specificGroupVisibility: boolean = false;

  // tracks
  tracks: Array<Track>;
  public group = []; // array de ids
  public query = ''; // string digitada pelo usuário
  public filteredList = []; // lista de sugestões exibidas
  public selectedTracks = []; // array com os tracks selecionados

  // sessions
  sessions: Array<Session>;
  sessionsNames: Array<any>;
  public session = [];
  public querySession = ''; // string digitada pelo usuário
  public filteredSession = []; // lista de sugestões exibidas
  public selectedSessions = []; // array com os tracks selecionados

  // groups
  groups: Array<Group>;
  groupsNames: Array<any>;
  public ManagerGroup = []; // array de ids
  public queryGroup = ''; // string digitada pelo usuário
  public filteredGroup = []; // lista de sugestões exibidas
  public selectedGroups = []; // array com os tracks selecionados

  scheduleModules: Array<any> = [];
  trainingType: string = TypeTraining.Pattern;
  scheduleModuleTrainingEdit: string = null;
  scheduleModuleTrackTrainingEdit: string = null;
  scheduleModuleSessionTrainingEdit: string = null;


  listAnswers: Array<Answer> = [];

  listNewQuestion: Array<Answer> = [];

  listDeleteInputAnswers: Array<any> = [];

  constructor(
    private location: Location,
    private route: ActivatedRoute,
    private dbEvent: DbEventsProvider,
    private dbSession: DbScheduleProvider,
    private dbGroup: DbGroupsProvider,
    private dbTraining: DbTrainingProvider,
    private fb: FormBuilder,
    private router: Router,
    private global: GlobalService,
    private formatIdioma: FormatedMultiIdiomaService
  ) {
    this.eventId = this.eventId = this.route.pathFromRoot[1]['params']['_value']['uid'];
    this.moduleId = this.route.snapshot.params['moduleId'];
    this.trainingId = this.route.snapshot.params['trainingId'];

    this.load();

    this.formGroup = new FormGroup({
      training_name_pt_br: new FormControl(''),
      training_name_en_us: new FormControl(''),
      training_name_es_es: new FormControl(''),
      training_name_fr_fr: new FormControl(''),
      training_name_de_de: new FormControl(''),
      // maxResponses: new FormControl(''),
      activeTimerEdit: new FormControl(''),
      maxTimerEdit: new FormControl(''),
      changeAnswer: new FormControl(''),
      viewAnswered: new FormControl('')
    });


    /**
     * Formulario editQuestion
     */
    this.formEditQuestion = this.fb.group({
      'title_pt_br': [''],
      'title_en_us': [''],
      'title_es_es': [''],
      'title_fr_fr': [''],
      'title_de_de': [''],
      'infobooth_pt_br': [''],
      'infobooth_en_us': [''],
      'infobooth_es_es': [''],
      'infobooth_fr_fr': [''],
      'infobooth_de_de': [''],
      'explanation_pt_br': [''],
      'explanation_en_us': [''],
      'explanation_es_es': [''],
      'explanation_fr_fr': [''],
      'explanation_de_de': [''],
      'radioTypeQuestion': [null],
      'radioTypeGraphic': [null]
    });

    /**
     * Formulario create New Question
     */
    this.formNewQuestion = this.fb.group({
      'title_pt_br': [''],
      'title_en_us': [''],
      'title_es_es': [''],
      'title_fr_fr': [''],
      'title_de_de': [''],
      'infobooth_pt_br': [''],
      'infobooth_en_us': [''],
      'infobooth_es_es': [''],
      'infobooth_fr_fr': [''],
      'infobooth_de_de': [''],
      'explanation_pt_br': [''],
      'explanation_en_us': [''],
      'explanation_es_es': [''],
      'explanation_fr_fr': [''],
      'explanation_de_de': [''],
      'radioTypeQuestion': [null],
      'radioTypeGraphic': [null]
    });

    this.listNewQuestion.push(new Answer());

    // //Seta o icone inicial
    let initialIcon = {
      icon: PathIcons.icon_training,
      family: 'material-icons'
    }
    this.setIcon(initialIcon);

    this.getTrainingOptions();

    this.dbTraining.getQuestions(this.moduleId, this.trainingId, (list) => {
      console.log(list)
      this.questions = list;
      this.loaderQuestions = Array(this.questions.length).fill(false);
    })
  }

  ngOnInit() {
    $("label.icon-selector").click(function () {
      $("#dropdownMenuButton").dropdown("toggle");
    });

    this.getEvent();
    this.getUserLanguage()
  }

  // get the language of the user.
  getUserLanguage() {
    this.global.getLanguage((language) => {
      this.userLanguage = language
    })
  }

  getEvent() {
    this.dbEvent.getEvent(this.eventId, (event) => {
      this.eventTimezone = event.timezone;
      this.eventLanguage = event.language;
      this.languages = event.languages;
      this.activeLanguage = this.formatIdioma.convertLangFormat(event.language);
    })
  }

  setIcon(item) {
    this.selectedIcon = item.icon;
    this.selectedIconFamily = item.family;
    $('.dropdown-menu').removeClass('show');
  }

  onUploadChangeNew($ev) {
    this.fileNew = $ev.srcElement.files[0];
    this.fileNameNew = this.fileNew.name;
  }

  onUploadChangeEdit($ev) {
    this.fileEdit = $ev.srcElement.files[0];
    this.fileNameEdit = this.fileEdit.name;
  }

  getTrainingOptions() {
    this.dbTraining.getTraining(this.moduleId, this.trainingId, (retorno) => {
      this.training = retorno;
      this.training.weight !== undefined ? this.activeWeight = this.training.weight : this.training.weight = false;
      this.training.marker !== undefined ? this.activeMarker = this.training.marker : this.training.marker = false;

      if (this.training.active_timer) {
        this.activeTimerEdit = true;
      }

      this.setValuesTraining();
    })
  }

  setValuesTraining() {
    if (this.training.type != TypeTraining.Pattern) {
      this.trainingLinkedToSession = true;

      this.trainingType = this.training.type;
      this.changeSessionType();

      switch (this.trainingType) {
        case TypeTraining.ScheduleModule:
          this.scheduleModuleTrainingEdit = this.training.module_id;
          break;

        case TypeTraining.SessionTrack:
          this.scheduleModuleTrackTrainingEdit = this.training.module_id;
          this.specificTrackVisibility = true;
          this.loadTracks(this.scheduleModuleTrackTrainingEdit);

          for (let trackId of this.training.references) {
            this.dbSession.getTrackModule(this.scheduleModuleTrackTrainingEdit, trackId, (track) => {
              let notFound = true;
              for (let aux of this.selectedTracks) {
                if (aux.uid == track.uid) {
                  notFound = false;
                }
              }

              if (notFound == true) {
                this.selectedTracks.push(track);
              }

            })
          }

          break;

        case TypeTraining.SpecificGroup:
          this.specificGroupVisibility = true;
          this.loadGroups();

          for (let groupId of this.training.references) {
            //marcador
            this.dbGroup.searchModulesAndGroups(this.eventId, (data) => {
              let groups = data['groups'];

              let notFound = true;
              for (let group of groups) {
                if (group.uid == groupId) {

                  for (let aux of this.selectedGroups) {
                    if (aux.uid == group.uid) {
                      notFound = false;
                    }
                  }

                  if (notFound) {
                    this.selectedGroups.push(group);
                  }

                  break;
                }
              }
            })
          }
          break;

        case TypeTraining.SpecificSession:
          this.scheduleModuleSessionTrainingEdit = this.training.module_id;
          this.SelectedSessionVisibility = true;
          this.loadSessions(this.scheduleModuleSessionTrainingEdit);

          for (let sessionId of this.training.references) {
            this.dbSession.getSession(sessionId, this.eventId, (session: Session) => {

              let notFound = true;
              for (let aux of this.selectedSessions) {
                if (aux.uid == session.uid) {
                  notFound = false;
                }
              }

              if (notFound == true) {
                this.selectedSessions.push(session)
              }
            })
          }

          break;
      }
    }

    this.formGroup.patchValue({
      training_name_pt_br: this.training.title.PtBR,
      training_name_en_us: this.training.title.EnUS,
      training_name_es_es: this.training.title.EsES,
      training_name_fr_fr: this.training.title.FrFR,
      training_name_de_de: this.training.title.DeDE,
      changeAnswer: this.training.change_answer,
      viewAnswered: this.training.view_answered,
      activeTimerEdit: this.training.active_timer,
      maxTimerEdit: this.training.timer_questions
    })

    this.activeTimerEdit = this.training.active_timer;
  }

  changeActiverTimer() {
    let value = this.formGroup.controls.activeTimerEdit.value;

    if (value) {
      this.activeTimerEdit = true;
    } else {
      this.activeTimerEdit = false;
    }
  }

  // changeMaxResponses() {
  //   var valor = this.trainingMaxResponsesVisibility;

  //   if (valor == true) {
  //     this.trainingMaxResponsesVisibility = false;

  //     this.formGroup.patchValue({
  //       maxResponses: null
  //     })

  //   } else {
  //     this.trainingMaxResponsesVisibility = true;
  //   }
  // }

  // checkUpdateTraining(data) {
  //   if (this.training.type != this.trainingType) {
  //     //Exibe o modal de confirmação de criação
  //     $("#modalUpdateTrainingConfirm").modal("show");
  //   } else {
  //     this.updateTraining(data, false);
  //   }
  // }

  updateTraining(data) {
    let training = new Training();

    if (data.maxTimerEdit == undefined) {
      data.maxTimerEdit = null;
    }

    training.icon = this.selectedIcon;
    training.iconFamily = this.selectedIconFamily;
    let aux = new NameModule(data.training_name_pt_br, data.training_name_en_us, data.training_name_es_es, data.training_name_fr_fr, data.training_name_de_de);
    training.title = this.formatIdioma.updateObjectMultiLanguages(this.training.title, aux, this.eventLanguage);
    training.change_answer = data.changeAnswer;
    training.view_answered = data.viewAnswered;
    training.active_timer = data.activeTimerEdit;
    training.timer_questions = data.maxTimerEdit;
    training.type = this.trainingType;
    training.weight = this.activeWeight;
    training.marker = this.activeMarker;

    switch (training.type) {
      case TypeTraining.Pattern:
        training.module_id = null;
        training.references = null;
        break;

      case TypeTraining.AllSessions:
        training.module_id = null;
        training.references = null;
        break;

      case TypeTraining.ScheduleModule:
        training.module_id = this.scheduleModuleTrainingEdit;
        training.references = null;
        break;

      case TypeTraining.SessionTrack:
        training.module_id = this.scheduleModuleTrackTrainingEdit;
        this.dbTraining.forTrainingReferences(this.selectedTracks, this.trainingId, this.moduleId);
        break;

      case TypeTraining.SpecificSession:
        training.module_id = this.scheduleModuleSessionTrainingEdit;
        this.dbTraining.forTrainingReferences(this.selectedSessions, this.trainingId, this.moduleId);
        break;

      case TypeTraining.SpecificGroup:
        training.module_id = null;
        this.dbTraining.forTrainingReferences(this.selectedGroups, this.trainingId, this.moduleId);
        break;
    }

    this.dbTraining.updateTraining(this.moduleId, this.trainingId, training, (data) => {
      if (data == true) {
        this.successSwal.fire();

        setTimeout(() => {
          this.router.navigate([`/event/${this.eventId}/interactivity/training/${this.moduleId}`]);
        }, 500);
      }
    });

  }

  /**
   * Abre Collapse da questao por index
   */
  openQuestion(index) {
    /**
     * get info answers for index
     */
    this.editQuestionIndex = index;
    this.editQuestionShow = true;
    this.allowShowAddQuestion = false;
    for (const i in this.questions) {
      if (i == index) {
        this.questionInfo(this.questions[i]);
        this.loadAnswer(this.questions[i].uid);
        window.scroll({
          top: 160,
          behavior: 'smooth'
        })
        break;
      }
    }
  }

  resetEditQuestion() {
    this.editQuestionShow = false;
    this.allowShowAddQuestion = true;
    this.formEditQuestion.reset();
  }

  /***
   * Delete question
   */
  deleteQuestion(index) {
    this.loaderQuestions[index] = true;

    if (this.questions.length > 1) {
      const deleteId = this.questions[index].uid;
      this.dbTraining.removeQuestion(this.moduleId, this.trainingId, deleteId, (status) => {
        if (status.code == 200) {
          this.questions.splice(index, 1);
          this.loaderQuestions[index] = false;
          this.loaderQuestions.splice(index, 1);
          this.successSwal.fire();
        }
      })
    } else {
      this.errorDeleteLastQuestion.fire();
      this.loaderQuestions[index] = false;
    }
    // this.daoTraining.deleteQuestion(this.trainingId, deleteId);
  }

  /**
   * Pega os valores da Questions
   */
  questionInfo(data) {
    this.typeQuestion = data.type;

    switch (data.type) {
      case 'oneselect':
        // this.blockGraphicTypevisibilityEdit = true;
        // this.blockOptionsAnswersVisibilityEdit = true;

        if (this.listAnswers.length <= 0) {
          this.listAnswers.push(new Answer());
        }
        break;

      case 'multipleSelect':
        // this.blockGraphicTypevisibilityEdit = true;
        // this.blockOptionsAnswersVisibilityEdit = true;

        if (this.listAnswers.length <= 0) {
          this.listAnswers.push(new Answer());
        }
        break;
    }
    this.questionId = data.uid;
    this.formEditQuestion.reset();
    this.formEditQuestion.patchValue({
      title_pt_br: data.title.PtBR,
      title_en_us: data.title.EnUS,
      title_es_es: data.title.EsES,
      title_fr_fr: data.title.FrFR,
      title_de_de: data.title.DeDE,
      infobooth_pt_br: data.infobooth.PtBR,
      infobooth_en_us: data.infobooth.EnUS,
      infobooth_es_es: data.infobooth.EsES,
      infobooth_fr_fr: data.infobooth.FrFR,
      infobooth_de_de: data.infobooth.DeDE,
      explanation_pt_br: data.explanation.PtBR,
      explanation_en_us: data.explanation.EnUS,
      explanation_es_es: data.explanation.EsES,
      explanation_fr_fr: data.explanation.FrFR,
      explanation_de_de: data.explanation.DeDE,
      radioTypeQuestion: data.type,
      radioTypeGraphic: data.graphic,
    });

    console.log(this.formEditQuestion)
  }

  changeTypeQuestionEdit() {
    for (let indice in this.listAnswers) {
      this.listAnswers[indice].correct = false;
    }

    this.typeQuestion = this.formEditQuestion.controls['radioTypeQuestion'].value;

    let value = this.formEditQuestion.get('radioTypeQuestion').value;
    switch (value) {
      case 'oneSelect':
        // this.blockGraphicTypevisibilityEdit = true;
        // this.blockOptionsAnswersVisibilityEdit = true;

        if (this.listAnswers.length <= 0) {
          this.listAnswers.push(new Answer());
        }
        break;

      case 'multipleSelect':
        // this.blockGraphicTypevisibilityEdit = true;
        // this.blockOptionsAnswersVisibilityEdit = true;

        if (this.listAnswers.length <= 0) {
          this.listAnswers.push(new Answer());
        }
        break;
    }
  }

  changeTypeQuestionCreate() {
    for (let indice in this.listNewQuestion) {
      this.listNewQuestion[indice].correct = false;
    }

    this.typeNewQuestion = this.formNewQuestion.controls['radioTypeQuestion'].value;

    let value = this.formNewQuestion.get('radioTypeQuestion').value;
    switch (value) {
      case 'oneSelect':
        // this.blockGraphicTypevisibilityCreate = true;
        // this.blockOptionsAnswersVisibilityCreate = true;

        if (this.listAnswers.length <= 0) {
          this.listAnswers.push(new Answer());
        }
        break;

      case 'multipleSelect':
        // this.blockGraphicTypevisibilityCreate = true;
        // this.blockOptionsAnswersVisibilityCreate = true;

        if (this.listAnswers.length <= 0) {
          this.listAnswers.push(new Answer());
        }
        break;
    }
  }

  /**
   * Pega os valores das Answers na DAO
   */
  loadAnswer(questionId) {
    this.dbTraining.getAnswers(this.moduleId, this.trainingId, questionId, (list) => {
      this.answers = [];
      this.listAuxInput = [];
      for (const data of list) {
        this.listAuxInput.push(data);
        this.answers.push(data);
      }

      this.carryInputsAnswer(this.answers);
      this.inputValueAnswer(this.listAuxInput);
    })
    // this.daoTraining.getAnswers(questionId).then((list: Array<any>) => {

    //   this.answers = [];
    //   this.listAuxInput = [];
    //   for (const data of list) {
    //     this.listAuxInput.push(data);
    //     this.answers.push(data);
    //   }
    //   this.carryInputsAnswer(this.answers);
    //   this.inputValueAnswer(this.listAuxInput);
    // });
  }

  /**
   * Carrega os valores das answers no input
   */
  inputValueAnswer(data) {
    let index = 0;
    this.listAnswers = [];

    for (const aux of data) {
      this.listAnswers[index] = aux;
      index++;
    }

  }

  /**
   * Cria os clones de inputs do formulario Answer
   */
  cloneInputAnswers() {
    this.listAnswers.push(new Answer());
  }

  /**
   * Delete input answers
   */
  getDeleteInputAnswers(index) {
    const deleteId = this.listAnswers[index].uid;

    if (deleteId !== undefined) {
      var questionId = this.questionId;
      var deleteAnswer = [questionId, deleteId];
      this.listDeleteInputAnswers.push(deleteAnswer);
      this.listAnswers.splice(index, 1);
    }
  }

  deleteInputAnswers() {
    for (let aux of this.listDeleteInputAnswers) {
      var questionId = aux[0];
      var deleteId = aux[1];

      this.dbTraining.deleteAnswer(this.moduleId, this.trainingId, questionId, deleteId, (status) => {

      });
    }
  }

  /**
   *  Carrega os clones dos inputs de acordo com o total de respostas que o formulario possui
   */
  carryInputsAnswer(data) {
    let cont = data.length;
    let print = 1;
    while (cont >= 1) {
      if (print < data.length) {
        this.cloneInputAnswers();
        print++;
      }
      cont--;
    }
  }

  /**
   * Submit formulario Edit Question
   */
  editQuestion(data) {
    console.log(data)
    this.errorTitleEdit = false;
    this.errorSelectQuestionEdit = false;
    this.errorOptionAnswerEdit = false;
    this.errorSelectChartTypeEdit = false;

    const newQuestion = new Question();
    let auxTitle = new NameModule(data.title_pt_br, data.title_en_us, data.title_es_es, data.title_fr_fr, data.title_de_de);
    let auxInfobooth = new NameModule(data.infobooth_pt_br, data.infobooth_en_us, data.infobooth_es_es, data.infobooth_fr_fr, data.infobooth_de_de);
    let auxExplanation = new NameModule(data.explanation_pt_br, data.explanation_en_us, data.explanation_es_es, data.explanation_fr_fr, data.explanation_de_de);
    newQuestion.title = this.formatIdioma.updateObjectMultiLanguages(this.questions[this.editQuestionIndex].title, auxTitle, this.eventLanguage) 
    newQuestion.infobooth = this.formatIdioma.updateObjectMultiLanguages(this.questions[this.editQuestionIndex].infobooth, auxInfobooth, this.eventLanguage)
    newQuestion.explanation = this.formatIdioma.updateObjectMultiLanguages(this.questions[this.editQuestionIndex].explanation, auxExplanation, this.eventLanguage)
    newQuestion.type = data.radioTypeQuestion;
    newQuestion.graphic = data.radioTypeGraphic;

    if (this.fileEdit !== undefined && this.fileEdit !== null) {
      newQuestion.image = this.fileEdit;
    } else {
      delete newQuestion.image;
    }

    if (newQuestion.type == 'oneSelect' || newQuestion.type == 'multipleSelect') {
      for (const answer of this.listAnswers) {
        newQuestion.answers.push(answer);
      }
    }


    //Caso o usuário não tenha inserido o titulo da pergunta
    if (newQuestion.title == undefined || newQuestion.title == null || (newQuestion.title.PtBR == "" &&
      newQuestion.title.EnUS == "" && newQuestion.title.EsES == "" && newQuestion.title.FrFR == "" &&
      newQuestion.title.DeDE == "")) {
      this.errorTitleEdit = true;
    }

    if (newQuestion.type == undefined || newQuestion.type == null || newQuestion.type == "") {
      this.errorSelectQuestionEdit = true;
    }

    if (newQuestion.type == 'oneSelect' || newQuestion.type == 'multipleSelect') {
      //Caso não tenha adicionado nenhuma resposta possível
      let find = false;

      for (let answer of this.listAnswers) {
        if (!(answer.answer == undefined) && !(answer.answer == null) && (answer.answer.PtBR != ""
          || answer.answer.EnUS != "" || answer.answer.EsES != "" || answer.answer.FrFR != ""
          || answer.answer.DeDE != "")) {
          find = true;
        }
      }

      if (find == false) {
        this.errorOptionAnswerEdit = true;
      }

      if(newQuestion.graphic == null || newQuestion.graphic == undefined || newQuestion.graphic == "") {
        this.errorSelectChartTypeEdit = true;
      }
    }

    if (!(this.errorTitleEdit || this.errorSelectQuestionEdit || this.errorOptionAnswerEdit || this.errorSelectChartTypeEdit)) {
      this.dbTraining.updateQuestion(this.moduleId, this.trainingId, this.questionId, newQuestion, this.eventTimezone, this.eventId, (status) => {
        this.questions[this.editQuestionIndex].title = newQuestion.title;
        this.questions[this.editQuestionIndex].type = newQuestion.type;

        this.editQuestionShow = false;
        this.addQuestionShow = false;
        this.allowShowAddQuestion = true;
        this.fileEdit = null;
        this.fileNameEdit = null;
      });

      this.deleteInputAnswers();

      this.allowShowAddQuestion = true;
    }

  }


  updateCorrectAnswerEdit(indice) {
    if (this.typeQuestion == 'oneSelect') { // Caso seja one select atribui a selecionada como verdadeira a selecionada e todas as outras como falsas.
      for (let i = 0; i < this.listAnswers.length; i++) {
        if (i == indice) {
          this.listAnswers[i].correct = true;
        } else {
          this.listAnswers[i].correct = false;
        }
      }
    }
    else if (this.typeQuestion == 'multipleSelect') { //Caso seja multiple select e a resposta na posição do indice seja correta muda para incorreta e caso seja incorreta muda para correta.
      if (this.listAnswers[indice].correct) {
        this.listAnswers[indice].correct = false;
      } else {
        this.listAnswers[indice].correct = true;
      }
    }
  }


  //----------------------------------
  //         Create New Question
  //----------------------------------

  /**
   * Cria os clones de inputs do formulario Answer
   */
  cloneInputNewQuestion() {
    this.listNewQuestion.push(new Answer());
  }

  /**
   * Delete input answers
   */
  deleteInputNewQuestion(index) {
    this.listNewQuestion.splice(index, 1);
  }

  /**
   * Create new Question
   */
  newQuestion(data) {
    this.errorTitleCreate = false;
    this.errorSelectQuestionCreate = false;
    this.errorOptionAnswerCreate = false;
    this.errorSelectChartTypeCreate = false;

    let newQuestion = new Question();
    newQuestion.title = new NameModule(data.title_pt_br, data.title_en_us, data.title_es_es, data.title_fr_fr, data.title_de_de);
    newQuestion.infobooth = new NameModule(data.infobooth_pt_br, data.infobooth_en_us, data.infobooth_es_es, data.infobooth_fr_fr, data.infobooth_de_de);
    newQuestion.type = data.radioTypeQuestion;
    newQuestion.image = this.fileNew;
    newQuestion.graphic = data.radioTypeGraphic;

    //Caso o usuário não tenha inserido o titulo da pergunta
    newQuestion.title = this.formatIdioma.fillEmptyLanguage(newQuestion.title, this.eventLanguage);
    newQuestion.infobooth = this.formatIdioma.fillEmptyLanguage(newQuestion.infobooth, this.eventLanguage);
    newQuestion.infobooth = this.replaceLinkInfooboth(newQuestion.infobooth);

    if (newQuestion.title == undefined || newQuestion.title == null || (newQuestion.title.PtBR == ""
      && newQuestion.title.EnUS == "" && newQuestion.title.EsES == "" && newQuestion.title.FrFR == ""
      && newQuestion.title.DeDE == "")) {
      this.errorTitleCreate = true;
    }

    if (newQuestion.type == undefined || newQuestion.type == null || newQuestion.type == "") {
      this.errorSelectQuestionCreate = true;
    }

    if (newQuestion.type == 'oneSelect' || newQuestion.type == 'multipleSelect') {
      //Caso não tenha adicionado nenhuma resposta possível
      let find = false;

      for (let answer of this.listNewQuestion) {
        if (answer.answer != undefined && answer.answer != null && (answer.answer.PtBR != ""
          || answer.answer.EnUS != "" || answer.answer.EsES != "" || answer.answer.FrFR != ""
          || answer.answer.DeDE != "")) {

          find = true;
        }
      }

      if (find == false) {
        this.errorOptionAnswerCreate = true;
      }

      if(newQuestion.graphic == null || newQuestion.graphic == undefined || newQuestion.graphic == "") {
        this.errorSelectChartTypeCreate = true;
      }
    }



    if (!(this.errorTitleCreate || this.errorSelectQuestionCreate || this.errorOptionAnswerCreate || this.errorSelectChartTypeCreate)) {
      if (newQuestion.type == 'oneSelect' || newQuestion.type == 'multipleSelect') {
        let cont = 0;
        for (let aux of this.listNewQuestion) {
          let answer = new Answer();

          answer.answer = aux.answer;
          aux.weight == undefined ? answer.weight = null : answer.weight = aux.weight;
          aux.marker == undefined ? answer.marker = null : answer.marker = aux.marker;
          answer.correct = aux.correct;

          // if (answer.answer !== null && answer.answer !== undefined && answer.answer !== "") {
          //   newQuestion.answers.push(answer)
          // }

          answer.answer = this.formatIdioma.fillEmptyLanguage(answer.answer, this.eventLanguage);
          if (answer.answer !== undefined && answer.answer !== null && (answer.answer.PtBR != ""
            || answer.answer.EnUS != "" || answer.answer.EsES != "" || answer.answer.FrFR != ""
            || answer.answer.DeDE != "")) {

            newQuestion.answers.push(answer)
          }

          if (cont == this.listNewQuestion.length - 1) {
            this.dbTraining.createOneQuestions(this.moduleId, this.trainingId, newQuestion, this.eventTimezone, this.eventId, (data) => {
              if (data.status == true) {
                newQuestion.uid = data.questionId;
                this.questions.push(newQuestion);
                this.loaderQuestions.push(false);
              }

              this.editQuestionShow = false;
              this.addQuestionShow = false;
              this.allowShowAddQuestion = true;
              this.fileNew = null;
              this.fileNameNew = null;
            });
          }

          cont++;
        }
      }


      //Clear form new question, close modal and show/hide blocks
      this.formNewQuestion.reset();
      // this.blockGraphicTypevisibilityCreate = true;
      // this.blockOptionsAnswersVisibilityCreate = true;
      $('#modalTraining').modal('toggle');

      this.listNewQuestion = [];
      this.listNewQuestion.push(new Answer());
      this.addQuestionShow = false;
      this.correctAnswerNewQuestion = null;

    }

  }

  updateCorrectAnswer(indice) {
    if (this.typeNewQuestion == 'oneSelect') { // Caso seja one select atribui a selecionada como verdadeira a selecionada e todas as outras como falsas.
      for (let i = 0; i < this.listNewQuestion.length; i++) {
        if (i == indice) {
          this.listNewQuestion[i].correct = true;
        } else {
          this.listNewQuestion[i].correct = false;
        }
      }
    }
    else if (this.typeNewQuestion == 'multipleSelect') { //Caso seja multiple select e a resposta na posição do indice seja correta muda para incorreta e caso seja incorreta muda para correta.
      if (this.listNewQuestion[indice].correct) {
        this.listNewQuestion[indice].correct = false;
      } else {
        this.listNewQuestion[indice].correct = true;
      }
    }
  }


  cancelNewQuestion() {

    //Clear form new question, close modal and show/hide blocks
    $(function () {
      $('#form-questions').each(function () {
        this.reset();
        $("input[name=newRadioTypeQuestion]").prop('checked', false);
        $("input[name=radioTypeGraphic]").prop('checked', false);
        // $('#answer-options').hide('slow');
        // $('#chart-options').hide('slow');
      });
    })

    this.listNewQuestion = [];
    this.listNewQuestion.push(new Answer());
    this.addQuestionShow = false;
  }




  load() {
    this.dbEvent.getModulesSchedule(this.eventId, (data) => {
      this.scheduleModules = data;
    });
  }

  // LOAD ALL TRACKS OF SCHEDULE MODULE 
  private loadTracks(moduleId) {
    this.dbSession.getModuleTracks(moduleId, (tracks) => {
      // this.dao.tracksSessionEvent(moduleId, (tracks) => {
      this.tracks = [];
      this.group = [];
      this.group = tracks;
      for (const track of tracks) {
        this.tracks.push(track);
      }
    });
  }

  // receive group name and return index of the group  to get id group
  findIdTrack(track: Track) {
    const index = this.tracks.map(function (e) {
      return new GetNameTrack().transform(e, [this.userLanguage, this.eventLanguage]);
    }).indexOf(new GetNameTrack().transform(track, [this.userLanguage, this.eventLanguage]));
    return index;
  }

  // autocomplete
  filter() { //  função usa a query variável para filtrar tracks, então ela armazena o resultado no filteredList.
    if (this.query !== "") {
      this.filteredList = this.tracks.filter(function (el) {
        let elementStr = new GetNameTrack().transform(el, [this.userLanguage, this.eventLanguage]);
        return elementStr.toLowerCase().indexOf(this.query.toLowerCase()) > -1;
      }.bind(this));
    } else {
      this.filteredList = [];
    }
  }

  // atribuir o item selecionado para a variável de consulta, a fim de fazê-lo aparecer na entrada, e para fazer a lista de sugestões de desaparecer
  select(item) {
    if (this.selectedTracks.length > 0) {
      const index = this.selectedTracks.indexOf(item);
      if (index == -1) {
        this.selectedTracks.push(item);
      }
    } else {
      this.selectedTracks.push(item);
    }

    this.query = '';
    this.filteredList = [];
  }

  remove(item) { // remove group from display list
    this.selectedTracks.splice(this.selectedTracks.indexOf(item), 1);
  }



  loadSessions(moduleId) {
    this.dbSession.getSessionsModule(moduleId, (list: Array<Session>) => {
      this.sessions = [];
      this.session = list;

      this.sessionsNames = [];
      for (const session of list) {
        this.sessionsNames.push(new GetNameSession().transform(session, [this.userLanguage, this.eventLanguage]))
        // this.sessions.push(session);
      }
    })
  }

  // autocomplete
  filterSession() { //  função usa a query variável para filtrar tracks, então ela armazena o resultado no filteredList.
    if (this.querySession !== "") {
      this.filteredSession = this.session.filter(function (el) {
        // search session using user language
        switch (this.userLanguage) {
          case ('pt_BR'):
            return el.name.PtBR.toLowerCase().indexOf(this.querySession.toLowerCase()) > -1;

          case ('en_US'):
            return el.name.EnUS.toLowerCase().indexOf(this.querySession.toLowerCase()) > -1;

          case ('es_ES'):
            return el.name.EsES.toLowerCase().indexOf(this.querySession.toLowerCase()) > -1;


          case ('fr_FR'):
            return el.name.FrFR.toLowerCase().indexOf(this.querySession.toLowerCase()) > -1;

          case ('de_DE'):
            return el.name.DeDE.toLowerCase().indexOf(this.querySession.toLowerCase()) > -1;
        }



      }.bind(this));
    } else {
      this.filteredSession = [];
    }
  }

  // receive session name and return index of the session to get id session
  findIdSession(session: Session) {
    const index = this.session.map(function (e) { return e.name; }).indexOf(session.name);
    return index;
  }

  // atribuir o item selecionado para a variável de consulta, a fim de fazê-lo aparecer na entrada, e para fazer a lista de sugestões de desaparecer
  selectSession(item) {
    if (this.selectedSessions.length > 0) {
      const index = this.selectedSessions.map(function (e) { return e.uid; }).indexOf(item.uid);

      if (index == -1) {
        this.selectedSessions.push(item);
      }
    } else {
      this.selectedSessions.push(item);
    }

    this.querySession = '';
    this.filteredSession = [];
  }

  removeSession(item) { // remove group from display list
    this.selectedSessions.splice(this.selectedSessions.indexOf(item), 1);
    // this.dao.notAllowGroupCheckin(this.eventId, this.groupId);
  }

  loadGroups() {
    this.dbGroup.searchModulesAndGroups(this.eventId, (response) => {
      this.groups = [];
      this.groups = response['groups'];
    });
  }

  // autocomplete
  filterGroup() { //  função usa a query variável para filtrar tracks, então ela armazena o resultado no filteredList.
    if (this.queryGroup !== "") {
      this.filteredGroup = this.groups.filter(function (el) {
        return el.name.toLowerCase().indexOf(this.queryGroup.toLowerCase()) > -1;
      }.bind(this));
    } else {
      this.filteredGroup = [];
    }
  }

  // receive session name and return index of the session to get id session
  findIdGroup(group: Group) {
    const index = this.groups.map(function (e) { return e.name; }).indexOf(group.name);
    return index;
  }

  // atribuir o item selecionado para a variável de consulta, a fim de fazê-lo aparecer na entrada, e para fazer a lista de sugestões de desaparecer
  selectGroup(item) {
    if (this.selectedGroups.length > 0) {
      const index = this.selectedGroups.indexOf(item);
      if (index == -1) {
        this.selectedGroups.push(item);
      }
    } else {
      this.selectedGroups.push(item);
    }

    this.queryGroup = '';
    this.filteredGroup = [];
  }

  removeGroup(item) { // remove group from display list
    this.selectedGroups.splice(this.selectedGroups.indexOf(item), 1);
    // this.dao.notAllowGroupCheckin(this.eventId, this.groupId);
  }

  //verifica se o training será vinculado ou não a uma sessão
  changeTypeTraining() {
    if (this.trainingLinkedToSession) {
      //caso o training sejá vinculado a uma sessão, automaticamente o valor inicial se torna todas as sessões
      this.trainingType = TypeTraining.AllSessions;
    } else {
      this.trainingLinkedToSession = false;
      this.selectedSessions = [];
      this.trainingType = TypeTraining.Pattern;

      this.SelectedSessionVisibility = false;
      this.tracksAttendeeVisibility = false;
      this.scheduleModuleTrackVisibility = false;
      this.scheduleModuleSessionVisibility = false;
      this.specificTrackVisibility = false;
      this.specificGroupVisibility = false;
    }
  }

  //caso o training seja vinculado as sessões, verifica se será em todas ou em uma especifica e altera o trainingType
  changeSessionType() {
    switch (this.trainingType) {
      case 'AllSessions':
        this.trainingType = TypeTraining.AllSessions;

        this.SelectedSessionVisibility = false;
        this.tracksAttendeeVisibility = false;
        this.scheduleModuleTrackVisibility = false;
        this.scheduleModuleSessionVisibility = false;
        this.specificTrackVisibility = false;
        this.specificGroupVisibility = false;

        //Esvazia o array de sessões selecionadas posteriormente.
        this.selectedSessions = [];
        break;

      case 'ScheduleModule':
        this.trainingType = TypeTraining.ScheduleModule;

        this.SelectedSessionVisibility = false;
        this.tracksAttendeeVisibility = false;
        this.scheduleModuleVisibility = true;
        this.scheduleModuleTrackVisibility = false;
        this.scheduleModuleSessionVisibility = false;
        this.specificTrackVisibility = false;
        this.specificGroupVisibility = false;
        break;

      case 'SessionTrack':
        this.trainingType = TypeTraining.SessionTrack;

        this.SelectedSessionVisibility = false;
        this.tracksAttendeeVisibility = false;
        this.scheduleModuleVisibility = false;
        this.scheduleModuleSessionVisibility = false;
        this.scheduleModuleTrackVisibility = true;
        this.specificGroupVisibility = false;
        break;

      case 'SpecificSession':
        this.trainingType = TypeTraining.SpecificSession;

        this.SelectedSessionVisibility = false;
        this.tracksAttendeeVisibility = false;
        this.scheduleModuleVisibility = false;
        this.scheduleModuleTrackVisibility = false;
        this.scheduleModuleSessionVisibility = true;
        this.specificTrackVisibility = false;
        this.specificGroupVisibility = false;
        break;

      case 'SpecificGroup':
        this.trainingType = TypeTraining.SpecificGroup;

        this.SelectedSessionVisibility = false;
        this.tracksAttendeeVisibility = false;
        this.scheduleModuleVisibility = false;
        this.scheduleModuleTrackVisibility = false;
        this.scheduleModuleSessionVisibility = false;
        this.specificTrackVisibility = false;
        this.specificGroupVisibility = true;
        this.loadGroups();
        break;
    }
  }

  moduleSelectedChange() {
    this.selectedGroups = [];
    this.selectedSessions = [];
    this.selectedTracks = [];

    var trackId = this.scheduleModuleTrackTrainingEdit;
    var sessionId = this.scheduleModuleSessionTrainingEdit;
    var specificModule = this.scheduleModuleTrainingEdit;

    if (trackId == undefined || trackId == null || trackId == '') {
      this.specificTrackVisibility = false;
    } else {
      this.specificTrackVisibility = true;
      this.loadTracks(trackId);
    }

    if (sessionId == undefined || sessionId == null || sessionId == '') {
      this.SelectedSessionVisibility = false;
    } else {
      this.SelectedSessionVisibility = true;
      this.loadSessions(sessionId);
    }
  }

  resetValuesForm() {
    this.trainingLinkedToSession = false;
    this.SelectedSessionVisibility = false;
    this.tracksAttendeeVisibility = false;
    this.specificTrackVisibility = false;
    this.scheduleModuleVisibility = false;
    this.scheduleModuleTrackVisibility = false;
    this.scheduleModuleSessionVisibility = false;
    this.specificGroupVisibility = false;

    // tracks
    this.tracks = [];
    this.group = []; // array de ids
    this.query = ''; // string digitada pelo usuário
    this.filteredList = []; // lista de sugestões exibidas
    this.selectedTracks = []; // array com os tracks selecionados

    // sessions
    this.sessions = [];
    this.sessionsNames = [];
    this.session = [];
    this.querySession = ''; // string digitada pelo usuário
    this.filteredSession = []; // lista de sugestões exibidas
    this.selectedSessions = []; // array com os tracks selecionados

    // groups
    this.groups = [];
    this.groupsNames = [];
    this.ManagerGroup = []; // array de ids
    this.queryGroup = ''; // string digitada pelo usuário
    this.filteredGroup = []; // lista de sugestões exibidas
    this.selectedGroups = []; // array com os tracks selecionados

    this.scheduleModules = [];
    this.trainingType = TypeTraining.Pattern;
    this.scheduleModuleTrainingEdit = null;
    this.scheduleModuleTrackTrainingEdit = null;
    this.scheduleModuleSessionTrainingEdit = null;
  }

  cancel() {
    this.location.back();
  }

  replaceLinkInfooboth(data) {
    data['PtBR'] == null ? data['PtBR'] = '' : data['PtBR'] = data['PtBR'].replace(/href="/g, 'class="wysiwyg-link" href="');
    data['EnUS'] == null ? data['EnUS'] = '' : data['EnUS'] = data['EnUS'].replace(/href="/g, 'class="wysiwyg-link" href="');
    data['EsES'] == null ? data['EsES'] = '' : data['EsES'] = data['EsES'].replace(/href="/g, 'class="wysiwyg-link" href="');
    data['FrFR'] == null ? data['FrFR'] = '' : data['FrFR'] = data['FrFR'].replace(/href="/g, 'class="wysiwyg-link" href="');
    data['DeDE'] == null ? data['DeDE'] = '' : data['DeDE'] = data['DeDE'].replace(/href="/g, 'class="wysiwyg-link" href="');

    return data;
  }

}
