import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {
  transform(list: any, term: any, language?: string): any {
    // check if search term is undefined
    if (term === undefined) {
      return list;
    }
    term = term.toLowerCase();
    // return updated List of objects searched
    return list.filter(function (element) {
      if (language) { return element.name[language].toLowerCase().includes(term.toLowerCase()); }
      if (element.name !== undefined) return element.name.toLowerCase().includes(term.toLowerCase());
      if (element.title !== undefined) return element.title.toLowerCase().includes(term.toLowerCase());
      if (element.appName !== undefined) return element.appName.toLowerCase().includes(term.toLowerCase());
    })
  }
}