import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { PathApi } from '../../paths/path-api';
import { AngularFirestore } from '@angular/fire/firestore';
import firebase from 'firebase/app';
import { TypeModule } from 'src/app/enums/type-module';
import { resolve } from 'q';
import { SessionFeedback } from '../../models/session-feedback';
import { Question } from '../../models/session-feedback-question';
import { Answer } from '../../models/session-feedback-answer';
import { NotifiicationDateService } from '../luxon/notification-date.service';
import { NameModule } from 'src/app/models/name-module';
import { take } from 'rxjs/operators';
import { StorageService } from '../storage/storage.service';
import { UUID } from 'angular2-uuid';
import { PathStorage } from 'src/app/paths/path-storage';



@Injectable({
    providedIn: 'root'
})
export class DbSessionFeedbackProvider {
    private db: firebase.firestore.Firestore;
    public headers;
    public requestOptions;

    constructor(private http: HttpClient, private aFirestore: AngularFirestore, private luxon: NotifiicationDateService, private storage: StorageService) {
        this.headers = new HttpHeaders();
        this.headers.append("Accept", 'application/json');
        this.headers.append("Content-Type", 'application/json');
        this.requestOptions = { headers: this.headers, params: {}, body: {} };
    }

    getFeedbackModule(eventId: string, onResolve) {
        let db = this.aFirestore.firestore;
        let ref = db.collection('events').doc(eventId).collection('modules');

        ref
            .where('type', '==', TypeModule.SESSION_FEEDBACK)
            .get()
            .then((snapshot) => {
                let auxModule = [];
                if (snapshot.size >= 1) {
                    snapshot.forEach(element => {
                        auxModule.push(element.data());
                    });
                }
                onResolve(auxModule);
            })
            .catch((e) => {
                onResolve(e);
            })
    }

    getTotalFeedbacks(moduleId, onResolve) {
        let db = this.aFirestore.firestore;
        let ref = db.collection('modules').doc(moduleId).collection('session-feedbacks');
        ref.onSnapshot((data) => {
            onResolve(data.size);
        });
    }

    changeOrder(moduleId, feedbacks, onResolve) {
        let db = this.aFirestore.firestore;
        let batch = db.batch();

        for (let feedback of feedbacks) {
            let ref = db.collection('modules').doc(moduleId).collection('session-feedbacks').doc(feedback.uid)
            batch.update(ref, { order: feedback.order })
        }

        batch.commit()
            .then(() => {
                onResolve(true)
            })
            .catch((e) => {
                onResolve(false)
            })
    }

    getFeedbackModuleAndFeedbacks(eventId: string, onResolve) {
        let db = this.aFirestore.firestore;
        let moduleId;
        let ref = db.collection('events').doc(eventId).collection('modules');
        let listFeedbacks = [];
        ref
            .where('type', '==', TypeModule.SURVEY)
            .get()
            .then((snapshot) => {
                if (snapshot.size >= 1) {
                    snapshot.forEach(element => {
                        moduleId = element.data().uid;
                    });

                    let refFeedback = db.collection('modules').doc(moduleId).collection('session-feedbacks').orderBy('order', 'asc');

                    refFeedback.get().then((data) => {
                        data.forEach(element => {
                            let feedback = element.data();
                            this.getQuestions(moduleId, feedback.uid, (questions) => {

                                feedback.questions = questions;
                                listFeedbacks.push(feedback);
                            })
                        });

                    })
                }
                onResolve({
                    feedbacks: listFeedbacks,
                    moduleId: moduleId
                })
            })
            .catch((e) => {
                onResolve(e);
            })

    }

    getFeedbacks(moduleId, onResolve) {
        let db = this.aFirestore.firestore;
        let ref = db.collection('modules').doc(moduleId).collection('session-feedbacks').orderBy('order', 'asc');

        ref.onSnapshot((data) => {
            let listFeedbacks = [];
            data.forEach(element => {
                let feedback = element.data();
                if (feedback.uid !== undefined) {
                    this.getQuestions(moduleId, feedback.uid, (questions) => {
                        feedback.questions = questions;
                        listFeedbacks.push(feedback);
                    });
                }
            });

            onResolve(listFeedbacks)
        });
    }

    getFeedback(moduleId, feedbackId, onResolve) {
        let db = this.aFirestore.firestore;
        let ref = db
            .collection('modules')
            .doc(moduleId)
            .collection('session-feedbacks')
            .doc(feedbackId);

        ref.get()
            .then((data) => {
                let feedback = data.data();
                this.getQuestions(moduleId, feedback.uid, (questions) => {
                    feedback.questions = questions;
                    onResolve(feedback);
                })
            })
    }

    getQuestions(moduleId: string, feedbackId: string, onResolve) {
        let db = this.aFirestore.firestore;

        let listQuestions: Array<Question> = [];

        let refQuestions = db.collection('modules').doc(moduleId).collection('session-feedbacks').doc(feedbackId).collection('questions')
            .orderBy('createdAt', 'asc');;

        refQuestions.get()
            .then((data) => {
                data.forEach(element => {
                    let question = this.instantiateQuestion(element.data());
                    listQuestions.push(question);
                });

                onResolve(listQuestions)
            })
    }

    getAnswers(moduleId: string, feedbackId: string, questionId: string, onResolve) {
        let db = this.aFirestore.firestore;

        let refQuestions = db.
            collection('modules')
            .doc(moduleId)
            .collection('session-feedbacks')
            .doc(feedbackId)
            .collection('questions')
            .doc(questionId)
            .collection('answers')
            .orderBy('createdAt', 'asc');;

        refQuestions.get()
            .then((data) => {
                let listAnswers: Array<Answer> = [];
                data.forEach(element => {
                    let answer: Answer = this.instantiateAnswer(element.data());
                    listAnswers.push(answer);
                });

                onResolve(listAnswers);
            })
    }

    instantiateQuestion(data) {
        let question = new Question();

        question.uid = data.uid;
        question.type = data.type;
        question.title = data.title;
        data.infobooth != undefined ? question.infobooth = data.infobooth : question.infobooth = new NameModule('', '', '', '', '');
        question.points = data.points;
        data.graphic != undefined ? question.graphic = data.graphic : question.graphic = null;
        question.createdAt = data.createdAt;
        data.visibility != undefined ? question.visibility = data.visibility : question.visibility = true;

        return question;
    }

    instantiateAnswer(data) {
        let answer = new Answer();
        answer.uid = data.uid;
        answer.answer = data.answer;
        data.weight != undefined ? answer.weight = data.weight : answer.weight = null;
        data.marker != undefined ? answer.marker = data.marker : answer.marker = null;
        answer.createdAt = data.createdAt;

        return answer;
    }

    changeVisibility(moduleId, feedbackId, visibility) {
        let db = this.aFirestore.firestore;

        let ref = db
            .collection('modules')
            .doc(moduleId)
            .collection('session-feedbacks')
            .doc(feedbackId);

        ref.update({
            visibility: visibility
        })
    }

    changeVisibilityQuestion(moduleId, surveyId, questionId, visibility) {
        let db = this.aFirestore.firestore;

        let ref = db
            .collection('modules')
            .doc(moduleId)
            .collection('session-feedbacks')
            .doc(surveyId)
            .collection('questions')
            .doc(questionId)

        ref.update({
            visibility: visibility
        }).then(() => {
            console.log('OOOK')
        })
    }

    createFeedback(imgFile: File, moduleId: string, feedback: SessionFeedback, eventId: string, selected: any, timezone: string, onResolve) {
        const aux = feedback.question;
        delete feedback.question;

        let db = this.aFirestore.firestore;

        let ref = db.collection('modules').doc(moduleId).collection('session-feedbacks').doc();
        feedback.uid = ref.id;

        let imgId = UUID.UUID();

        if(imgFile !== undefined){
            this.storage.uploadImg(imgFile, eventId, moduleId, feedback.uid, imgId, PathStorage.feedback, (url) => {    
                feedback.imgCapa =  url;
                
                feedback.title = Object.assign({}, feedback.title)
                feedback = Object.assign({}, feedback);
                ref.set(feedback)
                .then(() => {

                    if (feedback.type == 'SpecificSession' || feedback.type == 'SessionTrack' || feedback.type == 'SpecificGroup') {
                        this.forFeedbackReferences(selected, feedback.uid, moduleId);
                    }
                    
                    this.createQuestions(moduleId, feedback.uid, aux, timezone, (data) => {
                        if (data == true) {
                            onResolve(data);
                        }
                    });
                });
            });

        }else{
                
            feedback.title = Object.assign({}, feedback.title)
            feedback = Object.assign({}, feedback);
            ref.set(feedback)
            .then(() => {
                
                if (feedback.type == 'SpecificSession' || feedback.type == 'SessionTrack' || feedback.type == 'SpecificGroup') {
                    this.forFeedbackReferences(selected, feedback.uid, moduleId);
                }
                
                this.createQuestions(moduleId, feedback.uid, aux, timezone, (data) => {
                    if (data == true) {
                        onResolve(data);
                    }
                });
            });
        }
    }

    forFeedbackReferences(ids, feedbackId, moduleId) {
        //Limpa referencias anteriores antes de gravar as novas

        let db = this.aFirestore.firestore;
        db.collection('modules').doc(moduleId).collection('session-feedbacks').doc(feedbackId).update({
            references: firebase.firestore.FieldValue.delete()
        }).then(() => {
            for (const item of ids) {
                db.collection('modules').doc(moduleId).collection('session-feedbacks').doc(feedbackId).update({
                    references: firebase.firestore.FieldValue.arrayUnion(item.uid)
                })
            }
        })
    }


    /**
   * Create question
   */
    createQuestions(moduleId: string, idFeedback: string, question: Array<Question>, timezone: string, onResolve) {
        let db = this.aFirestore.firestore;
        let contTime = 0;
        let cont = 0;
        let objFeedback = {};

        for (let data of question) {
            setTimeout(() => {
                const answers = data.answers;
                delete data.answers;

                let questionId;

                let refFeedback = db.collection('modules').doc(moduleId).collection('session-feedbacks').doc(idFeedback);
                let ref = db.collection('modules').doc(moduleId).collection('session-feedbacks').doc(idFeedback).collection('questions')
                data.createdAt = this.luxon.getTimeStampFromDateNow(new Date(), timezone);

                data = Object.assign({}, data);
                data.title = Object.assign({}, data.title);
                data.infobooth = Object.assign({}, data.infobooth);

                ref.add(data)
                    .then((docRef) => {
                        questionId = docRef.id;
                        data.uid = questionId;

                        objFeedback[`questions.${questionId}`] = data;

                        ref.doc(questionId).update({ uid: questionId })
                            .then(() => {
                                refFeedback.update(objFeedback).then(() => {
                                    if (data.type == 'oneSelect' || data.type == 'multipleSelect') {
                                        this.createAnswers(moduleId, idFeedback, questionId, answers, timezone);
                                    }

                                    if (cont == question.length - 1) {
                                        onResolve(true);
                                    }

                                    cont++;
                                })
                            })

                    })
            }, contTime * 30);

            contTime++;
        }
    }

    /**
   * Create answer
   */
    createAnswers(moduleId, idFeedback, idQuestion: string, answers: Array<Answer>, timezone: string) {
        let db = this.aFirestore.firestore;

        let cont = 0;
        let objAnswers = {};

        for (let data of answers) {
            setTimeout(() => {
                let refFeedback = db
                    .collection('modules')
                    .doc(moduleId)
                    .collection('session-feedbacks')
                    .doc(idFeedback);

                let ref = db.collection('modules')
                    .doc(moduleId)
                    .collection('session-feedbacks')
                    .doc(idFeedback)
                    .collection('questions')
                    .doc(idQuestion)
                    .collection('answers');

                data.createdAt = this.luxon.getTimeStampFromDateNow(new Date(), timezone);
                data = Object.assign({}, data);
                data.answer = Object.assign({}, data.answer)

                ref.add(data).then((docRef) => {
                    let answerId = docRef.id;
                    data.uid = answerId;

                    // objFeedback[`questions.${idQuestion}`] = data;
                    objAnswers[`questions.${idQuestion}.answers.${answerId}`] = data;
                    refFeedback.update(objAnswers)

                    ref.doc(answerId).update({ uid: answerId })
                });
            }, cont * 30);

            cont++;
        }
    }

    deleteAnswer(moduleId, feedbackId, questionId, answerId, onResolve) {
        let db = this.aFirestore.firestore;

        let refFeedback = db
            .collection('modules')
            .doc(moduleId)
            .collection('session-feedbacks')
            .doc(feedbackId);

        let ref = db.collection('modules')
            .doc(moduleId)
            .collection('session-feedbacks')
            .doc(feedbackId)
            .collection('questions')
            .doc(questionId)
            .collection('answers')
            .doc(answerId);

        ref.delete()
            .then(() => {
                let objAnswer = {};
                objAnswer[`questions.${questionId}.answers.${answerId}`] = firebase.firestore.FieldValue.delete();
                refFeedback.update(objAnswer).then(() => {
                    onResolve(true);
                })
                    .catch(() => {
                        onResolve(false);
                    })
            })
            .catch(() => {
                onResolve(false);
            })
    }

    updateFeedback(moduleId, feedbackId, feedback, onResolve) {
        let db = this.aFirestore.firestore;

        let ref = db.collection('modules')
            .doc(moduleId)
            .collection('session-feedbacks')
            .doc(feedbackId);

        feedback = Object.assign({}, feedback);
        feedback.title = Object.assign({}, feedback.title)
        ref.update(feedback).then(() => {
            onResolve(true)
        })
    }

    updateQuestion(moduleId, feedbackId, questionId, newQuestion, timezone, onResolve) {
        let db = this.aFirestore.firestore;

        newQuestion.uid = questionId;
        const answers = newQuestion.answers;
        delete newQuestion.answers;

        let refFeedback = db
            .collection('modules')
            .doc(moduleId)
            .collection('session-feedbacks')
            .doc(feedbackId);

        let ref = db.collection('modules')
            .doc(moduleId)
            .collection('session-feedbacks')
            .doc(feedbackId)
            .collection('questions')
            .doc(questionId);

        newQuestion = Object.assign({}, newQuestion);
        newQuestion.title = Object.assign({}, newQuestion.title);
        newQuestion.infobooth = Object.assign({}, newQuestion.infobooth);
        ref.update(newQuestion)
            .then(() => {

                let objFeedback = {};
                objFeedback[`questions.${questionId}`] = newQuestion;

                refFeedback.update(objFeedback).then(() => {

                    for (let answer of answers) {
                        if (answer.uid !== undefined) {
                            let objAnswer = {};
                            answer.answer = Object.assign({}, answer.answer);
                            objAnswer[`questions.${questionId}.answers.${answer.uid}`] = Object.assign({}, answer);
                            refFeedback.update(objAnswer);
                        }
                    }
                })

                // objAnswers[`questions.${idQuestion}.answers.${answerId}`] = data;
                if (newQuestion.type == 'oneSelect' || newQuestion.type == 'multipleSelect') {
                    this.updateAnswer(moduleId, feedbackId, questionId, answers, timezone, (data) => {
                        if (data) {
                            onResolve(data);
                        }
                    });
                } else {
                    ref.collection('answers').get()
                        .then((data) => {
                            if (data.size > 0) {
                                let listAnswers = [];
                                data.forEach(element => {
                                    listAnswers.push(element.data());
                                });

                                let contAnswer = 0;
                                for (let answer of listAnswers) {
                                    ref.collection('answers')
                                        .doc(answer.uid)
                                        .delete().then(() => {
                                            if (contAnswer == listAnswers.length - 1) {
                                                onResolve(true);
                                            }

                                            contAnswer++;
                                        })
                                }
                            } else {
                                onResolve(true);
                            }
                        })
                }
            })
            .catch(() => {
                onResolve(false);
            })
    }

    updateAnswer(moduleId: string, feedbackId: string, questionId: string, answers: Array<Answer>, timezone, onResolve) {
        let db = this.aFirestore.firestore;

        let refFeedback = db
            .collection('modules')
            .doc(moduleId)
            .collection('session-feedbacks')
            .doc(feedbackId);

        let ref = db.collection('modules')
            .doc(moduleId)
            .collection('session-feedbacks')
            .doc(feedbackId)
            .collection('questions')
            .doc(questionId);

        let cont = 0;
        for (let newAnswer of answers) {
            if (newAnswer.weight == undefined) {
                newAnswer.weight = null;
            }

            let auxAnswer = newAnswer;
            let auxAnswerId = newAnswer.uid;
            if (auxAnswerId !== undefined) {

                auxAnswer = Object.assign({}, auxAnswer);
                auxAnswer.answer = Object.assign({}, auxAnswer.answer);

                ref.collection('answers')
                    .doc(auxAnswerId)
                    .update(auxAnswer)
                    .then(() => {
                        let objAnswer = {};
                        objAnswer[`questions.${questionId}.answers.${auxAnswerId}`] = auxAnswer;

                        refFeedback.update(objAnswer).then(() => {
                            if (cont == answers.length - 1) {
                                onResolve(true);
                            }

                            cont++;
                        })
                    })
            } else {
                auxAnswer.createdAt = this.luxon.getTimeStampFromDateNow(new Date(), timezone);
                auxAnswer = Object.assign({}, auxAnswer);
                auxAnswer.answer = Object.assign({}, auxAnswer.answer);
                ref.collection('answers')
                    .add(auxAnswer)
                    .then((data) => {

                        let answerId = data.id;
                        auxAnswer.uid = answerId;

                        ref.collection('answers')
                            .doc(answerId)
                            .update({ uid: answerId })
                            .then(() => {
                                let objAnswer = {};
                                objAnswer[`questions.${questionId}.answers.${answerId}`] = auxAnswer;

                                refFeedback.update(objAnswer).then(() => {
                                    if (cont == answers.length - 1) {
                                        onResolve(true);
                                    }
                                })

                                cont++;
                            })
                    })
            }
        }
    }

    createOneQuestions(moduleId, feedbackId, newQuestion, timezone, onResolve) {
        let db = this.aFirestore.firestore;

        let answers = newQuestion.answers;
        delete newQuestion.answers;

        let refFeedback = db
            .collection('modules')
            .doc(moduleId)
            .collection('session-feedbacks')
            .doc(feedbackId);

        let ref = db.collection('modules')
            .doc(moduleId)
            .collection('session-feedbacks')
            .doc(feedbackId)
            .collection('questions');

        let objQuestion = {};
        newQuestion.createdAt = this.luxon.getTimeStampFromDateNow(new Date(), timezone);
        newQuestion = Object.assign({}, newQuestion);
        newQuestion.title = Object.assign({}, newQuestion.title);
        newQuestion.infobooth = Object.assign({}, newQuestion.infobooth);
        ref.add(newQuestion)
            .then((data) => {
                let questionId = data.id;

                ref.doc(questionId).update({
                    uid: questionId
                })
                    .then(() => {
                        newQuestion.uid = questionId;
                        objQuestion[`questions.${questionId}`] = newQuestion;
                        refFeedback.update(objQuestion).then(() => {
                            if (newQuestion.type == 'oneSelect' || newQuestion.type == 'multipleSelect') {
                                this.createAnswers(moduleId, feedbackId, questionId, answers, timezone);

                                onResolve({
                                    status: true,
                                    questionId: questionId
                                });
                            } else {
                                onResolve({
                                    status: true,
                                    questionId: questionId
                                });
                            }
                        })
                    })
            })
            .catch((err) => {
                onResolve(err);
            })
    }

    removeFeedback(moduleId, feedbackId, onResolve) {
        this.http.delete(PathApi.baseUrl + PathApi.dbSessionFeedbackDeleteFeedback + '?moduleId=' + moduleId + '&feedbackId=' + feedbackId, this.requestOptions)
            .subscribe((data) => {
                onResolve(data)
            },
                (error) => {
                    onResolve(error);
                })
    }

    /**
     * Clear all results feedback
     * @param moduleId 
     * @param feedbacksIds 
     */
    clearAllFeedbacks(moduleId: string, feedbacksIds: Array<string>) {
        this.requestOptions.body.feedbacksIds = feedbacksIds;
        return (this.http.delete(PathApi.baseUrl + PathApi.dbSessionFeedbackClearAll + '?moduleId=' + moduleId, this.requestOptions).pipe(take(1)))
    }

    /**
     * Clear a result of a feedback
     * @param moduleId 
     * @param feedbackId 
     */
    clearFeedback(moduleId: string, feedbackId: string) {
        return (this.http.delete(PathApi.baseUrl + PathApi.dbSessionFeedbackClearResultFeedback + '?moduleId=' + moduleId + '&feedbackId=' + feedbackId, this.requestOptions).pipe(take(1)));
    }

    removeQuestion(moduleId: string, feedbackId: string, questionId: string, onResolve) {
        this.http.delete(PathApi.baseUrl + PathApi.dbSessionFeedbackDeleteQuestion + '?moduleId=' + moduleId + '&feedbackId=' + feedbackId + '&questionId=' + questionId, this.requestOptions)
            .subscribe((data) => {
                onResolve(data)
            },
                (error) => {
                    onResolve(error);
                })
    }

    /**
     * Clear a question
     * @param moduleId 
     * @param feedbackId 
     * @param questionId 
     * @param onResolve 
     */
    clearQuestion(moduleId: string, feedbackId: string, questionId: string) {
        return (this.http.delete(PathApi.baseUrl + PathApi.dbSessionFeedbackClearResultQuestion + '?moduleId=' + moduleId + '&feedbackId=' + feedbackId + '&questionId=' + questionId, this.requestOptions).pipe(take(1)));
    }

    exportFeedbacks(eventId: string, moduleId: string, feedbackId: string, language: string, onResolve) {

        this.http.get(PathApi.baseUrl + PathApi.dbSessionFeedbackExportFeedback + '?eventId=' + eventId + '&moduleId=' + moduleId + '&feedbackId=' + feedbackId + '&language=' + language, this.requestOptions)
            .subscribe((data) => {
                onResolve(data)
            },
                (error) => {
                    onResolve(error);
                })
    }

    exportQuestion(eventId: string, moduleId: string, feedbackId: string, questionId: string, language: string, onResolve) {
        this.http.get(PathApi.baseUrl + PathApi.dbSessionFeedbackExportQuestionFeedback + '?eventId=' + eventId + '&moduleId=' + moduleId + '&feedbackId=' + feedbackId + '&questionId=' + questionId + '&language=' + language, this.requestOptions)
            .subscribe((data) => {
                onResolve(data)
            },
                (error) => {
                    onResolve(error);
                })
    }
}