import { NameModule } from "./name-module";

export class Document {
    uid: string;
    identifier: string
    moduleId: string
    folderId: string
    eventId: string
    storageId: string;
    url: string;
    name: NameModule;
    type: string;
    icon: string;
    createdAt: number;
    total_access: number;

    constructor(identifier: string, name: NameModule, eventId: string, moduleId: string, folderId: string, type: string) {
        this.uid = null;
        this.url = null;
        this.identifier = identifier
        this.name = name;
        this.eventId = eventId
        this.moduleId = moduleId
        this.folderId = folderId
        this.type = type;
        this.icon = this.checkType(type);
        this.storageId = null;
        this.total_access = 0;
    }
    
    checkType(type: string): string {
        let icon = '';
        switch (type) {
            case 'doc':
            case 'docx':
            case 'docm':
            case 'DOC':
            case 'DOCX':
            case 'DOCM':
                icon = 'doc_ico.png';
                break;


            case 'xls':
            case 'xlt':
            case 'xls':
            case 'xml':
            case 'xlsx':
            case 'xlsm':
            case 'xlsb':
            case 'xltx':
            case 'xltm':
            case 'XLS':
            case 'XLT':
            case 'XLS':
            case 'XML':
            case 'XLSX':
            case 'XLSM':
            case 'XLSB':
            case 'XLTX':
            case 'XLTM':
                icon = 'excel_ico.png';
                break;


            case 'pdf':
            case 'PDF':
                icon = 'pdf_ico.png';
                break;


            case 'png':
            case 'PNG':
                icon = 'png_ico.png';
                break;


            case 'jpg':
            case 'jpeg':
            case 'JPG':
            case 'JPEG':
                icon = 'jpg_ico.png';
                break;


            case 'ppt':
            case 'pptx':
            case 'thmx':
            case 'ppsx':
            case 'pps':
            case 'ppsm':
            case 'PPT':
            case 'PPTX':
            case 'THMX':
            case 'PPSX':
            case 'PPS':
            case 'PPSM':
                icon = 'ppt_ico.png';
                break;


            case 'mp4':
            case 'wmv':
            case '3gp':
            case 'avi':
            case 'mp3':
            case 'wav':
            case 'MP4':
            case 'WMV':
            case '3GP':
            case 'AVI':
            case 'MP3':
            case 'WAV':
                icon = 'media_ico.png';
                break;

            default:
                icon = 'generic-file_ico.png';
        }

        return icon;
    }
}