import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { GroupDiscussionsService } from 'src/app/providers/group-discussions/group-discussions.service';
import { GroupDiscussion } from 'src/app/models/group-discussion';
import { Participant } from 'src/app/models/participant';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { GlobalService } from 'src/app/providers/global/global.service';
import { DbGroupsProvider } from 'src/app/providers/database/db-groups';

@Component({
    selector: 'app-group-discussions-detail',
    templateUrl: './group-discussions-detail.component.html',
    styleUrls: ['./group-discussions-detail.component.scss']
})
export class GroupDiscussionsDetailComponent implements OnInit {
    @ViewChild('errorSwal') public errorSwal: SwalComponent;

    eventId: string;
    moduleId: string;
    groupId: string;
    q: string;
    q2: string;
    groupDiscussion$: Observable<GroupDiscussion>;
    event$: Observable<any>;
    sessions$: Observable<any[]>;
    eventUsers$: Observable<Participant[]>;
    submiting: boolean;
    userLanguage: string;
    error: string;
    groups = []
    selectedGroups = []
    loadingGr = true;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private global: GlobalService,
        private gdService: GroupDiscussionsService,
        private dbGroups: DbGroupsProvider,
    ) {
        this.eventId = this.route.parent.params['_value']['uid'];
        this.moduleId = this.route.snapshot.params['moduleId'];
        this.groupId = this.route.snapshot.params['groupId'];
        this.submiting = false;
        this.q = '';
        this.q2 = '';
    }

    ngOnInit(): void {
        this.groupDiscussion$ = this.gdService.groupDiscussion(this.groupId);
        this.groupDiscussion$.subscribe(gr => {
            if(gr.groups !== undefined){ 
                this.dbGroups.getGroupsOfEvent(this.eventId, (result) => {
                    this.groups = result;
                    this.selectedGroups = [...this.groups].filter(x => gr.groups.includes(x.uid)) 
                    this.loadingGr = false;
                });
            }
        });
        this.eventUsers$ = this.gdService.eventUsers(this.eventId);
        this.event$ = this.gdService.event(this.eventId);
        this.sessions$ = this.gdService.sesssionsByEvent(this.eventId);
    }

    redirectToList() {
        this.router.navigate([
            `/event/${this.eventId}/group-discussions/${this.moduleId}`
        ]);
    }

    getUserLanguage() {
        this.global.getLanguage((language) => {
            this.userLanguage = language;
        });
    }

    onPickUser(participants: Participant[], user: Participant, uid: string) {
        const idx = participants.findIndex((p) => p.uid === user.uid);

        if (~~idx) participants.push({ ...user, creator: user.uid === uid });
    }

    chooseGroup(gd: GroupDiscussion, uid){
        if(!gd.visibility && this.selectedGroups.every(x => x.uid !== uid))
            this.selectedGroups.push([...this.groups].filter(x => x.uid === uid)[0])
        this.q = ""
    }

    removeGroup(gr){
        this.selectedGroups = [...this.selectedGroups].filter(x => x.uid !== gr.uid)
    }

    onPickSesssion(sessions: any[], session: any) {
        const idx = sessions.findIndex((p) => p.id === session.id);
        if (~~idx) sessions.push(session);
    }

    onRemoveParticipant(participants: Participant[], participant: Participant) {
        const idx = participants.findIndex((p) => p.uid === participant.uid);

        if (~idx) participants.splice(idx, 1);
    }

    onRemoveSession(sessions: any[], session: any) {
        const idx = sessions.findIndex((p) => p.id === session.id);

        if (~idx) sessions.splice(idx, 1);
    }

    async onSubmit(gd: GroupDiscussion): Promise<void> {
        this.error = null;

        if (gd.id) {
            try {
                this.submiting = true;

                await this.gdService.update(this.eventId, {
                    ...gd,
                    // custom objects (Participant) not working, so using spread operator
                    participants: gd.participants.map((p) => {
                        return { ...p };
                    })
                }, this.selectedGroups);

                this.redirectToList();
            } catch (e) {
                this.error = e;

                this.errorSwal.fire();
            } finally {
                this.submiting = false;
            }
        } else {
            try {
                this.submiting = true;

                await this.gdService.add(this.eventId, {
                    ...gd,
                    // custom objects (Participant) not working, so using spread operator
                    participants: gd.participants.map((p) => {
                        return { ...p };
                    }),
                    eventId: this.eventId,
                    moduleId: this.moduleId
                },this.selectedGroups);

                this.redirectToList();
            } catch (e) {
                this.error = e;

                this.errorSwal.fire();
            } finally {
                this.submiting = false;
            }
        }
    }
}
