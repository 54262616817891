import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { PathApi } from '../../paths/path-api';
import { AngularFirestore } from '@angular/fire/firestore';
import { StorageService } from '../storage/storage.service';
import { Notification } from '../../models/notifications';

@Injectable({
    providedIn: 'root'
})
export class DbNotificationsProvider {
    public headers;
    public requestOptions;

    constructor(private http: HttpClient, private aFirestore: AngularFirestore, private storage: StorageService) {
        this.headers = new HttpHeaders();
        this.headers.append("Accept", 'application/json');
        this.headers.append("Content-Type", 'application/json');
        this.requestOptions = { headers: this.headers, body: {} };
    }

    getModule(moduleId: string, onResolve) {
        let db = this.aFirestore.firestore;

        db
            .collection('modules')
            .doc(moduleId)
            .get()
            .then((snapshot) => {
                onResolve(snapshot.data);
            })
    }

    getAllNotifications(eventId: string, typeOrder, onResolve) {
        let db = this.aFirestore.firestore;

        db
            .collection('events')
            .doc(eventId)
            .collection('notifications')
            .orderBy('createdAt', typeOrder)
            .onSnapshot((values) => {
                let notifications = [];
                if (values.size >= 1) {
                    values.forEach(element => {
                        notifications.push(element.data());
                    });
                }
                onResolve(notifications);
            });
    }

    getNotification(eventId: string, notificationId: string, onResolve) {
        let db = this.aFirestore.firestore;

        db
            .collection('events')
            .doc(eventId)
            .collection('notifications')
            .doc(notificationId)
            .onSnapshot((value) => {
                onResolve(value.data());
            });
    }

    changeOrderItems(eventId: string, typeOrder, onResolve) {
        let db = this.aFirestore.firestore;

        db
            .collection('events')
            .doc(eventId)
            .update({ notificationsOrder: typeOrder })
            .then((_) => {
                onResolve(true);
            })
            .catch((e) => {
                onResolve(false);
            });
    }

    createNotification(eventId: string, moduleId: string, notification: Notification, onResolve) {
        let db = this.aFirestore.firestore;
        let ref = db.collection('events').doc(eventId).collection('notifications').doc();
        notification.uid = ref.id;
        notification.moduleId = moduleId;
        notification.eventId = eventId;

        notification = Object.assign({}, notification);
        ref.set(notification)
            .then((_) => {
                onResolve(true);
            })
            .catch((e) => {
                onResolve(false);
            });
    }
    createMultipleNotification(eventId: string, moduleId: string, notifications: Notification[]) {
        let db = this.aFirestore.firestore;
        const batch = db.batch();
        notifications.forEach(n => {
            let doc = db.collection('events').doc(eventId).collection('notifications').doc();
            n.uid = doc.id;
            n.moduleId = moduleId;
            n.eventId = eventId;

            n = Object.assign({}, n);
            batch.set(doc, n)
        })
        return batch.commit();
    }

    deleteNotification(eventId: string, notificationId: string, onResolve) {
        let db = this.aFirestore.firestore;
        let ref = db.collection('events').doc(eventId).collection('notifications').doc(notificationId);
        ref.delete()
            .then((_) => {
                onResolve(true);
            })
            .catch((e) => {
                onResolve(false);
            });
    }

    async removeNotifications(moduleId, listRemoveUids, onResolve) {
        let newList = this.breakArray(listRemoveUids, 400);

        let cont = 0;
        for (let list of newList) {
            await this.removeListNotifications(moduleId, list)
                .then((data) => {
                    if (cont == newList.length - 1) {
                        onResolve(true);
                    }

                    cont++;
                })
                .catch((err) => {
                    if (cont == newList.length - 1) {
                        onResolve(err);
                    }

                    cont++;
                })
        }

    }

    removeListNotifications(eventId, listRemoveUids) {
        return new Promise((resolve, reject) => {
            let db = this.aFirestore.firestore;

            const batch = db.batch()

            // remove notifications. Activates trigger
            for (const notificationId of listRemoveUids) {
                const ref1 = db.collection('events').doc(eventId).collection('notifications').doc(notificationId)
                batch.delete(ref1)
            }

            // Commit the batch
            batch.commit().then(() => {
                resolve(true)
            }).catch((error) => {
                reject(error)
            })
        })
    }

    breakArray(base, max) {
        var resultado = [[]];
        var grupo = 0;

        if (base.length < max) {
            resultado[grupo] = base;
            return resultado;
        }

        for (var indice = 0; indice < base.length; indice++) {
            if (resultado[grupo] === undefined) {
                resultado[grupo] = [];
            }

            resultado[grupo].push(base[indice]);

            if ((indice + 1) % max === 0) {
                grupo = grupo + 1;
            }
        }

        return resultado;
    }
}