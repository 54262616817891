import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { PathApi } from '../../paths/path-api';
import { AngularFirestore } from '@angular/fire/firestore';
import firebase from 'firebase/app';
import { Training } from 'src/app/models/training';
import { Question } from 'src/app/models/training-question';
import { Answer } from 'src/app/models/training-answer';
import { TypeModule } from 'src/app/enums/type-module';
import { NotifiicationDateService } from '../luxon/notification-date.service';
import { createOfflineCompileUrlResolver } from '@angular/compiler';
import { StorageService } from '../storage/storage.service';
import { NameModule } from 'src/app/models/name-module';



@Injectable({ 
    providedIn: 'root'
})
export class DbTrainingProvider {
    private db: firebase.firestore.Firestore;
    public headers;
    public requestOptions;

    constructor(private http: HttpClient, private aFirestore: AngularFirestore, private luxon: NotifiicationDateService, private storage: StorageService) {
        this.headers = new HttpHeaders();
        this.headers.append("Accept", 'application/json');
        this.headers.append("Content-Type", 'application/json');
        this.requestOptions = { headers: this.headers };
    }

    getTrainingModuleAndTrainings(eventId: string, onResolve) {
        let db = this.aFirestore.firestore;
        let moduleId;
        let ref = db.collection('events').doc(eventId).collection('modules');
        let listTrainings = [];
        ref
            .where('type', '==', TypeModule.TRAINING)
            .get()
            .then((snapshot) => {
                if (snapshot.size >= 1) {
                    snapshot.forEach(element => {
                        moduleId = element.data().uid;
                    });

                    let refTraining = db.collection('modules').doc(moduleId).collection('trainings').orderBy('order', 'asc');

                    refTraining.get().then((data) => {
                        data.forEach(element => {
                            let training = element.data();
                            this.getQuestions(moduleId, training.uid, (questions) => {

                                training.questions = questions;
                                listTrainings.push(training);
                            })
                        });

                    })
                }
                onResolve({
                    trainings: listTrainings,
                    moduleId: moduleId
                })
            })
            .catch((e) => {
                onResolve(e);
            })

    }
    getTrainingModule(eventId: string, onResolve) {
        let db = this.aFirestore.firestore;
        let ref = db.collection('events').doc(eventId).collection('modules');

        ref
            .where('type', '==', TypeModule.TRAINING)
            .get()
            .then((snapshot) => {
                let auxModule = [];
                if (snapshot.size >= 1) {
                    snapshot.forEach(element => {
                        auxModule.push(element.data());
                    });
                }
                onResolve(auxModule);
            })
            .catch((e) => {
                onResolve(e);
            })
    }

    getTotalTrainings(moduleId, onResolve) {
        let db = this.aFirestore.firestore;
        let ref = db.collection('modules').doc(moduleId).collection('trainings');
        ref.onSnapshot((data) => {
            onResolve(data.size);
        });
    }

    changeOrder(moduleId, trainings, onResolve) {
        let db = this.aFirestore.firestore;
        let batch = db.batch();

        for(let training of trainings) {
            let ref = db.collection('modules').doc(moduleId).collection('trainings').doc(training.uid)
            batch.update(ref, { order: training.order })
        }

        batch.commit()
        .then(() => {
            onResolve(true)
        })
        .catch((e) => {
            onResolve(false)
        })
    }

    getTrainings(moduleId, onResolve) {
        let db = this.aFirestore.firestore;
        let ref = db.collection('modules').doc(moduleId).collection('trainings').orderBy('order', 'asc');
        ref.onSnapshot((data) => {
            let listTrainings = [];
            data.forEach(element => {
                let training = element.data();
                if (training.uid !== undefined) {
                    this.getQuestions(moduleId, training.uid, (questions) => {
                        training.questions = questions;
                        listTrainings.push(training);
                    });
                }
            });

            onResolve(listTrainings)
        });
    }

    getTraining(moduleId, trainingId, onResolve) {
        let db = this.aFirestore.firestore;
        let ref = db
            .collection('modules')
            .doc(moduleId)
            .collection('trainings')
            .doc(trainingId);

        ref.get()
            .then((data) => {
                let training = data.data();
                this.getQuestions(moduleId, trainingId, (questions) => {
                    training.questions = questions;
                    onResolve(training);
                })
            })
            .catch((err) => {
                console.log(err)
            })
    }

    getQuestions(moduleId: string, trainingId: string, onResolve) {
        let db = this.aFirestore.firestore;

        let listQuestions: Array<Question> = [];

        let refQuestions = db.collection('modules').doc(moduleId).collection('trainings').doc(trainingId).collection('questions')
            .orderBy('createdAt', 'asc');;

        refQuestions.get()
            .then((data) => {
                data.forEach(element => {
                    let question = this.instantiateQuestion(element.data());
                    listQuestions.push(question);
                });

                onResolve(listQuestions)
            })
    }

    getQuestion(moduleId: string, trainingId: string, questionId: string, onResolve) {
        let db = this.aFirestore.firestore;

        let refQuestion = db
            .collection('modules')
            .doc(moduleId)
            .collection('trainings')
            .doc(trainingId)
            .collection('questions')
            .doc(questionId);

        refQuestion.get()
            .then((data) => {
                onResolve(data.data());
            })
            .catch((err) => {
                console.log(err)
            })
    }

    getAnswers(moduleId: string, trainingId: string, questionId: string, onResolve) {
        let db = this.aFirestore.firestore;

        let refQuestions = db.
            collection('modules')
            .doc(moduleId)
            .collection('trainings')
            .doc(trainingId)
            .collection('questions')
            .doc(questionId)
            .collection('answers')
            .orderBy('createdAt', 'asc');;

        refQuestions.get()
            .then((data) => {
                let listAnswers: Array<Answer> = [];
                data.forEach(element => {
                    let answer: Answer = this.instantiateAnswer(element.data());
                    listAnswers.push(answer);
                });

                onResolve(listAnswers);
            })
            .catch((err) => {
                console.log(err)
            })
    }

    getResultAnswer(moduleId, trainingId, questionId, answerId, onResolve) {
        let db = this.aFirestore.firestore;

        let ref = db
            .collection('modules')
            .doc(moduleId)
            .collection('trainings')
            .doc(trainingId)
            .collection('questions')
            .doc(questionId)
            .collection('result');

        let listResults = [];
        ref.get()
            .then((data) => {

                if (data.size > 0) {

                    let listResultUser = [];
                    data.forEach(user => {
                        listResultUser.push(user);
                    });

                    let cont = 0;
                    for (let userResult of listResultUser) {
                        let userId = userResult.uid;

                        ref.doc(userId)
                            .get()
                            .then((dataResult) => {
                                let result = dataResult.data();

                                let contResult = 0;
                                for (let answer of result.answer) {
                                    if (answer == answerId) {
                                        listResults.push(userId);
                                    }

                                    if (contResult == result.answer.length - 1) {

                                        if (cont == listResultUser.length - 1) {
                                            onResolve(listResults);
                                        }

                                        cont++;
                                    }

                                    contResult++;
                                }


                            })
                    }
                } else {
                    onResolve(listResults);
                }

            })
    }

    instantiateQuestion(data) {
        let question = new Question();

        question.uid = data.uid;
        question.type = data.type;
        question.title = data.title;
        data.infobooth != undefined ? question.infobooth = data.infobooth : question.infobooth = new NameModule('', '', '', '', '');
        data.explanation != undefined ? question.explanation = data.explanation : question.explanation = new NameModule('', '', '', '', '');
        data.graphic != undefined ? question.graphic = data.graphic : question.graphic = null;
        data.visibility != undefined ? question.visibility = data.visibility : question.visibility = true;
        
        return question;
    }

    instantiateAnswer(data) {
        let answer = new Answer();
        answer.uid = data.uid;
        answer.answer = data.answer;
        data.weight != undefined ? answer.weight = data.weight : answer.weight = null;
        data.marker != undefined ? answer.marker = data.marker : answer.marker = null;
        data.correct != undefined ? answer.correct = data.correct : answer.correct = false;

        return answer;
    }

    changeVisibility(moduleId, trainingId, visibility) {
        let db = this.aFirestore.firestore;

        let ref = db
            .collection('modules')
            .doc(moduleId)
            .collection('trainings')
            .doc(trainingId);

        ref.update({
            visibility: visibility
        })
    }
    
    changeVisibilityQuestion(moduleId, trainingId, questionId, visibility) {
        let db = this.aFirestore.firestore;

        let ref = db
            .collection('modules')
            .doc(moduleId)
            .collection('trainings')
            .doc(trainingId)
            .collection('questions')
            .doc(questionId)

        ref.update({
            visibility: visibility
        }).then(() => {
            console.log('OOOK')
        })
    }

    createTraining(moduleId: string, training: Training, referenceModuleId: string, selected: any, timezone: string, eventId: string, onResolve) {
        const aux = training.question;
        delete training.question;

        let db = this.aFirestore.firestore;
        let trainingId;

        let ref = db.collection('modules').doc(moduleId).collection('trainings').doc();
        training.uid = ref.id;

        training.title = Object.assign({}, training.title)
        training = Object.assign({}, training);
        ref.set(training)
            .then(() => {

                if (training.type == 'SpecificSession' || training.type == 'SessionTrack' || training.type == 'SpecificGroup') {

                    this.forTrainingReferences(selected, training.uid, moduleId);
                }

                // if (training.type == 'SpecificSession') {
                // this.forTrainingSessionsUids(selected, trainingId, moduleId);
                // }
                // if (training.type == 'SessionTrack') {
                // this.forTrainingTracksUids(selected, trainingId, moduleId, referenceModuleId);
                // }
                // if (training.type == 'SpecificGroup') {
                // this.forTrainingGroupsUids(selected, trainingId, moduleId);
                // }

                this.createQuestions(moduleId, training.uid, aux, timezone, eventId, (data) => {
                    if (data == true) {
                        onResolve(data);
                    }
                });
            })
            .catch((error) => {

            })
    }

    forTrainingReferences(ids, trainingId, moduleId) {
        //Limpa referencias anteriores antes de gravar as novas

        let db = this.aFirestore.firestore;
        db.collection('modules').doc(moduleId).collection('trainings').doc(trainingId).update({
            references: firebase.firestore.FieldValue.delete()
        }).then(() => {
            for (const item of ids) {
                db.collection('modules').doc(moduleId).collection('trainings').doc(trainingId).update({
                    references: firebase.firestore.FieldValue.arrayUnion(item.uid)
                })
            }
        })
    }

    /**
   * Create question
   */
    createQuestions(moduleId: string, idTraining: string, question: Array<Question>, timezone, eventId, onResolve) {
        let db = this.aFirestore.firestore;
        let contTime = 0;
        let cont = 0;
        for (let data of question) {
            setTimeout(() => {
                const answers = data.answers;
                delete data.answers;

                let file = data.image;
                data.image = null;

                let questionId;

                let ref = db.collection('modules').doc(moduleId).collection('trainings').doc(idTraining).collection('questions')
                data.createdAt = this.luxon.getTimeStampFromDateNow(new Date(), timezone);

                data = Object.assign({}, data);
                data.title = Object.assign({}, data.title);
                data.infobooth = Object.assign({}, data.infobooth);
                data.explanation = Object.assign({}, data.explanation);

                ref.add(data)
                    .then((docRef) => {
                        questionId = docRef.id;

                        ref.doc(questionId).update({ uid: questionId })
                            .then(() => {
                                if (file != null && file != undefined && file !== '') {
                                    this.storage.uploadQuestionTrainingImage(file, moduleId, idTraining, questionId, eventId).then((url) => {
                                        ref.doc(questionId).update({ image: url }).then(() => {
                                            if (data.type == 'oneSelect' || data.type == 'multipleSelect') {
                                                this.createAnswers(moduleId, idTraining, questionId, answers, timezone);
                                            }

                                            if (cont == question.length - 1) {
                                                onResolve(true);
                                            }

                                            cont++;
                                        })
                                    })
                                } else {

                                    if (data.type == 'oneSelect' || data.type == 'multipleSelect') {
                                        this.createAnswers(moduleId, idTraining, questionId, answers, timezone);
                                    }

                                    if (cont == question.length - 1) {
                                        onResolve(true);
                                    }

                                    cont++;
                                }

                            })
                    })
            }, contTime * 30);

            contTime++;
        }
    }

    /**
   * Create answer
   */
    createAnswers(moduleId, idTraining, idQuestion: string, answers: Array<Answer>, timezone) {
        let db = this.aFirestore.firestore;

        let cont = 0;
        for (let data of answers) {
            setTimeout(() => {
                let ref = db.collection('modules')
                    .doc(moduleId)
                    .collection('trainings')
                    .doc(idTraining)
                    .collection('questions')
                    .doc(idQuestion)
                    .collection('answers');

                data.createdAt = this.luxon.getTimeStampFromDateNow(new Date(), timezone);
                data = Object.assign({}, data);
                data.answer = Object.assign({}, data.answer)

                ref.add(data).then((docRef) => {
                    let answerId = docRef.id;

                    ref.doc(answerId).update({ uid: answerId })
                });
            }, cont * 30);

            cont++;
        }
    }

    deleteAnswer(moduleId, trainingId, questionId, answerId, onResolve) {
        let db = this.aFirestore.firestore;

        let ref = db.collection('modules')
            .doc(moduleId)
            .collection('trainings')
            .doc(trainingId)
            .collection('questions')
            .doc(questionId)
            .collection('answers')
            .doc(answerId);

        ref.delete()
            .then(() => {
                onResolve(true);
            })
            .catch(() => {
                onResolve(false);
            })
    }

    updateTraining(moduleId, trainingId, training, onResolve) {

        let db = this.aFirestore.firestore;

        let ref = db.collection('modules')
            .doc(moduleId)
            .collection('trainings')
            .doc(trainingId);

        training = Object.assign({}, training);
        training.title = Object.assign({}, training.title)
        ref.update(training).then(() => {
            onResolve(true);
        })
    }

    updateQuestion(moduleId, trainingId, questionId, newQuestion, timezone, eventId, onResolve) {
        let db = this.aFirestore.firestore;

        newQuestion.uid = questionId;
        const answers = newQuestion.answers;
        delete newQuestion.answers;

        let file = newQuestion.image;
        delete newQuestion.image;

        let ref = db.collection('modules')
            .doc(moduleId)
            .collection('trainings')
            .doc(trainingId)
            .collection('questions')
            .doc(questionId);

        newQuestion = Object.assign({}, newQuestion);
        newQuestion.title = Object.assign({}, newQuestion.title);
        newQuestion.infobooth = Object.assign({}, newQuestion.infobooth);
        newQuestion.explanation = Object.assign({}, newQuestion.explanation);

        ref.update(newQuestion)
            .then(() => {
                if (newQuestion.type == 'oneSelect' || newQuestion.type == 'multipleSelect') {

                    if (file !== null && file !== undefined) {
                        this.storage.uploadQuestionTrainingImage(file, moduleId, trainingId, questionId, eventId).then((url) => {
                            ref.update({ image: url }).then(() => {
                                this.updateAnswer(moduleId, trainingId, questionId, answers, timezone, (data) => {
                                    if (data) {
                                        onResolve(data);
                                    }
                                });
                            })
                        })
                    } else {
                        this.updateAnswer(moduleId, trainingId, questionId, answers, timezone, (data) => {
                            if (data) {
                                onResolve(data);
                            }
                        });
                    }
                }
            })
            .catch(() => {
                onResolve(false);
            })
    }

    updateAnswer(moduleId: string, trainingId: string, questionId: string, answers: Array<Answer>, timezone, onResolve) {
        let db = this.aFirestore.firestore;

        let ref = db.collection('modules')
            .doc(moduleId)
            .collection('trainings')
            .doc(trainingId)
            .collection('questions')
            .doc(questionId);

        let cont = 0;
        for (let newAnswer of answers) {
            if (newAnswer.weight == undefined) {
                newAnswer.weight = null;
            }

            let auxAnswer = newAnswer;
            let auxAnswerId = newAnswer.uid;
            if (auxAnswerId !== undefined) {

                auxAnswer = Object.assign({}, auxAnswer);
                auxAnswer.answer = Object.assign({}, auxAnswer.answer);

                ref.collection('answers')
                    .doc(auxAnswerId)
                    .update(auxAnswer)
                    .then(() => {
                        if (cont == answers.length - 1) {
                            onResolve(true);
                        }

                        cont++;
                    })
            } else {
                auxAnswer.createdAt = this.luxon.getTimeStampFromDateNow(new Date(), timezone);
                auxAnswer = Object.assign({}, auxAnswer);
                auxAnswer.answer = Object.assign({}, auxAnswer.answer)
                ref.collection('answers')
                    .add(auxAnswer)
                    .then((data) => {

                        let answerId = data.id;
                        ref.collection('answers')
                            .doc(answerId)
                            .update({ uid: answerId })
                            .then(() => {
                                if (cont == answers.length - 1) {
                                    onResolve(true);
                                }

                                cont++;
                            })
                    })
            }
        }
    }

    createOneQuestions(moduleId, trainingId, newQuestion, timezone, eventId, onResolve) {
        let db = this.aFirestore.firestore;

        let answers = newQuestion.answers;
        delete newQuestion.answers;

        let file = newQuestion.image;
        newQuestion.image = null;

        let ref = db.collection('modules')
            .doc(moduleId)
            .collection('trainings')
            .doc(trainingId)
            .collection('questions');

        newQuestion.createdAt = this.luxon.getTimeStampFromDateNow(new Date(), timezone);
        newQuestion = Object.assign({}, newQuestion);
        newQuestion.title = Object.assign({}, newQuestion.title);
        newQuestion.infobooth = Object.assign({}, newQuestion.infobooth);
        newQuestion.explanation = Object.assign({}, newQuestion.explanation);
        ref.add(newQuestion)
            .then((data) => {
                let questionId = data.id;

                ref.doc(questionId).update({
                    uid: questionId
                })
                    .then(() => {
                        if (file != null && file !== undefined && file !== '') {
                            this.storage.uploadQuestionTrainingImage(file, moduleId, trainingId, questionId, eventId).then((url) => {
                                ref.doc(questionId).update({ image: url })
                                    .then(() => {
                                        if (newQuestion.type == 'oneSelect' || newQuestion.type == 'multipleSelect') {
                                            // answers = Object.assign({}, answers);
                                            this.createAnswers(moduleId, trainingId, questionId, answers, timezone);

                                            onResolve({
                                                status: true,
                                                questionId: questionId
                                            });
                                        }
                                    })
                            })
                        } else {
                            if (newQuestion.type == 'oneSelect' || newQuestion.type == 'multipleSelect') {
                                // answers = Object.assign({}, answers);
                                this.createAnswers(moduleId, trainingId, questionId, answers, timezone);

                                onResolve({
                                    status: true,
                                    questionId: questionId
                                });
                            }
                        }

                    })
            })
            .catch((err) => {
                onResolve(err);
            })
    }

    removeTraining(moduleId, trainingId, onResolve) {
        this.http.delete(PathApi.baseUrl + PathApi.dbTrainingDeleteTraining + '?moduleId=' + moduleId + '&trainingId=' + trainingId, this.requestOptions)
            .subscribe((data) => {
                onResolve(data)
            },
                (error) => {
                    onResolve(error);
                })
    }

    clearAllTrainings(moduleId: string, trainingsIds: Array<string>, onResolve) {
        this.requestOptions.params.trainingsIds = trainingsIds;
        this.http.delete(PathApi.baseUrl + PathApi.dbTrainingClearAll + '?moduleId=' + moduleId, this.requestOptions)
            .subscribe((data) => {
                onResolve(data);
            })
    }

    clearTraining(moduleId: string, trainingId: string, onResolve) {
        this.http.delete(PathApi.baseUrl + PathApi.dbTrainingClearResultTraining + '?moduleId=' + moduleId + '&trainingId=' + trainingId, this.requestOptions)
            .subscribe((data) => {
                onResolve(data)
            },
                (error) => {
                    onResolve(error);
                })
    }

    removeQuestion(moduleId: string, trainingId: string, questionId: string, onResolve) {
        this.http.delete(PathApi.baseUrl + PathApi.dbTrainingDeleteQuestion + '?moduleId=' + moduleId + '&trainingId=' + trainingId + '&questionId=' + questionId, this.requestOptions)
            .subscribe((data) => {
                onResolve(data)
            },
                (error) => {
                    onResolve(error);
                })
    }

    clearQuestion(moduleId: string, trainingId: string, questionId: string, onResolve) {
        this.http.delete(PathApi.baseUrl + PathApi.dbTrainingClearResultQuestion + '?moduleId=' + moduleId + '&trainingId=' + trainingId + '&questionId=' + questionId, this.requestOptions)
            .subscribe((data) => {
                onResolve(data)
            },
                (error) => {
                    onResolve(error);
                })
    }

    exportTrainings(moduleId: string, trainingId: string, language: string, onResolve) {
        console.log('call export trainings')
        console.log(moduleId, trainingId, language)

        this.http.get(PathApi.baseUrl + PathApi.dbTrainingExportTraining + '?moduleId=' + moduleId + '&trainingId=' + trainingId + '&language=' + language, this.requestOptions)
            .subscribe((data) => {
                onResolve(data)  
            },
            (error) => {
                onResolve(error);
            })
    }

    exportQuestion(moduleId: string, trainingId: string, questionId: string, language: string, onResolve) {
        this.http.get(PathApi.baseUrl + PathApi.dbTrainingExportQuestionTraining + '?moduleId=' + moduleId + '&trainingId=' + trainingId + '&questionId=' + questionId + '&language=' + language, this.requestOptions)
            .subscribe((data) => {
                onResolve(data)
            },
            (error) => {
                onResolve(error);
            })
    }
}