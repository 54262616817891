import { Component, NgZone, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { EmailEditorComponent } from "angular-email-editor";
import { Attendee } from "src/app/models/attendees";
import { DbGroupsProvider } from "src/app/providers/database/db-groups";
import { EmailProvider } from "src/app/providers/email/email.service";
import { DbAttendeesProvider } from "src/app/providers/database/db-attendees";
import { SwalComponent } from "@sweetalert2/ngx-sweetalert2";
import { DestinationType } from "src/app/enums/type-destination-email";
import { DbEventsProvider } from "src/app/providers/database/db.events";
import { DbMailingProvider } from "src/app/providers/database/db-mailing";
import { Email } from "src/app/models/email";
import Swal from "sweetalert2";
import { TranslateService } from "@ngx-translate/core";

const EMAIL_REGEX =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

@Component({
    selector: "app-create-email",
    templateUrl: "./create-email.component.html",
    styleUrls: ["./create-email.component.scss"],
})
export class CreateEmailComponent implements OnInit {
    @ViewChild(EmailEditorComponent)
    private emailEditor: EmailEditorComponent;

    @ViewChild("successEmailSwal") public successEmailSwal: SwalComponent;
    @ViewChild("errorEmailSwal") public errorEmailSwal: SwalComponent;

    title = "";
    object = "";
    destination = "-1";
    sender = "";

    eventTitle: string;
    eventId: string;
    savedEmailId: string;

    groups = [];
    selectedGroups = [];

    q: string = "";
    errorEmailBody: boolean;
    errorEmailTitle;
    errorEmailObject;
    errorEmailDestination: boolean;
    errorEmailSender: boolean;
    principalEventLanguage = "";
    errorEmailEmpty: boolean;
    errorEmailsTest: boolean;

    showGroups = false;
    savingLoader = false;
    emailTestDestination = "";
    extractedTestEmails: string[] = [];

    attendees = [];
    speakers = [];
    constructor(
        private route: ActivatedRoute,
        private emailSvc: EmailProvider,
        private dbGroups: DbGroupsProvider,
        private dbAttendees: DbAttendeesProvider,
        private dbEvents: DbEventsProvider,
        private dbMailing: DbMailingProvider,
        private router: Router,
        private zone: NgZone,
        private translateService: TranslateService
    ) {
        this.eventId = this.route.parent.params["_value"]["uid"];
        this.dbEvents.getEvent(this.eventId, (ev) => {
            this.eventTitle = ev.title;
            this.sender = this.eventTitle;
            this.principalEventLanguage = ev.language;
        });
    }

    async ngOnInit() {
        this.dbGroups.getGroupsOfEvent(this.eventId, (result) => {
            this.groups = result.sort((a, b) =>
                a.name.toLowerCase() > b.name.toLowerCase()
                    ? 1
                    : a.name.toLowerCase() < b.name.toLowerCase()
                    ? -1
                    : 0
            );
        });
        this.attendees = await this.dbEvents.getAttendeesEvent(this.eventId);
        this.speakers = await this.dbEvents.getSpeakersEvent(this.eventId);
    }

    chooseGroup(uid) {
        if (this.selectedGroups.every((x) => x.uid !== uid))
            this.selectedGroups.push(
                [...this.groups].filter((x) => x.uid === uid)[0]
            );
        this.q = "";
    }

    removeGroup(gr) {
        this.selectedGroups = [...this.selectedGroups].filter(
            (x) => x.uid !== gr.uid
        );
    }

    selectDestinationType() {
        this.showGroups =
            this.destination === DestinationType.GROUPS.toString();
    }

    sendEmail(type: string) {
        this.errorEmailTitle = false;
        this.errorEmailObject = false;
        this.errorEmailSender = false;
        this.errorEmailDestination = false;
        this.errorEmailEmpty = false;
        this.errorEmailsTest = false;

        if (!this.title.trim()) {
            this.errorEmailTitle = true;
            return;
        }
        if (!this.object.trim()) {
            this.errorEmailObject = true;
            return;
        }
        if (!this.sender.trim()) {
            this.errorEmailSender = true;
            return;
        }
        if (this.destination == "-1" && type === "normal") {
            this.errorEmailDestination = true;
            return;
        }
        if (type === "email-test" && this.extractedTestEmails.length === 0) {
            this.errorEmailsTest = true;
            return;
        }
        this.emailEditor.editor.exportHtml(async (data) => {
            console.log("Data: ", data);
            const rows = data.design.body.rows;
            const design = JSON.stringify(data.design);

            if (
                !rows.every((r) =>
                    r.columns.every((c) => c.contents.length === 0)
                )
            ) {
                // check if there is at least one component
                let receivers = "";
                let attendeesEmail = [];
                let speakersEmail = [];
                let des: DestinationType;
                switch (Number(this.destination)) {
                    case DestinationType.ALL:
                        attendeesEmail = [...this.attendees].map(
                            (a) => a.email
                        );
                        speakersEmail = [...this.speakers].map((s) => s.email);
                        des = DestinationType.ALL;
                        break;
                    case DestinationType.GROUPS:
                        des = DestinationType.GROUPS;
                        attendeesEmail = [...this.attendees]
                            .filter((a) =>
                                Object.keys(a.groups).some((k) =>
                                    [...this.selectedGroups]
                                        .map((x) => x.uid)
                                        .includes(k)
                                )
                            )
                            .map((_) => _.email);
                        speakersEmail = [...this.speakers]
                            .filter((a) =>
                                Object.keys(a.groups).some((k) =>
                                    [...this.selectedGroups]
                                        .map((x) => x.uid)
                                        .includes(k)
                                )
                            )
                            .map((_) => _.email);
                        break;
                    case DestinationType.CONNECTED_USERS:
                        des = DestinationType.CONNECTED_USERS;
                        attendeesEmail = [...this.attendees]
                            .filter((a) => a.firstAccess === false)
                            .map((a) => a.email);
                        speakersEmail = [...this.speakers]
                            .filter((a) => a.firstAccess === true)
                            .map((s) => s.email);
                        break;
                    case DestinationType.NOT_CONNECTED_USERS:
                        des = DestinationType.NOT_CONNECTED_USERS;
                        attendeesEmail = [...this.attendees]
                            .filter((a) => !(a.firstAccess === false))
                            .map((a) => a.email);
                        speakersEmail = [...this.speakers]
                            .filter((a) => !(a.firstAccess === true))
                            .map((s) => s.email);
                        break;
                }
                receivers = [...attendeesEmail, ...speakersEmail]
                    .filter((x) => x)
                    .join(",");

                const email = new Email(
                    this.title,
                    this.object,
                    this.eventId,
                    des
                );
                email.sender = this.sender;
                email.receivers = receivers;
                email.groups = [...this.selectedGroups].map((x) => x.uid);

                const fakeDomainName = this.eventTitle
                    .toLowerCase()
                    .split(" ")
                    .filter(
                        (x) => (x >= "a" && x <= "z") || (x >= "0" && x <= "9")
                    )
                    .join("");
                const from = `${this.sender} <noreplay@${fakeDomainName}.com>`;
                let emailRequest = {
                    title: this.object,
                    body: data.html,
                    email: receivers,
                    tag: this.eventId,
                    from: from,
                };

                if (type === "save") {
                    email.status = 0;
                    email.destinationType = des ? des : -1;
                    if (this.savedEmailId) {
                        this.savingLoader = true;
                        email.uid = this.savedEmailId;
                        email.design = design;
                        // update
                        const res = await this.dbMailing.saveEmail(
                            this.eventId,
                            email,
                            emailRequest.body
                        );
                        this.savingLoader = false;
                        if (res) {
                            this.successEmailSwal.fire();
                        } else {
                            this.errorEmailSwal.fire();
                        }
                    } else {
                        // add new
                        Swal.fire(
                            this.translateService.instant(
                                "comp.mailing.swal_adding"
                            )
                        );
                        Swal.showLoading();
                        const res = await this.dbMailing.createEmail(
                            this.eventId,
                            email,
                            emailRequest.body,
                            design
                        );
                        if (res.value) {
                            this.savedEmailId = res.id;
                            Swal.hideLoading();
                            this.successEmailSwal.fire();
                        }
                    }
                } else if (type === "email-test") {
                    const emailDB = new Email(
                        this.title,
                        this.object,
                        this.eventId,
                        DestinationType.SPECIFIC
                    );
                    emailDB.status = 1;
                    emailDB.isTest = true;
                    emailDB.receivers = this.extractedTestEmails.join(",");
                    emailDB.sender = this.sender;
                    const fakeDomainName = this.eventTitle
                        .toLowerCase()
                        .split(" ")
                        .filter(
                            (x) =>
                                (x >= "a" && x <= "z") || (x >= "0" && x <= "9")
                        )
                        .join("");
                    const from = `${this.sender} <noreplay@${fakeDomainName}.com>`;
                    Swal.fire(
                        this.translateService.instant(
                            "comp.mailing.swal_waiting"
                        )
                    );
                    Swal.showLoading();

                    emailRequest.email = emailDB.receivers;
                    this.emailSvc.sendEmailToUser(emailRequest, async (res) => {
                        // this.loaderIndividualEmail = false;
                        console.log("sendEmailToUser", res);
                        if (res["result"]) {
                            emailDB.deliveredAt = (Date.now() / 1000) | 0;
                            email.mailGunId = res["mailgunID"];
                            const valueAdd = await this.dbMailing.createEmail(
                                this.eventId,
                                emailDB,
                                emailRequest.body,
                                design
                            );
                            Swal.close();
                            if (valueAdd.value) {
                                this.zone.run(() => {
                                    this.redirectToList();
                                });
                                this.successEmailSwal.fire();
                            } else {
                                console.log("error adding email to DB");
                            }
                            this.selectedGroups = [];
                        } else {
                            console.log("error sending mail");
                            this.errorEmailSwal.fire();
                        }
                    });
                } else {
                    Swal.fire(
                        this.translateService.instant(
                            "comp.mailing.swal_adding"
                        )
                    );
                    Swal.showLoading();
                    email.status = 1;
                    this.emailSvc.sendEmailToUser(emailRequest, async (res) => {
                        console.log("sendEmailToUser", res);
                        // this.loaderIndividualEmail = false;
                        if (res["result"]) {
                            email.deliveredAt = (Date.now() / 1000) | 0;
                            email.mailGunId = res["mailgunID"];
                            const result = await this.dbMailing.createEmail(
                                this.eventId,
                                email,
                                emailRequest.body,
                                design
                            );
                            Swal.close();
                            if (result.value) {
                                this.successEmailSwal.fire();
                                this.selectedGroups = [];
                                this.zone.run(() => {
                                    this.redirectToList();
                                });
                            } else {
                                console.log("error adding email to DB");
                                this.errorEmailSwal.fire();
                            }
                        } else {
                            console.log("error sending mail");
                            this.errorEmailSwal.fire();
                        }
                    });
                }
            } else {
                this.errorEmailEmpty = true;
            }
        });
    }

    testEmailsChanged() {
        this.extractedTestEmails = this.emailTestDestination
            .split(";")
            .filter((e) => EMAIL_REGEX.test(e.trim()));
    }

    redirectToList() {
        this.router.navigate([`/event/${this.eventId}/mailing`]);
    }
}
