import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { PathApi } from '../../paths/path-api';
import { Session } from '../../models/session';
import firebase from 'firebase/app';
import { AngularFirestore } from '@angular/fire/firestore';
import { TypeModule } from 'src/app/enums/type-module';


@Injectable({
    providedIn: 'root'
})

export class DbPersonalScheduleProvider {
    public headers;
    public requestOptions;
    private db: firebase.firestore.Firestore

    constructor(
        private http: HttpClient,
        private aFirestore: AngularFirestore
    ) {
        this.headers = new HttpHeaders();
        this.headers.append("Accept", 'application/json');
        this.headers.append("Content-Type", 'application/json');
        this.requestOptions = { headers: this.headers };

        this.db = firebase.firestore()
    }

    getModule(moduleId, onResolve){
        const ref = this.db.collection('modules').doc(moduleId)

        ref.get().then((snapshot) => {
            onResolve(snapshot.data())
        })
    }

    contModulesPersonalAgendaEvent(eventId) {
        return new Promise((resolve) => {
            const ref = this.db.collection('events').doc(eventId).collection('modules').where('type', '==', TypeModule.PERSONALSCHEDULE)

            ref.get().then((snapshot) => {
                resolve(snapshot.size)
            })

        })
    }

    // oads the sessions with the number of participants registered in the weight calendar.
    getSessions(eventId: string, onResolve) {
       this.http.get(PathApi.baseUrl + PathApi.dbPersonalScheduleGetAllSessions + '?eventId=' + eventId, this.requestOptions).subscribe(
            data => {
                onResolve(data['result']['sessions'])
            }
        )
    }

    getPromiseSessions(eventId: string) : Promise<any[]> {
        return new Promise((resolve,reject) => {
            this.http.get(PathApi.baseUrl + PathApi.dbPersonalScheduleGetAllSessions + '?eventId=' + eventId, this.requestOptions).subscribe(
                data => {
                    resolve(data['result']['sessions'])
                }
            )
        })
    }

     // loads all sessions from the personal calendar.
    async getSessionsModuleForAttendees(modulePersonalAgendaId: string) : Promise<any[]>{
        let db = this.aFirestore.firestore;
        const refs = []
        const snapshot = await db.collection('modules').doc(modulePersonalAgendaId).collection('attendees').get()
        const attendees = []
        for(let i = 0;i < snapshot.docs.length ;i++){
            const attendeeId = snapshot.docs[i].data().uid
            attendees.push(attendeeId)
            refs.push(db.collection('modules').doc(modulePersonalAgendaId).collection('attendees').doc(attendeeId).collection('sessions').orderBy('startTime').get())
        }
        // snapshot.docs.forEach(doc => {
        //     const attendeeId = doc.data().uid
        //     attendees.push(attendeeId)
        //     refs.push(db.collection('modules').doc(modulePersonalAgendaId).collection('attendees').doc(attendeeId).collection('sessions').orderBy('startTime').get())
        // })
        const result = await Promise.all(refs)
        let finalResult = []
        result.forEach((r,index) => {
            finalResult.push({userId: attendees[index],sessions: [...r.docs].map(_ => _.data())})
        })
        // return finalResult.reduce((accumulator, currentValue) => accumulator.concat(currentValue))
        return finalResult.filter(item => item.sessions.length>0)
    }

}