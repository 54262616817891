import { Injectable } from '@angular/core';
import * as timezones from 'timezones.json';
import { DateTime } from "luxon";

@Injectable({
    providedIn: 'root'
})
export class DateTimeService {

    public deviceLocale = (navigator.languages && navigator.languages.length) ? 
        navigator.languages[0] : 
        navigator.language;
    
    public deviceTimeZone: string = Intl.DateTimeFormat().resolvedOptions().timeZone; // tz identifier (like "America/Sao_Paulo"

    constructor() {}

    /**
     * @returns a list of timezones (list of Timezone.text from timezones.json)
     */
    getTimeZones() {
        let tzs: string[] = [];
        timezones.default.forEach(tz => { tzs.push(tz.text); });
        return tzs;
    }

    /**
     * return a timezone identifier of a timezone passed as ("Timezone.text" of Timezone interface) param 
     */
    findTzIdentifier(chosenTZ: string): string {
        return timezones.default.find(tz => { return tz.text == chosenTZ; }).utc.find(tzIdentifier => tzIdentifier);
    }

    /**
     * receives a timezone identifier and convert it to a descriptive text of it (i.e "Timezone.text" of Timezone interface)
     */
    findTzText(TZidentifier: string): string {
        const aux = timezones.default.find(tz => { return tz.utc[0] == TZidentifier; });
        return (aux == undefined) ? TZidentifier : aux.text;
    }

    /**
     * @returns the "database time" i.e. the UTC time as a ISO string (e.g. "2000-12-31T12:23:00.000Z")
     * @param dateTime if no value is passed the current date is used
     */
    public dbTime(dateTime?: string | number | Date): string {
        return (dateTime == null) ? new Date().toISOString() : new Date(dateTime).toISOString();
    }

    /**
     * @param year 
     * @param month 
     * @param day 
     * @param hours the default value is "00"
     * @param minutes the default value is "00"
     * @param seconds the default value is "00"
     * @returns a timestamp of the date provided by the arguments passed to the method
     * @description make a date timestamp
     */
    public makeDateTp(
        year: string | number, 
        month: string | number, 
        day: string | number, 
        hours: string | number = "00", 
        minutes: string | number = "00", 
        seconds: string | number = "00"
    ) {
        const date: string = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`
        return new Date(date).valueOf();
    }

    /**
     * Convert the provided date to the intended timezone
     * @param dateTime
     * @param intendedTZ timezone to convert to
     * @param locale if not provided uses the device locale
     */
    public convertTimeZone(
        dateTime: number | string | Date, 
        intendedTZ: string, 
        locale?: string, 
        options?: Intl.DateTimeFormatOptions
    ): string {
        locale  = (locale == null) ? this.deviceLocale : locale;
        options = (options == null) ? { timeZone: intendedTZ } : options;
        return new Date(dateTime).toLocaleString(locale, options)
        //return new Date(new Date(date) + this.eventTimeZone).toLocaleString(locale, {timeZone: this.deviceTimeZone});
    }

    /**
     * @param date accepts a date or timestamp of one
     * @param locale the default value is the deviceLocale
     * @returns a string with the date in the locale format
     */
    public getDate(date: number | Date | string, locale: string = this.deviceLocale): string {
        return new Date(date).toLocaleDateString(locale, { year: "numeric", month: "2-digit", day: "2-digit" });
    }

    /**
     * @param date accepts a date or timestamp of one
     * @param locale the default value is the deviceLocale
     * @returns a string with the time (e.g. 3:25 PM or 15:25) in the locale format
     */
    public getTime(date: number | Date | string, mustB24hFormat: boolean = false, locale: string = this.deviceLocale): string {
        const options: Intl.DateTimeFormatOptions = {
            hour: "2-digit",
            minute: "2-digit"
        };
        if (mustB24hFormat) { options["hour12"] = false; }

        return new Date(date).toLocaleTimeString(locale, options);
    }

    /**
     * @description get a ISO date from a ISO string
     * @param date 
     * @returns 
     */
    public getISODate(date: String) {
        return date.substring(0, 10);
    }

    /**
     * @description create timestamp with a set timezone
     * @param timezone receives a timezone identifier or uses the default value (the device time zone)
     * @param year  yyyy
     */
    public createDateInTZ(
        year: string, 
        month: string, 
        day: string, 
        hour?: string, 
        minute?: string, 
        timezone: string = this.deviceTimeZone
    ) {
        const timeSetup = (value?: string): string => {
            return (value == null) ? "00" : (parseInt(value) < 10) ? `0${parseInt(value)}` : value;
        }

        const date = `${year}-${timeSetup(month)}-${timeSetup(day)}T${timeSetup(hour)}:${timeSetup(minute)}:00`;
        //const date = year + '-' + month + '-' + day + 'T' + hour + ':' + minute + ':00';
        return DateTime.fromISO(date, { zone: timezone });
    }

    /**
     * 
     * @param date 
     * @param locale 
     * @param timezone 
     * @returns an array with a date (index 0) and a time (index 1), e.g. for locale equal to "en-US" 
     * the method should return something like this: ["12/31/2023", 07:30 PM].
     */
    public getDateTime(date: string | number | Date, locale?: string, timezone?: string) {
        locale  = (locale == null) ? this.deviceLocale : locale;
        timezone = (timezone == null) ? this.deviceTimeZone : timezone;

        const options: Intl.DateTimeFormatOptions = {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            timeZone: timezone
        }

        const dateTime = this.convertTimeZone(date, timezone, locale, options);
        return dateTime.split(", ");
    }

    /**
     * @description receive the event start or end date in ISO string format 
     * (e.g. "2023-12-31T02:00:00Z") and return the substring containing only 
     * the date [yyyy-mm-dd]
     * @param date 
     * @returns 
     */
    public formatDate(date: string) {
        return date.substring(0, 10);
    }
}
