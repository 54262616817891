import { NameModule } from "./name-module";
import { UUID } from "angular2-uuid";

/**
 * Custom Fields Selectable Answer
 */
export class CFSAnswer {
    order: number;
    value: NameModule;
    id: string;

    constructor(order: number, value?: NameModule) {
        this.order = order;
        this.value = value ?? new NameModule("", "", "", "", "");
        this.id = UUID.UUID();
    }
}
export class eventCustomField {
    uid: string;
    name: NameModule;
    type: String;
    exibicao: String;
    edicao: string;
    value: string;
    textValue: NameModule;
    order: number;
    icon: string;
    iconFamily: string;
    answerOptions: any[];
    answerSelected: string[];   // store answer ids

    constructor(name: NameModule, type: string, exibicao: string, edicao: string) {
        this.uid = null;
        this.name = new NameModule(name.PtBR, name.EnUS, name.EsES, name.FrFR, name.DeDE);
        this.type = type;
        this.exibicao = exibicao;
        this.edicao = edicao;
        this.value = '';
        if (type == 'text') {
            this.textValue = new NameModule('', '', '', '', '');
        }
    }
}