import { Name } from './name';

/**
 * Represents a user with firebase db data
 * @attribute uid
 * @attribute name
 * @attribute email
 * @attribute company
 * @attribute title
 * @attribute description
 * @attribute type
 * @attribute events
 * @attribute facebook
 * @attribute instagram
 * @attribute twitter
 * @attribute linkedin
 * @attribute website
 * @attribute picture
 * @attribute selfEditLink
 * @attribute aboutMe
 * @attribute group
 * @export
 * @class User
 */

export class User {
     private uid: string;
     private identifier: string;
     private name: string;
     private queryName: string;
     private email: string;
     private emailRecovery: string;
     private company: string;
     private title: string;
     private description: string;
     private type: number;
     private events;
     private facebook: string;
     private phone: string;
     private instagram: string;
     private twitter: string;
     private linkedin: string;
     private website: string;
     private picture: string;
     private selfEditLink: string;
     private aboutMe: string;
     private password: string;
     private photoUrl: string;
     private language: string;
     private checkin: boolean;
     private index: number;
     private token: string;
     private firstAccess: boolean;
     private points: number;
     private groups: any;
     private customFields: any;
     private terms_use: string;
     private privacity: string;
     private createdAt: any;
     private clientId: string;

     constructor(name: string, email: string) {
          this.name = name;
          if (name !== undefined && name !== null && name !== '') this.queryName = name.toUpperCase();
          this.email = email;
          this.uid = "";
          this.identifier = "";
          this.emailRecovery = "";
          this.company = "";
          this.title = "";
          this.description = "";
          this.type = 0;
          this.facebook = "";
          this.instagram = "";
          this.twitter = "";
          this.linkedin = "";
          this.website = "";
          this.picture = "";
          this.selfEditLink = "";
          this.aboutMe = "";
          this.phone = "";
          this.photoUrl = "";
          this.language = "pt_BR";
          this.token = "";
          this.groups = null;
          this.customFields = null;
          this.terms_use = "";
          this.privacity = "";
          this.createdAt = null;
          this.clientId = null;
     }



     /**
      * Getter $uid
      * @return {string}
      */
     public get $uid(): string {
          return this.uid;
     }

     /**
      * Getter $identifier
      * @return {string}
      */
     public get $identifier(): string {
          return this.identifier;
     }

     /**
      * Getter $name
      * @return {string}
      */
     public get $name(): string {
          return this.name;
     }

     /**
        * Getter $queryName
        * @return {string}
        */
     public get $queryName(): string {
          return this.queryName;
     }

     /**
      * Getter $email
      * @return {string}
      */
     public get $email(): string {
          return this.email;
     }

     /**
      * Getter $emailRecovery
      * @return {string}
      */
     public get $emailRecovery(): string {
          return this.emailRecovery;
     }

     /**
      * Getter $company
      * @return {string}
      */
     public get $company(): string {
          return this.company;
     }

     /**
      * Getter $title
      * @return {string}
      */
     public get $title(): string {
          return this.title;
     }

     /**
      * Getter $description
      * @return {string}
      */
     public get $description(): string {
          return this.description;
     }

     /**
      * Getter $type
      * @return {number}
      */
     public get $type(): number {
          return this.type;
     }

     /**
      * Getter $facebook
      * @return {string}
      */
     public get $facebook(): string {
          return this.facebook;
     }

     /**
      * Getter $phone
      * @return {string}
      */
     public get $phone(): string {
          return this.phone;
     }

     /**
      * Getter $instagram
      * @return {string}
      */
     public get $instagram(): string {
          return this.instagram;
     }

     /**
      * Getter $twitter
      * @return {string}
      */
     public get $twitter(): string {
          return this.twitter;
     }

     /**
      * Getter $linkedin
      * @return {string}
      */
     public get $linkedin(): string {
          return this.linkedin;
     }

     /**
      * Getter $website
      * @return {string}
      */
     public get $website(): string {
          return this.website;
     }

     /**
      * Getter $picture
      * @return {string}
      */
     public get $picture(): string {
          return this.picture;
     }

     /**
      * Getter $selfEditLink
      * @return {string}
      */
     public get $selfEditLink(): string {
          return this.selfEditLink;
     }

     /**
      * Getter $aboutMe
      * @return {string}
      */
     public get $aboutMe(): string {
          return this.aboutMe;
     }

     /**
      * Getter $password
      * @return {string}
      */
     public get $password(): string {
          return this.password;
     }

     /**
      * Getter $photoUrl
      * @return {string}
      */
     public get $photoUrl(): string {
          return this.photoUrl;
     }

     /**
      * Getter $language
      * @return {string}
      */
     public get $language(): string {
          return this.language;
     }

     /**
      * Getter $checkin
      * @return {boolean}
      */
     public get $checkin(): boolean {
          return this.checkin;
     }

     /**
      * Getter $index
      * @return {number}
      */
     public get $index(): number {
          return this.index;
     }

     /**
      * Getter $token
      * @return {string}
      */
     public get $token(): string {
          return this.token;
     }

     /**
      * Getter $firstAccess
      * @return {boolean}
      */
     public get $firstAccess(): boolean {
          return this.firstAccess;
     }

     /**
      * Getter $points
      * @return {number}
      */
     public get $points(): number {
          return this.points;
     }

     /**
      * Getter $groups
      * @return {any}
      */
     public get $groups(): any {
          return this.groups;
     }

     /**
      * Getter $customFields
      * @return {any}
      */
     public get $customFields(): any {
          return this.customFields;
     }

     /**
      * Getter $terms_use
      * @return {string}
      */
     public get $terms_use(): string {
          return this.terms_use;
     }

     /**
      * Getter $privacity
      * @return {string}
      */
     public get $privacity(): string {
          return this.privacity;
     }

     /**
      * Getter $createdAt
      * @return {any}
      */
     public get $createdAt(): any {
          return this.createdAt;
     }

     /**
      * Setter $uid
      * @param {string} value
      */
     public set $uid(value: string) {
          this.uid = value;
     }

     /**
      * Setter $identifier
      * @param {string} value
      */
     public set $identifier(value: string) {
          this.identifier = value;
     }

     /**
      * Setter $name
      * @param {string} value
      */
     public set $name(value: string) {
          this.name = value;
     }

     /**
   * Setter $queryName
   * @param {string} value
   */
     public set $queryName(value: string) {
          this.queryName = value;
     }

     /**
      * Setter $email
      * @param {string} value
      */
     public set $email(value: string) {
          this.email = value;
     }

     /**
      * Setter $emailRecovery
      * @param {string} value
      */
     public set $emailRecovery(value: string) {
          this.emailRecovery = value;
     }

     /**
      * Setter $company
      * @param {string} value
      */
     public set $company(value: string) {
          this.company = value;
     }

     /**
      * Setter $title
      * @param {string} value
      */
     public set $title(value: string) {
          this.title = value;
     }

     /**
      * Setter $description
      * @param {string} value
      */
     public set $description(value: string) {
          this.description = value;
     }

     /**
      * Setter $type
      * @param {number} value
      */
     public set $type(value: number) {
          this.type = value;
     }

     /**
      * Setter $facebook
      * @param {string} value
      */
     public set $facebook(value: string) {
          this.facebook = value;
     }

     /**
      * Setter $phone
      * @param {string} value
      */
     public set $phone(value: string) {
          this.phone = value;
     }

     /**
      * Setter $instagram
      * @param {string} value
      */
     public set $instagram(value: string) {
          this.instagram = value;
     }

     /**
      * Setter $twitter
      * @param {string} value
      */
     public set $twitter(value: string) {
          this.twitter = value;
     }

     /**
      * Setter $linkedin
      * @param {string} value
      */
     public set $linkedin(value: string) {
          this.linkedin = value;
     }

     /**
      * Setter $website
      * @param {string} value
      */
     public set $website(value: string) {
          this.website = value;
     }

     /**
      * Setter $picture
      * @param {string} value
      */
     public set $picture(value: string) {
          this.picture = value;
     }

     /**
      * Setter $selfEditLink
      * @param {string} value
      */
     public set $selfEditLink(value: string) {
          this.selfEditLink = value;
     }

     /**
      * Setter $aboutMe
      * @param {string} value
      */
     public set $aboutMe(value: string) {
          this.aboutMe = value;
     }

     /**
      * Setter $password
      * @param {string} value
      */
     public set $password(value: string) {
          this.password = value;
     }

     /**
      * Setter $photoUrl
      * @param {string} value
      */
     public set $photoUrl(value: string) {
          this.photoUrl = value;
     }

     /**
      * Setter $language
      * @param {string} value
      */
     public set $language(value: string) {
          this.language = value;
     }

     /**
      * Setter $checkin
      * @param {boolean} value
      */
     public set $checkin(value: boolean) {
          this.checkin = value;
     }

     /**
      * Setter $index
      * @param {number} value
      */
     public set $index(value: number) {
          this.index = value;
     }

     /**
      * Setter $token
      * @param {string} value
      */
     public set $token(value: string) {
          this.token = value;
     }

     /**
      * Setter $firstAccess
      * @param {boolean} value
      */
     public set $firstAccess(value: boolean) {
          this.firstAccess = value;
     }

     /**
      * Setter $points
      * @param {number} value
      */
     public set $points(value: number) {
          this.points = value;
     }

     /**
      * Setter $groups
      * @param {any} value
      */
     public set $groups(value: any) {
          this.groups = value;
     }

     /**
      * Setter $customFields
      * @param {any} value
      */
     public set $customFields(value: any) {
          this.customFields = value;
     }

     /**
      * Setter $terms_use
      * @param {string} value
      */
     public set $terms_use(value: string) {
          this.terms_use = value;
     }

     /**
      * Setter $privacity
      * @param {string} value
      */
     public set $privacity(value: string) {
          this.privacity = value;
     }

     /**
      * Setter $createdAt
      * @param {any} value
      */
     public set $createdAt(value: any) {
          this.createdAt = value;
     }


     /**
   * Getter $clientId
   * @return {string}
   */
     public get $clientId(): string {
          return this.clientId;
     }

     /**
    * Setter $clientId
    * @param {string} value
    */
     public set $clientId(value: string) {
          this.clientId = value;
     }
}
