import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SafeHtmlPipe } from '../pipes/safe-html.pipe';
import { ChangeLangComponent } from './change-lang/change-lang.component';
import { FilterPipe } from '../pipes/filter.pipe';
import { GetNameModule } from '../pipes/get-name-module';
import { GetNameTrack } from '../pipes/get-name-track';

import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { SweetAlert2Module } from "@sweetalert2/ngx-sweetalert2";
import { CKEditorModule } from "ng2-ckeditor";
import { AvatarModule } from "ngx-avatar";
import { NgxPaginationModule } from "ngx-pagination";
import { UiSwitchModule } from "ngx-ui-switch";
import { ColorPickerModule } from "ngx-color-picker";
import { StorageService } from "src/app/providers/storage/storage.service";
import { DbWidgetsProvider } from "src/app/providers/database/db-widgets";
import { DragulaModule } from "ng2-dragula";
import { ImageCropperModule } from "ngx-image-cropper";
import { FilterPiperSessions } from "src/app/pipes/filter-sessions.pipe";
import { GetNameSession } from "src/app/pipes/get-name-session";

import { AngularSvgIconModule } from "angular-svg-icon";
import { DropzoneModule } from "ngx-dropzone-wrapper";
import { DROPZONE_CONFIG } from "ngx-dropzone-wrapper";
import { DropzoneConfigInterface } from "ngx-dropzone-wrapper";
import { FileDropDirective } from "src/app/directives/file-drop.directive";
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { TransformVisibilityPipe } from "src/app/pipes/transform-visibility.pipe";
import { GetNameParticipantPipe } from "src/app/pipes/get-name-participant.pipe";
import { SortGroupDiscussionsPipe } from "src/app/pipes/sort-group-discussions.pipe";
import { MaterialModule } from "src/app/global-modules/material-modules";

import { EmailEditorModule } from 'angular-email-editor';

// translate module config
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, '../assets/i18n/', '.json');
}

const DEFAULT_DROPZONE_CONFIG: DropzoneConfigInterface = {
  // Change this to your upload POST address:
  url: 'https://us-central1-high5event-a48b5.cloudfunctions.net/utilitiesDropzoneUpload',
  maxFilesize: 50
};

@NgModule({
  imports: [
    FormsModule,
    CommonModule,
    HttpClientModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    AvatarModule,
    SweetAlert2Module,
    CKEditorModule,
    NgxPaginationModule,
    ReactiveFormsModule.withConfig({ warnOnNgModelWithFormControl: "never" }),
    UiSwitchModule,
    ColorPickerModule,
    DragulaModule,
    ImageCropperModule,
    AngularSvgIconModule,
    DropzoneModule,
    NgxQRCodeModule,
    MaterialModule,
    EmailEditorModule
  ],
  declarations: [
    SafeHtmlPipe,
    ChangeLangComponent,
    FilterPipe,
    GetNameModule,
    GetNameTrack,
    FilterPiperSessions,
    FileDropDirective,
    GetNameSession,
    GetNameParticipantPipe,
    SortGroupDiscussionsPipe,
    TransformVisibilityPipe
  ],
  exports: [
    SafeHtmlPipe,
    ChangeLangComponent,
    FilterPipe,
    GetNameModule,
    GetNameTrack,
    FilterPiperSessions,
    FileDropDirective,
    GetNameSession,
    GetNameParticipantPipe,
    SortGroupDiscussionsPipe,
    TransformVisibilityPipe,

    //CommonModule,
    FormsModule,
    HttpClientModule,
    TranslateModule,
    AvatarModule,
    SweetAlert2Module,
    CKEditorModule,
    NgxPaginationModule,
    ReactiveFormsModule,
    UiSwitchModule,
    ColorPickerModule,
    DragulaModule,
    ImageCropperModule,
    AngularSvgIconModule,
    DropzoneModule,
    NgxQRCodeModule,
    MaterialModule,
    EmailEditorModule
  ],
  providers: [
    StorageService,
    DbWidgetsProvider,
    {
        provide: DROPZONE_CONFIG,
        useValue: DEFAULT_DROPZONE_CONFIG,
    },
  ]
})
export class SharedModule { }
