import { Module } from "./module";
import { TypeModule } from '../../enums/type-module';


export class ModuleInteractivity extends Module {
    access_groups: any; // uid of the group that is allowed access to the module
    typeVision: number; 
    visOnlyForLoggedUser?: boolean;

    constructor(name, icon, typeVision, eventId, order, visOnlyForLoggedUser?: boolean) {
        super(name, icon, TypeModule.INTERACTIVITY, eventId, order, false, true, true)
        this.typeVision = typeVision;
        this.access_groups = {};
        if (visOnlyForLoggedUser === undefined) {
            this.visOnlyForLoggedUser = false;
        } else {
            this.visOnlyForLoggedUser = visOnlyForLoggedUser;
        }
    }
}