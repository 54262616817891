import { Component, OnInit, ViewChild, NgModule } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { Question } from 'src/app/models/quiz-question';
import { Answer } from 'src/app/models/quiz-answer';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { Track } from 'src/app/models/track';
import { Session } from 'src/app/models/session';
import { Group } from 'src/app/models/group';
import { TypeQuiz } from 'src/app/models/type-quiz';
import { DbEventsProvider } from 'src/app/providers/database/db.events';
import { DbScheduleProvider } from 'src/app/providers/database/db-schedule';
import { DbGroupsProvider } from 'src/app/providers/database/db-groups';
import { DbQuizProvider } from 'src/app/providers/database/db-quiz';
import { Quiz } from 'src/app/models/quiz';
import { MenuIcons, icons } from '../../../../../../models/menu-icons';
import { PathIcons } from 'src/app/paths/path-icons';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { GlobalService } from 'src/app/providers/global/global.service';
import { Languages } from 'src/app/models/languages';
import { NameModule } from 'src/app/models/name-module';
import { GetNameSession } from 'src/app/pipes/get-name-session';
import { GetNameTrack } from 'src/app/pipes/get-name-track';
import { FormatedMultiIdiomaService } from 'src/app/providers/formated-multi-idioma/formated-multi-idioma.service';
import { ImageCroppedEvent } from 'ngx-image-cropper';

declare let $: any;

@Component({
    selector: 'app-edit-quiz',
    templateUrl: './edit-quiz.component.html',
    styleUrls: ['./edit-quiz.component.scss']
})

@NgModule({
    declarations: [
        GetNameSession
    ],
})


export class EditQuizComponent implements OnInit {
    public userLanguage: string   // get the language of the user.
    public eventLanguage: string // get the language of the event.

    @ViewChild('successSwal') public successSwal: SwalComponent;
    @ViewChild('errorDeleteLastQuestion') public errorDeleteLastQuestion: SwalComponent;

    eventId: string = null;
    eventTimezone: string = null;
    moduleId: string = null;
    quizId: string = null;
    languages: Languages = null //event languages
    activeLanguage: string = null;

    public questions: Array<Question> = [];
    public answers: Array<Answer> = [];
    public typeQuestion: string;
    public typeNewQuestion: string = 'oneSelect';

    public listAuxInput: Array<any> = [];
    loaderQuestions: Array<boolean> = [];

    questionId: string = null;
    iconsImg: MenuIcons[] = icons;
    selectedIcons;
    selectedIcon: string = null;
    selectedIconFamily: string = null;
    formGroup: FormGroup;
    formEditQuestion: FormGroup;
    formNewQuestion: FormGroup;
    addQuestionShow: boolean = false;
    editQuestionShow: boolean = false;
    allowShowAddQuestion: boolean = true;

    editQuestionIndex: number = null;
    correctAnswerNewQuestion: number;

    blockGraphicTypevisibilityEdit = true;
    blockOptionsAnswersVisibilityEdit = true;
    blockGraphicTypevisibilityCreate = true;
    blockOptionsAnswersVisibilityCreate = true;
    activeMarker: boolean = false;
    activeWeight: boolean = false;

    includeImgToAnswers: boolean = false;
    questionsNumberVisibility: boolean = false;

    public errorTitleEdit = false;
    public errorSelectQuestionEdit = false;
    public errorOptionAnswerEdit = false;
    public errorSelectChartTypeEdit = false;
    public errorSelectGraphicDifusionEdit = false;
    errorImgUndefined = false;
    public errorTitleCreate = false;
    public errorSelectQuestionCreate = false;
    public errorOptionAnswerCreate = false;
    public errorSelectChartTypeCreate = false;
    public errorSelectGraphicDifusionCreate = false;

    loaderEditQuestion: boolean = false;

    quiz;
    quizTitle;
    quizIcon;

    fileNew: any = null;
    fileNameNew: string = null;
    fileEdit: any = null;
    fileNameEdit: string = null;

    activeTimerEdit = false;
    // quizMaxResponses;
    // quizChangeAnswer = false;
    // quizMaxResponsesVisibility = false;

    quizLinkedToSession: boolean = false;
    SelectedSessionVisibility: boolean = false;
    tracksAttendeeVisibility: boolean = false;
    specificTrackVisibility: boolean = false;
    scheduleModuleVisibility: boolean = false;
    scheduleModuleTrackVisibility: boolean = false;
    scheduleModuleSessionVisibility: boolean = false;
    specificGroupVisibility: boolean = false;

    // tracks
    tracks: Array<Track>;
    public group = []; // array de ids
    public query = ''; // string digitada pelo usuário
    public filteredList = []; // lista de sugestões exibidas
    public selectedTracks = []; // array com os tracks selecionados

    // sessions
    sessions: Array<Session>;
    sessionsNames: Array<any>;
    public session = [];
    public querySession = ''; // string digitada pelo usuário
    public filteredSession = []; // lista de sugestões exibidas
    public selectedSessions = []; // array com os tracks selecionados

    // groups
    groups: Array<Group>;
    groupsNames: Array<any>;
    public ManagerGroup = []; // array de ids
    public queryGroup = ''; // string digitada pelo usuário
    public filteredGroup = []; // lista de sugestões exibidas
    public selectedGroups = []; // array com os tracks selecionados

    scheduleModules: Array<any> = [];
    quizType: string = TypeQuiz.Pattern;
    scheduleModuleQuizEdit: string = null;
    scheduleModuleTrackQuizEdit: string = null;
    scheduleModuleSessionQuizEdit: string = null;


    listAnswers: Array<Answer> = [];

    listNewQuestion: Array<Answer> = [];

    listDeleteInputAnswers: Array<any> = [];

    croppedImage;
    listCroppedImage = [];
    imageChangedEvent: any;
    cropperShow: boolean;
    sizeImgMax: number = 2097152;
    invalidSizeImg: boolean = false;
    fileName: string;
    selectedAnswer: number;

    constructor(
        private location: Location,
        private route: ActivatedRoute,
        private dbEvent: DbEventsProvider,
        private dbSession: DbScheduleProvider,
        private dbGroup: DbGroupsProvider,
        private dbQuiz: DbQuizProvider,
        private fb: FormBuilder,
        private router: Router,
        private global: GlobalService,
        private formatIdioma: FormatedMultiIdiomaService
    ) {
        this.eventId = this.eventId = this.route.pathFromRoot[1]['params']['_value']['uid'];
        this.moduleId = this.route.snapshot.params['moduleId'];
        this.quizId = this.route.snapshot.params['quizId'];

        this.load();

        this.formGroup = new FormGroup({
            quiz_name_pt_br: new FormControl(''),
            quiz_name_en_us: new FormControl(''),
            quiz_name_es_es: new FormControl(''),
            quiz_name_fr_fr: new FormControl(''),
            quiz_name_de_de: new FormControl(''),
            // maxResponses: new FormControl(''),
            activeTimerEdit: new FormControl(''),
            maxTimerEdit: new FormControl(''),
            changeAnswer: new FormControl(''),
            viewAnswered: new FormControl('')
        });


        /**
         * Formulario editQuestion
         */
        this.formEditQuestion = this.fb.group({
            'title_pt_br': [''],
            'title_en_us': [''],
            'title_es_es': [''],
            'title_fr_fr': [''],
            'title_de_de': [''],
            'infobooth_pt_br': [''],
            'infobooth_en_us': [''],
            'infobooth_es_es': [''],
            'infobooth_fr_fr': [''],
            'infobooth_de_de': [''],
            'radioTypeQuestion': [null],
            'radioTypeGraphic': [null],
            "radioGraphicDifusion": [null]
        });

        /**
         * Formulario create New Question
         */
        this.formNewQuestion = this.fb.group({
            'title_pt_br': [''],
            'title_en_us': [''],
            'title_es_es': [''],
            'title_fr_fr': [''],
            'title_de_de': [''],
            'infobooth_pt_br': [''],
            'infobooth_en_us': [''],
            'infobooth_es_es': [''],
            'infobooth_fr_fr': [''],
            'infobooth_de_de': [''],
            'radioTypeQuestion': [null],
            'radioTypeGraphic': [null],
            "radioGraphicDifusion": ['no']

        });

        this.listNewQuestion.push(new Answer());

        // //Seta o icone inicial
        let initialIcon = {
            icon: PathIcons.icon_quiz,
            family: 'material-icons'
        }
        this.setIcon(initialIcon);

        this.getQuizOptions();

        this.dbQuiz.getQuestions(this.moduleId, this.quizId, (list) => {
            this.questions = list;
            this.loaderQuestions = Array(this.questions.length).fill(false);
        })
    }

    ngOnInit() {
        $("label.icon-selector").click(function () {
            $("#dropdownMenuButton").dropdown("toggle");
        });

        this.getEvent();
        this.getUserLanguage()
    }

    convertLanguage(language: string) {
        switch (language) {
            case 'pt_BR':
                return ("PtBR");
            case 'en_US':
                return ("EnUS");
            case 'es_ES':
                return ("EsES");
            case 'fr_FR':
                return ("FrFR");
            case 'de_DE':
                return ("DeDE");
        }
    }

    // get the language of the user.
    getUserLanguage() {
        this.global.getLanguage((language) => {
            this.userLanguage = language
        })
    }

    getEvent() {
        this.dbEvent.getEvent(this.eventId, (event) => {
            this.eventTimezone = event.timezone;
            this.eventLanguage = event.language;
            this.languages = event.languages;
            this.activeLanguage = this.formatIdioma.convertLangFormat(event.language);
        })
    }

    setIcon(item) {
        this.selectedIcon = item.icon;
        this.selectedIconFamily = item.family;
        $('.dropdown-menu').removeClass('show');
    }

    onUploadChangeNew($ev) {
        this.fileNew = $ev.srcElement.files[0];
        this.fileNameNew = this.fileNew.name;
    }

    onUploadChangeEdit($ev) {
        this.fileEdit = $ev.srcElement.files[0];
        this.fileNameEdit = this.fileEdit.name;
    }

    getQuizOptions() {
        this.dbQuiz.getQuiz(this.moduleId, this.quizId, (retorno) => {
            this.quiz = retorno;
            this.quiz.weight !== undefined ? this.activeWeight = this.quiz.weight : this.quiz.weight = false;
            this.quiz.marker !== undefined ? this.activeMarker = this.quiz.marker : this.quiz.marker = false;
            this.includeImgToAnswers = this.quiz.includeImgToAnswers !== undefined ? this.quiz.includeImgToAnswers : false
            this.questionsNumberVisibility = this.quiz.questionsNumberVisibility !== undefined ? this.quiz.questionsNumberVisibility : false
            if (this.quiz.active_timer) {
                this.activeTimerEdit = true;
            }

            this.setValuesQuiz();
        })
    }

    setValuesQuiz() {
        if (this.quiz.type != TypeQuiz.Pattern) {
            this.quizLinkedToSession = true;

            this.quizType = this.quiz.type;
            this.changeSessionType();

            switch (this.quizType) {
                case TypeQuiz.ScheduleModule:
                    this.scheduleModuleQuizEdit = this.quiz.module_id;
                    break;

                case TypeQuiz.SessionTrack:
                    this.scheduleModuleTrackQuizEdit = this.quiz.module_id;
                    this.specificTrackVisibility = true;
                    this.loadTracks(this.scheduleModuleTrackQuizEdit);

                    for (let trackId of this.quiz.references) {
                        this.dbSession.getTrackModule(this.scheduleModuleTrackQuizEdit, trackId, (track) => {
                            let notFound = true;
                            for (let aux of this.selectedTracks) {
                                if (aux.uid == track.uid) {
                                    notFound = false;
                                }
                            }

                            if (notFound == true) {
                                this.selectedTracks.push(track);
                            }

                        })
                    }

                    break;

                case TypeQuiz.SpecificGroup:
                    this.specificGroupVisibility = true;
                    this.loadGroups();

                    for (let groupId of this.quiz.references) {
                        //marcador
                        this.dbGroup.searchModulesAndGroups(this.eventId, (data) => {
                            let groups = data['groups'];

                            let notFound = true;
                            for (let group of groups) {
                                if (group.uid == groupId) {

                                    for (let aux of this.selectedGroups) {
                                        if (aux.uid == group.uid) {
                                            notFound = false;
                                        }
                                    }

                                    if (notFound) {
                                        this.selectedGroups.push(group);
                                    }

                                    break;
                                }
                            }
                        })
                    }
                    break;

                case TypeQuiz.SpecificSession:
                    this.scheduleModuleSessionQuizEdit = this.quiz.module_id;
                    this.SelectedSessionVisibility = true;
                    this.loadSessions(this.scheduleModuleSessionQuizEdit);

                    for (let session of this.quiz.selectedSessionsData) {
                        this.dbSession.getSession(session.uid, this.eventId, (session: Session) => {

                            let notFound = true;
                            for (let aux of this.selectedSessions) {
                                if (aux.uid == session.uid) {
                                    notFound = false;
                                }
                            }

                            if (notFound == true) {
                                this.selectedSessions.push(session)
                            }
                        })
                    }

                    break;
            }
        }

        this.formGroup.patchValue({
            quiz_name_pt_br: this.quiz.title.PtBR,
            quiz_name_en_us: this.quiz.title.EnUS,
            quiz_name_es_es: this.quiz.title.EsES,
            quiz_name_fr_fr: this.quiz.title.FrFR,
            quiz_name_de_de: this.quiz.title.DeDE,
            changeAnswer: this.quiz.change_answer,
            viewAnswered: this.quiz.view_answered,
            activeTimerEdit: this.quiz.active_timer,
            maxTimerEdit: this.quiz.timer_questions
        })

        this.activeTimerEdit = this.quiz.active_timer;
    }

    changeActiverTimer() {
        let value = this.formGroup.controls.activeTimerEdit.value;

        if (value) {
            this.activeTimerEdit = true;
        } else {
            this.activeTimerEdit = false;
        }
    }

    updateQuiz(data) {
        let quiz = new Quiz();

        if (data.maxTimerEdit == undefined) {
            data.maxTimerEdit = null;
        }

        quiz.icon = this.selectedIcon;
        quiz.iconFamily = this.selectedIconFamily;
        let aux = new NameModule(data.quiz_name_pt_br, data.quiz_name_en_us, data.quiz_name_es_es, data.quiz_name_fr_fr, data.quiz_name_de_de);
        quiz.title = this.formatIdioma.updateObjectMultiLanguages(this.quiz.title, aux, this.eventLanguage);

        quiz.change_answer = data.changeAnswer;
        quiz.view_answered = data.viewAnswered;
        quiz.active_timer = data.activeTimerEdit;
        quiz.timer_questions = data.maxTimerEdit;
        quiz.type = this.quizType;
        quiz.weight = this.activeWeight;
        quiz.marker = this.activeMarker;
        quiz.totalQuestions = this.questions.length;
        quiz.questionsNumberVisibility = this.questionsNumberVisibility;
        quiz.includeImgToAnswers = this.includeImgToAnswers;

        switch (quiz.type) {
            case TypeQuiz.Pattern:
                quiz.module_id = null;
                quiz.references = null;
                break;

            case TypeQuiz.AllSessions:
                quiz.module_id = null;
                quiz.references = null;
                break;

            case TypeQuiz.ScheduleModule:
                quiz.module_id = this.scheduleModuleQuizEdit;
                quiz.references = null;
                break;

            case TypeQuiz.SessionTrack:
                quiz.module_id = this.scheduleModuleTrackQuizEdit;
                this.dbQuiz.forQuizReferences(this.selectedTracks, this.quizId, this.moduleId);
                break;

            case TypeQuiz.SpecificSession:
                quiz.module_id = this.scheduleModuleSessionQuizEdit;
                this.dbQuiz.forQuizReferences(this.selectedSessions, this.quizId, this.moduleId);
                break;

            case TypeQuiz.SpecificGroup:
                quiz.module_id = null;
                this.dbQuiz.forQuizReferences(this.selectedGroups, this.quizId, this.moduleId);
                break;
        }

        this.dbQuiz.updateQuiz(this.moduleId, this.quizId, quiz, (data) => {
            if (data == true) {
                this.successSwal.fire();

                setTimeout(() => {
                    this.router.navigate([`/event/${this.eventId}/interactivity/quiz/${this.moduleId}`]);
                }, 500);
            }
        });

    }

    /**
     * Abre Collapse da questao por index
     */
    openQuestion(index) {
        /**
         * get info answers for index
         */
        this.editQuestionIndex = index;
        this.editQuestionShow = true;
        this.allowShowAddQuestion = false;
        for (const i in this.questions) {
            if (i == index) {
                this.questionInfo(this.questions[i]);
                this.loadAnswer(this.questions[i].uid);
                window.scroll({
                    top: 160,
                    behavior: 'smooth'
                })
                break;
            }
        }
    }

    resetEditQuestion() {
        this.editQuestionShow = false;
        this.allowShowAddQuestion = true;
        this.formEditQuestion.reset();
        this.selectedAnswer = -1;
    }

    /***
     * Delete question
     */
    deleteQuestion(index) {
        this.loaderQuestions[index] = true;

        if (this.questions.length > 1) {
            const deleteId = this.questions[index].uid;
            this.dbQuiz.removeQuestion(this.moduleId, this.quizId, deleteId, (status) => {
                if (status.code == 200) {
                    this.questions.splice(index, 1);
                    this.loaderQuestions[index] = false;
                    this.loaderQuestions.splice(index, 1);
                    this.successSwal.fire();
                }
            })
        } else {
            this.errorDeleteLastQuestion.fire();
            this.loaderQuestions[index] = false;
        }
        // this.daoQuiz.deleteQuestion(this.quizId, deleteId);
    }

    /**
     * Pega os valores da Questions
     */
    questionInfo(data) {
        this.typeQuestion = data.type;

        switch (data.type) {
            case 'oneselect':
                // this.blockGraphicTypevisibilityEdit = true;
                // this.blockOptionsAnswersVisibilityEdit = true;

                if (this.listAnswers.length <= 0) {
                    this.listAnswers.push(new Answer());
                }
                break;

            case 'multipleSelect':
                // this.blockGraphicTypevisibilityEdit = true;
                // this.blockOptionsAnswersVisibilityEdit = true;

                if (this.listAnswers.length <= 0) {
                    this.listAnswers.push(new Answer());
                }
                break;
        }
        this.questionId = data.uid;
        this.formEditQuestion.reset();
        this.formEditQuestion.patchValue({
            title_pt_br: data.title.PtBR,
            title_en_us: data.title.EnUS,
            title_es_es: data.title.EsES,
            title_fr_fr: data.title.FrFR,
            title_de_de: data.title.DeDE,
            infobooth_pt_br: data.infobooth.PtBR,
            infobooth_en_us: data.infobooth.EnUS,
            infobooth_es_es: data.infobooth.EsES,
            infobooth_fr_fr: data.infobooth.FrFR,
            infobooth_de_de: data.infobooth.DeDE,
            radioTypeQuestion: data.type,
            radioTypeGraphic: data.graphic,
            radioGraphicDifusion: (data.graphicDifusion) ? 'yes' : 'no'
        });


    }

    changeTypeQuestionEdit() {
        for (let indice in this.listAnswers) {
            this.listAnswers[indice].correct = false;
        }

        this.typeQuestion = this.formEditQuestion.controls['radioTypeQuestion'].value;

        let value = this.formEditQuestion.get('radioTypeQuestion').value;
        switch (value) {
            case 'oneSelect':
                // this.blockGraphicTypevisibilityEdit = true;
                // this.blockOptionsAnswersVisibilityEdit = true;

                if (this.listAnswers.length <= 0) {
                    this.listAnswers.push(new Answer());
                }
                break;

            case 'multipleSelect':
                // this.blockGraphicTypevisibilityEdit = true;
                // this.blockOptionsAnswersVisibilityEdit = true;

                if (this.listAnswers.length <= 0) {
                    this.listAnswers.push(new Answer());
                }
                break;
        }
    }

    changeTypeQuestionCreate() {
        for (let indice in this.listNewQuestion) {
            this.listNewQuestion[indice].correct = false;
        }

        this.typeNewQuestion = this.formNewQuestion.controls['radioTypeQuestion'].value;

        let value = this.formNewQuestion.get('radioTypeQuestion').value;
        switch (value) {
            case 'oneSelect':
                // this.blockGraphicTypevisibilityCreate = true;
                // this.blockOptionsAnswersVisibilityCreate = true;

                if (this.listAnswers.length <= 0) {
                    this.listAnswers.push(new Answer());
                }
                break;

            case 'multipleSelect':
                // this.blockGraphicTypevisibilityCreate = true;
                // this.blockOptionsAnswersVisibilityCreate = true;

                if (this.listAnswers.length <= 0) {
                    this.listAnswers.push(new Answer());
                }
                break;
        }
    }

    /**
     * Pega os valores das Answers na DAO
     */
    loadAnswer(questionId) {
        this.dbQuiz.getAnswers(this.moduleId, this.quizId, questionId, (list) => {
            this.answers = [];
            this.listAuxInput = [];
            for (const data of list) {
                this.listAuxInput.push(data);
                this.answers.push(data);
            }

            this.carryInputsAnswer(this.answers);
            this.inputValueAnswer(this.listAuxInput);
        })
    }

    /**
     * Carrega os valores das answers no input
     */
    inputValueAnswer(data) {
        let index = 0;
        this.listAnswers = [];

        for (const aux of data) {
            this.listAnswers[index] = aux;
            this.listCroppedImage[index] = aux.img;
            index++;
        }

    }

    /**
     * Cria os clones de inputs do formulario Answer
     */
    cloneInputAnswers() {
        this.listAnswers.push(new Answer());
    }

    /**
     * Delete input answers
     */
    getDeleteInputAnswers(index) {
        const deleteId = this.listAnswers[index].uid;

        if (deleteId !== undefined) {
            var questionId = this.questionId;
            var deleteAnswer = [questionId, deleteId];
            this.listDeleteInputAnswers.push(deleteAnswer);
            this.listAnswers.splice(index, 1);
            this.listCroppedImage.splice(index, 1);
        }
    }

    deleteInputAnswers() {
        for (let aux of this.listDeleteInputAnswers) {
            var questionId = aux[0];
            var deleteId = aux[1];

            this.dbQuiz.deleteAnswer(this.moduleId, this.quizId, questionId, deleteId, (status) => {

            });
        }
    }

    /**
     *  Carrega os clones dos inputs de acordo com o total de respostas que o formulario possui
     */
    carryInputsAnswer(data) {
        let cont = data.length;
        let print = 1;
        while (cont >= 1) {
            if (print < data.length) {
                this.cloneInputAnswers();
                print++;
            }
            cont--;
        }
    }

    /**
     * Submit formulario Edit Question
     */
    editQuestion(data) {
        this.errorTitleEdit = false;
        this.errorSelectQuestionEdit = false;
        this.errorOptionAnswerEdit = false;
        this.errorSelectChartTypeEdit = false;
        this.errorSelectGraphicDifusionEdit = false;
        this.errorImgUndefined = false;

        const newQuestion = new Question();

        let auxTitle = new NameModule(data.title_pt_br, data.title_en_us, data.title_es_es, data.title_fr_fr, data.title_de_de);
        let auxInfobooth = new NameModule(data.infobooth_pt_br, data.infobooth_en_us, data.infobooth_es_es, data.infobooth_fr_fr, data.infobooth_de_de);
        newQuestion.title = this.formatIdioma.updateObjectMultiLanguages(this.questions[this.editQuestionIndex].title, auxTitle, this.eventLanguage)
        newQuestion.infobooth = this.formatIdioma.updateObjectMultiLanguages(this.questions[this.editQuestionIndex].infobooth, auxInfobooth, this.eventLanguage)
        newQuestion.type = data.radioTypeQuestion;
        newQuestion.graphic = data.radioTypeGraphic;
        newQuestion.graphicDifusion = (data.radioGraphicDifusion == 'yes') ? true : false;

        if (this.fileEdit !== undefined && this.fileEdit !== null) {
            newQuestion.image = this.fileEdit;
        } else {
            delete newQuestion.image;
        }

        if (newQuestion.type == 'oneSelect' || newQuestion.type == 'multipleSelect') {
            for (const answer of this.listAnswers) {
                newQuestion.answers.push(answer);
            }
        }


        //Caso o usuário não tenha inserido o titulo da pergunta
        if (newQuestion.title == undefined || newQuestion.title == null || (newQuestion.title.PtBR == "" &&
            newQuestion.title.EnUS == "" && newQuestion.title.EsES == "" && newQuestion.title.FrFR == "" &&
            newQuestion.title.DeDE == "")) {
            this.errorTitleEdit = true;
        }

        if (newQuestion.type == undefined || newQuestion.type == null || newQuestion.type == "") {
            this.errorSelectQuestionEdit = true;
        }

        if (newQuestion.type == 'oneSelect' || newQuestion.type == 'multipleSelect') {
            //Caso não tenha adicionado nenhuma resposta possível
            let find = false;

            for (let answer of this.listAnswers) {
                if(!answer.img && this.includeImgToAnswers){
                    this.errorImgUndefined = true ;
                    answer.img = '';
                }
                if (!(answer.answer == undefined) && !(answer.answer == null) && (answer.answer.PtBR != ""
                    || answer.answer.EnUS != "" || answer.answer.EsES != "" || answer.answer.FrFR != ""
                    || answer.answer.DeDE != "")) {
                    find = true;
                }
            }

            if (find == false) {
                this.errorOptionAnswerEdit = true;
            }

            if (newQuestion.graphic == null || newQuestion.graphic == undefined || newQuestion.graphic == "") {
                this.errorSelectChartTypeEdit = true;
            }
        }

        if (!(this.errorTitleEdit || this.errorSelectQuestionEdit || this.errorOptionAnswerEdit || this.errorSelectChartTypeEdit || this.errorImgUndefined)) {
            this.loaderEditQuestion = true;
            this.dbQuiz.updateQuestion(this.moduleId, this.quizId, this.questionId, newQuestion, this.eventTimezone, this.eventId, (status) => {
                this.questions[this.editQuestionIndex].title = newQuestion.title;
                this.questions[this.editQuestionIndex].type = newQuestion.type;
                this.questions[this.editQuestionIndex].graphicDifusion = newQuestion.graphicDifusion;

                this.editQuestionShow = false;
                this.addQuestionShow = false;
                this.allowShowAddQuestion = true;
                this.fileEdit = null;
                this.fileNameEdit = null;
                this.loaderEditQuestion = false;
            });

            this.deleteInputAnswers();

            this.allowShowAddQuestion = true;
        }

    }


    updateCorrectAnswerEdit(indice) {
        if (this.typeQuestion == 'oneSelect') { // Caso seja one select atribui a selecionada como verdadeira a selecionada e todas as outras como falsas.
            for (let i = 0; i < this.listAnswers.length; i++) {
                if (i == indice) {
                    this.listAnswers[i].correct = true;
                } else {
                    this.listAnswers[i].correct = false;
                }
            }
        }
        else if (this.typeQuestion == 'multipleSelect') { //Caso seja multiple select e a resposta na posição do indice seja correta muda para incorreta e caso seja incorreta muda para correta.
            if (this.listAnswers[indice].correct) {
                this.listAnswers[indice].correct = false;
            } else {
                this.listAnswers[indice].correct = true;
            }
        }
    }


    //----------------------------------
    //         Create New Question
    //----------------------------------

    /**
     * Cria os clones de inputs do formulario Answer
     */
    cloneInputNewQuestion() {
        this.listNewQuestion.push(new Answer());
    }

    /**
     * Delete input answers
     */
    deleteInputNewQuestion(index) {
        this.listNewQuestion.splice(index, 1);
    }

    /**
     * Create new Question
     */
    newQuestion(data) {
        this.errorTitleCreate = false;
        this.errorSelectQuestionCreate = false;
        this.errorOptionAnswerCreate = false;
        this.errorSelectChartTypeCreate = false;

        let newQuestion = new Question();
        newQuestion.title = new NameModule(data.title_pt_br, data.title_en_us, data.title_es_es, data.title_fr_fr, data.title_de_de);
        newQuestion.infobooth = new NameModule(data.infobooth_pt_br, data.infobooth_en_us, data.infobooth_es_es, data.infobooth_fr_fr, data.infobooth_de_de);
        newQuestion.type = data.radioTypeQuestion;
        newQuestion.image = this.fileNew;
        newQuestion.graphic = data.radioTypeGraphic;
        newQuestion.graphicDifusion = (data.radioGraphicDifusion == 'yes') ? true : false;

        //Caso o usuário não tenha inserido o titulo da pergunta
        newQuestion.title = this.formatIdioma.fillEmptyLanguage(newQuestion.title, this.eventLanguage);
        newQuestion.infobooth = this.formatIdioma.fillEmptyLanguage(newQuestion.infobooth, this.eventLanguage);
        newQuestion.infobooth = this.replaceLinkInfooboth(newQuestion.infobooth);

        if (newQuestion.title == undefined || newQuestion.title == null || (newQuestion.title.PtBR == ""
            && newQuestion.title.EnUS == "" && newQuestion.title.EsES == "" && newQuestion.title.FrFR == ""
            && newQuestion.title.DeDE == "")) {
            this.errorTitleCreate = true;
        }

        if (newQuestion.type == undefined || newQuestion.type == null || newQuestion.type == "") {
            this.errorSelectQuestionCreate = true;
        }

        if (newQuestion.type == 'oneSelect' || newQuestion.type == 'multipleSelect') {
            //Caso não tenha adicionado nenhuma resposta possível
            let find = false;

            for (let answer of this.listNewQuestion) {
                if (answer.answer != undefined && answer.answer != null && (answer.answer.PtBR != ""
                    || answer.answer.EnUS != "" || answer.answer.EsES != "" || answer.answer.FrFR != ""
                    || answer.answer.DeDE != "")) {

                    find = true;
                }
            }

            if (find == false) {
                this.errorOptionAnswerCreate = true;
            }

            if (newQuestion.graphic == null || newQuestion.graphic == undefined || newQuestion.graphic == "") {
                this.errorSelectChartTypeCreate = true;
            }
        }



        if (!(this.errorTitleCreate || this.errorSelectQuestionCreate || this.errorOptionAnswerCreate || this.errorSelectChartTypeCreate)) {
            if (newQuestion.type == 'oneSelect' || newQuestion.type == 'multipleSelect') {
                let cont = 0;
                for (let aux of this.listNewQuestion) {
                    let answer = new Answer();

                    answer.answer = aux.answer;
                    aux.weight == undefined ? answer.weight = null : answer.weight = aux.weight;
                    aux.marker == undefined ? answer.marker = null : answer.marker = aux.marker;
                    answer.correct = aux.correct;

                    // if (answer.answer !== null && answer.answer !== undefined && answer.answer !== "") {
                    //   newQuestion.answers.push(answer)
                    // }

                    answer.answer = this.formatIdioma.fillEmptyLanguage(answer.answer, this.eventLanguage);
                    if (answer.answer !== undefined && answer.answer !== null && (answer.answer.PtBR != ""
                        || answer.answer.EnUS != "" || answer.answer.EsES != "" || answer.answer.FrFR != ""
                        || answer.answer.DeDE != "")) {

                        newQuestion.answers.push(answer)
                    }

                    if (cont == this.listNewQuestion.length - 1) {
                        this.dbQuiz.createOneQuestions(this.moduleId, this.quizId, newQuestion, this.eventTimezone, this.eventId, (data) => {
                            if (data.status == true) {
                                newQuestion.uid = data.questionId;
                                this.questions.push(newQuestion);
                                this.loaderQuestions.push(false);
                            }

                            this.editQuestionShow = false;
                            this.addQuestionShow = false;
                            this.allowShowAddQuestion = true;
                            this.fileNew = null;
                            this.fileNameNew = null;
                        });
                    }

                    cont++;
                }
            }


            //Clear form new question, close modal and show/hide blocks
            this.formNewQuestion.reset();
            // this.blockGraphicTypevisibilityCreate = true;
            // this.blockOptionsAnswersVisibilityCreate = true;
            $('#modalQuiz').modal('toggle');

            this.listNewQuestion = [];
            this.listNewQuestion.push(new Answer());
            this.addQuestionShow = false;
            this.correctAnswerNewQuestion = null;

        }

    }

    updateCorrectAnswer(indice) {
        if (this.typeNewQuestion == 'oneSelect') { // Caso seja one select atribui a selecionada como verdadeira a selecionada e todas as outras como falsas.
            for (let i = 0; i < this.listNewQuestion.length; i++) {
                if (i == indice) {
                    this.listNewQuestion[i].correct = true;
                } else {
                    this.listNewQuestion[i].correct = false;
                }
            }
        }
        else if (this.typeNewQuestion == 'multipleSelect') { //Caso seja multiple select e a resposta na posição do indice seja correta muda para incorreta e caso seja incorreta muda para correta.
            if (this.listNewQuestion[indice].correct) {
                this.listNewQuestion[indice].correct = false;
            } else {
                this.listNewQuestion[indice].correct = true;
            }
        }
    }


    cancelNewQuestion() {

        //Clear form new question, close modal and show/hide blocks
        $(function () {
            $('#form-questions').each(function () {
                this.reset();
                $("input[name=newRadioTypeQuestion]").prop('checked', false);
                $("input[name=radioTypeGraphic]").prop('checked', false);
                // $('#answer-options').hide('slow');
                // $('#chart-options').hide('slow');
            });
        })

        this.listNewQuestion = [];
        this.listNewQuestion.push(new Answer());
        this.addQuestionShow = false;
    }




    load() {
        this.dbEvent.getModulesSchedule(this.eventId, (data) => {
            this.scheduleModules = data;
        });
    }

    // LOAD ALL TRACKS OF SCHEDULE MODULE 
    private loadTracks(moduleId) {
        this.dbSession.getModuleTracks(moduleId, (tracks) => {
            // this.dao.tracksSessionEvent(moduleId, (tracks) => {
            this.tracks = [];
            this.group = [];
            this.group = tracks;
            for (const track of tracks) {
                this.tracks.push(track);
            }
        });
    }

    // receive group name and return index of the group  to get id group
    findIdTrack(track: Track) {
        const index = this.tracks.map(function (e) {
            return new GetNameTrack().transform(e, [this.userLanguage, this.eventLanguage]);
        }).indexOf(new GetNameTrack().transform(track, [this.userLanguage, this.eventLanguage]));
        return index;
    }

    // autocomplete
    filter() { //  função usa a query variável para filtrar tracks, então ela armazena o resultado no filteredList.
        if (this.query !== "") {
            this.filteredList = this.tracks.filter(function (el) {
                let elementStr = new GetNameTrack().transform(el, [this.userLanguage, this.eventLanguage]);
                return elementStr.toLowerCase().indexOf(this.query.toLowerCase()) > -1;
            }.bind(this));
        } else {
            this.filteredList = [];
        }
    }

    // atribuir o item selecionado para a variável de consulta, a fim de fazê-lo aparecer na entrada, e para fazer a lista de sugestões de desaparecer
    select(item) {
        if (this.selectedTracks.length > 0) {
            const index = this.selectedTracks.indexOf(item);
            if (index == -1) {
                this.selectedTracks.push(item);
            }
        } else {
            this.selectedTracks.push(item);
        }

        this.query = '';
        this.filteredList = [];
    }

    remove(item) { // remove group from display list
        this.selectedTracks.splice(this.selectedTracks.indexOf(item), 1);
    }



    loadSessions(moduleId) {
        this.dbSession.getSessionsModule(moduleId, (list: Array<Session>) => {
            this.sessions = [];
            this.session = list;

            this.sessionsNames = [];
            for (const session of list) {
                this.sessionsNames.push(new GetNameSession().transform(session, [this.userLanguage, this.eventLanguage]))
                // this.sessions.push(session);
            }
        })
    }

    // autocomplete
    filterSession() { //  função usa a query variável para filtrar tracks, então ela armazena o resultado no filteredList.
        if (this.querySession !== "") {
            this.filteredSession = this.session.filter(function (el) {
                // search session using user language
                switch (this.userLanguage) {
                    case ('pt_BR'):
                        return el.name.PtBR.toLowerCase().indexOf(this.querySession.toLowerCase()) > -1;

                    case ('en_US'):
                        return el.name.EnUS.toLowerCase().indexOf(this.querySession.toLowerCase()) > -1;

                    case ('es_ES'):
                        return el.name.EsES.toLowerCase().indexOf(this.querySession.toLowerCase()) > -1;


                    case ('fr_FR'):
                        return el.name.FrFR.toLowerCase().indexOf(this.querySession.toLowerCase()) > -1;

                    case ('de_DE'):
                        return el.name.DeDE.toLowerCase().indexOf(this.querySession.toLowerCase()) > -1;
                }



            }.bind(this));
        } else {
            this.filteredSession = [];
        }
    }

    // receive session name and return index of the session to get id session
    findIdSession(session: Session) {
        const index = this.session.map(function (e) { return e.name; }).indexOf(session.name);
        return index;
    }

    // atribuir o item selecionado para a variável de consulta, a fim de fazê-lo aparecer na entrada, e para fazer a lista de sugestões de desaparecer
    selectSession(item) {
        if (this.selectedSessions.length > 0) {
            const index = this.selectedSessions.map((e) => { return e.uid; }).indexOf(item.uid);

            if (index == -1) {
                this.selectedSessions.push(item);
            }
        } else {
            this.selectedSessions.push(item);
        }

        this.querySession = '';
        this.filteredSession = [];
    }

    removeSession(item) { // remove group from display list
        this.selectedSessions.splice(this.selectedSessions.indexOf(item), 1);
        // this.dao.notAllowGroupCheckin(this.eventId, this.groupId);
    }

    loadGroups() {
        this.dbGroup.searchModulesAndGroups(this.eventId, (response) => {
            this.groups = [];
            this.groups = response['groups'];
        });
    }

    // autocomplete
    filterGroup() { //  função usa a query variável para filtrar tracks, então ela armazena o resultado no filteredList.
        if (this.queryGroup !== "") {
            this.filteredGroup = this.groups.filter(function (el) {
                return el.name.toLowerCase().indexOf(this.queryGroup.toLowerCase()) > -1;
            }.bind(this));
        } else {
            this.filteredGroup = [];
        }
    }

    // receive session name and return index of the session to get id session
    findIdGroup(group: Group) {
        const index = this.groups.map(function (e) { return e.name; }).indexOf(group.name);
        return index;
    }

    // atribuir o item selecionado para a variável de consulta, a fim de fazê-lo aparecer na entrada, e para fazer a lista de sugestões de desaparecer
    selectGroup(item) {
        if (this.selectedGroups.length > 0) {
            const index = this.selectedGroups.indexOf(item);
            if (index == -1) {
                this.selectedGroups.push(item);
            }
        } else {
            this.selectedGroups.push(item);
        }

        this.queryGroup = '';
        this.filteredGroup = [];
    }

    removeGroup(item) { // remove group from display list
        this.selectedGroups.splice(this.selectedGroups.indexOf(item), 1);
        // this.dao.notAllowGroupCheckin(this.eventId, this.groupId);
    }

    //verifica se o quiz será vinculado ou não a uma sessão
    changeTypeQuiz() {
        if (this.quizLinkedToSession) {
            //caso o quiz sejá vinculado a uma sessão, automaticamente o valor inicial se torna todas as sessões
            this.quizType = TypeQuiz.AllSessions;
        } else {
            this.quizLinkedToSession = false;
            this.selectedSessions = [];
            this.quizType = TypeQuiz.Pattern;

            this.SelectedSessionVisibility = false;
            this.tracksAttendeeVisibility = false;
            this.scheduleModuleTrackVisibility = false;
            this.scheduleModuleSessionVisibility = false;
            this.specificTrackVisibility = false;
            this.specificGroupVisibility = false;
        }
    }

    //caso o quiz seja vinculado as sessões, verifica se será em todas ou em uma especifica e altera o quizType
    changeSessionType() {
        switch (this.quizType) {
            case 'AllSessions':
                this.quizType = TypeQuiz.AllSessions;

                this.SelectedSessionVisibility = false;
                this.tracksAttendeeVisibility = false;
                this.scheduleModuleTrackVisibility = false;
                this.scheduleModuleSessionVisibility = false;
                this.specificTrackVisibility = false;
                this.specificGroupVisibility = false;

                //Esvazia o array de sessões selecionadas posteriormente.
                this.selectedSessions = [];
                break;

            case 'ScheduleModule':
                this.quizType = TypeQuiz.ScheduleModule;

                this.SelectedSessionVisibility = false;
                this.tracksAttendeeVisibility = false;
                this.scheduleModuleVisibility = true;
                this.scheduleModuleTrackVisibility = false;
                this.scheduleModuleSessionVisibility = false;
                this.specificTrackVisibility = false;
                this.specificGroupVisibility = false;
                break;

            case 'SessionTrack':
                this.quizType = TypeQuiz.SessionTrack;

                this.SelectedSessionVisibility = false;
                this.tracksAttendeeVisibility = false;
                this.scheduleModuleVisibility = false;
                this.scheduleModuleSessionVisibility = false;
                this.scheduleModuleTrackVisibility = true;
                this.specificGroupVisibility = false;
                break;

            case 'SpecificSession':
                this.quizType = TypeQuiz.SpecificSession;

                this.SelectedSessionVisibility = false;
                this.tracksAttendeeVisibility = false;
                this.scheduleModuleVisibility = false;
                this.scheduleModuleTrackVisibility = false;
                this.scheduleModuleSessionVisibility = true;
                this.specificTrackVisibility = false;
                this.specificGroupVisibility = false;
                break;

            case 'SpecificGroup':
                this.quizType = TypeQuiz.SpecificGroup;

                this.SelectedSessionVisibility = false;
                this.tracksAttendeeVisibility = false;
                this.scheduleModuleVisibility = false;
                this.scheduleModuleTrackVisibility = false;
                this.scheduleModuleSessionVisibility = false;
                this.specificTrackVisibility = false;
                this.specificGroupVisibility = true;
                this.loadGroups();
                break;
        }
    }

    moduleSelectedChange() {
        this.selectedGroups = [];
        // this.selectedSessions = [];
        this.selectedTracks = [];

        var trackId = this.scheduleModuleTrackQuizEdit;
        var sessionId = this.scheduleModuleSessionQuizEdit;
        var specificModule = this.scheduleModuleQuizEdit;

        if (trackId == undefined || trackId == null || trackId == '') {
            this.specificTrackVisibility = false;
        } else {
            this.specificTrackVisibility = true;
            this.loadTracks(trackId);
        }

        if (sessionId == undefined || sessionId == null || sessionId == '') {
            this.SelectedSessionVisibility = false;
        } else {
            this.SelectedSessionVisibility = true;
            this.loadSessions(sessionId);
        }
    }

    resetValuesForm() {
        this.quizLinkedToSession = false;
        this.SelectedSessionVisibility = false;
        this.tracksAttendeeVisibility = false;
        this.specificTrackVisibility = false;
        this.scheduleModuleVisibility = false;
        this.scheduleModuleTrackVisibility = false;
        this.scheduleModuleSessionVisibility = false;
        this.specificGroupVisibility = false;

        // tracks
        this.tracks = [];
        this.group = []; // array de ids
        this.query = ''; // string digitada pelo usuário
        this.filteredList = []; // lista de sugestões exibidas
        this.selectedTracks = []; // array com os tracks selecionados

        // sessions
        this.sessions = [];
        this.sessionsNames = [];
        this.session = [];
        this.querySession = ''; // string digitada pelo usuário
        this.filteredSession = []; // lista de sugestões exibidas
        this.selectedSessions = []; // array com os tracks selecionados

        // groups
        this.groups = [];
        this.groupsNames = [];
        this.ManagerGroup = []; // array de ids
        this.queryGroup = ''; // string digitada pelo usuário
        this.filteredGroup = []; // lista de sugestões exibidas
        this.selectedGroups = []; // array com os tracks selecionados

        this.scheduleModules = [];
        this.quizType = TypeQuiz.Pattern;
        this.scheduleModuleQuizEdit = null;
        this.scheduleModuleTrackQuizEdit = null;
        this.scheduleModuleSessionQuizEdit = null;
    }

    cancel() {
        this.location.back();
    }

    replaceLinkInfooboth(data) {
        data['PtBR'] == null ? data['PtBR'] = '' : data['PtBR'] = data['PtBR'].replace(/href="/g, 'class="wysiwyg-link" href="');
        data['EnUS'] == null ? data['EnUS'] = '' : data['EnUS'] = data['EnUS'].replace(/href="/g, 'class="wysiwyg-link" href="');
        data['EsES'] == null ? data['EsES'] = '' : data['EsES'] = data['EsES'].replace(/href="/g, 'class="wysiwyg-link" href="');
        data['FrFR'] == null ? data['FrFR'] = '' : data['FrFR'] = data['FrFR'].replace(/href="/g, 'class="wysiwyg-link" href="');
        data['DeDE'] == null ? data['DeDE'] = '' : data['DeDE'] = data['DeDE'].replace(/href="/g, 'class="wysiwyg-link" href="');

        return data;
    }

    imageCropped(event: ImageCroppedEvent) {

        // Get base 64 image
        this.croppedImage = event.base64;
    }

    onUploadCroppedChange($event: any) {
        this.invalidSizeImg = false;
        const file: File = $event.target.files[0];
        this.fileName = file.name;

        if (file.size < this.sizeImgMax) {
            this.cropperShow = true;
            this.imageChangedEvent = event;
        } else {
            this.invalidSizeImg = true;
        }
    }

    saveAnswerPicture() {
        if (!this.invalidSizeImg && this.croppedImage) {
            this.listCroppedImage[this.selectedAnswer] = this.croppedImage;
            const aux = this.croppedImage.split(',');
            this.listAnswers[this.selectedAnswer].img = aux[1]
            this.clearCropper();
            $('#selectPicture').modal('toggle')
        }
    }

    clearCropper() {
        this.invalidSizeImg = false;
        this.cropperShow = false;
        this.selectedAnswer = -1;
        this.fileName = null;
    }

    selectImg(id){
        this.selectedAnswer = id;
    }

}
