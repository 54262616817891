import { Component, OnInit, ViewChild } from "@angular/core";
import { Event } from "../../../models/event";
import { AvailableModules } from "../../../models/available-modules";

import { ModuleAttendee } from "../../../models/modules/module-attendees";
import { ModuleSpeaker } from "../../../models/modules/module-speaker";
import { ModuleSchedule } from "../../../models/modules/module-schedule";
import { ModuleWidget } from "src/app/models/modules/module-widget";
import { ModuleLocation } from "src/app/models/modules/module-location";

import { TypeVisionGroup } from "../../../enums/type-vision-group";
import { TypeModule } from "../../../enums/type-module";

import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { SwalComponent } from "@sweetalert2/ngx-sweetalert2";

import { DbEventsProvider } from "src/app/providers/database/db.events";
import { DbAdminUserProvider } from "src/app/providers/database/db-admin-user";
import { PathIcons } from "src/app/paths/path-icons";
import { ModulePersonalAgenda } from "src/app/models/modules/module-personal-agenda";
import { ModuleGroup } from "src/app/models/modules/module-groups";
import { ManagerModules } from "src/app/models/modules/module-manager-module";
import { ModuleDocuments } from "src/app/models/modules/module-documents";
import { ModuleGallery } from "src/app/models/modules/module-gallery";
import { ModuleCheckin } from "src/app/models/modules/module-checkin";
import { ModuleEvent } from "src/app/models/modules/module-event";
import { ModuleNotification } from "src/app/models/modules/module-notifications";
import { ModuleInteractivity } from "src/app/models/modules/module-interacvity";
import { ModuleSurvey } from "src/app/models/modules/module-survey";
import { ModuleQuiz } from "src/app/models/modules/module-quiz";
import { ModuleTraining } from "src/app/models/modules/module-training";
import { TypeVisionModule } from "src/app/enums/type-vision-module";
import { ModuleSessionFeedback } from "src/app/models/modules/module-session-feedback";
import { ModuleFeedNews } from "src/app/models/modules/module-feed-news";
import { ModuleMaps } from "src/app/models/modules/module-maps";
import { ModuleRanking } from "src/app/models/modules/module-ranking";
import { ModuleAskQuestion } from "src/app/models/modules/module-ask-question";
import { ModuleSelfCheckin } from "src/app/models/modules/module-self-checkin";
import { ModuleInfobooth } from "src/app/models/modules/module-infoobooth";
import { ModuleCustomPage } from "src/app/models/modules/module-custom-page";
import { environment } from "src/environments/environment";
import { GlobalService } from "src/app/providers/global/global.service";
import { NameModule } from "src/app/models/name-module";
import { ModuleWordCloud } from "src/app/models/modules/module-word-cloud";
import { ModulePrizeDraw } from "src/app/models/modules/module-prize-draw";
import { RegexProvider } from "src/app/providers/regex/regex.service";
import { ModuleGamification } from "src/app/models/modules/module-gamification";
import { ModuleGroupDiscussion } from "src/app/models/modules/module-group-discussion";
import { ModuleAppointments } from "src/app/models/modules/module-appointments";
import { DateTimeService } from 'src/app/providers/date-time/date-time.service';
import { ModuleSponsor } from "src/app/models/modules/module-sponsor";
import { ModuleStand } from "src/app/models/modules/module-stand";

@Component({
    selector: "app-event-client-create",
    templateUrl: "./event-client-create.component.html",
    styleUrls: ["./event-client-create.component.scss"],
})
export class EventClientCreateComponent implements OnInit {
    // ngModels
    defaultAppUrl: string = environment.platform.defaultAppUrl;
    title: string;
    shortcode: string;
    startDate: string;
    endDate: string;
    visibility: boolean;
    userLimit: number;
    emailClient: string;
    qtdModels: number;
    language_principal: any;

    // languages
    languages = [];

    // module names
    public nameModuleAttendee: NameModule;
    public nameModuleSpeaker: NameModule;
    public nameModuleSchedule: NameModule;
    public nameModuleWidget: NameModule;
    public nameModuleDocument: NameModule;
    public nameModuleGallery: NameModule;
    public nameModuleCheckin: NameModule;
    public nameModuleMap: NameModule;
    public nameModuleRanking: NameModule;
    public nameModuleSurvey: NameModule;
    public nameModuleQuiz: NameModule;
    public nameModuleTraining: NameModule;
    public nameModuleSessionFeedBack: NameModule;
    public NameModuleAskQuestion: NameModule;
    public nameModuleNewsFeed: NameModule;
    public nameModuleInfobooth: NameModule;
    public nameModuleCustomPage: NameModule;
    public nameModuleLocation: NameModule;
    public nameModulePersonalAgenda: NameModule;
    public nameModuleSelfeCheckin: NameModule;
    public nameModuleEvent: NameModule;
    public nameModuleGroup: NameModule;
    public nameModuleManager: NameModule;
    public nameModuleNotifications: NameModule;
    public nameModuleInteractivity: NameModule;
    public nameModuleWordCloud: NameModule;
    public nameModuleGamification: NameModule;
    public nameModulePrizeDraw: NameModule;
    public nameModuleGroupDiscussion: NameModule;
    public nameModuleAppointments: NameModule;
    public nameModuleSponsor: NameModule;
    public nameModuleStand: NameModule;

    // modules
    public modules: Array<any>;
    public moduleAttendee: ModuleAttendee;
    public moduleSpeaker: ModuleSpeaker;
    public moduleSchedule: ModuleSchedule;
    public moduleWidget: ModuleWidget;
    public moduleLocation: ModuleLocation;
    public moduleMaps: ModuleMaps;
    public moduleRanking: ModuleRanking;
    public modulePersonalAgenda: ModulePersonalAgenda;
    public moduleGroups: ModuleGroup;
    public moduleManager: ManagerModules;
    public moduleDocuments: ModuleDocuments;
    public moduleGallery: ModuleGallery;
    public moduleCheckin: ModuleCheckin;
    public moduleSelfCheckin: ModuleSelfCheckin;
    public modulePrizeDraw: ModulePrizeDraw;
    public moduleNotifications: ModuleNotification;
    public moduleEvent: ModuleEvent;
    public moduleSurvey: ModuleSurvey;
    public moduleWordCloud: ModuleWordCloud;
    public moduleSponsor: ModuleSponsor;
    public moduleStand: ModuleStand;
    public moduleQuiz: ModuleQuiz;
    public moduleTraining: ModuleTraining;
    public moduleSessionFeedback: ModuleSessionFeedback;
    public moduleAskQuestion: ModuleAskQuestion;
    public moduleInteractivity: ModuleInteractivity;
    public moduleFeedNews: ModuleFeedNews;
    public moduleInfobooth: ModuleInfobooth;
    public moduleCustomPage: ModuleCustomPage;
    public moduleGamification: ModuleGamification;
    public moduleGroupDiscussion: ModuleGroupDiscussion;
    public moduleAppointments: ModuleAppointments;

    visibilities: Array<boolean>;
    gallery_file_limit: number = 2048000;
    documents_file_limit: number = 3072000;

    // Variable Forms Error
    dateError: boolean;
    startDatePastError: boolean;
    endDatePastError: boolean;
    invalidShortcodeError: boolean;
    createEventError: boolean;
    clientError: boolean;

    loader: boolean = false;
    public userLanguage: string; //Saves the user's language.
    @ViewChild("successSwal") public successSwal: SwalComponent;
    public allowChatUse: boolean = true; //saves the value of the chat select.
    public allowLanguageUse: boolean = true; //stores the value of multi-language select.
    public allowVisioUse: boolean = true; //stores the value of visio select.
    public allowVisioUseFor2: boolean = true; //stores the value of visio for 2 select.
    public allowAppointments: boolean = true; // stores the value of appointments feature.
    public allowAnalyticsPlus: boolean = true;

    constructor(
        private router: Router,
        private translateService: TranslateService,
        private dbEvents: DbEventsProvider,
        private dbClient: DbAdminUserProvider,
        private global: GlobalService,
        private regex: RegexProvider,
        private dt: DateTimeService,
    ) {
        this.dateError = false;
        this.startDatePastError = false;
        this.endDatePastError = false;
        this.invalidShortcodeError = false;
        this.createEventError = false;
        this.clientError = false;

        this.title = "";
        this.shortcode = "";
        this.startDate = "";
        this.endDate = "";
        this.visibility = true; // visibility: true => public and private false
        this.userLimit = 100;
        this.emailClient = "";
        this.qtdModels = 0;
        (this.language_principal = {
            value: "en_US",
            name: this.translateService.instant("comp.event_info.english"),
        }),
            // options ptincipal languages
            (this.languages = [
                {
                    value: "pt_BR",
                    name: this.translateService.instant(
                        "comp.event_info.portuguese"
                    ),
                },
                {
                    value: "en_US",
                    name: this.translateService.instant(
                        "comp.event_info.english"
                    ),
                },
                {
                    value: "es_ES",
                    name: this.translateService.instant(
                        "comp.event_info.spanish"
                    ),
                },
                {
                    value: "fr_FR",
                    name: this.translateService.instant(
                        "comp.event_info.french"
                    ),
                },
                {
                    value: "de_DE",
                    name: this.translateService.instant(
                        "comp.event_info.german"
                    ),
                },
            ]);

        // get user language
        this.global.getLanguage((language) => {
            this.userLanguage = language;
        });

        // names modules
        this.nameModuleAttendee = new NameModule(
            "Participantes",
            "Attendees",
            "Participantes",
            "Participants",
            "Teilnehmer"
        );
        this.nameModuleSpeaker = new NameModule(
            "Palestrantes",
            "Speakers",
            "Oradores",
            "Oradores",
            "Lautsprecher"
        );
        this.nameModuleSchedule = new NameModule(
            "Agenda",
            "Schedule",
            "Horario",
            "Agenda",
            "Zeitplan"
        );
        this.nameModuleWidget = new NameModule(
            "Widget",
            "Widget",
            "Widget",
            "Widget",
            "Widget"
        );
        this.nameModuleDocument = new NameModule(
            "Documentos",
            "Documents",
            "Documentos",
            "Documents",
            "Unterlagen"
        );
        this.nameModuleGallery = new NameModule(
            "Galeria",
            "Gallery",
            "Galeria",
            "Galerie",
            "Galerie"
        );
        this.nameModuleCheckin = new NameModule(
            "Checkin",
            "Checkin",
            "Checkin",
            "Checkin",
            "Checkin"
        );
        this.nameModuleMap = new NameModule(
            "Mapas",
            "Maps",
            "Mapas",
            "Plans",
            "Karten"
        );
        this.nameModuleRanking = new NameModule(
            "Ranking",
            "Ranking",
            "Ranking",
            "Ranking",
            "Ranking"
        );
        this.nameModuleSurvey = new NameModule(
            "Pesquisa de Opinião",
            "Survey",
            "Survey",
            "Survey",
            "Survey"
        );
        this.nameModuleQuiz = new NameModule(
            "Quiz",
            "Quiz",
            "Quiz",
            "Quiz",
            "Quiz"
        );
        this.nameModuleTraining = new NameModule(
            "Training",
            "Training",
            "Training",
            "Training",
            "Training"
        );
        this.nameModuleSessionFeedBack = new NameModule(
            "Feedback da Sessão",
            "Session Feedback",
            "Comentarios de la sesión",
            "Feedback des sessions",
            "Sitzungs-Feedback"
        );
        this.NameModuleAskQuestion = new NameModule(
            "Faça uma Pergunta",
            "Ask a question",
            "Haga una pregunta",
            "Poser une question",
            "Stellen Sie eine Frage"
        );
        this.nameModuleNewsFeed = new NameModule(
            "Feed de Notícias",
            "News feed",
            "News Feed",
            "Fil d'actualités",
            "News Feed"
        );
        this.nameModuleInfobooth = new NameModule(
            "Infobooth",
            "Infobooth",
            "Infobooth",
            "Infobooth",
            "Infobooth"
        );
        this.nameModuleCustomPage = new NameModule(
            "Páginas Customizadas",
            "Custom Pages",
            "Páginas personalizadas",
            "Liste de pages HTML",
            "Benutzerdefinierte Seiten"
        );
        this.nameModuleLocation = new NameModule(
            "Localização",
            "Location",
            "Ubicación",
            "Localisation",
            "Lage"
        );
        this.nameModulePersonalAgenda = new NameModule(
            "Agenda Pessoal",
            "Personal schedule",
            "Agenda personal",
            "Agenda Personnel",
            "Persönliche Agenda"
        );
        this.nameModuleSelfeCheckin = new NameModule(
            "Auto checkin",
            "Self checkin",
            "Registro automático",
            "Self checkin",
            "Automatisches Einchecken"
        );
        this.nameModuleEvent = new NameModule(
            "Evento",
            "Event",
            "Evento",
            "Événement",
            "Evento"
        );
        this.nameModuleGroup = new NameModule(
            "Grupos",
            "Groups",
            "Grupos",
            "Groupes",
            "Gruppen"
        );
        this.nameModuleManager = new NameModule(
            "Módulos",
            "Module",
            "Módulos",
            "Modules",
            "Module"
        );
        this.nameModuleNotifications = new NameModule(
            "Notificações",
            "Notifications",
            "Notificaciones",
            "Push Notifications",
            "Benachrichtigungen"
        );
        this.nameModuleInteractivity = new NameModule(
            "Interatividade",
            "Interactivity",
            "Interactividad",
            "Interactivité",
            "Interaktivität"
        );
        this.nameModuleWordCloud = new NameModule(
            "Nuvem de palavras",
            "Word cloud",
            "Nube de palabras",
            "Mot nuage",
            "Wortwolke"
        );
        this.nameModuleGamification = new NameModule(
            "Gamificação",
            "Gamification",
            "Gamificación",
            "Gamification",
            "Gamification"
        );
        this.nameModuleGroupDiscussion = new NameModule(
            "Bate-papo em grupo",
            "Discussion group",
            "Discusión grupal",
            "Discussion de groupe",
            "Gruppendiskussion"
        );
        this.nameModuleAppointments = new NameModule(
            "Encontro",
            "Appointment",
            "Cita",
            "Rendez-vous",
            "Verabredung"
        );
        this.nameModulePrizeDraw = new NameModule(
            "Sorteio de prêmios",
            "Prize draw",
            "Sorteo de premios",
            "tirage au sort",
            "Gewinnspiel"
        );
        this.nameModuleSponsor = new NameModule(
            "Patrocinadores",
            "Sponsors",
            "Patrocinadores",
            "Sponsors",
            "Sponsoren",
        );
        this.nameModuleStand = new NameModule(
            "Estandes",
            "Stands",
            "Stands",
            "Stands",
            "Stands",
        );

        // / modules
        this.moduleAttendee = new ModuleAttendee(
            this.nameModuleAttendee,
            PathIcons.icon_people,
            TypeVisionGroup.GLOBAL_VISION,
            "",
            null
        );
        this.moduleSpeaker = new ModuleSpeaker(
            this.nameModuleSpeaker,
            PathIcons.icon_mic,
            TypeVisionGroup.GLOBAL_VISION,
            "",
            null
        );
        this.moduleSchedule = new ModuleSchedule(
            this.nameModuleSchedule,
            PathIcons.icon_date_range,
            TypeVisionGroup.GLOBAL_VISION,
            "",
            null
        );
        this.moduleWidget = new ModuleWidget(
            this.nameModuleWidget,
            PathIcons.icon_view_agenda,
            null,
            TypeVisionModule.GLOBAL_VISION,
            null
        );
        this.moduleDocuments = new ModuleDocuments(
            this.nameModuleDocument,
            PathIcons.icon_document,
            null,
            TypeVisionModule.GLOBAL_VISION,
            null
        );
        this.moduleGallery = new ModuleGallery(
            this.nameModuleGallery,
            PathIcons.icon_gallery,
            null,
            TypeVisionModule.GLOBAL_VISION,
            null
        );
        this.moduleCheckin = new ModuleCheckin(
            this.nameModuleCheckin,
            PathIcons.icon_checkin,
            TypeVisionGroup.GROUP_ACCESS_PERMISSION,
            "",
            null
        );
        this.moduleMaps = new ModuleMaps(
            this.nameModuleMap,
            PathIcons.icon_map,
            TypeVisionModule.GLOBAL_VISION,
            null
        );
        this.moduleRanking = new ModuleRanking(
            this.nameModuleRanking,
            PathIcons.icon_ranking,
            TypeVisionModule.GLOBAL_VISION,
            null
        );
        this.moduleSurvey = new ModuleSurvey(
            this.nameModuleSurvey,
            PathIcons.icon_survey,
            TypeVisionGroup.GLOBAL_VISION,
            null,
            null
        );
        this.moduleQuiz = new ModuleQuiz(
            this.nameModuleQuiz,
            PathIcons.icon_quiz,
            TypeVisionGroup.GLOBAL_VISION,
            null,
            null
        );
        this.moduleTraining = new ModuleTraining(
            this.nameModuleTraining,
            PathIcons.icon_training,
            TypeVisionGroup.GLOBAL_VISION,
            null,
            null
        );
        this.moduleSessionFeedback = new ModuleSessionFeedback(
            this.nameModuleSessionFeedBack,
            PathIcons.icon_session_feedback,
            TypeVisionGroup.GLOBAL_VISION,
            null,
            null
        );
        this.moduleAskQuestion = new ModuleAskQuestion(
            this.NameModuleAskQuestion,
            PathIcons.icon_ask_question,
            TypeVisionGroup.GLOBAL_VISION,
            null,
            null
        );
        this.moduleFeedNews = new ModuleFeedNews(
            this.nameModuleNewsFeed,
            PathIcons.icon_feed,
            TypeVisionGroup.GLOBAL_VISION,
            "",
            null
        );
        this.moduleInfobooth = new ModuleInfobooth(
            this.nameModuleInfobooth,
            PathIcons.icon_infobooth,
            TypeVisionGroup.GLOBAL_VISION,
            "",
            null
        );
        this.moduleCustomPage = new ModuleCustomPage(
            this.nameModuleCustomPage,
            PathIcons.icon_infobooth,
            TypeVisionGroup.GLOBAL_VISION,
            "",
            null
        );
        this.moduleWordCloud = new ModuleWordCloud(
            this.nameModuleWordCloud,
            PathIcons.icon_word_cloud,
            TypeVisionGroup.GLOBAL_VISION,
            null,
            null
        );
        this.moduleGamification = new ModuleGamification(
            this.nameModuleGamification,
            PathIcons.icon_android_phone,
            "",
            TypeVisionGroup.GLOBAL_VISION,
            null
        );
        this.moduleGroupDiscussion = new ModuleGroupDiscussion(
            this.nameModuleGroupDiscussion,
            PathIcons.icon_people,
            TypeVisionGroup.GLOBAL_VISION,
            "",
            null
        );
        this.moduleAppointments = new ModuleAppointments(
            this.nameModuleAppointments,
            PathIcons.icon_calendar_today,
            TypeVisionModule.GLOBAL_VISION,
            null,
            null
        );
        this.moduleSponsor = new ModuleSponsor(
            this.nameModuleSponsor,
            PathIcons.icon_sponsor,
            TypeVisionModule.GLOBAL_VISION,
            null,
            null,
        );
        this.moduleStand = new ModuleStand(
            this.nameModuleStand,
            PathIcons.icon_stand,
            TypeVisionModule.GLOBAL_VISION,
            null,
            null,
        );

        // fixed modules
        this.moduleLocation = new ModuleLocation(
            this.nameModuleLocation,
            PathIcons.icon_map,
            null
        );
        this.modulePersonalAgenda = new ModulePersonalAgenda(
            this.nameModulePersonalAgenda,
            PathIcons.icon_calendar_today,
            "",
            null
        );
        this.moduleSelfCheckin = new ModuleSelfCheckin(
            this.nameModuleSelfeCheckin,
            PathIcons.icon_smartphone,
            null,
            null
        );
        this.moduleEvent = new ModuleEvent(
            this.nameModuleEvent,
            PathIcons.icon_event,
            null,
            null
        );
        this.moduleGroups = new ModuleGroup(
            this.nameModuleGroup,
            PathIcons.icon_group,
            null
        );
        this.moduleManager = new ManagerModules(
            this.nameModuleManager,
            PathIcons.icon_settings,
            null
        );
        this.moduleNotifications = new ModuleNotification(
            this.nameModuleNotifications,
            PathIcons.icon_notications,
            null
        );
        this.moduleInteractivity = new ModuleInteractivity(
            this.nameModuleInteractivity,
            PathIcons.icon_interactivity,
            TypeVisionGroup.GLOBAL_VISION,
            null,
            null
        );
        this.modulePrizeDraw = new ModulePrizeDraw(
            this.nameModulePrizeDraw,
            PathIcons.icon_prize_draw,
            TypeVisionGroup.GLOBAL_VISION,
            null,
            null,
        );

        this.modules = [
            this.moduleAttendee,
            this.moduleSpeaker,
            this.moduleSponsor,
            this.moduleStand,
            this.moduleSchedule,
            this.moduleWidget,
            this.moduleDocuments,
            this.moduleGallery,
            this.moduleCheckin,
            this.moduleSurvey,
            this.moduleQuiz,
            this.moduleTraining,
            this.moduleSessionFeedback,
            this.moduleAskQuestion,
            this.moduleWordCloud,
            this.moduleFeedNews,
            this.moduleMaps,
            this.moduleRanking,
            this.moduleInfobooth,
            this.moduleCustomPage,
            this.moduleGamification,
            this.moduleGroupDiscussion,
            this.moduleAppointments,
        ];
    }

    ngOnInit() {}

    addModule(module) {
        module.selected = true;
        this.qtdModels++;
    }

    removeModule(module) {
        module.selected = false;
        this.qtdModels--;
    }

    createEvent() {
        this.loader = true;
        let validation = true;

        this.dateError = false;
        this.startDatePastError = false;
        this.endDatePastError = false;
        this.invalidShortcodeError = false;
        this.createEventError = false;
        this.clientError = false;

        const auxSDate: Array<string> = this.startDate.split("-");    // [year, month, day]
        const startDate: number = this.dt.makeDateTp(auxSDate[0], auxSDate[1], auxSDate[2]);

        const auxEDate: Array<string> = this.endDate.split("-");    // [year, month, day]
        const endDate: number = this.dt.makeDateTp(auxEDate[0], auxEDate[1], auxEDate[2]);

        const currentDate: number = Date.now();

        validation = true;

        // start date after closure date
        if (startDate > endDate) {
            validation = false;
            this.dateError = true;
            this.loader = false;
        }

        // If the end date is in the past
        if (startDate < currentDate) {
            validation = false;
            this.startDatePastError = true;
            this.loader = false;
        }

        // If the end date is in the past
        if (endDate < currentDate) {
            validation = false;
            this.endDatePastError = true;
            this.loader = false;
        }

        // user limit
        const limit = +this.userLimit;

        // multi language
        let languageEvent = environment.platform.defaultLanguage;

        if (!this.allowLanguageUse) {
            languageEvent = this.language_principal.value;
        }

        // modules
        const modules = [];

        // add first module
        this.moduleEvent.order = 0;
        modules.push(this.moduleEvent);

        let i = 1;
        for (const module of this.modules) {
            module.visOnlyForLoggedUser = false;
            module.visOnlyForUnloggedUser = false;
            if (module.selected) {
                module.order = i;

                modules.push(module);
                i++;
            }
        }

        // get modules Available Modules
        let available = new AvailableModules();

        for (const module of this.modules) {
            if (module.type === TypeModule.ATTENDEE) {
                available.module_attendees = module.selected;
                this.moduleAttendee.allow_chat = this.allowChatUse;
            } else if (module.type === TypeModule.SPEAKER) {
                available.module_speaker = module.selected;
            } else if (module.type === TypeModule.SPONSOR) {
                available.module_sponsor = module.selected;
            } else if (module.type === TypeModule.STAND) {
                available.module_stand = module.selected;
            } else if (module.type === TypeModule.SCHEDULE) {
                available.module_schedule = module.selected;
            } else if (module.type === TypeModule.WIDGETS) {
                available.module_widget = module.selected;
            } else if (module.type === TypeModule.DOCUMENT) {
                available.module_document = module.selected;
            } else if (module.type === TypeModule.GALLERY) {
                available.module_gallery = module.selected;
            } else if (module.type === TypeModule.SURVEY) {
                available.module_survey = module.selected;
            } else if (module.type === TypeModule.QUIZ) {
                available.module_quiz = module.selected;
            } else if (module.type === TypeModule.TRAINING) {
                available.module_training = module.selected;
            } else if (module.type === TypeModule.ASK_QUESTION) {
                available.module_ask_question = module.selected;
            } else if (module.type === TypeModule.SESSION_FEEDBACK) {
                available.module_session_feedback = module.selected;
            } else if (module.type === TypeModule.NEWS_FEED) {
                available.module_feed_news = module.selected;
            } else if (module.type === TypeModule.MAPS) {
                available.module_maps = module.selected;
            } else if (module.type === TypeModule.RANKING) {
                available.module_ranking = module.selected;
            } else if (module.type === TypeModule.CHECKIN) {
                available.module_checkin = module.selected;
            } else if (module.type === TypeModule.INFOBOOTH) {
                available.module_infobooth = module.selected;
            } else if (module.type === TypeModule.CUSTOM_PAGE) {
                available.module_custom_page = module.selected;
            } else if (module.type === TypeModule.GAMING) {
                available.module_gamification = module.selected;
            } else if (module.type === TypeModule.NETWORKING) {
                available.module_group_discussion = module.selected;
            }
        }

        // Personal calendar should only be created if there is a schedule module
        if (available.module_schedule) {
            this.modulePersonalAgenda.order = modules.length;
            modules.push(this.modulePersonalAgenda);
        }

        // Interactivity module should only be created if have at least 1 interacvity module
        // if (
        //     available.module_survey ||
        //     available.module_quiz ||
        //     available.module_training ||
        //     available.module_session_feedback ||
        //     available.module_ask_question ||
        //     available.module_prize_draw
        // ) {
            // create interactivity module
            this.moduleInteractivity.order = modules.length;
            modules.push(this.moduleInteractivity);
        // }

        if (available.module_checkin) {
            this.moduleSelfCheckin.order = modules.length;
            modules.push(this.moduleSelfCheckin);
        }

        // adds modules where the order field is equal to null
        // fixed modules
        modules.push(this.moduleManager);
        modules.push(this.moduleLocation);
        modules.push(this.moduleGroups);
        modules.push(this.moduleNotifications);
        modules.push(this.modulePrizeDraw);

        let auxShortcode = this.regex.removeSpecialCharactersAndSpaces(
            this.shortcode
        );
        this.shortcode = auxShortcode.toLowerCase();
        Promise.all([this.checkSortCode(), this.checkCLient()]).then(
            (responses: any) => {
                // shortcode
                const qtdShortcode = responses[0];
                if (qtdShortcode > 0) {
                    this.invalidShortcodeError = true;
                    validation = false;
                    this.loader = false;
                }

                // client
                const client = responses[1];
                if (client === null) {
                    this.clientError = true;
                    validation = false;
                    this.loader = false;
                }

                if (validation) {
                    const event = new Event(
                        this.title,
                        0,
                        this.shortcode,
                        this.dt.dbTime(startDate),
                        this.dt.dbTime(endDate),
                        "America/Danmarkshavn",    // identifier of (UTC+00:00) timezone
                        this.visibility,
                        limit,
                        available,
                        client,
                        languageEvent
                    );
                    event.gallery_file_limit = this.gallery_file_limit;
                    event.documents_file_limit = this.documents_file_limit;
                    event.allow_chat = this.allowChatUse;
                    event.allow_visio = this.allowVisioUse;
                    event.allow_visio_for_2 = this.allowVisioUseFor2;
                    event.allow_language = this.allowLanguageUse;
                    event.allow_analytics_plus = this.allowAnalyticsPlus;
                    event.required_edit_profile = false;
                    event.firstRegistration = false;
                    event.allowProfileQR = false;

                    this.dbEvents.createEvent(event, modules, (data) => {
                        if (data) {
                            this.loader = false;
                            this.successSwal.fire();
                        } else {
                            this.createEventError = true;
                            this.loader = false;
                        }
                    });
                }
            }
        );
    }

    checkSortCode() {
        return new Promise((resolve) => {
            this.dbEvents.checkSortCode(this.shortcode, (data) => {
                resolve(data);
            });
        });
    }

    checkCLient() {
        return new Promise((resolve) => {
            this.dbClient.checkClientWithEmail(this.emailClient, (data) => {
                resolve(data);
            });
        });
    }

    redirectList() {
        this.router.navigate(["/dashboard/events-client"]);
    }

    getUrlWithShortcode(): string {
        return `${this.defaultAppUrl}/#/${this.shortcode}`;
    }
}
