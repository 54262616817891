import { Module } from '../modules/module';
import { TypeModule } from '../../enums/type-module';
import { NameModule } from '../name-module';

export class ModuleFeedNews extends Module {
    typeVision: number
    access_groups: any // uid of the group that is allowed access to the module
    selected: boolean
    public: boolean // true: all users can post. false: only adms can post.
    comment: boolean //enable or disable the option to comment on a post, when disabled nobody can comment on anyone's post
    need_moderate: boolean
    social_sharing: boolean // booleanenables and disables the option of sharing posts on social networks.
    visOnlyForLoggedUser: boolean;

    constructor(name: NameModule, icon: string, typeVision: number, eventId: string, order: number, visOnlyForLoggedUser?: boolean) {
        super(name, icon, TypeModule.NEWS_FEED, eventId, order, true, true, true)
        this.typeVision = typeVision;
        this.access_groups = {};
        this.selected = false;
        this.public = true
        this.comment = true
        this.need_moderate = false
        this.social_sharing = false
        if (visOnlyForLoggedUser === undefined) {
            this.visOnlyForLoggedUser = false;
        } else {
            this.visOnlyForLoggedUser = visOnlyForLoggedUser;
        }
    }
}