import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from '../../providers/auth/auth.service';
import { AngularFirestore } from '@angular/fire/firestore';
import firebase from 'firebase/app';
import { StorageService } from 'src/app/providers/storage/storage.service';
import { flatMap, map, take } from 'rxjs/operators';
import {
    AppointmentRule,
    AppointmentRuleDatabase,
    AppointmentRuleTimeSlotsDatabase,
    AppointmentTimeSlot,
    AppointmentTimeSlotDatabase,
    AppointmentTimeSlotStatus,
    AppointmentTimeSlotWithGroupsAndRule
} from 'src/app/models/appointments';
import localeFr from '@angular/common/locales/fr';
import localePt from '@angular/common/locales/pt';
import localeEn from '@angular/common/locales/en';
import localeEs from '@angular/common/locales/es';
import localeDe from '@angular/common/locales/de';
import { registerLocaleData } from '@angular/common';
import { GlobalService } from '../global/global.service';
import { v4 as uuidv4 } from 'uuid';
import { combineLatest } from 'rxjs';
import { ModuleAppointments } from 'src/app/models/modules/module-appointments';

@Injectable({
    providedIn: 'root'
})
export class DbAppointments {
    locale: string;

    constructor(
        private aFirestore: AngularFirestore,
        private global: GlobalService,
    ) {
        this.global.getLanguage((language) => {
            this.locale = language.replace('_', '-');
            this.initLangFormat(language);
        });
    }

    getModule(eventId: string, moduleId: string) {
        return this.aFirestore
            .collection('events')
            .doc(eventId)
            .collection('modules')
            .doc<ModuleAppointments>(moduleId)
            .valueChanges();
    }

    async updateModuleSettings(eventId: string, moduleId: string, key: string, value: boolean) {
        const db = this.aFirestore.firestore;

        const ref1 = db.collection('events').doc(eventId).collection('modules').doc(moduleId);
        const ref2 = db.collection('modules').doc(moduleId);

        const batch = db.batch();

        const updateObject = {};
        updateObject[key] = value;

        batch.update(ref1, { ...updateObject });
        batch.update(ref2, { ...updateObject });

        await batch.commit();
    }

    async createTimeSlotsRule(rule: AppointmentRule, eventId: string, moduleId: string) {
        const db = this.aFirestore.firestore;
        const ref = db.collection('events').doc(eventId).collection('modules').doc(moduleId).collection('timeSlotsRules').doc();
        rule.uid = ref.id;
        await ref.set(rule.toDatabaseFormat());
        return rule;
    }

    async updateTimeSlotsRule(rule: AppointmentRule, eventId: string, moduleId: string) {
        const db = this.aFirestore.firestore;
        const ref = db.collection('events').doc(eventId).collection('modules').doc(moduleId).collection('timeSlotsRules').doc(rule.uid);
        await ref.set(rule.toDatabaseFormat());
        return rule;
    }

   async getAllAppointmentsPromise(eventId: string, moduleId: string) : Promise<Array<any>>{
       const snapshot = await this.aFirestore.firestore.collection('events')
       .doc(eventId)
       .collection('modules')
       .doc(moduleId)
       .collection('timeSlotsRules').get()
       let rules = []
       snapshot.docs.forEach(d => {
           rules.push(d.data())
       })
        rules = [...rules].map(rule => AppointmentRule.fromDatabase(rule))

        const moduleSnapshot = await this.aFirestore.firestore.collection('events').doc(eventId).collection('modules').doc(moduleId).get()
        const module = moduleSnapshot.data()

        return new Promise((resolve, reject) => {
            const ref = this.aFirestore.firestore.collection('events')
                    .doc(eventId)
                    .collection('appointments').get().then(snapshot => {
                        let tab = []
                        snapshot.forEach((value) => {
                            const appointment = value.data();
                            appointment.status === AppointmentTimeSlotStatus.APPOINTMENT_ACCEPTED && 
                            tab.push(appointment)
                        })
                        tab = [...tab]
                        .map(appointment => AppointmentTimeSlot.fromDatabaseFormat(appointment))
                        .filter(appointment => {
                            if (!module.hideAppointmentsOnTimeSlotDelete) {
                                return true;
                            }
                            const sourceRule = rules.find(rule => rule.uid === appointment.sourceRuleId);
                            if (!sourceRule) {
                                return false;
                            }
                            const overridenTimeSlot = appointment.getStatusWithOverridenStatusFromOtherSlots(
                                sourceRule.slots.disabledTimeSlots
                            );
                            return overridenTimeSlot.status === AppointmentTimeSlotStatus.APPOINTMENT_ACCEPTED;
                        })
                        resolve(tab)
                    })
        })
    }

    getAllAppointments(eventId: string, moduleId: string) {
        const rulesObs = this.getTimeSlotsRules(eventId, moduleId);
        const moduleObs = this.getModule(eventId, moduleId);
        return combineLatest([rulesObs, moduleObs]).pipe(flatMap(([rules, module]) => {
            return this.aFirestore.collection('events')
                .doc(eventId)
                .collection<AppointmentTimeSlotDatabase>('appointments',
                    ref => ref.where('status', '==', AppointmentTimeSlotStatus.APPOINTMENT_ACCEPTED))
                .valueChanges()
                .pipe(map(appointments => {
                    return appointments
                        .map(appointment => AppointmentTimeSlot.fromDatabaseFormat(appointment))
                        .filter(appointment => {
                            if (!module.hideAppointmentsOnTimeSlotDelete) {
                                return true;
                            }
                            const sourceRule = rules.find(rule => rule.uid === appointment.sourceRuleId);
                            if (!sourceRule) {
                                return false;
                            }
                            const overridenTimeSlot = appointment.getStatusWithOverridenStatusFromOtherSlots(
                                sourceRule.slots.disabledTimeSlots
                            );
                            return overridenTimeSlot.status === AppointmentTimeSlotStatus.APPOINTMENT_ACCEPTED;
                        }
                        );
                }));
        }));
    }

    getTimeSlotsRules(eventId: string, moduleId: string) {
        const db = this.aFirestore;
        return db.collection('events')
            .doc(eventId)
            .collection('modules')
            .doc(moduleId)
            .collection<AppointmentRuleDatabase>('timeSlotsRules')
            .valueChanges()
            .pipe(map(rulesData => rulesData.map(rule => AppointmentRule.fromDatabase(rule))));
    }

    /**
     * Set the status of a time slot for a rule
     * @param eventId
     * @param moduleId
     * @param timeSlot
     * @param status Must be AppointmentTimeSlotStatus.TIME_SLOT_ENABLED or AppointmentTimeSlotStatus.TIME_SLOT_DISABLED
     */
    async setTimeSlotStatus(
        eventId: string,
        moduleId: string,
        timeSlots: AppointmentTimeSlotWithGroupsAndRule[],
        status: AppointmentTimeSlotStatus
    ) {
        const db = this.aFirestore.firestore;

        const rules: { [key: string]: AppointmentTimeSlotWithGroupsAndRule[] } = timeSlots.reduce((r, a) => {
            r[a.sourceRule.uid] = r[a.sourceRule.uid] || [];
            r[a.sourceRule.uid].push(a);
            return r;
        }, Object.create(null));

        const batch = this.aFirestore.firestore.batch();

        for (const ruleId of Object.keys(rules)) {

            const tsFromRule: AppointmentTimeSlot[] =
                rules[ruleId][0] ? rules[ruleId][0].sourceRule.slots.getAllIntervals().filter(ts => ts.available)
                    : [];
            /* const allTimeSlotsAreDisabled = !tsFromRule.some(tsfr => !rules[ruleId].some(ts =>
                tsfr.startDateTime.equals(ts.startDateTime)
                && tsfr.endDateTime.equals(ts.endDateTime)
            )); */

            const ruleRef = db.collection('events')
                .doc(eventId)
                .collection('modules')
                .doc(moduleId)
                .collection('timeSlotsRules')
                .doc(ruleId);
            let allDisabledTimeSlots = rules[ruleId][0]
                ? rules[ruleId][0].sourceRule.slots.disabledTimeSlots.map(dts => dts.toDatabaseFormat())
                : [];
            rules[ruleId].forEach((timeSlotsForRule: AppointmentTimeSlotWithGroupsAndRule) => {
                if (timeSlotsForRule.uid) {
                    // time slot already exists, if status to set is disabled, we update it, else, we delete it
                    if (status === AppointmentTimeSlotStatus.TIME_SLOT_ENABLED) {
                        allDisabledTimeSlots = allDisabledTimeSlots.filter(dts => dts.uid !== timeSlotsForRule.uid);
                        timeSlotsForRule.status = status;
                        timeSlotsForRule.uid = undefined;
                    } else {
                        const foundIndex = allDisabledTimeSlots.findIndex(dts => dts.uid === timeSlotsForRule.uid);
                        allDisabledTimeSlots[foundIndex] = timeSlotsForRule.toDatabaseFormat();
                    }
                } else if (status !== AppointmentTimeSlotStatus.TIME_SLOT_ENABLED) {
                    timeSlotsForRule.status = status;
                    timeSlotsForRule.uid = uuidv4();
                    allDisabledTimeSlots.push(timeSlotsForRule.toDatabaseFormat());
                }
            });
            batch.update(ruleRef, {
                'slots.disabledTimeSlots': allDisabledTimeSlots
            });
        }

        return batch.commit();
    }


    async deleteRule(ruleId: string, eventId: string, moduleId: string) {
        const ruleRef = this.aFirestore.firestore.collection('events')
            .doc(eventId)
            .collection('modules')
            .doc(moduleId)
            .collection('timeSlotsRules')
            .doc(ruleId);
        const rule = AppointmentRule.fromDatabase((await ruleRef.get()).data() as AppointmentRuleDatabase);
        const allTimeSlots = rule.slots.getAllIntervals()
            .map(ts => {
                ts.status = AppointmentTimeSlotStatus.TIME_SLOT_DISABLED;
                return ts.toDatabaseFormat();
            });
        await this.aFirestore.firestore.collection('events')
            .doc(eventId)
            .collection('modules')
            .doc(moduleId)
            .collection('timeSlotsRules')
            .doc(ruleId).update({
                'slots.disabledTimeSlots': allTimeSlots
            });
    }

    async removeModuleData(eventId: string) {
        const batch = this.aFirestore.firestore.batch();
        const docs = await this.aFirestore.collection('events')
            .doc(eventId)
            .collection('appointments')
            .get()
            .pipe(take(1))
            .toPromise();
        docs.forEach(doc => {
            batch.delete(doc.ref);
        });
        await batch.commit();
    }


    initLangFormat(lang) {
        let langtoUse;
        switch (lang) {
            case 'pt_BR': {
                langtoUse = localePt;
                break;
            }
            case 'en_US': {
                langtoUse = localeEn;
                break;
            }
            case 'es_ES': {
                langtoUse = localeEs;
                break;
            }
            case 'fr_FR': {
                langtoUse = localeFr;
                break;
            }
            case 'de_DE': {
                langtoUse = localeDe;
                break;
            }
        }
        registerLocaleData(langtoUse);
    }
}
