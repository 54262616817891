import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "transformVisibility",
})
export class TransformVisibilityPipe implements PipeTransform {
  transform(value: boolean): string {
    return value
      ? "comp.group_discussions.public"
      : "comp.group_discussions.private";
  }
}
