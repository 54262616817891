import { NameModule } from "./name-module";

export class Widget {
    public uid: string;
    public title: Object;
    public proportion: string;
    public order: number;
    public isImage: boolean;
    public image: NameModule;
    public backgroundColor: string;
    public borderColor: string;
    public borderEnabled: boolean;
    public backgroundEnabled: boolean;
    public eventId: string;
    public moduleId: string;
    public borderSize: string;
    public storageId: string;
    public route: string;
    public open_link_behavior: string;
    public isExtern: boolean;
    public iconFill: string;
    public subtitleTxtColor: string;
    public subtitleBgColor: string;
    public typeVision: number
    public groups: {}
    public widgetVisForLoggedUsers: boolean;
    public widgetVisForUnloggedUsers: boolean;
    public gridArea?: string
    public iframeImg?: string = '';
    public iframeSrc?: string = '';

    constructor(proportion: string, 
        order: number, 
        isImage: boolean, 
        image: NameModule, 
        backgroundColor: string, 
        borderColor: string, 
        borderEnabled: boolean, 
        backgroundEnabled: boolean, 
        borderSize: string, 
        eventId: string, 
        moduleId: string, 
        storageId: string, 
        route: string, 
        isExtern: boolean, 
        typeVision: number, 
        groups: any, 
        widgetVisForLoggedUsers: boolean, 
        widgetVisForUnloggedUsers: boolean, 
        open_link_behavior: string, 
        gridArea?: string) {
        this.uid = null;
        this.proportion = proportion;
        this.order = order;
        this.isImage = isImage;
        this.image = image;
        this.backgroundColor = backgroundColor;
        this.borderColor = borderColor;
        this.borderEnabled = borderEnabled;
        this.backgroundEnabled = backgroundEnabled;
        this.eventId = eventId;
        this.moduleId = moduleId;
        this.borderSize = borderSize;
        this.storageId = storageId;
        this.route = route;
        this.isExtern = isExtern;
        this.iconFill = null;
        this.subtitleTxtColor = null;
        this.subtitleBgColor = null;
        this.typeVision = typeVision;
        this.groups = groups;
        this.widgetVisForLoggedUsers = widgetVisForLoggedUsers;
        this.widgetVisForUnloggedUsers = widgetVisForUnloggedUsers;
        this.open_link_behavior = open_link_behavior;
        this.gridArea = gridArea;
    }
}
