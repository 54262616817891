import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { PathApi } from '../../paths/path-api';
import { AngularFirestore } from '@angular/fire/firestore';
import firebase from 'firebase/app';
import { Quiz } from 'src/app/models/quiz';
import { Question } from 'src/app/models/quiz-question';
import { Answer } from 'src/app/models/quiz-answer';
import { TypeModule } from 'src/app/enums/type-module';
import { NotifiicationDateService } from '../luxon/notification-date.service';
import { createOfflineCompileUrlResolver } from '@angular/compiler';
import { StorageService } from '../storage/storage.service';
import { NameModule } from 'src/app/models/name-module';
import { map, switchMap, take } from 'rxjs/operators';
import { of } from 'rxjs';
import * as _ from 'lodash';
import { Session } from 'protractor';
import { select } from '@ngrx/store';

import { PathStorage } from 'src/app/paths/path-storage';
import { UUID } from 'angular2-uuid';



@Injectable({
    providedIn: 'root'
})
export class DbQuizProvider {
    private db: firebase.firestore.Firestore;
    public headers;
    public requestOptions;

    constructor(private http: HttpClient, private aFirestore: AngularFirestore, private luxon: NotifiicationDateService, private storage: StorageService) {
        this.headers = new HttpHeaders();
        this.headers.append("Accept", 'application/json');
        this.headers.append("Content-Type", 'application/json');
        this.requestOptions = { headers: this.headers, params: {}, body: {} };
    }

    getQuizModuleAndQuizs(eventId: string, onResolve) {
        let db = this.aFirestore.firestore;
        let moduleId;
        let ref = db.collection('events').doc(eventId).collection('modules');
        let listQuizs = [];
        ref
            .where('type', '==', TypeModule.QUIZ)
            .get()
            .then((snapshot) => {
                if (snapshot.size >= 1) {
                    snapshot.forEach(element => {
                        moduleId = element.data().uid;
                    });

                    let refQuiz = db.collection('modules').doc(moduleId).collection('quizs').orderBy('order', 'asc');

                    refQuiz.get().then((data) => {
                        data.forEach(element => {
                            let quiz = element.data();
                            this.getQuestions(moduleId, quiz.uid, (questions) => {

                                quiz.questions = questions;
                                listQuizs.push(quiz);
                            })
                        });

                    })
                }
                onResolve({
                    quizs: listQuizs,
                    moduleId: moduleId
                })
            })
            .catch((e) => {
                onResolve(e);
            })

    }
    getQuizModule(eventId: string, onResolve) {
        let db = this.aFirestore.firestore;
        let ref = db.collection('events').doc(eventId).collection('modules');

        ref
            .where('type', '==', TypeModule.QUIZ)
            .get()
            .then((snapshot) => {
                let auxModule = [];
                if (snapshot.size >= 1) {
                    snapshot.forEach(element => {
                        auxModule.push(element.data());
                    });
                }
                onResolve(auxModule);
            })
            .catch((e) => {
                onResolve(e);
            })
    }

    getTotalQuizs(moduleId, onResolve) {
        let db = this.aFirestore.firestore;
        let ref = db.collection('modules').doc(moduleId).collection('quizs');
        ref.onSnapshot((data) => {
            onResolve(data.size);
        });
    }

    changeOrder(moduleId, quizs, onResolve) {
        let db = this.aFirestore.firestore;
        let batch = db.batch();

        for (let quiz of quizs) {
            let ref = db.collection('modules').doc(moduleId).collection('quizs').doc(quiz.uid)
            batch.update(ref, { order: quiz.order })
        }

        batch.commit()
            .then(() => {
                onResolve(true)
            })
            .catch((e) => {
                onResolve(false)
            })
    }

    getQuizs(moduleId, onResolve) {
        let db = this.aFirestore.firestore;
        let ref = db.collection('modules').doc(moduleId).collection('quizs').orderBy('order', 'asc');
        ref.onSnapshot((data) => {
            let listQuizs = [];
            data.forEach(element => {
                let quiz = element.data();
                if (quiz.uid !== undefined) {
                    this.getQuestions(moduleId, quiz.uid, (questions) => {
                        quiz.questions = questions;
                        listQuizs.push(quiz);
                    });
                }
            });

            onResolve(listQuizs)
        });
    }

    getQuiz(moduleId, quizId, onResolve) {
        let db = this.aFirestore.firestore;
        let ref = db
            .collection('modules')
            .doc(moduleId)
            .collection('quizs')
            .doc(quizId);

        ref.get()
            .then((data) => {
                let quiz = data.data();
                this.getQuestions(moduleId, quizId, (questions) => {
                    quiz.questions = questions;
                    onResolve(quiz);
                })
            })
            .catch((err) => {
                console.log(err)
            })
    }

    getQuestions(moduleId: string, quizId: string, onResolve) {
        let db = this.aFirestore.firestore;

        let listQuestions: Array<Question> = [];

        let refQuestions = db.collection('modules').doc(moduleId).collection('quizs').doc(quizId).collection('questions')
            .orderBy('createdAt', 'asc');;

        refQuestions.get()
            .then((data) => {
                data.forEach(element => {
                    let question = this.instantiateQuestion(element.data());
                    listQuestions.push(question);
                });

                onResolve(listQuestions)
            })
    }

    getQuestion(moduleId: string, quizId: string, questionId: string, onResolve) {
        let db = this.aFirestore.firestore;

        let refQuestion = db
            .collection('modules')
            .doc(moduleId)
            .collection('quizs')
            .doc(quizId)
            .collection('questions')
            .doc(questionId);

        refQuestion.get()
            .then((data) => {
                onResolve(data.data());
            })
            .catch((err) => {
                console.log(err)
            })
    }

    getAnswers(moduleId: string, quizId: string, questionId: string, onResolve) {
        let db = this.aFirestore.firestore;

        let refQuestions = db.
            collection('modules')
            .doc(moduleId)
            .collection('quizs')
            .doc(quizId)
            .collection('questions')
            .doc(questionId)
            .collection('answers')
            .orderBy('createdAt', 'asc');;

        refQuestions.get()
            .then((data) => {
                let listAnswers: Array<Answer> = [];
                data.forEach(element => {
                    let answer: Answer = this.instantiateAnswer(element.data());
                    listAnswers.push(answer);
                });

                onResolve(listAnswers);
            })
            .catch((err) => {
                console.log(err)
            })
    }

    getResultAnswer(moduleId, quizId, questionId, answerId, onResolve) {
        let db = this.aFirestore.firestore;

        let ref = db
            .collection('modules')
            .doc(moduleId)
            .collection('quizs')
            .doc(quizId)
            .collection('questions')
            .doc(questionId)
            .collection('result');

        let listResults = [];
        ref.get()
            .then((data) => {

                if (data.size > 0) {

                    let listResultUser = [];
                    data.forEach(user => {
                        listResultUser.push(user);
                    });

                    let cont = 0;
                    for (let userResult of listResultUser) {
                        let userId = userResult.uid;

                        ref.doc(userId)
                            .get()
                            .then((dataResult) => {
                                let result = dataResult.data();

                                let contResult = 0;
                                for (let answer of result.answer) {
                                    if (answer == answerId) {
                                        listResults.push(userId);
                                    }

                                    if (contResult == result.answer.length - 1) {

                                        if (cont == listResultUser.length - 1) {
                                            onResolve(listResults);
                                        }

                                        cont++;
                                    }

                                    contResult++;
                                }


                            })
                    }
                } else {
                    onResolve(listResults);
                }

            })
    }

    instantiateQuestion(data) {
        let question = new Question();

        question.uid = data.uid;
        question.type = data.type;
        question.title = data.title;
        data.graphicDifusion != undefined ? question.graphicDifusion = data.graphicDifusion : question.graphicDifusion = false;
        data.infobooth != undefined ? question.infobooth = data.infobooth : question.infobooth = new NameModule('', '', '', '', '');
        data.graphic != undefined ? question.graphic = data.graphic : question.graphic = null;
        data.visibility != undefined ? question.visibility = data.visibility : question.visibility = true;

        return question;
    }

    instantiateAnswer(data) {
        let answer = new Answer();
        answer.uid = data.uid;
        answer.answer = data.answer;
        data.weight != undefined ? answer.weight = data.weight : answer.weight = null;
        data.marker != undefined ? answer.marker = data.marker : answer.marker = null;
        data.correct != undefined ? answer.correct = data.correct : answer.correct = false;
        answer.img = data.img;
        return answer;
    }

    changeVisibility(moduleId, quizId, visibility) {
        let db = this.aFirestore.firestore;

        let ref = db
            .collection('modules')
            .doc(moduleId)
            .collection('quizs')
            .doc(quizId);

        ref.update({
            visibility: visibility
        })
    }

    changeVisibilityQuestion(moduleId, quizId, questionId, visibility) {
        return (
            this.aFirestore
                .collection("modules")
                .doc(moduleId)
                .collection("quizs")
                .doc(quizId)
                .collection("questions")
                .doc(questionId)
                .update({ visibility: visibility })
        )
    }

    createQuiz(capaImg: File, moduleId: string, quiz: Quiz, referenceModuleId: string, selected: any, timezone: string, eventId: string, onResolve) {
        const aux = quiz.question;
        delete quiz.question;
        
        let db = this.aFirestore.firestore;
        let quizId;
        
        let ref = db.collection('modules').doc(moduleId).collection('quizs').doc();
        quiz.uid = ref.id;
        
        let imgId = UUID.UUID();
        
        if(capaImg !== undefined){

            this.storage.uploadImg(capaImg, eventId, moduleId, quiz.uid, imgId, PathStorage.quiz, (url) => {
                quiz.imgCapa = url;
                
                quiz.title = Object.assign({}, quiz.title)
                quiz = Object.assign({}, quiz);
                ref.set(quiz)
                .then(() => {
                    
                    if (quiz.type == 'SpecificSession' || quiz.type == 'SessionTrack' || quiz.type == 'SpecificGroup') {
                        
                        this.forQuizReferences(selected, quiz.uid, moduleId);
                    }
                    
                    // if (quiz.type == 'SpecificSession') {
                        // this.forQuizSessionsUids(selected, quizId, moduleId);
                        // }
                        // if (quiz.type == 'SessionTrack') {
                            // this.forQuizTracksUids(selected, quizId, moduleId, referenceModuleId);
                            // }
                            // if (quiz.type == 'SpecificGroup') {
                    // this.forQuizGroupsUids(selected, quizId, moduleId);
                    // }
                    
                    this.createQuestions(moduleId, quiz.uid, aux, timezone, eventId, (data) => {
                        if (data == true) {
                            onResolve(data);
                        }
                    });
                })
                .catch((error) => {
                    
                })
            });
        }else{                
            quiz.title = Object.assign({}, quiz.title)
            quiz = Object.assign({}, quiz);
            ref.set(quiz)
                .then(() => {

                    if (quiz.type == 'SpecificSession' || quiz.type == 'SessionTrack' || quiz.type == 'SpecificGroup') {

                        this.forQuizReferences(selected, quiz.uid, moduleId);
                    }

                    this.createQuestions(moduleId, quiz.uid, aux, timezone, eventId, (data) => {
                        if (data == true) {
                            onResolve(data);
                        }
                    });
                })
                .catch((error) => {

                })
        }
    }

    forQuizReferences(selectedSessions: any[], quizId, moduleId) {
        return (
            this.aFirestore
                .collection("modules")
                .doc(moduleId)
                .collection("quizs")
                .doc(quizId)
                .update({
                    selectedSessionsData: selectedSessions,
                    references: selectedSessions.map((session) => session.uid)
                })
        )
    }

    /**
   * Create question
   */
    createQuestions(moduleId: string, idQuiz: string, question: Array<Question>, timezone, eventId, onResolve) {
        let db = this.aFirestore.firestore;
        let contTime = 0;
        let cont = 0;
        for (let data of question) {
            setTimeout(() => {
                const answers = data.answers;
                delete data.answers;

                let file = data.image;
                data.image = null;

                let questionId;

                let ref = db.collection('modules').doc(moduleId).collection('quizs').doc(idQuiz).collection('questions')
                data.createdAt = this.luxon.getTimeStampFromDateNow(new Date(), timezone);

                data = Object.assign({}, data);
                data.title = Object.assign({}, data.title);
                data.infobooth = Object.assign({}, data.infobooth);

                ref.add(data)
                    .then((docRef) => {
                        questionId = docRef.id;

                        ref.doc(questionId).update({ uid: questionId })
                            .then(() => {
                                if (file != null && file != undefined && file !== '') {
                                    this.storage.uploadQuestionQuizImage(file, moduleId, idQuiz, questionId, eventId).then((url) => {
                                        ref.doc(questionId).update({ image: url }).then(() => {
                                            if (data.type == 'oneSelect' || data.type == 'multipleSelect') {
                                                this.createAnswers(moduleId, idQuiz, questionId, answers, timezone, eventId);
                                            }

                                            if (cont == question.length - 1) {
                                                onResolve(true);
                                            }

                                            cont++;
                                        })
                                    })
                                } else {

                                    if (data.type == 'oneSelect' || data.type == 'multipleSelect') {
                                        this.createAnswers(moduleId, idQuiz, questionId, answers, timezone, eventId);
                                    }

                                    if (cont == question.length - 1) {
                                        onResolve(true);
                                    }

                                    cont++;
                                }

                            })
                    })
            }, contTime * 30);

            contTime++;
        }
    }

    /**
   * Create answer
   */
    createAnswers(moduleId, idQuiz, idQuestion: string, answers: Array<Answer>, timezone, eventId?: string) {
        let db = this.aFirestore.firestore;

        let cont = 0;
        for (let data of answers) {
            setTimeout(() => {
                let ref = db.collection('modules')
                    .doc(moduleId)
                    .collection('quizs')
                    .doc(idQuiz)
                    .collection('questions')
                    .doc(idQuestion)
                    .collection('answers');

                data.createdAt = this.luxon.getTimeStampFromDateNow(new Date(), timezone);
                data = Object.assign({}, data);
                data.answer = Object.assign({}, data.answer)
                ref.add(data).then(async (docRef) => {
                    let answerId = docRef.id;
                    let url = '';
                    if(data.img){
                        url = await this.storage.uploadAnswerQuizImage(data.img ,eventId, moduleId,idQuiz, idQuestion, answerId)
                    } else {
                        url = '';
                    }
                    ref.doc(answerId).update({ uid: answerId, img: url })

                });
            }, cont * 30);

            cont++;
        }
    }

    deleteAnswer(moduleId, quizId, questionId, answerId, onResolve) {
        let db = this.aFirestore.firestore;

        let ref = db.collection('modules')
            .doc(moduleId)
            .collection('quizs')
            .doc(quizId)
            .collection('questions')
            .doc(questionId)
            .collection('answers')
            .doc(answerId);

        ref.delete()
            .then(() => {
                onResolve(true);
            })
            .catch(() => {
                onResolve(false);
            })
    }

    updateQuiz(moduleId, quizId, quiz, onResolve) {

        let db = this.aFirestore.firestore;

        let ref = db.collection('modules')
            .doc(moduleId)
            .collection('quizs')
            .doc(quizId);

        quiz = Object.assign({}, quiz);
        quiz.title = Object.assign({}, quiz.title)
        ref.update(quiz).then(() => {
            onResolve(true);
        })
    }

    updateQuestion(moduleId, quizId, questionId, newQuestion, timezone, eventId, onResolve) {
        let db = this.aFirestore.firestore;

        newQuestion.uid = questionId;
        const answers = newQuestion.answers;
        delete newQuestion.answers;

        let file = newQuestion.image;
        delete newQuestion.image;

        let ref = db.collection('modules')
            .doc(moduleId)
            .collection('quizs')
            .doc(quizId)
            .collection('questions')
            .doc(questionId);

        newQuestion = Object.assign({}, newQuestion);
        newQuestion.title = Object.assign({}, newQuestion.title);
        newQuestion.infobooth = Object.assign({}, newQuestion.infobooth);

        ref.update(newQuestion)
            .then(() => {
                if (newQuestion.type == 'oneSelect' || newQuestion.type == 'multipleSelect') {

                    if (file !== null && file !== undefined) {
                        this.storage.uploadQuestionQuizImage(file, moduleId, quizId, questionId, eventId).then((url) => {
                            ref.update({ image: url }).then(() => {
                                this.updateAnswer(moduleId, quizId, questionId, answers, timezone, (data) => {
                                    if (data) {
                                        onResolve(data);
                                    }
                                }, eventId);
                            })
                        })
                    } else {
                        this.updateAnswer(moduleId, quizId, questionId, answers, timezone, (data) => {
                            if (data) {
                                onResolve(data);
                            }
                        }, eventId);
                    }
                }
            })
            .catch(() => {
                onResolve(false);
            })
    }

    async updateAnswer(moduleId: string, quizId: string, questionId: string, answers: Array<Answer>, timezone, onResolve, eventId?: string ) {
        let db = this.aFirestore.firestore;

        let ref = db.collection('modules')
            .doc(moduleId)
            .collection('quizs')
            .doc(quizId)
            .collection('questions')
            .doc(questionId);

        let cont = 0;
        for (let newAnswer of answers) {
            if (newAnswer.weight == undefined) {
                newAnswer.weight = null;
            }

            let auxAnswer = {...newAnswer};
            let auxAnswerId = newAnswer.uid;
            if (auxAnswerId) {

                auxAnswer = Object.assign({}, auxAnswer);
                auxAnswer.answer = Object.assign({}, auxAnswer.answer);

                let url = '';
                if(auxAnswer.img && !auxAnswer.img.includes('https://')){ // img contains base64 image
                    url = await this.storage.uploadAnswerQuizImage(auxAnswer.img, eventId, moduleId, quizId, questionId, auxAnswerId)
                } 
                auxAnswer.img = url;
                ref.collection('answers')
                    .doc(auxAnswerId)
                    .update(auxAnswer)
                    .then(() => {
                        if (cont == answers.length - 1) {
                            onResolve(true);
                        }

                        cont++;
                    })
            } else {
                auxAnswer.createdAt = this.luxon.getTimeStampFromDateNow(new Date(), timezone);
                auxAnswer = Object.assign({}, auxAnswer);
                auxAnswer.answer = Object.assign({}, auxAnswer.answer)
                ref.collection('answers')
                    .add(auxAnswer)
                    .then(async (data) => {

                        let answerId = data.id;
                        let url = '';
                        if(auxAnswer.img && !auxAnswer.img.includes('https://')){
                            console.log(auxAnswer.img)
                            url = await this.storage.uploadAnswerQuizImage(auxAnswer.img,eventId, moduleId,quizId, questionId, answerId)
                        } 
                        ref.collection('answers')
                            .doc(answerId)
                            .update({ uid: answerId, img: url })
                            .then(() => {
                                if (cont == answers.length - 1) {
                                    onResolve(true);
                                }

                                cont++;
                            })
                    })
            }
        }
    }

    createOneQuestions(moduleId, quizId, newQuestion, timezone, eventId, onResolve) {
        let db = this.aFirestore.firestore;

        let answers = newQuestion.answers;
        delete newQuestion.answers;

        let file = newQuestion.image;
        newQuestion.image = null;

        let ref = db.collection('modules')
            .doc(moduleId)
            .collection('quizs')
            .doc(quizId)
            .collection('questions');

        newQuestion.createdAt = this.luxon.getTimeStampFromDateNow(new Date(), timezone);
        newQuestion = Object.assign({}, newQuestion);
        newQuestion.title = Object.assign({}, newQuestion.title);
        newQuestion.infobooth = Object.assign({}, newQuestion.infobooth);
        ref.add(newQuestion)
            .then((data) => {
                let questionId = data.id;

                ref.doc(questionId).update({
                    uid: questionId
                })
                    .then(() => {
                        if (file != null && file !== undefined && file !== '') {
                            this.storage.uploadQuestionQuizImage(file, moduleId, quizId, questionId, eventId).then((url) => {
                                ref.doc(questionId).update({ image: url })
                                    .then(() => {
                                        if (newQuestion.type == 'oneSelect' || newQuestion.type == 'multipleSelect') {
                                            // answers = Object.assign({}, answers);
                                            this.createAnswers(moduleId, quizId, questionId, answers, timezone);

                                            onResolve({
                                                status: true,
                                                questionId: questionId
                                            });
                                        }
                                    })
                            })
                        } else {
                            if (newQuestion.type == 'oneSelect' || newQuestion.type == 'multipleSelect') {
                                // answers = Object.assign({}, answers);
                                this.createAnswers(moduleId, quizId, questionId, answers, timezone);

                                onResolve({
                                    status: true,
                                    questionId: questionId
                                });
                            }
                        }

                    })
            })
            .catch((err) => {
                onResolve(err);
            })
    }

    removeQuiz(moduleId, quizId, onResolve) {
        this.http.delete(PathApi.baseUrl + PathApi.dbQuizDeleteQuiz + '?moduleId=' + moduleId + '&quizId=' + quizId, this.requestOptions)
            .subscribe((data) => {
                onResolve(data)
            },
                (error) => {
                    onResolve(error);
                })
    }

    /**
     * Clear all results of quiz
     * @param moduleId 
     * @param quizsIds 
     * @param onResolve 
     */
    clearAllQuizs(eventId: string, moduleId: string, quizsIds: Array<string>) {
        let chunkedIds = _.chunk(quizsIds, 10);

        chunkedIds.forEach((quizIds) => {
            this.aFirestore
                .collection('events')
                .doc(eventId)
                .collection('attendees', (ref) => ref.where('answeredQuizes', "array-contains-any", quizIds))
                .get()
                .pipe(
                    switchMap((attendeesDocs) => {
                        let batch = this.aFirestore.firestore.batch();

                        attendeesDocs.docs.forEach((doc) => {
                            let attendee = doc.data();
                            quizIds.forEach((quizId) => {
                                if (attendee.answeredQuizes && attendee.answeredQuizes.includes(quizId)) {
                                    attendee.answeredQuizes.splice(1, attendee.answeredQuizes.indexOf(quizId));
                                    batch.update(doc.ref, attendee);
                                }
                            });
                        })
                        return (batch.commit());
                    })
                )
                .toPromise()
        })

        this.requestOptions.body.quizsIds = quizsIds;
        return (this.http.delete(PathApi.baseUrl + PathApi.dbQuizClearAll + '?moduleId=' + moduleId, this.requestOptions).pipe(take(1)));
    }

    /**
     * Clear result of quiz
     * @param moduleId 
     * @param quizId 
     */
    clearQuiz(eventId: string, moduleId: string, quizId: string) {
        this.aFirestore
            .collection('events')
            .doc(eventId)
            .collection('attendees', (ref) => ref.where('answeredQuizes', "array-contains", quizId))
            .get()
            .pipe(
                switchMap((attendeesDocs) => {
                    let batch = this.aFirestore.firestore.batch();
                    attendeesDocs.docs.forEach((doc) => {
                        let attendee = doc.data();
                        if (attendee.answeredQuizes && attendee.answeredQuizes.includes(quizId)) {
                            attendee.answeredQuizes.splice(attendee.answeredQuizes.indexOf(quizId), 1);
                            batch.update(doc.ref, attendee);
                        }
                    })
                    return (batch.commit());
                })
            )
            .toPromise();
        return (this.http.delete(PathApi.baseUrl + PathApi.dbQuizClearResultQuiz + '?moduleId=' + moduleId + '&quizId=' + quizId, this.requestOptions).pipe(take(1)));
    }

    removeQuestion(moduleId: string, quizId: string, questionId: string, onResolve) {
        this.http.delete(PathApi.baseUrl + PathApi.dbQuizDeleteQuestion + '?moduleId=' + moduleId + '&quizId=' + quizId + '&questionId=' + questionId, this.requestOptions)
            .subscribe((data) => {
                onResolve(data)
            },
                (error) => {
                    onResolve(error);
                })
    }

    /**
     * Clear question
     * @param moduleId 
     * @param quizId 
     * @param questionId 
     */
    clearQuestion(moduleId: string, quizId: string, questionId: string) {
        return (this.http.delete(PathApi.baseUrl + PathApi.dbQuizClearResultQuestion + '?moduleId=' + moduleId + '&quizId=' + quizId + '&questionId=' + questionId, this.requestOptions).pipe(take(1)));
    }

    exportQuizs(moduleId: string, quizId: string, language: string, onResolve) {

        this.http.get(PathApi.baseUrl + PathApi.dbQuizExportQuiz + '?moduleId=' + moduleId + '&quizId=' + quizId + '&language=' + language, this.requestOptions)
            .subscribe((data) => {
                onResolve(data)
            },
                (error) => {
                    onResolve(error);
                })
    }

    exportQuestion(moduleId: string, quizId: string, questionId: string, language: string, onResolve) {
        this.http.get(PathApi.baseUrl + PathApi.dbQuizExportQuestionQuiz + '?moduleId=' + moduleId + '&quizId=' + quizId + '&questionId=' + questionId + '&language=' + language, this.requestOptions)
            .subscribe((data) => {
                onResolve(data)
            },
                (error) => {
                    onResolve(error);
                })
    }
}