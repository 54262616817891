export class Log {
    public date: number;
    public operation: string;
    public userId: string;
    public userName: string;
    public eventId: string;
    public eventName: string;
    public itemId: string;
    public itemName: string;
    public moduleId: string;
    public moduleName: string;
    public type: string;  // inEvent | outEvent | user
    public userType: number;

    constructor(date: number, operation: string, userId: string, userName: string, itemId: string, itemName: string, type: string) {
        this.date = date;
        this.operation = operation;
        this.userId = userId;
        this.userName = userName;
        this.eventId = null;
        this.eventName = null;
        this.itemId = itemId;
        this.itemName = itemName;
        this.moduleId = null;
        this.moduleName = null;
        this.type = type;
        this.userType = null;
    }
}