import { Component, OnInit, ViewChild } from '@angular/core';
import { DbAttendeesProvider } from 'src/app/providers/database/db-attendees';
import { Attendee } from 'src/app/models/attendees';
import { DbRankingProvider } from 'src/app/providers/database/db-ranking';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { ActivatedRoute } from '@angular/router';
import { GlobalService } from 'src/app/providers/global/global.service';
import { DbEventsProvider } from 'src/app/providers/database/db.events';
import { TranslateService } from '@ngx-translate/core';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
type AOA = Array<Array<any>>;

@Component({
  selector: 'app-ranking',
  templateUrl: './ranking.component.html',
  styleUrls: ['./ranking.component.scss'],
  providers: [DbRankingProvider]
})
export class RankingComponent implements OnInit {
  @ViewChild('successSwal') public successSwal: SwalComponent;
  @ViewChild('errorSwal') public errorSwal: SwalComponent;
  term;

  public module = null
  mergeInteractivityPoints: boolean = false;
  showOnlyAttendeeWithPoints: boolean = false;

  // get the language of the user.
  public userLanguage: string

  eventId: string;
  moduleId: string;
  attendees: Array<Attendee> = [];
  // EXPORT DATA
  data: any = null;
  attendeeExport: AOA = null;
  loader: boolean = true;
  isSavingSettings: boolean = false;

  constructor(
    private dbRanking: DbRankingProvider,
    private route: ActivatedRoute,
    private global: GlobalService,
    private dbEvent: DbEventsProvider,
    private translateService: TranslateService,
  ) {
    this.eventId = this.route.parent.params['_value']['uid'];
    this.moduleId = this.route.snapshot.params['moduleId'];
  }

  ngOnInit() {
    this.dbRanking.getRankingModule(this.eventId, this.moduleId, (module) => {
      this.module = module;
      this.mergeInteractivityPoints = module.mergeInteractivityPoints ? module.mergeInteractivityPoints : false;
      this.showOnlyAttendeeWithPoints = module.showOnlyAttendeeWithPoints ? module.showOnlyAttendeeWithPoints : false;
    });
    this.getEvent();
    this.start();
    this.getUserLanguage()
  }

  event: any = null;
  getEvent() {
    this.dbEvent.getEvent(this.eventId, (event: Event) => {
      this.event = event;
    });
  }

  start() {
    this.dbRanking.getAttendeeRanking(this.eventId, (attendees) => {
        this.attendees = this.sortAttendeesByPoints(attendees);
        this.loader = false;
    });
  }

  resetRankingSettingsModal() {
    this.mergeInteractivityPoints = this.module.mergeInteractivityPoints ? this.module.mergeInteractivityPoints : false;
    this.showOnlyAttendeeWithPoints = this.module.showOnlyAttendeeWithPoints ? this.module.showOnlyAttendeeWithPoints : false;
  }

  toggleShowOnlyAttendeeWithPoints() {
    this.showOnlyAttendeeWithPoints = !this.showOnlyAttendeeWithPoints;
  }

  toggleMergeInteractivityPoints() {
    this.mergeInteractivityPoints = !this.mergeInteractivityPoints;
    this.attendees = this.sortAttendeesByPoints(this.attendees);
}

  saveRankingSettings() {
    this.isSavingSettings = true;
    this.dbRanking.updateRankingSettings(this.eventId, this.moduleId, this.mergeInteractivityPoints, this.showOnlyAttendeeWithPoints)
        .then(() => {
            this.successSwal.fire();
        })
        .catch((err) => {
            console.error(err);
            this.errorSwal.fire();
        })
        .finally(() => {
            document.getElementById("btnCloseSettings").click();
            this.isSavingSettings = false;
        });
  }

  attendeesToShow() {
    const showOnlyAttendeeWithPoints = this.module.showOnlyAttendeeWithPoints ? this.module.showOnlyAttendeeWithPoints : false;
    if (showOnlyAttendeeWithPoints)
        return this.attendeesWithPoints();
    return this.attendees;
  }

  attendeesWithPoints() {
    return this.attendees.filter(attendee => this.getAttendeeEngagementPoints(attendee) > 0 || attendee['points']);
  }

  sortAttendeesByPoints(attendees) {
    attendees = attendees.sort((a, b) => { // sorting by name (asc)
        return a['name'].toLowerCase() > b['name'].toLowerCase() ? 1 : -1;
    })

    attendees = attendees.sort((a, b) => { // sorting by interactivity points
        return a.points > b.points ? -1 : 1;
    })

    return attendees.sort((a, b) => { // sorting by engagement points
        const aPoints = this.getAttendeeEngagementPoints(a) + (this.mergeInteractivityPoints ? a.points : 0);
        const bPoints = this.getAttendeeEngagementPoints(b) + (this.mergeInteractivityPoints ? b.points : 0);
        return aPoints > bPoints ? -1 : 1;
    })
  }

  getAttendeeEngagementPoints(attendee) {
    if (!attendee.engagementPoints) return 0;
    return Object.values<number>(attendee.engagementPoints).reduce((acc: number, cur: number) => acc + cur, 0);
  }

exportRanking() {
    this.attendeeExport = [];
    this.attendeeExport = [[
        this.translateService.instant('global.position'),
        this.translateService.instant('global.name'),
        this.translateService.instant('comp.ranking.engagement_points'),
        this.translateService.instant('comp.ranking.interactivity_points'),
        this.translateService.instant('comp.ranking.total'),
    ]];
    let cont = 0;
    for (let i = 0; i < this.attendees.length; i++) {
      let row: any;
      const position = i + 1;
      row = this.instantiateAttendeeRow(this.attendees[i], position);
      this.attendeeExport.push(row);
      if (cont == this.attendees.length - 1) {
        const wscols: XLSX.ColInfo[] = [
          { wpx: 50 }, // "pixels"
          { wpx: 200 }, // "pixels"
          { wpx: 150 }, // "pixels"
          { wpx: 150 }, // "pixels"
          { wpx: 100 }, // "pixels"
          // { hidden: false } // hide column
        ];

        /* At 96 PPI, 1 pt = 1 px */
        const wsrows: XLSX.RowInfo[] = [
          { hpx: 25 }, // "pixels"
        ];

        /* generate worksheet */
        const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.attendeeExport);

        /* TEST: column props */
        ws['!cols'] = wscols;

        /* TEST: row props */
        ws['!rows'] = wsrows;

        /* generate workbook and add the worksheet */
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

        /* save to file */
        const wbout: string = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
        saveAs(new Blob([this.s2ab(wbout)]), 'ranking.xlsx');
      }
      cont++;
    }
}
// AJUDA A GERAR O ARQUIVO EXECL
private s2ab(s: string): ArrayBuffer {
    const buf: ArrayBuffer = new ArrayBuffer(s.length);
    const view: Uint8Array = new Uint8Array(buf);
    for (let i = 0; i !== s.length; ++i) {
      view[i] = s.charCodeAt(i) & 0xFF;
    }
    return buf;
}

instantiateAttendeeRow(attendee: any, position: number) {
    const row = [];
    let positionString = position.toString();
    row.push(positionString);
    row.push(attendee['name']);
    row.push(this.getAttendeeEngagementPoints(attendee));
    row.push(attendee['points']);
    row.push(row[2] + row[3]);

    return row;
}

  // get the language of the user.
  getUserLanguage() {
    this.global.getLanguage((language) => {
      this.userLanguage = language
    })
  }
}
