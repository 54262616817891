import { Component, OnInit, ViewChild } from "@angular/core";
import { ModuleSurvey } from "src/app/models/modules/module-survey";
import { DbSurveyProvider } from "src/app/providers/database/db-survey";
import { ActivatedRoute, Router } from "@angular/router";
import { Location } from "@angular/common";
import { DbSessionFeedbackProvider } from "src/app/providers/database/db-session-feedback";
import { DbAskQuestionProvider } from "src/app/providers/database/db-ask-question";
import { DbWordCloudProvider } from "src/app/providers/database/db-word-cloud";
import { TypeModule } from "src/app/enums/type-module";
import { DbQuizProvider } from "src/app/providers/database/db-quiz";
import { DbTrainingProvider } from "src/app/providers/database/db-training";
import { GlobalService } from "src/app/providers/global/global.service";
import { DbInteractivityProvider } from "src/app/providers/database/db-interactivity";
import { SwalComponent } from "@sweetalert2/ngx-sweetalert2";
import { DragulaService } from "ng2-dragula";
import { DragulaOptions } from "dragula";
import { DbPrizeDrawProvider } from "src/app/providers/database/db-prize-draw";

declare var $: any;
@Component({
    selector: "app-interactivity",
    templateUrl: "./interactivity.component.html",
    styleUrls: ["./interactivity.component.scss"],
})
export class InteractivityComponent implements OnInit {
    @ViewChild("removeColorSwal") public removeColorSwal: SwalComponent;
    @ViewChild("successSwal") public successSwal: SwalComponent;
    @ViewChild("errorSwal") public errorSwal: SwalComponent;

    dragulaOptions: DragulaOptions = {
        moves: () => true,
    };

    hex;
    onReorderShow: boolean = false;
    loaderOrder: boolean = false;

    activeRoute: number = null;
    eventId: string = null;
    moduleId: string = null;
    modules: Array<any> = [];
    loader: boolean = true;
    routerChange;
    columnColor: string = "#2973A2";
    listColorsChart: Array<any> = [];

    showModerate = false;

    removeColorId = null;
    removeColorIndex = null;

    imgQuiz: File;
    imgSurvey: File;
    imgAskQuestion: File;

    imgQuizName: string = "";
    imgSurveyName: string = "";
    imgAskQuestionName: string = "";

    closeModal = false;

    // get the language of the user.
    public userLanguage: string;

    constructor(
        private dbInteractivity: DbInteractivityProvider,
        private dbSurvey: DbSurveyProvider,
        private dbFeedback: DbSessionFeedbackProvider,
        private dbQuiz: DbQuizProvider,
        private dbPrizeDraw: DbPrizeDrawProvider,
        private dbTraining: DbTrainingProvider,
        private dbAskQuestion: DbAskQuestionProvider,
        private dbWordCloud: DbWordCloudProvider,
        private route: ActivatedRoute,
        private router: Router,
        private location: Location,
        private global: GlobalService,
        private dragula: DragulaService
    ) {
        this.eventId = this.route.parent.params["_value"]["uid"];
        dragula.createGroup("bag-color", this.dragulaOptions);
    }

    ngOnInit() {
        // start dragula reorder bag
        this.dragula.dropModel("bag-color").subscribe((value: any) => {
            this.onReorder(value);
        });

        this.dbInteractivity.getInteractivityModuleByEventId(
            this.eventId,
            (module) => {
                this.moduleId = module.uid;
                this.start();
                this.getListColorsChart();
            }
        );
    }

    uploadImgQuiz($event) {
        this.imgQuiz = $event.srcElement.files[0];
        this.imgQuizName = this.imgQuiz.name;
    }

    uploadImgSurvey($event) {
        this.imgSurvey = $event.srcElement.files[0];
        this.imgSurveyName = this.imgSurvey.name;
    }

    uploadImgFeedback($event) {
        this.imgAskQuestion = $event.srcElement.files[0];
        this.imgAskQuestionName = this.imgAskQuestion.name;
    }

    saveImg() {
        this.loaderOrder = true;

        this.dbInteractivity.newBanner(
            this.imgQuiz,
            this.imgAskQuestion,
            this.imgSurvey,
            this.eventId,
            this.moduleId,
            (res) => {
                if (res) {
                    this.successSwal.fire();
                    this.loaderOrder = false;
                    $("#settings").modal("hide");
                } else {
                    this.errorSwal.fire();
                    this.loaderOrder = false;
                }
            }
        );

        this.imgQuizName = "";
        this.imgQuiz = null;

        this.imgAskQuestionName = "";
        this.imgAskQuestion = null;

        this.imgSurveyName = "";
        this.imgSurvey = null;
    }

    // ngDoCheck() {
    //   if (this.routerChange !== this.location.path()) {
    //     if (!(this.location.path().includes('create') || this.location.path().includes('edit'))) {
    //       this.routerChange = this.location.path();
    //       this.start();
    //     }
    //   }
    // }

    // update order of modules
    onReorder(order: any): void {
        this.onReorderShow = true;
        this.listColorsChart = order.targetModel;
        for (let i = 0; i < this.listColorsChart.length; ++i) {
            this.listColorsChart[i].order = i;
        }
    }

    saveNewOrder() {
        this.loaderOrder = true;

        this.dbInteractivity.changeOrderColors(
            this.moduleId,
            this.listColorsChart,
            (result) => {
                if (result) {
                    this.successSwal.fire();
                } else {
                    this.errorSwal.fire();
                }

                this.loaderOrder = false;
                this.onReorderShow = false;
            }
        );
    }

    changeActiveRoute(typeModule: number) {
        this.activeRoute = typeModule;
    }

    start() {
        this.getUserLanguage();

        Promise.all([
            this.getSurvey(),
            this.getSessionFeedback(),
            this.getQuiz(),
            //this.getTraining(),
            this.getAskQuestion(),
            this.getWordCloud(),
            this.getPrizeDraw(),
        ]).then((response) => {
            this.modules = [];
            if (response.length >= 1) {
                for (let i = 0; i < response.length; i++) {
                    if (
                        response[i][0] !== undefined &&
                        response[i][0] !== null
                    ) {
                        this.modules.push(response[i][0]);
                    }

                    if (i == response.length - 1) {
                        if (
                            this.modules[0] !== undefined &&
                            this.modules[0] !== null
                        ) {
                            // redirect to first module
                            this.activeRoute = this.modules[0].type;
                            this.router.navigate([
                                `${this.modules[0].viewBackOffice}`,
                            ]);
                            break;
                        }
                    }
                }
            }
            this.loader = false;
            console.log(this.modules )
        });
    }

    // get the language of the user.
    getUserLanguage() {
        this.global.getLanguage((language) => {
            this.userLanguage = language;
        });
    }

    getSurvey() {
        return new Promise((resolve) => {
            this.dbSurvey.getSurveyModule(this.eventId, (module) => {
                resolve(module);
            });
        });
    }

    getSessionFeedback() {
        return new Promise((resolve) => {
            this.dbFeedback.getFeedbackModule(this.eventId, (module) => {
                resolve(module);
            });
        });
    }

    getQuiz() {
        return new Promise((resolve) => {
            this.dbQuiz.getQuizModule(this.eventId, (module) => {
                resolve(module);
            });
        });
    }

    getPrizeDraw() {
        return new Promise((resolve) => {
            this.dbPrizeDraw.getPrizeDrawModule(this.eventId, (module) => {
                resolve(module);
            });
        });
    }

    getTraining() {
        return new Promise((resolve) => {
            this.dbTraining.getTrainingModule(this.eventId, (module) => {
                resolve(module);
            });
        });
    }

    getAskQuestion() {
        return new Promise((resolve, reject) => {
            this.dbAskQuestion.getAskQuestionModule(this.eventId, (module) => {
                resolve(module);
            });
        });
    }

    getWordCloud() {
        return new Promise((resolve, reject) => {
            this.dbWordCloud.getWordCloudModule(this.eventId, (module) => {
                resolve(module);
            });
        });
    }

    getListColorsChart() {
        this.dbInteractivity.getListColorsCharts(this.moduleId, (colors) => {
            if (colors !== undefined) {
                this.listColorsChart = colors;
            }
        });
    }

    addNewColorCharts() {
        this.dbInteractivity.addNewColorCharts(
            this.moduleId,
            this.columnColor,
            (color) => {
                this.listColorsChart.push(color);
            }
        );
    }

    getRemoveColor(colorId, index) {
        this.removeColorId = colorId;
        this.removeColorIndex = index;
        this.removeColorSwal.fire();
    }

    removeColor() {
        this.dbInteractivity.removeColorChart(
            this.moduleId,
            this.removeColorId,
            (data) => {
                if (data) {
                    this.listColorsChart.splice(this.removeColorIndex, 1);
                }
            }
        );
    }

    ngOnDestroy() {
        // remove dragula reorder bag case exit component
        this.dragula.destroy("bag-color");
    }
}
