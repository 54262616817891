import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from '../providers/auth/auth.service';
// import { AppStrings } from '../providers/app-strings/app-strings.service';
// import { DaoService } from '../providers/dao/dao.service';
import { TypeUser } from '../enums/typeUser';
import { PathRoutes } from '../paths/path-routers';
import { GlobalService } from '../providers/global/global.service';
import { environment } from '../../environments/environment';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})

export class LoginComponent implements OnInit {
    loginLogo: string = environment.platform.loginLogo;
    license: string = environment.platform.license;
    loginFailed: boolean;
    noAccessFail: boolean;
    loading: boolean;
    correntUserType: number;
    incorretEmail: boolean = false;
    email: string = null;
    loader: boolean = false;
    loaderModal: boolean = false;
    recoveryPassError: boolean = false;
    accNotExist: boolean = false;
    recoveryPassSuccess: boolean = false;

    constructor(
        private auth: AuthService,
        private router: Router,
        private global: GlobalService
    ) {
        this.loginFailed = false;
        this.noAccessFail = false;
        this.loading = true;
        this.correntUserType = -1;
    }

    ngOnInit() {
        this.auth.isAuthenticated().subscribe((state) => {
            if (state) {
                this.auth.claimsUser((claims) => {
                    if (typeof claims.type !== 'undefined') {
                        this.correntUserType = claims.type;
                        this.global.loadService((_) => { });
                    }

                    if (this.correntUserType === TypeUser.SUPERGOD || this.correntUserType === TypeUser.GOD) {
                        this.router.navigate([PathRoutes.ROUTER_DASHBOARD]);

                    } else if (this.correntUserType === TypeUser.CLIENT || this.correntUserType == TypeUser.EMPLOYEE) {
                        this.router.navigate([PathRoutes.ROUTER_CLIENT_DASHBOARD]);
                    } else {
                        this.noAccessFail = true;
                        this.auth.logoutUser();
                    }
                });
            } else {
                this.loading = false;
            }
        });
    }

    /**
    * Receives the login button click event and attempts user login.
    * @param {any} data User input taken from html form
    * @memberof LoginComponent
    */
    loginClick(data) {
        this.loader = true;
        this.loginFailed = false;

        this.auth.loginUser(data.email, data.password)
            .then((token) => {
                
            })
            .catch((notSucess: boolean) => {
                this.loginFailed = true;
                this.loader = false;
            });
    }

    resetPassword() {
        this.recoveryPassSuccess = false;
        this.loaderModal = false;
        this.accNotExist = false;
        this.recoveryPassError = false;
        this.loaderModal = true;
        this.auth.checkAuthEmail(this.email, (status) => {
            if (status[0] == 'password') {
                this.auth.recoveryPassword(this.email).then((success) => {
                    if (success == true) {
                        this.loaderModal = false;
                        this.recoveryPassSuccess = true;
                    }
                }).catch((err) => {
                    this.recoveryPassSuccess = false;
                    this.recoveryPassError = true;
                    this.loaderModal = false;
                });
            } else {
                this.recoveryPassSuccess = false;
                this.loaderModal = false;
                this.accNotExist = true;
            }
        });

    }

}
