import { Module } from '../modules/module';
import { TypeModule } from '../../enums/type-module';
import { NameModule } from '../name-module';

export class ModuleAskQuestion extends Module {
    typeVision: number;
    access_groups: any; // uid of the group that is allowed access to the module
    selected: boolean;
    visOnlyForLoggedUser?: boolean;
    imgAskQuestion: string;

    constructor(name: NameModule, icon: string, typeVision: number, eventId: string, order: number, visOnlyForLoggedUser?: boolean) {
        super(name, icon, TypeModule.ASK_QUESTION, eventId, order, false, false, false)
        this.typeVision = typeVision;
        this.access_groups = {};
        this.selected = false;
        this.imgAskQuestion = "";
        
        if (visOnlyForLoggedUser === undefined) {
            this.visOnlyForLoggedUser = false;
        } else {
            this.visOnlyForLoggedUser = visOnlyForLoggedUser;
        }
    }
}