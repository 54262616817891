import { Module } from "./module";
import { TypeModule } from '../../enums/type-module';
import { NameModule } from "../name-module";

export class ModuleRanking extends Module {
    selected: boolean;
    access_groups: any; // uid of the group that is allowed access to the module
    typeVision: number;
    visOnlyForLoggedUser?: boolean;

    constructor(name: NameModule, icon: string, typeVision, eventId: string, visOnlyForLoggedUser?: boolean) {
        super(name, icon, TypeModule.RANKING, eventId, null, null, true, true);
        this.selected = false;
        this.typeVision = typeVision;
        this.access_groups = {};
        if (visOnlyForLoggedUser === undefined) {
            this.visOnlyForLoggedUser = false;
        } else {
            this.visOnlyForLoggedUser = visOnlyForLoggedUser;
        } 
    }
}