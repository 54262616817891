import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { Checkin } from "src/app/models/checkin";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { PathApi } from "../../paths/path-api";
import firebase from 'firebase/app';
import { Group } from "../../models/group";
import { DbAttendeesProvider } from "./db-attendees";
import { take } from "rxjs/operators";

@Injectable({
    providedIn: "root",
})
export class DbCheckinProvider {
    public headers;
    public requestOptions;
    constructor(
        private aFirestore: AngularFirestore,
        private http: HttpClient,
        private dbAttendee: DbAttendeesProvider
    ) {
        this.headers = new Headers();
        this.headers.append("Accept", "application/json");
        this.headers.append("Content-Type", "application/json");
        this.requestOptions = { headers: this.headers };
    }

    getCheckinList(moduleId: string, onResolve) {
        let db = this.aFirestore.firestore;

        db.collection("modules")
            .doc(moduleId)
            .collection("checkins")
            .orderBy("order", "asc")
            .onSnapshot((values) => {
                let checkins = [];
                if (values.size >= 1) {
                    values.forEach((element) => {
                        checkins.push(element.data());
                    });
                }
                onResolve(checkins);
            });
    }

    getCheckin(moduleId: string, checkinId: string, onResolve) {
        let db = this.aFirestore.firestore;

        db.collection("modules")
            .doc(moduleId)
            .collection("checkins")
            .doc(checkinId)
            .get()
            .then((value) => {
                onResolve(value.data());
            });
    }

    getCheckinPromise(moduleId: string, checkinId: string) {
        return new Promise((resolve) => {
            let db = this.aFirestore.firestore;

            db.collection("modules")
                .doc(moduleId)
                .collection("checkins")
                .doc(checkinId)
                .get()
                .then((value) => {
                    resolve(value.data());
                });
        });
    }

    newCheckin(moduleId: string, eventId: string, checkin: Checkin, onResolve) {
        let body = {
            moduleId: moduleId,
            eventId: eventId,
            checkin: checkin,
        };

        this.http
            .post(
                PathApi.baseUrl + PathApi.dbCheckinCreate,
                body,
                this.requestOptions
            )
            .subscribe((status) => {
                onResolve(status["result"]);
            });
    }

    editCheckin(
        moduleId: string,
        checkinId: string,
        name: string,
        typeVisionListing,
        typeVisionViewer,
        groups,
        viewerGroups,
        onResolve
    ) {
        let db = this.aFirestore.firestore;
        let ref = db
            .collection("modules")
            .doc(moduleId)
            .collection("checkins")
            .doc(checkinId);

        ref.update({
            name: name,
            typeVisionListing: typeVisionListing,
            typeVisionViewer: typeVisionViewer,
            groups: groups,
            viewerGroups: viewerGroups
        })
            .then((status) => {
                onResolve(true);
            })
            .catch((err) => {
                onResolve(false);
            });
    }

    /**
     *Delete checkin. Activate the trigger:dbCheckinDelete
     *@param moduleId
     *@param checkinId
     *@returns onResolve
     */
    deleteCheckin(moduleId: string, checkinId: string, onResolve) {
        let db = this.aFirestore.firestore;
        let ref = db
            .collection("modules")
            .doc(moduleId)
            .collection("checkins")
            .doc(checkinId);

        ref.delete()
            .then((_) => {
                this.reorderCheckins(moduleId, (_) => {
                    onResolve(true);
                });
            })
            .catch((err) => {
                console.error(err);
                onResolve(false);
            });
    }

    /**
     *Delete checkins. Activate the trigger:dbCheckinDelete
     *@param moduleId
     *@param listCheckinsId
     *@returns onResolve
     */

    deleteCheckins(moduleId: string, listCheckinsId: Array<string>, onResolve) {
        let db = this.aFirestore.firestore;
        let batch = db.batch();

        for (const checkinId of listCheckinsId) {
            let ref = db
                .collection("modules")
                .doc(moduleId)
                .collection("checkins")
                .doc(checkinId);
            batch.delete(ref);
        }

        batch
            .commit()
            .then((_) => {
                this.reorderCheckins(moduleId, (_) => {
                    onResolve(true);
                });
            })
            .catch((err) => {
                console.error(err);
                onResolve(false);
            });
    }

    /**
     *Sorts the order field of module check ins.
     *@param moduleId
     *@returns onResolve
     */
    reorderCheckins(moduleId: string, onResolve) {
        let db = this.aFirestore.firestore;
        let ref = db
            .collection("modules")
            .doc(moduleId)
            .collection("checkins")
            .orderBy("order", "asc");

        ref.get().then((snapshot) => {
            let order = 0;

            snapshot.forEach((element) => {
                const checkin = element.data();
                const checkinId = checkin.uid;

                db.collection("modules")
                    .doc(moduleId)
                    .collection("checkins")
                    .doc(checkinId)
                    .update({ order });
                order++;
            });

            onResolve(true);
        });
    }

    changeVisibility(moduleId: string, uid: string, status: boolean) {
        let db = this.aFirestore.firestore;
        let ref = db
            .collection("modules")
            .doc(moduleId)
            .collection("checkins")
            .doc(uid);

        ref.update({ visibility: status });
    }

    changeUserCheckin(
        moduleId: string,
        checkinId: string,
        userId: string,
        attendeeModule: string,
        eventId: string,
        status: boolean,
        onResolve
    ) {
        let db = this.aFirestore.firestore;
        let ref = db
            .collection("modules")
            .doc(moduleId)
            .collection("checkins")
            .doc(checkinId)
            .collection("attendees")
            .doc(userId);

        ref.update({
            checkinStatus: status,
            time: Date.now(),
        })
            .then((status) => {
                onResolve(true);
            })
            .catch((err) => {
                onResolve(false);
            });
    }

    getAttendeesWithCheckin(
        eventId,
        moduleId,
        checkinId: string,
        order: string,
        typeVision: number,
        groups: any,
        onResolve
    ) {
        let body = {
            eventId: eventId,
            moduleId: moduleId,
            checkinId: checkinId,
            order: order,
            typeVision: typeVision,
            groups: groups,
        };

        this.http
            .post(
                PathApi.baseUrl + PathApi.dbCheckinAttendeeCheckinStatus,
                body,
                this.requestOptions
            )
            .subscribe((aux) => {
                onResolve(aux["result"]);
            });
    }

    getCheckinModule(moduleId: string, onResolve) {
        let db = this.aFirestore.firestore;

        db.collection("modules")
            .doc(moduleId)
            .onSnapshot((snapshot) => {
                onResolve(snapshot.data());
            });
    }

    allowGroupsToModule(moduleId: string, groups: Array<Group>) {
        let db = this.aFirestore.firestore;

        for (let group of groups) {
            db.collection("modules")
                .doc(moduleId)
                .update({
                    access_group: firebase.firestore.FieldValue.arrayUnion(
                        group.uid
                    ),
                });
        }
    }

    removeGroupsFromModule(moduleId: string, group: Group) {
        let db = this.aFirestore.firestore;
        db.collection("modules")
            .doc(moduleId)
            .update({
                access_group: firebase.firestore.FieldValue.arrayRemove(
                    group.uid
                ),
            });
    }

    exportCheckin(
        eventId: string,
        moduleId: string,
        checkinId: string,
        typeVision: number,
        groups: Array<any>,
        onResolve
    ) {
        let body = {
            eventId: eventId,
            moduleId: moduleId,
            checkinId: checkinId,
            typeVision: typeVision,
            groups: groups,
        };
        this.http
            .post(
                PathApi.baseUrl + PathApi.dbAttendeesExportCheckin,
                body,
                this.requestOptions
            )
            .subscribe((snapshot) => {
                onResolve(snapshot["result"]);
            });
    }

    reorderCheckinList(moduleId: string, checkinList: Array<any>) {
        return new Promise((resolve, reject) => {
            let db = this.aFirestore.firestore;
            let cont = 0;
            let size = checkinList.length;
            checkinList.forEach((el) => {
                let checkin = el;
                db.collection("modules")
                    .doc(moduleId)
                    .collection("checkins")
                    .doc(checkin.uid)
                    .update(checkin)
                    .then((_) => {
                        cont++;
                        if (cont == size) {
                            resolve(true);
                        }
                    })
                    .catch((error) => {
                        error++;
                        if (error == size) {
                            reject(false);
                        }
                    });
            });
        });
    }

    changeAttendeeOrderInCheckin(
        moduleId: string,
        checkinId: string,
        typeOrder: string
    ) {
        let db = this.aFirestore.firestore;
        return db
            .collection("modules")
            .doc(moduleId)
            .collection("checkins")
            .doc(checkinId)
            .update({ typeOrder: typeOrder });
    }

    editCheckinConfigs(
        moduleId: string,
        checkinId: string,
        checkin,
        onResolve
    ) {
        let db = this.aFirestore.firestore;
        let obj = {
            title: checkin["title"],
            text: checkin["text"],
            totalBgColor: checkin["totalBgColor"],
            totalTxtColor: checkin["totalTxtColor"],
            totalPresentBgColor: checkin["totalPresentBgColor"],
            totalPresentTxtColor: checkin["totalPresentTxtColor"],
            allowFooter: checkin["allowFooter"],
            allowUsername: checkin["allowUsername"],
        };

        db.collection("modules")
            .doc(moduleId)
            .collection("checkins")
            .doc(checkinId)
            .update(obj)
            .then((_) => {
                onResolve(true);
            })
            .catch((e) => {
                onResolve(false);
            });
    }

    getLastCheckins(moduleId: string, checkinId: string, onResolve) {
        let db = this.aFirestore.firestore;
        db.collection("modules")
            .doc(moduleId)
            .collection("checkins")
            .doc(checkinId)
            .collection("attendees")
            .where("status", "==", true)
            .orderBy("time", "desc")
            .onSnapshot((values) => {
                let users = [];
                if (values.size >= 1) {
                    values.forEach((element) => {
                        let checkin = element.data();
                        this.dbAttendee.getAttendee(
                            checkin.eventId,
                            checkin.attendeeModule,
                            checkin.uid,
                            (data) => {
                                data["result"].checkinTime = checkin.time;
                                users.push(data["result"]);
                                onResolve(users);
                            }
                        );
                    });
                } else {
                    onResolve(users);
                }
            });
    }

    getEventTotalAttendeesNumber(eventId: string, onResolve) {
        let db = this.aFirestore.firestore;
        db.collection("events")
            .doc(eventId)
            .collection("attendees")
            .onSnapshot((values) => {
                onResolve(values.size);
            });
    }

    /**
     * @description get all checkIns data (i.e. firebase firestore DocumentData) and the data related to 
     * the attendees listed in those check ins. The content of the response is available by accessing the 
     * properties (that are Objects too) ".attendees" and ".checkins" of the returned Object in the promise
     * @param moduleId 
     */
    public getCheckinStatuses(moduleId: string): Promise<any> {
        const body = { moduleId: moduleId }; 
        const post$ = this.http.post(
            PathApi.baseUrl + PathApi.dbCheckinStatuses,
            body,
            this.requestOptions
        ).pipe(take(1));

        return new Promise((response, reject) => {
            post$.subscribe((data) => {
                if (data['code'] != 200) {
                    reject(data['message'])
                }
                else {
                    response(data);
                }
            })
        })
    }
}
