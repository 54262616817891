import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { PathApi } from '../../paths/path-api';
import { Event } from '../../models/event';
import firebase from 'firebase/app';
import { PlataformRoutersService } from '../plataform-routes/plataform-routers.service';
import { TypeModule } from 'src/app/enums/type-module';
import { AttendeeFields } from 'src/app/models/attendee-fields';
import { EventColors } from 'src/app/models/event-colors';
import { StorageService } from '../storage/storage.service';
import { PageInfobooth } from 'src/app/models/page-infobooth';
import { Group } from 'src/app/models/group';
import { NameGroup } from 'src/app/enums/name-group';
import { DbGroupsProvider } from './db-groups';
import { ModuleGroup } from 'src/app/models/modules/module-groups';
import { FirebaseApp } from '@angular/fire';
import { HotsiteProps } from 'src/app/client-dashboard/client-event-dashboard/client-hotsite/types/hotsite';
import { FieldProps } from 'src/app/client-dashboard/client-event-dashboard/client-engagement/types/engagement';



@Injectable({
    providedIn: 'root'
})

export class DbEventsProvider {
    private db: firebase.firestore.Firestore

    public headers;
    public requestOptions;

    constructor(
        private http: HttpClient,
        private plataformRouters: PlataformRoutersService,
        private storage: StorageService,
        private dbGroup: DbGroupsProvider
    ) {
        this.headers = new HttpHeaders();
        this.headers.append("Accept", 'application/json');
        this.headers.append("Content-Type", 'application/json');
        this.requestOptions = { headers: this.headers };

        this.db = firebase.firestore()
    }

    createEvent(event: Event, modules: Array<any>, onResolve) {
        // convert typescript objects to jsons
        event = Object.assign({}, event)
        event.availableModules = Object.assign({}, event.availableModules)
        event.languages = Object.assign({}, event.languages)
        event.description = Object.assign({}, event.description)
        event.colors = Object.assign({}, event.colors)
        event.eventFields = Object.assign({}, event.eventFields)

        // convert typescript modules to jsons
        for (const index in modules) {
            modules[index] = Object.assign({}, modules[index])
        }

        // convert typescript client object to jsons
        if (event.client !== null) {
            event.client = Object.assign({}, event.client)
        }

        // start batch
        let batch = this.db.batch();

        // create document event
        let refEvent = this.db.collection("events").doc();
        event.uid = refEvent.id;
        event.homePage = `/event/${refEvent.id}/info`;
        batch.set(refEvent, event)

        // create documents modules
        for (const module of modules) {
            // Converts module names to jsons
            module.name = Object.assign({}, module.name)
            module.eventId = event.uid; // get eventId

            let refModule = this.db.collection("modules").doc();
            module.uid = refModule.id//get moduleId


            // get the backoffice and app routes.
            if (module.type == TypeModule.INFOBOOTH) {
                const page = Object.assign({}, new PageInfobooth())
                page.uid = this.db.collection('modules').doc(module.uid).collection('pages').doc().id
                page.moduleId = module.uid
                page.eventId = module.eventId
                page.title = Object.assign({}, page.title);
                page.htmlContent = Object.assign({}, page.htmlContent);

                // router with page
                const url = this.plataformRouters.relatesUrls(module.type, module.uid, page.uid, module.eventId)
                if (url && url.backoffice) {
                    module.viewBackOffice = url.backoffice;
                }
                if (url && url.app) {
                    module.viewApp = url.app;
                }

                const ref1 = this.db.collection('events').doc(page.eventId).collection('modules').doc(page.moduleId).collection('pages').doc(page.uid)
                const ref2 = this.db.collection('modules').doc(page.moduleId).collection('pages').doc(page.uid)

                batch.set(ref1, page)
                batch.set(ref2, page)
            } else {
                const url = this.plataformRouters.relatesUrls(module.type, module.uid, null, module.eventId)
                if (url && url.backoffice) {
                    module.viewBackOffice = url.backoffice;
                }
                if (url && url.app) {
                    module.viewApp = url.app;
                }
            }


            // Checks if the module type is the same as the participants or speakers. If so converts the fields field to json.
            if (module.type == TypeModule.ATTENDEE || module.type == TypeModule.SPEAKER) {
                module.fields = Object.assign({}, new AttendeeFields());
            }


            let refEvent = this.db.collection("events").doc(event.uid).collection("modules").doc(module.uid)

            // update path module and path event
            batch.set(refModule, module)
            batch.set(refEvent, module)
        }

        // Commit the batch
        batch.commit().then((batchOk) => {
            onResolve(true);
        }).catch((error) => {
            onResolve(error);
        })
    }



    getEventsB3App(typeOrder, onResolve) {
        let ref;

        switch (typeOrder) {
            case 'asc': //a-z
                ref = this.db
                    .collection('events')
                    .where('type', '==', 1)
                    .orderBy('title', 'asc');
                break;

            case 'desc': //z-a
                ref = this.db
                    .collection('events')
                    .where('type', '==', 1)
                    .orderBy('title', 'desc');
                break;

            case 'distant'://distante-próximo
                ref = this.db
                    .collection('events')
                    .where('type', '==', 1)
                    .orderBy('startDate', 'desc');
                break;

            case 'nearest': //próximo-distante
                ref = this.db
                    .collection('events')
                    .where('type', '==', 1)
                    .orderBy('startDate', 'asc');
                break;
        }

        ref
            .onSnapshot((events) => {
                const list = [];

                events.forEach(
                    (doc) => {
                        list.push(doc.data());
                    }
                )

                onResolve(list);
            })
    }


    async updateEvent(event: Event, onResolve) {
        const batch = firebase.firestore().batch();
        const eventId = event.uid;

        // update event
        const ref = this.db.collection('events').doc(eventId);

        batch.update(ref, event);

        batch
            .commit().then(() => {
                onResolve(true);
            })
            .catch((error) => {
                onResolve(error);
            })
    }
    async updateEventWithImgs(event: Event, authLogo: any, desktopBg: any, mobileBg: any, onResolve) {
        const batch = firebase.firestore().batch();
        const eventId = event.uid;

        const ref = this.db.collection('events').doc(eventId);

        // batch.update(ref, event);

        // batch
        //     .commit().then(() => {
        //         onResolve(true);
        //     })
        //     .catch((error) => {
        //         onResolve(error);
        //     })
        const authLogoID = `${event.uid}_authLogo`;
        const desktopBgImgID = `${event.uid}_desktopBG`;
        const mobileBgImgID = `${event.uid}_mobileBG`;
        if (authLogo !== null && authLogo !== undefined) {
            const url = await this.storage.eventBgImg(authLogo,event.uid,authLogoID)
            // const url = await this.storage.containerLogo(authLogo, authLogoID)
            event.authLogo = url;
        }
        if (desktopBg !== null && desktopBg !== undefined) {
            // const url = await this.storage.containerLogo(desktopBg, desktopBgImgID)
            const url = await this.storage.eventBgImg(desktopBg,event.uid,desktopBgImgID)
            event.desktopBg = url;
        }
        if (mobileBg !== null && mobileBg !== undefined) {
            // const url = await this.storage.containerLogo(mobileBg, mobileBgImgID)
            const url = await this.storage.eventBgImg(mobileBg,event.uid,mobileBgImgID)
            event.mobileBg = url;
        }

        event = Object.assign({}, event);

        batch.update(ref, event);

        batch
            .commit().then(() => {
                onResolve(true);
            })
            .catch((error) => {
                onResolve(error);
            })
    }

    async removeImagesEventPublic(eventId: string, event: Event, imgType: string, onResolve){
        const authLogoID = `${event.uid}_authLogo`;
        const desktopBgImgID = `${event.uid}_desktopBG`;
        const mobileBgImgID = `${event.uid}_mobileBG`;
        let containerImgID = ''
        const ref = this.db.collection('events').doc(eventId);

        if(imgType === 'auth') {
            event.authLogo = '';
            containerImgID = authLogoID;
        } else if(imgType === 'desktop'){
            event.desktopBg = '';
            containerImgID = desktopBgImgID;
        } else if(imgType === 'mobile') {
            event.mobileBg = '';
            containerImgID = mobileBgImgID;
        }
        event = Object.assign({}, event);
        ref.update(event)
            .then(_ => {
                this.storage.deleteEventPublicBgImages(event.uid, containerImgID)
                .then(value => {
                    onResolve(true)
                    console.log(value)
                })
                // onResolve(true)
            })
            .catch((e) => {
                console.error(e);
                onResolve(false);
        });
    }

    async updateEventModules(eventId: string, modules, onResolve) {
        const batch = firebase.firestore().batch();

        let cont = 0;
        let size = modules.length - 1;
        for (let module of modules) {
            if (module.visOnlyForLoggedUser === undefined) {
                module.visOnlyForLoggedUser = false;
                console.log('Visibility for user of module ', module.name['FrFR'], ' : ', module.visOnlyForLoggedUser);
            }
            let refEvent;
            let refModule;
            let pageId = null;
            if (module.uid !== null && module.uid !== undefined && module.uid !== '') {
                refEvent = this.db.collection("events").doc(eventId).collection("modules").doc(module.uid);
                refModule = this.db.collection("modules").doc(module.uid);
                if (module.type === TypeModule.INFOBOOTH) {
                    let page = await this.db.collection("events").doc(eventId).collection("modules").doc(module.uid).collection('pages').where("eventId", "==", eventId).where("moduleId", "==", module.uid).get();
                    if (!page.empty) {
                        pageId = page.docs[0].id;
                    }
                }
                // get the backoffice and app routes.
                const url = this.plataformRouters.relatesUrls(module.type, module.uid, pageId, module.eventId);
                if (url && url.backoffice) {
                    module.viewBackOffice = url.backoffice;
                }
                if (url && url.app) {
                    module.viewApp = url.app;
                }
                module.name = Object.assign({}, module.name);
                module = Object.assign({}, module);
                batch.update(refEvent, module);
                batch.update(refModule, module);
            } else {
                refEvent = this.db.collection("events").doc(eventId).collection("modules").doc();
                module.uid = refEvent.id;
                refModule = this.db.collection("modules").doc(refEvent.id);
                if (module.type === TypeModule.INFOBOOTH) {
                    let page = await this.db.collection("events").doc(eventId).collection("modules").doc(module.uid).collection('pages').where("eventId", "==", eventId).where("moduleId", "==", module.uid).get();
                    if (!page.empty) {
                        pageId = page.docs[0].id;
                    }
                }
                // get the backoffice and app routes.
                const url = this.plataformRouters.relatesUrls(module.type, module.uid, pageId, module.eventId);
                if (url && url.backoffice) {
                    module.viewBackOffice = url.backoffice;
                }
                if (url && url.app) {
                    module.viewApp = url.app;
                }
                module.name = Object.assign({}, module.name);
                module = Object.assign({}, module);

                batch.set(refEvent, module);
                batch.set(refModule, module);
            }

            if (cont == size) {
                batch.commit()
                    .then((_) => {
                        onResolve(true);
                    })
                    .catch((e) => {
                        onResolve(false);
                    });
            }
            cont++;

        }
    }

    updateEventItems(eventId: string, items: any, onResolve) {
        let ref = this.db.collection("events").doc(eventId);

        ref
            .update(items)
            .then(() => { onResolve(true) })
            .catch((e) => { onResolve(false) });
    }

    updateEventTerms(eventId: string, term: any, onResolve) {
        let ref = this.db.collection("events").doc(eventId)

        ref.update(term)
            .then(() => {
                onResolve(true)
            })
            .catch((error) => {
                onResolve(error)
            })
    }

    updateEventCustomField(eventId: string, field: any, onResolve) {
        let ref = this.db.collection("events").doc(eventId)

        ref.update(field)
            .then(() => {
                onResolve(true)
            })
            .catch((error) => {
                onResolve(error)
            })
    }

    getEventsClient(typeOrder, onResolve) {
        let ref;

        switch (typeOrder) {
            case 'asc': //a-z
                ref = this.db
                    .collection('events')
                    .where('type', '==', 0)
                    .orderBy('title', 'asc');
                break;

            case 'desc': //z-a
                ref = this.db
                    .collection('events')
                    .where('type', '==', 0)
                    .orderBy('title', 'desc');
                break;

            case 'distant'://distante-próximo
                ref = this.db
                    .collection('events')
                    .where('type', '==', 0)
                    .orderBy('startDate', 'desc');
                break;

            case 'nearest': //próximo-distante
                ref = this.db
                    .collection('events')
                    .where('type', '==', 0)
                    .orderBy('startDate', 'asc');
                break;
        }

        ref
            .onSnapshot((events) => {
                const list = [];

                events.forEach(
                    (doc) => {
                        list.push(doc.data());
                    }
                )

                onResolve(list);
            })
    }

    getEventsByClient(clientId: string, onResolve) {
        this.db
            .collection('events')
            .where('client.uid', '==', clientId)
            .where('visibility', '==', true)
            .get()
            .then((snapshot) => {
                let events = [];
                if (snapshot.size >= 1) {
                    snapshot.forEach((element) => {
                        events.push(element.data());
                    })
                }
                onResolve(events);
            })
            .catch((e) => console.error(e));
    }

    getEvent(eventId: string, onResolve) {
        let ref = this.db.collection("events").doc(eventId);

        ref.onSnapshot((element) => {
            const event = element.data()
            onResolve(event)
        }, err => {
            console.log(err)
        });
    }

    getModulesEvent(eventId, onResolve) {
        let ref = this.db.collection("events").doc(eventId).collection('modules').where('habilitedBackOffice', '==', true).where('order', '>=', 0).orderBy('order')
        ref.onSnapshot((snapshot) => {
            const list = []
            snapshot.forEach((document) => {
                const module = document.data()
                list.push(module)
            })

            onResolve(list)
        })

    }

    getModulesSchedule(eventId, onResolve) {
        let ref = this.db.collection('events').doc(eventId).collection('modules').where('type', '==', TypeModule.SCHEDULE);
        ref.onSnapshot((snapshot) => {
            let listModules = [];
            snapshot.forEach(element => {
                const module = element.data();
                listModules.push(module);
            });

            onResolve(listModules)
        });
    }


    async getModulesToSendNotifs(eventId)  {
        let refSchedule = this.db.collection('events').doc(eventId).collection('modules')
            .where('type', '==', TypeModule.SCHEDULE).get();
        let refPersonnalSchedule = this.db.collection('events').doc(eventId).collection('modules')
            .where('type', '==', TypeModule.PERSONALSCHEDULE).get();
        let refAppointments= this.db.collection('events').doc(eventId).collection('modules')
            .where('type', '==', TypeModule.APPOINTMENTS).get();
        const [schedule, personnalSchedule, appointments] = await Promise.all([refSchedule, refPersonnalSchedule, refAppointments]);

        return [...schedule.docs].map(_ => _.data()).concat([...personnalSchedule.docs].map(_ => _.data())).concat([...appointments.docs].map(_ => _.data()))
    }

    getModuleLocation(eventId: string, onResolve) {
        let ref = this.db.collection('events').doc(eventId).collection('modules').where('type', '==', TypeModule.LOCATION)

        ref
            .get()
            .then((snapshot) => {
                let module = null

                snapshot.forEach((childSnapshot) => {
                    module = childSnapshot.data()
                })

                onResolve(module)
            })
    }

    getModuleManagerGroup(eventId: string, onResolve) {
        let ref = this.db.collection('events').doc(eventId).collection('modules').where('type', '==', TypeModule.MANAGER_GROUP)

        ref
            .get()
            .then((snapshot) => {
                let module = null

                snapshot.forEach((childSnapshot) => {
                    module = childSnapshot.data()
                })

                onResolve(module)
            })
    }

    getModuleManagerModules(eventId: string, onResolve) {
        let ref = this.db.collection('events').doc(eventId).collection('modules').where('type', '==', TypeModule.MANAGER_MODULES)

        ref
            .get()
            .then((snapshot) => {
                let module = null

                snapshot.forEach((childSnapshot) => {
                    module = childSnapshot.data()
                })

                onResolve(module)
            })
    }

    getModuleNotification(eventId: string, onResolve) {
        let ref = this.db.collection('events').doc(eventId).collection('modules').where('type', '==', TypeModule.NOTIFY)

        ref
            .get()
            .then((snapshot) => {
                let module = null

                snapshot.forEach((childSnapshot) => {
                    module = childSnapshot.data()
                })

                onResolve(module)
            })
    }

    getModuleInteractivity(eventId: string, onResolve) {
        let ref = this.db.collection('events').doc(eventId).collection('modules').where('type', '==', TypeModule.INTERACTIVITY)

        ref
            .get()
            .then((snapshot) => {
                let module = null

                snapshot.forEach((childSnapshot) => {
                    module = childSnapshot.data()
                })

                onResolve(module)
            })
    }

    changeInteractivityAnswerConfig(eventId: string, moduleId: string, status: boolean, onResolve) {
        let refEvent = this.db.collection('events').doc(eventId).collection('modules').doc(moduleId)
        let refModule = this.db.collection('modules').doc(moduleId)
        let batch = this.db.batch()

        batch.update(refEvent, { answerOffline: status })
        batch.update(refModule, { answerOffline: status })

        batch.commit()
            .then(() => {
                onResolve(true)
            })
            .catch((e) => {
                console.log(e)
                onResolve(false)
            })
    }

    getTimezone(eventId: string, onResolve) {
        this.db.collection('events').doc(eventId)
            .get()
            .then((snapshot) => {
                onResolve(snapshot.data().timezone)
            })
    }

    // removes event in the firestore database and activates the api: dbEventDelete trigger to remove event data.
    deleteEvent(eventId: string, onResolve) {
        this.db.collection('events').doc(eventId).delete()
        onResolve(true)
    }

    // get the linguage principal of the event.
    getLanguagePrincipalEvent(eventId: string, onResolve) {
        this.db.collection('events')
            .doc(eventId)
            .get()
            .then((snapshot) => {
                const language = snapshot.data().language
                onResolve(language)
            })
    }

    // get the languages of the event.
    getLanguagesEvent(eventId: string, onResolve) {
        this.db.collection('events')
            .doc(eventId)
            .get()
            .then((snapshot) => {
                const languages = snapshot.data().languages
                onResolve(languages)
            })
    }

    // get the available descriptions languages ​​at the event
    getEnabledDescriptionsLanguages(eventId, onResolve) {
        this.db.collection('events').doc(eventId)
            .get()
            .then((snapshot) => {
                const languages = []
                const event = snapshot.data()

                if (event !== null) {
                    const descriptions = event.description

                    if (descriptions.pt_BR !== null) {
                        languages.push('pt_BR')
                    }

                    if (descriptions.en_US !== null) {
                        languages.push('en_US')
                    }

                    if (descriptions.de_DE !== null) {
                        languages.push('de_DE')
                    }

                    if (descriptions.fr_FR !== null) {
                        languages.push('fr_FR')
                    }

                    if (descriptions.es_ES !== null) {
                        languages.push('es_ES')
                    }
                }

                onResolve(languages)
            })
    }

    checkSortCode(sortcode: string, onResolve) {
        this.db.collection('events').where('shortcode', '==', sortcode)
            .get()
            .then((snapshot) => {
                const size = snapshot.size
                onResolve(size)
            }).catch((error) => {
                onResolve(error)
            })
    }


    getClientEvent(clientId: string, typeOrder, onResolve) {
        let ref;

        switch (typeOrder) {
            case 'asc': //a-z
                ref = this.db
                    .collection('events')
                    .where('client.uid', '==', clientId)
                    .orderBy('title', 'asc');
                break;

            case 'desc': //z-a
                ref = this.db
                    .collection('events')
                    .where('client.uid', '==', clientId)
                    .orderBy('title', 'desc');
                break;

            case 'distant'://distante-próximo
                ref = this.db
                    .collection('events')
                    .where('client.uid', '==', clientId)
                    .orderBy('startDate', 'desc');
                break;

            case 'nearest': //próximo-distante
                ref = this.db
                    .collection('events')
                    .where('client.uid', '==', clientId)
                    .orderBy('startDate', 'asc');
                break;
        }

        ref.get()
            .then((events) => {
                const list = [];

                events.forEach(
                    (doc) => {
                        list.push(doc.data());
                    }
                )

                onResolve(list);

            })
    }

    changeRequiredProfileEdit(eventId: string, status: boolean) {
        this.db.collection('events')
            .doc(eventId)
            .update({ required_edit_profile: status })
            .then((_) => {
                return true;
            })
            .catch((e) => {
                return false;
            });
    }

    updateEventColors(eventId: string, eventColors: EventColors, onResolve) {
        this.db.collection('events')
            .doc(eventId)
            .update({
                colors: {
                    menu_color: eventColors.menu_color,
                    menu_text_color: eventColors.menu_text_color,
                    menu_text_color2: eventColors.menu_text_color2,
                    //active_menu_item: eventColors.active_menu_item,
                    title_color: eventColors.title_color,
                    text_content_color: eventColors.text_content_color,
                    link_color: eventColors.link_color,
                    btn_text_color: eventColors.btn_text_color,
                    bg_content_color: eventColors.bg_content_color,
                    bg_general_color: eventColors.bg_general_color,
                    img_bg: eventColors.img_bg
                },
                //font: font,
                //globalMsg: msg
            })
            .then((_) => {
                onResolve(true);
            })
            .catch((e) => {
                onResolve(false);
            });
    }

    updateEventLogo(eventId: string, oldId: string, url: string, logoId: string, onResolve) {
        let ref = this.db.collection('events').doc(eventId);
        ref.update({
            logo: {
                url: url,
                uid: logoId
            }
        })
            .then((ok) => {
                if (oldId !== undefined && oldId !== null && oldId !== '') this.storage.deleteLogo(eventId, oldId);
                onResolve(true);
            })
            .catch((e) => {
                onResolve(false);
            });
    }

    updateEventIcon(eventId: string, oldId: string, url: string, iconId: string, onResolve) {
        let obj = {
            touchIcon: {
                url: url,
                uid: iconId
            }
        }
        this.db.collection('events')
            .doc(eventId)
            .update({ webApp: obj })
            .then((_) => {
                if (oldId !== undefined && oldId !== null && oldId !== '') { this.storage.deleteTouchIcon(eventId, oldId); }
                onResolve(true);
            })
            .catch((__) => {
                onResolve(false);
            });
    }

    updateEventBanner(eventId: string, oldId: string, url: string, bannerId: string, onResolve) {
        let ref = this.db.collection('events').doc(eventId);
        ref.update({
            banner: {
                url: url,
                uid: bannerId
            }
        })
            .then((ok) => {
                if (oldId !== undefined && oldId !== null && oldId !== '') this.storage.deleteBanner(eventId, oldId);
                onResolve(true);
            })
            .catch((e) => {
                onResolve(false);
            });
    }

    updateVisibiltyBanner(eventId: string, visibility: boolean, onResolve) {
        this.db.collection('events')
            .doc(eventId)
            .update({
                visibiltyBanner: visibility
            })
            .then((_) => {
                onResolve(true);
            })
            .catch((e) => {
                onResolve(false);
            });
    }

    //========================================================== group multi language ===========================================

    /**
     * get the MANAGER GROUP module of the event.
     * @param eventId module event uid
    */

    getModuleGroup(eventId: string): Promise<ModuleGroup> {
        return new Promise((resolve) => {
            this.db
                .collection('events')
                .doc(eventId)
                .collection('modules')
                .where('type', '==', TypeModule.MANAGER_GROUP)
                .get()
                .then((value) => {
                    value.forEach(element => {
                        resolve(element.data() as ModuleGroup);
                    });
                })
        })
    }


    /**
    * check if group exists by identifier
    *
    *  @param identifier group identifier
    *  @param eventId uid of the group event.

    */

    checkGroupExist(identifier: string, eventId: string): Promise<boolean> {
        return new Promise(async (resolve) => {
            const moduleGroup = await this.getModuleGroup(eventId)
            const moduleGroupId = moduleGroup.uid

            this.db.collection('modules')
                .doc(moduleGroupId)
                .collection('groups')
                .where('identifier', '==', identifier)
                .get()
                .then((snapshot) => {
                    resolve(snapshot.size > 0 ? true : false)
                })

        })
    }



    /**
    * create dynamic multi-language group.
    *
    *  @param language group language
    *  @param uid uid of the group module.
    *  @param eventId uid of the group event.
    *  @param batch firebase batch

    */

    createGroup(language: string, moduleGroupId: string, eventId: string, batch: any): Promise<boolean> {
        return new Promise((resolve) => {
            let group = new Group(language, moduleGroupId, eventId, language, '#F0FFFF', '#000000', true)

            let ref1 = this.db.collection('events').doc(eventId).collection('modules').doc(moduleGroupId).collection('groups').doc();
            let ref2 = this.db.collection('modules').doc(moduleGroupId).collection('groups').doc(ref1.id);
            group.uid = ref1.id;

            group = Object.assign({}, group);

            // add in batch
            batch.set(ref1, group);
            batch.set(ref2, group);

            resolve(true)
        })
    }



    /**
    * remove dynamic multi-language group.
    *
    *  @param language group language
    *  @param uid uid of the group module.
    *  @param eventId uid of the group event.
    *  @param batch firebase batch

    */
    removeGroup(language: string, moduleGroupId: string, eventId: string, batch: any): Promise<boolean> {
        return new Promise((resolve) => {
            this.db.collection('modules')
                .doc(moduleGroupId)
                .collection('groups')
                .where('identifier', '==', language)
                .get()
                .then((snapshot) => {
                    let group = null

                    if (snapshot.size > 0) {
                        snapshot.forEach((element) => {
                            group = element.data()
                            const groupId = group.uid

                            let ref1 = this.db.collection('modules').doc(moduleGroupId).collection('groups').doc(groupId);

                            // add in batch
                            batch.delete(ref1);
                        })
                    }

                    resolve(true)
                })
        })
    }

    updatePopupVisibility(eventId: string,property: string, value: boolean) : Promise<boolean>{
        // let db = this.aFirestore.firestore;
        return new Promise((resolve,reject) => {
            let ref = this.db.collection('events').doc(eventId)
            const obj = {}
            obj[property] = value
            ref.update(obj).then(v => resolve(true)).catch(e => resolve(false));
        })
    }

    async getAttendeesEvent(eventId: string){
        let refAttendees = this.db.collection('events').doc(eventId).collection('attendees');
        // let refSpeakers = this.db.collection('events').doc(eventId).collection('speakers');
        // refAttendees.get().then(snapshot => snapshot.docs.map(x => x.data()))
        const snapshots = await refAttendees.get();

        return [...snapshots.docs].map(x => x.data())
    }
    async getSpeakersEvent(eventId: string){
        let refSpeakers = this.db.collection('events').doc(eventId).collection('speakers');
        // refAttendees.get().then(snapshot => snapshot.docs.map(x => x.data()))
        const snapshots = await refSpeakers.get()

        // return [...snapshots].map(arr => arr.docs.map(x => x.data())).reduce((acc, curr) => acc.concat(curr), [])
        return [...snapshots.docs].map(x => x.data())
    }

    /**
     *  Receives new settings from the hotsite to update in the specific event
     *
     * @param eventId
     * @param hotsite
     */
    updateHotsiteSettings(eventId: string, hotsite: HotsiteProps): Promise<void> {
        return this.db
        .collection('events')
        .doc(eventId)
        .update({
            hotsite,
        })
    }

    updateEngagementFields(eventId: string, engagementFields: FieldProps[]): Promise<void> {
        const ref = this.db
            .collection('events')
            .doc(eventId);

        return ref.update({
            engagementFields,
        })
    }
}
