import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PathComponents } from './paths/path-components';
import { AuthGuardService } from './providers/auth-guard/auth.guard';

const routes: Routes = [
    { path: '', component: PathComponents.login },
    { path: 'dashboard', loadChildren: '../app/adm-dashboard/adm-dashboard.module#AdmDashboardModule', canActivate: [AuthGuardService] },
    { path: 'dash', loadChildren: '../app/client-dashboard/client-dashboard.module#ClientDashboardModule', canActivate: [AuthGuardService] },
    { path: 'event/:uid', loadChildren: '../app/client-dashboard/client-event-dashboard/client-event-dashboard.module#ClientEventDashboardModule', canActivate: [AuthGuardService] },
    { path: 'checkin-time/:moduleId/:checkinId', component: PathComponents.client_checkin_time },
    { path: 'moderate-ask-question-session/:eventId/:moduleId/:sessionId', component: PathComponents.client_ask_question_session_moderate },
    { path: 'result-ask-question-session/:eventId/:moduleId/:sessionId', component: PathComponents.client_ask_question_session_result },
    { path: 'moderate-ask-question/:eventId/:moduleId/:itemId', component: PathComponents.client_ask_question_moderate },
    { path: 'result-ask-question/:eventId/:moduleId/:itemId', component: PathComponents.client_ask_question_result },
    { path: 'result-quiz/:eventId/:moduleId/:quizId/:questionId', component: PathComponents.result_quiz },
    { path: 'result-prize-draw/:eventId/:moduleId/:resultId', component: PathComponents.result_prize_draw },
    { path: 'result-training/:eventId/:moduleId/:trainingId/:questionId', component: PathComponents.result_training },
    { path: 'word-cloud-result/:eventId/:moduleId/:cloudId', component: PathComponents.client_word_cloud_result },
    { path: 'realtime-ranking/:eventId', component: PathComponents.rt_ranking }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
        useHash: true, paramsInheritanceStrategy: 'always',
    })],
    exports: [RouterModule]
})

export class AppRoutingModule { }
