import { Component, OnInit } from '@angular/core';
import { DbLogsProvider } from 'src/app/providers/database/db-logs';

@Component({
  selector: 'app-logs',
  templateUrl: './logs.component.html',
  styleUrls: ['./logs.component.scss']
})
export class LogsComponent implements OnInit {
  logs: Array<any> = [];
  loader: boolean = true;
  public p: number = 1;
  constructor(private dbLogs: DbLogsProvider) {
    this.dbLogs.getAllLogs((logs) => {
      this.logs = logs;
      this.loader = false;
    });
  }

  ngOnInit() {
  }

}
