import { Module } from '../modules/module';
import { TypeModule } from '../../enums/type-module';
import { NameModule } from '../name-module';

export class ModuleWidget extends Module {
    widgets: Array<any>;
    typeVision: number;
    access_groups: any; //uid of the group that is allowed access to the module
    selected: boolean;
    moduleBackgroundTypeImage: boolean;
    moduleBackgroundIsDegrade: boolean;
    moduleBackgroundColor: string;
    moduleBackgroundColorSecondary: string;
    moduleBackgroundImage: any;
    moduleBackgroundImageDesktop: any;
    visOnlyForLoggedUser: boolean;

    constructor(name: NameModule, icon: string, eventId: string, typeVision: number, order: number, visOnlyForLoggedUser?: boolean) {
        super(name, icon, TypeModule.WIDGETS, eventId, order, true, true, true)
        this.typeVision = typeVision
        this.access_groups = {};
        this.selected = false;;
        this.moduleBackgroundTypeImage = false;
        this.moduleBackgroundIsDegrade = false;
        this.moduleBackgroundColor = '#F6F6F6';
        this.moduleBackgroundColorSecondary = '#F6F6F6';
        this.moduleBackgroundImage = '';
        this.moduleBackgroundImageDesktop = '';
        this.visOnlyForLoggedUser = visOnlyForLoggedUser;

        this.widgets = [];
    }

}