import { Module } from "./module";
import { TypeModule } from '../../enums/type-module';


export class ModuleGeneric extends Module {
    visOnlyForLoggedUser: boolean;

    constructor(name, icon, eventId, order, viewApp, visOnlyForLoggedUser?: boolean) {
        super(name, icon, TypeModule.GENERIC, eventId, order, true, true, true)
        this.viewApp = viewApp;
        if (visOnlyForLoggedUser === undefined) {
            this.visOnlyForLoggedUser = false;
        } else {
            this.visOnlyForLoggedUser = visOnlyForLoggedUser;
        }
    }
}