/// <reference types="@types/googlemaps" />
import { Component, OnInit, ViewChild, ElementRef, NgZone } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Map } from 'src/app/models/map';
import { NotifiicationDateService } from 'src/app/providers/luxon/notification-date.service';
import { DbEventsProvider } from 'src/app/providers/database/db.events';
import { DbMapsProvider } from 'src/app/providers/database/db-maps';
import { StorageService } from 'src/app/providers/storage/storage.service';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { UUID } from 'angular2-uuid';
import { MapsAPILoader } from '@agm/core';
import { GlobalService } from 'src/app/providers/global/global.service';
declare let google: any;
declare let $: any;

@Component({
  selector: 'app-maps',
  templateUrl: './maps.component.html',
  styleUrls: ['./maps.component.scss'],
  providers: [DbMapsProvider]
})

export class MapsComponent implements OnInit {
  // get the language of the user.
  public userLanguage: string

  public module = null

  @ViewChild('successSwal') public successSwal: SwalComponent;
  @ViewChild('errorSwal') public errorSwal: SwalComponent;
  // maps
  @ViewChild("search") public searchElement: ElementRef;
  @ViewChild("searchEdit") public searchEditElement: ElementRef;
  p: number = 1;
  addressGoogle = '';
  eventId: string = null;
  moduleId: string = null;
  maps: Array<any> = [];
  typeMap: boolean = false;
  typeMapString: string = 'address';
  typeOrder: string = null;
  file: any = null;
  term;
  fileName: string = null;
  mapName: string = null;
  mapEditImage: string = null;
  mapAddress: any = null;
  editUrl: string = null;
  timezone: string = null;
  nameError: boolean = false;
  addressError: boolean = false;
  imageError: boolean = false;
  mapEdit: Map;
  deleteMap: Map;
  loader: boolean = true;
  modalLoader: boolean = false;
  constructor(
    private route: ActivatedRoute,
    private dbEvent: DbEventsProvider,
    private dbMaps: DbMapsProvider,
    private luxon: NotifiicationDateService,
    private storage: StorageService,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private global: GlobalService,
  ) {
    this.eventId = this.route.parent.params['_value']['uid'];
    this.moduleId = this.route.snapshot.params['moduleId'];
  }

  event: any = null;
  ngOnInit() {
    this.getUserLanguage()

    this.dbEvent.getEvent(this.eventId, (event) => {
      if (typeof event !== 'undefined' && event !== null) {
        this.event = event;
        this.timezone = event.timezone;
      }
    });

    this.dbMaps.getModule(this.moduleId, (module) => {
      if (typeof module !== 'undefined' && module !== null) {
        this.module = module
        this.typeOrder = module.orderMaps;
        this.getMaps();
        this.loadPlacesAutocomplete();
      }
    });
  }

  getMaps() {
    this.dbMaps.getMaps(this.moduleId, this.typeOrder, (maps: Array<Map>) => {
      this.maps = [];
      this.maps = maps;
      this.loader = false;
    });
  }

  changeTypeMap(status) {
    this.typeMap = status;
    if (this.typeMap == false) {
      this.typeMapString = 'address'
    } else {
      this.typeMapString = 'image'
    }
  }

  // get the language of the user.
  getUserLanguage() {
    this.global.getLanguage((language) => {
      this.userLanguage = language
    })
  }

  changeOrder() {
    this.dbMaps.updateModuleOrder(this.moduleId, this.typeOrder);
    this.getMaps();
  }

  onUploadChange($ev) {
    this.file = $ev.srcElement.files[0];
    this.fileName = this.file.name;
  }

  async createMap() {
    this.imageError = false;
    this.nameError = false;
    this.addressError = false;
    let map = new Map;
    map.name = this.mapName;
    map.address = this.addressGoogle;
    map.type = this.typeMapString;
    map.createdAt = this.luxon.getTimeStampFromDateNow(new Date(), this.timezone);
    let error = false;
    this.modalLoader = true;
    if (map.name !== null) {
      if (map.type == 'image') {
        if (this.file !== null) {
          let storageId = UUID.UUID();
          await this.storage.uploadMapImage(this.file, storageId, this.moduleId, this.eventId)
            .then(async (url: string) => {
              map.image = await url;
              map.storageId = storageId;
              error = false;
            });
        } else {
          // necessário adicionar uma imagem no tipo imagem
          error = true;
          this.imageError = true;
          this.modalLoader = false;
        }
      } else if (map.type == 'address') {
        if (this.mapAddress !== null) {
          map.address = this.addressGoogle;;
          error = false;
        } else {
          // necessário adicoinar um endereço
          error = true;
          this.addressError = true;
          this.modalLoader = false;
        }
      }

      if (error == false) {
        this.dbMaps.createMap(map, this.moduleId, (result) => {
          if (result == true) {
            $('#newMap').modal('toggle');
            this.successSwal.fire();
            this.modalLoader = false;
            this.modalLoader = false;
            this.mapAddress = null;
            this.mapName = null;
            this.typeMap = false;
            this.typeMapString = 'address';
            this.file = null;
            this.fileName = null;
          } else {
            this.errorSwal.fire();
            this.modalLoader = false;
          }
        });
      }
    } else {
      this.nameError = true;
      this.modalLoader = false;
    }
  }

  getMapEdit(map: Map) {
    this.mapEdit = map;
    this.mapName = map.name;
    this.mapAddress = map.address;
    this.mapEditImage = map.image;

    if (map.type == 'image') {
      this.typeMapString = 'image';
      this.typeMap = true;
    } else {
      this.loadPlacesEditAutocomplete();
      this.typeMapString = 'address';
      this.typeMap = false;
    }
  }

  async editMap() {
    this.imageError = false;
    this.nameError = false;
    this.addressError = false;
    let map = new Map;
    map.uid = this.mapEdit.uid;
    map.name = this.mapName;
    if (this.addressGoogle !== '') {
      map.address = this.addressGoogle;
    } else {
      map.address = this.mapAddress;
    }
    map.type = this.typeMapString;
    map.image = this.mapEditImage;
    this.modalLoader = true;
    let error = false;
    if (map.name !== null) {
      if (map.type == 'image') {
        if (this.file !== null) {
          let storageId = UUID.UUID();
          await this.storage.uploadMapImage(this.file, storageId, this.moduleId, this.eventId)
            .then(async (url: string) => {
              map.image = await url;
              map.storageId = storageId;
              this.storage.deleteMap(this.mapEdit.storageId, this.moduleId, this.eventId);
              error = false;
            });
        } else if (this.mapEditImage !== null) {
          map.image = this.mapEditImage;
        }
        else {
          // necessário adicionar uma imagem no tipo imagem
          error = true;
          this.imageError = true;
          this.modalLoader = false;
        }
      } else if (map.type == 'address') {
        if (this.addressGoogle !== '') {
          map.address = this.addressGoogle;
        } else if (this.mapAddress !== null) {
          map.address = this.mapAddress;
          error = false;
        } else {
          // necessário adicoinar um endereço
          error = true;
          this.addressError = true;
          this.modalLoader = false;
        }
      }

      if (error == false) {
        this.dbMaps.editMap(map, this.moduleId, (result) => {
          if (result == true) {
            $('#editMap').modal('toggle');
            this.successSwal.fire();
            this.modalLoader = false;
            this.mapAddress = null;
            this.mapName = null;
            this.typeMap = false;
            this.typeMapString = 'address';
            this.file = null;
            this.fileName = null;
          } else {
            this.errorSwal.fire();
            this.modalLoader = false;
          }
        });
      }
    } else {
      this.nameError = true;
      this.modalLoader = false;
    }
  }

  loadPlacesAutocomplete() {
    this.mapsAPILoader.load().then(
      () => {
        setTimeout(() => {
          let autocomplete = new google.maps.places.Autocomplete(this.searchElement.nativeElement, { types: ["address"] });
          autocomplete.addListener("place_changed", () => {
            this.ngZone.run(() => {
              let place: google.maps.places.PlaceResult = autocomplete.getPlace();
              if (place.geometry === undefined || place.geometry === null) {
                return;
              }
              this.addressGoogle = place.formatted_address;
            })
          })
        }, 1200);
      }
    )
  }


  loadPlacesEditAutocomplete() {
    this.mapsAPILoader.load().then(
      () => {
        setTimeout(() => {
          let autocomplete = new google.maps.places.Autocomplete(this.searchEditElement.nativeElement, { types: ["address"] });
          autocomplete.addListener("place_changed", () => {
            this.ngZone.run(() => {
              let place: google.maps.places.PlaceResult = autocomplete.getPlace();
              if (place.geometry === undefined || place.geometry === null) {
                return;
              }
              this.addressGoogle = place.formatted_address;
            })
          })
        }, 1200);
      }
    )
  }

  getMapDelete(map: Map) {
    this.deleteMap = map;
  }

  removeMap() {
    this.loader = true;
    this.dbMaps.removeMap(this.deleteMap, this.moduleId, this.eventId, (status) => {
      if (status == true) {
        this.successSwal.fire();
        this.loader = false;
      } else {
        this.errorSwal.fire();
        this.loader = false;
      }
    })
  }

  selectedAll() {

  }

}
