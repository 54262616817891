import { Component, OnInit, NgModule, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { DbAdminUserProvider } from '../../../providers/database/db-admin-user';
import { User } from '../../../models/user';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { GlobalService } from 'src/app/providers/global/global.service';
import { Name } from 'src/app/models/name';
import { AuthService } from '../../../providers/auth/auth.service';
import { FilterPipe } from 'src/app/pipes/filter.pipe';
import { TypeUser } from 'src/app/enums/typeUser';
import { DbAttendeesProvider } from 'src/app/providers/database/db-attendees';
import { DbSpeakersProvider } from 'src/app/providers/database/db-speakers';
import { TranslateService } from '@ngx-translate/core'

@Component({
  selector: 'app-supergod-create',
  templateUrl: './supergod-create.component.html',
  styleUrls: ['./supergod-create.component.scss']
})

@NgModule({
  declarations: [FilterPipe],
})

export class SupergodCreateComponent implements OnInit {

  formValidation: FormGroup;
  loader: boolean = false;
  validPassword: boolean = null;
  showErrorPassword: boolean = false;
  @ViewChild('successSwal') public successSwal: SwalComponent;
  @ViewChild('errorSwal') public errorSwal: SwalComponent;
  @ViewChild('errorEmailSwal') public errorEmailSwal: SwalComponent;
  @ViewChild('errorPermissionSwal') public errorPermissionSwal: SwalComponent;
  @ViewChild('confirmChangeUserTypeSwal') public confirmChangeUserTypeSwal: SwalComponent;
  @ViewChild('alreadyExistsSwal') public alreadyExistsSwal: SwalComponent;


  userType: number = null;
  term: string = '';
  userRemoveId: string = null;
  userRemoveType: number = null;
  userRemoveTypeStr: string = null;
  

  constructor(private router: Router,
    fb: FormBuilder, 
    private dbAdminUser: DbAdminUserProvider, 
    private global: GlobalService, 
    private auth: AuthService,
    private dbAttendee: DbAttendeesProvider,
    private dbSpeaker: DbSpeakersProvider,
    private translateService: TranslateService) {

    this.formValidation = fb.group({
      'name': [null, Validators.compose([Validators.required, Validators.maxLength(100)])],
      'email': [null, Validators.compose([Validators.required, Validators.email, Validators.maxLength(200)])],
      'password': [null, Validators.compose([Validators.required])],
      'language': ['pt_BR', Validators.compose([Validators.required])],
      'description': [null],
    })
  }

  ngOnInit() {
  }

  ngDoCheck() {
    this.validatePassword();
  }

  validatePassword() {
    let password = this.formValidation.get('password').value;
    let regex = /(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[^a-zA-Z]).{8,50}/;
    let regexSymbol = /[-!$%^&*#@()_+|~=`{}\[\]:";'<>?,.\/]/;

    let found;
    let foundSymbol;
    if(password !== null && password !== undefined && password !== '') {      
      found = password.match(regex);
      foundSymbol = password.match(regexSymbol);
      
      
      if(found !== null && foundSymbol !== null) {
        this.validPassword = true;
      } else {
        this.validPassword = false;
        this.showErrorPassword = true;
      }
    } else {
      this.validPassword = false;
      this.showErrorPassword = false;
    }
  }

  ngAfterContentChecked() {
    this.userType = this.global.userType;
  }

  checkEmailCreateSupergod() {
    let data = this.formValidation.value;

    this.dbAdminUser.getUserByEmail(data.email, (user) => {
      if(user === null) {
        this.userRemoveId = null;
        this.userRemoveType = null;
        this.userRemoveTypeStr = null;
        this.createSupergod();
      } else {

        if(user.type === TypeUser.SUPERGOD) {
          //Mensagem informando que o usuário já é um SUPERGOD
          this.alreadyExistsSwal.fire();

        } else if(user.type === TypeUser.GOD) {
          if(this.global.userType === TypeUser.SUPERGOD) {
            // Pergunta se o usuário deseja transformar o GOD em SUPERGOD
            this.userRemoveId = user.uid;
            this.userRemoveType = user.type;
            this.userRemoveTypeStr = this.translateService.instant('global.type_user_god');
            this.confirmChangeUserTypeSwal.fire();
          } else {
            //Informa que o usuário não tem permissão para mudar o tipo de usuário.
            this.errorPermissionSwal.fire();
            
          }

        } else if(user.type === TypeUser.CLIENT) {
          if(this.global.userType === TypeUser.SUPERGOD || this.global.userType === TypeUser.GOD) {
            // Pergunta se o usuário deseja transformar o CLIENT em SUPERGOD
            this.userRemoveId = user.uid;
            this.userRemoveType = user.type;
            this.userRemoveTypeStr = this.translateService.instant('global.type_user_client');
            this.confirmChangeUserTypeSwal.fire();
          } else {
            //Informa que o usuário não tem permissão para mudar o tipo de usuário.
            this.errorPermissionSwal.fire();
          }

        } else if(user.type === TypeUser.EMPLOYEE) {
          if(this.global.userType === TypeUser.SUPERGOD || this.global.userType === TypeUser.GOD || this.global.userType === TypeUser.CLIENT) {
            // Pergunta se o usuário deseja transformar o EMPLOYEE em SUPERGOD
            this.userRemoveId = user.uid;
            this.userRemoveType = user.type;
            this.userRemoveTypeStr = this.translateService.instant('global.type_user_employee');
            this.confirmChangeUserTypeSwal.fire();
          } else {
            //Informa que o usuário não tem permissão para mudar o tipo de usuário.
            this.errorPermissionSwal.fire();
          }

        } else if(user.type === TypeUser.SPEAKER) {
          this.userRemoveId = user.uid;
          this.userRemoveType = user.type;
          this.userRemoveTypeStr = this.translateService.instant('global.type_user_speaker');
          this.confirmChangeUserTypeSwal.fire();

        } else if(user.type === TypeUser.ATTENDEE) {
          this.userRemoveId = user.uid;
          this.userRemoveType = user.type;
          this.userRemoveTypeStr = this.translateService.instant('global.type_user_attendee');
          this.confirmChangeUserTypeSwal.fire();
        
        }
      }
    })
  }

  confirmChangeUserType() {
    if(this.userRemoveType == TypeUser.SUPERGOD || this.userRemoveType == TypeUser.GOD 
      || this.userRemoveType == TypeUser.CLIENT || this.userRemoveType == TypeUser.EMPLOYEE) {
      
      this.auth.removeUserAuth(this.userRemoveId, (data) => {
        if(data.result === true) {
          this.createSupergod();
        }
      });

    } else if(this.userRemoveType == TypeUser.ATTENDEE) {
      
      this.dbAttendee.removeAttendeeAllEvents(this.userRemoveId, (data) => {
        if(data == true) {
          this.createSupergod();
        }
      })

    } else if(this.userRemoveType == TypeUser.SPEAKER) {
      
      this.dbSpeaker.removeSpeakerAllEvents(this.userRemoveId, (data) => {
        if(data == true) {
          this.createSupergod();
        }
      })
    }
    
  }

  createSupergod() {
    let data = this.formValidation.value;

    if(this.validPassword) {
      this.loader = true;
      
      let user = new User(data.name, data.email);
      user.$photoUrl = "";
      user.$password = data.password;
      user.$language = data.language;
      user.$description = data.description;
      user.$type = 0;
      user.$createdAt = null;
      user.$emailRecovery = "";
      user.$title = "";
      user.$company = "";
  
      //verifica se o email já existe
      this.auth.verifyEmailDb(data.email)
      .then((data) => {
        if(data['result'] == "email-not-found") { //se o email não existir cria o user
          this.dbAdminUser.createUser(user, (data) => {
            if (data.code == 201) {
              if (data['result'].uid !== null && data['result'].uid !== undefined && data['result'].uid !== '') {
                this.successSwal.fire();
              }
            } else {
              this.errorSwal.fire();
            }
      
            this.loader = false;
          })
        } else { // se o email existir emite o aviso de erro
          this.errorEmailSwal.fire();
          this.loader = false;
        }
      })
      .catch((err) => {
        console.log(err)
      })
    }
  }

  redirectList() {
    this.router.navigate(['/dashboard/supergod']);
  }

}
