import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { forkJoin, of } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { QRCode } from "src/app/models/gaming-qrcode";

@Injectable({
    providedIn: "root",
})
export class DbGamificationProvider {
    constructor(
        private aFirestore: AngularFirestore,
    ) {
    }

    getModule(moduleId: string, onResolve) {
        let db = this.aFirestore.firestore;

        db.collection("modules")
            .doc(moduleId)
            .onSnapshot((value) => {
                onResolve(value.data());
            });
    }

    updateModuleSettingsTexts(
        moduleId: string,
        eventId: string,
        objUpdate,
        onResolve
    ) {
        let db = this.aFirestore.firestore;
        let refModule = db.collection("modules").doc(moduleId);
        let refEvent = db
            .collection("events")
            .doc(eventId)
            .collection("modules")
            .doc(moduleId);
        let batch = db.batch();
        batch.update(refModule, objUpdate);
        batch.update(refEvent, objUpdate);

        batch
            .commit()
            .then((_) => {
                onResolve(true);
            })
            .catch((e) => {
                onResolve(false);
            });
    }

    getCodes(moduleId: string, order: string, onResolve) {
        let db = this.aFirestore.firestore;
        let ref;

        switch (order) {
            case "asc": //a-z
                ref = db
                    .collection("modules")
                    .doc(moduleId)
                    .collection("gamification-qrcodes")
                    .orderBy("title", "asc");
                break;

            case "desc": //z-a
                ref = db
                    .collection("modules")
                    .doc(moduleId)
                    .collection("gamification-qrcodes")
                    .orderBy("title", "desc");
                break;

            case "oldest": //antiho-recente
                ref = db
                    .collection("modules")
                    .doc(moduleId)
                    .collection("gamification-qrcodes")
                    .orderBy("createdAt", "asc");
                break;

            case "recent": //recente-antigo
                ref = db
                    .collection("modules")
                    .doc(moduleId)
                    .collection("gamification-qrcodes")
                    .orderBy("createdAt", "desc");
                break;
        }
        ref.onSnapshot((snapshot) => {
            let codes = [];
            if (snapshot.size >= 1) {
                snapshot.forEach((element) => {
                    let code = element.data();
                    codes.push(code);
                });
            }
            onResolve(codes);
        });
    }

    newQR(qrcode: QRCode, onResolve) {
        let db = this.aFirestore.firestore;
        let refEvent = db
            .collection("events")
            .doc(qrcode.eventId)
            .collection("gamification-qrcodes")
            .doc();
        let refModule = db
            .collection("modules")
            .doc(qrcode.moduleId)
            .collection("gamification-qrcodes")
            .doc(refEvent.id);
        let batch = db.batch();

        qrcode.uid = refEvent.id;
        qrcode.createdAt = (Date.now() / 1000) | 0;
        qrcode = Object.assign({}, qrcode);

        batch.set(refEvent, qrcode);
        batch.set(refModule, qrcode);
        batch
            .commit()
            .then((_) => {
                onResolve(true);
            })
            .catch((e) => {
                onResolve(false);
            });
    }

    editQR(qrcode: QRCode, onResolve) {
        let db = this.aFirestore.firestore;
        let refEvent = db
            .collection("events")
            .doc(qrcode.eventId)
            .collection("gamification-qrcodes")
            .doc(qrcode.uid);
        let refModule = db
            .collection("modules")
            .doc(qrcode.moduleId)
            .collection("gamification-qrcodes")
            .doc(qrcode.uid);
        let batch = db.batch();

        qrcode = Object.assign({}, qrcode);

        batch.update(refEvent, qrcode);
        batch.update(refModule, qrcode);
        batch
            .commit()
            .then((_) => {
                onResolve(true);
            })
            .catch((e) => {
                onResolve(false);
            });
    }

    deleteQR(qrcode: QRCode, onResolve) {
        let db = this.aFirestore.firestore;
        let refEvent = db
            .collection("events")
            .doc(qrcode.eventId)
            .collection("gamification-qrcodes")
            .doc(qrcode.uid);
        let refModule = db
            .collection("modules")
            .doc(qrcode.moduleId)
            .collection("gamification-qrcodes")
            .doc(qrcode.uid);
        let batch = db.batch();

        batch.delete(refEvent);
        batch.delete(refModule);
        batch
            .commit()
            .then((_) => {
                onResolve(true);
            })
            .catch((e) => {
                onResolve(false);
            });
    }

    deleteAllQRCode(qrcodes, eventId: string, moduleId: string, onResolve) {
        let db = this.aFirestore.firestore;
        let batch = db.batch();
        let cont = 0;
        let codes = Object.values(qrcodes);
        for (let index in codes) {
            if (codes[index]["remove"]) {
                let refEvent = db
                    .collection("events")
                    .doc(eventId)
                    .collection("gamification-qrcodes")
                    .doc(codes[index]["uid"]);
                let refModule = db
                    .collection("modules")
                    .doc(moduleId)
                    .collection("gamification-qrcodes")
                    .doc(codes[index]["uid"]);
                batch.delete(refEvent);
                batch.delete(refModule);
                cont++;

                if (cont == codes.length - 1) {
                    batch
                        .commit()
                        .then((_) => {
                            onResolve(true);
                        })
                        .catch((e) => {
                            onResolve(false);
                        });
                }
            }
        }
    }

    changeOrder(order: string, moduleId: string, eventId: string) {
        let db = this.aFirestore.firestore;
        let refEvent = db.collection("events").doc(eventId);
        let refModule = db.collection("modules").doc(moduleId);
        refEvent.update({ typeOrder: order });
        refModule.update({ typeOrder: order });
    }

    exportGamification(eventId: string, moduleId: string) {
        return forkJoin([
            this.aFirestore
                .collection("modules")
                .doc(moduleId)
                .collection("gamification-qrcodes")
                .get()
                .pipe(map((docs) => docs.docs.map((doc) => doc.data()))),
            this.aFirestore
                .collection("events")
                .doc(eventId)
                .collection("attendees")
                .get()
                .pipe(map((docs) => docs.docs.map((doc) => doc.data()))),
        ]).pipe(
            switchMap((results) => {
                let qrCodes = results[0];
                for (let i = 0; i < qrCodes.length; i++) {
                    qrCodes[i].attendees = results[1].filter(
                        (attendee) =>
                            attendee.gaming && attendee.gaming[qrCodes[i].uid]
                    );
                }

                return of(qrCodes);
            })
        );
    }
}
