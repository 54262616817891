import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { DbEventsProvider } from 'src/app/providers/database/db.events';
import { Event } from 'src/app/models/event';
import { DbAnalyticsProvider } from 'src/app/providers/database/db-analytics';
import { GlobalService } from 'src/app/providers/global/global.service';
import { FormatedEventLanguageService } from 'src/app/providers/formated-event-language/formated-event-language.service';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/shared/reducers';
import { take } from 'rxjs/operators';
import { getGeneralAnalytics, getUsersAnalytics } from 'src/app/shared/selectors/analytics.selectors';

@Component({
    selector: 'app-general-analytics',
    templateUrl: './general-analytics.component.html',
    styleUrls: ['./general-analytics.component.scss']
})
export class GeneralAnalyticsComponent implements OnInit {
    public userLanguage: string

    eventId: string;
    event: Event = null;
    @ViewChild('errorSwal') public errorSwal: SwalComponent;
    loader: boolean = true;

    // ATTENDEES INFOS
    totalAccess: number = 0;
    totalUniqueUsers: number = 0;
    profileEdited: number = 0;
    blockUserInfos: boolean = false;

    // MODULES INFOS
    loaderModules: boolean = true;
    modules: Array<any> = [];

    constructor(
        private route: ActivatedRoute,
        private dbEvent: DbEventsProvider,
        private dbAnalytics: DbAnalyticsProvider,
        private global: GlobalService,
        public formatedLanguage: FormatedEventLanguageService,
        private store: Store<AppState>
    ) {
    }

    ngOnInit() {
        this.route.params.subscribe((params) => {
            this.eventId = params.uid;
            this.getUserLanguage()
            this.getEvent();
        })
    }

    getEvent() {
        this.dbEvent.getEvent(this.eventId, (event) => {
            this.event = event;
            this.formatedLanguage.convertLangFormat(event.language);
            this.dbAnalytics.getUsersAnalytics(this.eventId).pipe(take(1)).subscribe(() => {
                this.store.select(getUsersAnalytics).subscribe((usersAnalytics) => {
                    this.totalUniqueUsers = (usersAnalytics && usersAnalytics.totalUniqueUsers) ? usersAnalytics.totalUniqueUsers : 0;
                })
            })
            this.dbAnalytics.getGeneralAnalytics(this.eventId).pipe(take(1)).subscribe(() => {
                this.store.select(getGeneralAnalytics).subscribe((generalAnalytics) => {
                    this.totalAccess = (generalAnalytics && generalAnalytics.totalAccess) ? generalAnalytics.totalAccess : 0;
                    this.profileEdited = (generalAnalytics && generalAnalytics.totalProfilEdition) ? generalAnalytics.totalProfilEdition : 0;
                    this.modules = (generalAnalytics && generalAnalytics.bestConsultedModules) ? generalAnalytics.bestConsultedModules : [];

                    this.loader = false;
                })
            });
        });
    }

    // get the language of the user.
    getUserLanguage() {
        this.global.getLanguage((language) => {
            this.userLanguage = this.formatedLanguage.convertLang(language);
        })
    }

    /**
     * Export analytics of tracking
     */
    exportAll() {
        this.loader = true;
        this.dbAnalytics.exportGeneralAnalytics(this.eventId, this.userLanguage).subscribe(() => {
            this.loader = false;
        }, (error) => {
            this.loader = false;
        })
    }
}
