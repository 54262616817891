import { ChangeDetectorRef, Component, EventEmitter, Input, NgModule, OnInit, Output } from '@angular/core';
import { Group } from 'src/app/models/group';
import { DbAppointments } from 'src/app/providers/database/db-appointments';
import * as luxon from 'luxon';
import {
  AppointmentRule,
  AppointmentRuleGroups,
} from 'src/app/models/appointments';

@Component({
  selector: 'app-time-slots-rule-editor',
  templateUrl: './time-slots-rule-editor.component.html',
  styleUrls: ['./time-slots-rule-editor.component.scss']
})
export class TimeSlotsRuleEditorComponent implements OnInit {
  event: any;

  @Input()
  rule: AppointmentRule = new AppointmentRule(null, [], false);
  @Input()
  groups: Group[] = [];

  locale: string;
  @Input()
  eventTimezone: string;

  @Output()
  submit = new EventEmitter<AppointmentRule>();
  @Output()
  delete = new EventEmitter<AppointmentRule>();

  slotsCountRaw = 0;
  get slotsCount() {
    return this.slotsCountRaw - this.rule.slots.disabledTimeSlots.length;
  }

  get canAddGroupRule() {
    return this.rule.groups
      && this.rule.groups.length > 0
      && this.rule.groups[this.rule.groups.length - 1].applicantGroupId
      && this.rule.groups[this.rule.groups.length - 1].invitedGroupId;
  }

  get canSubmitRule() {
    return this.rule.slots
      && this.rule.slots.startDateTime
      && this.rule.slots.endDateTime
      && this.rule.slots.minutesInterval
      && this.slotsCountRaw > 0
      && this.rule.groups
      && !this.rule.groups.some(gr => !gr.applicantGroupId || !gr.invitedGroupId);
  }

  constructor(
    private dbAppointments: DbAppointments,
    private cd: ChangeDetectorRef,
  ) {
  }

  ngOnInit() {
    this.locale = this.dbAppointments.locale;
  }

  getDateTimeWithEventZone(isoDateTime: string) {
    return luxon.DateTime.fromISO(isoDateTime, { zone: this.eventTimezone });
  }


  async onSubmit() {
    this.submit.emit(this.rule);
  }

  async onDelete() {
    this.delete.emit(this.rule);
  }

  addGroupsRule() {
    this.rule.groups.push(new AppointmentRuleGroups(null, null));
  }

  addAllGroupsRule() {
    this.rule.groups = [];
    this.groups.forEach(g1 => {
      this.groups.forEach(g2 => {
        this.rule.groups.push(new AppointmentRuleGroups(g1.uid, g2.uid));
      });
    });
  }

  deleteGroupsRule(index: number) {
    this.rule.groups.splice(index, 1);
  }

  enableRemovedTimeSlot(index: number) {
    this.rule.slots.disabledTimeSlots.splice(index, 1);
    this.cd.detectChanges();
  }

}
