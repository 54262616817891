import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { PathApi } from "../../paths/path-api";
import { Session } from "../../models/session";
import firebase from 'firebase/app';
import { Track } from "src/app/models/track";
import { AngularFirestore } from "@angular/fire/firestore";
import { TypeModule } from "src/app/enums/type-module";
import { ModuleSchedule } from "src/app/models/modules/module-schedule";

@Injectable({
    providedIn: "root",
})
export class DbScheduleProvider {
    public headers;
    public requestOptions;
    private db: firebase.firestore.Firestore;

    constructor(
        private http: HttpClient,
        private aFirestore: AngularFirestore
    ) {
        this.headers = new HttpHeaders();
        this.headers.append("Accept", "application/json");
        this.headers.append("Content-Type", "application/json");
        this.requestOptions = { headers: this.headers };

        this.db = firebase.firestore();
    }

    getIdentifiersSessions(eventId: string, onResolve) {
        let ref = this.db
            .collection("events")
            .doc(eventId)
            .collection("sessions");

        ref.orderBy("identifier")
            .get()
            .then((snapshot) => {
                let list = [];

                snapshot.forEach((session) => {
                    list.push(session.data().identifier);
                });

                onResolve(list);
            });
    }

    getSessionsModule(moduleId, onResolve) {
        const ref = this.db
            .collection("modules")
            .doc(moduleId)
            .collection("sessions");

        ref.orderBy("startTime")
            .get()
            .then((snapshot) => {
                const list = [];

                snapshot.forEach((value) => {
                    const session = value.data();
                    list.push(session);
                });
                onResolve(list);
            });
    }
    getPromiseSessionsModule(moduleId): Promise<Array<any>> {
        return new Promise((resolve, rejects) => {
            const ref = this.db
                .collection("modules")
                .doc(moduleId)
                .collection("sessions");

            ref.orderBy("startTime")
                .get()
                .then((snapshot) => {
                    const list = [];

                    snapshot.forEach((value) => {
                        const session = value.data();
                        list.push(session);
                    });
                    resolve(list);
                });
        });
    }

    getSessionsEvent(eventId, onResolve) {
        const ref = this.db
            .collection("events")
            .doc(eventId)
            .collection("sessions")
            .orderBy("startTime");

        ref.get().then((data) => {
            let listSessions = [];
            data.forEach((element) => {
                listSessions.push(element.data());
            });

            onResolve(listSessions);
        });
    }

    getSessionsModuleExport(moduleId: string, onResolve) {
        let body = {
            moduleId: moduleId,
        };

        this.http
            .post(
                PathApi.baseUrl + PathApi.dbScheduleGetSessionsExport,
                body,
                this.requestOptions
            )
            .subscribe((status) => {
                onResolve(status["result"]);
            });
    }

    getSession(sessionId: string, eventId: string, onResolve) {
        const ref = this.db
            .collection("events")
            .doc(eventId)
            .collection("sessions")
            .doc(sessionId);

        ref.onSnapshot((snapshot) => {
            const session = snapshot.data();
            onResolve(session);
        });
    }

    getSessionPromise(sessionId: string, moduleId: string) {
        return new Promise((resolve) => {
            const ref = this.db
                .collection("modules")
                .doc(moduleId)
                .collection("sessions")
                .doc(sessionId);

            ref.onSnapshot((snapshot) => {
                const session = snapshot.data();
                resolve(session);
            });
        });
    }

    createSession(session: Session, onResolve) {
        const sessionModuleId = session.moduleId;
        const eventId = session.eventId;

        // convert typescript objects to jsons
        session.descriptions = Object.assign({}, session.descriptions);
        session.name = Object.assign({}, session.name);
        session = Object.assign({}, session);

        for (let location in session.locations) {
            location = Object.assign({}, location);
        }

        for (let track in session.tracks) {
            track = Object.assign({}, track);
        }

        for (let speaker in session.speakers) {
            speaker = Object.assign({}, speaker);
        }

        for (let group in session.groups) {
            group = Object.assign({}, group);
        }

        // start batch
        let batch = this.db.batch();

        // create document event
        let refEvent = this.db
            .collection("events")
            .doc(eventId)
            .collection("sessions")
            .doc();
        const sessionId = refEvent.id;
        session.uid = refEvent.id;
        batch.set(refEvent, session);

        // create document module session
        let refModule = this.db
            .collection("modules")
            .doc(sessionModuleId)
            .collection("sessions")
            .doc(sessionId);
        batch.set(refModule, session);

        if (session.videoPlayer) {
            const refReactions = this.db    // create a empty document for session video reactions
                .doc(`events/${eventId}/modules/${session.moduleId}/reactions/${sessionId}`);
            
            const reactionModel = {
                counter: 0,
                userUids: {}
            };

            const obj = {};
            obj[session.videoPlayer.id] = {
                amazing: reactionModel,
                like: reactionModel,
                love: reactionModel,
                wow: reactionModel,
                rocket: reactionModel,
                congratulation: reactionModel
            }

            batch.set(refReactions, obj);
        }

        //  Commit the batch
        batch
            .commit()
            .then((batchOk) => {
                onResolve(true);
            })
            .catch((error) => {
                console.log("Error! :( " + error);
                onResolve(false);
            });
    }

    async updateSession(session, onResolve) {
        // start batch
        let batch = this.db.batch();

        const sessionModuleId = session.moduleId;
        const eventId = session.eventId;
        const sessionId = session.uid;

        // update the session on the event and module
        const ref1 = this.db
            .collection("events")
            .doc(eventId)
            .collection("sessions")
            .doc(sessionId);
        const ref2 = this.db
            .collection("modules")
            .doc(sessionModuleId)
            .collection("sessions")
            .doc(sessionId);

        batch.set(ref1, session);
        batch.set(ref2, session);

        // Commit the batch
        batch
            .commit()
            .then((batchOk) => {
                onResolve(true);
            })
            .catch((error) => {
                onResolve(error);
            });
    }

    /**
     * Update activation of visio on session
     * @param sessionId
     * @param activation
     */
    async updateActivationVisioOnSession(session: Session, activation) {
        await this.aFirestore
            .collection("events")
            .doc(session.eventId)
            .collection("sessions")
            .doc(session.uid)
            .update({
                "visio.activated": activation,
            });

        return this.aFirestore
            .collection("modules")
            .doc(session.moduleId)
            .collection("sessions")
            .doc(session.uid)
            .update({
                "visio.activated": activation,
            });
    }

    importSessions(
        eventId: string,
        moduleId: string,
        principalSessions: Array<string>,
        sessions_de_DE: Array<string>,
        sessions_en_US: Array<string>,
        sessions_es_ES: Array<string>,
        sessions_fr_FR: Array<string>,
        sessions_pt_BR: Array<string>,
        timezone: string,
        onResolve
    ) {
        let body = {
            eventId: eventId,
            moduleId: moduleId,
            dataPrincipalSessions: principalSessions,
            sessions_de_DE: sessions_de_DE,
            sessions_en_US: sessions_en_US,
            sessions_es_ES: sessions_es_ES,
            sessions_fr_FR: sessions_fr_FR,
            sessions_pt_BR: sessions_pt_BR,
            timezone: timezone
        };

        this.http
            .post(
                PathApi.baseUrl + PathApi.dbScheduleImportSessions,
                body,
                this.requestOptions
            )
            .subscribe((status) => {
                onResolve(status);
            });
    }

    deleteSession(session: Session, onResolve) {
        const eventId = session.eventId;
        const sessionId = session.uid;
        const moduleId = session.moduleId;
        const batch = this.db.batch();

        //active dbScheduleDeleteSession
 
        batch.delete(
            this.db.doc(`events/${eventId}/sessions/${sessionId}`)
        );
        batch.delete(
            this.db.doc(`events/${eventId}/modules/${moduleId}/reactions/${sessionId}`)
        );
        batch.delete(
            this.db.doc(`modules/${moduleId}/sessions/${sessionId}`)
        );

        batch.commit().then(() => {
            onResolve(true);    
        }).catch((error) => {
            console.error(error);
            onResolve(false);
        })
        onResolve(true);
    }

    deleteSessions(sessions: Array<Session>, onResolve) {
        for (const session of sessions) {
            const eventId = session.eventId;
            const sessionId = session.uid;
            const moduleId = session.moduleId;

            // active dbScheduleDeleteSession
            this.db
                .collection("events")
                .doc(eventId)
                .collection("sessions")
                .doc(sessionId)
                .delete();
            this.db
                .collection("modules")
                .doc(moduleId)
                .collection("sessions")
                .doc(sessionId)
                .delete();
        }

        onResolve(true);
    }

    /**
     * Update schedule header visibility
     * @param eventId
     * @param moduleId
     * @param value
     */
    updateProperty(
        eventId: string,
        moduleId: string,
        propertyName: string,
        value: any
    ) {
        let db = this.aFirestore.firestore;

        const ref1 = db
            .collection("events")
            .doc(eventId)
            .collection("modules")
            .doc(moduleId);
        const ref2 = db.collection("modules").doc(moduleId);

        let batch = this.db.batch();

        const obj = {};
        obj[propertyName] = value;
        batch.update(ref1, obj);
        batch.update(ref2, obj);

        return batch
            .commit()
            .then((_) => {
                return true;
            })
            .catch(() => {
                return false;
            });
    }

    /**
     * Update orators field value schedule
     * @param eventId
     * @param moduleId
     * @param oratorsField
     */
    updateOratorsFieldSchedule(
        eventId: string,
        moduleId: string,
        oratorsField: string
    ) {
        let db = this.aFirestore.firestore;

        const ref1 = db
            .collection("events")
            .doc(eventId)
            .collection("modules")
            .doc(moduleId);
        const ref2 = db.collection("modules").doc(moduleId);

        let batch = this.db.batch();

        batch.update(ref1, { oratorsField: oratorsField });
        batch.update(ref2, { oratorsField: oratorsField });

        return batch
            .commit()
            .then((_) => {
                return true;
            })
            .catch(() => {
                return false;
            });
    }

    /*============================================================  LOCATIONS ==================================================================/ */

    // get locations of session
    getLocationsOfSession(sessionId: string, moduleId: string) {
        return new Promise((resolve) => {
            const ref = this.db
                .collection("modules")
                .doc(moduleId)
                .collection("sessions")
                .doc(sessionId)
                .collection("locations");
            const locations = [];

            ref.get().then((childSnapshot) => {
                if (childSnapshot.size > 0) {
                    childSnapshot.forEach((value) => {
                        locations.push(value.data());
                    });
                }

                resolve(locations);
            });
        });
    }

    // list the sessions of the path passed in the parameter
    getLocationsOfSessionPath(reference) {
        return new Promise((resolve) => {
            reference.get().then((snapshot) => {
                const locations = snapshot.data().locations;
                resolve(locations);
            });
        });
    }

    // delete locations of session
    deleteLocationsOfSession(
        sessionId: string,
        moduleId: string,
        eventId: string
    ) {
        return new Promise((resolve) => {
            const refEvent = this.db
                .collection("events")
                .doc(eventId)
                .collection("sessions")
                .doc(sessionId)
                .collection("locations");

            refEvent.get().then((snapshot) => {
                snapshot.forEach((location) => {
                    console.log(location.data());
                });
            });
            resolve(true);
        });
    }

    /***************************************************************** TRACKS ************************************************************************** */
    public createNewTrack(eventId: string, moduleId: string, track: Track, onResolve) {
        const db = this.aFirestore.firestore;
        const batch = db.batch();

        const ref1 = db.collection('events').doc(eventId).collection('modules').doc(moduleId).collection('tracks').doc();
        const ref2 = db.collection("modules").doc(moduleId).collection("tracks").doc(ref1.id);

        track.$name = Object.assign({}, track.$name);
        track.$queryName = Object.assign({}, track.$queryName);
        track.$date = Object.assign({}, track.$date);
        track.$startTime = Object.assign({}, track.$startTime);
        track.$endTime = Object.assign({}, track.$endTime);
        track.$location = Object.assign({}, track.$location);
        track = Object.assign({}, track);
    
        track.uid = ref1.id;

        batch.set(ref1, track);
        batch.set(ref2, track);

        batch.commit().then(() => {
            onResolve(true);
        }).catch((error) => {
            console.log(error);
            onResolve(false);
        })
    }

    editTrack(moduleId: string, eventId: string, track: Track, onResolve) {
        // let db = this.aFirestore.firestore;
        // let ref = db.collection('modules').doc(moduleId).collection('tracks').doc(track.$uid);
        // track.$name = Object.assign({}, track.$name);
        // track = Object.assign({}, track);

        // ref.update(track)
        //     .then((_) => {
        //         onResolve(true)
        //     })
        //     .catch((e) => {
        //         onResolve(false)
        //     });

        let body = {
            moduleId: moduleId,
            track: track,
            eventId: eventId,
        };

        this.http
            .post(
                PathApi.baseUrl + PathApi.dbScheduleEditTrack,
                body,
                this.requestOptions
            )
            .subscribe((result) => {
                onResolve(result["result"]);
            }),
            (err) => {
                onResolve(err);
            };
    }

    getModuleTracks(moduleId: string, onResolve) {
        let db = this.aFirestore.firestore;
        let ref = db
            .collection("modules")
            .doc(moduleId)
            .collection("tracks")
            .orderBy("queryName", "asc");

        ref.onSnapshot((snapshot) => {
            let tracks = [];
            snapshot.forEach((element) => {
                tracks.push(element.data());
            });
            onResolve(tracks);
        });
    }

    getTrackModule(moduleId: string, trackId: string, onResolve) {
        let db = this.aFirestore.firestore;
        let ref = db
            .collection("modules")
            .doc(moduleId)
            .collection("tracks")
            .doc(trackId);

        ref.get().then((data) => {
            let track = data.data();
            onResolve(track);
        });
    }

    deleteTrack(eventId: string, moduleId: string, trackId: string, onResolve) {
        const body = {
            eventId: eventId,
            moduleId: moduleId,
            trackId: trackId,
        };
        this.http
            .post(
                PathApi.baseUrl + PathApi.dbScheduleDeleteTrack,
                body,
                this.requestOptions
            )
            .subscribe((ok) => {
                onResolve(true);
            }),
            (e) => {
                onResolve(false);
            };
    }

    renameTrack(eventId: string, moduleId: string, name: any, onResolve) {
        let db = this.aFirestore.firestore;
        let refModule = db.collection("modules").doc(moduleId);
        let refEvent = db
            .collection("events")
            .doc(eventId)
            .collection("modules")
            .doc(moduleId);
        // start batch
        let batch = this.db.batch();
        name = Object.assign({}, name);
        batch.update(refModule, { trackName: name });
        batch.update(refEvent, { trackName: name });

        batch
            .commit()
            .then((_) => {
                onResolve(true);
            })
            .catch(() => {
                onResolve(false);
            });
    }

    getScheduleModule(moduleId: string, onResolve) {
        let db = this.aFirestore.firestore;
        let ref = db.collection("modules").doc(moduleId);

        ref.onSnapshot((value) => {
            onResolve(Object.assign({}, value.data()));
        });
    }

    importTracks(
        moduleId: string,
        eventId: string,
        tracks: Array<Track>,
        onResolve
    ) {
        let body = {
            moduleId: moduleId,
            eventId: eventId,
            tracks: tracks,
        };

        this.http
            .post(
                PathApi.baseUrl + PathApi.dbScheduleImportTracks,
                body,
                this.requestOptions
            )
            .subscribe((status) => {
                onResolve(status);
            });
    }

    /********************************************************************** * personal agenda ****************************************************/

    // receives the event uid and returns the event's agenda module
    eventCalendarModule(eventId: string) {
        return new Promise((resolve: any) => {
            let db = this.aFirestore.firestore;

            const ref = db
                .collection("events")
                .doc(eventId)
                .collection("modules");

            ref.where("type", "==", TypeModule.PERSONALSCHEDULE)
                .get()
                .then((snapshot) => {
                    snapshot.forEach((childSnapshot) => {
                        const module = childSnapshot.data();
                        resolve(module);
                    });
                });
        });
    }

    // lists the participants who have the session on the personal agenda.
    getListOfSessionAttendees(moduleScheduleId: string, sessionId: string) {
        return new Promise((resolve) => {
            let db = this.aFirestore.firestore;

            const ref = db
                .collection("modules")
                .doc(moduleScheduleId)
                .collection("sessions")
                .doc(sessionId)
                .collection("attendees");

            ref.get().then((snapshot) => {
                const list = [];

                snapshot.forEach((childSnapshot) => {
                    list.push(childSnapshot.data());
                });

                resolve(list);
            });
        });
    }

    getTracksIds(moduleId: string, onResolve) {
        let db = this.aFirestore.firestore;
        let ref = db
            .collection("modules")
            .doc(moduleId)
            .collection("tracks")
            .orderBy("identifier", "asc");

        ref.get().then((data) => {
            let listIds: Array<number> = [];
            data.forEach((element) => {
                let track = element.data();
                listIds.push(track.identifier);
            });

            onResolve(listIds);
        });
    }

    /**
     * Update sessions
     * @param eventId
     * @param moduleId
     * @param sessions
     * @returns
     */
    updateSessions(eventId: string, moduleId: string, sessions: any[]) {
        let batch = this.aFirestore.firestore.batch();
        sessions.forEach((session) => {
            console.log(
                "Session batch: ",
                session.uid,
                " - ",
                eventId,
                " - ",
                moduleId
            );
            batch.update(
                this.aFirestore
                    .collection("events")
                    .doc(eventId)
                    .collection("sessions")
                    .doc(session.uid).ref,
                session
            );
            batch.update(
                this.aFirestore
                    .collection("modules")
                    .doc(moduleId)
                    .collection("sessions")
                    .doc(session.uid).ref,
                session
            );
        });

        return batch.commit();
    }

    /**
     * @description update the content of firebase document of module schedule, related with schedule settings 
     * @param eventId 
     * @param moduleId 
     * @param settings 
     */
    public updateSettings(eventId, moduleId, settings: Object) {
        const db = this.aFirestore.firestore;

        const ref1 = db
            .collection("events")
            .doc(eventId)
            .collection("modules")
            .doc(moduleId);
        
        const ref2 = db.collection("modules").doc(moduleId);

        let batch = this.db.batch();

        batch.update(ref1, settings);
        batch.update(ref2, settings);

        batch.commit().catch((error) => { console.log(error); });
    }
}
