import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Image } from 'src/app/models/image';
import { StorageService } from '../storage/storage.service';
import { GalleryFolder } from 'src/app/models/gallery-folder';
import { PathStorage } from 'src/app/paths/path-storage';

@Injectable({
    providedIn: 'root'
})
export class DbGallery {

    constructor(
        private aFirestore: AngularFirestore,
        private storage: StorageService
    ) {

    }

    getImages(moduleId: string, folderId: string, typeOrder: string, onResolve) {
        let db = this.aFirestore.firestore;
        let ref;

        switch (typeOrder) {
            case 'asc'://antigo-recente
                ref = db.collection('modules').doc(moduleId).collection('folders').doc(folderId).collection('images')
                    .orderBy('name', 'asc');
                break;
            case 'desc'://antigo-recente
                ref = db.collection('modules').doc(moduleId).collection('folders').doc(folderId).collection('images')
                    .orderBy('name', 'desc');
                break;
            case 'oldest'://antigo-recente
                ref = db.collection('modules').doc(moduleId).collection('folders').doc(folderId).collection('images')
                    .orderBy('createdAt', 'asc');
                break;

            case 'recent': //recente-antigo
                ref = db.collection('modules').doc(moduleId).collection('folders').doc(folderId).collection('images')
                    .orderBy('createdAt', 'desc');
                break;
        }

        ref.onSnapshot((values) => {
            let images = [];
            if (values.size >= 1) {
                values.forEach(element => {
                    images.push(element.data());
                });
            }
            onResolve(images);
        })
    }

    getFolders(moduleId: string, typeOrder: string, onResolve) {
        let db = this.aFirestore.firestore;
        let ref;

        switch (typeOrder) {
            case 'oldest'://antigo-recente
                ref = db.collection('modules').doc(moduleId).collection('folders')
                    .orderBy('createdAt', 'asc');
                break;

            case 'recent': //recente-antigo
                ref = db.collection('modules').doc(moduleId).collection('folders')
                    .orderBy('createdAt', 'desc');
                break;
            case 'custom': // manul order
                ref = db.collection('modules').doc(moduleId).collection('folders')
                    .orderBy('order');
                break;
        }

        ref
            .onSnapshot((values) => {
                let folders = [];
                if (values.size >= 1) {
                    values.forEach(element => {
                        folders.push(element.data());
                    });
                }
                onResolve(folders);
            });
    }

    getFolder(moduleId: string, folderId: string, onResolve) {
        let db = this.aFirestore.firestore;
        db
            .collection('modules')
            .doc(moduleId)
            .collection('folders')
            .doc(folderId)
            .onSnapshot((value) => {
                onResolve(value.data());
            });
    }

    getModule(moduleId: string, onResolve) {
        let db = this.aFirestore.firestore;
        db
            .collection('modules')
            .doc(moduleId)
            .onSnapshot((value) => {
                onResolve(value.data());
            });
    }

    changeOrderModule(moduleId: string, typeOrder: string, onResolve) {
        let db = this.aFirestore.firestore;
        db.collection("modules").doc(moduleId).update({ typeOrder: typeOrder })
            .then((_) => {
                onResolve(true);
            })
            .catch((__) => {
                onResolve(false);
            });
    }

    changeOrderItems(moduleId: string, folderId: string, typeOrder: string, onResolve) {
        let db = this.aFirestore.firestore;
        db.collection("modules").doc(moduleId).collection('folders').doc(folderId).update({ orderGallery: typeOrder })
            .then((_) => {
                onResolve(true);
            })
            .catch((__) => {
                onResolve(false);
            });
    }

    saveImage(eventId: string, moduleId: string, folderId: string, image: Image, onResolve) {
        let db = this.aFirestore.firestore;
        let ref = db.collection('modules').doc(moduleId).collection('folders').doc(folderId).collection('images').doc();
        let refEvent = db.collection('events').doc(eventId).collection('gallery-images').doc(ref.id);
        let batch = db.batch();

        image.uid = ref.id;
        image = Object.assign({}, image);

        batch.set(ref, image);
        batch.set(refEvent, image);

        batch
            .commit()
            .then((_) => {
                onResolve(true);
            })
            .catch((err) => {
                console.log();
                onResolve(false);
            });
        ref
    }

    // inclui a ID e URL da img de capa de uma folder do app, como campos do document folderId do firestore
    // relaciona um arquivo de img no firebase storage com a respectiva folderId no firestore
    saveFolderImage(eventId: string, moduleId: string, folderId: string, image: Image, onResolve) {
        let db = this.aFirestore.firestore
            .collection('modules')
            .doc(moduleId)
            .collection('folders')
            .doc(folderId);
        
        db.get()
            .then((snapshot) => {
                let imageId = snapshot.data().folderCoverId;
                console.log("id img anterior: ", imageId);

                // se ja existe uma img de capa da pasta no storage, exclui a img antiga
                if (imageId) {
                    this.storage.deleteImg(eventId, moduleId, folderId, imageId, PathStorage.gallery);
                }

                let update = db.update({folderCoverImg: image.url , folderCoverId: image.storageId});

                update.then(() => {
                    console.log("saveImage works");
                    onResolve(true);
                })
                .catch((error) => {
                    console.log("erro na tentativa de salvar a img", error);
                    onResolve(false);
                })
            })
            .catch((error) => {
                console.log("error", error);
            });
    }

    deleteImage(eventId: string, moduleId: string, folderId: string, imageId: string, storageId: string, onResolve) {
        let db = this.aFirestore.firestore;

        db.collection('events').doc(eventId).collection('gallery-images').doc(imageId).delete();

        db
            .collection('modules')
            .doc(moduleId)
            .collection('folders')
            .doc(folderId)
            .collection('images')
            .doc(imageId)
            .delete()
            .then((status) => {
                this.storage.deleteImg(eventId, moduleId, folderId, storageId, PathStorage.gallery);
                onResolve(true);
            })
            .catch((err) => {
                onResolve(false);
            });
    }

    deleteImages(eventId: string, moduleId: string, folderId: string, imageIds: Array<string>, storageIds: Array<string>, onResolve) {
        for (let i = 0; i < imageIds.length; i++) {
            let db = this.aFirestore.firestore;

            db.collection('events').doc(eventId).collection('gallery-images').doc(imageIds[i]).delete();

            db.collection('modules')
                .doc(moduleId)
                .collection('folders')
                .doc(folderId)
                .collection('images')
                .doc(imageIds[i])
                .delete()
                .then((status) => {
                    this.storage.deleteImg(eventId, moduleId, folderId, storageIds[i], PathStorage.gallery);

                    if (i == imageIds.length - 1) {
                        onResolve(true);
                    }
                })
                .catch((err) => {
                    if (i == imageIds.length - 1) {
                        onResolve(false);
                    }
                })
        }
    }

    deleteFolders(eventId: string, moduleId: string, folderId: Array<string>, onResolve) {
        let db = this.aFirestore.firestore;
        let batch = this.aFirestore.firestore.batch();
        let ref = db.collection('modules').doc(moduleId).collection('folders');

        let listOrdersRemove = [];
        for (let i = 0; i < folderId.length; i++) {

            ref.doc(folderId[i]).get().then((data) => {
                let folder = data.data();
                listOrdersRemove.push(folder.order);

                ref
                    .doc(folderId[i])
                    .collection('images')
                    .get()
                    .then((values) => {
                        if (values.size >= 1) {
                            values.forEach(element => {
                                let image = element.data();
                                batch.delete(db.collection('modules').doc(moduleId).collection('folders').doc(folderId[i]).collection('images').doc(image.uid));
                                batch.delete(db.collection('events').doc(eventId).collection('gallery-images').doc(image.uid));

                                if (image.storageId) {
                                    this.storage.deleteImg(eventId, moduleId, folderId[i], image.storageId, PathStorage.gallery);
                                }
                            });
                            if (i == folderId.length - 1) {
                                batch.delete(db.collection('modules').doc(moduleId).collection('folders').doc(folderId[i]));
                                batch
                                    .commit()
                                    .then((_) => {
                                        this.reorderFoldersAfterAllRemoving(moduleId, listOrdersRemove, () => {
                                        });
                                        onResolve(true);
                                    })
                                    .catch(() => {
                                        onResolve(false);
                                    })
                            }
                        }
                        else {
                            batch.delete(db.collection('modules').doc(moduleId).collection('folders').doc(folderId[i]));
                            if (i == folderId.length - 1) {
                                batch
                                    .commit()
                                    .then((_) => {
                                        this.reorderFoldersAfterAllRemoving(moduleId, listOrdersRemove, () => {
                                        });
                                        onResolve(true);
                                    })
                                    .catch(() => {
                                        onResolve(false);
                                    });
                            }
                        }
                    })
                    .catch((err) => {
                        if (i == folderId.length - 1) {
                            onResolve(false);
                        }
                    });
            })
        }
    }

    reorderFoldersAfterAllRemoving(moduleId, listOrders, onResolve) {
        let db = this.aFirestore.firestore;
        let ref = db.collection('modules').doc(moduleId).collection('folders');

        ref.get().then((data) => {
            if (data.size > 0) {
                let listFolders = [];

                data.forEach(element => {
                    listFolders.push(element.data());
                });

                let cont = 0;
                for (let folders of listFolders) {
                    let lessPositions = 0;

                    for (let order of listOrders) {
                        if (folders.order > order) {
                            lessPositions++;
                        }
                    }

                    if (lessPositions > 0) {
                        folders.order = folders.order - lessPositions;
                        ref.doc(folders.uid).update(folders).then(() => {
                            if (cont == listFolders.length - 1) {
                                onResolve(true);
                            }

                            cont++;
                        })
                    } else {
                        if (cont == listFolders.length - 1) {
                            onResolve(true);
                        }

                        cont++;
                    }
                }
            } else {
                onResolve(true);
            }
        })
    }

    createFolder(moduleId: string, folder: GalleryFolder, onResolve) {
        let db = this.aFirestore.firestore;
        let ref = db.collection('modules').doc(moduleId).collection('folders').doc();
        folder.uid = ref.id;
        folder.createdAt = Date.now() / 1000 | 0;
        folder = Object.assign({}, folder);
        folder.name = Object.assign({}, folder.name);

        ref
            .set(folder)
            .then((set) => {
                onResolve(true);
            })
            .catch((err) => {
                onResolve(false);
            });
    }

    editFolder(moduleId: string, folder: GalleryFolder, onResolve) {
        let db = this.aFirestore.firestore;
        let ref = db.collection('modules').doc(moduleId).collection('folders').doc(folder.uid);

        folder.name = Object.assign({}, folder.name);

        ref
            .update({ name: folder.name })
            .then((set) => {
                onResolve(true);
            })
            .catch((err) => {
                onResolve(false);
            });
    }

    // deleteFolder(eventId: string, moduleId: string, folderId: string, onResolve) {
    //     let db = this.aFirestore.firestore;
    //     let ref = db.collection('modules').doc(moduleId).collection('folders').doc(folderId);

    //     ref.get().then((data) => {
    //         let folder = data.data();
    //         let orderRemove = folder.order;

    //         ref
    //             .collection('images')
    //             .get()
    //             .then((values) => {
    //                 let cont = 0;
    //                 if (values.size >= 1) {
    //                     values.forEach(element => {
    //                         let image = element.data();
    //                         ref.collection('images').doc(image.uid).delete().then((ok) => {
    //                             if (cont == values.size) {
    //                                 ref.delete();

    //                                 this.reorderFolders(moduleId, orderRemove, (data) => {
    //                                     onResolve(true);
    //                                 });
    //                             }
    //                             cont++;
    //                         });
    //                     });
    //                 } else {
    //                     ref.delete();
    //                     this.reorderFolders(moduleId, orderRemove, (data) => {
    //                         onResolve(true);
    //                     });
    //                 }
    //             })
    //             .catch((err) => {
    //                 onResolve(false);
    //             });
    //     })
    // }

    reorderFolders(moduleId, orderRemove, onResolve) {
        console.log('reorderFolders')
        let db = this.aFirestore.firestore;
        let refFolders = db.collection('modules').doc(moduleId).collection('folders');

        refFolders.get().then((data) => {
            if (data.size > 0) {
                let listFolders = [];

                data.forEach(element => {
                    listFolders.push(element.data());
                });

                let cont = 0;
                for (let folder of listFolders) {
                    if (folder.order > orderRemove) {
                        folder.order--;

                        refFolders.doc(folder.uid).update(folder).then(() => {
                            if (cont == listFolders.length - 1) {
                                onResolve(true);
                            }

                            cont++;
                        })
                    } else {
                        if (cont == listFolders.length - 1) {
                            onResolve(true);
                        }

                        cont++;
                    }
                }
            } else {
                onResolve(true);
            }
        })

    }

    reorderFoldersList(moduleId: string, foldersList: Array<any>) {
        return new Promise((resolve, reject) => {
            let db = this.aFirestore.firestore;
            let cont = 0;
            let size = foldersList.length;
            foldersList.forEach(el => {
                let folder = el;
                db
                    .collection('modules')
                    .doc(moduleId)
                    .collection('folders')
                    .doc(folder.uid)
                    .update(folder)
                    .then((_) => {
                        cont++;
                        if (cont == size) {
                            resolve(true);
                        }
                    })
                    .catch((error) => {
                        error++;
                        if (error == size) {
                            reject(false);
                        }
                    });
            });
        });
    }

    changeFolderGridFormat(moduleId: string, folderId: string, grid: boolean) {
        let db = this.aFirestore.firestore;
        let ref = db.collection('modules').doc(moduleId).collection('folders').doc(folderId);

        ref.update({ grid: grid });
    }

    allowChangeFolderGridFormat(moduleId: string, folderId: string, allow: boolean) {
        let db = this.aFirestore.firestore;
        let ref = db.collection('modules').doc(moduleId).collection('folders').doc(folderId);

        ref.update({ allowGrid: allow });
    }

    updateModuleFolder(moduleId: string, folderId: string, property: string, value: any){
        let db = this.aFirestore.firestore;
        let ref = db.collection('modules').doc(moduleId).collection('folders').doc(folderId);

        ref.update({ [property]: value });
    }
}