import { Injectable } from "@angular/core";

////////////////////// GLOBAL COMPONENTS \\\\\\\\\\\\\\\\\\\\\\\
import { LoginComponent } from "../login/login.component";

// ///////////////// ADM DASHBOARD \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\
import { DashboardComponent } from "../adm-dashboard/dashboard/dashboard.component";
import { ResumeComponent } from "../adm-dashboard/resume/resume.component";
import { SupergodComponent } from "../adm-dashboard/supergod/supergod.component";
import { SupergodEditComponent } from "../adm-dashboard/supergod/supergod-edit/supergod-edit.component";
import { SupergodCreateComponent } from "../adm-dashboard/supergod/supergod-create/supergod-create.component";
import { EventsB3Component } from "../adm-dashboard/events-b3/events-b3.component";
import { EventsClientComponent } from "../adm-dashboard/events-client/events-client.component";
import { EventClientCreateComponent } from "../adm-dashboard/events-client/event-client-create/event-client-create.component";
import { EventB3CreateComponent } from "../adm-dashboard/events-b3/event-b3-create/event-b3-create.component";
import { EventB3EditComponent } from "../adm-dashboard/events-b3/event-b3-edit/event-b3-edit.component";
import { EventClientEditComponent } from "../adm-dashboard/events-client/event-client-edit/event-client-edit.component";
import { ClientDashboardComponent } from "../client-dashboard/client-dashboard/client-dashboard.component";
import { ClientEventDashboardComponent } from "../client-dashboard/client-event-dashboard/client-event-dashboard.component";
import { ClientEventInfoComponent } from "../client-dashboard/client-event-dashboard/client-event-info/client-event-info.component";
import { ClientAccessPublicComponent } from './../client-dashboard/client-event-dashboard/client-access-public/client-access-public.component';
import { TermsOfUseComponent } from "../adm-dashboard/terms-of-use/terms-of-use.component";
import { PrivacyTermsComponent } from "../adm-dashboard/privacy-terms/privacy-terms.component";
import { LogsComponent } from "../adm-dashboard/logs/logs/logs.component";
import { ProfileComponent } from "../adm-dashboard/profile/profile.component";
import { GodComponent } from "../adm-dashboard/god/god.component";
import { GodCreateComponent } from "../adm-dashboard/god/god-create/god-create.component";
import { GodEditComponent } from "../adm-dashboard/god/god-edit/god-edit.component";
import { ClientComponent } from "../adm-dashboard/client/client.component";
import { ClientEditComponent } from "../adm-dashboard/client/client-edit/client-edit.component";
import { ClientCreateComponent } from "../adm-dashboard/client/client-create/client-create.component";
import { AttendeesComponent } from "../client-dashboard/client-event-dashboard/client-modules/attendees/list/attendees.component";
import { CreateAttendeeComponent } from "../client-dashboard/client-event-dashboard/client-modules/attendees/create/create-attendee.component";
import { EditAttendeeComponent } from "../client-dashboard/client-event-dashboard/client-modules/attendees/edit/edit-attendee.component";
import { SpeakersComponent } from "../client-dashboard/client-event-dashboard/client-modules/speakers/list/speakers.component";
import { CreateSpeakerComponent } from "../client-dashboard/client-event-dashboard/client-modules/speakers/create/create-speaker.component";
import { EditSpeakerComponent } from "../client-dashboard/client-event-dashboard/client-modules/speakers/edit/edit-speaker.component";
import { SchedulesComponent } from "../client-dashboard/client-event-dashboard/client-modules/schedules/list/schedules.component";
import { CreateScheduleComponent } from "../client-dashboard/client-event-dashboard/client-modules/schedules/create/create-schedule.component";
import { EditScheduleComponent } from "../client-dashboard/client-event-dashboard/client-modules/schedules/edit/edit-schedule.component";
import { EmployeesComponent } from "../client-dashboard/employees/employees.component";
import { CreateEmployeeComponent } from "../client-dashboard/create-employee/create-employee.component";
import { EditEmployeeComponent } from "../client-dashboard/edit-employee/edit-employee.component";
import { ClientResumeComponent } from "../client-dashboard/client-resume/client-resume.component";
import { WidgetsComponent } from "../client-dashboard/client-event-dashboard/client-modules/widgets/widgets.component";
import { ManageModulesComponent } from "../client-dashboard/client-event-dashboard/client-modules/manage-modules/manage-modules.component";
import { LocationsComponent } from "../client-dashboard/client-event-dashboard/client-modules/locations/locations.component";
import { ClientProfileComponent } from "../client-dashboard/client-profile/client-profile.component";
import { GroupsComponent } from "../client-dashboard/client-event-dashboard/client-modules/groups/groups.component";
import { SchedulePersonalComponent } from "../client-dashboard/client-event-dashboard/client-modules/schedule-personal/schedule-personal.component";
import { InteractivityComponent } from "../client-dashboard/client-event-dashboard/client-modules/interactivity/interactivity.component";
import { SurveyComponent } from "../client-dashboard/client-event-dashboard/client-modules/interactivity/survey/survey.component";
import { CreateSurveyComponent } from "../client-dashboard/client-event-dashboard/client-modules/interactivity/survey/create-survey/create-survey.component";
import { DocumentsComponent } from "../client-dashboard/client-event-dashboard/client-modules/documents/documents.component";
import { GalleryComponent } from "../client-dashboard/client-event-dashboard/client-modules/gallery/gallery.component";
import { CheckinComponent } from "../client-dashboard/client-event-dashboard/client-modules/checkin/checkin.component";
import { CheckinStatusComponent } from "../client-dashboard/client-event-dashboard/client-modules/checkin/checkin-status/checkin-status.component";
import { RealtimeCheckinComponent } from "../realtime-checkin/realtime-checkin.component";
import { GalleryFolderComponent } from "../client-dashboard/client-event-dashboard/client-modules/gallery/gallery-folder/gallery-folder.component";
import { DocumentsFolderComponent } from "../client-dashboard/client-event-dashboard/client-modules/documents/documents-folder/documents-folder.component";
import { ColorsComponent } from "../client-dashboard/client-event-dashboard/client-design/colors/colors.component";
import { LogoBannerComponent } from "../client-dashboard/client-event-dashboard/client-design/logo-banner/logo-banner.component";
import { NotificationsComponent } from "../client-dashboard/client-event-dashboard/client-modules/notifications/notifications.component";
import { CreateNotificationComponent } from "../client-dashboard/client-event-dashboard/client-modules/notifications/create-notification/create-notification.component";
import { EditSurveyComponent } from "../client-dashboard/client-event-dashboard/client-modules/interactivity/survey/edit-survey/edit-survey.component";
import { SessionFeedbackComponent } from "../client-dashboard/client-event-dashboard/client-modules/interactivity/session-feedback/session-feedback.component";
import { CreateSessionFeedbackComponent } from "../client-dashboard/client-event-dashboard/client-modules/interactivity/session-feedback/create-session-feedback/create-session-feedback.component";
import { EditSessionFeedbackComponent } from "../client-dashboard/client-event-dashboard/client-modules/interactivity/session-feedback/edit-session-feedback/edit-session-feedback.component";
import { FeedNewsComponent } from "../client-dashboard/client-event-dashboard/client-modules/feed-news/feed-news.component";
import { AnalyticsComponent } from "../client-dashboard/client-event-dashboard/client-analytics/analytics.component";
import { MapsComponent } from "../client-dashboard/client-event-dashboard/client-modules/maps/maps.component";
import { RankingComponent } from "../client-dashboard/client-event-dashboard/client-modules/ranking/ranking.component";
import { TermsNPrivacyComponent } from "../client-dashboard/client-event-dashboard/terms-n-privacy/terms-n-privacy.component";
import { InfoboothComponent } from "../client-dashboard/client-event-dashboard/client-modules/infobooth/infobooth.component";
import { CustomPagesComponent } from "../client-dashboard/client-event-dashboard/client-modules/custom-pages/custom-pages.component";
import { CustomPageCreateComponent } from "../client-dashboard/client-event-dashboard/client-modules/custom-pages/custom-page-create/custom-page-create.component";
import { AskQuestionComponent } from "../client-dashboard/client-event-dashboard/client-modules/interactivity/ask-question/ask-question.component";
import { AskQuestionSessionModerateComponent } from "../ask-question-session-moderate/ask-question-session-moderate.component";
import { AskQuestionSessionResultComponent } from "../ask-question-session-result/ask-question-session-result.component";
import { CustomPageEditComponent } from "../client-dashboard/client-event-dashboard/client-modules/custom-pages/custom-page-edit/custom-page-edit.component";
import { QuizComponent } from "../client-dashboard/client-event-dashboard/client-modules/interactivity/quiz/quiz.component";
import { CreateQuizComponent } from "../client-dashboard/client-event-dashboard/client-modules/interactivity/quiz/create-quiz/create-quiz.component";
import { EditQuizComponent } from "../client-dashboard/client-event-dashboard/client-modules/interactivity/quiz/edit-quiz/edit-quiz.component";
import { QuizResultComponent } from "../quiz-result/quiz-result.component";
import { TrainingComponent } from "../client-dashboard/client-event-dashboard/client-modules/interactivity/training/training.component";
import { CreateTrainingComponent } from "../client-dashboard/client-event-dashboard/client-modules/interactivity/training/create-training/create-training.component";
import { EditTrainingComponent } from "../client-dashboard/client-event-dashboard/client-modules/interactivity/training/edit-training/edit-training.component";
import { TrainingResultComponent } from "../training-result/training-result.component";
import { AskQuestionModerateComponent } from "../ask-question-moderate/ask-question-moderate.component";
import { AskQuestionResultComponent } from "../ask-question-result/ask-question-result.component";
import { WordCloudComponent } from "../client-dashboard/client-event-dashboard/client-modules/interactivity/word-cloud/word-cloud.component";
import { WordCloudResultComponent } from "../word-cloud-result/word-cloud-result.component";
import { GamificationComponent } from "../client-dashboard/client-event-dashboard/client-modules/gamification/gamification.component";
import { RealtimeRankingComponent } from "../realtime-ranking/realtime-ranking.component";
import { ContainerListComponent } from "../adm-dashboard/container/container-list/container-list.component";
import { CreateContainerComponent } from "../adm-dashboard/container/create-container/create-container.component";
import { EditContainerComponent } from "../adm-dashboard/container/edit-container/edit-container.component";
import { DefaultContainerComponent } from "../adm-dashboard/container/default-container/default-container.component";
import { ClientContainersComponent } from "../client-dashboard/client-containers/client-containers.component";
import { EditClientContainerComponent } from "../client-dashboard/client-containers/edit-container/edit-client-container.component";
import { CreateClientContainerComponent } from "../client-dashboard/client-containers/create-container/create-client-container.component";
import { GroupDiscussionsComponent } from "../client-dashboard/client-event-dashboard/client-modules/group-discussions/list/group-discussions.component";
import { GroupDiscussionsDetailComponent } from "../client-dashboard/client-event-dashboard/client-modules/group-discussions/detail/group-discussions-detail.component";
import { UsersAnalyticsComponent } from "../client-dashboard/client-event-dashboard/client-analytics/users-analytics/users-analytics.component";
import { VisioAnalyticsComponent } from "../client-dashboard/client-event-dashboard/client-analytics/visio-analytics/visio-analytics.component";
import { DocumentsAnalyticsComponent } from "../client-dashboard/client-event-dashboard/client-analytics/documents-analytics/documents-analytics.component";
import { GalleryAnalyticsComponent } from "../client-dashboard/client-event-dashboard/client-analytics/gallery-analytics/gallery-analytics.component";
import { FeedAnalyticsComponent } from "../client-dashboard/client-event-dashboard/client-analytics/feed-analytics/feed-analytics.component";
import { GeneralAnalyticsComponent } from "../client-dashboard/client-event-dashboard/client-analytics/general-analytics/general-analytics.component";
import { ChatAnalyticsComponent } from "../client-dashboard/client-event-dashboard/client-analytics/chat-analytics/chat-analytics.component";
import { TrackingAnalyticsComponent } from "../client-dashboard/client-event-dashboard/client-analytics/tracking-analytics/tracking-analytics.component";
import { SchedulesAnalyticsComponent } from "../client-dashboard/client-event-dashboard/client-analytics/schedules-analytics/schedules-analytics.component";
import { AttendeesAnalyticsComponent } from "../client-dashboard/client-event-dashboard/client-analytics/attendees-analytics/attendees-analytics.component";
import { SpeakersAnalyticsComponent } from "../client-dashboard/client-event-dashboard/client-analytics/speakers-analytics/speakers-analytics.component";
import { TimeTrackingAnalyticsComponent } from "../client-dashboard/client-event-dashboard/client-analytics/time-tracking-analytics/time-tracking-analytics.component";
import { AppointmentsComponent } from "../client-dashboard/client-event-dashboard/client-modules/appointments/appointments.component";
import { AppointmentsComponentModule } from "../client-dashboard/client-event-dashboard/client-modules/appointments/appointments.module";
import { ClientEventIntegrationsComponent } from "../client-dashboard/client-event-dashboard/client-event-integrations/client-event-integrations.component";
import { CreateEmailComponent } from "../client-dashboard/client-event-dashboard/client-mailing/create/create-email.component";
import { MailingComponent } from "../client-dashboard/client-event-dashboard/client-mailing/list/mailing.component";
import { EditEmailComponent } from "../client-dashboard/client-event-dashboard/client-mailing/edit/edit-email.component";
import { PrizeDrawResultComponent } from "../prize-draw-result/prize-draw-result.component";

@Injectable()
export class PathComponents {
    // global
    static login = LoginComponent;

    // module  adm-dasboard
    static adm_dashboard = DashboardComponent;

    static adm_resume = ResumeComponent;
    static adm_supergods = SupergodComponent;
    static adm_supergod_create = SupergodCreateComponent;
    static adm_supergod_edit = SupergodEditComponent;
    static adm_gods = GodComponent;
    static adm_god_create = GodCreateComponent;
    static adm_god_edit = GodEditComponent;
    static adm_clients = ClientComponent;
    static adm_client_create = ClientCreateComponent;
    static adm_client_edit = ClientEditComponent;
    static adm_events_b3 = EventsB3Component;
    static adm_events_b3_create = EventB3CreateComponent;
    static adm_events_b3_edit = EventB3EditComponent;
    static adm_events_client = EventsClientComponent;
    static adm_events_client_create = EventClientCreateComponent;
    static adm_events_client_edit = EventClientEditComponent;
    static adm_terms_of_use = TermsOfUseComponent;
    static adm_privacy_terms = PrivacyTermsComponent;
    static adm_logs = LogsComponent;
    static adm_profile = ProfileComponent;
    static adm_client_container = ContainerListComponent;
    static adm_client_container_create = CreateContainerComponent;
    static adm_client_container_edit = EditContainerComponent;
    static adm_default_container = DefaultContainerComponent;

    //module client-dashboard
    static client_dashboard = ClientDashboardComponent;
    static client_dashboard_edit_profile = ClientProfileComponent;
    static client_resume = ClientResumeComponent;
    static client_event_dashboard = ClientEventDashboardComponent;
    static client_event_info = ClientEventInfoComponent;
    static client_event_integrations = ClientEventIntegrationsComponent;
    static client_access_public = ClientAccessPublicComponent;
    static client_manage_module = ManageModulesComponent;
    static client_containers = ClientContainersComponent;
    static client_edit_container = EditClientContainerComponent;
    static client_create_container = CreateClientContainerComponent;

    static client_dashboard_employees = EmployeesComponent;
    static client_dashboard_create_employee = CreateEmployeeComponent;
    static client_dashboard_edit_employee = EditEmployeeComponent;

    static client_attendee = AttendeesComponent;
    static client_attendee_create = CreateAttendeeComponent;
    static client_attendee_edit = EditAttendeeComponent;

    static client_speaker = SpeakersComponent;
    static client_speaker_create = CreateSpeakerComponent;
    static client_speaker_edit = EditSpeakerComponent;

    static client_schedule = SchedulesComponent;
    static client_schedule_create = CreateScheduleComponent;
    static client_schedule_edit = EditScheduleComponent;

    static client_schedule_personal = SchedulePersonalComponent;

    static client_widgets = WidgetsComponent;
    static client_locations = LocationsComponent;
    static client_groups = GroupsComponent;
    static client_feed_news = FeedNewsComponent;
    static client_maps = MapsComponent;
    static client_ranking = RankingComponent;
    static client_terms_privacy = TermsNPrivacyComponent;

    // INTERACTIVITY MODULES
    static client_interactivity = InteractivityComponent;

    // SURVEY
    static client_survey = SurveyComponent;
    static client_survey_new = CreateSurveyComponent;
    static client_survey_edit = EditSurveyComponent;

    // QUIZ
    static client_quiz = QuizComponent;
    static client_quiz_new = CreateQuizComponent;
    static client_quiz_edit = EditQuizComponent;
    static result_quiz = QuizResultComponent;

    static client_group_discussion = GroupDiscussionsComponent;
    static client_group_discussion_detail = GroupDiscussionsDetailComponent;

    // PRIZE-DRAW
    static result_prize_draw = PrizeDrawResultComponent;

    //TRAINING
    static client_training = TrainingComponent;
    static client_training_new = CreateTrainingComponent;
    static client_training_edit = EditTrainingComponent;
    static result_training = TrainingResultComponent;

    //SESSION FEEDBACK
    static client_session_feedback = SessionFeedbackComponent;
    static client_session_feedback_new = CreateSessionFeedbackComponent;
    static client_session_feedback_edit = EditSessionFeedbackComponent;

    static client_documents = DocumentsComponent;
    static client_documents_folder = DocumentsFolderComponent;
    static client_gallery = GalleryComponent;
    static client_gallery_folder = GalleryFolderComponent;
    static client_checkin = CheckinComponent;
    static client_checkin_status = CheckinStatusComponent;
    static client_checkin_time = RealtimeCheckinComponent;

    //ASK QUESTION
    static client_ask_question = AskQuestionComponent;
    static client_ask_question_session_moderate = AskQuestionSessionModerateComponent;
    static client_ask_question_session_result = AskQuestionSessionResultComponent;
    static client_ask_question_moderate = AskQuestionModerateComponent;
    static client_ask_question_result = AskQuestionResultComponent;

    // WORD CLOUD
    static client_word_cloud = WordCloudComponent;
    static client_word_cloud_result = WordCloudResultComponent;

    // DESIGN
    static client_design_colors = ColorsComponent;
    static client_design_bannerlogo = LogoBannerComponent;

    static client_notifications = NotificationsComponent;
    static client_create_notifications = CreateNotificationComponent;

    // ANALYTICS
    static client_analytics = AnalyticsComponent;
    static client_attendees_analytics = AttendeesAnalyticsComponent;
    static client_chats_analytics = ChatAnalyticsComponent;
    static client_documents_analytics = DocumentsAnalyticsComponent;
    static client_feed_analytics = FeedAnalyticsComponent;
    static client_gallery_analytics = GalleryAnalyticsComponent;
    static client_general_analytics = GeneralAnalyticsComponent;
    static client_schedules_analytics = SchedulesAnalyticsComponent;
    static client_speakers_analytics = SpeakersAnalyticsComponent;
    static client_tracking_analytics = TrackingAnalyticsComponent;
    static client_users_analytics = UsersAnalyticsComponent;
    static client_visio_analytics = VisioAnalyticsComponent;
    static client_time_tracking = TimeTrackingAnalyticsComponent;

    // infobooths
    static client_infobooth = InfoboothComponent;

    // custom pagess
    static custom_pages = CustomPagesComponent;
    static custom_pages_create = CustomPageCreateComponent;
    static custom_pages_edit = CustomPageEditComponent;

    // gamification
    static gamification = GamificationComponent;

    // realtime ranking
    static rt_ranking = RealtimeRankingComponent;

    // appointments
    static appointments = AppointmentsComponent;
    static appointmentsModule = AppointmentsComponentModule;

    // mailing
    static list_mailing = MailingComponent;
    static create_email = CreateEmailComponent;
    static edit_email = EditEmailComponent;

}
