import { Component, OnInit, ViewChild } from '@angular/core';
import { StorageService } from '../../../../providers/storage/storage.service'
import { DbNewsFeedProvider } from '../../../../providers/database/db-news-feed'
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core'
import { Post } from 'src/app/models/post';
import { AuthService } from 'src/app/providers/auth/auth.service';
import { TypeUser } from 'src/app/enums/typeUser';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { GlobalService } from 'src/app/providers/global/global.service';
import { DbEventsProvider } from 'src/app/providers/database/db.events';

declare let $: any;


@Component({
  selector: 'app-feed-news',
  templateUrl: './feed-news.component.html',
  styleUrls: ['./feed-news.component.scss']
})


export class FeedNewsComponent implements OnInit {
  public module = null

  // get the language of the user.
  public userLanguage: string

  @ViewChild('successSwal') public successSwal: SwalComponent;
  @ViewChild('errorSwal') public errorSwal: SwalComponent;

  modalOpened: string = null;
  eventId: string;
  moduleId: string;

  posts = []
  postRemove = null
  postEdit = null

  formValidationCreatePost: FormGroup
  formValidationEditPost: FormGroup

  public fileCreatePostName: string = null;
  public fileEditPostName: string = null;


  loaderCreatePost: boolean = false
  loaderEditPost: boolean = false
  loader = false

  // module attributes
  isPublic: boolean = false
  isComment: boolean = false
  isNeed_moderate: boolean = false
  social_sharing: boolean = false

  constructor(
    private storage: StorageService,
    private dbFeed: DbNewsFeedProvider,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private translateService: TranslateService,
    private auth: AuthService,
    private global: GlobalService,
    private dbEvent: DbEventsProvider
  ) { }

  ngOnInit() {
    this.eventId = this.route.parent.params['_value']['uid'];
    this.moduleId = this.route.snapshot.params['moduleId'];

    this.fileCreatePostName = this.translateService.instant('global.select_picture')

    this.formValidationCreatePost = this.fb.group({
      fileImg: [null],
      description: [null],
      fixedTop: [false]
    });

    this.formValidationEditPost = this.fb.group({
      fileImg: [null],
      description: [null],
      fixedTop: [false]
    });

    this.loadPosts()
    this.loadModule()
    this.getEvent()
    this.getUserLanguage()
  }

  event: any = null;
  getEvent() {
    this.dbEvent.getEvent(this.eventId, (event: Event) => {
      this.event = event;
    });
  }

  ngDoCheck() { // verifica se houve mudança no parâmetro do idModule
    if (this.moduleId !== this.route.params['_value']['moduleId']) {
      this.moduleId = this.route.params['_value']['moduleId']

      this.fileCreatePostName = this.translateService.instant('global.select_picture')

      this.formValidationCreatePost = this.fb.group({
        fileImg: [null],
        description: [null],
        fixedTop: [false]
      });

      this.formValidationEditPost = this.fb.group({
        fileImg: [null],
        description: [null],
        fixedTop: [false]
      });

      this.loadPosts()
      this.loadModule()
      this.getUserLanguage()
    }
    if ($('.cke_dialog_background_cover').is(':visible')) {
      $(this.modalOpened).modal('hide');
    } else {
      if (this.modalOpened !== null) {
        $(this.modalOpened).modal('show');
      }
    }
  }

  closeModal(modalId: string) {
    this.modalOpened = null;
    $(modalId).modal('toggle');
  }

  // get the language of the user.
  getUserLanguage() {
    this.global.getLanguage((language) => {
      this.userLanguage = language
    })
  }

  loadModule() {
    this.dbFeed.moduleNewsFeed(this.moduleId, (module) => {
      if (typeof module !== 'undefined' && module !== null) {
        this.module = module
        this.isPublic = module.public
        this.isComment = module.comment
        this.isNeed_moderate = module.need_moderate
        this.social_sharing = module.social_sharing
      }
    })
  }

  onChangeModulePublic(value) {
    this.module.public = value
    this.dbFeed.updateModule(this.module)
  }

  onChangeModuleComment(value) {
    this.module.comment = value
    this.dbFeed.updateModule(this.module)
  }

  onChangeModuleNeedModerate(value) {
    this.module.need_moderate = value
    this.dbFeed.updateModule(this.module)
  }

  onChangeModuleSocialSharing(value) {
    this.module.social_sharing = value
    this.dbFeed.updateModule(this.module)
  }

  loadPosts() {
    this.dbFeed.getPostsNewsFeed(this.moduleId, (posts) => {
      this.posts = []
      this.posts = posts
    })
  }

  // remover!!!!!!!!!
  onUploadChangeCreatePost($ev) {
    this.formValidationCreatePost.patchValue({
      fileImg: $ev.srcElement.files[0]
    });

    this.fileCreatePostName = this.formValidationCreatePost.value.fileImg.name
  }

  onUploadChangeEditPost($ev) {
    this.formValidationEditPost.patchValue({
      fileImg: $ev.srcElement.files[0]
    });

    this.fileEditPostName = this.formValidationEditPost.value.fileImg.name
  }

  onChangeFixedTopPost(post, value) {
    post.fixedTop = value
    this.dbFeed.editPost(post, this.eventId)
  }

  onChangeFixedTop(value: boolean) {
    this.formValidationCreatePost.patchValue({
      fixedTop: value
    });
  }

  onChangeEditPostFixedTop(value: boolean) {
    this.formValidationEditPost.patchValue({
      fixedTop: value
    });
  }

  onChangeActivePost(post, value) {
    post.active = value
    this.dbFeed.editPost(post, this.eventId);
  }

  async createPost(form) {
    this.loaderCreatePost = true

    const file = form.fileImg

    // post attributes
    const description = form.description !== null ? form.description.replace(/href="/g, 'class="wysiwyg-link" href="') : ""
    let fixedTop = 1
    let postId = ""

    let img: any = {
      name: "",
      url: ""
    }

    let creator: any = {
      name: "",
      img: "",
      uid: ""
    }


    if (form.fixedTop) {
      fixedTop = 0
    }

    // create uid's post
    postId = this.dbFeed.createIdPost(this.moduleId)

    // create url storage
    if (file !== null && typeof file !== 'undefined') {
      img['url'] = await this.storage.uploadImgNewsFeed(file, postId, this.eventId, this.moduleId)
      img['name'] = this.fileCreatePostName
    }

    // checks the user type
    const data = await this.auth.claimsTypeUser()
    const typeUser = data['type']
    const userId = data['uid']

    // SUPERGOD AND GOD
    if (typeUser === TypeUser.SUPERGOD || typeUser === TypeUser.GOD) {
      // take the name of the event and the logo
      creator = await this.dbFeed.getEventData(this.eventId)

    } else { // CLIENT 
      creator = await this.dbFeed.getClientData(userId)

    }

    if (creator.name == null) creator.name = '';
    if (creator.img == null) creator.img = '';
    if (creator.uid == null) creator.uid = '';
    const post = new Post(postId, this.moduleId, this.eventId, img, description, creator, fixedTop, true)

    this.dbFeed.createPost(post, this.eventId)
      .then((success) => {
        this.loaderCreatePost = false
        this.successSwal.fire()
        this.modalOpened = null;
        $('#newPostFeed').modal('toggle')
        this.clearformValidationCreatePost()
        this.loadPosts()
      })
      .catch((error) => {
        this.loaderCreatePost = false
        this.errorSwal.fire();
      })
  }

  async editPost(form) {
    this.loaderEditPost = true
    // // post attributes
    // img
    const file = form.fileImg

    if (file !== null) {
      this.postEdit.img['url'] = await this.storage.uploadImgNewsFeed(file, this.postEdit.uid, this.eventId, this.moduleId)
      this.postEdit.img['name'] = this.fileEditPostName
    }

    // description
    this.postEdit.description = form.description

    // fixed top
    this.postEdit.fixedTop = form.fixedTop ? 0 : 1

    // edit post
    this.dbFeed.editPost(this.postEdit, this.eventId)
      .then(() => {
        this.loaderEditPost = false
        this.successSwal.fire()
        this.modalOpened = null;
        $('#editPostFeed').modal('toggle')
        this.clearformValidationEditPost()
        this.loadPosts()
      })
      .catch((error) => {
        this.loaderEditPost = false
        this.errorSwal.fire();
      })

  }

  // clean feed creation form
  clearformValidationCreatePost() {
    this.fileCreatePostName = this.translateService.instant('global.select_picture')

    this.formValidationCreatePost.patchValue({
      fileImg: null,
      description: null,
      fixedTop: false
    })
  }

  clearformValidationEditPost() {
    this.fileEditPostName = this.translateService.instant('global.select_picture')

    this.formValidationEditPost.patchValue({
      fileImg: null,
      description: null,
      fixedTop: false
    })
  }

  // receives the post object that will be removed.
  getRemovePost(post) {
    this.postRemove = post
  }

  // remove post
  removePost() {
    this.loader = true

    this.dbFeed.deletePost(this.postRemove, this.eventId);
    this.loadPosts()
    this.postRemove = null

    this.loader = false
  }

  getEditPost(post) {
    this.postEdit = post

    // Gets the file name (image)
    if (this.postEdit.img.name.length > 0) {
      this.fileEditPostName = this.postEdit.img.name
    } else {
      this.fileEditPostName = this.translateService.instant('global.select_picture')
    }

    // fixedTop
    let fixedTop = false
    if (this.postEdit.fixedTop === 0) {
      fixedTop = true
    }


    this.formValidationEditPost.patchValue({
      fileImg: null,
      description: this.postEdit.description,
      fixedTop: fixedTop
    })

  }

}
