import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SweetAlert2LoaderService } from '@sweetalert2/ngx-sweetalert2';
import { Subscription } from 'rxjs';
import { IntegrationsService } from 'src/app/providers/integrations/integrations.service';
import { SymplaIntegrationService } from 'src/app/providers/integrations/sympla-integration.service'
import { IIntegration, IZoom } from 'src/app/shared/interfaces/integrations.interface';
import { SweetAlertOptions } from 'sweetalert2';
import { DbManagerModuleProvider } from 'src/app/providers/database/db-manager-module';
import { DbEventsProvider } from 'src/app/providers/database/db.events';
import * as uuid from 'uuid';
import { DbAttendeesProvider } from 'src/app/providers/database/db-attendees';

@Component({
    selector: 'app-client-event-integrations',
    templateUrl: './client-event-integrations.component.html',
    styleUrls: ['./client-event-integrations.component.scss']
})
export class ClientEventIntegrationsComponent implements OnInit, OnDestroy {
    subscriptions: Subscription[] = [];
    eventId: string = "";
    zoomForm: FormGroup;
    symplaForm: FormGroup;
    integration: IIntegration;

    deleteIndex: number = 0;

    // Swal
    successSwal: SweetAlertOptions = {
        title: this.translate.instant('global.saved_successfully'),
        text: this.translate.instant("global.modals.success_message"),
        icon: "success",
        confirmButtonColor: "#1FC94F",
        confirmButtonText: this.translate.instant("global.modals.btn_ok"),
        showCancelButton: false,
    }
    errorSwal: SweetAlertOptions = {
        title: this.translate.instant('global.modals.error'),
        text: this.translate.instant("global.modals.error_message"),
        icon: "error",
        confirmButtonColor: "#6C757D",
        confirmButtonText: this.translate.instant("global.modals.btn_ok"),
        showCancelButton: false
    }
    alreadyExistSwal: SweetAlertOptions = {
        title: this.translate.instant('global.modals.error'),
        text: this.translate.instant("global.invalid_id"),
        icon: "error",
        confirmButtonColor: "#6C757D",
        confirmButtonText: this.translate.instant("global.modals.btn_ok"),
        showCancelButton: false
    }

    symplaFormBtnSpinner = {
        apiToken: false,
        impAtts: false
    }

    attendeeModules: Array<any> = [];
    eventLanguage: string = '';

    constructor(
        private fb: FormBuilder,
        private SIntegrations: IntegrationsService,
        private SSymplaIntegration: SymplaIntegrationService,
        private dbModules: DbManagerModuleProvider,
        private dbEvents: DbEventsProvider,
        private dbAttendees: DbAttendeesProvider,
        private route: ActivatedRoute,
        private translate: TranslateService,
        private swal: SweetAlert2LoaderService
    ) {
        this.route.params.subscribe((params) => {
            this.eventId = params.uid;
        })

        this.zoomForm = this.fb.group({
            'identifier': ['', Validators.required],
            'apiKey': ['', Validators.required],
            'secret': ['', Validators.required],
            'jwt': ['', Validators.required],
            'email': ['', Validators.compose([Validators.required, Validators.email])]
        });

        this.symplaForm = this.fb.group({
            'apiToken': ['', Validators.required],
            'eventId': ['', Validators.required],
            'attendeeModuleId': ['', Validators.required],
        });
    }

    ngOnInit(): void {
        this.subscriptions.push(this.SIntegrations.getIntegration(this.eventId).subscribe((integration) => {
            this.integration = integration;
        }));

        this.dbModules.getAttendeeModulesByEvent(this.eventId, (modules) => {
            this.attendeeModules = modules;
        });

        this.dbEvents.getEvent(this.eventId, (eventData) => {
            this.eventLanguage = eventData.language;
        })
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub) => sub.unsubscribe());
    }

    /**
     * Check if identifier on zoom integration already exist
     * @param identifier 
     */
    checkAlreadyExist(identifier: string, index: number) {
        return (this.integration.zoom.some((inte, i) => (inte.identifier == identifier && index != i)));
    }

    /**
     * Update zoom integration
     */
    async updateZoomIntegration(index: number) {
        let swal = await this.swal.swal;
        try {
            if (!this.checkAlreadyExist(this.integration.zoom[index].identifier, index)) {
                this.SIntegrations.updateZoomIntegration(this.eventId, this.integration.zoom);
                swal.fire(this.successSwal);
            } else {
                swal.fire(this.alreadyExistSwal);
            }
        } catch (error) {
            swal.fire(this.errorSwal);
        }
    }

    /**
     * Adding new zoom integration
     */
    async addZoomIntegration() {
        let swal = await this.swal.swal;
        try {
            let data: IZoom = this.zoomForm.value;
            if (!this.integration) {
                let newIntegration: IIntegration = {
                    eventId: this.eventId,
                    zoom: [{
                        id: uuid.v4(),
                        ...data
                    }]
                }
                this.SIntegrations.createIntegration(this.eventId, newIntegration);
                swal.fire(this.successSwal);
            } else {
                if (!this.checkAlreadyExist(data.identifier, 99999)) {
                    this.integration.zoom.push({
                        id: uuid.v4(),
                        ...data
                    });
                    this.SIntegrations.updateZoomIntegration(this.eventId, this.integration.zoom);
                    swal.fire(this.successSwal);
                } else {
                    swal.fire(this.alreadyExistSwal);
                }
            }
        } catch (error) {
            swal.fire(this.errorSwal);
        }
    }

    /**
     * Confirm for deletion zoom integration
     * @param index 
     */
    confirmDeleteZoomIntegration(index: number) {
        this.deleteIndex = index;
    }

    /**
     * Delete zoom integration
     * @param index 
     */
    async deleteZoomIntegration() {
        let swal = await this.swal.swal;
        try {
            this.integration.zoom.splice(this.deleteIndex, 1);
            this.SIntegrations.updateZoomIntegration(this.eventId, this.integration.zoom);
            swal.fire(this.successSwal);
        } catch (error) {
            swal.fire(this.errorSwal);
        }
    }

    symplaEvents: Array<any> = [];

    convertLangFormat(lang) {
        switch (lang) {
          case 'pt_BR':
            return 'PtBR'
          case 'en_US':
            return 'EnUS';
          case 'es_ES':
            return 'EsES';
          case 'fr_FR':
            return 'FrFR';
          case 'de_DE':
            return 'DeDE';
        }
    }

    getSymplaEvents() {
        this.symplaFormBtnSpinner.apiToken = true;

        this.SSymplaIntegration.getEvents(
            this.symplaForm.value.apiToken
        ).then((result) => {
            this.symplaEvents = result['data'];
        }).catch((error) => { 
            console.error(error);
            this.swal.swal.then(s => s.fire(this.errorSwal))
        }).finally(() => { this.symplaFormBtnSpinner.apiToken = false; });
    }

    async importSymplaAttendees() {
        this.symplaFormBtnSpinner.impAtts = true;
        const swal = await this.swal.swal;

        const attendeeModule = JSON.parse(this.symplaForm.value.attendeeModuleId);
        this.SSymplaIntegration.importSymplaAttendees(
            this.symplaForm.value.apiToken,
            this.symplaForm.value.eventId,
            this.eventId,
            attendeeModule.uid,
            this.eventLanguage
        ).then((result) => {
            swal.fire(this.successSwal);
        }).catch((error) => { 
            console.error(error);
            swal.fire(this.errorSwal);
        }).finally(() => { this.symplaFormBtnSpinner.impAtts = false; });
    }

    stringfyObj(obj: any) {
        return JSON.stringify(obj);
    }
}
