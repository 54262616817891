import { Injectable } from '@angular/core';

@Injectable()

export class PathIcons {
    static icon_settings = 'settings' //Gerenciar Módulos
    static icon_gavel = 'gavel' //Legal
    static icon_notications = 'notifications' //Notificações
    static icon_map = 'map' //Localização
    static icon_view_agenda = 'view_agenda' //Grupos widgets
    static icon_info = 'info' //Informações
    static icon_people = 'people' //Participantes
    static icon_mic = 'record_voice_over' //palestrantes
    static icon_date_range = 'date_range' //Cronograma
    static icon_calendar_today = 'calendar_today' //Agenda pessoal
    static icon_survey = 'note_alt'
    static icon_quiz = 'pie_chart'
    static icon_training = 'help'
    static icon_session_feedback = 'file_copy'
    static icon_ask_question = 'contact_support' //faca uma pergunta
    static icon_word_cloud = 'cloud'
    static icon_interactivity = 'stars' // interactivity
    static icon_group = 'group_work' // gerenciar grupos
    static icon_document = 'insert_drive_file' // documents
    static icon_gallery = 'collections' // gallery
    static icon_checkin = 'qr_code' // checkin
    static icon_self_checkin = 'qr_code_scanner' // self checkin
    static icon_event = 'info';
    static icon_feed = 'rss_feed'; // Feed de notícias
    static icon_ranking = 'bar_chart';
    static icon_smartphone = 'smartphone';
    static icon_infobooth = 'code';
    static icon_android_phone = 'phone_android';
    static icon_prize_draw = 'redeem';
    static icon_sponsor = 'handshake';
    static icon_stand = 'hail';
}
