import { Module } from "./module";
import { TypeModule } from "src/app/enums/type-module";
import { NameModule } from "../name-module";


export class ModuleInfobooth extends Module {
    selected: boolean;
    typeVision: number;
    access_groups: any; // uid of the group that is allowed access to the module
    visOnlyForLoggedUser: boolean;

    constructor(name: NameModule, icon: string, typeVision:number, eventId: string, order:number, visOnlyForLoggedUser?: boolean) {
        super(name, icon, TypeModule.INFOBOOTH, eventId, order, true, true, true)

        this.selected = false
        this.typeVision = typeVision
        this.access_groups = {}
        this.visOnlyForLoggedUser = visOnlyForLoggedUser;
        if (visOnlyForLoggedUser === undefined) {
            this.visOnlyForLoggedUser = false;
        } else {
            this.visOnlyForLoggedUser = visOnlyForLoggedUser;
        }
    }
}