import { Component, OnInit, NgModule } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { SessionFeedback } from '../../../../../../models/session-feedback';
import { Question } from '../../../../../../models/session-feedback-question'
import { Answer } from '../../../../../../models/session-feedback-answer';
import { Group } from '../../../../../../models/group';
import { Session } from '../../../../../../models/session';
import { Track } from '../../../../../../models/track';
import { TypeSessionFeedback } from '../../../../../../models/type-session-feedback';
import { DbEventsProvider } from 'src/app/providers/database/db.events';
import { DbScheduleProvider } from 'src/app/providers/database/db-schedule';
import { DbGroupsProvider } from 'src/app/providers/database/db-groups';
import { MenuIcons, icons } from '../../../../../../models/menu-icons';
import { PathIcons } from 'src/app/paths/path-icons';
import { DbSessionFeedbackProvider } from 'src/app/providers/database/db-session-feedback';
import { GlobalService } from 'src/app/providers/global/global.service';
import { GetNameSession } from 'src/app/pipes/get-name-session';
import { NameModule } from 'src/app/models/name-module';
import { Languages } from 'src/app/models/languages';
import { FormatedMultiIdiomaService } from 'src/app/providers/formated-multi-idioma/formated-multi-idioma.service';

declare let $: any;

@Component({
  selector: 'app-create-session-feedback',
  templateUrl: './create-session-feedback.component.html',
  styleUrls: ['./create-session-feedback.component.scss']
})

@NgModule({
  declarations: [
    GetNameSession
  ],
})

export class CreateSessionFeedbackComponent implements OnInit {
  term;
  feedbackModuleId: string = null;
  moduleId: string = null
  eventId: string = null;
  eventTimezone: string = null;
  languages: Languages = null //event languages
  activeLanguage: string = null;

  // public maxResponses = false;
  // QuestionMaxResponses = false;
  hideAddQuestion = true;

  public feedbackName: NameModule = new NameModule('', '', '', '', '');
  public changeAnswers: boolean = false;
  public listQuestions: Array<Question> = [];
  public listAnswers: Array<Answer> = [new Answer()];
  public questionTitle: NameModule = new NameModule('', '', '', '', '');
  public questionInfobooth: NameModule = new NameModule('', '', '', '', '');
  public questionPoints: number = null;
  public questionType: string = 'oneSelect';
  // public chartType: string = "";
  public operation: string = "Criar";
  public indexEdit: number = null;
  public searchFor: string = TypeSessionFeedback.AllSessions;
  public totalFeedbacks: number = null;


  // public chartBlockVisibility: boolean = true;
  public answersOptionsBlockVisibility: boolean = true;
  public activeMarker: boolean = false;
  public activeWeight: boolean = false;

  // Errors
  public msgError = false;
  public errorLevantamento = false;
  public errorNoQuestions = false;
  public errorTitle = false;
  public errorSelectQuestion = false;
  public errorOptionAnswer = false;
  // public errorOptionGraphic = false;
  iconsImg: MenuIcons[] = icons;
  selectedIcon: string = PathIcons.icon_session_feedback;
  selectedIconFamily: string = 'material-icons';

  loader: boolean = false;

  public userLanguage: string  // get the language of the user.
  public eventLanguage: string  // get the language of the event.


  // ============================================ TIPO DE LEVANTAMENTO ====================================================

  public feedbackType: string = TypeSessionFeedback.AllSessions;

  SelectedSessionVisibility: boolean = false;
  tracksAttendeeVisibility: boolean = false;
  specificTrackVisibility: boolean = false;
  scheduleModuleVisibility: boolean = false;
  scheduleModuleTrackVisibility: boolean = false;
  scheduleModuleSessionVisibility: boolean = false;
  specificGroupVisibility: boolean = false;

  // tracks
  tracks: Array<Track>;
  public group = []; // array de ids
  public query = ''; // string digitada pelo usuário
  public filteredList = []; // lista de sugestões exibidas
  public selectedTracks = []; // array com os tracks selecionados

  // sessions
  sessions: Array<Session>;
  sessionsNames: Array<any>;
  public session = [];
  public querySession = ''; // string digitada pelo usuário
  public filteredSession = []; // lista de sugestões exibidas
  public selectedSessions = []; // array com os tracks selecionados

  // groups
  groups: Array<Group>;
  groupsNames: Array<any>;
  public ManagerGroup = []; // array de ids
  public queryGroup = ''; // string digitada pelo usuário
  public filteredGroup = []; // lista de sugestões exibidas
  public selectedGroups = []; // array com os tracks selecionados

  scheduleModules: Array<any> = [];
  //====================================== FIM TIPO DE LEVANTAMENTO ==============================================

  imgFile: File;
  imgFileName: string = "";

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private db: DbSessionFeedbackProvider,
    private dbEvent: DbEventsProvider,
    private dbSession: DbScheduleProvider,
    private dbGroup: DbGroupsProvider,
    private global: GlobalService,
    private formatIdioma: FormatedMultiIdiomaService
  ) {
    // get module id
    this.eventId = this.eventId = this.route.pathFromRoot[1]['params']['_value']['uid'];
    this.feedbackModuleId = this.route.parent.params['_value']['moduleId'];
    this.load();
  }

  ngOnInit() {
    $("label.icon-selector").click(function () {
      $("#dropdownMenuButton").dropdown("toggle");
    });

    this.getEvent();
    this.getUserLanguage()
    this.getTotalFeedbacks()
  }

  getTotalFeedbacks() {
    this.db.getTotalFeedbacks(this.feedbackModuleId, (data) => {
      this.totalFeedbacks = data;
    })
  }

  getEvent() {
    this.dbEvent.getEvent(this.eventId, (event) => {
      this.eventTimezone = event.timezone;
      this.eventLanguage = event.language;
      this.activeLanguage = this.formatIdioma.convertLangFormat(event.language);
      this.languages = event.languages;
    })
  }

  // get the language of the user.
  getUserLanguage() {
    this.global.getLanguage((language) => {
      this.userLanguage = language
    })
  }

  changeTypeQuestion() {
    if (this.questionType == 'oneSelect' || this.questionType == 'multipleSelect') {
      this.answersOptionsBlockVisibility = true;

    } else if (this.questionType == 'evaluation') {
      this.answersOptionsBlockVisibility = false;

    } else if (this.questionType == 'dissertative' || this.questionType == 'yesOrNo') {
      this.answersOptionsBlockVisibility = false;
    }
  }

  addAnswerOption() {
    let answer = new Answer();
    this.listAnswers.push(answer);
  }

  removeAnswerOption(index) {
    this.listAnswers.splice(index, 1);
  }

  addQuestion() {
    this.errorTitle = false;
    this.errorSelectQuestion = false;
    // this.errorOptionGraphic = false;
    this.errorOptionAnswer = false;
    this.msgError = false;

    //Caso o usuário não tenha inserido o titulo da pergunta
    let mainLanguage = this.formatIdioma.mainLanguageIsFilled(this.questionTitle, this.eventLanguage);
    this.questionTitle = this.formatIdioma.fillEmptyLanguage(this.questionTitle, this.eventLanguage);
    if (this.questionTitle == undefined || this.questionTitle == null || !mainLanguage) {
      this.msgError = true;
      this.errorTitle = true;
    }

    this.questionInfobooth = this.formatIdioma.fillEmptyLanguage(this.questionInfobooth, this.eventLanguage);
    this.questionInfobooth = this.replaceLinkInfooboth(this.questionInfobooth);

    if (this.questionType == undefined || this.questionType == null || this.questionType == "") {
      this.msgError = true;
      this.errorSelectQuestion = true;
    }

    if (this.questionType == 'oneSelect' || this.questionType == 'multipleSelect') {
      //Caso não tenha adicionado nenhuma resposta possível
      let find = false;

      for (let answer of this.listAnswers) {
        let mainLanguageAnswer = this.formatIdioma.mainLanguageIsFilled(answer.answer, this.eventLanguage);
        answer.answer = this.formatIdioma.fillEmptyLanguage(answer.answer, this.eventLanguage);
        if (answer.answer != undefined && answer.answer != null && mainLanguageAnswer) {
          find = true;
        }
      }

      if (find == false) {
        this.msgError = true;
        this.errorOptionAnswer = true;
      }
    }

    if (this.msgError == false) {
      this.sendQuestion();
    }
  }

  sendQuestion() {
    let question = new Question();
    question.title = this.questionTitle;
    question.infobooth = this.questionInfobooth;
    question.type = this.questionType;
    question.points = this.questionPoints;
    // question.graphic = this.chartType;

    if (question.type == "oneSelect" || question.type == "multipleSelect") {
      let answers: Array<Answer> = [];
      for (let answer of this.listAnswers) {
        if (answer.answer != undefined) {
          answers.push(answer);
        }
      }

      question.answers = answers;
    }

    if (this.operation == "Criar") {
      this.listQuestions.push(question);
    } else if (this.operation == "Editar") {
      this.listQuestions[this.indexEdit] = question;
    }

    this.resetQuestion();
  }

  getEditQuestion(index) {
    this.indexEdit = index;
    this.listAnswers = this.listQuestions[index].answers;
    this.questionTitle = this.listQuestions[index].title;
    this.questionInfobooth = this.listQuestions[index].infobooth;
    this.questionPoints = this.listQuestions[index].points;
    this.questionType = this.listQuestions[index].type;
    // this.chartType = this.listQuestions[index].graphic;
    this.operation = "Editar";
  }


  resetQuestion() {
    this.listAnswers = [new Answer()];
    this.questionTitle = new NameModule('', '', '', '', '');
    this.questionInfobooth = new NameModule('', '', '', '', '');
    this.questionType = "";
    this.questionPoints = null;
    // this.chartType = "";
    this.operation = "Criar";
  }

  removeQuestion(index) {
    this.listQuestions.splice(index, 1);
  }

  setIcon(item) {
    this.selectedIcon = item.icon;
    this.selectedIconFamily = item.family;
    $('.dropdown-menu').removeClass('show');
  }

  uploadImg($event) {
    this.imgFile = $event.srcElement.files[0];
    this.imgFileName = this.imgFile.name;
  }

  createFeedback() {
    let scheduleModuleId = $("#scheduleModuleFeedback").val();
    let trackModuleId = $("#scheduleModuleTrackFeedback").val();
    let sessionModuleId = $("#scheduleModuleSessionFeedback").val();
    let iconFeedback = this.selectedIcon;
    let iconFamilyFeedback = this.selectedIconFamily;
    this.loader = true;

    this.errorLevantamento = false;
    this.errorNoQuestions = false;

    let mainLanguage = this.formatIdioma.mainLanguageIsFilled(this.feedbackName, this.eventLanguage);
    if (this.feedbackName == undefined || this.feedbackName == null || !mainLanguage) {
      this.errorLevantamento = true;
      this.loader = false;
    }
    else {

      this.feedbackName = this.formatIdioma.fillEmptyLanguage(this.feedbackName, this.eventLanguage);

      if (this.listQuestions.length < 1) {
        this.errorNoQuestions = true;
        this.loader = false;
      } else {
        const feedback = new SessionFeedback();
        feedback.title = new NameModule(this.feedbackName.PtBR, this.feedbackName.EnUS, this.feedbackName.EsES, this.feedbackName.FrFR, this.feedbackName.DeDE)
        feedback.imgCapa = ""
        feedback.type = this.searchFor;
        feedback.icon = iconFeedback;
        feedback.iconFamily = iconFamilyFeedback;
        // feedback.max_responses = maxResponses;
        feedback.change_answer = this.changeAnswers;
        feedback.visibility = true;
        feedback.module_id = this.moduleId;
        feedback.question = this.listQuestions;
        feedback.order = this.totalFeedbacks;
        feedback.weight = this.activeWeight;
        feedback.marker = this.activeMarker;
  
        switch (feedback.type) {
          case TypeSessionFeedback.AllSessions:
            this.db.createFeedback(this.imgFile, this.feedbackModuleId, feedback, this.eventId, null, this.eventTimezone, (data) => {
              if (data) {
                this.returnFeedbackList();
              }
  
              this.loader = false;
            })
            break;
  
          case TypeSessionFeedback.ScheduleModule:
            feedback.module_id = scheduleModuleId;
            this.db.createFeedback(this.imgFile, this.feedbackModuleId, feedback, scheduleModuleId, null, this.eventTimezone, (data) => {
              if (data) {
                this.returnFeedbackList();
              }
  
              this.loader = false;
            });
            break;
  
          case TypeSessionFeedback.SessionTrack:
            feedback.module_id = trackModuleId;
            this.db.createFeedback(this.imgFile, this.feedbackModuleId, feedback, trackModuleId, this.selectedTracks, this.eventTimezone, (data) => {
              if (data) {
                this.returnFeedbackList();
              }
  
              this.loader = false;
            });
            break;
  
          case TypeSessionFeedback.SpecificSession:
            feedback.module_id = sessionModuleId;
            this.db.createFeedback(this.imgFile, this.feedbackModuleId, feedback, sessionModuleId, this.selectedSessions, this.eventTimezone, (data) => {
              if (data) {
                this.returnFeedbackList();
              }
  
              this.loader = false;
            });
            break;
  
          case TypeSessionFeedback.SpecificGroup:
            this.db.createFeedback(this.imgFile, this.feedbackModuleId, feedback, null, this.selectedGroups, this.eventTimezone, (data) => {
              if (data) {
                this.returnFeedbackList();
              }
  
              this.loader = false;
            });
          break;
        }
      }
    }
  }

  returnFeedbackList() {
    this.router.navigate([`/event/${this.eventId}/interactivity/session-feedback/${this.feedbackModuleId}`]);
  }


  //======================================== SURVEY TYPE ====================================================

  //caso o quiz seja vinculado as sessões, verifica se será em todas ou em uma especifica e altera o quizType
  changeSessionType() {
    switch (this.searchFor) {
      case 'AllSessions':
        this.feedbackType = TypeSessionFeedback.AllSessions;
        this.SelectedSessionVisibility = false;
        this.tracksAttendeeVisibility = false;
        this.scheduleModuleTrackVisibility = false;
        this.scheduleModuleSessionVisibility = false;
        this.specificTrackVisibility = false;
        this.specificGroupVisibility = false;

        //Esvazia o array de sessões selecionadas posteriormente.
        this.selectedSessions = [];
        break;

      case 'ScheduleModule':
        this.feedbackType = TypeSessionFeedback.ScheduleModule;
        this.SelectedSessionVisibility = false;
        this.tracksAttendeeVisibility = false;
        this.scheduleModuleVisibility = true;
        this.scheduleModuleTrackVisibility = false;
        this.scheduleModuleSessionVisibility = false;
        this.specificTrackVisibility = false;
        this.specificGroupVisibility = false;
        break;

      case 'SessionTrack':
        this.feedbackType = TypeSessionFeedback.SessionTrack;
        this.SelectedSessionVisibility = false;
        this.tracksAttendeeVisibility = false;
        this.scheduleModuleVisibility = false;
        this.scheduleModuleSessionVisibility = false;
        this.scheduleModuleTrackVisibility = true;
        this.specificGroupVisibility = false;
        break;

      case 'SpecificSession':
        this.feedbackType = TypeSessionFeedback.SpecificSession;
        this.SelectedSessionVisibility = false;
        this.tracksAttendeeVisibility = false;
        this.scheduleModuleVisibility = false;
        this.scheduleModuleTrackVisibility = false;
        this.scheduleModuleSessionVisibility = true;
        this.specificTrackVisibility = false;
        this.specificGroupVisibility = false;
        break;

      case 'SpecificGroup':
        this.loadGroups();
        this.feedbackType = TypeSessionFeedback.SpecificGroup;
        this.SelectedSessionVisibility = false;
        this.tracksAttendeeVisibility = false;
        this.scheduleModuleVisibility = false;
        this.scheduleModuleTrackVisibility = false;
        this.scheduleModuleSessionVisibility = false;
        this.specificTrackVisibility = false;
        this.specificGroupVisibility = true;
        break;
    }
  }


  moduleSelectedChange() {
    var track = $("#scheduleModuleTrackFeedback").val();
    var session = $("#scheduleModuleSessionFeedback").val();
    var specificModule = $("#scheduleModuleFeedback").val();

    if (track == undefined || track == null || track == '') {
      this.specificTrackVisibility = false;
    } else {
      this.specificTrackVisibility = true;
      this.moduleId = track;
      this.loadTracks();
    }

    if (session == undefined || session == null || session == '') {
      this.SelectedSessionVisibility = false;
    } else {
      this.SelectedSessionVisibility = true;
      this.moduleId = session;
      this.loadSessions();
    }
  }


  load() {
    this.dbEvent.getModulesSchedule(this.eventId, (data) => {
      this.scheduleModules = data;
    });
  }


  // LOAD ALL TRACKS OF SCHEDULE MODULE 
  private loadTracks() {
    this.dbSession.getModuleTracks(this.moduleId, (tracks) => {
      this.tracks = [];
      this.tracks = tracks;
    })
  }

  // receive group name and return index of the group  to get id group
  findIdTrack(nameGroup: string) {
    const index = this.group.map(function (e) { return e.name; }).indexOf(nameGroup);
    return index;
  }

  // autocomplete
  filter() { //  função usa a query variável para filtrar tracks, então ela armazena o resultado no filteredList.
    if (this.query !== "") {
      this.filteredList = this.tracks.filter(function (el) {
        return el.name.toLowerCase().indexOf(this.query.toLowerCase()) > -1;
      }.bind(this));
    } else {
      this.filteredList = [];
    }
  }

  // atribuir o item selecionado para a variável de consulta, a fim de fazê-lo aparecer na entrada, e para fazer a lista de sugestões de desaparecer
  select(item) {
    if (this.selectedTracks.length > 0) {
      const index = this.selectedTracks.indexOf(item);
      if (index == -1) {
        this.selectedTracks.push(item);
      }
    } else {
      this.selectedTracks.push(item);
    }
    this.query = '';
    this.filteredList = [];
  }

  remove(item) { // remove group from display list
    this.selectedTracks.splice(this.selectedTracks.indexOf(item), 1);
  }



  loadSessions() {
    this.dbSession.getSessionsModule(this.moduleId, (list: Array<Session>) => {
      this.sessions = [];
      this.session = list;

      this.sessionsNames = [];
      for (const session of list) {
        this.sessionsNames.push(new GetNameSession().transform(session, [this.userLanguage, this.eventLanguage]));

      }
    })
  }

  // autocomplete
  filterSession() { //  função usa a query variável para filtrar tracks, então ela armazena o resultado no filteredList.
    if (this.querySession !== "") {
      this.filteredSession = this.sessionsNames.filter(function (el) {
        return el.toLowerCase().indexOf(this.querySession.toLowerCase()) > -1;
      }.bind(this));
    } else {
      this.filteredSession = [];
    }
  }

  // receive session name and return index of the session to get id session
  findIdSession(nameSession: string) {
    let index = -1

    // search session using user language
    switch (this.userLanguage) {
      case ('pt_BR'):
        index = this.session.map(function (e) { return e.name.PtBR; }).indexOf(nameSession);
        break

      case ('en_US'):
        index = this.session.map(function (e) { return e.name.EnUS; }).indexOf(nameSession);
        break

      case ('es_ES'):
        index = this.session.map(function (e) { return e.name.EsES; }).indexOf(nameSession);
        break

      case ('fr_FR'):
        index = this.session.map(function (e) { return e.name.FrFR; }).indexOf(nameSession);
        break

      case ('de_DE'):
        index = this.session.map(function (e) { return e.name.DeDE; }).indexOf(nameSession);
        break
    }

    // search session using principal event language
    if (index <= -1) {
      switch (this.eventLanguage) {
        case ('pt_BR'):
          index = this.session.map(function (e) { return e.name.PtBR; }).indexOf(nameSession);
          break

        case ('en_US'):
          index = this.session.map(function (e) { return e.name.EnUS; }).indexOf(nameSession);
          break

        case ('es_ES'):
          index = this.session.map(function (e) { return e.name.EsES; }).indexOf(nameSession);
          break

        case ('fr_FR'):
          index = this.session.map(function (e) { return e.name.FrFR; }).indexOf(nameSession);
          break

        case ('de_DE'):
          index = this.session.map(function (e) { return e.name.DeDE; }).indexOf(nameSession);
          break
      }
    }
    return index;
  }

  // atribuir o item selecionado para a variável de consulta, a fim de fazê-lo aparecer na entrada, e para fazer a lista de sugestões de desaparecer
  selectSession(item) {
    const index = this.findIdSession(item);

    if (index > -1) {
      this.selectedSessions.push(this.session[index]);
    }

    this.querySession = '';
    this.filteredSession = [];
  }

  removeSession(item) { // remove group from display list
    this.selectedSessions.splice(this.selectedSessions.indexOf(item), 1);
    // this.dao.notAllowGroupCheckin(this.eventId, this.groupId);
  }

  loadGroups() {
    this.dbGroup.searchModulesAndGroups(this.eventId, (response) => {
      this.groups = [];
      this.groups = response['groups'];
    });
  }

  // autocomplete
  filterGroup() { //  função usa a query variável para filtrar tracks, então ela armazena o resultado no filteredList.
    if (this.queryGroup !== "") {
      this.filteredGroup = this.groups.filter(function (el) {
        return el.name.toLowerCase().indexOf(this.queryGroup.toLowerCase()) > -1;
      }.bind(this));
    } else {
      this.filteredGroup = [];
    }
  }

  // receive group name and return index of the group to get id group
  findIdGroup(nameGroup: string) {
    const index = this.group.map(function (e) { return e.name; }).indexOf(nameGroup);
    return index;
  }

  // atribuir o item selecionado para a variável de consulta, a fim de fazê-lo aparecer na entrada, e para fazer a lista de sugestões de desaparecer
  selectGroup(item) {
    if (this.selectedGroups.length > 0) {
      const index = this.selectedGroups.indexOf(item);
      if (index == -1) {
        this.selectedGroups.push(item);
      }
    } else {
      this.selectedGroups.push(item);
    }
    this.queryGroup = '';
    this.filteredGroup = [];
  }

  removeGroup(item) { // remove group from display list
    this.selectedGroups.splice(this.selectedGroups.indexOf(item), 1);
    // this.dao.notAllowGroupCheckin(this.eventId, this.groupId);
  }

  replaceLinkInfooboth(data) {
    data['PtBR'] = data['PtBR'].replace(/href="/g, 'class="wysiwyg-link" href="');
    data['EnUS'] = data['EnUS'].replace(/href="/g, 'class="wysiwyg-link" href="');
    data['EsES'] = data['EsES'].replace(/href="/g, 'class="wysiwyg-link" href="');
    data['FrFR'] = data['FrFR'].replace(/href="/g, 'class="wysiwyg-link" href="');
    data['DeDE'] = data['DeDE'].replace(/href="/g, 'class="wysiwyg-link" href="');

    return data;
  }

  cancel() {
    this.location.back();
  }

}
