import { Answer } from './session-feedback-answer';
import { NameModule } from './name-module';

export class Question {
    uid: string;
    title: NameModule;
    infobooth: NameModule;
    type: string;
    graphic: string;
    points: number;
    answers: Array<Answer> = [];
    createdAt: number;
    visibility: boolean;
    // maxResponses: number;

}