import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from '../../providers/auth/auth.service';
import { PathApi } from '../../paths/path-api';
import { AngularFirestore } from '@angular/fire/firestore';
import { Speaker } from 'src/app/models/speakers';
import firebase from 'firebase/app';

@Injectable({
    providedIn: 'root'
})

export class DbCustomFieldSpeakerProvider {

    public headers;
    public requestOptions;

    constructor(private auth: AuthService, private http: HttpClient, private aFirestore: AngularFirestore) {
        this.headers = new HttpHeaders();
        this.headers.append("Accept", 'application/json');
        this.headers.append("Content-Type", 'application/json');
        this.requestOptions = { headers: this.headers };
    }

}