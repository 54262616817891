import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IAnalyticsState } from '../interfaces/analytics.interfaces';

export const getAnalyticsState = createFeatureSelector<IAnalyticsState>('analytics');

export const getGeneralAnalytics = createSelector(getAnalyticsState, (state: IAnalyticsState) => state.general);

export const getAttendeesAnalytics = createSelector(getAnalyticsState, (state: IAnalyticsState) => state.attendees);

export const getChatsAnalytics = createSelector(getAnalyticsState, (state: IAnalyticsState) => state.chats);

export const getDocumentsAnalytics = createSelector(getAnalyticsState, (state: IAnalyticsState) => state.documents);

export const getFeedsAnalytics = createSelector(getAnalyticsState, (state: IAnalyticsState) => state.feeds);

export const getGalleriesAnalytics = createSelector(getAnalyticsState, (state: IAnalyticsState) => state.galleries);

export const getSchedulesAnalytics = createSelector(getAnalyticsState, (state: IAnalyticsState) => state.schedules);

export const getSpeakersAnalytics = createSelector(getAnalyticsState, (state: IAnalyticsState) => state.speakers);

export const getTrackingAnalytics = createSelector(getAnalyticsState, (state: IAnalyticsState) => state.tracking);
export const getUserRoadTrackingAnalytics = createSelector(getAnalyticsState, (state: IAnalyticsState) => state.tracking.userRoadTracking);

export const getUsersAnalytics = createSelector(getAnalyticsState, (state: IAnalyticsState) => state.users);

export const getVisiosAnalytics = createSelector(getAnalyticsState, (state: IAnalyticsState) => state.visios);