export class ColorsEvent {
    menu_color: string
    menu_text_color: string
    menu_text_color2: string
    active_menu_item: string
    title_color: string
    text_content_color: string
    link_color: string
    btn_text_color: string
    bg_content_color: string
    bg_general_color: string
    img_bg: string

    constructor() {
        // sets the color attributes.
        this.menu_color = '#2973a2'
        this.menu_text_color = '#ffffff'
        this.menu_text_color2 = '#ffffff'
        this.active_menu_item = '#ffffff'
        this.title_color = '#000000'
        this.text_content_color = '#3f3f3f'
        this.link_color = '#4ba4d8'
        this.btn_text_color = '#000000'
        this.bg_content_color = '#ffffff'
        this.bg_general_color = '#f6f6f6'
        this.img_bg = ''
    }
}