import {Question} from './session-feedback-question';
import { NameModule } from './name-module';

export class SessionFeedback {
    title: NameModule;
    imgCapa: string = "";
    type: string;
    // max_responses: number;
    change_answer: boolean;
    visibility: boolean;
    icon: string;
    iconFamily: string;
    uid: string;
    question: Array<Question> = [];
    module_id: String;
    references: any;
    order: number;
    weight: boolean;
    marker: boolean;
}