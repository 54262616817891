import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable, of } from 'rxjs';
import { GroupDiscussion } from 'src/app/models/group-discussion';
import firebase from 'firebase/app'
import * as XLSX from 'xlsx';
import { AngularFireAuth } from '@angular/fire/auth';
import { map, switchMap, take } from 'rxjs/operators';
import { Participant } from 'src/app/models/participant';
import * as moment from 'moment';
import { Group } from 'src/app/models/group';
import { DbGroupsProvider } from '../database/db-groups';

@Injectable({
    providedIn: 'root'
})
export class GroupDiscussionsService {
    groupDiscussionsRef;
    cell_names = [
        'id',
        'title',
        'groupCreator',
        'description',
        'eventId',
        'moduleId',
        'createdAt',
        'participants',
        'visibility',
        'visio',
        'groups',
    ];

    message_cell_names = [
        "id",
        "User id",
        "User name",
        "Message",
        "Send at"
    ]

    constructor(private afs: AngularFirestore, private aAuth: AngularFireAuth,private dbGroups: DbGroupsProvider) {
        this.groupDiscussionsRef = this.afs.collection<GroupDiscussion>(
            'groupDiscussions'
        );
    }

    GroupDiscussions(
        eventId: string,
        moduleId: string
    ): Observable<GroupDiscussion[]> {
        return this.afs
            .collection<GroupDiscussion>('groupDiscussions', (ref) =>
                ref
                    .where('eventId', '==', eventId)
                    .where('moduleId', '==', moduleId)
            )
            .valueChanges({ idField: 'id' });
    }

    sesssionsByEvent(eventId: string) {
        return this.afs
            .collection('events')
            .doc(eventId)
            .collection('sessions')
            .valueChanges({ idField: 'id' });
    }

    groupDiscussion(id: string): Observable<GroupDiscussion> {
        if (id) {
            return this.groupDiscussionsRef.doc(id).valueChanges();
        } else {
            return this.aAuth.user.pipe(
                switchMap((u: firebase.User) =>
                    this.afs
                        .collection('users')
                        .doc(u.uid)
                        .valueChanges()
                        .pipe(
                            map(
                                (u: any) =>
                                    new GroupDiscussion({
                                        uid: u.uid,
                                        name: u.name
                                    })
                            )
                        )
                )
            );
        }
    }

    fetchParticipant(uid: string): Promise<Participant> {
        return this.afs
            .collection('users')
            .doc(uid)
            .valueChanges()
            .pipe(
                map(
                    (u: any) =>
                        new Participant(u.uid, u.name, u.email, u.emailRecovery)
                ),
                take(1)
            )
            .toPromise();
    }

    eventUsers(eventId: string): Observable<Participant[]> {
        return this.afs
            .collection('users', (ref) =>
                ref.where('events', 'array-contains', eventId)
            )
            .valueChanges({ idField: 'id' })
            .pipe(
                map((u: any[]) =>
                    u.map(
                        (u: any) =>
                            new Participant(
                                u.uid,
                                u.name,
                                u.email,
                                u.emailRecovery
                            )
                    )
                )
            );
    }

    add(eventId: string, gd: GroupDiscussion, groups?): Promise<void> {
        const id = this.afs.createId();
        const batch = this.afs.firestore.batch();
        const chatRef = this.afs
            .collection('events')
            .doc(eventId)
            .collection('chats')
            .doc(id).ref;

        gd.groups = groups.map(gr => gr.uid)
        batch.set(this.groupDiscussionsRef.doc(id).ref, {
            ...gd,
            id,
            createdAt: firebase.firestore.FieldValue.serverTimestamp()
        });
        batch.set(chatRef, {
            uid: id,
            group: true,
            visibility: gd.visibility,
            members: gd.participants.reduce((prev: any, p: Participant) => {
                prev[p.uid] = {
                    uid: p.uid
                };

                return prev;
            }, {})
        });

        return batch.commit();
    }

    update(eventId: string, gd: GroupDiscussion, groups?: Array<Group>): Promise<void> {
        const batch = this.afs.firestore.batch();
        const chatRef = this.afs
            .collection('events')
            .doc(eventId)
            .collection('chats')
            .doc(gd.id).ref;

        gd.groups = groups.map(gr => gr.uid)
        batch.update(this.groupDiscussionsRef.doc(gd.id).ref, gd);
        batch.update(chatRef, {
            visibility: gd.visibility,
            groups: groups.map(gr => gr.uid)
        });
        // batch.update(chatRef, {
        //     visibility: gd.visibility,
        //     members: gd.participants.reduce((prev: any, p: Participant) => {
        //         prev[p.uid] = {
        //             uid: p.uid
        //         };

        //         return prev;
        //     }, {})
        // });

        return batch.commit();
    }

    delete(eventId: string, id: string) {
        const batch = this.afs.firestore.batch();
        const chatRef = this.afs
            .collection('events')
            .doc(eventId)
            .collection('chats')
            .doc(id).ref;

        batch.delete(this.groupDiscussionsRef.doc(id).ref);
        batch.delete(chatRef);

        return batch.commit();
    }

    /**
     * Delete all messages for a group conversation
     * @param eventId 
     * @param groupId 
     */
    deleteConvMessages(eventId: string, groupId: string) {
        const batch = this.afs.firestore.batch();

        return (this.afs
            .collection('events')
            .doc(eventId)
            .collection('chats')
            .doc(groupId)
            .collection('messages')
            .get()
            .pipe(
                switchMap((messagesDocs) => {
                    if (messagesDocs.empty) {
                        return (of(false));
                    } else {
                        messagesDocs.docs.forEach((doc) => {
                            batch.delete(doc.ref);
                        })

                        return (of(batch.commit()).pipe(
                            switchMap(() => {
                                return (of(true));
                            })
                        ));
                    }
                })
            )
        )
    }

    enableOrDisable(eventId: string, groupId: string, disabled: boolean) {
        const batch = this.afs.firestore.batch();
        const data = {
            disabled: !disabled
        };
        const chatRef = this.afs
            .collection('events')
            .doc(eventId)
            .collection('chats')
            .doc(groupId).ref;

        batch.update(this.groupDiscussionsRef.doc(groupId).ref, data);
        batch.update(chatRef, data);

        return batch.commit();
    }

    deleteSelected(eventId: string, ids: string[]): Promise<void> {
        const batch = this.afs.firestore.batch();

        ids.map((id) => {
            const chatRef = this.afs
                .collection('events')
                .doc(eventId)
                .collection('chats')
                .doc(id).ref;

            batch.delete(this.groupDiscussionsRef.doc(id).ref);
            batch.delete(chatRef);
        });

        return batch.commit();
    }

    module(moduleId: string): Observable<any> {
        return this.afs.collection('modules').doc(moduleId).valueChanges();
    }

    setSetting(moduleId: string, setting: string, value: boolean) {
        return this.afs
            .collection('modules')
            .doc(moduleId)
            .update({
                [setting]: value
            });
    }

    event(eventId: string): Observable<any> {
        return this.afs.collection('events').doc(eventId).valueChanges();
    }

    async prepareImportation(bstr: string): Promise<any> {
        const wb: XLSX.WorkBook = XLSX.read(bstr, {
            type: 'binary'
        });
        const result = {
            errors: [],
            internalError: null,
            failed: {
                participants: [],
                rows: []
            },
            ready: []
        };

        for (const sheetName of wb.SheetNames) {
            const wsAux: XLSX.WorkSheet = wb.Sheets[sheetName];
            const sheetAuxData: any[] = XLSX.utils.sheet_to_json(wsAux);

            if (sheetAuxData.length > 1000) {
                result.errors.push('global.required_docs_length_inf_1000');

                return result;
            }

            for (const row of sheetAuxData) {
                const id = row.id;
                const title = row.title;
                const groupCreator = row.groupCreator;
                const description = row.description;
                const eventId = row.eventId;
                const moduleId = row.moduleId;
                const createdAt = firebase.firestore.Timestamp.fromMillis(
                    row.createdAt
                );
                const visibility = row.visibility;
                const visio = row.visio;
                const participants: Participant[] = [];
                const groupsIds = row.groups.split(';')

                if (!id) {
                    result.failed.rows.push({
                        data: row,
                        error: 'global.required_id'
                    });

                    continue;
                } else if (!eventId) {
                    result.failed.rows.push({
                        data: row,
                        error: 'com.group_discussions.required_eventId'
                    });

                    continue;
                } else if (!moduleId) {
                    result.failed.rows.push({
                        data: row,
                        error: 'com.group_discussions.required_moduleId'
                    });

                    continue;
                } else if (row.participants) {
                    if (row.participants.split(';').length > 50) {
                        result.failed.rows.push({
                            data: row,
                            error: 'com.group_discussions.participants_limit'
                        });

                        continue;
                    }

                    for (const uid of row.participants.split(';')) {
                        try {
                            const p = await this.fetchParticipant(uid);

                            participants.push(p);
                        } catch (e) {
                            result.failed.participants.push(uid);
                        }
                    }
                }

                const gd: GroupDiscussion = new GroupDiscussion(
                    groupCreator,
                    id,
                    title,
                    description,
                    eventId,
                    moduleId,
                    createdAt,
                    participants.map((p) => {
                        return { ...p };
                    }),
                    visibility,
                    visio
                );

                result.ready.push(gd);
            }

            return result;
        }
    }

    async import(gds: GroupDiscussion[]) {
        const result = {
            success: {
                created: 0,
                updated: 0
            },
            errors: []
        };

        for (const gd of gds) {
            try {
                await this.afs.firestore.runTransaction(async (t) => {
                    const docRef = this.groupDiscussionsRef.doc(gd.id).ref;
                    const doc = await t.get(docRef);

                    await t.set(docRef, { ...gd }, { merge: true });

                    if (doc.exists) {
                        result.success.updated++;
                    } else {
                        result.success.created++;
                    }
                });
            } catch (e) {
                result.errors.push({ id: gd.id, error: e });
            }
        }

        return result;
    }

    exportTemplate() {
        const gd: GroupDiscussion = new GroupDiscussion(
            'CeuApp',
            'myId',
            'title',
            'description',
            'eventId',
            'moduleId',
            firebase.firestore.Timestamp.fromDate(new Date()),
            [
                new Participant('1', 'name1', true),
                new Participant('2', 'name2', false)
            ],
            true,
            false
        );

        return this.export([gd]);
    }

    /**
     * Export all conversation message
     * @param eventId 
     * @param groupId 
     */
    exportConvMessages(eventId: string, groupId: string) {
        return (this.afs
            .collection('events')
            .doc(eventId)
            .collection("chats")
            .doc(groupId)
            .collection("messages")
            .get()
            .pipe(
                map((docs) => {
                    return (docs.docs.map((doc) => {
                        return ({
                            id: doc.id,
                            ...doc.data() as any
                        });
                    }))
                }),
                switchMap((messages) => {
                    const ws_name = "Messages";
                    const rows = messages.map((message) => [
                        message.id,
                        message.from_user,
                        message.send_from_user_name,
                        message.message,
                        moment(message.send_at).format("DD/MM/YYYY - HH:mm:ss")
                    ]);

                    const wb = XLSX.utils.book_new();
                    const ws = XLSX.utils.aoa_to_sheet([this.message_cell_names, ...rows]);

                    XLSX.utils.book_append_sheet(wb, ws, ws_name);
                    XLSX.writeFile(wb, 'group_discussion_messages.xlsx');

                    return (of(true));
                })
            )
        )
    }

    async export(gds: GroupDiscussion[]): Promise<boolean> {
        const ws_name = 'Group Discussions';
        const rows = gds.map((gd: GroupDiscussion) => [
            gd.id,
            gd.title,
            gd.groupCreator,
            gd.description,
            gd.eventId,
            gd.moduleId,
            gd.createdAt.toMillis(),
            gd.participants.map((p: Participant) => p.uid).join(';'),
            gd.visibility,
            gd.visio,
            gd.groups.join(';')
        ]);
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.aoa_to_sheet([this.cell_names, ...rows]);

        XLSX.utils.book_append_sheet(wb, ws, ws_name);
        XLSX.writeFile(wb, 'group_discussions.xlsx');

        return true;
    }
}
