import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { PathApi } from '../../paths/path-api';
import { AngularFirestore } from '@angular/fire/firestore';
import firebase from 'firebase/app';
import { Survey } from 'src/app/models/survey';
import { Question } from 'src/app/models/question';
import { Answer } from 'src/app/models/answer';
import { TypeModule } from 'src/app/enums/type-module';
import { NotifiicationDateService } from '../luxon/notification-date.service';
import { createOfflineCompileUrlResolver } from '@angular/compiler';
import { NameModule } from 'src/app/models/name-module';
import { take } from 'rxjs/operators';



@Injectable({
    providedIn: 'root'
})
export class DbSurveyProvider {
    private db: firebase.firestore.Firestore;
    public headers;
    public requestOptions;

    constructor(private http: HttpClient, private aFirestore: AngularFirestore, private luxon: NotifiicationDateService) {
        this.headers = new HttpHeaders();
        this.headers.append("Accept", 'application/json');
        this.headers.append("Content-Type", 'application/json');
        this.requestOptions = { headers: this.headers, params: {}, body: {} };
    }

    getSurveyModuleAndSurveys(eventId: string, onResolve) {
        let db = this.aFirestore.firestore;
        let moduleId;
        let ref = db.collection('events').doc(eventId).collection('modules');
        let listSurveys = [];
        ref
            .where('type', '==', TypeModule.SURVEY)
            .get()
            .then((snapshot) => {
                if (snapshot.size >= 1) {
                    snapshot.forEach(element => {
                        moduleId = element.data().uid;
                    });

                    let refSurvey = db.collection('modules').doc(moduleId).collection('surveys').orderBy('order', 'asc');

                    refSurvey.get().then((data) => {
                        data.forEach(element => {
                            let survey = element.data();
                            this.getQuestions(moduleId, survey.uid, (questions) => {

                                survey.questions = questions;
                                listSurveys.push(survey);
                            })
                        });

                    })
                }
                onResolve({
                    surveys: listSurveys,
                    moduleId: moduleId
                })
            })
            .catch((e) => {
                onResolve(e);
            })

    }
    getSurveyModule(eventId: string, onResolve) {
        let db = this.aFirestore.firestore;
        let ref = db.collection('events').doc(eventId).collection('modules');

        ref
            .where('type', '==', TypeModule.SURVEY)
            .get()
            .then((snapshot) => {
                let auxModule = [];
                if (snapshot.size >= 1) {
                    snapshot.forEach(element => {
                        auxModule.push(element.data());
                    });
                }
                onResolve(auxModule);
            })
            .catch((e) => {
                onResolve(e);
            })
    }

    getTotalSurveys(moduleId, onResolve) {
        let db = this.aFirestore.firestore;
        let ref = db.collection('modules').doc(moduleId).collection('surveys');
        ref.onSnapshot((data) => {
            onResolve(data.size);
        });
    }

    changeOrder(moduleId, surveys, onResolve) {
        let db = this.aFirestore.firestore;
        let batch = db.batch();

        for (let survey of surveys) {
            let ref = db.collection('modules').doc(moduleId).collection('surveys').doc(survey.uid)
            batch.update(ref, { order: survey.order })
        }

        batch.commit()
            .then(() => {
                onResolve(true)
            })
            .catch((e) => {
                onResolve(false)
            })
    }

    getSurveys(moduleId, onResolve) {
        let db = this.aFirestore.firestore;
        let ref = db.collection('modules').doc(moduleId).collection('surveys').orderBy('order', 'asc');

        ref.onSnapshot((data) => {
            let listSurveys = [];
            data.forEach(element => {
                let survey = element.data();
                if (survey.uid !== undefined) {
                    this.getQuestions(moduleId, survey.uid, (questions) => {
                        survey.questions = questions;
                        listSurveys.push(survey);
                    });
                }
            });

            onResolve(listSurveys)
        });
    }

    getSurvey(moduleId, surveyId, onResolve) {
        let db = this.aFirestore.firestore;
        let ref = db
            .collection('modules')
            .doc(moduleId)
            .collection('surveys')
            .doc(surveyId);

        ref.get()
            .then((data) => {
                let survey = data.data();
                this.getQuestions(moduleId, survey.uid, (questions) => {
                    survey.questions = questions;
                    onResolve(survey);
                })
            })
    }

    getQuestions(moduleId: string, surveyId: string, onResolve) {
        let db = this.aFirestore.firestore;

        let listQuestions: Array<Question> = [];

        let refQuestions = db.collection('modules').doc(moduleId).collection('surveys').doc(surveyId).collection('questions')
            .orderBy('createdAt', 'asc');;

        refQuestions.get()
            .then((data) => {
                data.forEach(element => {
                    let question = this.instantiateQuestion(element.data());
                    listQuestions.push(question);
                });

                onResolve(listQuestions)
            })
    }

    getAnswers(moduleId: string, surveyId: string, questionId: string, onResolve) {
        let db = this.aFirestore.firestore;

        let refQuestions = db.
            collection('modules')
            .doc(moduleId)
            .collection('surveys')
            .doc(surveyId)
            .collection('questions')
            .doc(questionId)
            .collection('answers')
            .orderBy('createdAt', 'asc');;

        refQuestions.get()
            .then((data) => {
                let listAnswers: Array<Answer> = [];
                data.forEach(element => {
                    let answer: Answer = this.instantiateAnswer(element.data());
                    listAnswers.push(answer);
                });

                onResolve(listAnswers);
            })
    }

    instantiateQuestion(data) {
        let question = new Question();

        question.uid = data.uid;
        question.type = data.type;
        question.mainTitle = data.mainTitle;
        question.title = data.title;
        question.mainTitle = data.mainTitle;
        question.points = data.points;
        data.infobooth != undefined ? question.infobooth = data.infobooth : question.infobooth = new NameModule('', '', '', '', '');
        data.graphic != undefined ? question.graphic = data.graphic : question.graphic = null;
        data.visibility != undefined ? question.visibility = data.visibility : question.visibility = true;

        return question;
    }

    instantiateAnswer(data) {
        let answer = new Answer();
        answer.uid = data.uid;
        answer.answer = data.answer;
        data.weight != undefined ? answer.weight = data.weight : answer.weight = null;
        data.marker != undefined ? answer.marker = data.marker : answer.marker = null;

        return answer;
    }

    changeVisibility(moduleId, surveyId, visibility) {
        let db = this.aFirestore.firestore;

        let ref = db
            .collection('modules')
            .doc(moduleId)
            .collection('surveys')
            .doc(surveyId);

        ref.update({
            visibility: visibility
        })
    }

    changeVisibilityQuestion(moduleId, surveyId, questionId, visibility) {
        let db = this.aFirestore.firestore;

        let ref = db
            .collection('modules')
            .doc(moduleId)
            .collection('surveys')
            .doc(surveyId)
            .collection('questions')
            .doc(questionId)

        ref.update({
            visibility: visibility
        }).then(() => {
        })
    }

    createSurvey(moduleId: string, survey: Survey, referenceModuleId: string, selected: any, timezone: string, onResolve) {
        const aux = survey.question;
        delete survey.question;

        let db = this.aFirestore.firestore;

        let ref = db.collection('modules').doc(moduleId).collection('surveys').doc();
        survey.uid = ref.id;
        survey.title = Object.assign({}, survey.title)
        survey = Object.assign({}, survey);
        ref.set(survey)
            .then(() => {
                if (survey.type == 'SpecificSession' || survey.type == 'SessionTrack' || survey.type == 'SpecificGroup') {

                    this.forSurveyReferences(selected, survey.uid, moduleId);
                }

                this.createQuestions(moduleId, survey.uid, aux, timezone, (data) => {
                    if (data == true) {
                        onResolve(data);
                    }
                });
            })
            .catch((error) => {

            })
    }

    forSurveyReferences(ids, surveyId, moduleId) {
        //Limpa referencias anteriores antes de gravar as novas

        let db = this.aFirestore.firestore;
        db.collection('modules').doc(moduleId).collection('surveys').doc(surveyId).update({
            references: firebase.firestore.FieldValue.delete()
        }).then(() => {
            for (const item of ids) {
                db.collection('modules').doc(moduleId).collection('surveys').doc(surveyId).update({
                    references: firebase.firestore.FieldValue.arrayUnion(item.uid)
                })
            }
        })
    }

    /**
   * Create question
   */
    createQuestions(moduleId: string, idSurvey: string, question: Array<Question>, timezone, onResolve) {
        let db = this.aFirestore.firestore;
        let contTime = 0;
        let cont = 0;
        for (let data of question) {
            setTimeout(() => {
                const answers = data.answers;
                delete data.answers;

                let questionId;

                let ref = db.collection('modules').doc(moduleId).collection('surveys').doc(idSurvey).collection('questions')
                data.createdAt = this.luxon.getTimeStampFromDateNow(new Date(), timezone);

                data = Object.assign({}, data);
                data.mainTitle = Object.assign({}, data.mainTitle);
                data.title = Object.assign({}, data.title);
                data.infobooth = Object.assign({}, data.infobooth);

                ref.add(data)
                    .then((docRef) => {
                        questionId = docRef.id;

                        ref.doc(questionId).update({ uid: questionId })
                            .then(() => {
                                if (data.type == 'oneSelect' || data.type == 'multipleSelect') {
                                    this.createAnswers(moduleId, idSurvey, questionId, answers, timezone);
                                }

                                if (cont == question.length - 1) {
                                    onResolve(true);
                                }

                                cont++;
                            })
                    })
            }, contTime * 30);

            contTime++;
        }
    }

    /**
   * Create answer
   */
    createAnswers(moduleId, idSurvey, idQuestion: string, answers: Array<Answer>, timezone) {
        let db = this.aFirestore.firestore;

        let cont = 0;
        for (let data of answers) {
            setTimeout(() => {
                let ref = db.collection('modules')
                    .doc(moduleId)
                    .collection('surveys')
                    .doc(idSurvey)
                    .collection('questions')
                    .doc(idQuestion)
                    .collection('answers');

                data.createdAt = this.luxon.getTimeStampFromDateNow(new Date(), timezone);
                data = Object.assign({}, data);
                data.answer = Object.assign({}, data.answer)

                ref.add(data).then((docRef) => {
                    let answerId = docRef.id;

                    ref.doc(answerId).update({ uid: answerId })
                });
            }, cont * 30);

            cont++;
        }
    }

    deleteAnswer(moduleId, surveyId, questionId, answerId, onResolve) {
        let db = this.aFirestore.firestore;

        let ref = db.collection('modules')
            .doc(moduleId)
            .collection('surveys')
            .doc(surveyId)
            .collection('questions')
            .doc(questionId)
            .collection('answers')
            .doc(answerId);

        ref.delete()
            .then(() => {
                onResolve(true);
            })
            .catch(() => {
                onResolve(false);
            })
    }

    updateSurvey(moduleId, surveyId, survey, onResolve) {

        let db = this.aFirestore.firestore;

        let ref = db.collection('modules')
            .doc(moduleId)
            .collection('surveys')
            .doc(surveyId);

        survey = Object.assign({}, survey);
        survey.title = Object.assign({}, survey.title)
        ref.update(survey).then(() => {
            onResolve(true);
        })
    }

    updateQuestion(moduleId, surveyId, questionId, newQuestion, timezone, onResolve) {
        let db = this.aFirestore.firestore;

        newQuestion.uid = questionId;
        const answers = newQuestion.answers;
        delete newQuestion.answers;

        let ref = db.collection('modules')
            .doc(moduleId)
            .collection('surveys')
            .doc(surveyId)
            .collection('questions')
            .doc(questionId);

        newQuestion = Object.assign({}, newQuestion);
        newQuestion.mainTitle = Object.assign({}, newQuestion.mainTitle);
        newQuestion.title = Object.assign({}, newQuestion.title);
        newQuestion.infobooth = Object.assign({}, newQuestion.infobooth);
        ref.update(newQuestion)
            .then(() => {

                if (newQuestion.type == 'oneSelect' || newQuestion.type == 'multipleSelect') {
                    this.updateAnswer(moduleId, surveyId, questionId, answers, timezone, (data) => {
                        if (data) {
                            onResolve(data);
                        }
                    });
                } else {
                    ref.collection('answers').get()
                        .then((data) => {
                            if (data.size > 0) {
                                let listAnswers = [];
                                data.forEach(element => {
                                    listAnswers.push(element.data());
                                });

                                let contAnswer = 0;
                                for (let answer of listAnswers) {
                                    ref.collection('answers')
                                        .doc(answer.uid)
                                        .delete().then(() => {
                                            if (contAnswer == listAnswers.length - 1) {
                                                onResolve(true);
                                            }

                                            contAnswer++;
                                        })
                                }
                            } else {
                                onResolve(true);
                            }
                        })
                }
            })
            .catch(() => {
                onResolve(false);
            })
    }

    updateAnswer(moduleId: string, surveyId: string, questionId: string, answers: Array<Answer>, timezone, onResolve) {
        let db = this.aFirestore.firestore;

        let ref = db.collection('modules')
            .doc(moduleId)
            .collection('surveys')
            .doc(surveyId)
            .collection('questions')
            .doc(questionId);

        let cont = 0;
        for (let newAnswer of answers) {
            if (newAnswer.weight == undefined) {
                newAnswer.weight = null;
            }

            let auxAnswer = newAnswer;
            let auxAnswerId = newAnswer.uid;
            if (auxAnswerId !== undefined) {

                auxAnswer = Object.assign({}, auxAnswer);
                auxAnswer.answer = Object.assign({}, auxAnswer.answer);
                ref.collection('answers')
                    .doc(auxAnswerId)
                    .update(auxAnswer)
                    .then(() => {
                        if (cont == answers.length - 1) {
                            onResolve(true);
                        }

                        cont++;
                    })
            } else {
                auxAnswer.createdAt = this.luxon.getTimeStampFromDateNow(new Date(), timezone);
                auxAnswer = Object.assign({}, auxAnswer);
                auxAnswer.answer = Object.assign({}, auxAnswer.answer)
                ref.collection('answers')
                    .add(auxAnswer)
                    .then((data) => {

                        let answerId = data.id;
                        ref.collection('answers')
                            .doc(answerId)
                            .update({ uid: answerId })
                            .then(() => {
                                if (cont == answers.length - 1) {
                                    onResolve(true);
                                }

                                cont++;
                            })
                    })
            }
        }
    }

    createOneQuestions(moduleId, surveyId, newQuestion, timezone, onResolve) {
        let db = this.aFirestore.firestore;

        let answers = newQuestion.answers;
        delete newQuestion.answers;

        let ref = db.collection('modules')
            .doc(moduleId)
            .collection('surveys')
            .doc(surveyId)
            .collection('questions');

        newQuestion.createdAt = this.luxon.getTimeStampFromDateNow(new Date(), timezone);
        newQuestion = Object.assign({}, newQuestion);
        newQuestion.mainTitle = Object.assign({}, newQuestion.mainTitle)
        newQuestion.title = Object.assign({}, newQuestion.title);
        newQuestion.infobooth = Object.assign({}, newQuestion.infobooth);
        ref.add(newQuestion)
            .then((data) => {
                let questionId = data.id;

                ref.doc(questionId).update({
                    uid: questionId
                })
                    .then(() => {
                        if (newQuestion.type == 'oneSelect' || newQuestion.type == 'multipleSelect') {
                            this.createAnswers(moduleId, surveyId, questionId, answers, timezone);

                            onResolve({
                                status: true,
                                questionId: questionId
                            });
                        } else {
                            onResolve({
                                status: true,
                                questionId: questionId
                            });
                        }
                    })
            })
            .catch((err) => {
                onResolve(err);
            })
    }

    removeSurvey(moduleId, surveyId, onResolve) {
        this.http.delete(PathApi.baseUrl + PathApi.dbSurveyDeleteSurvey + '?moduleId=' + moduleId + '&surveyId=' + surveyId, this.requestOptions)
            .subscribe((data) => {
                onResolve(data)
            })
    }

    clearSurvey(moduleId: string, surveyId: string) {
        return (this.http.delete(PathApi.baseUrl + PathApi.dbSurveyClearResultSurvey + '?moduleId=' + moduleId + '&surveyId=' + surveyId, this.requestOptions).pipe(
            take(1)
        ))
    }

    clearAllSurveys(moduleId: string, surveysIds: Array<string>) {
        this.requestOptions.body.surveysIds = surveysIds;

        return (this.http.delete(PathApi.baseUrl + PathApi.dbSurveyClearAll + '?moduleId=' + moduleId, this.requestOptions).pipe(
            take(1)
        ));
    }

    removeQuestion(moduleId: string, surveyId: string, questionId: string, onResolve) {
        this.http.delete(PathApi.baseUrl + PathApi.dbSurveyDeleteQuestion + '?moduleId=' + moduleId + '&surveyId=' + surveyId + '&questionId=' + questionId, this.requestOptions)
            .subscribe((data) => {
                onResolve(data)
            },
                (error) => {
                    onResolve(error);
                })
    }

    /**
     * Clear a question
     * @param moduleId 
     * @param surveyId 
     * @param questionId 
     */
    clearQuestion(moduleId: string, surveyId: string, questionId: string) {
        return (this.http.delete(PathApi.baseUrl + PathApi.dbSurveyClearResultQuestion + '?moduleId=' + moduleId + '&surveyId=' + surveyId + '&questionId=' + questionId, this.requestOptions).pipe(take(1)));
    }

    exportSurveys(moduleId: string, surveyId: string, language: string, onResolve) {

        this.http.get(PathApi.baseUrl + PathApi.dbSurveyExportSurvey + '?moduleId=' + moduleId + '&surveyId=' + surveyId + '&language=' + language, this.requestOptions)
            .subscribe((data) => {
                onResolve(data)
            },
                (error) => {
                    onResolve(error);
                })
    }

    exportQuestion(moduleId: string, surveyId: string, questionId: string, language: string, onResolve) {
        this.http.get(PathApi.baseUrl + PathApi.dbSurveyExportQuestionSurvey + '?moduleId=' + moduleId + '&surveyId=' + surveyId + '&questionId=' + questionId + '&language=' + language, this.requestOptions)
            .subscribe((data) => {
                onResolve(data)
            },
                (error) => {
                    onResolve(error);
                })
    }

}