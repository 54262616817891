import { Module } from "./module";
import { TypeModule } from "src/app/enums/type-module";
import { NameModule } from "../name-module";


export class ModuleCustomPage extends Module {
    selected: boolean;
    typeVision: number;
    access_groups
    visOnlyForLoggedUser: boolean;

    constructor(name: NameModule, icon: string, typeVision: number, eventId: string, order: number, visOnlyForLoggedUser?: boolean) {
        super(name, icon, TypeModule.CUSTOM_PAGE, eventId, order, true, true, true)
        this.selected = false
        this.typeVision = typeVision
        this.access_groups = {};
        if (visOnlyForLoggedUser === undefined) {
            this.visOnlyForLoggedUser = false;
        } else {
            this.visOnlyForLoggedUser = visOnlyForLoggedUser;
        }
    }
}