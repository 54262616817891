import { TypeVisionCheckin } from "../enums/type-vision-checkin";

export class Checkin {
    uid: string;
    eventId: string;
    moduleId: string
    name: string;
    visibility: boolean;
    order: number;
    typeOrder: string;
    title: string;
    text: string;
    totalBgColor: string;
    totalTxtColor: string;
    totalPresentBgColor: string;
    totalPresentTxtColor: string;
    allowFooter: boolean;
    allowUsername: boolean;
    typeVisionListing: TypeVisionCheckin
    typeVisionViewer: TypeVisionCheckin
    groups: Object
    viewerGroups: Object

    constructor(name: string, order: number, typeVisionListing: TypeVisionCheckin, typeVisionViewer: TypeVisionCheckin) {
        this.uid = null;
        this.eventId = null;
        this.moduleId
        this.name = name;
        this.visibility = true;
        this.typeOrder = 'asc';
        this.order = order;
        this.title = '';
        this.text = '';
        this.totalBgColor = '#fafafa';
        this.totalTxtColor = '#000000';
        this.totalPresentBgColor = '#1fc94f';
        this.totalPresentTxtColor = '#000000';
        this.allowFooter = true;
        this.allowUsername = true;
        this.typeVisionListing = typeVisionListing;
        this.typeVisionViewer = typeVisionViewer;
        this.groups = {}
        this.viewerGroups = {}

    }
}
