import { Component, OnInit, NgModule } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { Training } from '../../../../../../models/training';
import { Question } from '../../../../../../models/training-question';
import { Answer } from '../../../../../../models/training-answer';
import { Group } from '../../../../../../models/group';
import { Session } from '../../../../../../models/session';
import { Track } from '../../../../../../models/track';
import { TypeTraining } from '../../../../../../models/type-training';
import { DbTrainingProvider } from '../../../../../../providers/database/db-training';
import { DbEventsProvider } from 'src/app/providers/database/db.events';
import { DbScheduleProvider } from 'src/app/providers/database/db-schedule';
import { DbGroupsProvider } from 'src/app/providers/database/db-groups';
import { MenuIcons, icons } from '../../../../../../models/menu-icons';
import { PathIcons } from 'src/app/paths/path-icons';
import { GlobalService } from 'src/app/providers/global/global.service';
import { Languages } from 'src/app/models/languages';
import { NameModule } from 'src/app/models/name-module';
import { GetNameSession } from 'src/app/pipes/get-name-session';
import { FormatedMultiIdiomaService } from 'src/app/providers/formated-multi-idioma/formated-multi-idioma.service';

declare let $: any;

@Component({
  selector: 'app-create-training',
  templateUrl: './create-training.component.html',
  styleUrls: ['./create-training.component.scss']
})

@NgModule({
  declarations: [
    GetNameSession
  ],
})

export class CreateTrainingComponent implements OnInit {

  public userLanguage: string   // get the language of the user.
  public eventLanguage: string   // get the language of the event.

  term;
  trainingModuleId: string = null;
  moduleId: string = null
  eventId: string = null;
  languages: Languages = null //event languages
  activeLanguage: string = null;
  eventTimezone: string = null;
  file: any = null;
  fileName: string = null;

  // public maxResponses = false;
  // QuestionMaxResponses = false;
  hideAddQuestion = true;

  public trainingName: NameModule = new NameModule('', '', '', '', '');

  public changeAnswers: boolean = false;
  public viewAnswered: boolean = false;
  public listQuestions: Array<Question> = [];
  public listAnswers: Array<Answer> = [new Answer()];
  public questionTitle: NameModule = new NameModule('', '', '', '', '');
  public explanationQuestion: NameModule = new NameModule('', '', '', '', '');
  public questionInfobooth: NameModule = new NameModule('', '', '', '', '');
  public questionType: string = 'oneSelect';
  public chartType: string = "";
  public operation: string = "Criar";
  public indexEdit: number = null;
  public searchFor: string = 'Pattern';
  public indexCorrectAnswer: string = null;
  public activeTime: boolean = false;
  public maxTimer: number = null;
  public totalTrainings: number = null;


  public chartBlockVisibility: boolean = true;
  public answersOptionsBlockVisibility: boolean = true;
  public activeMarker: boolean = false;
  public activeWeight: boolean = false;

  // Errors
  public msgError = false;
  public errorLevantamento = false;
  public errorNoQuestions = false;
  public errorTitle = false;
  public errorSelectQuestion = false;
  public errorOptionAnswer = false;
  public errorOptionGraphic = false;
  iconsImg: MenuIcons[] = icons;
  selectedIcon: string = PathIcons.icon_training;
  selectedIconFamily: string = 'material-icons';

  loader: boolean = false;


  // ============================================ TIPO DE LEVANTAMENTO ====================================================

  public trainingType: string = TypeTraining.Pattern;

  trainingLinkedToSession: boolean = false;
  SelectedSessionVisibility: boolean = false;
  tracksAttendeeVisibility: boolean = false;
  specificTrackVisibility: boolean = false;
  scheduleModuleVisibility: boolean = false;
  scheduleModuleTrackVisibility: boolean = false;
  scheduleModuleSessionVisibility: boolean = false;
  specificGroupVisibility: boolean = false;

  // tracks
  tracks: Array<Track>;
  public group = []; // array de ids
  public query = ''; // string digitada pelo usuário
  public filteredList = []; // lista de sugestões exibidas
  public selectedTracks = []; // array com os tracks selecionados

  // sessions
  sessions: Array<Session>;
  sessionsNames: Array<any>;
  public session = [];
  public querySession = ''; // string digitada pelo usuário
  public filteredSession = []; // lista de sugestões exibidas
  public selectedSessions = []; // array com os tracks selecionados

  // groups
  groups: Array<Group>;
  groupsNames: Array<any>;
  public ManagerGroup = []; // array de ids
  public queryGroup = ''; // string digitada pelo usuário
  public filteredGroup = []; // lista de sugestões exibidas
  public selectedGroups = []; // array com os tracks selecionados

  scheduleModules: Array<any> = [];
  //====================================== FIM TIPO DE LEVANTAMENTO ==============================================

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private db: DbTrainingProvider,
    private dbEvent: DbEventsProvider,
    private dbSession: DbScheduleProvider,
    private dbGroup: DbGroupsProvider,
    private global: GlobalService,
    private formatIdioma: FormatedMultiIdiomaService
  ) {
    // get module id
    this.eventId = this.eventId = this.route.pathFromRoot[1]['params']['_value']['uid'];
    this.trainingModuleId = this.route.parent.params['_value']['moduleId'];
    this.load();

  }

  ngOnInit() {
    $("label.icon-selector").click(function () {
      $("#dropdownMenuButton").dropdown("toggle");
    });
    this.getUserLanguage();
    this.getEvent();
    this.getTotalTrainings();
  }

  getTotalTrainings() {
    this.db.getTotalTrainings(this.trainingModuleId, (data) => {
      this.totalTrainings = data;
    })
  }

  getEvent() {
    this.dbEvent.getEvent(this.eventId, (event) => {
      this.eventTimezone = event.timezone;
      this.eventLanguage = event.language;
      this.activeLanguage = this.formatIdioma.convertLangFormat(event.language);
      this.languages = event.languages;
    })
  }

  changeTypeQuestion() {
    if (this.questionType == 'oneSelect' || this.questionType == 'multipleSelect') {
      // this.answersOptionsBlockVisibility = true;
      // this.chartBlockVisibility = true;

    } else if (this.questionType == 'evaluation') {
      // this.answersOptionsBlockVisibility = false;
      // this.chartBlockVisibility = true;

    } else if (this.questionType == 'dissertative' || this.questionType == 'date') {
      // this.answersOptionsBlockVisibility = false;
      // this.chartBlockVisibility = false;
    }
  }

  // get the language of the user.
  getUserLanguage() {
    this.global.getLanguage((language) => {
      this.userLanguage = language
    })
  }

  addAnswerOption() {
    let answer = new Answer();
    this.listAnswers.push(answer);
  }

  removeAnswerOption(index) {
    this.listAnswers.splice(index, 1);
  }

  addQuestion() {
    this.errorTitle = false;
    this.errorSelectQuestion = false;
    this.errorOptionGraphic = false;
    this.errorOptionAnswer = false;
    this.msgError = false;

    //Caso o usuário não tenha inserido o titulo da pergunta
    let mainLanguage = this.formatIdioma.mainLanguageIsFilled(this.questionTitle, this.eventLanguage);
    this.questionTitle = this.formatIdioma.fillEmptyLanguage(this.questionTitle, this.eventLanguage);
    if (this.questionTitle == undefined || this.questionTitle == null || !mainLanguage) {
      this.msgError = true;
      this.errorTitle = true;
    }

    this.explanationQuestion = this.formatIdioma.fillEmptyLanguage(this.explanationQuestion, this.eventLanguage);
    this.questionInfobooth = this.formatIdioma.fillEmptyLanguage(this.questionInfobooth, this.eventLanguage);
    this.questionInfobooth = this.replaceLinkInfooboth(this.questionInfobooth);

    if (this.questionType == undefined || this.questionType == null || this.questionType == "") {
      this.msgError = true;
      this.errorSelectQuestion = true;
    }

    if (this.questionType == 'oneSelect' || this.questionType == 'multipleSelect') {
      //Caso não tenha adicionado nenhuma resposta possível
      let find = false;

      for (let answer of this.listAnswers) {
        let mainLanguageAnswer = this.formatIdioma.mainLanguageIsFilled(answer.answer, this.eventLanguage);
        answer.answer = this.formatIdioma.fillEmptyLanguage(answer.answer, this.eventLanguage);
        if (answer.answer != undefined && answer.answer != null && mainLanguageAnswer) {
          find = true;
        }
      }

      if (find == false) {
        this.msgError = true;
        this.errorOptionAnswer = true;
      }

      if (this.chartType == undefined || this.chartType == null || this.chartType == "") {
        this.msgError = true;
        this.errorOptionGraphic = true;
      }
    }

    if (this.questionType == "evaluation") {
      if (this.chartType == undefined || this.chartType == null || this.chartType == "") {
        this.msgError = true;
        this.errorOptionGraphic = true;
      }
    }

    if (this.msgError == false) {
      this.sendQuestion();
    }
  }

  sendQuestion() {
    let question = new Question();
    question.title = this.questionTitle;
    question.explanation = this.explanationQuestion;
    question.infobooth = this.questionInfobooth;
    question.type = this.questionType;
    question.graphic = this.chartType;
    question.image = this.file;

    if (question.type == "oneSelect" || question.type == "multipleSelect") {
      let answers: Array<Answer> = [];
      for (let answer of this.listAnswers) {
        if (answer.answer != undefined) {
          if (answer.correct == undefined) {
            answer.correct = false;
          }

          // answer.answer = Object.assign({}, answer.answer);
          answers.push(answer);
        }
      }

      question.answers = answers;
    }

    if (this.operation == "Criar") {
      this.listQuestions.push(question);
    } else if (this.operation == "Editar") {
      // question.title = Object.assign({}, question.title)
      this.listQuestions[this.indexEdit] = question;
    }

    this.resetQuestion();
  }

  getEditQuestion(index) {
    this.indexEdit = index;
    this.listAnswers = this.listQuestions[index].answers;
    this.questionTitle = this.listQuestions[index].title;
    this.explanationQuestion = this.listQuestions[index].explanation;
    this.questionInfobooth = this.listQuestions[index].infobooth;
    this.questionType = this.listQuestions[index].type;
    this.chartType = this.listQuestions[index].graphic;
    this.operation = "Editar";

    if (this.questionType == 'oneSelect') {
      for (let i = 0; i < this.listAnswers.length; i++) {
        if (this.listAnswers[i].correct == true) {
          this.indexCorrectAnswer = i.toString();
        }
      }
    }
  }


  resetQuestion() {
    this.listAnswers = [new Answer()];
    this.explanationQuestion = new NameModule('', '', '', '', '')
    this.questionTitle = new NameModule('', '', '', '', '');
    this.questionInfobooth = new NameModule('', '', '', '', '');
    this.questionType = "";
    this.chartType = "";
    this.listAnswers[0].correct = false;
    this.operation = "Criar";
    this.indexCorrectAnswer = null;
    this.file = null;
    this.fileName = null;
  }

  removeQuestion(index) {
    this.listQuestions.splice(index, 1);
  }

  setCorrect() {
    let index = Number(this.indexCorrectAnswer);

    for (let i = 0; i < this.listAnswers.length; i++) {
      if (i != index) {
        this.listAnswers[i].correct = false;
      } else {
        this.listAnswers[i].correct = true;
      }
    }
  }

  onUploadChange($ev) {
    this.file = $ev.srcElement.files[0];
    this.fileName = this.file.name;
  }

  setIcon(item) {
    this.selectedIcon = item.icon;
    this.selectedIconFamily = item.family;
    $('.dropdown-menu').removeClass('show');
  }

  createTraining() {
    let scheduleModuleId = $("#scheduleModuleTraining").val();
    let trackModuleId = $("#scheduleModuleTrackTraining").val();
    let sessionModuleId = $("#scheduleModuleSessionTraining").val();
    let iconTraining = this.selectedIcon;
    let iconFamilyTraining = this.selectedIconFamily;
    this.loader = true;

    this.errorLevantamento = false;
    this.errorNoQuestions = false;

    let mainLanguage = this.formatIdioma.mainLanguageIsFilled(this.trainingName, this.eventLanguage);
    if (this.trainingName == undefined || this.trainingName == null || !mainLanguage) {
      this.errorLevantamento = true;
      this.loader = false;
    }
    else {

      this.trainingName = this.formatIdioma.fillEmptyLanguage(this.trainingName, this.eventLanguage);

      if (this.listQuestions.length < 1) {
        this.errorNoQuestions = true;
        this.loader = false;
      } else {
        const training = new Training();
        training.title = new NameModule(this.trainingName.PtBR, this.trainingName.EnUS, this.trainingName.EsES, this.trainingName.FrFR, this.trainingName.DeDE);
        training.type = this.searchFor;
        training.icon = iconTraining;
        training.iconFamily = iconFamilyTraining;
        // training.max_responses = maxResponses;
        training.change_answer = this.changeAnswers;
        training.view_answered = this.viewAnswered;
        training.active_timer = this.activeTime;
        training.timer_questions = this.maxTimer;
        training.visibility = true;
        training.module_id = this.moduleId;
        training.question = this.listQuestions;
        training.order = this.totalTrainings;
        training.weight = this.activeWeight;
        training.marker = this.activeMarker;

        switch (training.type) {
          case TypeTraining.Pattern:
            this.db.createTraining(this.trainingModuleId, training, "", null, this.eventTimezone, this.eventId, (data) => {
              if (data) {
                this.returnTrainingList();
              }

              this.loader = false;
            });
            break;

          case TypeTraining.AllSessions:
            this.db.createTraining(this.trainingModuleId, training, "", null, this.eventTimezone, this.eventId, (data) => {
              if (data) {
                this.returnTrainingList();
              }

              this.loader = false;
            })
            break;

          case TypeTraining.ScheduleModule:
            training.module_id = scheduleModuleId;
            this.db.createTraining(this.trainingModuleId, training, scheduleModuleId, null, this.eventTimezone, this.eventId, (data) => {
              if (data) {
                this.returnTrainingList();
              }

              this.loader = false;
            });
            break;

          case TypeTraining.SessionTrack:
            training.module_id = trackModuleId;
            this.db.createTraining(this.trainingModuleId, training, trackModuleId, this.selectedTracks, this.eventTimezone, this.eventId, (data) => {
              if (data) {
                this.returnTrainingList();
              }

              this.loader = false;
            });
            break;

          case TypeTraining.SpecificSession:
            training.module_id = sessionModuleId;
            this.db.createTraining(this.trainingModuleId, training, sessionModuleId, this.selectedSessions, this.eventTimezone, this.eventId, (data) => {
              if (data) {
                this.returnTrainingList();
              }

              this.loader = false;
            });
            break;

          case TypeTraining.SpecificGroup:
            this.db.createTraining(this.trainingModuleId, training, null, this.selectedGroups, this.eventTimezone, this.eventId, (data) => {
              if (data) {
                this.returnTrainingList();
              }

              this.loader = false;
            });
            break;
        }
      }
    }
  }

  replaceLinkInfooboth(data) {
    data['PtBR'] = data['PtBR'].replace(/href="/g, 'class="wysiwyg-link" href="');
    data['EnUS'] = data['EnUS'].replace(/href="/g, 'class="wysiwyg-link" href="');
    data['EsES'] = data['EsES'].replace(/href="/g, 'class="wysiwyg-link" href="');
    data['FrFR'] = data['FrFR'].replace(/href="/g, 'class="wysiwyg-link" href="');
    data['DeDE'] = data['DeDE'].replace(/href="/g, 'class="wysiwyg-link" href="');

    return data;
  }

  returnTrainingList() {
    this.router.navigate([`/event/${this.eventId}/interactivity/training/${this.trainingModuleId}`]);
  }


  //======================================== SURVEY TYPE ====================================================

  //verifica se o training será vinculado ou não a uma sessão
  changeTypeTraining() {
    if ($("#trainingLinkedToSession").is(":checked")) {
      this.trainingLinkedToSession = true;

      //caso o training sejá vinculado a uma sessão, automaticamente o valor inicial se torna todas as sessões
      this.trainingType = TypeTraining.AllSessions;
    } else {
      this.trainingLinkedToSession = false;
      this.selectedSessions = [];
      this.trainingType = TypeTraining.Pattern;

      this.SelectedSessionVisibility = false;
      this.tracksAttendeeVisibility = false;
      this.scheduleModuleTrackVisibility = false;
      this.scheduleModuleSessionVisibility = false;
      this.specificTrackVisibility = false;
      this.specificGroupVisibility = false;
    }
  }


  //caso o training seja vinculado as sessões, verifica se será em todas ou em uma especifica e altera o trainingType
  changeSessionType() {
    switch (this.searchFor) {
      case 'AllSessions':
        this.trainingType = TypeTraining.AllSessions;
        this.SelectedSessionVisibility = false;
        this.tracksAttendeeVisibility = false;
        this.scheduleModuleTrackVisibility = false;
        this.scheduleModuleSessionVisibility = false;
        this.specificTrackVisibility = false;
        this.specificGroupVisibility = false;

        //Esvazia o array de sessões selecionadas posteriormente.
        this.selectedSessions = [];
        break;

      case 'ScheduleModule':
        this.trainingType = TypeTraining.ScheduleModule;
        this.SelectedSessionVisibility = false;
        this.tracksAttendeeVisibility = false;
        this.scheduleModuleVisibility = true;
        this.scheduleModuleTrackVisibility = false;
        this.scheduleModuleSessionVisibility = false;
        this.specificTrackVisibility = false;
        this.specificGroupVisibility = false;
        break;

      case 'SessionTrack':
        this.trainingType = TypeTraining.SessionTrack;
        this.SelectedSessionVisibility = false;
        this.tracksAttendeeVisibility = false;
        this.scheduleModuleVisibility = false;
        this.scheduleModuleSessionVisibility = false;
        this.scheduleModuleTrackVisibility = true;
        this.specificGroupVisibility = false;
        break;

      case 'SpecificSession':
        this.trainingType = TypeTraining.SpecificSession;
        this.SelectedSessionVisibility = false;
        this.tracksAttendeeVisibility = false;
        this.scheduleModuleVisibility = false;
        this.scheduleModuleTrackVisibility = false;
        this.scheduleModuleSessionVisibility = true;
        this.specificTrackVisibility = false;
        this.specificGroupVisibility = false;
        break;

      case 'SpecificGroup':
        this.loadGroups();
        this.trainingType = TypeTraining.SpecificGroup;
        this.SelectedSessionVisibility = false;
        this.tracksAttendeeVisibility = false;
        this.scheduleModuleVisibility = false;
        this.scheduleModuleTrackVisibility = false;
        this.scheduleModuleSessionVisibility = false;
        this.specificTrackVisibility = false;
        this.specificGroupVisibility = true;
        break;
    }
  }


  moduleSelectedChange() {
    var track = $("#scheduleModuleTrackTraining").val();
    var session = $("#scheduleModuleSessionTraining").val();
    var specificModule = $("#scheduleModuleTraining").val();

    if (track == undefined || track == null || track == '') {
      this.specificTrackVisibility = false;
    } else {
      this.specificTrackVisibility = true;
      this.moduleId = track;
      this.loadTracks();
    }

    if (session == undefined || session == null || session == '') {
      this.SelectedSessionVisibility = false;
    } else {
      this.SelectedSessionVisibility = true;
      this.moduleId = session;
      this.loadSessions();
    }
  }


  load() {
    this.dbEvent.getModulesSchedule(this.eventId, (data) => {
      this.scheduleModules = data;
    });
  }


  // LOAD ALL TRACKS OF SCHEDULE MODULE 
  private loadTracks() {
    this.dbSession.getModuleTracks(this.moduleId, (tracks) => {
      this.tracks = [];
      this.tracks = tracks;
    })
  }

  // receive group name and return index of the group  to get id group
  findIdTrack(nameGroup: string) {
    const index = this.group.map(function (e) { return e.name; }).indexOf(nameGroup);
    return index;
  }

  // autocomplete
  filter() { //  função usa a query variável para filtrar tracks, então ela armazena o resultado no filteredList.
    if (this.query !== "") {
      this.filteredList = this.tracks.filter(function (el) {
        return el.name.toLowerCase().indexOf(this.query.toLowerCase()) > -1;
      }.bind(this));
    } else {
      this.filteredList = [];
    }
  }

  // atribuir o item selecionado para a variável de consulta, a fim de fazê-lo aparecer na entrada, e para fazer a lista de sugestões de desaparecer
  select(item) {
    if (this.selectedTracks.length > 0) {
      const index = this.selectedTracks.indexOf(item);
      if (index == -1) {
        this.selectedTracks.push(item);
      }
    } else {
      this.selectedTracks.push(item);
    }
    this.query = '';
    this.filteredList = [];
  }

  remove(item) { // remove group from display list
    this.selectedTracks.splice(this.selectedTracks.indexOf(item), 1);
  }



  loadSessions() {
    this.dbSession.getSessionsModule(this.moduleId, (list: Array<Session>) => {
      this.sessions = [];
      this.session = list;

      this.sessionsNames = [];
      for (const session of list) {
        this.sessionsNames.push(new GetNameSession().transform(session, [this.userLanguage, this.eventLanguage]));
      }
    })
  }

  // autocomplete
  filterSession() { //  função usa a query variável para filtrar tracks, então ela armazena o resultado no filteredList.
    if (this.querySession !== "") {
      this.filteredSession = this.sessionsNames.filter(function (el) {
        return el.toLowerCase().indexOf(this.querySession.toLowerCase()) > -1;
      }.bind(this));
    } else {
      this.filteredSession = [];
    }
  }

  // receive session name and return index of the session to get id session
  findIdSession(nameSession: string) {
    let index = -1

    // search session using user language
    switch (this.userLanguage) {
      case ('pt_BR'):
        index = this.session.map(function (e) { return e.name.PtBR; }).indexOf(nameSession);
        break

      case ('en_US'):
        index = this.session.map(function (e) { return e.name.EnUS; }).indexOf(nameSession);
        break

      case ('es_ES'):
        index = this.session.map(function (e) { return e.name.EsES; }).indexOf(nameSession);
        break

      case ('fr_FR'):
        index = this.session.map(function (e) { return e.name.FrFR; }).indexOf(nameSession);
        break

      case ('de_DE'):
        index = this.session.map(function (e) { return e.name.DeDE; }).indexOf(nameSession);
        break
    }

    // search session using principal event language
    if (index <= -1) {
      switch (this.eventLanguage) {
        case ('pt_BR'):
          index = this.session.map(function (e) { return e.name.PtBR; }).indexOf(nameSession);
          break

        case ('en_US'):
          index = this.session.map(function (e) { return e.name.EnUS; }).indexOf(nameSession);
          break

        case ('es_ES'):
          index = this.session.map(function (e) { return e.name.EsES; }).indexOf(nameSession);
          break

        case ('fr_FR'):
          index = this.session.map(function (e) { return e.name.FrFR; }).indexOf(nameSession);
          break

        case ('de_DE'):
          index = this.session.map(function (e) { return e.name.DeDE; }).indexOf(nameSession);
          break
      }
    }

    return index;
  }

  // atribuir o item selecionado para a variável de consulta, a fim de fazê-lo aparecer na entrada, e para fazer a lista de sugestões de desaparecer
  selectSession(item) {
    const index = this.findIdSession(item);
    if (index > -1) {
      this.selectedSessions.push(this.session[index]);
    }
    this.querySession = '';
    this.filteredSession = [];
  }

  removeSession(item) { // remove group from display list
    this.selectedSessions.splice(this.selectedSessions.indexOf(item), 1);
    // this.dao.notAllowGroupCheckin(this.eventId, this.groupId);
  }

  loadGroups() {
    this.dbGroup.searchModulesAndGroups(this.eventId, (response) => {
      this.groups = [];
      this.groups = response['groups'];
    });
  }

  // autocomplete
  filterGroup() { //  função usa a query variável para filtrar tracks, então ela armazena o resultado no filteredList.
    if (this.queryGroup !== "") {
      this.filteredGroup = this.groups.filter(function (el) {
        return el.name.toLowerCase().indexOf(this.queryGroup.toLowerCase()) > -1;
      }.bind(this));
    } else {
      this.filteredGroup = [];
    }
  }

  // receive group name and return index of the group to get id group
  findIdGroup(nameGroup: string) {
    const index = this.group.map(function (e) { return e.name; }).indexOf(nameGroup);
    return index;
  }

  // atribuir o item selecionado para a variável de consulta, a fim de fazê-lo aparecer na entrada, e para fazer a lista de sugestões de desaparecer
  selectGroup(item) {
    if (this.selectedGroups.length > 0) {
      const index = this.selectedGroups.indexOf(item);
      if (index == -1) {
        this.selectedGroups.push(item);
      }
    } else {
      this.selectedGroups.push(item);
    }
    this.queryGroup = '';
    this.filteredGroup = [];
  }

  removeGroup(item) { // remove group from display list
    this.selectedGroups.splice(this.selectedGroups.indexOf(item), 1);
    // this.dao.notAllowGroupCheckin(this.eventId, this.groupId);
  }


  cancel() {
    this.location.back();
  }

}
