import { PathIcons } from "../paths/path-icons";

export class MenuIcons {
    public icon: string;
    public family: string;
}

export const icons: MenuIcons[] = [
    { icon: PathIcons.icon_settings, family: 'material-icons' },
    { icon: PathIcons.icon_gavel, family: 'material-icons' },
    { icon: PathIcons.icon_notications, family: 'material-icons' },
    { icon: PathIcons.icon_map, family: 'material-icons' },
    { icon: PathIcons.icon_view_agenda, family: 'material-icons' },
    { icon: PathIcons.icon_info, family: 'material-icons' },
    { icon: PathIcons.icon_people, family: 'material-icons' },
    { icon: PathIcons.icon_mic, family: 'material-icons' },
    { icon: PathIcons.icon_date_range, family: 'material-icons' },
    { icon: PathIcons.icon_calendar_today, family: 'material-icons' },
    { icon: PathIcons.icon_survey, family: 'material-icons' },
    { icon: PathIcons.icon_group, family: 'material-icons' },
    { icon: PathIcons.icon_document, family: 'material-icons' },
    { icon: PathIcons.icon_gallery, family: 'material-icons' },
    { icon: PathIcons.icon_checkin, family: 'material-icons' },
    { icon: PathIcons.icon_self_checkin, family: 'material-icons' },
    { icon: PathIcons.icon_event, family: 'material-icons' },
    { icon: PathIcons.icon_feed, family: 'material-icons' },
    { icon: '', family: 'b3-icon-01' },
    { icon: '', family: 'b3-icon-02' },
    { icon: '', family: 'b3-icon-03' },
    { icon: '', family: 'b3-icon-04' },
    { icon: '', family: 'b3-icon-05' },
    { icon: '', family: 'b3-icon-06' },
    { icon: '', family: 'b3-icon-07' },
    { icon: '', family: 'b3-icon-08' },
    { icon: '', family: 'b3-icon-09' },
    { icon: '', family: 'b3-icon-10' },
    { icon: '', family: 'b3-icon-11' },
    { icon: '', family: 'b3-icon-12' },
    { icon: '', family: 'b3-icon-13' },
    { icon: '', family: 'b3-icon-14' },
    { icon: '', family: 'b3-icon-15' },
    { icon: '', family: 'b3-icon-16' },
    { icon: '', family: 'b3-icon-17' },
    { icon: '', family: 'b3-icon-18' },
    { icon: '', family: 'b3-icon-19' },
    { icon: '', family: 'b3-icon-20' },
    { icon: '', family: 'b3-icon-21' },
    { icon: '', family: 'b3-icon-22' },
    { icon: '', family: 'b3-icon-23' },
    { icon: '', family: 'b3-icon-24' },
    { icon: '', family: 'b3-icon-25' },
    { icon: '', family: 'b3-icon-26' },
    { icon: '', family: 'b3-icon-27' },
    { icon: '', family: 'b3-icon-28' },
    { icon: '', family: 'b3-icon-29' },
    { icon: '', family: 'b3-icon-30' },
    { icon: '', family: 'b3-icon-31' },
    { icon: '', family: 'b3-icon-32' },
    { icon: '', family: 'b3-icon-33' },
    { icon: '', family: 'b3-icon-34' },
    { icon: '', family: 'b3-icon-35' },
    { icon: '', family: 'b3-icon-36' },
    { icon: '', family: 'b3-icon-37' },
    { icon: '', family: 'b3-icon-38' },
    { icon: '', family: 'b3-icon-39' },
    { icon: '', family: 'b3-icon-40' },
    { icon: '', family: 'b3-icon-41' },
    { icon: '', family: 'b3-icon-42' },
    { icon: '', family: 'b3-icon-43' },
    { icon: '', family: 'b3-icon-44' },
    { icon: '', family: 'b3-icon-45' },
    { icon: '', family: 'b3-icon-46' },
    { icon: '', family: 'b3-icon-47' },
    { icon: '', family: 'b3-icon-48' },
    { icon: '', family: 'b3-icon-49' },
    { icon: '', family: 'b3-icon-50' },
    { icon: '', family: 'b3-icon-51' },
    { icon: '', family: 'b3-icon-52' },
    { icon: '', family: 'b3-icon-53' },
]
