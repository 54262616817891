import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DbEventsProvider } from 'src/app/providers/database/db.events';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';

@Component({
  selector: 'app-terms-n-privacy',
  templateUrl: './terms-n-privacy.component.html',
  styleUrls: ['./terms-n-privacy.component.scss']
})
export class TermsNPrivacyComponent implements OnInit {
  @ViewChild('successSwal') public successSwal: SwalComponent;
  @ViewChild('errorSwal') public errorSwal: SwalComponent;
  eventId: string;
  termsOfUse: string = null;
  privacy: string = null;
  cookiePolicy: string = null;
  termsOfUseVisibility: boolean = true;
  privacyVisibility: boolean = true;
  cookiePolicyVisibility: boolean = false;
  loader: boolean = false;
  termsOfUseLabel = {
    fr_FR: '',
    es_ES: '',
    en_US: '',
    pt_BR: '',
    de_DE: '',
  }
  privacyLabel = {
    fr_FR: '',
    es_ES: '',
    en_US: '',
    pt_BR: '',
    de_DE: '',
  }
  cookiePolicyLabel = {
    fr_FR: '',
    es_ES: '',
    en_US: '',
    pt_BR: '',
    de_DE: '',
  }
  languages = {
    FrFR: null,
    EsES: null,
    EnUS: null,
    PtBR: null,
    DeDE: null,
  };
  constructor(
    private route: ActivatedRoute,
    private dbEvent: DbEventsProvider
  ) {
    this.eventId = this.route.parent.params['_value']['uid'];
  }

  ngOnInit() {
    this.dbEvent.getEvent(this.eventId, (event) => {
      this.termsOfUse = event['terms_of_use'];
      this.privacy = event['privacy'];
      this.cookiePolicy = event['cookiePolicy'] ? event['cookiePolicy'] : '';
      this.languages = event.languages;
      this.termsOfUseLabel = event.termsOfUseLabel ? event.termsOfUseLabel : this.termsOfUseLabel
      this.privacyLabel = event.privacyLabel ? event.privacyLabel : this.privacyLabel
      this.cookiePolicyLabel = event.cookiePolicyLabel ? event.cookiePolicyLabel : this.cookiePolicyLabel
      this.termsOfUseVisibility = event.termsOfUseVisibility !== undefined ? event.termsOfUseVisibility : this.termsOfUseVisibility
      // this.termsOfUseVisibility = event.termsOfUseVisibility 
      this.privacyVisibility = event.privacyVisibility !== undefined ? event.privacyVisibility : this.privacyVisibility
      this.cookiePolicyVisibility = event.cookiePolicyVisibility !== undefined ? event.cookiePolicyVisibility : this.cookiePolicyVisibility

    })
  }

  updateTermOfuse() {
    if(typeof this.termsOfUse === 'undefined' || this.termsOfUse === null){
      this.termsOfUse = ""
    }

    this.loader = true;
    
    this.dbEvent.updateEventTerms(this.eventId, { terms_of_use: this.termsOfUse, termsOfUseLabel: this.termsOfUseLabel, termsOfUseVisibility: this.termsOfUseVisibility }, (status) => {
      if (status == true) {
        this.successSwal.fire();
        console.log(this.termsOfUse)
        this.loader = false;
      } else {
        this.errorSwal.fire();
        this.loader = false;
      }
    });
  }

  updatePrivacy() {
    if(typeof this.privacy === 'undefined' || this.privacy === null){
      this.privacy = ""
    }
    
    this.loader = true;
    
    this.dbEvent.updateEventTerms(this.eventId, { privacy: this.privacy, privacyLabel: this.privacyLabel, privacyVisibility: this.privacyVisibility }, (status) => {
      if (status == true) {
        this.successSwal.fire();
        this.loader = false;
      } else {
        this.errorSwal.fire();
        this.loader = false;
      }
    });
  }

  updateCookiePolicy() {
    if(typeof this.cookiePolicy === 'undefined' || this.cookiePolicy === null){
      this.cookiePolicy = ""
    }
    
    this.loader = true;
    
    this.dbEvent.updateEventTerms(this.eventId, { cookiePolicy: this.cookiePolicy, cookiePolicyLabel: this.cookiePolicyLabel, cookiePolicyVisibility: this.cookiePolicyVisibility }, (status) => {
      if (status == true) {
        this.successSwal.fire();
        this.loader = false;
      } else {
        this.errorSwal.fire();
        this.loader = false;
      }
    });
  }
}
