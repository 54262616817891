import { User } from "./user";
import { AvailableModules } from './available-modules';
import { EventColors } from "./event-colors";
import { environment } from "src/environments/environment";
import { Languages } from "./languages";
import { DescriptionEvent } from "./description-event";
import { ColorsEvent } from "./colors-event";
import { EventFieldsVisibility } from "./event-fields-visibility";

export class Event {
    uid: string;

    // required attributes
    type: number;
    title: string;
    shortcode: string;
    startDate: any;
    endDate: any;
    timezone: string;
    visibility: boolean;
    eventFields: EventFieldsVisibility;
    userLimit: number;
    modules: Array<any>;
    availableModules: AvailableModules;

    // attributes not required
    placeName: string;
    placeAddress: string;
    site: string;
    subdomain: string;
    client: User;
    emailSupport: string;
    logo: any;
    webApp: any;
    banner: any;
    user_profile_edit: boolean;
    required_edit_profile: boolean;
    create_date: number;
    languages: Languages; //Object that represents the  languages of the event.
    language: string; //main language of the event
    description: DescriptionEvent;
    gallery_file_limit: number;
    documents_file_limit: number;
    homePage: string;
    colors: EventColors;
    notificationsOrder: string;
    allow_chat: boolean;
    allow_visio: boolean;
    allow_appointments: boolean;
    allow_visio_for_2: boolean;
    allow_analytics_plus: boolean;
    allow_language: boolean; //multi language 
    default_attendee_module: string;
    notification_api_id: string;
    notification_app_id: string;
    firstAccessCodeLanguage: string;
    publicOptions: {
        google_btn: boolean;
        facebook_btn: boolean;
        twitter_btn: boolean;
        microsoft_btn: boolean;
        yahoo_btn: boolean;
    }
    allowProfileQR: boolean;
    allowLogin?: boolean;
    allowRegistrationMail?: boolean;
    emailAlias?: string;
    allowAlias?: boolean;
    allowRegistration?: boolean;
    firstRegistration?:  boolean;
    attendeeVisProfilOnly?: boolean;
    attendeeVisProfilNParticipants?: boolean;
    switchToLogin?: boolean;
    authLogo: string;
    desktopBg: string;
    mobileBg: string;
    constructor(title: string, type: number, shortcode: string, startDate: any, endDate: any, timezone: string,
        visibility: boolean, userLimit: number, availableModules: AvailableModules, client: User, language
    ) {
        this.uid = null;

        this.title = title;
        this.shortcode = shortcode;
        this.startDate = startDate;
        this.endDate = endDate;
        this.timezone = timezone;
        this.visibility = visibility;
        this.userLimit = userLimit;
        this.type = type;
        this.client = client;
        this.availableModules = availableModules;
        this.language = language;
        this.description = new DescriptionEvent();
        this.eventFields = new EventFieldsVisibility();

        this.placeName = "";
        this.placeAddress = "";
        this.site = "";
        this.subdomain = "";
        this.emailSupport = "";
        this.logo = { uid: "", url: "" };
        this.webApp = { touchIcon: { url: environment.platform.defaultEventIcon, uid: "" } };
        this.banner = { uid: "", url: "" };
        this.user_profile_edit = true;
        this.required_edit_profile = false;
        this.create_date = 0;
        this.languages = new Languages()
        this.gallery_file_limit = 2048;
        this.documents_file_limit = 4096;
        this.homePage = "";
        this.colors = new ColorsEvent()
        this.notificationsOrder = 'desc';
        this.allow_chat = true;
        this.allow_visio = false;
        this.allow_visio_for_2 = false;
        this.allow_analytics_plus = false;
        this.allow_appointments = false;
        this.allow_language = true;
        this.default_attendee_module = "";
        this.notification_api_id = environment.platform.oneSignalApiId;
        this.notification_app_id = environment.platform.oneSignalAppId
        this.firstAccessCodeLanguage = language;
        this.publicOptions = {
            google_btn: false,
            facebook_btn: false,
            twitter_btn: false,
            microsoft_btn: false,
            yahoo_btn: false
        }
        this.allowProfileQR = true;
        this.authLogo = ''
        this.desktopBg = ''
        this.mobileBg = ''

        // check language
        switch (this.language) {
            case 'pt_BR' || 'pt-BR': {
                this.description.pt_BR = ''
                this.languages.PtBR = true
                break;
            }

            case 'en_US' || 'en-US': {
                this.description.en_US = ''
                this.languages.EnUS = true
                break;
            }

            case 'es_ES' || 'es-ES': {
                this.description.es_ES = ''
                this.languages.EsES = true
                break;
            }

            case 'fr_FR' || 'fr-FR': {
                this.description.fr_FR = ''
                this.languages.FrFR = true
                break;
            }

            case 'de_DE' || 'de-DE': {
                this.description.de_DE = ''
                this.languages.DeDE = true
                break;
            }
        }
    }
}