import { Pipe, PipeTransform } from "@angular/core";
import { GroupDiscussion } from "../models/group-discussion";

@Pipe({
  name: "sortGroupDiscussions",
})
export class SortGroupDiscussionsPipe implements PipeTransform {
  transform(value: GroupDiscussion[], order: string): GroupDiscussion[] {
    switch (order) {
      case "desc":
        return [...value].sort((a, b) => this.f1(a, b, -1));
      case "latest":
        return [...value].sort((a, b) => this.f2(a, b, -1));
      case "oldest":
        return [...value].sort(this.f2);
      default:
        return [...value].sort(this.f1);
    }
  }

  f1 = (a, b, c = 1) =>
    c * (a.title > b.title ? 1 : a.title == b.title ? 0 : -1);
  f2 = (a, b, c = 1) => c * (a.createdAt.toMillis() - b.createdAt.toMillis());
}
