export class Map {
    uid: string;
    moduleId: string;
    name: string;
    createdAt: number;
    address: any;
    image: string;
    storageId: string;
    type: string;

    constructor(){
        this.storageId = '';
        this.image = '';
    }
}