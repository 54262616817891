import { Action } from '@ngrx/store';
import {
    IAnalyticsGeneral,
    IAnalyticsChats,
    IAnalyticsDocuments,
    IAnalyticsFeeds,
    IAnalyticsGalleries,
    IAnalyticsTracking,
    IAnalyticsUsers,
    IAnalyticsVisios,
    IAnalyticsSchedules,
    IAnalyticsAttendees,
    IAnalyticsSpeakers
} from '../interfaces/analytics.interfaces';

export enum AnalyticsActionsTypes {

    // General analytics
    UpdateGeneralAnalytics = "[Analytics Service] Update general analytics",
    UpdateTotalAccess = "[Analytics Service] Update total access analytics",
    UpdateTotalProfilEdition = "[Analytics Service] Update total profil edition analytics",
    UpdateBestConsultedModules = "[Analytics Service] Update best consulted modules analytics",

    // Attendees analytics
    UpdateAttendeesAnalytics = "[Analytics Service] Update attendees analytics",
    UpdateTotalAttendeesModulesAnalytics = "[Analytics Service] Update total attendees modules analytics",
    UpdateTotalAttendeesAnalytics = "[Analytics Service] Update total attendees analytics",

    // Chats analytics
    UpdateChatsAnalytics = "[Analytics Service] Update chats analytics",
    UpdateTotalChats = "[Analytics Service] Update total chats analytics",
    UpdateTotalChatsUsers = "[Analytics Service] Update total chats users analytics",
    UpdateTotalMessagesChats = "[Analytics Service] Update total messages chats analytics",
    UpdateTotalDiscussionGroups = "[Analytics Service] Update total discussion groups analytics",
    UpdateTotalDiscussionGroupsUsers = "[Analytics Service] Update total discussion groups users analytics",
    UpdateTotalMessagesDiscussionGroups = "[Analytics Service] Update total messages discussion groups analytics",

    // Documents analytics
    UpdateDocumentsAnalytics = "[Analytics Service] Update documents analytics",
    UpdateBestDocuments = "[Analytics Service] Update best documents analytics",
    UpdateTotalDocuments = "[Analytics Service] Update total documents analytics",

    // Feed analytics
    UpdateFeedsAnalytics = "[Analytics Service] Update feeds analytics",
    UpdateTotalFeedsPosts = "[Analytics Service] Update total feeds posts analytics",
    UpdateTotalFeedsLikes = "[Analytics Service] Update total feeds likes analytics",
    UpdateTotalFeedsComments = "[Analytics Service] Update total feeds comments analytics",
    UpdateBestLikedFeeds = "[Analytics Service] Update best liked feeds analytics",
    UpdateBestCommentedFeeds = "[Analytics Service] Update best commented feeds analytics",


    // Galleries analytics
    UpdateGalleriesAnalytics = "[Analytics Service] Update galleries analytics",
    UpdateBestImages = "[Analytics Service] Update best images analytics",
    UpdateTotalImages = "[Analytics Service] Update total images analytics",


    // Users analytics
    UpdateUsersAnalytics = "[Analytics Service] Update users analytics",
    UpdateTotalUniqueUsers = "[Analytics Service] Update total unique users analytics",
    UpdateCurrentConnectedAttendees = "[Analytics Service] Update total of current connected attendees analytics",
    UpdateCurrentConnectedSpeakers = "[Analytics Service] Update total of current connected speakers analytics",
    UpdateTotalUsersByModule = "[Analytics Service] Update total users by module analytics",
    UpdateTotalAttendees = "[Analytics Service] Update total attendees analytics",
    UpdateTotalSpeakers = "[Analytics Service] Update total speakers analytics",


    // Schedules analytics
    UpdateSchedulesAnalytics = "[Analytics Service] Update schedules analytics",
    UpdateTotalSchedulesModulesAnalytics = "[Analytics Service] Update total schedules modules analytics",
    UpdateTotalSchedulesSessionsAnalytics = "[Analytics Service] Update total schedules sessions analytics",


    // Speakers analytics
    UpdateSpeakersAnalytics = "[Analytics Service] Update Speakers analytics",
    UpdateTotalSpeakersModulesAnalytics = "[Analytics Service] Update total Speakers modules analytics",
    UpdateTotalSpeakersAnalytics = "[Analytics Service] Update total Speakers analytics",


    // Tracking analytics
    UpdateTrackingAnalytics = "[Analytics Service] Update tracking analytics",
    UpdateUserRoadTrackingAnalytics = "[Analytics Service] Update user tracking analytics",


    // Visios analytics
    UpdateVisiosAnalytics = "[Analytics Service] Update visios analytics",
    UpdateTotalPIM = "[Analytics Service] Update total pim visios analytics",
    UpdateTotalRoomsForEvent = "[Analytics Service] Update total rooms for event visios analytics",
    UpdateTotalRoomsBetween2 = "[Analytics Service] Update total rooms for 2 visios analytics"
}


/******************************************/
/*********   General analytics   **********/
/******************************************/
export class UpdateGeneralAnalytics implements Action {
    readonly type = AnalyticsActionsTypes.UpdateGeneralAnalytics;

    constructor(public payload: IAnalyticsGeneral) { }
}
export class UpdateTotalAccess implements Action {
    readonly type = AnalyticsActionsTypes.UpdateTotalAccess;

    constructor(public payload: number) { }
}
export class UpdateTotalProfilEdition implements Action {
    readonly type = AnalyticsActionsTypes.UpdateTotalProfilEdition;

    constructor(public payload: number) { }
}
export class UpdateBestConsultedModules implements Action {
    readonly type = AnalyticsActionsTypes.UpdateBestConsultedModules;

    constructor(public payload: any[]) { }
}



/******************************************/
/**********  Attendees analytics  *********/
/******************************************/

export class UpdateAttendeesAnalytics implements Action {
    readonly type = AnalyticsActionsTypes.UpdateAttendeesAnalytics;

    constructor(public payload: IAnalyticsAttendees) { }
}
export class UpdateTotalAttendeesModulesAnalytics implements Action {
    readonly type = AnalyticsActionsTypes.UpdateTotalAttendeesModulesAnalytics;

    constructor(public payload: number) { }
}
export class UpdateTotalAttendeesAnalytics implements Action {
    readonly type = AnalyticsActionsTypes.UpdateTotalAttendeesAnalytics;

    constructor(public payload: number) { }
}




/******************************************/
/***********  Chats analytics  ************/
/******************************************/

export class UpdateChatsAnalytics implements Action {
    readonly type = AnalyticsActionsTypes.UpdateChatsAnalytics;

    constructor(public payload: IAnalyticsChats) { }
}
export class UpdateTotalChats implements Action {
    readonly type = AnalyticsActionsTypes.UpdateTotalChats;

    constructor(public payload: number) { }
}
export class UpdateTotalChatsUsers implements Action {
    readonly type = AnalyticsActionsTypes.UpdateTotalChatsUsers;

    constructor(public payload: number) { }
}
export class UpdateTotalMessagesChats implements Action {
    readonly type = AnalyticsActionsTypes.UpdateTotalMessagesChats;

    constructor(public payload: number) { }
}
export class UpdateTotalDiscussionGroups implements Action {
    readonly type = AnalyticsActionsTypes.UpdateTotalDiscussionGroups;

    constructor(public payload: number) { }
}
export class UpdateTotalDiscussionGroupsUsers implements Action {
    readonly type = AnalyticsActionsTypes.UpdateTotalDiscussionGroupsUsers;

    constructor(public payload: number) { }
}
export class UpdateTotalMessagesDiscussionGroups implements Action {
    readonly type = AnalyticsActionsTypes.UpdateTotalMessagesDiscussionGroups;

    constructor(public payload: number) { }
}




/******************************************/
/*********  Documents analytics  **********/
/******************************************/

export class UpdateDocumentsAnalytics implements Action {
    readonly type = AnalyticsActionsTypes.UpdateDocumentsAnalytics;

    constructor(public payload: IAnalyticsDocuments) { }
}
export class UpdateBestDocuments implements Action {
    readonly type = AnalyticsActionsTypes.UpdateBestDocuments;

    constructor(public payload: any[]) { }
}
export class UpdateTotalDocuments implements Action {
    readonly type = AnalyticsActionsTypes.UpdateTotalDocuments;

    constructor(public payload: number) { }
}




/******************************************/
/*********    Feeds analytics    **********/
/******************************************/

export class UpdateFeedsAnalytics implements Action {
    readonly type = AnalyticsActionsTypes.UpdateFeedsAnalytics;

    constructor(public payload: IAnalyticsFeeds) { }
}
export class UpdateTotalFeedsPosts implements Action {
    readonly type = AnalyticsActionsTypes.UpdateTotalFeedsPosts;

    constructor(public payload: number) { }
}
export class UpdateTotalFeedsLikes implements Action {
    readonly type = AnalyticsActionsTypes.UpdateTotalFeedsLikes;

    constructor(public payload: number) { }
}
export class UpdateTotalFeedsComments implements Action {
    readonly type = AnalyticsActionsTypes.UpdateTotalFeedsComments;

    constructor(public payload: number) { }
}
export class UpdateBestLikedFeeds implements Action {
    readonly type = AnalyticsActionsTypes.UpdateBestLikedFeeds;

    constructor(public payload: any[]) { }
}
export class UpdateBestCommentedFeeds implements Action {
    readonly type = AnalyticsActionsTypes.UpdateBestCommentedFeeds;

    constructor(public payload: any[]) { }
}




/******************************************/
/*********  Galleries analytics  **********/
/******************************************/

export class UpdateGalleriesAnalytics implements Action {
    readonly type = AnalyticsActionsTypes.UpdateGalleriesAnalytics;

    constructor(public payload: IAnalyticsGalleries) { }
}
export class UpdateBestImages implements Action {
    readonly type = AnalyticsActionsTypes.UpdateBestImages;

    constructor(public payload: any[]) { }
}
export class UpdateTotalImages implements Action {
    readonly type = AnalyticsActionsTypes.UpdateTotalImages;

    constructor(public payload: number) { }
}




/******************************************/
/**********  Schedules analytics  *********/
/******************************************/

export class UpdateSchedulesAnalytics implements Action {
    readonly type = AnalyticsActionsTypes.UpdateSchedulesAnalytics;

    constructor(public payload: IAnalyticsSchedules) { }
}
export class UpdateTotalSchedulesModulesAnalytics implements Action {
    readonly type = AnalyticsActionsTypes.UpdateTotalSchedulesModulesAnalytics;

    constructor(public payload: number) { }
}
export class UpdateTotalSchedulesSessionsAnalytics implements Action {
    readonly type = AnalyticsActionsTypes.UpdateTotalSchedulesSessionsAnalytics;

    constructor(public payload: number) { }
}



/******************************************/
/**********  Speakers analytics  *********/
/******************************************/

export class UpdateSpeakersAnalytics implements Action {
    readonly type = AnalyticsActionsTypes.UpdateSpeakersAnalytics;

    constructor(public payload: IAnalyticsSpeakers) { }
}
export class UpdateTotalSpeakersModulesAnalytics implements Action {
    readonly type = AnalyticsActionsTypes.UpdateTotalSpeakersModulesAnalytics;

    constructor(public payload: number) { }
}
export class UpdateTotalSpeakersAnalytics implements Action {
    readonly type = AnalyticsActionsTypes.UpdateTotalSpeakersAnalytics;

    constructor(public payload: number) { }
}


/******************************************/
/**********  Tracking analytics  **********/
/******************************************/

export class UpdateTrackingAnalytics implements Action {
    readonly type = AnalyticsActionsTypes.UpdateTrackingAnalytics;

    constructor(public payload: IAnalyticsTracking) { }
}
export class UpdateUserRoadTrackingAnalytics implements Action {
    readonly type = AnalyticsActionsTypes.UpdateUserRoadTrackingAnalytics;

    constructor(public payload: any[]) { }
}



/******************************************/
/*********    Users analytics    **********/
/******************************************/

export class UpdateUsersAnalytics implements Action {
    readonly type = AnalyticsActionsTypes.UpdateUsersAnalytics;

    constructor(public payload: IAnalyticsUsers) { }
}
export class UpdateTotalUniqueUsers implements Action {
    readonly type = AnalyticsActionsTypes.UpdateTotalUniqueUsers;

    constructor(public payload: number) { }
}
export class UpdateCurrentConnectedAttendees implements Action {
    readonly type = AnalyticsActionsTypes.UpdateCurrentConnectedAttendees;

    constructor(public payload: number) { }
}
export class UpdateCurrentConnectedSpeakers implements Action {
    readonly type = AnalyticsActionsTypes.UpdateCurrentConnectedSpeakers;

    constructor(public payload: number) { }
}
export class UpdateTotalUsersByModule implements Action {
    readonly type = AnalyticsActionsTypes.UpdateTotalUsersByModule;

    constructor(public payload: any[]) { }
}
export class UpdateTotalAttendees implements Action {
    readonly type = AnalyticsActionsTypes.UpdateTotalAttendees;

    constructor(public payload: number) { }
}
export class UpdateTotalSpeakers implements Action {
    readonly type = AnalyticsActionsTypes.UpdateTotalSpeakers;

    constructor(public payload: number) { }
}




/******************************************/
/*********   Visios analytics    **********/
/******************************************/

export class UpdateVisiosAnalytics implements Action {
    readonly type = AnalyticsActionsTypes.UpdateVisiosAnalytics;

    constructor(public payload: IAnalyticsVisios) { }
}
export class UpdateTotalPIM implements Action {
    readonly type = AnalyticsActionsTypes.UpdateTotalPIM;

    constructor(public payload: number) { }
}
export class UpdateTotalRoomsForEvent implements Action {
    readonly type = AnalyticsActionsTypes.UpdateTotalRoomsForEvent;

    constructor(public payload: number) { }
}
export class UpdateTotalRoomsBetween2 implements Action {
    readonly type = AnalyticsActionsTypes.UpdateTotalRoomsBetween2;

    constructor(public payload: number) { }
}



export type AnalyticsActionsUnion = UpdateGeneralAnalytics
    | UpdateTotalAccess
    | UpdateTotalProfilEdition
    | UpdateBestConsultedModules


    | UpdateAttendeesAnalytics
    | UpdateTotalAttendeesModulesAnalytics
    | UpdateTotalAttendeesAnalytics


    | UpdateChatsAnalytics
    | UpdateTotalChats
    | UpdateTotalChatsUsers
    | UpdateTotalMessagesChats
    | UpdateTotalDiscussionGroups
    | UpdateTotalDiscussionGroupsUsers
    | UpdateTotalMessagesDiscussionGroups


    | UpdateDocumentsAnalytics
    | UpdateBestDocuments
    | UpdateTotalDocuments


    | UpdateFeedsAnalytics
    | UpdateTotalFeedsPosts
    | UpdateTotalFeedsLikes
    | UpdateTotalFeedsComments
    | UpdateBestLikedFeeds
    | UpdateBestCommentedFeeds


    | UpdateGalleriesAnalytics
    | UpdateBestImages
    | UpdateTotalImages


    | UpdateSchedulesAnalytics
    | UpdateTotalSchedulesModulesAnalytics
    | UpdateTotalSchedulesSessionsAnalytics


    | UpdateSpeakersAnalytics
    | UpdateTotalSpeakersModulesAnalytics
    | UpdateTotalSpeakersAnalytics


    | UpdateTrackingAnalytics
    | UpdateUserRoadTrackingAnalytics


    | UpdateUsersAnalytics
    | UpdateTotalUniqueUsers
    | UpdateCurrentConnectedAttendees
    | UpdateCurrentConnectedSpeakers
    | UpdateTotalUsersByModule
    | UpdateTotalAttendees
    | UpdateTotalSpeakers


    | UpdateVisiosAnalytics
    | UpdateTotalPIM
    | UpdateTotalRoomsForEvent
    | UpdateTotalRoomsBetween2;