import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { CKEditorModule } from 'ng2-ckeditor';
import { DragulaModule } from 'ng2-dragula';
import { ColorPickerModule } from 'ngx-color-picker';
import { DropzoneModule } from 'ngx-dropzone-wrapper';
import { ImageCropperModule } from 'ngx-image-cropper';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { UiSwitchModule } from 'ngx-ui-switch';
import { createTranslateLoader, SharedModule } from 'src/app/shared/shared.module';
import {
    AppointmentsRuleGroupsSelectionComponent
} from './appointments-rule-groups-selection/appointments-rule-groups-selection.component';
import { AppointmentsRuleTimeSlotsComponent } from './appointments-rule-time-slots/appointments-rule-time-slots.component';
import { AppointmentsComponent } from './appointments.component';
import { ListAppointmentsComponent } from './list-appointments/list-appointments.component';
import { ListTimeSlotsComponent } from './list-time-slots/list-time-slots.component';
import { TimeSlotsRuleEditorComponent } from './time-slots-rule-editor/time-slots-rule-editor.component';


@NgModule({
    declarations: [
        AppointmentsComponent,
        AppointmentsRuleGroupsSelectionComponent,
        AppointmentsRuleTimeSlotsComponent,
        ListTimeSlotsComponent,
        TimeSlotsRuleEditorComponent,
        ListAppointmentsComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        HttpClientModule,
        TranslateModule.forChild({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient],
            },
        }),
        SweetAlert2Module,
        NgxPaginationModule,
        ReactiveFormsModule.withConfig({ warnOnNgModelWithFormControl: "never" }),
        RouterModule.forChild([{path: "", component: AppointmentsComponent}]),
        SharedModule,
        UiSwitchModule,
    ],
})
export class AppointmentsComponentModule { }
