import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Chart } from 'chart.js';
import 'chartjs-plugin-datalabels';
// import ChartDataLabels from 'chartjs-plugin-datalabels';
import { DbTrainingProvider } from '../providers/database/db-training';
import { Question } from '../models/training-question';
import { Answer } from '../models/training-answer';
import { GlobalService } from '../providers/global/global.service';
import { DbEventsProvider } from '../providers/database/db.events';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { DbInteractivityProvider } from '../providers/database/db-interactivity';

declare let $: any;
@Component({
  selector: 'app-training-result',
  templateUrl: './training-result.component.html',
  styleUrls: ['./training-result.component.scss']
})
export class TrainingResultComponent implements OnInit {

  @ViewChild('successSwal') public successSwal: SwalComponent;

  menu_color: string = null;
  menu_color2: string = null;
  menu_text_color: string = null;
  logo: string = null;

  //configs
  chartFontSize: number = 12;
  subtitleFontSize: number = 12;
  // chartHeight: number = 700;
  // chartWidth: number = 700;
  chartIndexShow: boolean = true;
  chartLabelShow: boolean = true;

  // event id and survey id to display results
  eventId = null;
  moduleId = null;
  trainingId = null;
  questionId = null;

  refResult: any = null;
  refResultCollection: AngularFirestoreCollection<any> = null;

  refUserResult: any = null;
  refUserResultDocument: AngularFirestoreDocument<any> = null;

  // watch and reference path firebase
  // data: Observable<any[]>;
  // ref: AngularFireList<any>;

  // chart variables
  graphicResult: Array<any> = [];
  // answers;
  question: Question;
  eventName: string;
  trainingName: string;
  questionTitle: string;
  objValFinal: any = [];
  objOptFinal: any = [];
  objChartFinal: any = [];
  objFinalUpdate: any = [];
  answeredPorcentage: Array<string>;
  chartObj;

  colorsChart: Array<any> = [];


  barColor: Array<any> = []
  retangleColor: Array<any> = []

  constructor(private daoTraining: DbTrainingProvider,
    private daoInteractivity: DbInteractivityProvider,
    private dbEvents: DbEventsProvider,
    private route: ActivatedRoute,
    private afs: AngularFirestore,
    public global: GlobalService) {

    // get event id from url
    this.eventId = this.route.snapshot.params['eventId'];
    //get module id from url
    this.moduleId = this.route.snapshot.params['moduleId'];
    // get training id from url
    this.trainingId = this.route.snapshot.params['trainingId'];
    this.questionId = this.route.snapshot.params['questionId'];
    this.getUserLanguage();
    this.daoTraining.getQuestion(this.moduleId, this.trainingId, this.questionId, (question) => {
      this.questionTitle = question.title[this.userLanguageFormat];
    })
  }

  ngOnInit() {
    this.daoTraining.getTraining(this.moduleId, this.trainingId, (result) => {
      this.trainingName = result.title[this.userLanguageFormat];
    });

    $(document).on('click', '.dropdown-menu', function (e) {
      e.stopPropagation();
    });

    this.startEvent();
  }

  ngAfterViewInit() {
    this.refResultCollection =
      this.afs
        .collection('modules')
        .doc(this.moduleId)
        .collection('trainings')
        .doc(this.trainingId)
        .collection('questions')
        .doc(this.questionId)
        .collection('result');

    this.refResult = this.refResultCollection.valueChanges().subscribe((data: any) => {
      if (this.chartObj) {
        this.updateChart(data);
      } else {
        this.getColorsChart((valueColors) => {
          this.getOptionsChart((valueOptions) => {
            if (valueOptions !== null) {
              this.chartFontSize = valueOptions.fontSize;
              this.subtitleFontSize = valueOptions.fontSizeLabel;
              // this.chartHeight = valueOptions.chartHeight;
              // this.chartWidth = valueOptions.chartWidth;
              this.chartIndexShow = valueOptions.showIndex;
              this.chartLabelShow = valueOptions.showLabel;
            }
            if (valueColors) {
              this.setValueTraining(data)
            }
          })
        });
      }
    }, err => { console.log(err) })
  }

  getColorsChart(onResolve) {
    this.daoInteractivity.getInteractivityModuleByEventId(this.eventId, (module) => {
      let moduleInteractivityId = module.uid;

      this.daoInteractivity.getListColorsCharts(moduleInteractivityId, (colors) => {
        if (colors !== undefined) {
          this.colorsChart = colors;
          this.barColor = colors !== null ? this.generateArrayColors() : this.generateArrayColorsDefault();
          this.retangleColor = this.barColor;
          onResolve(true);
        }
      })
    })
  }

  getOptionsChart(onResolve) {
    this.daoInteractivity.getOptionsChart(this.eventId, (data) => {
      onResolve(data);
    })
  }

  generateArrayColors() {
    let array = [];
    let totalColors = this.colorsChart.length;
    let sizeArray = 30;
    let cont = 1;

    while (array.length < sizeArray) {
      array.push(this.colorsChart[cont - 1].color);

      if (cont >= totalColors) {
        cont = 1;
      } else {
        cont++;
      }
    }

    return array;
  }

  generateArrayColorsDefault() {
    return [
      'rgba(0, 113, 186, 1)',
      'rgba(109, 199, 221, 1)',
      'rgba(73, 60, 144, 1)',
      'rgba(150, 54, 139, 1)',
      'rgba(234, 82, 132, 1)',
      'rgba(231, 52, 88, 1)',
      'rgba(243, 147, 37, 1)',
      'rgba(253, 196, 31, 1)',
      'rgba(149, 193, 31, 1)',
      'rgba(0, 179, 187, 1)',

      'rgba(0, 113, 186, 1)',
      'rgba(109, 199, 221, 1)',
      'rgba(73, 60, 144, 1)',
      'rgba(150, 54, 139, 1)',
      'rgba(234, 82, 132, 1)',
      'rgba(231, 52, 88, 1)',
      'rgba(243, 147, 37, 1)',
      'rgba(253, 196, 31, 1)',
      'rgba(149, 193, 31, 1)',
      'rgba(0, 179, 187, 1)',

      'rgba(0, 113, 186, 1)',
      'rgba(109, 199, 221, 1)',
      'rgba(73, 60, 144, 1)',
      'rgba(150, 54, 139, 1)',
      'rgba(234, 82, 132, 1)',
      'rgba(231, 52, 88, 1)',
      'rgba(243, 147, 37, 1)',
      'rgba(253, 196, 31, 1)',
      'rgba(149, 193, 31, 1)',
      'rgba(0, 179, 187, 1)'
    ]
  }

  allowDegrade: boolean = false;
  startEvent() {
    this.dbEvents.getEvent(this.eventId, (event) => {
      this.logo = event.logo.url
      this.menu_color = event.colors.menu_color;
      this.menu_text_color = event.colors.menu_text_color;
    });
  }

  separeGradientColors(str) {
    let hex = [];
    let index = str.indexOf('#');

    while (index > -1) {
      hex.push(this.getHexColors(str))
      str = str.replace('#', '');
      index = str.indexOf('#');
    }

    return hex;
  }

  getHexColors(str) {
    let position = str.indexOf('#');
    let result = str.substring(position, position + 7);

    return result;
  }

  userLanguageFormat: string = 'PtBR';
  getUserLanguage() {
    this.global.getLanguage((language) => {
      this.userLanguageFormat = this.convertLangFormat(language);
    })
  }

  setValueTraining(userResult) {
    this.daoTraining.getQuestion(this.moduleId, this.trainingId, this.questionId, (question) => {
      this.getAnswers(question).then((question: Question) => {
        this.question = question;

        for (let answer of question.answers) {
          let answerResults = [];

          for (let user of userResult) {
            let index = this.checkIndexExists(user.answer, answer.uid);
            if (index >= 0) {
              answerResults.push(user.user);
            }
          }

          answer['result'] = answerResults;
        }

        this.createChartData(this.question);
      })
        .catch((err) => {
          console.log(err)
        })
      this.questionTitle = question.title[this.userLanguageFormat];
    })
  }

  checkIndexExists(array, item) {
    return array.map(function (e) { return e; }).indexOf(item);
  }

  getAnswers(data) {
    return new Promise(resolve => {
      const question = new Question();
      question.uid = data.uid;
      question.title = data.title;
      question.graphic = data.graphic;
      question.type = data.type;

      this.getAnswerResult(question).then((list: Array<Answer>) => {
        for (const a of list) {
          question.answers.push(a);
        }
        resolve(question);
      });
    });
  }

  getAnswerResult(question) {
    return new Promise(resolve => {
      this.daoTraining.getAnswers(this.moduleId, this.trainingId, this.questionId, (list) => {
        let answers: Array<Answer> = [];
        answers = list;

        resolve(answers);
      })
    });
  }

  createChartData(question) {
    let objValues = {};
    this.objValFinal = [];
    this.objOptFinal = [];
    this.objChartFinal = [];
    let cont = 0;

    let contAnswer = 0;
    this.objValFinal[cont] = [];
    this.objOptFinal[cont] = [];
    this.objChartFinal = [];
    for (const answer of question.answers) {
      this.listAvaliacao = [];
      if (answer.answer === 'resposta de avaliacao') {
        this.listAvaliacao[cont] = answer.result;
      } else {
        objValues = {
          'result': answer.result.length,
          'name': answer.answer[this.userLanguageFormat],
          'graphic': question.graphic
        }

        this.objValFinal[cont].push(objValues['result']);
        this.objOptFinal[cont].push({
          name: objValues['name'],
          resume: objValues['name'].length > 35 ? objValues['name'].substring(0, 35) + '...' : objValues['name']
        });
      }

      if (question.answers.length - 1 == contAnswer) {
        this.drawChart(objValues, cont);
        this.calcPercentage(this.objValFinal[0]);
        cont++;
      }
      contAnswer++;
    }

  }

  calcPercentage(objValFinal) {
    //soma o total de votos
    let total = 0;
    // for(let aux of objValFinal) {
    for (let votes of objValFinal) {
      total += votes;
    }
    // }

    //com base no total, calcula o porcentual de votos de cada questão
    let listPorcentage: Array<string> = [];
    // for(let aux of objValFinal) {
    for (let i = 0; i < objValFinal.length; i++) {
      let votes = objValFinal[i];

      let porcentage: string = "0.00";
      if (total != 0) {
        porcentage = (votes / total * 100).toFixed(2);
      }

      listPorcentage.push(porcentage);
    }
    // }

    this.answeredPorcentage = listPorcentage;

  }

  /**
 * generate json to chart
 */
  listAvaliacao = [];

  drawChart(chartData, index) {
    let graphicType = '';
    if (chartData.graphic == 'PieChart') {
      graphicType = 'pie';
    } if (chartData.graphic == 'ColumnChart') {
      graphicType = 'bar';
    } if (chartData.graphic == 'BarChart') {
      graphicType = 'horizontalBar';
    }
    let ctx = document.getElementById('trainingChart') as HTMLCanvasElement;

    let listLabels: Array<string> = [];
    for (let aux of this.objOptFinal[index]) {
      listLabels.push(aux.resume);
    }

    this.chartObj = new Chart(ctx, {
      // type == type of chart
      type: graphicType,
      data: {
        // labels == legends of the chart
        labels: listLabels,
        datasets: [{
          label: '',
          // data == values of result
          data: this.objValFinal[index],
          // backgroundColor == colors of the charts
          // backgroundColor == colors of the charts
          backgroundColor: this.barColor,
          // borderColor == color of the chart borders
          borderColor: this.barColor,
          borderWidth: 1
        }]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false
        },
        scales: {
          yAxes: [{
            display: this.question.graphic == 'ColumnChart' || this.question.graphic == 'PieChart' ?
              this.chartIndexShow : this.chartLabelShow,
            ticks: {
              beginAtZero: true,
              // scaleSteps: 0.1,
              stepSize: 1,
              // scaleStartValue: 0,
              fontSize: this.chartFontSize,
              minor: {
                fontSize: this.chartFontSize
              },
              // barPercentage: 0.7 //largura da barra do gráfico em relação ao tamanho da tela (eixo X)
            }
          }],
          xAxes: [{
            display: this.question.graphic == 'BarChart' ? this.chartIndexShow : this.chartLabelShow,
            ticks: {
              beginAtZero: true,
              // scaleSteps: 0.1,
              stepSize: 1,
              // scaleStartValue: 0,
              fontSize: this.chartFontSize,
              minor: {
                fontSize: this.chartFontSize
              }
            },
            // barPercentage: 0.7 //largura da barra do gráfico em relação ao tamanho da tela (eixo X)
          }]
        },
        plugins: {
          datalabels: {
            formatter: (value, ctx) => {
              let sum = 0;
              let dataArr: any = ctx.chart.data.datasets[0].data;
              dataArr.map(data => {
                sum += data;
              });
              let percentage = (value * 100 / sum).toFixed(2) + "%";
              
              if(percentage == '0.00%') {
                percentage = null;
              }

              return percentage;
            },

            color: '#fff', //color porcentagem interna do gráfico
            font: {
              size: 20, // font size porcentagem interna do gráfico
              weight: 800 // weight porcentagem interna do gráfico
            },
          }
        }
      }
    })

     //seta o tamanho certo do gráfico, caso tenha algum valor posteriormente definido
    //  this.changeChartHeight();
    //  this.changeChartWidth();
  }

  updateChart(userResult) {
    this.daoTraining.getQuestion(this.moduleId, this.trainingId, this.questionId, (result) => {
      let questions;
      this.getAnswers(result).then((question: Question) => {
        questions = question;

        for (let answer of questions.answers) {
          let answerResults = [];

          for (let user of userResult) {
            let index = this.checkIndexExists(user.answer, answer.uid);

            if (index >= 0) {
              answerResults.push(user.user);
            }
          }

          answer['result'] = answerResults;
        }

        this.contChartUpdate(questions);
      })
        .catch((err) => {
          console.log(err)
        });
    });
  }


  contChartUpdate(question) {
    let objValues = {};
    var objValFinal = [];
    let cont = 0;

    let contAnswer = 0;
    for (const answer of question.answers) {
      this.listAvaliacao = [];
      if (answer.answer === 'resposta de avaliacao') {
        this.listAvaliacao[cont] = answer.result;
      } else {
        objValFinal.push(answer.result.length);
      }
      if (question.answers.length - 1 == contAnswer) {
        this.chartObj.data.datasets[0].data = objValFinal;
        this.chartObj.update();
        cont++;
      }
      contAnswer++;
    }

    this.calcPercentage(objValFinal);
  }


  changeFontSize() {
    this.chartObj.options.scales.xAxes[0].ticks.minor.fontSize = this.chartFontSize;
    this.chartObj.options.scales.yAxes[0].ticks.minor.fontSize = this.chartFontSize;

    this.chartObj.options.scales.xAxes[0].ticks.fontSize = this.chartFontSize;
    this.chartObj.options.scales.yAxes[0].ticks.fontSize = this.chartFontSize;

    this.chartObj.update();
  }

  // changeChartHeight() {
  //   let size = this.chartHeight + 'px';
  //   this.chartObj.canvas.parentNode.style.height = size;
  //   this.chartObj.update();
  // }

  // changeChartWidth() {
  //   let size = this.chartWidth + 'px';
  //   this.chartObj.canvas.parentNode.style.width = size;
  //   this.chartObj.update();
  // }

  changeViewIndexChart() {
    let typeChart = this.question.graphic;
    let value = this.chartIndexShow;

    if (typeChart == 'ColumnChart' || typeChart == 'PieChart') {
      this.chartObj.options.scales.yAxes[0].display = value;

    } else if ('BarChart') {
      this.chartObj.options.scales.xAxes[0].display = value;
    }

    this.chartObj.update();
  }

  changeViewLabelChart() {
    let typeChart = this.question.graphic;
    let value = this.chartLabelShow;

    if (typeChart == 'ColumnChart' || typeChart == 'PieChart') {
      this.chartObj.options.scales.xAxes[0].display = value;

    } else if ('BarChart') {
      this.chartObj.options.scales.yAxes[0].display = value;
    }

    this.chartObj.update();
  }

  convertLangFormat(lang) {
    let formatedLang;
    switch (lang) {
      case 'pt_BR': {
        formatedLang = 'PtBR'
        break;
      }
      case 'en_US': {
        formatedLang = 'EnUS';
        break;
      }
      case 'es_ES': {
        formatedLang = 'EsES';
        break;
      }
      case 'fr_FR': {
        formatedLang = 'FrFR';
        break;
      }
      case 'de_DE': {
        formatedLang = 'DeDE';
        break;
      }
    }
    return formatedLang;
  }


  saveChangeOptionsChart() {
    let options = {
      fontSize: this.chartFontSize,
      fontSizeLabel: this.subtitleFontSize,
      // chartHeight: this.chartHeight,
      // chartWidth: this.chartWidth,
      showIndex: this.chartIndexShow,
      showLabel: this.chartLabelShow
    }

    this.daoInteractivity.updateChartOptions(this.eventId, options, (data) => {
      if (data) {
        this.successSwal.fire();
      } else {
        // this.errorSwal.fire();
      }
    })
  }

}
