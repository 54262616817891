import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { GlobalService } from 'src/app/providers/global/global.service';
import { DbWordCloudProvider } from 'src/app/providers/database/db-word-cloud';
import { ActivatedRoute, Router } from '@angular/router';
import { WordCloud } from 'src/app/models/word-cloud';
import { TranslateService } from '@ngx-translate/core'

type AOA = Array<Array<any>>;
declare let $: any;

@Component({
  selector: 'app-word-cloud',
  templateUrl: './word-cloud.component.html',
  styleUrls: ['./word-cloud.component.scss']
})

export class WordCloudComponent implements OnInit {

  @ViewChild('successSwal') public successSwal: SwalComponent;
  @ViewChild('errorSwal') public errorSwal: SwalComponent;
  @ViewChild('clearWordCloudSwal') public clearWordCloudSwal: SwalComponent;
  @ViewChild('removeWordCloudSwal') public removeWordCloudSwal: SwalComponent;
  enableAll;
  dataExportWords: AOA = [[]];
  data: any;
  wordclouds: Array<WordCloud> = [];
  eventId: string = null;
  moduleId: string = null;
  name: string;
  changeName: string = '';
  visibility: boolean;
  words: any;
  visibilityNew: boolean = true;
  visibilityEdit: boolean = null;
  cloudEditId: string = null;
  cloudRemoveId: string = null;
  cloudClearId: string = null;

  editTitle: string;
  editSubtitle: string;
  editMaxChars: number;
  editVisibility: boolean;

  formNewCloud = new FormGroup({
    title: new FormControl(null),
    subtitle: new FormControl(null),
    max_characters: new FormControl(null),
    visibility: new FormControl(null),
  });

  formEditCloud = new FormGroup({
    title: new FormControl(null),
    subtitle: new FormControl(null),
    max_characters: new FormControl(null),
  });

  constructor(private global: GlobalService,
    private route: ActivatedRoute,
    private dbWordCloud: DbWordCloudProvider,
    private router: Router,
    private translateService: TranslateService) {
    // get event id and module id
    this.moduleId = this.route.snapshot.params['moduleId'];
    this.eventId = this.route.pathFromRoot[1]['params']['_value']['uid'];
  }

  ngOnInit() {
    if (this.eventId !== undefined && this.eventId !== null && this.eventId !== '') {
      this.dbWordCloud.getAllWordCloud(this.moduleId, (clouds: Array<WordCloud>) => {
        this.wordclouds = [];
        this.wordclouds = clouds;
      });
    }

    $(function () {
      $('[data-toggle="tooltip"]').tooltip()
    })

  }

  changeVisibility() {
    if (this.visibilityNew == true) {
      this.visibilityNew = false;
    } else {
      this.visibilityNew = true;
    }
  }

  setVisibility(cloud) {
    cloud.visibility = !cloud.visibility;
    this.dbWordCloud.setWordCloudVisibility(this.moduleId, cloud.uid, cloud.visibility, (data) => {
      if(data === false) {
        cloud.visibility = !cloud.visibility;
      }
    });
  }

  clearResults() {
    this.dbWordCloud.clearResult(this.moduleId, this.cloudClearId, (data) => {
      this.successSwal.fire();
    })
  }

  newCloud() {
    const cloud: WordCloud = new WordCloud;
    const aux = this.formNewCloud.value;

    cloud._title = aux.title;
    cloud._subtitle = aux.subtitle;
    cloud._max_characters = aux.max_characters;
    cloud._visibility = true;
    this.dbWordCloud.createWordCloud(this.moduleId, cloud, (res) => {
      if (res == true) {
        // created successfully
        this.successSwal.fire();
      } else {
        // failed to create 
        this.errorSwal.fire();
      }
    });
  }

  getCloudEdit(cloud) {
    this.cloudEditId = cloud.uid;

    this.formEditCloud.patchValue({
      title: cloud['title'],
      subtitle: cloud['subtitle'],
      max_characters: cloud['max_characters']
    });
  }

  getClearCloud(cloud) {
    this.cloudClearId = cloud.uid;

    this.formEditCloud.patchValue({
      title: cloud['title'],
      subtitle: cloud['subtitle'],
      max_characters: cloud['max_characters']
    });

    this.clearWordCloudSwal.fire();
  }

  getRemoveCloud(cloud) {
    this.cloudRemoveId = cloud.uid;

    this.formEditCloud.patchValue({
      title: cloud['title'],
      subtitle: cloud['subtitle'],
      max_characters: cloud['max_characters']
    });

    this.removeWordCloudSwal.fire();
  }



  habilitAll() {
    // for (const item of this.wordclouds) {
    //   this.dbWordCloud.setWordCloudVisibility(this.eventId, item, true);
    // }
  }

  disableAll() {
    // for (const item of this.wordclouds) {
    //   this.dbWordCloud.setWordCloudVisibility(this.eventId, item, false);
    // }
  }

  editCloud() {
    const cloudEdit: WordCloud = new WordCloud;
    const aux = this.formEditCloud.value;
    cloudEdit._title = aux.title;
    cloudEdit._subtitle = aux.subtitle;
    cloudEdit._max_characters = aux.max_characters;

    this.dbWordCloud.editWordCloud(this.moduleId, this.cloudEditId, cloudEdit, (res) => {
      if (res == true) {
        // edited successfully
        this.successSwal.fire();
      } else {
        // failed to edit
        this.errorSwal.fire();
      }
    })
  }

  removeCloud() {
    this.dbWordCloud.deleteWordCloud(this.moduleId, this.cloudRemoveId, (data) => {
      let index = this.wordclouds.map(function(e) { return e._uid; }).indexOf(this.cloudRemoveId);
      this.wordclouds.splice(index, 1);
      this.successSwal.fire();
    })
  }

  prepareExportReport(cloud) {

    this.dataExportWords = [];

    this.dbWordCloud.exportCloud(this.moduleId, cloud.uid, (data) => {
      if(data.result.length > 0) {
        this.dataExportWords = data.result;
        this.dataExportWords
        .unshift(
          [
            this.translateService.instant('global.name'),
            this.translateService.instant('global.email'),
            this.translateService.instant('global.word')
          ]
            );

        this.exportReport();
      } else {
        //sem dados pra exportar
      }

    })
  }

  // export data in excel
  exportReport() {
    const wscols: XLSX.ColInfo[] = [
      { wpx: 100 }, // "pixels"
      { wpx: 100 }, // "pixels"
      { wpx: 100 }, // "pixels"
      { hidden: false } // hide column
    ];

    /* At 96 PPI, 1 pt = 1 px */
    const wsrows: XLSX.RowInfo[] = [
      { hpx: 25 }, // "pixels"
    ];

    /* generate worksheet */
    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.dataExportWords);

    /* TEST: column props */
    ws['!cols'] = wscols;

    /* TEST: row props */
    ws['!rows'] = wsrows;

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    const wbout: string = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
    saveAs(new Blob([this.s2ab(wbout)]), 'word_cloud.xlsx');

    this.data = null;
  }

  // AJUDA A GERAR O ARQUIVO EXECL
  private s2ab(s: string): ArrayBuffer {
    const buf: ArrayBuffer = new ArrayBuffer(s.length);
    const view: Uint8Array = new Uint8Array(buf);
    for (let i = 0; i !== s.length; ++i) {
      view[i] = s.charCodeAt(i) & 0xFF;
    }
    return buf;
  }

  openPage(page) {
    this.router.navigate([page, { eventId: this.eventId }]);
  }

}
