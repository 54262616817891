export const environment = {
    production: true,
    firebase: {
        apiKey: "AIzaSyAcX-jtB_3xTy5fJtNXlfSeP9LUGVrcfvs",
        authDomain: "high5event-a48b5.firebaseapp.com",
        projectId: "high5event-a48b5",
        storageBucket: "high5event-a48b5.appspot.com",
        messagingSenderId: "735681106251",
        appId: "1:735681106251:web:60989e94db77e7eb6b58ce"
    },
    platform: {
        name: "High5Event - Backoffice",
        apiBaseUrl: "https://us-central1-high5event-a48b5.cloudfunctions.net/",
        loginLogo: "logo-ezbizevent.svg",
        navLogo: "logo-ezbizevent.svg",
        license: "ezbizevent - All Rights Reserved © 2022.",
        defaultLanguage: "en_US",
        defaultTimezone: "Europe/Paris",
        defaultAppUrl: "https://cms.high5event.com",
        oneSignalApiId: "",
        oneSignalAppId: "",
        defaultEventIcon: "add-image.svg",
        bambuserApplicationId: "",
        bambuserApiKey: "",
    },
};
