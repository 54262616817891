export class Group {
    identifier: string; //string data type for multi-language and number for normal groups.
    uid: string;
    moduleId: string
    eventId: string
    name: string;
    queryName: string; //saves the group name in upper case to do the sorting.
    bgColor: string;
    txtColor: string;
    createdAt: Number;
    multiLanguage: boolean

    constructor(identifier: any, moduleId: string, eventId: string, name: string, bgColor: string, textColor: string, multiLanguage: boolean){
        this.uid = null
        this.queryName = name.toLocaleUpperCase()
        this.createdAt = Date.now() / 1000 | 0;

        this.identifier = identifier
        this.moduleId = moduleId
        this.eventId = eventId
        this.name = name
        this.bgColor = bgColor
        this.txtColor = textColor
        this.multiLanguage = multiLanguage
    }
}