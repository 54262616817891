import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { AuthService } from "../../providers/auth/auth.service";
import { PathApi } from "../../paths/path-api";
import { AngularFirestore } from "@angular/fire/firestore";
import { Speaker } from "src/app/models/speakers";
import firebase from 'firebase/app';
import { eventCustomField } from "src/app/models/eventCustomField";
import { eventCustomFieldAnswerOption } from "src/app/models/eventCustomFieldAnswerOption";
import { StorageService } from "src/app/providers/storage/storage.service";
import { DbAdminUserProvider } from "./db-admin-user";
import { TypeModule } from "src/app/enums/type-module";
import { async } from "q";
import { ModuleSchedule } from "src/app/models/modules/module-schedule";
import { Scheduler } from "rxjs";
import { Session } from "src/app/models/session";

@Injectable({
    providedIn: "root",
})
export class DbSpeakersProvider {
    private db: firebase.firestore.Firestore;
    public headers;
    public requestOptions;

    constructor(
        private auth: AuthService,
        private http: HttpClient,
        private aFirestore: AngularFirestore,
        private storage: StorageService,
        private dbUser: DbAdminUserProvider
    ) {
        this.headers = new HttpHeaders();
        this.headers.append("Accept", "application/json");
        this.headers.append("Content-Type", "application/json");
        this.requestOptions = { headers: this.headers };

        this.db = firebase.firestore();
    }

    speakerModule(moduleId: string, onResolve) {
        let db = this.aFirestore.firestore;
        db.collection("modules")
            .doc(moduleId)
            .get()
            .then((doc) => {
                let module = doc.data();
                onResolve(module);
            })
            .catch((err) => onResolve(err));
    }

    // load evnt speakers
    getSpeakersEvent(eventId: string, onResolve) {
        let db = this.aFirestore.firestore;

        db.collection("events")
            .doc(eventId)
            .collection("speakers")
            .get()
            .then((snapshot) => {
                const list = [];

                snapshot.forEach((childSnanapshot) => {
                    const speaker = childSnanapshot.data();
                    list.push(speaker);
                });

                onResolve(list);
            });
    }

    getSpeakers(moduleId, typeOrder, onResolve) {
        let firestore = this.aFirestore.firestore;
        let refSpeakers;

        switch (typeOrder) {
            case "asc": //a-z
                refSpeakers = firestore
                    .collection("modules")
                    .doc(moduleId)
                    .collection("speakers")
                    .orderBy("queryName", "asc");
                break;

            case "desc": //z-a
                refSpeakers = firestore
                    .collection("modules")
                    .doc(moduleId)
                    .collection("speakers")
                    .orderBy("queryName", "desc");
                break;

            case "oldest": //antiho-recente
                refSpeakers = firestore
                    .collection("modules")
                    .doc(moduleId)
                    .collection("speakers")
                    .orderBy("createdAt", "asc");
                break;

            case "recent": //recente-antigo
                refSpeakers = firestore
                    .collection("modules")
                    .doc(moduleId)
                    .collection("speakers")
                    .orderBy("createdAt", "desc");
                break;

            case "id": //ID
                refSpeakers = firestore
                    .collection("modules")
                    .doc(moduleId)
                    .collection("speakers")
                    .orderBy("identifier");
                break;
        }

        refSpeakers
            .get()
            .then((data) => {
                let listSpeakers = [];
                data.forEach((doc) => {
                    let speaker = doc.data();
                    listSpeakers.push(speaker);
                });
                let result = {
                    code: 200,
                    message: "success",
                    result: listSpeakers,
                };

                onResolve(result);
            })
            .catch((error) => {});
    }

    getSpeaker(eventId, moduleId, speakerId, onResolve) {
        let firestore = this.aFirestore.firestore;

        firestore
            .collection("events")
            .doc(eventId)
            .collection("speakers")
            .doc(speakerId)
            .get()
            .then((data) => {
                let result = {
                    code: 200,
                    message: "success",
                    result: data.data(),
                };

                onResolve(result);
            })
            .catch((error) => {});
    }

    // get speaker object using a promise function.
    getSpeakerPromise(eventId: string, speakerId: string) {
        return new Promise((resolve) => {
            let db = this.aFirestore.firestore;
            const ref = db
                .collection("events")
                .doc(eventId)
                .collection("speakers")
                .doc(speakerId);

            ref.get().then((snapshot) => {
                const speaker = snapshot.data();
                resolve(speaker);
            });
        });
    }

    verifyExistSpeakerByEmail(eventId: string, email: string, onResolve) {
        let db = this.aFirestore.firestore;

        db.collection("events")
            .doc(eventId)
            .collection("speakers")
            .where("email", "==", email)
            .get()
            .then((data) => {
                let docNotExist = true;
                data.forEach((doc) => {
                    if (doc.exists) {
                        docNotExist = false;
                        onResolve(true);
                    }
                });

                if (docNotExist == true) {
                    onResolve(false);
                }
            })
            .catch((error) => {});
    }

    verifyExistSpeakerByIdentifier(eventId, identifier, onResolve) {
        let db = this.aFirestore.firestore;

        db.collection("events")
            .doc(eventId)
            .collection("speakers")
            .where("identifier", "==", identifier)
            .get()
            .then((data) => {
                let docNotExist = true;

                data.forEach((doc) => {
                    if (doc.exists) {
                        docNotExist = false;
                        onResolve(true);
                    }
                });

                if (docNotExist == true) {
                    onResolve(false);
                }
            })
            .catch((error) => {});
    }

    verifyIdentifierValidBySpeaker(eventId, identifier, speakerId, onResolve) {
        let db = this.aFirestore.firestore;

        db.collection("events")
            .doc(eventId)
            .collection("speakers")
            .where("identifier", "==", identifier)
            .get()
            .then((data) => {
                let docNotExist = true;

                data.forEach((doc) => {
                    if (doc.exists) {
                        let speaker = doc.data();

                        if (speakerId == speaker.uid) {
                            onResolve(true);
                        } else {
                            onResolve(false);
                        }
                    }
                });

                if (docNotExist) {
                    onResolve(true);
                }
            })
            .catch((error) => {});
    }

    verifyEmailValidBySpeaker(email, speakerId, onResolve) {
        if (email !== "" && email !== null && email !== undefined) {
            let db = this.aFirestore.firestore;

            db.collection("users")
                .where("email", "==", email)
                .get()
                .then((data) => {
                    if (data.size == 0) {
                        onResolve(true);
                    }

                    data.forEach((element) => {
                        let user = element.data();

                        if (user.uid == speakerId) {
                            onResolve(true);
                        } else {
                            onResolve(false);
                        }
                    });
                });
        } else {
            onResolve(true);
        }
    }

    createSpeaker(
        eventId: string,
        moduleId: string,
        speaker: Speaker,
        listCustomFields: Array<eventCustomField>,
        existUser: boolean,
        userEmail: string,
        profilePhoto: string,
        onResolve
    ) {
        speaker.$moduleId = moduleId;

        if (existUser) {
            // caso o usuário já exista
            this.db
                .collection("users")
                .where("email", "==", userEmail)
                .get()
                .then((data) => {
                    data.forEach((doc) => {
                        let docSpeaker = doc.data();
                        speaker.$uid = docSpeaker.uid;

                        let batch = this.db.batch();

                        let aux = Object.assign({}, speaker);

                        let refSpeaker = this.db
                            .collection("events")
                            .doc(eventId)
                            .collection("speakers")
                            .doc(speaker.$uid);
                        batch.set(refSpeaker, aux);

                        let refSpeakerModule = this.db
                            .collection("modules")
                            .doc(moduleId)
                            .collection("speakers")
                            .doc(speaker.$uid);
                        batch.set(refSpeakerModule, aux);

                        batch
                            .commit()
                            .then((data) => {
                                //Adiciona os custom Fields ao novo speaker
                                let batchCustom = this.db.batch();
                                for (let custom of listCustomFields) {
                                    let refCustomSpeakerEvent = this.db
                                        .collection("events")
                                        .doc(eventId)
                                        .collection("speakers")
                                        .doc(speaker.$uid)
                                        .collection("customFields")
                                        .doc(custom.uid);
                                    batchCustom.set(
                                        refCustomSpeakerEvent,
                                        custom
                                    );

                                    let refCustomSpeakerModule = this.db
                                        .collection("modules")
                                        .doc(moduleId)
                                        .collection("speakers")
                                        .doc(speaker.$uid)
                                        .collection("customFields")
                                        .doc(custom.uid);
                                    batchCustom.set(
                                        refCustomSpeakerModule,
                                        custom
                                    );
                                }

                                let updateCustomFields = null;
                                batchCustom
                                    .commit()
                                    .then((data) => {
                                        updateCustomFields = {
                                            code: 200,
                                            message: "success",
                                            result: data,
                                        };
                                    })
                                    .catch((error) => {
                                        updateCustomFields = {
                                            code: 500,
                                            message: "error",
                                            result: error,
                                        };
                                    });

                                //adiciona o evento ao path users
                                this.db
                                    .collection("users")
                                    .doc(speaker.$uid)
                                    .update({
                                        events: firebase.firestore.FieldValue.arrayUnion(
                                            eventId
                                        ),
                                        speakerModules: firebase.firestore.FieldValue.arrayUnion(
                                            moduleId
                                        ),
                                    })
                                    .catch((error) => {
                                        let result = {
                                            code: 500,
                                            message:
                                                "error when adding event to user",
                                            result: error,
                                        };

                                        onResolve(result);
                                    });

                                if (
                                    profilePhoto != null &&
                                    profilePhoto != undefined
                                ) {
                                    this.storage.profilePictureSpeakers(
                                        profilePhoto,
                                        eventId,
                                        speaker.$uid,
                                        (url) => {
                                            let batch = this.db.batch();
                                            batch.update(refSpeaker, {
                                                photoUrl: url,
                                            });
                                            batch.update(refSpeakerModule, {
                                                photoUrl: url,
                                            });

                                            batch
                                                .commit()
                                                .then((data) => {
                                                    let result = {
                                                        code: 201,
                                                        message: "success",
                                                        result: data,
                                                    };

                                                    let AllUpdates = {
                                                        speaker: result,
                                                        customFields: updateCustomFields,
                                                    };

                                                    onResolve(AllUpdates);
                                                })
                                                .catch((error) => {
                                                    let result = {
                                                        code: 500,
                                                        message:
                                                            "error-upload-profile-image",
                                                        result: error,
                                                    };

                                                    let AllUpdates = {
                                                        speaker: result,
                                                        customFields: updateCustomFields,
                                                    };

                                                    onResolve(AllUpdates);
                                                });
                                        }
                                    );
                                } else {
                                    let result = {
                                        code: 201,
                                        message: "success",
                                        result: data,
                                    };

                                    let AllUpdates = {
                                        speaker: result,
                                        customFields: updateCustomFields,
                                    };

                                    onResolve(AllUpdates);
                                }
                            })
                            .catch((error) => {
                                let result = {
                                    code: 500,
                                    message: "success",
                                    result: error,
                                };

                                let AllUpdates = {
                                    speaker: result,
                                    customFields: null,
                                };

                                onResolve(AllUpdates);
                            });
                    });
                });
        } else {
            //caso o usuário não exista
            let batch = this.db.batch();

            let ref = this.db.collection("users").doc();
            speaker.$uid = ref.id;

            console.log(speaker);
            let userAttr = this.generateUserObject(speaker, eventId, moduleId);
            console.log(userAttr);

            let auxUser = Object.assign({}, userAttr);
            batch.set(ref, auxUser);

            let auxSpeaker = Object.assign({}, speaker);
            let refSpeaker = this.db
                .collection("events")
                .doc(eventId)
                .collection("speakers")
                .doc(speaker.$uid);
            batch.set(refSpeaker, auxSpeaker);

            let refSpeakerModule = this.db
                .collection("modules")
                .doc(moduleId)
                .collection("speakers")
                .doc(speaker.$uid);
            batch.set(refSpeakerModule, auxSpeaker);

            batch
                .commit()
                .then((data) => {
                    //Adiciona os custom Fields ao novo speaker
                    let batchCustom = this.db.batch();
                    for (let custom of listCustomFields) {
                        let refCustomSpeakerEvent = this.db
                            .collection("events")
                            .doc(eventId)
                            .collection("speakers")
                            .doc(speaker.$uid)
                            .collection("customFields")
                            .doc(custom.uid);
                        batchCustom.set(refCustomSpeakerEvent, custom);

                        let refCustomSpeakerModule = this.db
                            .collection("modules")
                            .doc(moduleId)
                            .collection("speakers")
                            .doc(speaker.$uid)
                            .collection("customFields")
                            .doc(custom.uid);
                        batchCustom.set(refCustomSpeakerModule, custom);
                    }

                    let updateCustomFields = null;
                    batchCustom
                        .commit()
                        .then((data) => {
                            updateCustomFields = {
                                code: 200,
                                message: "success",
                                result: data,
                            };
                        })
                        .catch((error) => {
                            updateCustomFields = {
                                code: 500,
                                message: "error",
                                result: error,
                            };
                        });

                    if (profilePhoto != null && profilePhoto != undefined) {
                        this.storage.profilePictureSpeakers(
                            profilePhoto,
                            eventId,
                            speaker.$uid,
                            (url) => {
                                let batchPhoto = this.db.batch();

                                batchPhoto.update(refSpeaker, {
                                    photoUrl: url,
                                });
                                batchPhoto.update(refSpeakerModule, {
                                    photoUrl: url,
                                });

                                batchPhoto
                                    .commit()
                                    .then((data) => {
                                        let result = {
                                            code: 201,
                                            message: "success",
                                            result: data,
                                        };

                                        let AllUpdates = {
                                            speaker: result,
                                            customFields: updateCustomFields,
                                        };

                                        onResolve(AllUpdates);
                                    })
                                    .catch((error) => {
                                        let result = {
                                            code: 500,
                                            message:
                                                "error-upload-profile-image",
                                            result: error,
                                        };

                                        let AllUpdates = {
                                            speaker: result,
                                            customFields: updateCustomFields,
                                        };

                                        onResolve(AllUpdates);
                                    });
                            }
                        );
                    } else {
                        let result = {
                            code: 201,
                            message: "success",
                            result: data,
                        };

                        let AllUpdates = {
                            speaker: result,
                            customFields: updateCustomFields,
                        };

                        onResolve(AllUpdates);
                    }
                })
                .catch((error) => {
                    let result = {
                        code: 500,
                        message: "success",
                        result: error,
                    };

                    let AllUpdates = {
                        speaker: result,
                        customFields: null,
                    };

                    onResolve(AllUpdates);
                });
        }
    }

    generateUserObject(data, eventId, moduleId) {
        console.log(data);
        let dataFormat = {
            uid: data.uid,
            name: data.name,
            queryName: data.name.toLowerCase(),
            type: data.type,
            email: "",
            language: "",
            description: "",
            photoUrl: "",
            company: "",
            title: "",
            emailRecovery: "",
            phone: "",
            events: firebase.firestore.FieldValue.arrayUnion(eventId),
            speakerModules: null,
            firstAccess: true, //sempre true
        };

        if (data.phone != undefined) {
            dataFormat.phone = data.phone;
        }

        if (data.email != undefined) {
            dataFormat.email = data.email;
        }

        if (data.language != undefined) {
            dataFormat.language = data.language;
        }

        if (data.description != undefined) {
            dataFormat.description = data.description;
        }

        if (data.photoUrl != undefined) {
            dataFormat.photoUrl = data.photoUrl;
        }

        if (data.company != undefined) {
            dataFormat.company = data.company;
        }

        if (data.title != undefined) {
            dataFormat.title = data.title;
        }

        console.log(data.emailRecovery);
        if (data.emailRecovery != undefined) {
            dataFormat.emailRecovery = data.emailRecovery;
        }

        dataFormat.events = firebase.firestore.FieldValue.arrayUnion(eventId);

        dataFormat.speakerModules = firebase.firestore.FieldValue.arrayUnion(
            moduleId
        );

        // if (data.firstAccess != undefined) {
        //     dataFormat.firstAccess = data.firstAccess;
        // }

        return dataFormat;
    }

    editSpeaker(
        eventId,
        moduleId,
        speaker,
        listCustomFields,
        photoProfile,
        changeEmail,
        onResolve
    ) {
        speaker = Object.assign({}, speaker);

        if (
            speaker.email !== "" &&
            speaker.email !== null &&
            speaker.email !== undefined
        ) {
            if (speaker.firstAccess == false) {
                this.auth.updateUserAuth(speaker.uid, speaker, (_) => {});
            }
            this.verifySpeakerInUsers(speaker.email).then((user) => {
                if (user !== false) {
                    speaker.oldId = speaker.uid;
                    speaker.uid = user["uid"];
                    this.remakeSpeaker(
                        eventId,
                        moduleId,
                        speaker,
                        listCustomFields,
                        photoProfile,
                        changeEmail
                    ).then((status) => {
                        onResolve(status);
                    });
                } else {
                    this.saveUpdateSpeaker(
                        eventId,
                        moduleId,
                        speaker,
                        listCustomFields,
                        photoProfile,
                        changeEmail
                    ).then((status) => {
                        onResolve(status);
                    });
                }
            });
        } else {
            this.saveUpdateSpeaker(
                eventId,
                moduleId,
                speaker,
                listCustomFields,
                photoProfile,
                changeEmail
            ).then((status) => {
                onResolve(status);
            });
        }
    }

    remakeSpeaker(
        eventId,
        moduleId,
        speaker,
        listCustomFields,
        photoProfile,
        changeEmail
    ) {
        return new Promise(async (resolve) => {
            let batchCustom = this.db.batch();
            for (let custom of listCustomFields) {
                let refCustomSpeakerEvent = this.db
                    .collection("events")
                    .doc(eventId)
                    .collection("speakers")
                    .doc(speaker.uid)
                    .collection("customFields")
                    .doc(custom.uid);
                batchCustom.set(refCustomSpeakerEvent, custom);

                let refCustomSpeakerModule = this.db
                    .collection("modules")
                    .doc(moduleId)
                    .collection("speakers")
                    .doc(speaker.uid)
                    .collection("customFields")
                    .doc(custom.uid);
                batchCustom.set(refCustomSpeakerModule, custom);
            }

            let updateCustomFields = null;
            batchCustom
                .commit()
                .then((data) => {
                    updateCustomFields = {
                        code: 200,
                        message: "success",
                        result: data,
                    };
                })
                .catch((error) => {
                    updateCustomFields = {
                        code: 500,
                        message: "error",
                        result: error,
                    };
                });

            let batch = this.db.batch();

            let refSpeakers = this.db
                .collection("events")
                .doc(eventId)
                .collection("speakers")
                .doc(speaker.uid);
            batch.set(refSpeakers, speaker);

            let refSpeakersModule = this.db
                .collection("modules")
                .doc(moduleId)
                .collection("speakers")
                .doc(speaker.uid);
            batch.set(refSpeakersModule, speaker);

            let emailRecovery = speaker.emailRecovery;
            let refUser = this.db.collection("users").doc(speaker.uid);

            if (changeEmail) {
                if (emailRecovery !== "") {
                    batch.update(refUser, {
                        email: speaker.email,
                        emailRecovery: emailRecovery,
                    });
                } else {
                    batch.update(refUser, { email: speaker.email });
                }
            } else {
                if (emailRecovery !== "") {
                    batch.update(refUser, {
                        email: speaker.email,
                        emailRecovery: emailRecovery,
                    });
                }
            }

            batch
                .commit()
                .then(async (data) => {
                    if (
                        photoProfile != null &&
                        photoProfile != "" &&
                        photoProfile != undefined
                    ) {
                        console.log('hyer')
                        this.storage.profilePictureSpeakers(
                            photoProfile,
                            eventId,
                            speaker.uid,
                            (url) => {
                                let batchPhoto = this.db.batch();
                                batchPhoto.update(refSpeakers, {
                                    photoUrl: url,
                                });
                                batchPhoto.update(refSpeakersModule, {
                                    photoUrl: url,
                                });

                                batchPhoto
                                    .commit()
                                    .then(async (data) => {
                                        let result = {
                                            code: 200,
                                            message: "success",
                                            result: data,
                                        };

                                        let AllUpdates = {
                                            speaker: result,
                                            customFields: updateCustomFields,
                                        };
                                        await this.updateSessions(eventId, speaker); //update sessions
                                        resolve(AllUpdates);
                                    })
                                    .catch((error) => {
                                        let result = {
                                            code: 500,
                                            message: "error-upload-image",
                                            result: error,
                                        };

                                        let AllUpdates = {
                                            speaker: result,
                                            customFields: updateCustomFields,
                                        };

                                        resolve(AllUpdates);
                                    });
                            }
                        );
                    } else {
                        let result = {
                            code: 200,
                            message: "success",
                            result: data,
                        };

                        let AllUpdates = {
                            speaker: result,
                            customFields: updateCustomFields,
                        };

                        await this.updateSessions(eventId, speaker); //update sessions
                        resolve(AllUpdates);
                    }
                })
                .catch((error) => {
                    let result = {
                        code: 500,
                        message: "error",
                        result: error,
                    };

                    let AllUpdates = {
                        speaker: result,
                        customFields: updateCustomFields,
                    };

                    resolve(AllUpdates);
                });


        });
    }

    getCustomFieldsFirstTime(moduleId, speakerId, onResolve) {
        let db = this.aFirestore.firestore;
        let ref = db.collection('modules').doc(moduleId).collection('speakers').doc(speakerId).collection('customFields');
        //let ref = db.collection('modules').doc(moduleId).collection('attendees').doc(attendeeId);

        ref.get()
            .then((data) => {
                let listCustomField = [];
                data.forEach(element => {
                    let custom = element.data();
                    listCustomField.push(custom);
                });
                console.log(listCustomField)
                let result = {
                    message: "success",
                    result: listCustomField
                }
                onResolve(result);
            })
            .catch((error) => {

                let result = {
                    message: "error",
                    result: error
                }
                onResolve(result);
            })
    }

    getCustomFieldsOfUserFirstTime(moduleId, speakerId, onResolve) {
        let db = this.aFirestore.firestore;
        let ref = db.collection('modules').doc(moduleId).collection('speakers').doc(speakerId).collection('customFields');
        //let ref = db.collection('modules').doc(moduleId).collection('attendees').doc(attendeeId);

        ref.get()
        .then((data) => {
            let listCustomField = [];
            data.forEach(element => {
                listCustomField.push(element.data());
            });
            console.log(listCustomField)
            let result = {
                message: "success",
                result: listCustomField
            }
            onResolve(result);
        })
        .catch((error) => {

            let result = {
                message: "error",
                result: error
            }
            onResolve(result);
        })
    }


    saveUpdateSpeaker(
        eventId,
        moduleId,
        speaker,
        listCustomFields,
        photoProfile,
        changeEmail
    ) {
        return new Promise(async (resolve) => {
            let batchCustom = this.db.batch();

            for (let custom of listCustomFields) {
                if (custom.type == "text") {
                    let refCustomSpeakerEvent = this.db
                        .collection("events")
                        .doc(eventId)
                        .collection("speakers")
                        .doc(speaker.uid)
                        .collection("customFields")
                        .doc(custom.uid);
                    batchCustom.update(refCustomSpeakerEvent, {
                        textValue: custom.textValue,
                    });

                    let refCustomSpeakerModule = this.db
                        .collection("modules")
                        .doc(moduleId)
                        .collection("speakers")
                        .doc(speaker.uid)
                        .collection("customFields")
                        .doc(custom.uid);
                    batchCustom.update(refCustomSpeakerModule, {
                        textValue: custom.textValue,
                    });
                } else {
                    let refCustomSpeakerEvent = this.db
                        .collection("events")
                        .doc(eventId)
                        .collection("speakers")
                        .doc(speaker.uid)
                        .collection("customFields")
                        .doc(custom.uid);
                    batchCustom.update(refCustomSpeakerEvent, {
                        value: custom.value,
                    });

                    let refCustomSpeakerModule = this.db
                        .collection("modules")
                        .doc(moduleId)
                        .collection("speakers")
                        .doc(speaker.uid)
                        .collection("customFields")
                        .doc(custom.uid);
                    batchCustom.update(refCustomSpeakerModule, {
                        value: custom.value,
                    });
                }
            }

            let updateCustomFields = null;
            batchCustom
                .commit()
                .then((data) => {
                    updateCustomFields = {
                        code: 200,
                        message: "success",
                        result: data,
                    };
                })
                .catch((error) => {
                    updateCustomFields = {
                        code: 500,
                        message: "error",
                        result: error,
                    };
                });

            let batch = this.db.batch();

            let refSpeakers = this.db
                .collection("events")
                .doc(eventId)
                .collection("speakers")
                .doc(speaker.uid);
            batch.update(refSpeakers, speaker);

            let refSpeakersModule = this.db
                .collection("modules")
                .doc(moduleId)
                .collection("speakers")
                .doc(speaker.uid);
            batch.update(refSpeakersModule, speaker);

            let refUsers = this.db.collection("users").doc(speaker.uid);
            batch.update(refUsers, { email: speaker.email });

            // ********************************************* session ****************************************************************
            await this.updateSessions(eventId, speaker);

            batch
                .commit()
                .then((data) => {
                    if (
                        photoProfile != null &&
                        photoProfile != "" &&
                        photoProfile != undefined
                    ) {
                        this.storage.profilePictureSpeakers(
                            photoProfile,
                            eventId,
                            speaker.uid,
                            (url) => {
                                let batchPhoto = this.db.batch();
                                batchPhoto.update(refSpeakers, {
                                    photoUrl: url,
                                });
                                batchPhoto.update(refSpeakersModule, {
                                    photoUrl: url,
                                });

                                batchPhoto
                                    .commit()
                                    .then((data) => {
                                        let result = {
                                            code: 200,
                                            message: "success",
                                            result: data,
                                        };

                                        let AllUpdates = {
                                            speaker: result,
                                            customFields: updateCustomFields,
                                        };

                                        resolve(AllUpdates);
                                    })
                                    .catch((error) => {
                                        let result = {
                                            code: 500,
                                            message: "error-upload-image",
                                            result: error,
                                        };

                                        let AllUpdates = {
                                            speaker: result,
                                            customFields: updateCustomFields,
                                        };

                                        resolve(AllUpdates);
                                    });
                            }
                        );
                    } else {
                        let result = {
                            code: 200,
                            message: "success",
                            result: data,
                        };

                        let AllUpdates = {
                            speaker: result,
                            customFields: updateCustomFields,
                        };

                        resolve(AllUpdates);
                    }
                })
                .catch((error) => {
                    let result = {
                        code: 500,
                        message: "error",
                        result: error,
                    };

                    let AllUpdates = {
                        speaker: result,
                        customFields: updateCustomFields,
                    };

                    resolve(AllUpdates);
                });
        });
    }

    verifySpeakerInUsers(email: string) {
        return new Promise((resolve) => {
            this.db
                .collection("users")
                .where("email", "==", email)
                .get()
                .then((values) => {
                    if (values.size >= 1) {
                        values.forEach((element) => {
                            resolve(element.data());
                        });
                    } else {
                        resolve(false);
                    }
                });
        });
    }

    /**
     * Update attendees field value
     * @param eventId
     * @param moduleId
     * @param attendeesField
     */
    updateAttendeesField(
        eventId: string,
        moduleId: string,
        attendeesField: string
    ) {
        let db = this.aFirestore.firestore;

        const ref1 = db
            .collection("events")
            .doc(eventId)
            .collection("modules")
            .doc(moduleId);
        const ref2 = db.collection("modules").doc(moduleId);

        let batch = this.db.batch();

        batch.update(ref1, { attendeesField: attendeesField });
        batch.update(ref2, { attendeesField: attendeesField });

        batch
            .commit()
            .then((_) => {
                return true;
            })
            .catch(() => {
                return false;
            });
    }

    removeSpeakers(eventId, moduleId, listRemoveUids, onResolve) {
        // remove speakers. Activates trigger:dbSpeakersDelete
        for (const speakerId of listRemoveUids) {
            this.db
                .collection("modules")
                .doc(moduleId)
                .collection("speakers")
                .doc(speakerId)
                .delete();
            this.db
                .collection("events")
                .doc(eventId)
                .collection("speakers")
                .doc(speakerId)
                .delete();
        }
        onResolve(true);
    }

    removeSpeakerAllEvents(userId, onResolve) {
        let refUser = this.db.collection("users").doc(userId);

        refUser.get().then((data) => {
            let user = data.data();

            let batch = this.db.batch();
            for (let moduleId of user.speakerModules) {
                let refModule = this.db
                    .collection("modules")
                    .doc(moduleId)
                    .collection("speakers")
                    .doc(user.uid);
                batch.delete(refModule);
            }

            for (let eventId of user.events) {
                let refEvent = this.db
                    .collection("events")
                    .doc(eventId)
                    .collection("speakers")
                    .doc(user.uid);
                batch.delete(refEvent);
            }

            this.auth.removeUserAuth(user.uid, (data) => {
                if (data.result == true) {
                    batch
                        .commit()
                        .then(() => {
                            onResolve(true);
                        })
                        .catch((err) => {
                            onResolve(err);
                        });
                }
            });
        });
    }

    breakArray(base, max) {
        var resultado = [[]];
        var grupo = 0;

        if (base.length < max) {
            resultado[grupo] = base;
            return resultado;
        }

        for (var indice = 0; indice < base.length; indice++) {
            if (resultado[grupo] === undefined) {
                resultado[grupo] = [];
            }

            resultado[grupo].push(base[indice]);

            if ((indice + 1) % max === 0) {
                grupo = grupo + 1;
            }
        }

        return resultado;
    }

    changeOrderItems(eventId: string, moduleId, typeOrder, onResolve) {
        let db = this.aFirestore.firestore;
        db.collection("modules")
            .doc(moduleId)
            .update({ orderUsers: typeOrder });
        db.collection("events")
            .doc(eventId)
            .collection("modules")
            .doc(moduleId)
            .update({ orderUsers: typeOrder })
            .then((_) => {
                onResolve(true);
            })
            .catch((__) => {
                onResolve(false);
            });
    }

    // ======================================================================== //

    updateModule(moduleId: string, fieldsCustom) {
        let db = this.aFirestore.firestore;

        let ref = db.collection("modules").doc(moduleId);

        ref.update({ fieldsCustom: fieldsCustom });
    }

    createCustomField(
        eventId: string,
        moduleId: string,
        customField: eventCustomField,
        listOptions: Array<eventCustomFieldAnswerOption>,
        onResolve
    ) {
        let refModuleCustom = this.db
            .collection("modules")
            .doc(moduleId)
            .collection("customFields")
            .doc();
        let refModule = this.db.collection("modules").doc(moduleId);
        customField.uid = refModuleCustom.id;
        customField = Object.assign({}, customField);
        customField.name = Object.assign({}, customField.name);
        customField.textValue = Object.assign({}, customField.textValue);
        refModuleCustom
            .set(customField)
            .then(() => {
                refModule.get().then((snapshot) => {
                    let module = snapshot.data();
                    let order;
                    if (
                        module["fieldsCustom"] == undefined ||
                        Object.keys(module["fieldsCustom"]).length === 0
                    ) {
                        order = 0;
                    } else {
                        order = Object.keys(module["fieldsCustom"]).length;
                    }

                    let updates = {};
                    updates[`fieldsCustom.${customField.uid}`] = {
                        required: false,
                        unique_edit: false,
                        order: order,
                    };
                    refModule.update(updates);

                    if (listOptions != null && listOptions != undefined) {
                        if (listOptions.length > 0) {
                            for (let option of listOptions) {
                                option = Object.assign({}, option);
                                option.answer = Object.assign(
                                    {},
                                    option.answer
                                );
                                refModuleCustom
                                    .collection("options")
                                    .add(option)
                                    .then((docRef) => {
                                        docRef.update({
                                            uid: docRef.id,
                                        });
                                        onResolve({
                                            code: 200,
                                            result: customField,
                                        });
                                    })
                                    .catch((error) => {
                                        onResolve({
                                            code: 400,
                                            result: error,
                                        });
                                    });
                            }
                        }
                    } else {
                        onResolve({
                            code: 200,
                            result: customField,
                        });
                    }
                });
            })
            .catch((e) => {
                onResolve({
                    code: 400,
                    result: e,
                });
            });
    }

    getCustomFields(moduleId, onResolve) {
        let db = this.aFirestore.firestore;

        let ref = db
            .collection("modules")
            .doc(moduleId)
            .collection("customFields");

        ref.get()
            .then((data) => {
                let listCustomField = [];
                data.forEach((element) => {
                    let custom = element.data();
                    listCustomField.push(custom);
                });

                let result = {
                    message: "success",
                    result: listCustomField,
                };
                onResolve(result);
            })
            .catch((error) => {
                let result = {
                    message: "error",
                    result: error,
                };
                onResolve(result);
            });
    }

    getCustomFieldsOfUser(moduleId, speakerId, onResolve) {
        let db = this.aFirestore.firestore;
        let ref = db
            .collection("modules")
            .doc(moduleId)
            .collection("speakers")
            .doc(speakerId)
            //.collection("customFields");

            ref.get().then((data) => {
                let listCustomFields = [];
    
                listCustomFields = data.data()['customFields'];
                onResolve(listCustomFields);
            });
    }

    getCustomFieldOptions(moduleId: string, customId: string, onResolve) {
        let db = this.aFirestore.firestore;

        let ref = db
            .collection("modules")
            .doc(moduleId)
            .collection("customFields")
            .doc(customId)
            .collection("options");

        ref.get().then((data) => {
            let listOptions = [];

            data.forEach((element) => {
                let option = element.data();

                listOptions.push(option);
            });

            onResolve(listOptions);
        });
    }

    getOptionsCustomField(moduleId, customId, onResolve) {
        let db = this.aFirestore.firestore;

        let ref = db
            .collection("modules")
            .doc(moduleId)
            .collection("customFields")
            .doc(customId)
            .collection("options");

        ref.get()
            .then((data) => {
                let listOption = [];
                data.forEach((element) => {
                    let option = element.data();

                    listOption.push(option);
                });

                let result = {
                    message: "success",
                    result: listOption,
                };

                onResolve(result);
            })
            .catch((error) => {
                let result = {
                    message: "error",
                    result: error,
                };

                onResolve(result);
            });
    }

    getFieldOptionsCustom(moduleId: string, onResolve) {
        let db = this.aFirestore.firestore;

        db.collection("modules")
            .doc(moduleId)
            .get()
            .then((value) => {
                let aux = value.data();
                onResolve(aux["fieldsCustom"]);
            });
    }

    editCustomField(
        eventId,
        moduleId,
        customField,
        oldCustomField,
        listOptions,
        listOptionsRemove,
        onResolve
    ) {
        let body = {
            eventId: eventId,
            moduleId: moduleId,
            customField: customField,
            oldCustomField: oldCustomField,
            listOptionsCustomEdit: listOptions,
            listOptionsCustomRemove: listOptionsRemove,
        };

        this.http
            .post(
                PathApi.baseUrl + PathApi.dbCustomFieldSpeakerEdit,
                body,
                this.requestOptions
            )
            .subscribe(
                (data) => {
                    onResolve(data);
                },
                (err) => {
                    onResolve(err);
                }
            );
    }

    updateOptionCustomField(moduleId, customId, option) {
        let db = this.aFirestore.firestore;

        let ref = db
            .collection("modules")
            .doc(moduleId)
            .collection("customFields")
            .doc(customId)
            .collection("options")
            .doc(option.uid);

        ref.update(option);
    }

    createOptionCustomField(moduleId, customId, option) {
        let db = this.aFirestore.firestore;
        option = Object.assign({}, option);

        let ref = db
            .collection("modules")
            .doc(moduleId)
            .collection("customFields")
            .doc(customId)
            .collection("options")
            .doc();
        option.uid = ref.id;

        ref.set(option);
    }

    removeOptionOfSpeakers(eventId, moduleId, customId, optionId) {
        let db = this.aFirestore.firestore;

        db.collection("modules")
            .doc(moduleId)
            .collection("speakers")
            .get()
            .then((data) => {
                //passa por todos os documentos
                data.forEach((doc) => {
                    let speaker = doc.data();

                    let refSpeakerEvent = db
                        .collection("events")
                        .doc(eventId)
                        .collection("speakers")
                        .doc(speaker.uid)
                        .collection("customFields")
                        .doc(customId);

                    let refSpeakerModule = db
                        .collection("modules")
                        .doc(moduleId)
                        .collection("speakers")
                        .doc(speaker.uid)
                        .collection("customFields")
                        .doc(customId);

                    refSpeakerModule.get().then((data) => {
                        let custom = data.data();

                        if (custom.value == optionId) {
                            let batch = db.batch();

                            batch.update(refSpeakerEvent, { value: "" });
                            batch.update(refSpeakerModule, { value: "" });

                            batch
                                .commit()
                                .then(() => {})
                                .catch(() => {});
                        }
                    });
                });
            });
    }

    removeOptionCustomField(moduleId, customId, optionId) {
        let db = this.aFirestore.firestore;
        let ref = db
            .collection("modules")
            .doc(moduleId)
            .collection("customFields")
            .doc(customId)
            .collection("options")
            .doc(optionId);

        ref.delete();
    }

    clearUsersResultCustom(eventId, moduleId, customId) {
        let db = this.aFirestore.firestore;

        db.collection("modules")
            .doc(moduleId)
            .collection("speakers")
            .get()
            .then((data) => {
                //passa por todos os documentos
                data.forEach((doc) => {
                    let speaker = doc.data();

                    let refSpeakerEvent = db
                        .collection("events")
                        .doc(eventId)
                        .collection("speakers")
                        .doc(speaker.uid)
                        .collection("customFields")
                        .doc(customId);

                    let refSpeakerModule = db
                        .collection("modules")
                        .doc(moduleId)
                        .collection("speakers")
                        .doc(speaker.uid)
                        .collection("customFields")
                        .doc(customId);

                    let batch = db.batch();

                    batch.update(refSpeakerEvent, { value: "" });
                    batch.update(refSpeakerModule, { value: "" });

                    batch
                        .commit()
                        .then(() => {})
                        .catch(() => {});
                });
            });
    }

    deleteCustomField(eventId, moduleId, customId, onResolve) {
        this.requestOptions.params.eventId = eventId;
        this.requestOptions.params.moduleId = moduleId;
        this.requestOptions.params.customId = customId;

        this.http
            .delete(
                PathApi.baseUrl + PathApi.dbCustomFieldSpeakerDelete,
                this.requestOptions
            )
            .subscribe(
                (data) => {
                    onResolve(data);
                },

                (err) => {
                    onResolve(err);
                }
            );
    }

    // ======================================================================== //

    // ================================= CONFIG MODULE ====================================
    getAllowedEditProfile(moduleId, onResolve) {
        let db = this.aFirestore.firestore;

        db.collection("modules")
            .doc(moduleId)
            .get()
            .then((data) => {
                let aux = data.data();
                let result = aux.allowedEditProfile;

                onResolve(result);
            });
    }

    changeAllowedEditProfile(moduleId, status, onResolve) {
        let db = this.aFirestore.firestore;

        db.collection("modules")
            .doc(moduleId)
            .update({
                allowedEditProfile: status,
            })
            .then(() => {
                onResolve("success");
            })
            .catch(() => {
                onResolve("error");
            });
    }

    // ==================================================================================

    importSpeakers(
        eventId: string,
        moduleId: string,
        speakers: Array<Speaker>,
        groupModuleId,
        language,
        onResolve
    ) {
        let body = {
            eventId: eventId,
            moduleId: moduleId,
            speakers: speakers,
            groupModuleId: groupModuleId,
            eventLanguage: language,
        };
        this.http
            .post(
                PathApi.baseUrl + PathApi.dbSpeakerImportSpeaker,
                body,
                this.requestOptions
            )
            .subscribe((result) => {
                onResolve(result);
            });
    }

    exportSpeakers(moduleId: string, typeOrder: string, onResolve) {
        this.http
            .get(
                PathApi.baseUrl +
                    PathApi.dbSpeakerExportSpeakers +
                    "?moduleId=" +
                    moduleId +
                    "&typeOrder=" +
                    typeOrder,
                this.requestOptions
            )
            .subscribe((data) => {
                onResolve(data);
            });
    }

    getSpeakersFieldOptions(moduleId: string, onResolve) {
        let db = this.aFirestore.firestore;

        db.collection("modules")
            .doc(moduleId)
            .get()
            .then((value) => {
                let aux = value.data();
                onResolve(aux["fields"]);
            });
    }

    getCustomSpeakersFieldOptions(moduleId: string, onResolve) {
        let db = this.aFirestore.firestore;

        db.collection("modules")
            .doc(moduleId)
            .get()
            .then((value) => {
                let aux = value.data();
                onResolve(aux["fieldsCustom"]);
            });
    }

    changeRequiredField(
        moduleId: string,
        field: string,
        value: boolean,
        allFields
    ) {
        let db = this.aFirestore.firestore;

        allFields[field].required = value;

        db.collection("modules").doc(moduleId).update({
            fields: allFields,
        });
    }

    changeUniqueEditField(
        moduleId: string,
        field: string,
        value: boolean,
        allFields
    ) {
        let db = this.aFirestore.firestore;

        allFields[field].unique_edit = value;

        db.collection("modules").doc(moduleId).update({
            fields: allFields,
        });
    }

    changeHideField(
        moduleId: string,
        field: string,
        value: boolean,
        allFields
    ) {
        let db = this.aFirestore.firestore;

        allFields[field].hide_field = value;

        db.collection("modules").doc(moduleId).update({
            fields: allFields,
        });
    }

    changeRequiredFieldCustom(
        moduleId: string,
        field: string,
        value: boolean,
        allFields
    ) {
        let db = this.aFirestore.firestore;

        allFields[field].required = value;

        db.collection("modules").doc(moduleId).update({
            fieldsCustom: allFields,
        });
    }

    changeUniqueEditFieldCustom(
        moduleId: string,
        field: string,
        value: boolean,
        allFields
    ) {
        let db = this.aFirestore.firestore;

        allFields[field].unique_edit = value;

        db.collection("modules").doc(moduleId).update({
            fieldsCustom: allFields,
        });
    }

    changeHideFieldCustom(
        eventId: string,
        moduleId: string,
        field: string,
        value: boolean,
        allFields
    ) {
        let db = this.aFirestore.firestore;

        allFields[field].hide_field = value;

        db.collection("modules").doc(moduleId).update({
            fieldsCustom: allFields,
        });
    }

    changeOrderCustoms(moduleId: string, allFields, onResolve) {
        let db = this.aFirestore.firestore;

        db.collection("modules")
            .doc(moduleId)
            .update({
                fieldsCustom: allFields,
            })
            .then(() => {
                onResolve(true);
            })
            .catch(() => {
                onResolve(false);
            });
    }

    // ************************************************************* SESSIONS  **************************************************************************************
    // vpdate the sessions that the speaker has
    updateSessions(eventId: string, speaker: any): Promise<boolean> {
        return new Promise(async (resolve) => {
            let db = this.aFirestore.firestore;
            speaker = Object.assign({}, speaker);
            const speakerId = speaker.uid;

            // Lists all schedule modules of the event.
            const modules = await this.getModulesSheduleEvent(eventId);

            for (const module of modules) {
                const moduleScheduleId = module.uid;

                // lists the sessions of the module that has the speaker.
                const sessions = await this.getSessionsOfSpeaker(
                    speakerId,
                    moduleScheduleId
                );

                // update sessions
                for (const session of sessions) {
                    const sessionId = session.uid;

                    // update session.
                    session.speakers[speakerId] = speaker;
                    db.collection("events")
                        .doc(eventId)
                        .collection("sessions")
                        .doc(sessionId)
                        .update(session);
                    db.collection("modules")
                        .doc(moduleScheduleId)
                        .collection("sessions")
                        .doc(sessionId)
                        .update(session);
                }
            }

            resolve(true);
        });
    }

    getSession(sessionId: string, moduleId: string) {
        return new Promise((resolve) => {
            const ref = this.db
                .collection("modules")
                .doc(moduleId)
                .collection("sessions")
                .doc(sessionId);

            ref.onSnapshot((snapshot) => {
                const session = snapshot.data();
                resolve(session);
            });
        });
    }

    modulePersonalAgendaEvent(eventId) {
        return new Promise((resolve) => {
            let module = null;
            const ref = this.db
                .collection("events")
                .doc(eventId)
                .collection("modules")
                .where("type", "==", TypeModule.PERSONALSCHEDULE);

            ref.get().then((snapshot) => {
                snapshot.forEach((childSnapshot) => {
                    module = childSnapshot.data();
                });

                resolve(module);
            });
        });
    }

    // Lists all schedule modules of the event.
    getModulesSheduleEvent(eventId: string): Promise<Array<ModuleSchedule>> {
        return new Promise((resolve) => {
            const list = [];

            const ref = this.db
                .collection("events")
                .doc(eventId)
                .collection("modules")
                .where("type", "==", TypeModule.SCHEDULE);

            ref.get().then((snapshot) => {
                snapshot.forEach((childSnapshot) => {
                    list.push(childSnapshot.data());
                });

                resolve(list);
            });
        });
    }

    // lists the sessions of the module that has the speaker.
    getSessionsOfSpeaker(
        speakerId: string,
        moduleScheduleId: string
    ): Promise<Array<Session>> {
        return new Promise((resolve) => {
            const list = [];
            const speaker = `speakers.${speakerId}`;

            const ref = this.db
                .collection("modules")
                .doc(moduleScheduleId)
                .collection("sessions")
                .orderBy(speaker);

            ref.get().then((snapshot) => {
                snapshot.forEach((element) => {
                    list.push(element.data());
                });

                resolve(list);
            });
        });
    }

    takeChangesInModule(moduleId: string, eventId: string, changes) {
        let db = this.aFirestore.firestore;

        let refModule = db.collection("modules").doc(moduleId);
        let refEventModule = db
            .collection("events")
            .doc(eventId)
            .collection("modules")
            .doc(moduleId);

        refModule.update(changes);
        refEventModule.update(changes);
    }

    resetAccount(speaker: Speaker) {
        return new Promise((resolve, reject) => {
            this.auth.verifyEmailAuth(speaker["email"]).then((response) => {
                if (response["code"] == 0) {
                    // user exist
                    resolve(0);
                    this.reconfigureUser(response["uid"], speaker).then(
                        (snap) => {
                            if (snap) {
                                resolve(0);
                            } else {
                                reject(2);
                            }
                        }
                    );
                } else if (response["code"] == 1) {
                    // user doesn't exist
                    resolve(1);
                } else if (response["code"] == 2) {
                    // error to find e-mail
                    reject(2);
                }
            });
        });
    }

    reconfigureUser(authUid: string, speaker: Speaker) {
        return new Promise((resolve, reject) => {
            let db = this.aFirestore.firestore;
            let oldUid;
            let user = null;

            db.collection("users")
                .where("email", "==", speaker["email"])
                .get()
                .then((snapshot) => {
                    if (snapshot.size >= 1) {
                        snapshot.forEach((element) => {
                            if (user == null) {
                                user = element.data();
                                let aux = user;
                                oldUid = user.uid;

                                let eventsId = aux.events;
                                let data = {
                                    name: aux.name,
                                    type: aux.type,
                                    email: aux.email,
                                    photoUrl: aux.photoUrl,
                                    createdAt: aux.createdAt,
                                    company: aux.company,
                                    title: aux.title,
                                    description: aux.description,
                                    recoveryEmail: aux.recoveryEmail,
                                    uid: authUid,
                                    events: aux.events,
                                    speakerModules: aux.speakerModules,
                                    firstAccess: false,
                                    language: aux.language,
                                    edited_profile: false,
                                };

                                let userObj = this.userModelDataSpeaker(data);
                                let cont = 0;
                                for (let idEvent of eventsId) {
                                    let batch = null;
                                    batch = db.batch();
                                    // apgar no path users antigo

                                    let oldUserRef = db
                                        .collection("users")
                                        .doc(oldUid);
                                    batch.delete(oldUserRef);

                                    // adicionar no path users o novo
                                    let newUserRef = db
                                        .collection("users")
                                        .doc(authUid);
                                    batch.set(newUserRef, userObj);

                                    let oldSpeakerRef = db
                                        .collection("events")
                                        .doc(idEvent)
                                        .collection("speakers")
                                        .doc(oldUid);
                                    let newSpeakerRef = db
                                        .collection("events")
                                        .doc(idEvent)
                                        .collection("speakers")
                                        .doc(authUid);

                                    let listCustomField = [];
                                    oldSpeakerRef
                                        .collection("customFields")
                                        .get()
                                        .then((data) => {
                                            data.forEach((doc) => {
                                                let custom = doc.data();
                                                listCustomField.push(custom);
                                            });
                                            // console.log(listCustomField)
                                        });

                                    oldSpeakerRef.get().then((data) => {
                                        let speaker = data.data();
                                        speaker.uid = authUid;
                                        speaker.firstAccess = false;
                                        let oldModulesSpeakerRef = db
                                            .collection("modules")
                                            .doc(speaker.moduleId)
                                            .collection("speakers")
                                            .doc(oldUid);
                                        let newModulesSpeakerRef = db
                                            .collection("modules")
                                            .doc(speaker.moduleId)
                                            .collection("speakers")
                                            .doc(authUid);
                                        // apagar no path events -> speakers
                                        batch.delete(oldSpeakerRef);
                                        // apagar no path modules -> speakers antigo
                                        batch.delete(oldModulesSpeakerRef);

                                        // adicionar no path events -> speakers
                                        batch.set(newSpeakerRef, speaker);
                                        // adicionar no path modules -> speakers novo
                                        batch.set(
                                            newModulesSpeakerRef,
                                            speaker
                                        );

                                        batch
                                            .commit()
                                            .then((batchOk) => {
                                                let batchCustom = db.batch();
                                                for (let custom of listCustomField) {
                                                    let refCustomEventSpeaker = newSpeakerRef
                                                        .collection(
                                                            "customFields"
                                                        )
                                                        .doc(custom.uid);
                                                    let refCustomModuleSpeaker = newModulesSpeakerRef
                                                        .collection(
                                                            "customFields"
                                                        )
                                                        .doc(custom.uid);

                                                    batchCustom.set(
                                                        refCustomEventSpeaker,
                                                        custom
                                                    );
                                                    batchCustom.set(
                                                        refCustomModuleSpeaker,
                                                        custom
                                                    );
                                                }

                                                batchCustom
                                                    .commit()
                                                    .then(() => {});

                                                if (
                                                    cont ==
                                                    eventsId.length - 1
                                                ) {
                                                    resolve(true);
                                                }
                                                cont++;
                                            })
                                            .catch((batchError) => {
                                                // remove da autenticação caso dê erro no banco
                                                console.log(batchError);
                                                // admin.auth().deleteUser(authUid);

                                                reject(false);
                                            });
                                    });
                                }
                            }
                        });
                    }
                });
        });
    }

    userModelDataSpeaker(user) {
        let userFormat = {
            name: null,
            type: null,
            email: "",
            language: "",
            description: "",
            photoUrl: "",
            company: "",
            title: "",
            recoveryEmail: "",
            events: user.events,
            speakerModules: user.speakerModules,
            firstAccess: null,
            uid: "",
            edited_profile: false,
        };

        if (user.name != undefined) {
            userFormat.name = user.name;
        }

        if (user.type != undefined) {
            userFormat.type = user.type;
        }

        if (user.email != undefined) {
            userFormat.email = user.email;
        }

        if (user.language != undefined) {
            userFormat.language = user.language;
        }

        if (user.description != undefined) {
            userFormat.description = user.description;
        }

        if (user.photoUrl != undefined) {
            userFormat.photoUrl = user.photoUrl;
        }

        if (user.company != undefined) {
            userFormat.company = user.company;
        }

        if (user.title != undefined) {
            userFormat.title = user.title;
        }

        if (user.recoveryEmail != undefined) {
            userFormat.recoveryEmail = user.recoveryEmail;
        }

        userFormat.events = user.events;

        userFormat.speakerModules = user.speakerModules;

        if (user.firstAccess != undefined) {
            userFormat.firstAccess = user.firstAccess;
        }

        if (user.uid != undefined) {
            userFormat.uid = user.uid;
        }

        return userFormat;
    }

    editFilterVisibility(moduleId: string, status: boolean,type: string, onResolve) {
        let db = this.aFirestore.firestore;
        let moduleRef = db.collection('modules').doc(moduleId);
        const obj = {}
        if(type === 'company'){
            obj['allowFilterCompany'] = status
        } else if(type === 'post') {
            obj['allowFilterPost'] = status
        }
        moduleRef.update(obj).then(() => {
            onResolve({type: type, value: true});
        })
        .catch(() => {
            onResolve({type: type, value: false});
        });
    }

    editPropVisibility(moduleId: string, prop: string, status: any, onResolve) {
        let db = this.aFirestore.firestore;
        const obj = {}
        obj[prop] = status
        db.collection('modules').doc(moduleId)
            .update(obj)
            .then(() => {
                onResolve(true);
            })
            .catch(() => {
                onResolve(false);
            });
    }
}
