import { Component, OnInit, ViewChild } from "@angular/core";
import {
    FormGroup,
    Validators,
    FormBuilder,
    FormControl,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { Session } from "../../../../../models/session";
import { Location } from "../../../../../models/location";
import { LuxonService } from "src/app/providers/luxon/luxon.service";
import { DbEventsProvider } from "src/app/providers/database/db.events";
import { DbScheduleProvider } from "src/app/providers/database/db-schedule";
import { SwalComponent } from "@sweetalert2/ngx-sweetalert2";
import { DbLocationsProvider } from "src/app/providers/database/db-locations";
import { Track } from "src/app/models/track";
import { Group } from "src/app/models/group";
import { DbGroupsProvider } from "src/app/providers/database/db-groups";
import { DbSpeakersProvider } from "src/app/providers/database/db-speakers";
import { Speaker } from "src/app/models/speakers";
import { Event } from "src/app/models/event";
import { DbDocuments } from "src/app/providers/database/db-documents";
import { GlobalService } from "src/app/providers/global/global.service";
import { NameSession } from "src/app/models/name-session";
import { TranslateService } from "@ngx-translate/core";
import { ModuleSchedule } from "src/app/models/modules/module-schedule";
import { WherebyService } from "src/app/providers/whereby/whereby.service";
import * as moment from "moment";
import { videoPlayersTypes } from "src/app/models/video-players-types";
import { IntegrationsService } from "src/app/providers/integrations/integrations.service";
import {
    IIntegration,
    IZoom,
} from "src/app/shared/interfaces/integrations.interface";
import { DateTimeService } from "src/app/providers/date-time/date-time.service";

@Component({
    selector: "app-create-schedule",
    templateUrl: "./create-schedule.component.html",
    styleUrls: ["./create-schedule.component.scss"],
})
export class CreateScheduleComponent implements OnInit {
    formValidation: FormGroup;
    public eventId: string = this.route.parent.params["_value"]["uid"];
    public moduleId: string = this.route.snapshot.params["moduleId"];

    @ViewChild("successSwal") public successSwal: SwalComponent;
    @ViewChild("errorSwal") public errorSwal: SwalComponent;

    loader: boolean = false;

    module: ModuleSchedule;

    public event: Event;
    principalEventLangFormated: string = "PtBR";
    public languages: Array<any> = [
        {
            value: "pt_BR",
            name: this.translateService.instant("comp.event_info.portuguese"),
        },
        {
            value: "en_US",
            name: this.translateService.instant("comp.event_info.english"),
        },
        {
            value: "es_ES",
            name: this.translateService.instant("comp.event_info.spanish"),
        },
        {
            value: "fr_FR",
            name: this.translateService.instant("comp.event_info.french"),
        },
        {
            value: "de_DE",
            name: this.translateService.instant("comp.event_info.german"),
        },
    ];
    public userLanguage: string = null; //user language is using

    createSessionError: boolean;
    errorFormLocation: boolean;
    timeError: boolean;

    // automcomplete locations
    listLocations: Array<Location>;
    selectedLocations: Array<Location> = [];
    filteredListLocations = [];
    queryLocation = "";

    // tracks
    listTrack: Array<Track>;
    selectedTrack: Array<Track> = [];
    filteredListTrack = [];
    queryTrack = "";
    errorFormTrack: boolean = false;

    // GROUPS FILTER

    listGroup: Array<Group>;
    selectedGroup: Array<Group> = [];
    filteredListGroups = [];
    queryGroup = "";
    errorFormGroup: boolean = false;

    // GROUPS visio FILTER

    listGroupVisio: Array<Group>;
    selectedGroupVisio: Array<Group> = [];
    filteredListGroupsVisio = [];
    queryGroupVisio = "";
    errorFormGroupVisio: boolean = false;
    showGroupVisio: boolean = false;

    // SPEAKERS FILTER
    listSpeaker: Array<Speaker>;
    selectedSpeaker = [];
    filteredListSpeakers = [];
    querySpeaker = "";
    errorFormSpeaker: boolean = false;

    // DOCUMENTS FILTER
    listDocument: Array<Speaker>;
    selectedDocument = [];
    filteredListDocuments = [];
    queryDocument = "";
    errorFormDocument: boolean = false;

    datePast: boolean = false; //Boolean variable that checks if the session date is in the past

    // WHEREBY
    visioForm: FormGroup;

    playersTypes: string[] = videoPlayersTypes;
    errorFormVideoUrl: boolean = false;

    // Integrations
    integration: IIntegration;

    constructor(
        private fb: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private luxon: LuxonService,
        private dbEvent: DbEventsProvider,
        private dbLocation: DbLocationsProvider,
        private dbSchedule: DbScheduleProvider,
        private dbGroups: DbGroupsProvider,
        private dbSpeaker: DbSpeakersProvider,
        private dbDocuments: DbDocuments,
        private global: GlobalService,
        private translateService: TranslateService,
        private SWhereby: WherebyService,
        private SIntegrations: IntegrationsService,
        private dt: DateTimeService
    ) {
        this.createSessionError = false;
        this.errorFormLocation = false;
        this.timeError = false;
        this.datePast = false;

        this.visioForm = this.fb.group({
            type: new FormControl("whereby"),
            name: new FormControl(""),
            url: new FormControl(""),
            hostUrl: new FormControl(""),
            startDate: new FormControl(moment().toISOString().split("T")[0]),
            endDate: new FormControl(
                moment().add(1, "year").toISOString().split("T")[0]
            ),
            mode: new FormControl("group"),
            zoomIntegrationId: new FormControl(null),
            allowChatOnVisio: new FormControl(true),
            allowScreenShareOnVisio: new FormControl(true),
            allowLeaveOnVisio: new FormControl(true),
            activated: new FormControl(false),
        });

        this.formValidation = fb.group({
            date: [null, Validators.compose([Validators.required])],
            locations: [null],
            startTime: [null, Validators.compose([Validators.required])],
            finalTime: [null],
            speakers: [null],
            tracks: [null],
            groups: [null],
            documents: [null],
            videoPlayerType: ["disabled"],
            videoPlayerUrl: [""],
            descriptionlanguages_de_DE: [""],
            descriptionlanguages_en_US: [""],
            descriptionlanguages_es_ES: [""],
            descriptionlanguages_fr_FR: [""],
            descriptionlanguages_pt_BR: [""],
            totalAttendees: [0],
        });

        // get the user's primary language.
        this.global.getLanguage((language: string) => {
            this.userLanguage = language;
            this.principalEventLangFormated = this.convertLangFormat(
                this.userLanguage
            );
        });

        this.getScheduleModule();

        // get the event.
        this.dbEvent.getEvent(this.eventId, (event: Event) => {
            this.event = event;

            // sort array of languages by placing the main language at the top.
            this.sortArrayLanguages();

            // Initializes the formControl of session titles.
            if (this.event.languages.PtBR) {
                this.formValidation.addControl(
                    "title_pt_br",
                    new FormControl(
                        null,
                        Validators.compose([
                            Validators.required,
                            Validators.maxLength(1000),
                        ])
                    )
                );
            } else {
                this.formValidation.addControl(
                    "title_pt_br",
                    new FormControl("")
                );
            }

            if (this.event.languages.EnUS) {
                this.formValidation.addControl(
                    "title_en_us",
                    new FormControl(
                        null,
                        Validators.compose([
                            Validators.required,
                            Validators.maxLength(1000),
                        ])
                    )
                );
            } else {
                this.formValidation.addControl(
                    "title_en_us",
                    new FormControl("")
                );
            }

            if (this.event.languages.EsES) {
                this.formValidation.addControl(
                    "title_es_es",
                    new FormControl(
                        null,
                        Validators.compose([
                            Validators.required,
                            Validators.maxLength(1000),
                        ])
                    )
                );
            } else {
                this.formValidation.addControl(
                    "title_es_es",
                    new FormControl("")
                );
            }

            if (this.event.languages.FrFR) {
                this.formValidation.addControl(
                    "title_fr_fr",
                    new FormControl(
                        null,
                        Validators.compose([
                            Validators.required,
                            Validators.maxLength(1000),
                        ])
                    )
                );
            } else {
                this.formValidation.addControl(
                    "title_fr_fr",
                    new FormControl("")
                );
            }

            if (this.event.languages.DeDE) {
                this.formValidation.addControl(
                    "title_de_de",
                    new FormControl(
                        null,
                        Validators.compose([
                            Validators.required,
                            Validators.maxLength(1000),
                        ])
                    )
                );
            } else {
                this.formValidation.addControl(
                    "title_de_de",
                    new FormControl("")
                );
            }
        });

        this.dbLocation.getLocationsEvent(this.eventId, "asc", (locations) => {
            this.listLocations = [];
            this.listLocations = locations;
        });

        this.dbSchedule.getModuleTracks(
            this.moduleId,
            (tracks: Array<Track>) => {
                this.listTrack = [];
                this.listTrack = tracks;
            }
        );

        this.dbSpeaker.getSpeakersEvent(
            this.eventId,
            (speakers: Array<Speaker>) => {
                this.listSpeaker = [];
                this.listSpeaker = speakers;
            }
        );

        this.dbGroups.searchModulesAndGroups(this.eventId, (result) => {
            this.listGroup = [];
            this.listGroupVisio = [];
            this.listGroup = result["groups"];
            this.listGroupVisio = result["groups"];
        });

        this.dbDocuments.getEventDocuments(this.eventId, (result) => {
            this.listDocument = [];
            this.listDocument = result;
        });

        this.SIntegrations.getIntegration(this.eventId).subscribe(
            (integration) => {
                this.integration = integration;
            }
        );
    }

    ngOnInit() {}

    /**
     * Getting schedule module
     */
    getScheduleModule() {
        this.dbSchedule.getScheduleModule(
            this.moduleId,
            (module: ModuleSchedule) => {
                this.module = module;
            }
        );
    }

    /**
     * Get zoom integration
     * @param integrationId
     */
    getZoomIntegrationById(integrationId: string): IZoom {
        let zoomIntegration = this.integration.zoom.filter(
            (zoom) => zoom.id == integrationId
        );
        return zoomIntegration ? zoomIntegration[0] : null;
    }

    async createSession(form) {
        if (!this.event) {
            this.createSessionError = true;    
            return;
        }

        let validation = true;
        this.loader = true;

        this.createSessionError = false;
        this.errorFormLocation = false;
        this.errorFormTrack = false;
        this.errorFormSpeaker = false;
        this.errorFormDocument = false;
        this.errorFormVideoUrl = false;
        this.timeError = false;
        this.datePast = false;

        const session = new Session();
        session.eventId = this.eventId;
        session.moduleId = this.moduleId;
        
        const auxDate = form.date.split("-");   // [year, month, day]
        session.date = this.dt.createDateInTZ(
            auxDate[0], 
            auxDate[1], 
            auxDate[2],
            "00", 
            "00",
            this.event.timezone
        ).toISO();

        const auxSTime: string[] = form.startTime.split(":");   // [hours, minutes, seconds]
        const startTime: number = this.dt.makeDateTp(auxDate[0], auxDate[1], auxDate[2], auxSTime[0], auxSTime[1], auxSTime[2]);
        session.startTime = this.dt.createDateInTZ(
            auxDate[0], 
            auxDate[1], 
            auxDate[2],
            auxSTime[0], 
            auxSTime[1],
            this.event.timezone
        ).toISO();

        if (form.finalTime != null) {
            const auxETime: string[] = form.finalTime.split(":");   // [hours, minutes, seconds]
            const endTime: number = this.dt.makeDateTp(auxDate[0], auxDate[1], auxDate[2], auxETime[0], auxETime[1], auxETime[2]);

            // checks if the endTime comes before than the startTime
            if (endTime < startTime) {
                validation = false;
                this.loader = false;
                this.timeError = true;
            }

            // session.newEndTime = {
            //     day: parseInt(auxDate[2]),
            //     month: parseInt(auxDate[1]),
            //     year: parseInt(auxDate[0]),
            //     hour: parseInt(auxETime[0]),
            //     minute: parseInt(auxETime[1]),
            //     second: parseInt(auxETime[2]),
            //     millisecond: 0,
            //     zone: "local",
            // };
            session.endTime = this.dt.createDateInTZ(
                auxDate[0], 
                auxDate[1], 
                auxDate[2],
                auxETime[0], 
                auxETime[1],
                this.event.timezone
            ).toISO();
        }

        // session.newDate = {
        //     day: parseInt(auxDate[2]),
        //     month: parseInt(auxDate[1]),
        //     year: parseInt(auxDate[0]),
        //     hour: 0,
        //     minute: 0,
        //     second: 0,
        //     millisecond: 0,
        //     zone: "local",
        // };

        // session.newStartTime = {
        //     day: parseInt(auxDate[2]),
        //     month: parseInt(auxDate[1]),
        //     year: parseInt(auxDate[0]),
        //     hour: parseInt(auxSTime[0]),
        //     minute: parseInt(auxSTime[1]),
        //     second: parseInt(auxSTime[2]),
        //     millisecond: 0,
        //     zone: "local",
        // };

        // title
        session.name = new NameSession(
            form.title_pt_br,
            form.title_en_us,
            form.title_es_es,
            form.title_fr_fr,
            form.title_de_de
        );

        if (form.locations.length > 0) {
            this.errorFormLocation = true;
            validation = false;
        } else {
            let contOrder = 0;

            for (let index in this.selectedLocations) {
                const location = this.selectedLocations[index];
                location.order = contOrder;
                session.locations[location.uid] = Object.assign({}, location);

                contOrder++;
            }
        }

        if (form.speakers) {
            this.errorFormSpeaker = true;
            validation = false;
        } else {
            for (let index in this.selectedSpeaker) {
                const speaker = this.selectedSpeaker[index];
                session.speakers[speaker.uid] = Object.assign({}, speaker);
            }
        }

        if (form.documents) {
            this.errorFormDocument = true;
            validation = false;
        } else {
            for (let index in this.selectedDocument) {
                const document = this.selectedDocument[index];
                session.documents[document.uid] = Object.assign({}, document);
            }
        }

        if (this.selectedTrack.length > 0) {
            for (let index in this.selectedTrack) {
                const track = this.selectedTrack[index];
                session.tracks[track["uid"]] = Object.assign({}, track);
            }
        }

        if (this.selectedGroup.length > 0) {
            for (let index in this.selectedGroup) {
                const group = this.selectedGroup[index];
                session.groups[group["uid"]] = Object.assign({}, group);
            }
        }

        // total attendees
        if (
            form.totalAttendees !== null &&
            form.totalAttendees !== "" &&
            typeof form.totalAttendees !== "undefined"
        ) {
            session.limitAttendees = form.totalAttendees;
        }

        // descriptions
        if (typeof form.descriptionlanguages_pt_BR !== "undefined") {
            session.descriptions.PtBR = form.descriptionlanguages_pt_BR.replace(
                /href="/g,
                'class="wysiwyg-link" href="'
            );
        } else {
            session.descriptions.PtBR = "";
        }

        if (typeof form.descriptionlanguages_en_US !== "undefined") {
            session.descriptions.EnUS = form.descriptionlanguages_en_US.replace(
                /href="/g,
                'class="wysiwyg-link" href="'
            );
        } else {
            session.descriptions.EnUS = "";
        }

        if (typeof form.descriptionlanguages_de_DE !== "undefined") {
            session.descriptions.DeDE = form.descriptionlanguages_de_DE.replace(
                /href="/g,
                'class="wysiwyg-link" href="'
            );
        } else {
            session.descriptions.DeDE = "";
        }

        if (typeof form.descriptionlanguages_es_ES !== "undefined") {
            session.descriptions.EsES = form.descriptionlanguages_es_ES.replace(
                /href="/g,
                'class="wysiwyg-link" href="'
            );
        } else {
            session.descriptions.EsES = "";
        }

        if (typeof form.descriptionlanguages_fr_FR !== "undefined") {
            session.descriptions.FrFR = form.descriptionlanguages_fr_FR.replace(
                /href="/g,
                'class="wysiwyg-link" href="'
            );
        } else {
            session.descriptions.FrFR = "";
        }

        // Video player part
        const videoUrl = (form.videoPlayerUrl) ? form.videoPlayerUrl.trim() : null;
        if (form.videoPlayerType != "disabled") {
            if (!videoUrl) {
                validation = false;
                this.errorFormVideoUrl = true;
            }
            session.videoPlayer.type = form.videoPlayerType;
            session.videoPlayer.url = form.videoPlayerUrl;
        } else {
            session.videoPlayer = null;
        }

        if (validation) {
            try {
                // Create room whereby if needed
                let startDateArray = this.visioForm.value.startDate
                    ? this.visioForm.value.startDate.split("-")
                    : [];
                let startDate =
                    startDateArray.length > 0
                        ? this.luxon
                              .createDate(
                                  startDateArray[0],
                                  startDateArray[1],
                                  startDateArray[2],
                                  "00",
                                  "00",
                                  "00"
                              )
                              .toISO()
                        : null;
                let endDateArray = this.visioForm.value.endDate
                    ? this.visioForm.value.endDate.split("-")
                    : [];
                let endDate =
                    endDateArray.length > 0
                        ? this.luxon
                              .createDate(
                                  endDateArray[0],
                                  endDateArray[1],
                                  endDateArray[2],
                                  "00",
                                  "00",
                                  "00"
                              )
                              .toISO()
                        : null;

                if (
                    this.event.allow_visio &&
                    startDate &&
                    endDate &&
                    startDate < endDate &&
                    this.visioForm.value.name &&
                    this.visioForm.value.name != ""
                ) {
                    let resp: any = null;
                    if (this.visioForm.value.type == "whereby") {
                        // if (session.visio && session.visio.meetingId) {
                        //     await this.SWhereby.deleteMeeting(session.visio.meetingId);
                        // }

                        let meeting: any = {
                            roomNamePrefix:
                                "/" +
                                (this.visioForm.value.name
                                    ? this.visioForm.value.name
                                    : session.visio.roomName
                                    ? session.visio.roomName
                                    : "default"),
                            roomMode: "group",
                            startDate: startDate ? startDate : null,
                            endDate: endDate ? endDate : null,
                            fields: ["hostRoomUrl"],
                        };
                        resp = await this.SWhereby.createMeeting(meeting);
                    } else if (this.visioForm.value.type == "zoom") {
                        let meeting: any = {
                            topic: this.visioForm.value.name,
                            start_time: this.luxon.getGmtDate(startDate),
                            duration: moment(endDate).diff(
                                moment(startDate),
                                "minutes"
                            ),
                            userId: this.getZoomIntegrationById(
                                this.visioForm.value.zoomIntegrationId
                            ).email,
                            jwt: this.getZoomIntegrationById(
                                this.visioForm.value.zoomIntegrationId
                            ).jwt,
                        };

                        resp = await this.SWhereby.createZoomMeeting(meeting);
                    }

                    if (resp.body && resp.body.meetingId && resp.body.roomUrl) {
                        session.visio = {
                            type: this.visioForm.value.type,
                            zoomIntegrationId:
                                this.visioForm.value.type == "zoom"
                                    ? this.getZoomIntegrationById(
                                          this.visioForm.value.zoomIntegrationId
                                      ).id
                                    : "",
                            password:
                                this.visioForm.value.type == "zoom" &&
                                resp.body.password
                                    ? resp.body.password
                                    : "",
                            meetingId: resp.body.meetingId
                                ? resp.body.meetingId
                                : "",
                            roomUrl: resp.body.roomUrl ? resp.body.roomUrl : "",
                            hostRoomUrl: resp.body.hostRoomUrl
                                ? resp.body.hostRoomUrl
                                : "",
                            startDate: startDate ? startDate : null,
                            endDate: endDate ? endDate : null,
                            roomMode: "group",
                            roomName: this.visioForm.value.name
                                ? this.visioForm.value.name
                                : session.visio.roomName
                                ? session.visio.roomName
                                : "default",
                            allowChatOnVisio: this.visioForm.value
                                .allowChatOnVisio,
                            allowScreenShareOnVisio: this.visioForm.value
                                .allowScreenShareOnVisio,
                            allowLeaveOnVisio: this.visioForm.value
                                .allowLeaveOnVisio,
                            activated: this.visioForm.value.activated,
                            groupsVisioHost: {},
                        };

                        if (this.selectedGroupVisio.length > 0) {
                            for (let index in this.selectedGroupVisio) {
                                const group = this.selectedGroupVisio[index];
                                session.visio.groupsVisioHost[
                                    group["uid"]
                                ] = Object.assign({}, group);
                            }
                        }
                    }
                }

                this.dbSchedule.getIdentifiersSessions(
                    this.eventId,
                    (identifiers) => {
                        session.identifier = this.identifier(identifiers);
                        this.dbSchedule.createSession(session, (success) => {
                            if (success) {
                                this.loader = false;
                                this.successSwal.fire();
                            } else {
                                this.errorSwal.fire();
                                this.loader = false;
                            }
                        });
                    }
                );
            } catch (error) {
                this.createSessionError = true;
                this.loader = false;
            }
        } else {
            this.loader = false;
        }
    }

    identifier(identifiers) {
        let id = 1;
        let flag = false;

        while (!flag) {
            const pos = identifiers
                .map(function (e) {
                    return e;
                })
                .indexOf(id);

            if (pos <= -1) {
                flag = true;
                break;
            }

            id++;
        }
        return id;
    }

    // sort array of languages by placing the main language at the top.
    sortArrayLanguages() {
        const index = this.languages
            .map(function (e) {
                return e.value;
            })
            .indexOf(this.event.language);

        // put the main language at index zero of the array.
        if (index > 0) {
            const aux = this.languages[0];
            this.languages[0] = this.languages[index];
            this.languages[index] = aux;
        }
    }

    redirectList() {
        this.router.navigate([
            "/event/" + this.eventId + "/schedule/" + this.moduleId,
        ]);
    }

    // autocomplete locations

    filterLocations() {
        //  funÃ§Ã£o usa a query variÃ¡vel para filtrar tracks, entÃ£o ela armazena o resultado no filteredList.
        if (this.queryLocation !== "") {
            this.filteredListLocations = this.listLocations.filter(
                function (el) {
                    return (
                        el.name
                            .toLowerCase()
                            .indexOf(this.queryLocation.toLowerCase()) > -1
                    );
                }.bind(this)
            );
        } else {
            this.filteredListLocations = [];
        }
    }

    selectLocation(item) {
        // atribuir o item selecionado para a variÃ¡vel de consulta, a fim de fazÃª-lo aparecer na entrada, e para fazer a lista de sugestÃµes de desaparecer
        if (this.selectedLocations.length > 0) {
            const index = this.selectedLocations.indexOf(item);
            if (index == -1) {
                this.selectedLocations.push(item);
            }
        } else {
            this.selectedLocations.push(item);
        }

        this.queryLocation = "";
        this.filteredListLocations = [];
    }

    removeLocation(item) {
        // remove valores selecionados
        this.selectedLocations.splice(this.selectedLocations.indexOf(item), 1);
    }

    // filter track
    filterTracks() {
        if (this.queryTrack !== "") {
            this.filteredListTrack = this.listTrack.filter(
                function (el) {
                    return (
                        el.name[this.principalEventLangFormated]
                            .toLowerCase()
                            .indexOf(this.queryTrack.toLowerCase()) > -1
                    );
                }.bind(this)
            );
        } else {
            this.filteredListTrack = [];
        }
    }

    // select track from filtered list
    selectTrack(item) {
        if (this.selectedTrack.length > 0) {
            const index = this.selectedTrack.indexOf(item);
            if (index == -1) {
                this.selectedTrack.push(item);
            }
        } else {
            this.selectedTrack.push(item);
        }
        this.queryTrack = "";
        this.filteredListTrack = [];
    }

    // remove selected location
    removeTrack(item) {
        this.selectedTrack.splice(this.selectedTrack.indexOf(item), 1);
    }

    /******************* GROUPS **********************/

    // filter groups
    filterGroups() {
        if (this.queryGroup !== "") {
            this.filteredListGroups = this.listGroup.filter(
                function (el) {
                    return (
                        el.name
                            .toLowerCase()
                            .indexOf(this.queryGroup.toLowerCase()) > -1
                    );
                }.bind(this)
            );
        } else {
            this.filteredListGroups = [];
        }
    }

    // Filter groups for visio
    filterGroupsVisio() {
        this.filteredListGroupsVisio = this.listGroupVisio;

        this.filteredListGroupsVisio = this.filteredListGroupsVisio.filter(
            (group) => {
                if (
                    group.name
                        .toLowerCase()
                        .indexOf(this.queryGroupVisio.toLowerCase()) > -1 &&
                    !this.selectedGroupVisio.find(
                        (groupFind) => groupFind.uid == group.uid
                    )
                ) {
                    return true;
                } else {
                    return false;
                }
            }
        );
        this.showGroupVisio = true;

        if (this.filteredListGroupsVisio.length == 0) {
            this.showGroupVisio = false;
        }
        // if (this.queryGroupVisio !== "") {
        //     this.filteredListGroupsVisio = this.listGroupVisio.filter(function (el) {
        //         return el.name.toLowerCase().indexOf(this.queryGroupVisio.toLowerCase()) > -1;
        //     }.bind(this));
        // } else {
        //     this.filteredListGroupsVisio = [];
        // }
    }

    // select track from filtered list
    selectGroup(item) {
        if (this.selectedGroup.length > 0) {
            const index = this.selectedGroup.indexOf(item);
            if (index == -1) {
                this.selectedGroup.push(item);
            }
        } else {
            this.selectedGroup.push(item);
        }
        this.queryGroup = "";
        this.filteredListGroups = [];
    }

    // Select group for visio
    selectGroupVisio(item) {
        if (this.selectedGroupVisio.length > 0) {
            const index = this.selectedGroupVisio.indexOf(item);
            if (index == -1) {
                this.selectedGroupVisio.push(item);
            }
        } else {
            this.selectedGroupVisio.push(item);
        }
        this.queryGroupVisio = "";
        this.filterGroupsVisio();
        this.showGroupVisio = false;
    }

    // remove selected location
    removeGroup(item) {
        this.selectedGroup.splice(this.selectedGroup.indexOf(item), 1);
    }

    // Remove group for visio
    removeGroupVisio(item) {
        this.selectedGroupVisio.splice(
            this.selectedGroupVisio.indexOf(item),
            1
        );
    }

    /******************* SPEAKERS **********************/

    // filter speakers
    filterSpeakers() {
        if (this.querySpeaker !== "") {
            this.filteredListSpeakers = this.listSpeaker.filter(
                function (el) {
                    return (
                        el.name
                            .toLowerCase()
                            .indexOf(this.querySpeaker.toLowerCase()) > -1
                    );
                }.bind(this)
            );
        } else {
            this.filteredListSpeakers = [];
        }
    }

    // select speaker from filtered list
    selectSpeaker(item) {
        if (this.selectedSpeaker.length > 0) {
            const index = this.selectedSpeaker.indexOf(item);
            if (index == -1) {
                this.selectedSpeaker.push(item);
            }
        } else {
            this.selectedSpeaker.push(item);
        }
        this.querySpeaker = "";
        this.filteredListSpeakers = [];
    }

    // remove selected speaker
    removeSelectedSpeakers(item) {
        this.selectedSpeaker.splice(this.selectedSpeaker.indexOf(item), 1);
    }

    /******************* DOCUMENTS **********************/

    // filter documents
    filterDocuments() {
        if (this.queryDocument !== "") {
            this.filteredListDocuments = this.listDocument.filter(
                function (el) {
                    return (
                        el.name[this.principalEventLangFormated]
                            .toLowerCase()
                            .indexOf(this.queryDocument.toLowerCase()) > -1
                    );
                }.bind(this)
            );
        } else {
            this.filteredListDocuments = [];
        }
    }

    // select document from filtered list
    selectDocument(item) {
        if (this.selectedDocument.length > 0) {
            const index = this.selectedDocument.indexOf(item);
            if (index == -1) {
                this.selectedDocument.push(item);
            }
        } else {
            this.selectedDocument.push(item);
        }
        this.queryDocument = "";
        this.filteredListDocuments = [];
    }

    // remove selected document
    removeSelectedDocuments(item) {
        this.selectedDocument.splice(this.selectedDocument.indexOf(item), 1);
    }

    convertLangFormat(lang) {
        let formatedLang;
        switch (lang) {
            case "pt_BR": {
                formatedLang = "PtBR";
                break;
            }
            case "en_US": {
                formatedLang = "EnUS";
                break;
            }
            case "es_ES": {
                formatedLang = "EsES";
                break;
            }
            case "fr_FR": {
                formatedLang = "FrFR";
                break;
            }
            case "de_DE": {
                formatedLang = "DeDE";
                break;
            }
        }
        return formatedLang;
    }
    foo1 = "a"
    foo() {
        this.foo1 = typeof this.formValidation.value.videoPlayerType;
    }
}
